import { AddIcon } from '@chakra-ui/icons'
import { Button, Flex, Stack } from '@chakra-ui/react'
import { getRouteUrl } from 'helpers/admin'
import React from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../../components/PageHeader'
import Table from '../../../components/Table'
import useAssetsDefaultFields from './hooks/useAssetsDefaultFields'

const AssetDefaultFields = () => {
  const {
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data,
    refetch,
    isLoading,
    pagination,
    fetchParams
  } = useAssetsDefaultFields()

  return (
    <Stack>
      <PageHeader
        title="Default Fields"
        Actions={
          <Flex flexDir={['column', 'row']} gap={4} align="center">
            <Link to={getRouteUrl('newAssetDefaultFields')}>
              <Button leftIcon={<AddIcon />} colorScheme="blue">
                New
              </Button>
            </Link>
          </Flex>
        }
      />
      <Table
        shouldResetPage={fetchParams?.page === 1}
        isLoading={isLoading}
        data={data}
        columns={columns}
        pageCount={pagination?.last}
        totalItems={pagination?.items}
        fetchPage={refetch}
        initiallyHiddenColumns={hiddenColumns}
        persistedColumnsKey={hiddenColumnsKey}
      />
    </Stack>
  )
}

export default AssetDefaultFields
