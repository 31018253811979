import { CheckIcon } from '@chakra-ui/icons'
import {
  Divider,
  Flex,
  Heading,
  Radio,
  Stack,
  Tag,
  VisuallyHidden
} from '@chakra-ui/react'
import EmptyState from 'components/EmptyState'
import InfoTip from 'components/Infotip'
import { isEmpty } from 'lodash'
import React from 'react'

const AssetQuestionCard = ({
  label,
  id,
  template_name,
  fields,
  value,
  onChange
}) => {
  const isAssetSelected = id === value?.asset_id
  return (
    <Stack
      borderRadius={4}
      borderWidth={1}
      borderColor={isAssetSelected ? 'green' : 'gray.200'}
      w="100%"
      gap={2}
      key={label}
      p={4}
      cursor="pointer"
      onClick={() =>
        onChange({
          asset_id: id,
          value: label,
          name: template_name,
          label,
          fields
        })
      }
      position="relative"
    >
      {isAssetSelected ? (
        <CheckIcon position="absolute" top="2" right="2" color="green.500" />
      ) : null}
      <Flex w="100%" justify="space-between" align="center">
        <Flex gap={2}>
          <Heading size="sm">{template_name}</Heading>
          <InfoTip label={label} placement="bottom" />
        </Flex>
        <VisuallyHidden>
          <Radio mb="0!important" value={id} />
        </VisuallyHidden>
      </Flex>
      <Divider />
      {isEmpty(fields) ? (
        <EmptyState text="This asset has no questions" />
      ) : (
        fields.map(({ label, value }, index) => (
          <Flex w="100%" key={index} justify="space-between" align="center">
            <Flex gap={4}>
              <Heading size="sm">{label}:</Heading>
              {value?.match(';') ? (
                <Flex gap={2} align="center">
                  {value?.split(';')?.map((item, index) => (
                    <Tag colorScheme="blue" size="sm" key={index}>
                      {item}
                    </Tag>
                  ))}
                </Flex>
              ) : value ? (
                value
              ) : (
                <Tag colorScheme="gray" size="sm">
                  Empty
                </Tag>
              )}
            </Flex>
          </Flex>
        ))
      )}
    </Stack>
  )
}

export default AssetQuestionCard
