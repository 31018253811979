import React, { useMemo } from 'react'
import Breadcrumb from '../../../../../components/Breadcrumb'
import { getRouteUrl } from '../../../../../helpers/admin'

const BusinessFormBreadcrumb = ({ business }) => {
  const options = useMemo(() => {
    const opts = [
      {
        title: 'Businesses',
        url: getRouteUrl('businesses')
      }
    ]
    if (business) {
      opts.push(
        {
          title: business.businessName,
          route: null
        },
        {
          title: 'Edit business',
          route: null
        }
      )
    }

    return opts
  }, [business])

  return <Breadcrumb nativeHomeRoute={false} routeTree={options} />
}

export default BusinessFormBreadcrumb
