/* eslint-disable react/display-name */
import {
  Box,
  Button,
  Flex,
  Select,
  Stack,
  theme,
  useMediaQuery
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Select as ReactSelect } from 'chakra-react-select'
import FormErrorMessage from 'components/FormErrorMessage'
import { isArray, omit } from 'lodash'
import PropTypes from 'prop-types'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import DatePicker from '../../../../../../components/DatePicker'
import FormField from '../../../../../../components/FormField'
import FormSubmitActions from '../../../../../../components/FormSubmitActions'
import EditInline from '../../../../../../components/Inputs/EditInline'
import { setQuillValue } from '../../../../../../helpers'
import RichTextEditor, {
  quillModules
} from '../../../../../../pages/TemplateEditor/RichTextEditor'
import Notification from '../../../../../../services/Notification'
import { useQuestionActions } from '../../../ActionsContext'
import UsersSelector from '../UsersSelector'

const schema = yup
  .object({
    title: yup.string().required('The title is required'),
    description: yup.string().required('The description is required'),
    status: yup.string().required('The status is required'),
    priority: yup.string().required('The priority is required')
  })
  .required()

const ActionEditor = forwardRef(
  (
    {
      action,
      onEdit,
      setHasUnsavedChanges,
      hasFullWidthTitle,
      isCreator,
      isCreation,
      setError
    },
    ref
  ) => {
    const defaultValues = useMemo(() => {
      return {
        title: action?.title ?? '',
        description: action?.description ?? '',
        status: action?.status ?? 'to_do',
        user_ids: action?.user_ids,
        folder_id: action?.folder_id ?? null,
        priority: action?.priority ?? 'medium',
        due_date: action?.due_date ?? ''
      }
    }, [action])

    const [assigneeOption] = useState(
      action?.user_ids || !action?.folder_id ? 'users' : 'folder'
    )

    const {
      register,
      handleSubmit,
      setValue,
      watch,
      formState: { errors }
    } = useForm({
      defaultValues,
      resolver: yupResolver(schema)
    })

    const [isMobile] = useMediaQuery('(max-width: 799px)')

    const { statuses, priorities, isLoading, singularTitle } =
      useQuestionActions()

    const currentValues = watch()

    const handleChangeDate = (date) => {
      setValue('due_date', date)
    }

    const onSubmit = (data) => {
      if (assigneeOption === 'users' && !data?.user_ids?.length) {
        return Notification({
          icon: 'error',
          text: `This ${singularTitle} must be associated with at least one user`
        })
      } else if (assigneeOption === 'folder' && !data?.folder_id) {
        return Notification({
          icon: 'error',
          text: 'This action must be associated with at least one folder'
        })
      }
      const submitData = omit(
        data,
        assigneeOption === 'users' ? ['folder_id'] : ['user_ids']
      )
      onEdit(submitData, setError)
    }

    const onChangeUsers = (users) => {
      setValue('user_ids', users)
    }

    const hasUnsavedChanges = useMemo(() => {
      const keys = Object.keys(defaultValues)
      return keys.some((key) => {
        if (isArray(defaultValues[key])) {
          return defaultValues[key].some(
            (v, index) =>
              v?.value && v.value !== currentValues[key][index]?.value
          )
        }
        return defaultValues[key] !== currentValues[key]
      })
    }, [defaultValues, currentValues])

    useEffect(() => {
      setHasUnsavedChanges(hasUnsavedChanges)

      return () => {
        setHasUnsavedChanges(false)
      }
    }, [hasUnsavedChanges, setHasUnsavedChanges])

    const onChangeDescription = (value) => {
      setQuillValue(value, setValue)
    }

    const onChangeSelectedFolder = (folderId) => {
      setValue('folder_id', folderId)
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2} mb={4}>
          <FormField label={`${singularTitle} title`}>
            <Box w={isMobile || hasFullWidthTitle ? '100%' : '40%'}>
              <EditInline
                placeholder={`Edit ${singularTitle} title`}
                defaultValue={defaultValues.title}
                startWithEditView={isCreation}
                fontSize="md"
                registerProps={{ ...register('title', { required: true }) }}
                isInvalid={!!errors?.title?.message}
              />
              <FormErrorMessage message={errors?.title?.message} />
            </Box>
          </FormField>
          <Box>
            <RichTextEditor
              maxWidth="100%"
              autoFocus={!isCreation}
              modules={quillModules}
              hasToolbar
              register={{
                ...register(`description`, {
                  required: true
                })
              }}
              value={defaultValues.description}
              onChangeValue={onChangeDescription}
              placeholder="Action Description"
              isInvalid={!!errors?.description?.message}
              focusBorderColor={errors?.description ? '#E53E3E' : '#3182CE'}
            />
            <FormErrorMessage message={errors?.description?.message} />
          </Box>
          <FormField label="Assign to users" isRequired Adornment={null}>
            {assigneeOption === 'users' ? (
              <UsersSelector
                onSelect={onChangeUsers}
                defaultUsers={currentValues.user_ids}
                closeMenuOnSelect={false}
              />
            ) : (
              <ReactSelect
                placeholder="Select a folder"
                options={[]}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: theme.zIndices.modal + 10
                  })
                }}
                defaultValue={currentValues.folder_id}
                onChange={onChangeSelectedFolder}
              />
            )}
          </FormField>
          {isArray(statuses) && (
            <FormField label="Status" isRequired>
              <Box>
                <Flex gap={2}>
                  <Select
                    {...register('status', { required: true })}
                    isInvalid={!!errors?.status?.message}
                  >
                    {statuses.map(({ label, value }, index) => {
                      return (
                        <option value={value} key={index}>
                          {label}
                        </option>
                      )
                    })}
                  </Select>
                  <Button
                    onClick={() => setValue('status', 'complete')}
                    colorScheme="blue"
                  >
                    Complete
                  </Button>
                </Flex>
                <FormErrorMessage message={errors?.status?.message} />
              </Box>
            </FormField>
          )}
          {isArray(priorities) && (
            <FormField label="Priority" isRequired>
              <Box>
                <Select
                  {...register('priority', { required: true })}
                  flex={1}
                  isInvalid={!!errors?.priority?.message}
                >
                  {priorities.map(({ label, value }, index) => {
                    return (
                      <option key={index} value={value}>
                        {label}
                      </option>
                    )
                  })}
                </Select>
                <FormErrorMessage message={errors?.priority?.message} />
              </Box>
            </FormField>
          )}
          <FormField label="Due date" isRequired={false}>
            <Box>
              <DatePicker
                initialStartDate={defaultValues.due_date}
                isRangePicker={false}
                onChange={handleChangeDate}
                showTimeSelect={false}
                minW="380px"
                keepDateOnClear
                monthsShown={1}
              />
              <FormErrorMessage message={errors?.due_date?.message} />
            </Box>
          </FormField>
        </Stack>
        <Flex>
          <FormSubmitActions
            cancelRoute="actions"
            saveButtonRef={ref}
            isLoading={isLoading}
            onSubmit={isCreator ? handleSubmit(onSubmit) : null}
          />
        </Flex>
      </form>
    )
  }
)

ActionEditor.defaultProps = {
  onEdit: () => ({}),
  setHasUnsavedChanges: () => ({}),
  action: {},
  hasFullWidthTitle: false,
  isCreator: false,
  isCreation: false
}

ActionEditor.propTypes = {
  onEdit: PropTypes.func,
  setHasUnsavedChanges: PropTypes.func,
  action: PropTypes.object,
  isCreator: PropTypes.bool,
  isCreation: PropTypes.bool
}

export default ActionEditor
