import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'
import { Label } from './styles'

const FloatingLabelBox = ({
  borderWidth,
  borderColor,
  borderRadius,
  padding,
  delay,
  label,
  children,
  labelCustomStyles,
  margin
}) => {
  return (
    <Box
      position="relative"
      borderWidth={borderWidth}
      borderColor={borderColor}
      borderRadius={borderRadius}
      padding={padding}
      margin={margin}
    >
      <Label
        dangerouslySetInnerHTML={{ __html: label }}
        labelCustomStyles={labelCustomStyles}
        delay={delay}
      />
      {children}
    </Box>
  )
}

FloatingLabelBox.defaultProps = {
  borderWidth: '2px',
  borderColor: 'blue.400',
  borderRadius: '4px',
  padding: 4,
  delay: '.5s',
  label: '',
  labelCustomStyles: ''
}

FloatingLabelBox.propTypes = {
  borderWidth: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  delay: PropTypes.string,
  label: PropTypes.string.isRequired,
  children: PropTypes.any,
  labelCustomStyles: PropTypes.string
}

export default FloatingLabelBox
