import React, { useMemo } from 'react'
import Breadcrumb from '../../../../../components/Breadcrumb'
import { getRouteUrl } from '../../../../../helpers'

const BreadcrumbChecklist = ({ id, name }) => {
  const options = useMemo(() => {
    const items = [
      {
        title: 'Checklists',
        url: getRouteUrl('checklists')
      },
      {
        title: 'Show Checklist',
        url: getRouteUrl('showChecklist', { id })
      },
      {
        title: name
      }
    ]
    return items
  }, [id, name])
  return <Breadcrumb routeTree={options} />
}

export default BreadcrumbChecklist
