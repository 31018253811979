/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronDownIcon, UnlockIcon } from '@chakra-ui/icons'
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { chakraComponentsSizes } from '../../helpers'

const PermissionSelector = ({
  onSelect,
  selectedPermission,
  hasMultiplePermissions,
  permissions,
  hideSelectedOptions,
  placeholder,
  blankPlaceholder,
  colorScheme,
  size,
  minW,
  variant,
  selectedPermissionPrefix,
  closeOnSelect
}) => {
  const buttonLabel = useMemo(() => {
    if (hasMultiplePermissions) {
      if (selectedPermission?.length) {
        return selectedPermission?.length === 1
          ? '1 permission selected'
          : `${selectedPermission?.length} permissions`
      }
      return blankPlaceholder
    } else if (selectedPermission?.label) {
      return `${selectedPermissionPrefix}: ${selectedPermission?.label}`
    }
    return blankPlaceholder
  }, [
    selectedPermissionPrefix,
    hasMultiplePermissions,
    blankPlaceholder,
    selectedPermission?.length,
    selectedPermission?.value
  ])
  const { isOpen, onClose, onOpen } = useDisclosure()

  const onPermissionsChange = (selectedPermissions) => {
    onSelect(selectedPermissions)
    if (closeOnSelect) {
      onClose()
    }
  }

  return (
    <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <PopoverTrigger>
        <Button
          minW={minW}
          colorScheme={colorScheme}
          size={size}
          leftIcon={<UnlockIcon />}
          variant={variant}
          rightIcon={<ChevronDownIcon />}
        >
          {buttonLabel}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>{placeholder}</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <Select
              isMulti={hasMultiplePermissions}
              options={permissions}
              onChange={onPermissionsChange}
              defaultValue={selectedPermission}
              hideSelectedOptions={hideSelectedOptions}
              placeholder={placeholder}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

PermissionSelector.propTypes = {
  onSelect: PropTypes.func,
  selectedPermission: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    )
  ]),
  hasMultiplePermissions: PropTypes.bool,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  hideSelectedOptions: PropTypes.bool,
  placeholder: PropTypes.string,
  colorScheme: PropTypes.string,
  size: PropTypes.oneOf(chakraComponentsSizes),
  minW: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
  selectedPermissionPrefix: PropTypes.string,
  closeOnSelect: PropTypes.bool
}

PermissionSelector.defaultProps = {
  onSelect: noop,
  selectedPermission: { label: '', value: null },
  hasMultiplePermissions: false,
  permissions: [],
  hideSelectedOptions: false,
  placeholder: 'Select a permission',
  blankPlaceholder: 'Select a permission',
  colorScheme: 'blue',
  size: 'md',
  minW: '200px',
  variant: 'outline',
  selectedPermissionPrefix: 'Permission',
  closeOnSelect: false
}

export default PermissionSelector
