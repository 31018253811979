import { isEqual } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useGlobalStateContext } from '../../../../../contexts/GlobalStateContext'
import {
  globalEvents,
  onTemplateSettingsTabChange
} from '../../../../../contexts/GlobalStateContext/events'
import { useGlobalEventListener, useQuery } from '../../../../../hooks'
import tabs from '../../tabs'
import useTabsParams from '../useTabsParams'

const getInitialTabIndex = (queryTab) => {
  const index = tabs.findIndex(({ id }) => isEqual(id, queryTab))
  if (index < 0 || !queryTab) {
    return 0
  }
  return index
}

const useTemplateSettingsTab = () => {
  const { global$ } = useGlobalStateContext()
  const query = useQuery()
  const { onChangeParams } = useTabsParams()
  const [currentTab, setCurrentTab] = useState(getInitialTabIndex(query?.tab))
  const [fetchControl, setFetchControl] = useState(
    (tabs ?? [])?.reduce((acc, tab) => {
      acc[tab.id] = true
      return acc
    }, {})
  )

  useEffect(() => {
    setCurrentTab(getInitialTabIndex(query?.tab))
  }, [query?.tab])

  const onTabChange = useCallback(
    (index, eventParams = {}) => {
      setCurrentTab(index)
      setFetchControl((prev) => ({
        ...prev,
        [tabs[currentTab].id]: false
      }))

      onChangeParams({ tab: tabs[index].id })

      global$.next(onTemplateSettingsTabChange({ ...tabs[index], eventParams }))
    },
    [currentTab, global$, onChangeParams]
  )

  useGlobalEventListener(
    useCallback(
      (event) => {
        if (event?.type === globalEvents.ON_CHANGE_TEMPLATE_SETTINGS_TAB) {
          const { id: tabId, ...otherParams } = event.payload
          const index = tabs.findIndex(({ id }) => isEqual(id, tabId))
          if (index >= 0) {
            onTabChange(index, { ...(otherParams ?? {}) })
          }
        }
      },
      [onTabChange]
    )
  )

  return {
    currentTab,
    onTabChange,
    fetchControl
  }
}

export default useTemplateSettingsTab
