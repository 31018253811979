/* eslint-disable react/jsx-key */
import { Box, Flex, Heading } from '@chakra-ui/react'
import EmptyState from 'components/EmptyState'
import RolesSelector from 'components/Selectors/RolesSelector'
import { isEmpty } from 'lodash'
import React from 'react'
import SelectedRoles from '../../SelectedRoles'

const UserRolesContent = ({
  roles,
  setValue,
  getValues,
  disabledRolesCategories,
  hasUserRolesByCategory,
  onChangeTemplatesSelector,
  checklistsList,
  checklistsOfChecklistRole,
  templatesList,
  templatesOfFormRole,
  templatesOfTemplateRole,
  foldersOfDocumentRole,
  dashboardsOfDashbordRole,
  documentsList,
  dashboardsList,
  templatesPageTitle,
  checklistsPageTitle,
  register,
  assetTemplatesPageTitle,
  assetTemplatesOfAssetTemplateRole,
  assetTemplatesList,
  data,
  isActive,
}) => {
  if (!isActive) return null;
  
  return (
    <Box w='100%'>
          <Flex w='100%' gap='30px'>
      <Box>
        {data?.roles ? (
          <RolesSelector
            roles={roles}
            rolesData={data?.roles}
            setValue={setValue}
            getValues={getValues}
            isEmptyLabel
            disabledRolesCategories={disabledRolesCategories}
          />
        ) : (
          <EmptyState text="There are no roles to show" />
        )}
      </Box>
    </Flex>
    {!isEmpty(roles) && (
      <Box>
        <Heading as="h3" size="md" pt={8}>
          Selected Roles
        </Heading>
        {React.Children.toArray(
          (roles).map((role) => (
            <SelectedRoles 
              hasRolesByCategory={hasUserRolesByCategory}
              onChangeTemplatesSelector={onChangeTemplatesSelector}
              role={role}
              checklistsList={checklistsList}
              checklistsOfChecklistRole={checklistsOfChecklistRole}
              templatesList={templatesList}
              templatesOfFormRole={templatesOfFormRole}
              templatesOfTemplateRole={templatesOfTemplateRole}
              foldersOfDocumentRole={foldersOfDocumentRole}
              dashboardsOfDashbordRole={dashboardsOfDashbordRole}
              documentsList={documentsList}
              dashboardsList={dashboardsList}
              templatesPageTitle={templatesPageTitle}
              checklistsPageTitle={checklistsPageTitle}
              register={register}
              assetTemplatesPageTitle={assetTemplatesPageTitle}
              assetTemplatesOfAssetTemplateRole={assetTemplatesOfAssetTemplateRole}
              assetTemplatesList={assetTemplatesList}
              data={data}
            />
            )
          )
        )}
      </Box>
    )}
    </Box>
  )
}

export default UserRolesContent