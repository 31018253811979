import { combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import dependenciesReducer from './reducers/dependencies'
import editTemplateReducer from './reducers/edit_template'
import globalReducer from './reducers/global'
import templatesReducers from './reducers/templates'

const reducer = combineReducers({
  templatesReducers,
  globalReducer,
  editTemplateReducer,
  dependenciesReducer
})

const buildStore = () => {
  const c = createStore
  const store = c(reducer, composeWithDevTools())

  return store
}

const store = buildStore()

export default store
