import { Text } from '@chakra-ui/react'
import React from 'react'
import { v4 as uuid } from 'uuid'

const TemplateGroups = ({ groups }) => {
  return (
    <>
      {!groups?.length && (
        <Text size="xs" color="gray.500">
          No groups
        </Text>
      )}
      {!!groups?.length &&
        groups.map((group, index) => {
          let key = `group-${index}-${uuid()}`
          if (index === groups.length - 1) {
            return (
              <Text key={key} size="xs" color="gray.500">
                {group}
              </Text>
            )
          }
          if (index !== groups.length - 2) {
            return (
              <Text key={key} size="xs" color="gray.500">
                {group},{' '}
              </Text>
            )
          }
          if (group.length - 2 === index) {
            return (
              <Text key={key} size="xs" color="gray.500">
                {group} and
              </Text>
            )
          }
        })}
    </>
  )
}

TemplateGroups.defaultProps = {
  groups: []
}

export default TemplateGroups
