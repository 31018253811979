import { RadioGroup } from '@chakra-ui/react'
import React from 'react'

const SelectionHOC = ({
  children,
  selectionMode,
  onSelectionChange,
  initialSelectedNodes
}) => {
  const onSingleSelection = (id) => {
    onSelectionChange(Number(id))
  }

  return (
    <>
      {(!selectionMode || ['multiple', 'disabled'].includes(selectionMode)) &&
        children}
      {selectionMode === 'single' && (
        <RadioGroup
          onChange={onSingleSelection}
          name="single-selection"
          defaultValue={`${initialSelectedNodes}`}
        >
          {children}
        </RadioGroup>
      )}
    </>
  )
}

export default SelectionHOC
