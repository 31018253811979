export const actionTypes = {
  section: 'section_question',
  logic: 'question_logic',
  question: 'template_question'
}

export const getSelectedContentValue = (type, values) => {
  const value = values[type] ?? ''
  return value
}
