import { Flex } from '@chakra-ui/layout'
import { isEmpty } from 'lodash'
import React from 'react'
import EmptyState from '../../../components/EmptyState'
import TemplatePageBreadcrumb from '../../Template/TemplatePagesBreadcrumb'
import Form from './Form'
import useFillForm from './useFillForm'

const FillForm = ({ data }) => {
  const {
    geolocation,
    shouldRenderQRCodeButton,
    templateDetails,
    templateStructure,
    pageTitle,
    singularTitle,
    template
  } = useFillForm({ data })

  if (isEmpty(templateDetails) || !templateDetails) {
    return <EmptyState text={`This ${singularTitle} is empty`} />
  }

  return (
    <>
      <TemplatePageBreadcrumb
        templateDetails={templateDetails}
        templateId={template.id}
        routeName="Fill Form"
        ariaLabel={`${pageTitle} list ${singularTitle} options ${template.id}`}
        pageTitle={pageTitle}
        singularTitle={singularTitle}
      />
      <Flex w="100%" justify="center" p={4}>
        <Form
          template={templateDetails}
          geolocation={geolocation}
          shouldRenderQRCodeButton={shouldRenderQRCodeButton}
          templateStructure={templateStructure}
        />
      </Flex>
    </>
  )
}

export default FillForm
