import { setFormErrors } from 'helpers'
import { noop } from 'lodash'
import { useState } from 'react'
import { toast } from 'react-toastify'
import Notification from 'services/Notification'
import OlingaAPI from 'services/OlingaAPI'

const useExportAssets = () => {
  const [isLoading, setIsLoading] = useState(false)
  const handleExport = async (id) => {
    setIsLoading(true)
    try {
      const response = await OlingaAPI.exportAssetsFromAssetTemplate(id)
      if (response.status === 200) {
        const { message } = response?.data || {}
        toast.success(message)
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    handleExport,
    isLoading
  }
}

export default useExportAssets
