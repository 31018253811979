import React from 'react'
import BasePage from '../../../pages/BasePage'
import ChecklistUsersTasks from '../../../pages/Checklists/ChecklistUsersTasks'
import OlingaAPI from '../../../services/OlingaAPI'

const ChecklistUsersTasksPage = ({ id, itemId }) => {
  return (
    <BasePage
      id={id}
      resourceName="checklistData"
      fetchWithoutId
      otherParams={{ id, itemId }}
      fetchCallback={{
        checklist: {
          callback: () => OlingaAPI.getChecklist(id),
          params: {}
        }
      }}
    >
      <ChecklistUsersTasks />
    </BasePage>
  )
}

export default ChecklistUsersTasksPage
