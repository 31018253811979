import { Box, Divider, Heading } from '@chakra-ui/react'
import React from 'react'

const TermsAndConditionsContent = () => {
  return (
    <main>
      <Heading as="h2" size="md">
        Terms and Conditions
      </Heading>
      <Divider my={4} />
      <Box
        sx={{
          '& h4': {
            fontSize: 'md',
            lineHeight: 'md',
            color: 'orange.500',
            fontWeight: 'bold',
            mb: 2
          },
          '& p': {
            fontSize: 'md',
            lineHeight: 'md',
            mb: 2,
            ml: { base: 2, md: 4 }
          }
        }}
      >
        <h4>1. Overview</h4>
        <p>
          Please read this agreement before accessing or using the information
          and services available through the LUIGI.JOBS website
          (&quot;Site&quot;). By accessing or using the Site, this signifies you
          have read, understood and agree to be bound by the terms and
          conditions below.
        </p>
        <h4>2. Copyright, trademarks and ownership rights</h4>
        <p>
          Copyright in the material and trademarks on this Site are owned by
          Buggaluggs Pty Ltd unless otherwise indicated. You agree not to
          infringe on any intellectual property rights owned by Buggaluggs Pty
          Ltd.
        </p>
        <p>
          Luigi Jobs does not grant you any other rights whatsoever in relation
          to the Website or the Services. All other rights are expressly
          reserved by Luigi Jobs. Luigi Jobs retains all rights, title and
          interest in and to the Website and all related Services.
        </p>
        <h4>3. Information for personal, non-commercial use only</h4>
        <p>
          You agree that information contained on this Site is for personal use
          only and may not be sold, redistributed or used for any commercial
          purpose. You may not modify, copy, reproduce, republish, upload, post,
          transmit or distribute in any way any material from this Site
          including code and software. You must not use this Site for any
          purpose that is unlawful or prohibited by these terms of use. You may
          not use data mining, robots, screen scraping, or similar automated
          data gathering, extraction or publication tools on this Site
          (including without limitation for the purposes of establishing,
          maintaining, advancing or reproducing information contained on our
          Site on your own website or in any other publication), except with Our
          prior written consent.
        </p>
        <h4>4. Confidential information and use of your account</h4>
        <p>
          You have the sole responsibility for protecting the confidentiality of
          your password and/or email address. Use of your password by any other
          person may result in the immediate cancellation of the Services.
        </p>
        <p>
          Any use of your registration information by any other person, or third
          parties, is strictly prohibited. You agree to immediately notify Luigi
          Jobs of any unauthorised use of your password, email address or any
          breach of security of which you have become aware.
        </p>
        <h4>5. Information does not represent professional advice</h4>
        <p>
          Buggaluggs Pty Ltd does not warrant the accuracy or completeness of
          any information presented on this Site. The information presented on
          this Site offers general guidance using third party data and
          Buggaluggs Pty Ltd will under no circumstances not be liable to you or
          anyone else in reliance upon any information contained on or omitted
          from the Site. It is solely your responsibility to evaluate the
          accuracy, completeness and usefulness of all opinions, advice,
          services, recommendations and other information provided through the
          Site.
        </p>
        <h4>6. No guarantees of a job</h4>
        <p>
          Buggaluggs Pty Ltd provides no warranty of service and cannot
          guarantee that you will be matched to and/or offered a job through the
          Site. Buggaluggs Pty Ltd uses a unique algorithm to undertake all job
          matching based on the advertisers’ specified requirements and
          registered users’ listed information. Any offers of employment is at
          the discretion of the advertiser and Buggaluggs Pty Ltd takes no
          responsibility for any decisions made or not made by the advertiser.
        </p>
        <h4>7. No liability for any employment or job offer</h4>
        <p>
          By registering for this Site, you understand that Buggaluggs Pty Ltd
          is not your employer or a recruitment agency that is actively seeking
          to find you work. This Site is a job matching site that connects
          workers with prospective employers and/or advertised jobs placed on
          this Site.
        </p>
        <p>
          Buggaluggs Pty Ltd is not liable or responsible and makes no
          warranties for any jobs advertised and/or offered to you by any
          advertisers. You must undertake your own investigations and enquiries
          of any prospective employers and/or job offers prior to accepting any
          employment offers.
        </p>
        <h4>8. No guarantees of job vacancy availability</h4>
        <p>
          Whilst Buggaluggs Pty Ltd will take efforts to ensure the information
          collected on the Site is reasonably reliable and valid, it gives no
          guarantee to you of the availability of any particular job advertised
          and/or matched to you on the Site and will not be liable to you should
          an advertiser have filled the vacancy at any time prior to removal of
          the advertisement from the Site.
        </p>
        <h4>9. No guarantees of a job vacancy being filled</h4>
        <p>
          Whilst Buggaluggs Pty Ltd will take efforts to ensure the information
          collected on the Site is reasonably reliable and valid, it gives no
          guarantee to you of any vacancies being advertised and/or matched to
          your business being filled and will therefore not be liable for
          unfilled vacancies advertised on the Site.
        </p>
        <h4>10. No guarantees as to the service</h4>
        <p>
          Buggaluggs Pty Ltd provides no warranty of service and cannot
          guarantee that the Site will be uninterrupted or error-free or that
          defects in the service will be corrected.
        </p>
        <p>
          Pursuant to law, Buggaluggs Pty Ltd also disclaims all warranties,
          whether express or implied.
        </p>
        <h4>11. No guarantees as to security of your information</h4>
        <p>
          Use of the Site is at your own risk. Everything on the Site is
          provided to you &quot;as is&quot; and &quot;as available&quot; without
          warranty or condition of any kind. While Buggaluggs Pty Ltd takes all
          due care in ensuring the privacy and integrity of the information you
          provide, the possibility exists that this information could be
          unlawfully observed by a third party while in transit over the
          internet. Buggaluggs Pty Ltd disclaims all liability to you to the
          greatest extent possible pursuant to law should this occur. Buggaluggs
          Pty Ltd will not sell profile information form the Site to
          third-parties. Buggaluggs Pty Ltd will only share the potential
          employee or business’ public profile with other users of the Site.
        </p>
        <h4>12. No misrepresentations allowed</h4>
        <p>
          You agree that it is a condition on your use of the Site and of any
          other services provided by Buggaluggs Pty Ltd or through the Site that
          you will not through any act or omission (including but not limited to
          creating a profile on the Site) mislead or deceive others.
        </p>
        <h4>13. Not liable for issues arising from the Terms</h4>
        <p>
          Under no circumstances is Buggaluggs Pty Ltd liable for any lost
          profits, revenues, information, or data, or consequential, special,
          indirect, exemplary, punitive, or incidental damages arising out of or
          related to these Terms. This applies even if Buggaluggs Pty Ltd was
          advised of the possibility of such damages.
        </p>
        <h4>14. Data retention</h4>
        <p>
          In the event of disabling or deleting your account, your data will
          still be retained for algorithm development. Most of the Personal
          Information is or will be stored in client files which will be kept by
          us for a minimum of 7 years.
        </p>
        <h4>15. General provisions</h4>
        <p>
          If any part of this agreement is held invalid that part shall be
          severed from this agreement and the remainder of this agreement will
          continue to be valid and enforceable. Termination of this agreement
          will not end those provisions that are capable of surviving the ending
          of this agreement. The laws governing this agreement will be the laws
          in New South Wales, Australia and you irrevocably submit to the
          exclusive jurisdiction of its Courts and its Appellate Courts and the
          jurisdiction of the Federal Court of Australia sitting in New South
          Wales.
        </p>
        <p>
          If terms are updated at any point, you will be given the chance to
          review them and once accepted will be bound to the new terms. If you
          have violated the terms and policies or have created a legal risk,
          Buggaluggs Pty Ltd may need to disable your Site account although your
          data will still be retained for algorithm development.
        </p>
        <h4>16. Changes to Terms and Conditions</h4>
        <p>
          Luigi Jobs reserves the right to review and change any of the Terms by
          updating this page at its sole discretion. When Luigi Jobs updates the
          Terms, it will use reasonable endeavours to provide you with notice of
          updates to the Terms. Any changes to the Terms take immediate effect
          from the date of their publication. Before you continue, we recommend
          you keep a copy of the Terms for your records.
        </p>
      </Box>
    </main>
  )
}

export default TermsAndConditionsContent
