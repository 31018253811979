import { Button, ButtonGroup, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { isEmpty, isObject } from 'lodash'
import React, { useState } from 'react'

const ActionConfirmSelector = ({
  message,
  register,
  onConfirm,
  isRequired,
  formKey,
  placeholder,
  options,
  value
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [newValue, setNewValue] = useState({})

  const onSelectChange = (selectedValue) => {
    if (value?.value === selectedValue?.value) return
    if (isObject(value) && !isEmpty(value)) {
      setIsOpen(true)
      setNewValue(selectedValue)
      return
    }

    onSelectConfirm(selectedValue)
  }

  const onSelectConfirm = (selectedValue) => {
    onConfirm(selectedValue)
    setIsOpen(false)
  }

  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        placement='right'
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <span>
            <Select
              {...register(formKey, { required: isRequired })}
              placeholder={placeholder}
              options={options}
              value={value}
              onChange={onSelectChange}
            />
          </span>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader fontWeight='semibold'>Confirmation</PopoverHeader>
          <PopoverArrow />
          <PopoverBody>
            {message}
          </PopoverBody>
          <PopoverFooter display='flex' justifyContent='flex-end'>
            <ButtonGroup size='sm'>
              <Button variant='outline' onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button colorScheme='red' onClick={() => onSelectConfirm(newValue)}>Confirm</Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </>
  )
}

ActionConfirmSelector.defaultProps = {
  message: 'By changing this field, some form fields will be cleared. Are you sure?'
}

export default ActionConfirmSelector