import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Flex, IconButton } from '@chakra-ui/react'
import React from 'react'

const RightAdornment = ({ foldersStates, id, name, getFolderAdornment }) => {
  const Icon = foldersStates[id].isOpened ? (
    <ChevronUpIcon />
  ) : (
    <ChevronDownIcon />
  )
  return (
    <Flex align="center">
      {getFolderAdornment(id)}
      <IconButton
        size="sm"
        colorScheme="blue"
        icon={Icon}
        aria-label={`Open folder ${name}-${id}`}
        variant="outline"
        ml={4}
      />
    </Flex>
  )
}

RightAdornment.defaultProps = {
  foldersStates: {},
  id: null,
  name: '',
  getFolderAdornment: () => null
}

export default RightAdornment
