import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react'
import { debounce } from 'lodash'
import React, { useEffect } from 'react'

const ItemOption = ({
  register,
  setFocus,
  fieldKey,
  index,
  focusedFieldIndex
}) => {
  useEffect(() => {
    if (index === focusedFieldIndex) {
      debounce(() => {
        setFocus(`${fieldKey}.${index}.name`)
      }, 100)()
    }
  }, [index, focusedFieldIndex, setFocus, fieldKey])

  return (
    <Flex gap={4} align="center">
      <FormControl>
        <FormLabel>Name</FormLabel>
        <Input {...register(`${fieldKey}.${index}.name`, { required: true })} />
      </FormControl>
    </Flex>
  )
}

export default ItemOption
