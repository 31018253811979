import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import { capitalize, flatMap } from 'lodash'
import React from 'react'
import { questionsTransformer } from '../BaseStructure'

const Labels = () => {
  return (
    <Flex
      w="100%"
      rowGap={4}
      columnGap={2}
      flexWrap="wrap"
      justifyContent="flex-end"
    >
      <Text>
        <b>Labels: </b>
      </Text>
      {flatMap(questionsTransformer, ({ color, type }) => {
        return (
          <HStack spacing={2} key={type}>
            <Box h="15px" w="15px" bg={color} borderRadius={4} />
            <Text>{capitalize(type)}</Text>
          </HStack>
        )
      })}
    </Flex>
  )
}

export default Labels
