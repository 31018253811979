import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  BreadcrumbItem,
  BreadcrumbLink,
  Breadcrumb as ChakraBreadcrumb,
  Divider,
  Flex,
  Menu,
  MenuItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getDashboardUrlParams, getRouteUrl } from '../../helpers'
import MaterialIcon from '../MaterialIcon'
import { Icon, LinkButton, Wrapper } from './styles'

const getOverlayMenu = (options) => (
  <Menu>
    {options.map((option, idx) => (
      <MenuItem key={idx} data-testid={option.title}>
        {option?.iconName && <Icon><MaterialIcon icon={option.iconName} /></Icon>}
        {option.url && (
          <Link onClick={option.onClick || (() => ({}))} to={option.url}>
            {option.title}
          </Link>
        )}
      </MenuItem>
    ))}
  </Menu>
)

const Breadcrumb = ({ routeTree, nativeHomeRoute }) => {
  const user = useSelector(({ globalReducer }) => globalReducer.user)

  const { dashboardId, userType } = useMemo(() => {
    const { dashboardId, userType } = getDashboardUrlParams(user)

    return { dashboardId, userType }
  }, [user])

  const getContent = (item) => {
    if (item.url) {
      return <Link to={item.url}>{item.title}</Link>
    }
    if (Array.isArray(item.options)) {
      return (
        <Popover>
          <PopoverTrigger>
            <LinkButton rightIcon={<ChevronDownIcon />}>
              {item.title}
            </LinkButton>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader>{item.title}</PopoverHeader>
            <PopoverBody>{getOverlayMenu(item.options)}</PopoverBody>
          </PopoverContent>
        </Popover>
      )
    }
    if (item.options) {
      return (
        <Flex align="center">
          <BreadcrumbLink mr={2}>{item.title}</BreadcrumbLink>
          {item.options}
        </Flex>
      )
    }
    return <BreadcrumbLink>{item.title}</BreadcrumbLink>
  }

  return (
    <Wrapper>
      <ChakraBreadcrumb pb={2}>
        {nativeHomeRoute && (
          <BreadcrumbItem data-testid='Home'>
            <Link to={getRouteUrl(dashboardId, { userType })}>Home</Link>
          </BreadcrumbItem>
        )}
        {routeTree &&
          routeTree.map((item, index) => (
            <BreadcrumbItem color="gray.400" key={index} data-testid={item?.title}>
              {getContent(item)}
            </BreadcrumbItem>
          ))}
      </ChakraBreadcrumb>
      <Divider />
    </Wrapper>
  )
}

Breadcrumb.defaultProps = {
  nativeHomeRoute: true
}

export default Breadcrumb
