import { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from '../../../hooks'

const getInitialRoute = (location, query) => {
  if (query?.reset_password_token) {
    return 'resetPassword'
  }
  if (
    location.pathname.includes('/users/sign_in') ||
    location.pathname === '/'
  ) {
    return 'signIn'
  }
  if (location.pathname.includes('/users/password/new')) {
    return 'newPassword'
  }
  return 'signIn'
}

const useUnsignedHome = ({ usersProfile, defaultPersonableType }) => {
  const location = useLocation()
  const query = useQuery()

  const [currentPage, setCurrentPage] = useState(
    getInitialRoute(location, query)
  )

  const onGoBack = useCallback(() => {
    window.history.pushState('', 'Olinga', '/')
    setCurrentPage('signIn')
  }, [])

  const props = useMemo(() => {
    switch (currentPage) {
      case 'signIn':
        return {
          usersProfile,
          setCurrentPage,
          defaultPersonableType
        }
      case 'newPassword':
        return {
          onSuccess: onGoBack,
          usersProfile
        }
      case 'resetPassword':
        return {
          token: query?.reset_password_token
        }
    }
  }, [
    currentPage,
    defaultPersonableType,
    onGoBack,
    usersProfile,
    query?.reset_password_token
  ])

  return {
    currentPage,
    props,
    onGoBack,
    displayLogo: ['newPassword', 'resetPassword'].includes(currentPage)
  }
}

export default useUnsignedHome
