import { useLocalStorage } from '../../../../hooks'

const predefinedValues = {
  question_is_active: true,
  logic_is_active: true,
  instruction_is_active: true,
  question_default_value: '',
  question_default_question_type: 'boolean',
  section_default_value: '',
  logic_default_value: '',
  logic_default_question_type: 'boolean',
  instruction_default_value: '',
  logic_default_condition: ''
}

const useTemplateEditorSettings = () => {
  const { data, setData } = useLocalStorage('defaults')

  const getDefaultQuestionField = () => {
    const active = data?.question_is_active || true
    const text = data?.question_default_value || ''
    const questionTypeId = data?.question_default_question_type || null
    const questionType = questionTypeId || 'counter'
    return {
      _id: null,
      _key: 'new',
      text,
      question_type: questionType,
      order: null,
      compulsory: null,
      active,
      main: false,
      notify: false,
      questions: [],
      origin: 'template_questions_attributes',
      template_question_options_attributes: [],
      template_question_options_attributes_deleted: [],
      alert_triggers_users_attributes_deleted: [],
      alert_trigger_attributes: {
        alert_triggers_users_attributes: []
      }
    }
  }

  const getDefaultSectionField = () => {
    const name = data?.section_default_value || ''
    const repeat = data?.section_repeat || false
    return {
      _id: null,
      name: name || '',
      order: null,
      repeat: repeat || false,
      origin: 'section_questions_attributes',
      questions: []
    }
  }

  const getDefaultLogicField = (shouldWrapLogicInsideQuestion = false) => {
    const active = data?.logic_is_active || true
    const value = data?.logic_default_value || null
    const question_default_question_type =
      data?.logic_default_question_type || null
    const question_logic_option_id = data?.logic_default_condition || null
    const question = getDefaultQuestionField()
    const logic = {
      _id: null,
      value: value || '',
      question_logic_option_id: question_logic_option_id,
      questions: [],
      template_question_options_attributes_deleted: [],
      origin: 'question_logics_attributes'
    }
    if (shouldWrapLogicInsideQuestion) {
      return {
        ...question,
        active,
        question_type: question_default_question_type || 'counter',
        questions: [logic]
      }
    }
    return logic
  }

  const getDefaultInstructionField = () => {
    const active = data?.instruction_is_active || true
    const text = data?.instruction_default_value || ''
    const question = getDefaultQuestionField()
    return {
      ...question,
      active,
      text,
      question_type: 'instruction'
    }
  }

  const saveSettings = (newData) => {
    setData({ defaults: newData })
  }

  return {
    getDefaultInstructionField,
    getDefaultLogicField,
    getDefaultQuestionField,
    getDefaultSectionField,
    saveSettings,
    data: data || predefinedValues
  }
}

export default useTemplateEditorSettings
