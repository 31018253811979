import { ChevronDownIcon } from '@chakra-ui/icons'
import React, { useMemo } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import { getRouteUrl } from '../../../../helpers'
import ActionKebab from '../../ActionKebab'

const ActionDetailsBreadcrumb = ({ action, pageTitle }) => {
  const options = useMemo(() => {
    const opts = [
      {
        title: pageTitle,
        url: getRouteUrl('actions')
      }
    ]
    if (action?.title) {
      opts.push({
        title: `${action?.title}`,
        options: (
          <ActionKebab
            id={action.id}
            size="xs"
            hasBorder={false}
            icon={<ChevronDownIcon />}
          />
        )
      })
      opts.push({
        title: `Show action ${action.id}`
      })
    }
    return opts
  }, [action.id, action?.title, pageTitle])
  return <Breadcrumb routeTree={options} />
}

export default ActionDetailsBreadcrumb
