import { capitalize } from 'lodash'
import { useCallback, useEffect, useReducer, useRef } from 'react'
import { useTemplateEditorContext } from '../../../../../contexts'
import { actions, formActionsReducer, initialState } from './formActionsReducer'

const {
  SET_ELEMENT_TYPE,
  SET_ATTRIBUTE_DISABLE,
  SET_IS_REMOVABLE,
  SET_POSITION
} = actions

const useFormActionsPositioning = ({ template }) => {
  const { focusedField } = useTemplateEditorContext()

  const ref = useRef(null)

  const [{ position, isRemovable, elementType, attributeDisabled }, dispatch] =
    useReducer(formActionsReducer, initialState)

  const getFocusedFieldPosition = useCallback(
    (focusedField) => {
      if (focusedField) {
        const element = document.querySelector(
          `[data-position="${focusedField}"]`
        )
        if (element) {
          const isElementRemovable =
            element.getAttribute('data-removable') === 'true'
          const attributeDisabled = element.getAttribute('data-disabled')
          const distance = element.getBoundingClientRect().top
          const type = element.getAttribute('data-type')

          dispatch({
            type: SET_IS_REMOVABLE,
            payload: template?.id || isElementRemovable
          })
          dispatch({
            type: SET_ATTRIBUTE_DISABLE,
            payload: attributeDisabled
          })
          dispatch({
            type: SET_POSITION,
            payload: distance
          })
          if (type) {
            dispatch({
              type: SET_ELEMENT_TYPE,
              payload: capitalize(type)
            })
          }
        }
      } else {
        dispatch({
          type: SET_POSITION,
          payload: 96
        })
      }
    },
    [template?.id]
  )

  useEffect(() => {
    getFocusedFieldPosition(focusedField)
  }, [getFocusedFieldPosition, focusedField])

  return {
    position,
    isRemovable,
    elementType,
    attributeDisabled,
    ref
  }
}

export default useFormActionsPositioning
