import React from 'react'
import BasePage from '../../../pages/BasePage'
import ChecklistTasks from '../../../pages/Checklists/ChecklistsTasks'
import OlingaAPI from '../../../services/OlingaAPI'

const ChecklistsTasks = ({ id }) => {
  return (
    <BasePage
      id={id}
      resourceName="data"
      fetchWithoutId
      fetchCallback={{
        checklist: {
          callback: () => OlingaAPI.getChecklist(id)
        },
        enumerators: {
          callback: OlingaAPI.getChecklistEnums,
          params: {}
        }
      }}
    >
      <ChecklistTasks />
    </BasePage>
  )
}

export default ChecklistsTasks
