import { useEffect, useRef, useState } from 'react'
import { getUniqChakraColors } from './helpers'

const useColors = (amount) => {
  const [colors, setColors] = useState([])
  const colorsRef = useRef([])

  useEffect(() => {
    const newColors = getUniqChakraColors(1, colorsRef.current)
    setColors(() => {
      colorsRef.current = newColors
      return newColors
    })
  }, [amount])

  return [colors]
}

export default useColors
