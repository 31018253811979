import { EditIcon } from '@chakra-ui/icons'
import { Flex, IconButton } from '@chakra-ui/react'
import DeleteButton from 'components/DeleteButton'
import { getRouteUrl } from 'helpers/admin'
import React from 'react'
import { Link } from 'react-router-dom'

const EditAssetDefaultFieldButton = ({ id, onDelete }) => (
  <>
    <Flex gap={4} align="center">
      <Link to={getRouteUrl('editAssetDefaultFields', { id })}>
        <IconButton
          icon={<EditIcon />}
          colorScheme="blue"
          size="sm"
          variant="outline"
          aria-label={`Edit asset ${id}`}
        />
      </Link>
      <DeleteButton
        index={id}
        onDelete={onDelete}
        alertTitle="Delete default field"
        alertBody="This action cannot be undone."
        continueLabel="Yes, remove it!"
        size="sm"
        variant="outline"
        borderRadius={6}
      />
    </Flex>
  </>
)

export default EditAssetDefaultFieldButton
