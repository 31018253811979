import {
  Checkbox,
  CheckboxGroup as ChakraCheckboxGroup
} from '@chakra-ui/checkbox'
import { Stack } from '@chakra-ui/layout'
import PropTypes from 'prop-types'
import React from 'react'

const CheckboxGroup = ({ options, value, onChange, isInvalid, size }) => (
  <ChakraCheckboxGroup defaultValue={value} onChange={onChange} size={size}>
    <Stack mt={1}>
      {options.map((opt, idx) => (
        <Checkbox key={idx} value={opt.value} isInvalid={isInvalid} mb={0}>
          {opt.label}
        </Checkbox>
      ))}
    </Stack>
  </ChakraCheckboxGroup>
)

CheckboxGroup.defaultProps = {
  size: 'md',
  isInvalid: false
}

CheckboxGroup.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  isInvalid: PropTypes.bool
}

export default CheckboxGroup
