import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Select as ChakraSelect,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Switch,
  Text,
  Textarea
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Select } from 'chakra-react-select'
import {
  capitalize,
  cloneDeep,
  get,
  isArray,
  isEmpty,
  isEqual,
  noop
} from 'lodash'
import React, { forwardRef, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import FormErrorMessage from '../../../../../components/FormErrorMessage'
import InfoTip from '../../../../../components/Infotip'
import ListBuilder from '../../../../../components/ListBuilder'
import Modal from '../../../../../components/Modal'
import ToggleButtons from '../../../../../components/ToggleValueModal/ToggleValueModalBody'
import Notification from '../../../../../services/Notification'
import QuarterlyChecklistRepeat from './QuarterlyChecklistRepeat'
import { alertInfoMessages, schema } from './helpers'

const ChecklistModal = forwardRef(
  (
    {
      defaultValues,
      frequencies,
      onChange,
      limitForCompletionLimit,
      displayCompulsory
    },
    ref
  ) => {
    const {
      handleSubmit,
      setValue,
      register,
      watch,
      reset,
      formState: { errors }
    } = useForm({
      defaultValues,
      resolver: yupResolver(schema)
    })
    const context = defaultValues?.context ?? ''

    useEffect(() => {
      reset(defaultValues)
    }, [reset, defaultValues])

    const [frequency, repeatValues, completionLimit] = watch([
      'frequency',
      'repeat_values',
      'completion_limit'
    ])

    const scrollToModalEnd = () => {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
    }

    const onRepeatValueChanges = (newValue) => {
      if (['monthly'].includes(frequency.value)) {
        setValue('repeat_values', newValue)
        return
      }
      if (isArray(repeatValues)) {
        const clonedValues = cloneDeep(repeatValues)
        const itemIndex = clonedValues.findIndex((item) =>
          isEqual(item, newValue)
        )
        if (itemIndex >= 0) {
          clonedValues.splice(itemIndex, 1)
        } else {
          clonedValues.push(newValue)
        }
        setValue(`repeat_values`, clonedValues)
      } else {
        setValue(`repeat_values`, [newValue])
      }

      if (['quarterly'].includes(frequency.value)) {
        scrollToModalEnd()
      }
    }

    const scrollRef = useRef()

    const onRepeatsCollectionChange = (updatedCollection) => {
      setValue('repeat_values', updatedCollection)
    }

    const onSubmit = (form) => {
      if (
        ['weekly', 'fortnightly'].includes(frequency.value) &&
        isEmpty(form?.repeat_values)
      ) {
        return Notification({
          text: 'At least one repeat day is required',
          icon: 'error'
        })
      }
      onChange(form)
      ref.current.close()
    }

    const onChangeCompletionLimit = ({ target: { checked } }) => {
      if (!checked) {
        setValue('completion_limit', 1)
      } else {
        setValue('completion_limit', 0)
      }
    }

    const onChangeCompletionValue = (numberValue) => {
      if (!numberValue) {
        setValue('completion_limit', 1)
        return
      }
      setValue('completion_limit', numberValue)
    }

    return (
      <Modal
        ref={ref}
        shouldInjectButton={false}
        header={`${capitalize(context)} task`}
        size="4xl"
        body={
          <form>
            <Stack
              position="relative"
              w="100%"
              gap={4}
              pb={
                ['monthly', 'quarterly'].includes(frequency.value) ? '200px' : 0
              }
            >
              <Flex w="100%" gap={4} align="center" flexDir={['column', 'row']}>
                <FormControl flex={4} w="100%">
                  <FormLabel htmlFor="title">Task</FormLabel>
                  <Box>
                    <Input
                      placeholder="Write a task name"
                      id="title"
                      {...register('title')}
                      isInvalid={!!errors?.title?.message}
                    />
                    <FormErrorMessage message={errors?.title?.message} />
                  </Box>
                 
                </FormControl>
                <FormControl mt={[0, 8]} display="flex" flex={1} flexDir="row">
                  <FormLabel mb="0" cursor="pointer" htmlFor="active">
                    Active
                  </FormLabel>
                  <Switch mb="0" id="active" {...register('active')} />
                </FormControl>
                {displayCompulsory && (
                  <FormControl
                    mt={[0, 8]}
                    display="flex"
                    flex={1}
                    flexDir="row"
                  >
                    <FormLabel mb="0" cursor="pointer" htmlFor="compulsory">
                      Compulsory
                    </FormLabel>
                    <Switch
                      mb="0"
                      id="compulsory"
                      {...register('compulsory')}
                    />
                  </FormControl>
                )}
              </Flex>
              <FormControl>
                <FormLabel htmlFor="name">Task Description</FormLabel>
                <Textarea
                  placeholder="What is this task about ?"
                  id="description"
                  {...register('description')}
                  isInvalid={!!errors?.description?.message}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="frequency">Frequency</FormLabel>
                <Select
                  options={frequencies}
                  {...register(`frequency`)}
                  onChange={noop}
                  defaultValue={frequency}
                  hideSelectedOptions
                  placeholder="Select frequency"
                  isDisabled={true}
                />
              </FormControl>
              <FormControl>
                <FormLabel display="flex" align="center" gap={2}>
                  <Text>Completion limit</Text>
                  <InfoTip label="It sets the maximum number of times a task can be marked as done per employee, defaulting to unlimited" />
                </FormLabel>
                <Stack gap={2}>
                  <Flex minH="50px" align="center" gap={4}>
                    <Switch
                      isChecked={completionLimit === 0}
                      onChange={onChangeCompletionLimit}
                      mb={0}
                    >
                      {completionLimit > 0 ? '' : 'Unlimited'}
                    </Switch>
                    {completionLimit > 0 && (
                      <NumberInput
                        onChange={onChangeCompletionValue}
                        value={completionLimit}
                        min={1}
                        max={limitForCompletionLimit}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    )}
                  </Flex>
                  {alertInfoMessages[frequency?.value] && (
                    <Alert
                      size="sm"
                      mt="0!important"
                      borderRadius={4}
                      status="info"
                    >
                      <AlertIcon />
                      {alertInfoMessages[frequency?.value]}
                    </Alert>
                  )}
                </Stack>
              </FormControl>
              {['weekly', 'fortnightly'].includes(frequency.value) && (
                <Stack gap={4}>
                  {!isEmpty(frequency?.options) &&
                    ['weekly', 'fortnightly'].includes(frequency.value) && (
                      <FormControl>
                        <FormLabel>Repeats on</FormLabel>
                        <ToggleButtons.Multiple
                          options={get(frequency, `options.week_days`)}
                          value={repeatValues ?? []}
                          onChange={onRepeatValueChanges}
                          attributeKey="value"
                          columns={[
                            2,
                            2,
                            get(frequency, `options.week_days`).length
                          ]}
                          colorScheme="blue"
                        />
                      </FormControl>
                    )}
                </Stack>
              )}
              {['monthly'].includes(frequency.value) && (
                <Stack>
                  <FormControl display="flex" alignItems="center" gap={4}>
                    <FormLabel display="flex" align="center" gap={2}>
                      <Text>Repeats on day</Text>
                      <InfoTip label="If the month ends on the 28th and the selected day exceeds this, the system will default to the 28th" />
                    </FormLabel>
                    <ChakraSelect
                      onChange={({ target: { value } }) =>
                        onRepeatValueChanges({ month_day: Number(value) })
                      }
                      w="80px"
                      value={repeatValues?.month_day}
                    >
                      {Array.from({ length: 31 }).map((_, day) => (
                        <option value={day + 1} key={day}>
                          {day + 1}
                        </option>
                      ))}
                    </ChakraSelect>
                  </FormControl>
                </Stack>
              )}
              {['quarterly'].includes(frequency.value) && (
                <Stack>
                  <FormControl>
                    <FormLabel>Repeats on day</FormLabel>
                    <ListBuilder
                      list={repeatValues ?? []}
                      resourceName="Quarterly repeats"
                      onChange={onRepeatsCollectionChange}
                      ItemComponent={QuarterlyChecklistRepeat}
                      onAddItem={onRepeatValueChanges}
                      onRemoveItem={onRepeatsCollectionChange}
                      idAttribute="_id"
                      isDisabled={!isEmpty(repeatValues)}
                      itemProps={{
                        attributeKey: 'repeat_values',
                        setValue,
                        watch,
                        scrollToModalEnd
                      }}
                      deleteButtonStyles={() => ({ position: 'relative' })}
                      customRemoveItemAction={(index, _, fallback) =>
                        fallback(index)
                      }
                    />
                  </FormControl>
                </Stack>
              )}
              <Box
                ref={scrollRef}
                position="absolute"
                left="0"
                bottom="-1000px"
              />
            </Stack>
          </form>
        }
        footer={
          <Button onClick={handleSubmit(onSubmit)} colorScheme="blue">
            {capitalize(context)} task
          </Button>
        }
      />
    )
  }
)

ChecklistModal.displayName = 'ChecklistModal'

ChecklistModal.defaultProps = {
  context: '',
  displayCompulsory: false
}

export default ChecklistModal
