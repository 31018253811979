import { useConst, useMediaQuery } from '@chakra-ui/react'
import { get, isEmpty, noop } from 'lodash'
import { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { getRouteUrl, setFormErrors } from '../../../../helpers'
import { usePageProperties } from '../../../../hooks'
import Notification from '../../../../services/Notification'
import OlingaAPI from '../../../../services/OlingaAPI'
import {
  dispatchRerender,
  initialKeyReducerState,
  keyReducer
} from '../fieldRenderKeyReducer'
import {
  defaultContextValue,
  editQuestionsAdapter,
  findChildrenByCondition,
  formatDataToSelectOptions,
  formatDataToSelectOptionsWithCustomValue,
  selectedQuestionAttributes,
  submitDataAdapter
} from '../helpers'

const defaultValues = {
  frequency: '',
  query_range: '',
  emails: [],
  reportType: [],
  template_questions: []
}

const useReportsEditor = ({ data }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formFieldKey, setFormFieldKey] = useState({ key: '', index: null })
  const [{ range: rangeFieldKey }, dispatch] = useReducer(
    keyReducer,
    initialKeyReducerState
  )

  const { pageTitle, singularTitle } = usePageProperties({
    pageName: 'template_reports'
  })

  const {
    shouldRender: shouldRenderScheduledReports,
    shouldCreate: shouldModifyScheduledReports
  } = usePageProperties({
    pageName: 'scheduled_reports'
  })

  const isReadOnly =
    shouldRenderScheduledReports && !shouldModifyScheduledReports

  const formFieldKeyPrefix = useConst(() => 'template_questions.')
  const ref = useRef({ open: noop, close: noop })
  const [isMobile] = useMediaQuery('(max-width: 799px)')
  const {
    frequencyRanges,
    frequency,
    counterType,
    reportType,
    contexts,
    headerQuestions,
    bodyQuestions,
    id,
    fields,
    templateId,
    templateName,
    structure
  } = useMemo(() => {
    if (data?.template) {
      const { report, template, enumerators } = data
      const reportData = {
        frequency: null,
        counterType: null,
        reportType: null,
        context: null,
        id: null,
        fields: null
      }
      if (report?.attributes) {
        reportData.id = get(report.attributes, 'id')
        reportData.fields = get(report.attributes, 'fields')
        reportData.reportType = get(report.attributes, 'reportType')
        reportData.frequency = get(report.attributes, 'fields.frequency')
      }
      const { frequency, id, fields } = reportData
      const {
        children,
        userPermissions,
        id: templateId,
        name: templateName
      } = template.attributes
      const headerQuestions = findChildrenByCondition(
        children,
        (child) =>
          ['question'].includes(child?.type) &&
          ['radio_btn', 'select'].includes(child?.question_type)
      )
      const bodyQuestions = findChildrenByCondition(
        children,
        (child) =>
          ['question'].includes(child?.type) &&
          !['instruction'].includes(child?.question_type)
      )
      return {
        frequency: enumerators?.frequency?.find(
          ({ value }) => value === frequency
        ),
        userPermissions,
        id,
        fields,
        bodyQuestions: formatDataToSelectOptionsWithCustomValue(
          bodyQuestions,
          'data',
          selectedQuestionAttributes,
          'text',
          'id'
        ),
        headerQuestions: formatDataToSelectOptions(
          headerQuestions,
          'text',
          selectedQuestionAttributes
        ),
        templateId,
        templateName,
        contexts: data.enumerators.context,
        structure: children
      }
    }
    return {}
  }, [data])

  const history = useHistory()

  const type = history.location.pathname.match('scheduled')
    ? 'scheduled'
    : history.location.pathname.match('summary')
    ? 'summary'
    : ''

  const methods = useForm({
    defaultValues
  })

  const [
    templateQuestions,
    formFrequency,
    queryRange,
    title,
    emails,
    withZero
  ] = methods.watch([
    'template_questions',
    'frequency',
    'query_range',
    'title',
    'emails',
    'with_zero'
  ])

  useEffect(() => {
    const { reset } = methods
    if (!!id && data?.template && data?.report) {
      const currentValues = editQuestionsAdapter(
        data.report?.attributes?.fields ?? {},
        headerQuestions,
        bodyQuestions,
        contexts
      )
      reset(currentValues)
    }

    return () => reset(defaultValues)
  }, [bodyQuestions, contexts, data, headerQuestions, id, methods])

  useEffect(() => {
    if (id && templateQuestions?.length) {
      dispatchRerender(dispatch, {
        type: 'list'
      })
    }
  }, [templateQuestions, id])

  useEffect(() => {
    return () => {
      setIsSubmitting(false)
      setFormFieldKey(null)
    }
  }, [])

  const handleBodyQuestionsChange = (changedList) => {
    const { setValue } = methods
    const currentItems = templateQuestions
    const newItems = []
    changedList.forEach(({ _id }, index) => {
      if (currentItems?.[index]) {
        newItems[index] = currentItems.find(({ _id: itemId }) => _id === itemId)
        newItems[index] = {
          ...newItems[index],
          _key: `template_questions.${index}`
        }
      }
    })
    setValue(`template_questions`, newItems)
  }

  const onSubmit = async (data) => {
    let formData = submitDataAdapter(templateId, data)
    formData = {
      ...formData,
      template_report: {
        ...formData.template_report,
        reportType: type
      }
    }
    if (isEmpty(formData?.template_report?.fields?.emails)) {
      return Notification({
        icon: 'error',
        text: 'The emails must not be empty'
      })
    }
    if (isEmpty(formData?.template_report?.fields?.template_questions)) {
      return Notification({
        icon: 'error',
        text: 'The templates questions must not be empty'
      })
    }
    setIsSubmitting(true)
    try {
      const endpoint = id
        ? () => OlingaAPI.updateReport(type, formData, id)
        : () => OlingaAPI.createReport(type, formData)
      const data = await endpoint()
      if (data) {
        Notification({
          icon: 'success',
          text: id ? 'Report updated!' : 'Report created!'
        })
        history.push(
          getRouteUrl('templateReports', { reportType: 'scheduled' })
        )
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    } finally {
      setIsSubmitting(false)
    }
  }

  const onAddReport = ({ _id }, index) => {
    const { setValue } = methods
    setFormFieldKey({
      key: `${formFieldKeyPrefix}${index}`,
      index
    })
    setValue(`${formFieldKeyPrefix}${index}`, {
      _id,
      context: defaultContextValue
    })
    const { open } = ref.current
    open()
  }

  const onRemoveReport = (questions) => {
    const { setValue } = methods
    const relatedQuestionsKey = 'template_questions'
    setValue(relatedQuestionsKey, questions)
  }

  const onFormFieldKeyClean = () => {
    setFormFieldKey({ key: '', index: null })
  }

  const onEmailsSelect = (emails) => {
    const { setValue } = methods
    setValue('emails', emails ?? [])
  }

  return {
    onEmailsSelect,
    onFormFieldKeyClean,
    onRemoveReport,
    onAddReport,
    onSubmit,
    handleBodyQuestionsChange,
    withZero,
    title,
    emails,
    queryRange,
    structure,
    templateName,
    fields,
    frequency,
    counterType,
    reportType,
    isMobile,
    rangeFieldKey,
    formFieldKey,
    isSubmitting,
    methods,
    ref,
    contexts,
    headerQuestions,
    bodyQuestions,
    id,
    formFrequency,
    frequencyRanges,
    dispatch,
    templateQuestions,
    setFormFieldKey,
    pageTitle,
    singularTitle,
    isReadOnly
  }
}

export default useReportsEditor
