import { useKeyPress } from '../../../../hooks'
import { Button } from '@chakra-ui/react'
import React, { useCallback, useEffect } from 'react'
import { styles } from '../styles'

const CalculationOperation = ({
  children,
  onChange,
  operation,
  isDisabled,
  bg,
  keyboardKey,
  ...props
}) => {
  const applyOperation = useCallback(() => {
    onChange(operation)
  }, [onChange, operation])

  const isKeyPressed = useKeyPress(keyboardKey, true, true)

  useEffect(() => {
    if (isKeyPressed) {
      applyOperation()
    }
  }, [isKeyPressed, applyOperation])

  return (
    <Button
      {...props}
      {...styles}
      bg={bg}
      isDisabled={isDisabled}
      onClick={applyOperation}
      size="xs"
      fontSize="20px"
    >
      {children}
    </Button>
  )
}

export default CalculationOperation
