import { get } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { singularize } from '../../../helpers'

const useTemplateStructure = ({ template }) => {
  const templateDetails = useMemo(() => {
    if (template?.attributes) {
      return {
        name: template.attributes.name,
        formFolderName: template.attributes.formFolderName,
        userPermissions: template.attributes?.userPermissions ?? {}
      }
    }
    return null
  }, [template])

  const { features } = useSelector(({ globalReducer }) => globalReducer)

  const {
    pageTitle,
    hasPermissionsToModifyFolder,
    hasPermissionsToModifyGroup
  } = {
    pageTitle:
      get(features, 'templates.businessName') ||
      get(features, 'templates.name'),
    hasPermissionsToModifyGroup: get(features, 'groups.shouldCreate'),
    hasPermissionsToModifyFolder: get(features, 'folders.shouldCreate')
  }

  const singularTitle = singularize(pageTitle)

  return {
    templateDetails,
    pageTitle,
    singularTitle,
    hasPermissionsToModifyFolder,
    hasPermissionsToModifyGroup
  }
}

export default useTemplateStructure
