import Breadcrumb from 'components/Breadcrumb'
import { getRouteUrl } from 'helpers/admin'
import React, { useMemo } from 'react'


const AssetDefaultFieldEditorBreadcrumb = ({ id, name }) => {
  const options = useMemo(() => {
    const items = [
      {
        title: 'Home',
        url: getRouteUrl('businesses')
      },
      {
        title: 'Assets Default Fields',
        url: getRouteUrl('assetDefaultFields')
      }
    ]
    if (id) {
      items.push({
        title: 'Edit Asset Default Field'
      })
      items.push({
        title: name
      })
    } else {
      items.push({
        title: `New Asset Default Field`
      })
    }
    return items
  }, [id, name])
  return <Breadcrumb routeTree={options} nativeHomeRoute={false} />
}

export default AssetDefaultFieldEditorBreadcrumb
