/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import {Button} from '@chakra-ui/button'
import {Box, Flex, Heading} from '@chakra-ui/layout'
import React from 'react'
import {FormProvider} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {getRouteUrl} from '../../../../helpers'
import QRCodeGenerator from '../../../../pages/Template/QRCodeGenerator'
import QuestionActionsProvider from '../ActionsContext'
import Content from './Content'
import useRootForm from './hooks/useRootForm'

const Form = ({
  template,
  geolocation,
  shouldRenderQRCodeButton,
  templateStructure
}) => {
  const {
    handleSubmit,
    onSubmit,
    onError,
    isMobile,
    isLoadingSubmit,
    methods,
    fields,
    indexes,
    localIndex
  } = useRootForm({ template, geolocation })

  const { getValues } = methods

  return (
    <Box w={`${isMobile ? '100%' : '50%'}`}>
      <Box paddingBottom="24px">
        <Heading as="h4" size="md">
          {template.name}
        </Heading>
      </Box>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <QuestionActionsProvider>
            {fields &&
              fields.map((field, index) => {
                const currentKey = `questions.${index}`
                const key = indexes.getUniqKey(currentKey)
                return (
                  <Content
                    localIndex={localIndex}
                    field={field}
                    currentKey={currentKey}
                    key={key}
                    index={index}
                    template={template}
                  />
                )
              })}
          </QuestionActionsProvider>
          <Flex justify="flex-start" w="100%" pt={5}>
            <Link to={getRouteUrl('templates')}>
              <Button key="Cancel">Cancel</Button>
            </Link>
            {shouldRenderQRCodeButton && (
              <QRCodeGenerator
                type="quick-submit"
                getValues={getValues}
                templateId={template.id}
                templateName={template.name}
                templateStructure={templateStructure}
              />
            )}
            <Button
              isLoading={isLoadingSubmit}
              loadingText="Send"
              type="submit"
              colorScheme="blue"
              ml={4}
            >
              Send
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </Box>
  )
}

export default Form
