import { Box, Tooltip } from '@chakra-ui/react'
import MaterialIcon from '../../../components/MaterialIcon'
import { isEmpty } from 'lodash'
import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { FormProvider, useForm } from 'react-hook-form'
import EmptyState from '../../../components/EmptyState'
import FormSubmitActions from '../../../components/FormSubmitActions'
import { useTemplateEditorContext } from '../../../contexts/TemplateEditor'
import Component from '../Component'
import Ghost from '../Component/Ghost'
import Dropzone from '../Dropzone'
import DeleteElementBody from '../FormActions/DeleteElementBody'
import { Wrapper as FocusButton } from '../FormActions/MobileActions/styles'
import Header from '../Header'
import { FocusButtonWrapper, FormWrapper } from '../styles'
import TemplateSkeleton from './TemplateSkeleton'
import useFormAlerts from './hooks/useFormAlerts'
import useFormFormatting from './hooks/useFormFormatting'
import useFormSubmission from './hooks/useFormSubmission'
import useTemplateFormOperations from './hooks/useTemplateFormOperations'

const Form = ({ hasHeader }) => {
  const {
    indexes,
    focusedField,
    ghostElement,
    template,
    isSettingsSidebarOpen,
    defaultAppPermissions
  } = useTemplateEditorContext()

  const methods = useForm({
    defaultValues: {
      template_setting_attributes: defaultAppPermissions
    }
  })

  const { control, reset, getValues, setFocus } = methods

  const {
    fields,
    onDragEnd,
    handleScrollToFocusedField,
    onGoBack,
    outsideClickRef,
    deleteElementRef
  } = useTemplateFormOperations({
    control,
    getValues,
    indexes,
    setFocus
  })

  const { onSubmit, isLoading } = useFormSubmission({
    hasHeader,
    indexes,
    methods,
    template
  })

  const { dialogContent, dialogTriggerRef, onCloseDialog } = useFormAlerts()

  const { isFormattingTemplate } = useFormFormatting({ template, reset })

  if (isFormattingTemplate) {
    return <TemplateSkeleton />
  }

  return (
    <>
      {focusedField && !isSettingsSidebarOpen && (
        <Tooltip label="Focus selected field">
          <FocusButtonWrapper data-focus="true">
            <FocusButton color="#2C5282" onClick={handleScrollToFocusedField}>
              <MaterialIcon icon='center_focus_strong' />
            </FocusButton>
          </FocusButtonWrapper>
        </Tooltip>
      )}
      <DeleteElementBody
        {...dialogContent}
        ref={dialogTriggerRef}
        context={deleteElementRef}
        handleClose={onCloseDialog}
        getValues={getValues}
      />
      <Box ref={outsideClickRef}>
        <FormWrapper>
          <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
              {hasHeader && (
                <Header
                  isEdit={!!template?.id}
                  templateId={template?.id}
                  isLoading={isLoading}
                />
              )}
              <DragDropContext onDragEnd={onDragEnd}>
                <Dropzone isNested={false} fromType="" id="main" type="root">
                  {isEmpty(fields) ? (
                    <EmptyState text="This template does not have any elements yet" />
                  ) : (
                    fields.map((field, index) => {
                      const currentKey = `questions.${index}`
                      const key = indexes.getUniqKey(currentKey)
                      return (
                        <Component
                          key={key}
                          field={field}
                          currentKey={currentKey}
                          droppableType="root"
                          index={index}
                        />
                      )
                    })
                  )}
                  {!focusedField && ghostElement && (
                    <Ghost type={ghostElement} />
                  )}
                </Dropzone>
              </DragDropContext>
              <FormSubmitActions
                onSubmit={onSubmit}
                isLoading={isLoading}
                cancelAction={onGoBack}
              />
            </form>
          </FormProvider>
        </FormWrapper>
      </Box>
    </>
  )
}

export default Form
