import { differenceBy, isEmpty, uniqBy } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

const useAssetsHandler = ({ currentKey }) => {
  const { assetsTemplates, isLoading } = useSelector(
    ({ editTemplateReducer }) => editTemplateReducer
  )
  const { watch, setValue } = useFormContext()

  const [currentList, deletedItems, associationType] = watch([
    `${currentKey}.template_question_assets_attributes`,
    `${currentKey}.template_question_assets_attributes_deleted`,
    `${currentKey}.association_type`
  ])

  const { leftList, rightList } = {
    leftList: differenceBy(
      uniqBy(
        [
          ...(assetsTemplates ?? []).map(({ label, value }) => ({
            id: value,
            asset_template_id: value,
            label,
            _id: (deletedItems ?? [])?.find(
              ({ asset_template_id }) => value === asset_template_id
            )?._id
          })),
          ...(deletedItems ?? [])
        ],
        'asset_template_id'
      ),
      currentList,
      'asset_template_id'
    ),
    rightList: (currentList ?? []).map((item) => ({
      ...item,
      id: item.asset_template_id,
      label:
        item?.label ||
        assetsTemplates?.find(({ value }) => value === item?.asset_template_id)
          ?.label
    }))
  }

  const onChange = (updatedLeftList, updatedRightList) => {
    setValue(
      `${currentKey}.template_question_assets_attributes`,
      uniqBy(
        (updatedRightList ?? []).map((item) => ({
          ...item,
          asset_template_id: item.id,
          association_type: associationType || 'specific'
        })),
        'asset_template_id'
      )
    )
    const leftListDiff = differenceBy(updatedLeftList, leftList, '_id')
    if (!isEmpty(leftListDiff)) {
      setValue(
        `${currentKey}.template_question_assets_attributes_deleted`,
        leftListDiff.map((item) => ({ ...item, _destroy: true }))
      )
    }
  }

  return {
    leftList,
    rightList,
    isLoading,
    onChange
  }
}

export default useAssetsHandler
