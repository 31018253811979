import React, { useMemo } from 'react'
import Breadcrumb from '../../../../../components/Breadcrumb'
import { getRouteUrl } from '../../../../../helpers/admin'

const AssetTypeEditorBreadcrumb = ({ id, name }) => {
  const options = useMemo(() => {
    const items = [
      {
        title: 'Assets',
        url: getRouteUrl('assetTypes')
      }
    ]
    if (id) {
      items.push({
        title: 'Edit Assets'
      })
      items.push({
        title: name
      })
    } else {
      items.push({
        title: `New asset`
      })
    }
    return items
  }, [id, name])
  return <Breadcrumb nativeHomeRoute={false} routeTree={options} />
}

export default AssetTypeEditorBreadcrumb
