import { debounce, isFunction } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useQuery } from '../../../hooks'

const useOperationsList = ({ setValue, attributeKey, watch, id }) => {
  const items = watch(attributeKey, [])
  const query = useQuery()
  const currentRemovedItems = watch(`${attributeKey}_deleted`, [])
  const [alertMessage, setEditionAlertMessage] = useState(false)
  const addOperatorsButtonRef = useRef([])
  const editOperationsButtonRef = useRef({})
  const addOperationButtonRef = useRef(null)

  const onChange = useCallback(
    (value) => {
      setValue(attributeKey, value)
    },
    [attributeKey, setValue]
  )

  const onAddItem = useCallback(
    (item, index) => {
      let clonedList = [
        ...(items ?? []),
        {
          ...item,
          do_action: 'calculate_a_field_value'
        }
      ]
      onChange(clonedList)

      const clickAddOperatorsValue = () => {
        if (isFunction(addOperatorsButtonRef.current[index]?.click)) {
          addOperatorsButtonRef.current[index]?.click()
        }
      }

      debounce(clickAddOperatorsValue, 100)()
    },
    [items, onChange]
  )

  const onRemoveItem = useCallback(
    (newItems, _, removedItem) => {
      onChange(newItems)

      const removedItems = [
        ...(currentRemovedItems ?? []),
        { ...removedItem, _destroy: true }
      ]
      setValue(`${attributeKey}_deleted`, removedItems)
    },
    [attributeKey, onChange, setValue, currentRemovedItems]
  )

  const setAddOperatorsButtonRef = useCallback((index, ref) => {
    addOperatorsButtonRef.current[index] = ref
  }, [])

  const setEditOperationsButtonRef = useCallback((id, ref) => {
    editOperationsButtonRef.current[id] = null
    editOperationsButtonRef.current[id] = ref
  }, [])

  const setAddOperationsButtonRef = (ref) => {
    addOperationButtonRef.current = ref
  }

  useEffect(() => {
    if (query.action) {
      switch (query.action) {
        case 'create':
          if (query?.question_id && id) {
            const questionId = Number(query.question_id)
            if (questionId === id && addOperationButtonRef.current) {
              debounce(() => {
                addOperationButtonRef.current?.click()
                addOperationButtonRef.current.scrollIntoView({
                  behavior: 'smooth'
                })
              }, 100)()
            }
          }
          break
        case 'edit':
          if (query.operation_id) {
            debounce(() => {
              const operationId = query.operation_id
              if (editOperationsButtonRef.current[operationId]) {
                editOperationsButtonRef.current[operationId]?.click()
              }
            }, 100)()
          }
          break
      }
    }
  }, [id, items, query])

  return {
    items,
    onChange,
    setAddOperatorsButtonRef,
    setAddOperationsButtonRef,
    setEditOperationsButtonRef,
    onRemoveItem,
    onAddItem,
    alertMessage,
    setEditionAlertMessage
  }
}

export default useOperationsList
