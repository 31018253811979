import {
  Box,
  Flex,
  Heading,
  ListItem,
  Stack,
  Tag,
  Text,
  theme,
  UnorderedList
} from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import FloatingLabelBox from '../../../../components/FloatingLabelBox'
import { getRouteUrl } from '../../../../helpers'
import {
  mapPriorityColor,
  mapStatusColor
} from '../../../../pages/Form/FillForm/ActionsBar/constants'
import Field from '../Field'

const ActionCard = ({
  createdAt,
  description,
  title,
  dueDate,
  formId,
  templateId,
  id,
  priority,
  priorityText,
  status,
  statusText,
  users,
  creator: {
    data: {
      attributes: { email: creatorEmail, name: creatorName }
    }
  }
}) => {
  if (!id) {
    return null
  }
  return (
    <Box p={4} shadow="md">
      <FloatingLabelBox label="Details">
        <Stack gap={2} w="100%">
          <Field label="ID">{id}</Field>
          <Field label="Form ID">
            {templateId && formId ? (
              <Link to={getRouteUrl('formAnswer', { id: templateId, formId })}>
                <Heading
                  color="orange.500"
                  fontWeight="normal"
                  size="sm"
                  _hover={{ color: theme.colors.orange[700] }}
                >
                  {formId}
                </Heading>
              </Link>
            ) : (
              <Tag w="max-content" colorScheme="gray">
                Empty
              </Tag>
            )}
          </Field>
          <Field label="Title">
            {
              <Text fontSize="sm" isTruncated>
                {title}
              </Text>
            }
          </Field>
          <Field label="Description">
            <Text
              dangerouslySetInnerHTML={{ __html: description }}
              fontSize="sm"
            />
          </Field>
          <Field label="Status">
            <Tag w="max-content" colorScheme={mapStatusColor[status]}>
              {statusText}
            </Tag>
          </Field>
          <Field label="Priority">
            <Tag w="max-content" colorScheme={mapPriorityColor[priority]}>
              {priorityText}
            </Tag>
          </Field>
          <Field label="Creator">
            {creatorName || creatorEmail ? (
              <Stack>
                {creatorName && (
                  <Flex gap={2}>
                    <Text fontSize="sm">Name: {creatorName}</Text>
                  </Flex>
                )}
                {creatorEmail && (
                  <Flex gap={2}>
                    <Text fontSize="sm">Email: {creatorEmail}</Text>
                  </Flex>
                )}
              </Stack>
            ) : (
              <Tag colorScheme="gray">Empty</Tag>
            )}
          </Field>
          <Field label="Created at">
            <Text fontSize="sm">{createdAt}</Text>
          </Field>
          <Field label="Due date">
            {dueDate ? (
              <Text fontSize="sm">{dueDate}</Text>
            ) : (
              <Tag w="max-content" colorScheme="gray">
                No due date
              </Tag>
            )}
          </Field>
          {users?.length ? (
            <Field label="Users">
              <UnorderedList>
                {users.map(({ value: id, label: name }) => {
                  return (
                    <ListItem key={id}>
                      <Text fontSize="sm">{name}</Text>
                    </ListItem>
                  )
                })}
              </UnorderedList>
            </Field>
          ) : (
            <Tag w="max-content" colorScheme="gray">
              No users
            </Tag>
          )}
        </Stack>
      </FloatingLabelBox>
    </Box>
  )
}

export default ActionCard
