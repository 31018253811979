import { DragHandleIcon, MinusIcon, SettingsIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Checkbox,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Select,
  Spinner,
  Stack,
  Tooltip,
  useMediaQuery
} from '@chakra-ui/react'
import { chunk, keys } from 'lodash'
import React, { useMemo } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useFormContext, useWatch } from 'react-hook-form'
import MaterialIcon from '../../../components/MaterialIcon'
import { useTemplateEditorContext } from '../../../contexts'
import Ghost from '../Component/Ghost'
import { Wrapper as FocusButton } from '../FormActions/MobileActions/styles'
import RichTextEditor from '../RichTextEditor'
import {
  BoxInnerContent,
  DraggableContainer,
  ItemsNumberLabel,
  MobileWrapper,
  RequiredInfo,
  SectionNameWrapper,
  Wrapper
} from '../styles'
import DestroyAssetAssociationsDialog from './DestroyAssetAssociationsDialog'
import DestroyOperationsDialog from './DestroyOperationsDialog'
import FocusedQuestionBody from './FocusedQuestionBody'
import useQuestionOperations from './hooks/useQuestionOperations'
import ScaleQuestionChangeDialog from './ScaleQuestionChangeDialog'

const Question = ({
  currentKey,
  index,
  droppableType,
  isNested,
  fromType,
  shouldHideLevelIndicators = false,
  parentExpanded
}) => {
  const {
    getOptions,
    getPossibleMoveSections,
    handleAddLogic,
    handleAddQuestionToSection,
    handleDragClick,
    handleParentFocus,
    handleQuestionType,
    handleRemoveLogics,
    handleRemoveOptionFromSection,
    handleTabsChange,
    notifiedOptions,
    onCancelChangeType,
    onChangeTextValue,
    onConfirmChangeType,
    options,
    alertTriggerRef,
    scaleAlertTriggerRef,
    assetAlertTriggerRef,
    ref,
    shouldKeepFocused
  } = useQuestionOperations({
    currentKey,
    index,
    droppableType,
    isNested,
    fromType,
    shouldHideLevelIndicators,
    parentExpanded
  })

  const { register, control } = useFormContext()

  const {
    focusedField,
    ghostElement,
    errors,
    indexes,
    operations,
    structure,
    logicOptions,
    questionTypes
  } = useTemplateEditorContext()

  const [
    selectedTab,
    oldPositionKey,
    id,
    questionType,
    isNotifying,
    text,
    isCompulsory,
    logics,
    isMain,
    operationsItems
  ] = useWatch({
    name: [
      `${currentKey}._selectedTab`,
      `${currentKey}._key`,
      `${currentKey}._id`,
      `${currentKey}.question_type`,
      `${currentKey}.notify`,
      `${currentKey}.text`,
      `${currentKey}.compulsory`,
      `${currentKey}.questions`,
      `${currentKey}.main`,
      `${currentKey}.question_calculation_fields_attributes`
    ],
    control
  })

  const {
    shouldRenderNotificationsTab,
    shouldRenderOperationsTab,
    shouldRenderSettingsTab,
    shouldRenderOptions,
    shouldRenderAssetsTab,
    isFocused,
    shouldDisplayRemoveOption,
    shouldDisplayMoveToSectionOptions
  } = useMemo(
    () => ({
      shouldRenderNotificationsTab: [
        'boolean',
        'select',
        'radio_btn',
        'checkbox'
      ].includes(questionType),
      shouldRenderOperationsTab: ['calculated'].includes(questionType),
      shouldRenderSettingsTab: !['calculated'].includes(questionType),
      shouldRenderOptions: [
        'radio_btn',
        'checkbox',
        'select',
        'checkbox'
      ].includes(questionType),
      shouldRenderAssetsTab: ['asset'].includes(questionType),
      isFocused: shouldKeepFocused || focusedField === currentKey,
      shouldDisplayRemoveOption:
        chunk(currentKey.split('.'), 2).length > 1 && !id && !!oldPositionKey,
      shouldDisplayMoveToSectionOptions: true
    }),
    [
      currentKey,
      focusedField,
      id,
      oldPositionKey,
      questionType,
      shouldKeepFocused
    ]
  )

  const [isMobile] = useMediaQuery('(max-width: 730px)')

  const logicQuestionTypes = useMemo(() => {
    const validOptions = keys(logicOptions)
    if (logics?.length > 0 && validOptions) {
      return questionTypes.reduce((acc, type, index) => {
        if (validOptions.includes(type.value)) {
          acc = acc.concat(questionTypes[index])
        }
        return acc
      }, [])
    }

    return questionTypes
  }, [logicOptions, logics?.length, questionTypes])

  return (
    <>
      <DestroyOperationsDialog
        ref={alertTriggerRef}
        onClose={onCancelChangeType}
        onConfirm={onConfirmChangeType}
      />
      <DestroyAssetAssociationsDialog
        ref={assetAlertTriggerRef}
        onClose={onCancelChangeType}
        onConfirm={onConfirmChangeType}
      />
      <ScaleQuestionChangeDialog
        ref={scaleAlertTriggerRef}
        onClose={onCancelChangeType}
        onConfirm={onConfirmChangeType}
      />
      <Draggable
        key={currentKey}
        draggableId={currentKey}
        index={index}
        type={droppableType}
      >
        {(provided) => (
          <div>
            <Wrapper
              onMouseDown={handleDragClick}
              ref={ref}
              data-position={currentKey}
              elementType={logics?.length > 0 ? 'logic' : 'question'}
              data-type="question"
              isFocused={isFocused}
              isNested={isNested}
              fromType={fromType}
              data-removable={!id}
              data-disabled={questionType === 'calculated' ? 'logic' : ''}
              shouldHideLevelIndicators={shouldHideLevelIndicators}
            >
              <Box
                {...provided.draggableProps}
                ref={provided.innerRef}
                rounded="sm"
                p="3"
                mb="0"
                bg="white"
                boxShadow="base"
              >
                <Stack>
                  <BoxInnerContent>
                    <DraggableContainer
                      data-id="draggable-container"
                      {...provided.dragHandleProps}
                      data-expanded={parentExpanded}
                    >
                      <DragHandleIcon />
                      {isCompulsory && (
                        <Tooltip
                          label={`This question is compulsory ${
                            isMain ? 'and main' : ''
                          }`}
                          placement="top"
                        >
                          <RequiredInfo>* {isMain && '*'}</RequiredInfo>
                        </Tooltip>
                      )}
                    </DraggableContainer>
                    <MobileWrapper>
                      <RichTextEditor
                        maxWidth="100%"
                        modules={{ toolbar: false }}
                        register={{
                          ...register(`${currentKey}.text`, {
                            required: true
                          })
                        }}
                        value={text}
                        onChangeValue={onChangeTextValue}
                        placeholder="Question label"
                        isInvalid={errors.includes(`${currentKey}.text`)}
                        focusBorderColor={
                          errors.includes(`${currentKey}.text`)
                            ? '#E53E3E'
                            : '#3182CE'
                        }
                      />
                      {logicQuestionTypes?.length > 0 ? (
                        <Select
                          maxW={isMobile ? '100%' : '220px'}
                          placeholder="Question type"
                          data-ignore="true"
                          {...register(`${currentKey}.question_type`, {
                            required: true
                          })}
                          onChange={handleQuestionType}
                          borderColor={
                            errors.includes(`${currentKey}.question_type`)
                              ? '#E53E3E'
                              : '#3182CE'
                          }
                        >
                          {logicQuestionTypes.map((option, index) => (
                            <option value={option.value} key={index}>
                              {option.label}
                            </option>
                          ))}
                        </Select>
                      ) : (
                        <Flex>
                          <Center>
                            <Spinner size="sm" />
                          </Center>
                        </Flex>
                      )}
                      <Flex
                        align="center"
                        justify={isFocused ? 'space-between' : 'flex-end'}
                        minW="215px"
                      >
                        {shouldKeepFocused && (
                          <Tooltip label="Set focus to the parent element">
                            <FocusButton
                              color="#36cec8"
                              noborder
                              onClick={handleParentFocus}
                            >
                              <MaterialIcon icon='center_focus_weak' />
                            </FocusButton>
                          </Tooltip>
                        )}
                        {logics && logics?.length > 0 && (
                          <ItemsNumberLabel>
                            {logics?.length}{' '}
                            {logics?.length === 1 ? 'logic' : 'logics'}
                          </ItemsNumberLabel>
                        )}
                        <Checkbox
                          mb="0"
                          mx="2"
                          {...register(`${currentKey}.active`)}
                        >
                          Active
                        </Checkbox>
                        <Menu isLazy>
                          <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<SettingsIcon />}
                            size="xs"
                            variant="outline"
                            colorScheme="blue"
                          />
                          <MenuList>
                            {(shouldDisplayRemoveOption ||
                              shouldDisplayMoveToSectionOptions) && (
                              <MenuGroup
                                title={`${
                                  logics.length > 0 ? 'Logic' : 'Question'
                                } options`}
                              >
                                {shouldDisplayMoveToSectionOptions &&
                                  getPossibleMoveSections().map(
                                    (item, index) => {
                                      return (
                                        <MenuItem
                                          onClick={() =>
                                            handleAddQuestionToSection(item)
                                          }
                                          key={index}
                                          icon={item?.Icon || null}
                                        >
                                          Add to
                                          <SectionNameWrapper
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item.name || 'Untitled section'
                                            }}
                                          />
                                        </MenuItem>
                                      )
                                    }
                                  )}
                                {shouldDisplayRemoveOption && (
                                  <MenuItem
                                    icon={<MinusIcon />}
                                    onClick={handleRemoveOptionFromSection}
                                  >
                                    Remove from this section
                                  </MenuItem>
                                )}
                              </MenuGroup>
                            )}
                            <MenuGroup title="Other options">
                              {logics?.length === 0 && (
                                <MenuItem onClick={handleAddLogic}>
                                  Turn into logic
                                </MenuItem>
                              )}
                              {!id && logics?.length > 0 && (
                                <MenuItem onClick={handleRemoveLogics}>
                                  Remove all logics from this question
                                </MenuItem>
                              )}
                            </MenuGroup>
                          </MenuList>
                        </Menu>
                      </Flex>
                    </MobileWrapper>
                  </BoxInnerContent>
                  <FocusedQuestionBody
                    id={id}
                    currentKey={currentKey}
                    focusedField={focusedField}
                    isNested={isNested}
                    getOptions={getOptions}
                    indexes={indexes}
                    questionType={questionType}
                    isFocused={isFocused}
                    selectedTab={selectedTab}
                    ghostElement={ghostElement}
                    logics={logics}
                    shouldRenderSettingsTab={shouldRenderSettingsTab}
                    shouldRenderOperationsTab={shouldRenderOperationsTab}
                    shouldRenderNotificationsTab={shouldRenderNotificationsTab}
                    shouldRenderAssetsTab={shouldRenderAssetsTab}
                    shouldRenderOptions={shouldRenderOptions}
                    operationsItems={operationsItems}
                    isNotifying={isNotifying}
                    operations={operations}
                    structure={structure}
                    handleTabsChange={handleTabsChange}
                    notifiedOptions={notifiedOptions}
                    options={options}
                  />
                </Stack>
              </Box>
            </Wrapper>
            {focusedField === currentKey &&
              !!ghostElement &&
              ghostElement !== 'logic' && <Ghost type={ghostElement} />}
          </div>
        )}
      </Draggable>
    </>
  )
}

export default Question
