import { isString, omit } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import { useGlobalStateContext } from '../../../../../../../contexts/GlobalStateContext'
import {
  globalEvents,
  onChangeTemplateSettingsTab
} from '../../../../../../../contexts/GlobalStateContext/events'
import useGlobalEventListener from '../../../../../../../hooks/useGlobalEventListener'
import { getLinearStructure } from '../../../../../../../pages/TemplateEditor/helpers'
import Notification from '../../../../../../../services/Notification'
import OlingaAPI from '../../../../../../../services/OlingaAPI'

const useCalculatedFields = ({ template, questions, onRefetch }) => {
  const { global$ } = useGlobalStateContext()
  const history = useHistory()
  const structure = useMemo(() => {
    return {
      complete: template.attributes,
      linear: getLinearStructure(template.attributes)
    }
  }, [template])

  const { watch, setValue, reset, handleSubmit, control } = useForm({
    defaultValues: structure.linear.object
  })

  const [isLoading, setIsLoading] = useState(false)

  const getFormattedCalculations = (items = [], structure = {}) => {
    return items?.map((item) => {
      return {
        ...item,
        expression: {
          data: (item?.expression?.data ?? []).map((operation) => {
            const question = structure.linear.object[operation?.value] ?? {}
            return {
              ...operation,
              removable: false,
              number: operation?.type === 'number',
              symbol:
                operation?.symbol ??
                ['math_operator'].includes(operation?.type),
              ...question,
              label: question?.text ?? '',
              color: operation?.type === 'math_operator' ? 'gray.900' : ''
            }
          })
        },
        _id: uuidv4()
      }
    })
  }

  const onSubmit = useCallback(
    async (data) => {
      setIsLoading(true)
      try {
        const templateData = Object.entries(data).reduce(
          (acc, [, item]) => {
            if (item.question_type === 'calculated') {
              acc.template.template_questions_attributes.push({
                id: item.id,
                question_calculation_fields_attributes:
                  item.question_calculation_fields.map((field) => ({
                    ...omit(field, ['_id']),
                    expression: {
                      data: (field?.expression?.data ?? [])?.map(
                        ({ value, id, type }) => {
                          if (type === 'question') {
                            return {
                              value: id,
                              type
                            }
                          }
                          return {
                            value,
                            type
                          }
                        }
                      )
                    }
                  }))
              })
            }
            return acc
          },
          { template: { template_questions_attributes: [] } }
        )
        const response = await OlingaAPI.templatesUpdate(template, templateData)
        if (response?.status === 200) {
          Notification({
            text: response.message ?? 'Template updated',
            icon: 'success',
            position: toast.POSITION.TOP_RIGHT
          })
          onRefetch()
        }
      } catch (e) {
        if (isString(e?.response?.data)) {
          Notification({
            text: 'Unable to save this operations. Check error logs',
            icon: 'error',
            position: toast.POSITION.TOP_RIGHT
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [onRefetch, template]
  )

  useEffect(() => {
    if (structure.linear.data.length > 0) {
      const initialStructure = questions.reduce((acc, question) => {
        acc[question.id] = {
          ...question,
          key: uuidv4(),
          question_calculation_fields: getFormattedCalculations(
            question?.question_calculation_fields ?? [],
            structure
          )
        }
        return acc
      }, [])
      reset(initialStructure)
    }
  }, [reset, structure, questions])

  useGlobalEventListener(
    useCallback(
      (event) => {
        if (event.type === globalEvents.ON_TEMPLATE_SETTINGS_TAB_CHANGE) {
          const isDirty = control._getDirty()
          if (event?.payload?.id !== 'calculated_fields' && isDirty) {
            handleSubmit(onSubmit)()
          }
        }
      },
      [handleSubmit, onSubmit, control]
    )
  )

  const goToTemplateEditor = () => {
    global$.next(
      onChangeTemplateSettingsTab({
        id: 'edit_form'
      })
    )
  }

  const onCancel = () => {
    history.goBack()
  }

  return {
    onSubmit,
    getFormattedCalculations,
    isLoading,
    watch,
    setValue,
    structure,
    handleSubmit,
    goToTemplateEditor,
    onCancel
  }
}

export default useCalculatedFields
