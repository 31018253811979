import { DownloadIcon } from '@chakra-ui/icons'
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import { pick } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { handleExportChecklists } from '../../../../helpers'
import { getFilteredParams } from '../../../../hooks/useTableFilter'
import MaterialIcon from '../../../../components/MaterialIcon'

const sanitizeParams = (params) => {
  return pick(params, ['start_date', 'end_date', 'text', 'frequency'])
}

const ExportChecklists = ({ id, name, size, params, ariaLabel }) => {
  const [isExporting, setIsExporting] = useState(false)

  const handleExport = (format) => {
    handleExportChecklists(
      id,
      name,
      format,
      sanitizeParams(getFilteredParams(params)),
      setIsExporting
    )
  }
  return (
    <Menu closeOnSelect>
      <MenuButton
        as={IconButton}
        icon={<DownloadIcon />}
        variant="outline"
        colorScheme="green"
        size={size}
        isLoading={isExporting}
        aria-label={ariaLabel}
      />
      <MenuList>
        <MenuItem
          alignItems="center"
          icon={
            <MaterialIcon icon='file_download' styles={{ fontSize: '20px' }}/>
          }
          onClick={() => handleExport('xlsx')}
          aria-label={`${ariaLabel} option`}
        >
          Export all as Spreadsheet
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

ExportChecklists.defaultProps = {
  size: 'sm'
}

ExportChecklists.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  format: PropTypes.string,
  params: PropTypes.object,
  ariaLabel: PropTypes.string
}

export default ExportChecklists
