/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import { Tooltip } from '@chakra-ui/tooltip'
import imageExtensions from 'image-extensions'
import isImage from 'is-image'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { v4 as uuid } from 'uuid'
import { getConjunctionSeparatedString, getExtension } from '../../../helpers'
import Notification from '../../../services/Notification'
import IconPreview from './IconPreview'
import {
  Img,
  Input,
  InputWrapper,
  RemoveIcon,
  Thumb,
  ThumbInner,
  ThumbsWrapper
} from './styles'

const UploadFile = forwardRef(
  (
    { onChange, isInvalid, maxFiles, acceptedExtensions, initialFiles },
    ref
  ) => {
    const [previews, setPreviews] = useState([])
    const [files, setFiles] = useState(initialFiles)
    const inputRef = useRef(null)

    useImperativeHandle(ref, () => ({
      reset() {
        setFiles([])
        onChange([])
      }
    }))

    const validExtensionsMessage = useMemo(() => {
      if (
        acceptedExtensions.length > 10 &&
        acceptedExtensions.includes('png')
      ) {
        return `Only images extensions are valid`
      }
      return `Only ${getConjunctionSeparatedString(acceptedExtensions)}  ${
        acceptedExtensions.length === 1 ? 'extension is' : 'extensions are'
      } valid`
    }, [acceptedExtensions])

    const getPreview = (file, idx) => {
      const fileExtension = getExtension(file.name)
      if (isImage(file.name)) {
        return {
          url: URL.createObjectURL && URL.createObjectURL(file),
          idx
        }
      } else if (
        acceptedExtensions.some(
          (extension) => extension.toLowerCase() === fileExtension.toLowerCase()
        )
      ) {
        return {
          idx,
          element: (
            <Tooltip label="Click to remove file">
              <div>
                <IconPreview
                  file={file}
                  index={idx}
                  fileExtension={fileExtension}
                  onRemoveFile={() => onRemoveFile(idx)}
                />
              </div>
            </Tooltip>
          )
        }
      }
      return {}
    }

    useEffect(
      () => () => {
        if (previews.length) {
          previews.forEach((object) => {
            if (object.preview) {
              URL.revokeObjectURL(object.preview)
            }
          })
        }
      },
      [previews]
    )

    const hasInvalidExtension = (files) => {
      return !files.some(({ name }) =>
        acceptedExtensions.some((extension) => {
          const fileExtension = getExtension(name)
          return fileExtension?.toLowerCase() === extension?.toLowerCase()
        })
      )
    }

    const handleSetFiles = (files) => {
      if (hasInvalidExtension(files)) {
        return Notification({
          icon: 'error',
          text: validExtensionsMessage
        })
      }
      if (files?.length <= maxFiles) {
        setFiles(files)
        onChange(files)
        return
      } else {
        return Notification({
          icon: 'error',
          text: `The max of files is ${maxFiles}`
        })
      }
    }

    const onChangeFiles = (evt) => {
      const newFiles = evt?.target?.files || []
      const allFiles = [...files, ...newFiles]
      handleSetFiles(allFiles)
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    }

    const handleInputChange = () => {
      if (inputRef.current) {
        inputRef.current.click()
      }
    }

    useEffect(() => {
      const newPreviews = []
      files.map((file, idx) => {
        newPreviews.push(getPreview(file, idx))
      })

      setPreviews([...newPreviews])
    }, [files])

    const handleDrop = (event) => {
      preventDefaults(event)

      const newFiles = event?.dataTransfer?.files
      let newFile = null
      if (newFiles && newFiles?.length > 0) {
        newFile = _.first(newFiles)
      }
      handleSetFiles([...files, newFile])
    }

    const preventDefaults = (event) => {
      event.preventDefault()
      event.stopPropagation()
    }

    const onRemoveFile = (idx) => {
      const newFiles = files.filter((file, index) => index !== idx)
      setFiles([...newFiles])
    }

    const getThumb = (preview) => {
      if (preview.element) {
        return (
          <Tooltip key={uuid()} label="Click to remove file">
            {preview.element}
          </Tooltip>
        )
      } else if (preview.url) {
        return (
          <Thumb key={uuid() + preview.url}>
            <Tooltip label="Click to remove file">
              <ThumbInner>
                <Img
                  src={preview.url}
                  onClick={() => onRemoveFile(preview.idx)}
                />
                <RemoveIcon
                  className="fas fa-trash"
                  onClick={() => onRemoveFile(preview.idx)}
                />
              </ThumbInner>
            </Tooltip>
          </Thumb>
        )
      }
      return null
    }

    const getAccetedExtensions = () => {
      if (acceptedExtensions.includes('png')) {
        return acceptedExtensions.map(
          (extension) => `image/${extension.toLowerCase()}`
        )
      }
      return acceptedExtensions.map((extension) => {
        const lowerExtension = extension.toLowerCase()
        switch (extension.toLowerCase()) {
          case 'xls':
            return `application/vnd.ms-excel`
          case 'xlsx':
            return `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`
          case 'pdf':
            return `application/pdf`
          default:
            return `.${lowerExtension}`
        }
      })
    }

    return (
      <>
        <InputWrapper
          onClick={handleInputChange}
          onDrop={handleDrop}
          onDragEnter={preventDefaults}
          onDragLeave={preventDefaults}
          onDragOver={preventDefaults}
          isInvalid={isInvalid}
        >
          <Input
            onChange={onChangeFiles}
            type="file"
            ref={inputRef}
            accept={getAccetedExtensions()}
            multiple={maxFiles > 1}
            data-testid="photo-uploader"
            id="photo-uploader"
          />
          <p>Drag and drop files here, or click to select files</p>
          <b>{validExtensionsMessage}</b>
        </InputWrapper>
        <ThumbsWrapper>
          {previews.map((preview) => getThumb(preview))}
        </ThumbsWrapper>
      </>
    )
  }
)

UploadFile.propTypes = {
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  maxFiles: PropTypes.number,
  acceptedExtensions: PropTypes.array,
  initialFiles: PropTypes.array
}

UploadFile.defaultProps = {
  onChange: _.noop,
  isInvalid: false,
  maxFiles: 5,
  acceptedExtensions: imageExtensions,
  initialFiles: []
}

export default UploadFile
