import { Box, Stack } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import FormErrorMessage from '../../../components/FormErrorMessage'
import FormField from '../../../components/FormField'
import FormSubmitActions from '../../../components/FormSubmitActions'
import EditInline from '../../../components/Inputs/EditInline'
import PageHeader from '../../../components/PageHeader'
import TransferList from '../../../components/TransferList'
import GroupUsersEditorActions from './GroupUsersEditorActions'
import GroupUsersEditorBreadcrumb from './GroupUsersEditorBreadcrumb'
import useGroupUsersEditor from './useGroupUsersEditor'

const GroupUsersEditor = ({ data }) => {
  const {
    errors,
    handleSubmit,
    handleTransferChange,
    isMobile,
    isSubmitting,
    name,
    onSubmit,
    register,
    selectedUsers,
    unselectedUsers,
    group,
    pageTitle,
    singularTitle
  } = useGroupUsersEditor({ data })

  return (
    <Stack>
      <PageHeader
        Breadcrumb={
          <GroupUsersEditorBreadcrumb
            group={group}
            pageTitle={pageTitle}
            singularTitle={singularTitle}
          />
        }
        Actions={
          group?.attributes ? (
            <GroupUsersEditorActions
              group={group}
              singularTitle={singularTitle}
            />
          ) : null
        }
        title={group?.id ? `Edit ${singularTitle}` : `New ${singularTitle}`}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Box w={isMobile ? '100%' : '40%'}>
            <Box>
              <FormField label={`${singularTitle} Name`}>
                <EditInline
                  placeholder={`${singularTitle} Name`}
                  registerProps={{ ...register('name', { required: true }) }}
                  defaultValue={name}
                  startWithEditView={!name}
                  autoFocus={!group?.id}
                  fontSize="md"
                  isInvalid={!!errors?.name}
                />
              </FormField>
              <FormErrorMessage message={errors?.name?.message} />
            </Box>
          </Box>
          <FormField label="Staff" isRequired={false}>
            <TransferList
              leftList={unselectedUsers}
              rightList={selectedUsers}
              onChange={(_, rightList) => handleTransferChange(rightList)}
            />
          </FormField>
        </Stack>
        <FormSubmitActions
          margin={4}
          isLoading={isSubmitting}
          cancelRoute="groups"
        />
      </form>
    </Stack>
  )
}

GroupUsersEditor.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    usersIds: PropTypes.array
  })
}

export default GroupUsersEditor
