import React from 'react'

function AndroidIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      fill="#000"
      version="1.1"
      viewBox="0 0 299.679 299.679"
      xmlSpace="preserve"
    >
      <g>
        <path d="M181.122 299.679c10.02 0 18.758-8.738 18.758-18.758v-43.808h12.525c7.516 0 12.525-5.011 12.525-12.525V99.466H74.749v125.123c0 7.515 5.01 12.525 12.525 12.525H99.8v43.808c0 10.02 8.736 18.758 18.758 18.758 10.019 0 18.756-8.738 18.756-18.758v-43.808h25.051v43.808c-.001 10.019 8.737 18.757 18.757 18.757z"></path>
        <path d="M256.214 224.589c10.02 0 18.756-8.737 18.756-18.758v-87.615c0-9.967-8.736-18.75-18.756-18.75-10.021 0-18.758 8.783-18.758 18.75v87.615c0 10.02 8.736 18.758 18.758 18.758z"></path>
        <path d="M43.466 224.589c10.021 0 18.758-8.737 18.758-18.758v-87.615c0-9.967-8.736-18.75-18.758-18.75-10.02 0-18.756 8.783-18.756 18.75v87.615c0 10.02 8.736 18.758 18.756 18.758z"></path>
        <path d="M209.899 1.89c-2.504-2.52-6.232-2.52-8.736 0l-16.799 16.743-.775.774c-9.961-4.988-21.129-7.479-33.566-7.503l-.182-.002h-.002l-.184.002c-12.436.024-23.604 2.515-33.564 7.503l-.777-.774L98.516 1.89c-2.506-2.52-6.232-2.52-8.736 0-2.506 2.506-2.506 6.225 0 8.729l16.25 16.253c-5.236 3.496-9.984 7.774-14.113 12.667-9.885 11.717-16.19 26.966-17.057 43.488-.008.172-.025.342-.033.514a72.074 72.074 0 00-.078 3.391H224.93c0-1.135-.027-2.266-.078-3.391-.008-.172-.025-.342-.035-.514-.865-16.522-7.172-31.772-17.057-43.487-4.127-4.893-8.877-9.171-14.113-12.667l16.252-16.253c2.506-2.505 2.506-6.224 0-8.73zm-91.365 63.173a9.383 9.383 0 110-18.766 9.383 9.383 0 010 18.766zm62.611 0a9.383 9.383 0 110-18.766 9.383 9.383 0 010 18.766z"></path>
      </g>
    </svg>
  )
}

export default AndroidIcon
