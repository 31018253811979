import { useMediaQuery } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useTemplateEditorContext } from '../../../contexts'
import Instruction from '../Instruction'
import Question from '../Question'
import Section from '../Section'

const Component = (props) => {
  const { focusedField } = useTemplateEditorContext()
  const [isMobile] = useMediaQuery('(max-width: 730px)')
  useEffect(() => {
    if (focusedField === props.currentKey) {
      const element = document.querySelector(
        `[data-position="${focusedField}"]`
      )
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: isMobile ? 'start' : 'center'
        })
      }
    }
  }, [props.currentKey, focusedField, isMobile])

  if (props.field?.question_type === 'instruction') {
    return <Instruction {...props} />
  }
  if (props?.field?.origin === 'template_questions_attributes') {
    return <Question {...props} />
  }
  if (props?.field?.origin === 'section_questions_attributes') {
    return <Section {...props} />
  }
  return null
}

export default Component
