import { Button, ButtonGroup } from '@chakra-ui/button'
import React from 'react'

const Boolean = ({ onChange, value, isInvalid }) => {
  const onClick = (newVaue) => {
    if (value === newVaue) {
      return onChange('')
    }

    onChange(newVaue)
  }

  const getColorScheme = (buttonValue) => {
    if (isInvalid) {
      return 'red'
    }

    if (buttonValue === 'yes') {
      return value === 'yes' ? 'blue' : 'gray'
    }

    return value === 'no' ? 'blue' : 'gray'
  }

  return (
    <ButtonGroup size="sm" isAttached>
      <Button
        onClick={() => onClick('yes')}
        variant={value === 'yes' ? 'solid' : 'outline'}
        colorScheme={getColorScheme('yes')}
        mr="-px"
      >
        Yes
      </Button>
      <Button
        onClick={() => onClick('no')}
        variant={value === 'no' ? 'solid' : 'outline'}
        colorScheme={getColorScheme('no')}
      >
        No
      </Button>
    </ButtonGroup>
  )
}

export default Boolean
