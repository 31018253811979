import { useDisclosure } from '@chakra-ui/react'
import { first } from 'lodash'
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { usePageProperties } from '../../../../hooks'
import OlingaAPI from '../../../../services/OlingaAPI'
import ActionSidebar from '../ActionsBar/ActionSidebar'
import { transformUsers } from '../ActionsBar/helpers'

const QuestionActionsContext = createContext({})

export default function QuestionActionsProvider({ children, actionId = null }) {
  const fetchRef = useRef(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [openedAction, setOpenedAction] = useState({})
  const [childIndex, setChildIndex] = useState({})
  const [currentQuestion, setCurrentQuestion] = useState({})
  const [dependencies, setDependencies] = useState({})

  const {
    singularTitle,
    pageTitle,
    shouldCreate: hasPermissionToModifyAction
  } = usePageProperties({
    pageName: 'actions'
  })

  useEffect(() => {
    if (hasPermissionToModifyAction) {
      setIsLoading(true)
      const fethcDependencies = async () => {
        try {
          let action = null
          const { priorities, status } = await OlingaAPI.actionsEnums()
          if (actionId) {
            const { data } = await OlingaAPI.getAction(actionId)
            action = data
          }
          fetchRef.current = true
          const deps = {
            priorities: priorities,
            statuses: status,
            action: null
          }
          if (actionId) {
            let fetchedAction = action.attributes
            const date = fetchedAction.dueDate
              ? first(fetchedAction.dueDate.split(' '))
              : null
            let formattedDate = null
            if (date) {
              let splittedDate = date.split('/')
              splittedDate[0] = splittedDate.splice(1, 1, splittedDate[0])[0]
              formattedDate = splittedDate.join('-')
            }
            let formattedAction = {
              ...fetchedAction,
              user_ids: transformUsers(fetchedAction.users?.data ?? []),
              due_date: formattedDate
            }
            deps.action = formattedAction
          }
          setDependencies(deps)
        } catch (error) {
          //
        } finally {
          setIsLoading(false)
        }
      }
      if (!fetchRef.current) {
        fethcDependencies()
      }
    }

    return () => {
      setIsLoading(false)
      setDependencies({})
      setOpenedAction({})
      setCurrentQuestion({})
    }
  }, [actionId, hasPermissionToModifyAction])

  return (
    <QuestionActionsContext.Provider
      value={{
        setOpenedAction,
        onOpen,
        onClose,
        childIndex,
        setChildIndex,
        currentQuestion,
        setCurrentQuestion,
        setIsLoading,
        isLoading,
        pageTitle,
        singularTitle,
        ...dependencies
      }}
    >
      <>
        {isOpen && (
          <ActionSidebar
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            action={openedAction}
          />
        )}
        {children}
      </>
    </QuestionActionsContext.Provider>
  )
}

export const useQuestionActions = () => {
  const context = useContext(QuestionActionsContext)

  return {
    ...context
  }
}
