import React, { useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import {
  getExtensionColor,
  getExtensionIcon,
  getExtension
} from '../../../helpers'
import { IconPreview as Wrapper } from './styles'
import MaterialIcon from '../../MaterialIcon'

const IconPreview = ({ file, onRemoveFile }) => {
  const fileExtension = useMemo(() => {
    if (file?.name) {
      return getExtension(file?.name)
    }
    return null
  }, [file])
  if (!fileExtension) {
    return null
  }
  return (
    <Wrapper
      color={getExtensionColor(fileExtension)}
      key={uuid()}
      onClick={onRemoveFile}
      data-id="upload-preview"
    >
      <MaterialIcon icon={getExtensionIcon(fileExtension)} />
      <p>
        {_.first(file.name.split(fileExtension))
          .substr(0, 10)
          .concat(fileExtension)}
      </p>
    </Wrapper>
  )
}

IconPreview.defaultProps = {
  onRemoveFile: () => ({})
}

export default IconPreview
