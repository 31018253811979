import { setFormErrors } from 'helpers'
import { appMasks } from 'helpers/masks'
import { noop } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import AdminOlingaAPI from 'services/admin/AdminOlingaAPI'
import Notification from 'services/Notification'

const useSettingsEditor = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false)

  const { settings } = useMemo(() => {
    return {
      settings: data?.settings?.data?.attributes
    }
  }, [data])

  const {
    reset,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      id: null,
      mobile_app_version: {
        android: '',
        ios: ''
      },
      mobile_force_update: {
        android: false,
        ios: false
      }
    }
  })

  useEffect(() => {
    if (settings) {
      reset(settings)
    }

    return () => {
      setIsLoading(false)
    }
  }, [settings, reset])

  const onChangeAppVersion = (field, value) => {
    setValue(field, appMasks.appVersion(value))
  }

  const onSubmit = async (data) => {
    setIsLoading(true)
    try {
      const response = await AdminOlingaAPI.updateSettings(settings.id, data)
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: response?.data?.message
        })
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmitForm = () => handleSubmit(onSubmit)()

  return {
    register,
    watch,
    onChangeAppVersion,
    onSubmitForm,
    isLoading,
    errors
  }
}

export default useSettingsEditor
