import {
  delay,
  flatMap,
  isFunction,
  isNull,
  isNumber,
  isString,
  isUndefined,
  noop,
  snakeCase
} from 'lodash'
import { useMemo, useRef, useState } from 'react'
import { cmToPxConvert, generateQRCodeHash } from '../../../../helpers'
import { quickSubmitQrCodeValidQuestions } from '../../../../pages/Form/FillForm/ActionsBar/constants'
import { getFormValues } from '../../../../pages/Form/FillForm/helpers'
import { getQuestionsIndexed, getTextLines } from './helpers'

const QR_CODE_SIZE = 10

const useQRCode = ({ type, templateId, templateName, getValues }) => {
  const [displayQrCode, setDisplayQrCode] = useState(false)
  const [qrCodeSize, setQrCodeSize] = useState(QR_CODE_SIZE)
  const [renderMargins, setRenderMargins] = useState(true)
  const [qrCodeHash, setQrCodeHash] = useState('')
  const [templateIndexedAnswers, setTemplateIndexedAnswers] = useState([])
  const [selectedQuestionId, setSelectedQuestionId] = useState(null)

  const ref = useRef({ open: noop, close: noop })

  const onGetTemplateQuestionsIndexed = () => {
    const values = getValues()
    const indexedAnswers = getQuestionsIndexed(values)

    setTemplateIndexedAnswers(indexedAnswers)

    return indexedAnswers
  }

  const onOpenChooseQRCodeLabelModal = () => {
    onGetTemplateQuestionsIndexed()
  }

  const onSelectQrCodeLabelQuestionId = (id) => {
    setSelectedQuestionId(id)
  }

  const onConfirmQrCodeSelection = () => {
    const indexedQuestions = onGetTemplateQuestionsIndexed()
    const qrCodeLabel = indexedQuestions[selectedQuestionId]?.value

    handleButtonClick(qrCodeLabel)

    if (isFunction(ref?.current?.close)) {
      ref?.current?.close()
    }
  }

  const { title, ...params } = useMemo(() => {
    let title = ''
    switch (type) {
      case 'quick-access':
        title = 'Quick Access'
        break
      case 'quick-submit':
        title = 'Quick Submit'
        break
      default:
        title = ''
        break
    }

    const baseUrl = `olingaapp://stack/`
    let url = baseUrl
    switch (type) {
      case 'quick-access':
        url += `quickAccess/${templateId}`
        break
      case 'quick-submit':
        url += `quickSubmit/${templateId}?data=${qrCodeHash}`
        break
      default:
        break
    }

    return {
      id: `${type}-templateId`,
      value: url,
      size: cmToPxConvert(qrCodeSize),
      level: 'H',
      includeMargin: renderMargins || !!selectedQuestionId,
      title
    }
  }, [
    qrCodeHash,
    qrCodeSize,
    renderMargins,
    selectedQuestionId,
    templateId,
    type
  ])

  const handleCheckBoxChange = (event) => {
    setRenderMargins(event.target.checked)
  }

  const getFormattedValuesFromForm = (formValues = []) => {
    const values = getFormValues([formValues])

    return flatMap(values)
      .filter(
        (answer) =>
          !!answer.value &&
          quickSubmitQrCodeValidQuestions.includes(answer?.question_type)
      )
      .reduce((acc, answer) => {
        if (answer.question_type === 'asset') {
          acc = acc.concat({
            id: answer.template_question_id,
            asset_id: answer?.value?.asset_id,
            value: answer?.value?.asset_id
          })
        } else {
          acc = acc.concat({
            id: answer.template_question_id,
            value: answer.value
          })
        }
        return acc
      }, [])
  }

  const getHashedForm = () => {
    const values = getValues()
    const formattedValues = getFormattedValuesFromForm(values)
    if (formattedValues?.length > 10) {
      Notification({
        icon: 'warning',
        text: 'There is a limit of 10 answers per QR Code'
      })

      return null
    }
    return generateQRCodeHash(formattedValues)
  }

  const downloadQRCode = (questionLabel) => {
    delay(() => {
      const canvas = document.getElementById(`${type}-templateId`)
      const canvasImage = canvas.toDataURL('image/png')
      const image = new Image()
      image.src = canvasImage

      image.onload = () => {
        const downloadCanvas = document.getElementById('donwload-canvas')
        downloadCanvas.width = canvas.width
        downloadCanvas.height = canvas.height
        const context = downloadCanvas.getContext('2d')

        if (isString(questionLabel) && !!questionLabel?.length) {
          const fontSize = cmToPxConvert(qrCodeSize) * 0.06
          const lineHeight = fontSize
          context.font = `${Math.floor(fontSize)}px Arial`

          const words = getTextLines(
            context,
            questionLabel,
            downloadCanvas.width * 0.9
          )
          downloadCanvas.height += lineHeight * (words.length + 1)

          context.fillStyle = '#fff'
          context.fillRect(0, 0, downloadCanvas.width, downloadCanvas.height)
          const initialPositionY = canvas.height

          context.fillStyle = '#FF871C'
          context.font = `${Math.floor(fontSize)}px Arial`
          words.forEach((currentWords, index) => {
            context.fillText(
              currentWords,
              downloadCanvas.width * 0.05,
              initialPositionY + (index + 1) * lineHeight
            )
          })
        }

        context.drawImage(image, 0, 0)

        const downloadLink = document.createElement('a')
        downloadLink.href = downloadCanvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream')
        downloadLink.download = `${snakeCase(templateName)}_${type.replace(
          /-/g,
          ''
        )}_qrcode.png`

        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)

        setDisplayQrCode(false)
      }
    }, 200)
  }

  const generateQRCode = (qrCodeLabel) => {
    const label = isNumber(qrCodeLabel) ? qrCodeLabel?.toString() : qrCodeLabel
    setDisplayQrCode(true)
    downloadQRCode(label)
  }

  const handleButtonClick = async (questionLabel = '') => {
    if (type === 'quick-submit') {
      const hash = await getHashedForm()
      if (hash) {
        const limitHashSize = hash.length + 25
        if (limitHashSize >= 3000) {
          Notification({
            icon: 'error',
            text: 'The QR code has too many characters. Please shorten it to continue'
          })
        }
        setQrCodeHash(hash)
        generateQRCode(questionLabel)
      }
    } else {
      generateQRCode()
    }
    return null
  }

  const allowQuestionIdSelection = ({ id }) => {
    const result = templateIndexedAnswers[id]
    return (
      !!result &&
      quickSubmitQrCodeValidQuestions.includes(result?.question_type) &&
      !isNull(result?.value) &&
      !isUndefined(result?.value) &&
      !!result?.value
    )
  }

  return {
    handleButtonClick,
    handleCheckBoxChange,
    qrCodeSize,
    setQrCodeSize,
    displayQrCode,
    params,
    title,
    renderMargins,
    templateIndexedAnswers,
    allowQuestionIdSelection,
    onOpenChooseQRCodeLabelModal,
    ref,
    onSelectQrCodeLabelQuestionId,
    selectedQuestionId,
    onConfirmQrCodeSelection
  }
}

export default useQRCode
