import { ViewIcon } from '@chakra-ui/icons'
import { Button, Heading, IconButton } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import Modal from '../../../components/Modal'
import { getRouteUrl } from '../../../helpers'
import AlertShowModalContent from '../AlertShowModalContent'

const AlertModal = ({ alertInfo, children, singularTitle }) => {
  if (!alertInfo?.attributes) {
    return null
  }
  const {
    attributes: {
      templateId,
      userPermissions: { edit: editAlertPermission }
    }
  } = alertInfo
  return (
    <Modal
      scrollType="outside"
      size="xl"
      footer={
        editAlertPermission && (
          <Link
            to={{
              pathname: getRouteUrl('templateSettings', { id: templateId })
            }}
          >
            <Button colorScheme="blue">Edit {singularTitle}</Button>
          </Link>
        )
      }
      header={
        <Heading isTruncated size="sm">
          {singularTitle} Details
        </Heading>
      }
      body={<AlertShowModalContent alertInfo={alertInfo?.attributes} />}
    >
      {children || (
        <IconButton
          icon={<ViewIcon />}
          colorScheme="blue"
          size="sm"
          variant="outline"
          aria-label={`Display ${singularTitle} for template ${templateId}`}
        />
      )}
    </Modal>
  )
}

export default AlertModal
