import { EditIcon } from '@chakra-ui/icons'
import { HStack, IconButton } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import ToggleUserActive from '../../..//pages/Assessors/ToggleUserActive'
import { getRouteUrl } from '../../../helpers'

const ManageUsersActions = ({ id, fetchUsers, isActive }) => {
  return (
    <HStack spacing={4}>
      <Link
        to={{
          pathname: getRouteUrl('manageUser', { id }),
          from: 'manageUsers'
        }}
      >
        <IconButton
          icon={<EditIcon />}
          colorScheme="blue"
          size="sm"
          variant="outline"
          aria-label={`Manage user ${id}`}
        />
      </Link>
      <ToggleUserActive
        id={id}
        isActive={isActive}
        shouldToggleBoth={false}
        onSuccess={() => fetchUsers()}
        type="user"
      />
    </HStack>
  )
}

export default ManageUsersActions
