import { theme } from '@chakra-ui/react'
import styled from '@emotion/styled'

const iconColorTransformer = {
  bar: `
    color: ${theme.colors.orange[400]};
  `,
  line: `
    color: ${theme.colors.cyan[500]};
  `,
  table: `
    color: ${theme.colors.teal[500]};
  `
}

export const ChartTypeIcon = styled.div`
  ${({ type }) => `
    & i {
      ${iconColorTransformer[type]};
      font-size: 18px;
    }
  `}
`

export const chartTypeContainerStyles = {
  border: `1px solid ${theme.colors.gray[200]}`,
  borderRadius: 4,
  p: 2
}
