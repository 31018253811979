import { Checkbox } from '@chakra-ui/checkbox'
import { DragHandleIcon } from '@chakra-ui/icons'
import { Box, Stack } from '@chakra-ui/layout'
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useFormContext } from 'react-hook-form'
import { useTemplateEditorContext } from '../../../contexts'
import Ghost from '../Component/Ghost'
import RichTextEditor, { quillModules } from '../RichTextEditor'
import { BoxInnerContent, DraggableContainer, Wrapper } from '../styles'
import useInstruction from './hooks/useInstruction'

const Instruction = ({
  currentKey,
  index,
  droppableType,
  isNested,
  fromType,
  shouldHideLevelIndicators = false,
  parentExpanded
}) => {
  const { handleDragClick, id, isFocused, onChangeTextValue, ref, text } =
    useInstruction({ currentKey, isNested })
  const { focusedField, ghostElement, errors } = useTemplateEditorContext()

  const { register } = useFormContext()

  return (
    <Draggable
      key={currentKey}
      draggableId={currentKey}
      index={index}
      type={droppableType}
    >
      {(provided) => (
        <div>
          <Wrapper
            onMouseDown={handleDragClick}
            ref={ref}
            data-position={currentKey}
            elementType="instruction"
            data-type="instruction"
            isFocused={isFocused}
            isNested={isNested}
            fromType={fromType}
            data-removable={!id}
            shouldHideLevelIndicators={shouldHideLevelIndicators}
          >
            <Box
              {...provided.draggableProps}
              ref={provided.innerRef}
              rounded="sm"
              p="3"
              mb="0"
              bg="white"
              boxShadow="base"
            >
              <Stack>
                <BoxInnerContent>
                  <DraggableContainer
                    data-id="draggable-container"
                    {...provided.dragHandleProps}
                    data-expanded={parentExpanded}
                  >
                    <DragHandleIcon />
                  </DraggableContainer>
                  <RichTextEditor
                    maxWidth="90%"
                    modules={quillModules}
                    hasToolbar
                    register={{
                      ...register(`${currentKey}.text`, {
                        required: true
                      })
                    }}
                    value={text}
                    onChangeValue={onChangeTextValue}
                    placeholder="Write an instruction"
                    isInvalid={errors.includes(`${currentKey}.text`)}
                    focusBorderColor={
                      errors.includes(`${currentKey}.text`)
                        ? '#E53E3E'
                        : '#3182CE'
                    }
                  />
                  <Checkbox mb="0" {...register(`${currentKey}.active`)}>
                    Active
                  </Checkbox>
                </BoxInnerContent>
              </Stack>
            </Box>
          </Wrapper>
          {focusedField === currentKey && ghostElement && (
            <Ghost type={ghostElement} />
          )}
        </div>
      )}
    </Draggable>
  )
}

export default Instruction
