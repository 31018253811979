import { debounce } from 'lodash'

export const ACTIONS = {
  SET_LOADING_FOLDERS: 'SET_LOADING_FOLDERS',
  SET_LOADING_ALL_RESOURCES: 'SET_LOADING_ALL_RESOURCES',
  SET_ALL_RESOURCES_WERE_LOADED: 'SET_ALL_RESOURCES_WERE_LOADED',
  SET_SHINY_RESOURCE_ID: 'SET_SHINY_RESOURCE_ID',
  SET_FOLDERS: 'SET_FOLDERS',
  SET_FOLDERS_STATES: 'SET_FOLDERS_STATES',
  SET_IS_ABLE_TO_FETCH_FOLDERS: 'SET_IS_ABLE_TO_FETCH_FOLDERS',
  SET_SHOULD_DISPLAY_ALL_RESOURCES: 'SET_SHOULD_DISPLAY_ALL_RESOURCES',
  SET_SHOULD_RENDER_STICKY_ACTIONS: 'SET_SHOULD_RENDER_STICKY_ACTIONS'
}

const {
  SET_LOADING_FOLDERS,
  SET_LOADING_ALL_RESOURCES,
  SET_ALL_RESOURCES_WERE_LOADED,
  SET_SHINY_RESOURCE_ID,
  SET_FOLDERS,
  SET_FOLDERS_STATES,
  SET_IS_ABLE_TO_FETCH_FOLDERS,
  SET_SHOULD_DISPLAY_ALL_RESOURCES,
  SET_SHOULD_RENDER_STICKY_ACTIONS
} = ACTIONS

export const foldersTreeInitialState = {
  isLoadingFolders: false,
  isLoadingAllResources: false,
  allResourcesWereLoaded: false,
  shinyResourceId: null,
  folders: [],
  foldersStates: null,
  isAbleToFetchFolders: false,
  shouldDisplayAllResources: false,
  shouldRenderStickyActions: false
}

export const onScroll = (id) => {
  const element = document.querySelector(`[data-id="${id}"]`)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

const applyShineOnResourceDiv = (dispatch, id) => {
  dispatch({
    type: SET_SHINY_RESOURCE_ID,
    payload: id
  })
  debounce(() => {
    dispatch({
      type: SET_SHINY_RESOURCE_ID,
      payload: null
    })
  }, 3600)()
}

export const scrollAndShine = (dispatch, resourceName, resourceId) => {
  onScroll(`${resourceName}-${resourceId}`)
  applyShineOnResourceDiv(dispatch, resourceId)
}

const foldersTreeReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_LOADING_FOLDERS:
      return {
        ...state,
        isLoadingFolders: payload
      }
    case SET_LOADING_ALL_RESOURCES:
      return {
        ...state,
        isLoadingAllResources: payload
      }
    case SET_ALL_RESOURCES_WERE_LOADED:
      return {
        ...state,
        allResourcesWereLoaded: payload
      }
    case SET_SHINY_RESOURCE_ID:
      return {
        ...state,
        shinyResourceId: payload
      }
    case SET_FOLDERS:
      return {
        ...state,
        folders: payload
      }
    case SET_FOLDERS_STATES:
      return {
        ...state,
        foldersStates: payload
      }
    case SET_IS_ABLE_TO_FETCH_FOLDERS:
      return {
        ...state,
        isAbleToFetchFolders: payload
      }
    case SET_SHOULD_DISPLAY_ALL_RESOURCES:
      return {
        ...state,
        shouldDisplayAllResources: payload
      }
    case SET_SHOULD_RENDER_STICKY_ACTIONS:
      return {
        ...state,
        shouldRenderStickyActions: payload
      }
    default:
      throw Error('Invalid action')
  }
}

export default foldersTreeReducer
