export const formDefaultValues = {
  template_report: {
    template_report_templates_attributes: [],
    template_report_templates_attributes_deleted: [],
    fields: {
      title: '',
      frequency: null,
      emails: []
    }
  }
}

export const formatSummaryReportInitialData = (report, enumerators = {}) => {
  if (report?.attributes) {
    const fields = report?.attributes?.fields ?? {}
    return {
      template_report: {
        fields: {
          ...fields,
          frequency: enumerators?.frequency?.find(
            ({ value }) => value === fields?.frequency
          ),
          emails: fields?.emails.map((email) => ({
            label: email,
            value: email,
            email
          }))
        },
        template_report_templates_attributes:
          report?.attributes?.templates?.map(
            ({ template: { id: template_id }, id }) => {
              return {
                ...(enumerators?.templates?.find(
                  ({ value }) => value === template_id
                ) ?? {}),
                id
              }
            }
          ),
        template_report_templates_attributes_deleted: []
      }
    }
  }

  return formDefaultValues
}
