import { Button } from '@chakra-ui/button'
import { CopyIcon } from '@chakra-ui/icons'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { handleTemplateClone } from '../../../helpers'
import { ButtonWrapper } from './styles'

const CloneTemplateButton = ({ templateId, label }) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <ButtonWrapper>
      <Button
        leftIcon={<CopyIcon />}
        variant="outline"
        onClick={() => handleTemplateClone(templateId, setIsLoading)}
        isLoading={isLoading}
        size="sm"
      >
        {label}
      </Button>
    </ButtonWrapper>
  )
}

CloneTemplateButton.defaultProps = {
  withoutPreFilling: false,
  shouldAlertForRoutingChanges: false,
  buttonRef: null,
  label: 'Duplicate template'
}

CloneTemplateButton.propTypes = {
  templateId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  label: PropTypes.string
}

export default CloneTemplateButton
