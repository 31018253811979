import React from 'react'
import { useWatch } from 'react-hook-form'
import NotificationsHandler from '..'

const TemplateNotificationsHandler = ({
  control,
  isInitiallyNotifying,
  initiallyNotifiedUsers,
  setValue,
  watchValue,
  subUsersList
}) => {
  const notifyKey = 'notify'
  const selfFormNotifyKey = 'notify_user_form'
  const notificationsUsersKey = 'notify_templates_users_attributes'
  const deletedNotifiedUsersKey = 'notify_templates_users_attributes_deleted'

  const [isNotifying, notifiedUsers, deletedNotifiedUsers] = useWatch({
    name: [notifyKey, notificationsUsersKey, deletedNotifiedUsersKey],
    control,
    defaultValue: [isInitiallyNotifying, initiallyNotifiedUsers, []]
  })

  return (
    <NotificationsHandler
      selfFormNotifyKey={selfFormNotifyKey}
      notificationsUsersKey={notificationsUsersKey}
      deletedNotifiedUsersKey={deletedNotifiedUsersKey}
      isNotifying={isNotifying}
      notifiedUsers={notifiedUsers}
      deletedNotifiedUsers={deletedNotifiedUsers}
      initiallyNotifiedUsers={initiallyNotifiedUsers}
      checkboxAriaLabel="template-users-list"
      setValueMethod={setValue}
      watchValueMethod={watchValue}
      subUsersList={subUsersList}
      enableExternalUsersNotifications
      enableSelfFormNotify
    />
  )
}

export default TemplateNotificationsHandler
