import { Button } from '@chakra-ui/button'
import { EmailIcon } from '@chakra-ui/icons'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/input'
import { Box, Flex } from '@chakra-ui/layout'
import { Select } from '@chakra-ui/react'
import React from 'react'
import useNewPassword from './useNewPassword'

const NewPassword = ({ onSuccess, usersProfile }) => {
  const { handleSubmit, onSubmit, onError, isLoading, errors, register } =
    useNewPassword({ onSuccess })

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Box p={1}>
        <InputGroup>
          <InputLeftAddon>
            <EmailIcon />
          </InputLeftAddon>
          <Input
            type="email"
            placeholder="Email"
            {...register('email', { required: true })}
            isInvalid={!!errors.email}
            isDisabled={isLoading}
          />
        </InputGroup>
      </Box>
      <Box p={1}>
        <Select
          isDisabled={isLoading}
          placeholder="Select Profile"
          {...register('personable_type', { required: true })}
          isInvalid={!!errors.personable_type?.message}
        >
          {usersProfile.map((user) => (
            <option key={user} value={user}>
              {user}
            </option>
          ))}
        </Select>
      </Box>
      <Flex justify="flex-end" w="100%" p={2}>
        <Button colorScheme="blue" type="submit" isLoading={isLoading}>
          Send
        </Button>
      </Flex>
    </form>
  )
}

export default NewPassword
