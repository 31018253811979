import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Portal
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import { chakraComponentsSizes } from '../../helpers'

const Sidebar = ({
  isOpen,
  onClose,
  header,
  body,
  footer,
  placement,
  children,
  size,
  initialFocusRef,
  CustomCancel,
  ...props
}) => {
  return (
    <>
      {children}
      <Drawer
        isOpen={isOpen}
        placement={placement}
        onClose={onClose}
        finalFocusRef={initialFocusRef}
        size={size}
        {...props}
      >
        <DrawerOverlay />
        <Portal>
          <DrawerContent>
            <DrawerCloseButton />
            {header && (
              <DrawerHeader borderBottomWidth="1px">{header}</DrawerHeader>
            )}

            {body && <DrawerBody p={0}>{body}</DrawerBody>}

            <DrawerFooter borderTopWidth="1px">
              {!CustomCancel && (
                <Button variant="outline" mr={3} onClick={onClose}>
                  Cancel
                </Button>
              )}
              {CustomCancel && CustomCancel}
              {footer && footer}
            </DrawerFooter>
          </DrawerContent>
        </Portal>
      </Drawer>
    </>
  )
}

Sidebar.defaultProps = {
  isOpen: false,
  onOpen: () => ({}),
  onClose: () => ({}),
  header: null,
  body: null,
  footer: null,
  placement: 'right',
  initialFocusRef: null,
  size: 'md'
}

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  header: PropTypes.any,
  body: PropTypes.any,
  footer: PropTypes.any,
  placement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  size: PropTypes.oneOf([...chakraComponentsSizes, 'full']),
  initialFocusRef: PropTypes.object
}

export default Sidebar
