import { Divider, Skeleton, Stack } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { v4 as uuid } from 'uuid'
import EmptyState from '../../../components/EmptyState'
import FloatingLabelBox from '../../../components/FloatingLabelBox'
import Answer from './Answer'
import Header from './Header'
import useFormDetails from './useFormDetails'

const FormDetails = ({ templateId, templateName, formId }) => {
  const { formDetails, isLoading, subject } = useFormDetails({
    templateId,
    formId
  })

  if (isLoading) {
    return (
      <>
        <Stack gap={4}>
          {Array.from(new Array(3)).map(() => (
            <Skeleton h="16px" w="100%" key={uuid()} />
          ))}
        </Stack>
        <Skeleton h="400px" w="100%" />
      </>
    )
  }

  if (isEmpty(formDetails?.formDetail)) {
    return <EmptyState text="There are no answers for this form" />
  }

  const { id, date, dateTime, employee } = formDetails

  return (
    <Stack as="section">
      <Header
        id={id}
        templateId={templateId}
        templateName={templateName}
        formId={formId}
        date={date}
        dateTime={dateTime}
        employee={employee}
        isLoading={isLoading}
        subject={subject}
      />
      <Divider my={4} />
      <FloatingLabelBox margin="16px 0px" delay="1.5s" label="Form responses">
        <Stack gap={4}>
          {formDetails?.formDetail?.map((answer, index) => {
            return <Answer answer={answer} key={index} />
          })}
        </Stack>
      </FloatingLabelBox>
    </Stack>
  )
}

FormDetails.propTypes = {
  templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default FormDetails
