export const appMasks = {
  phoneNumber(value) {
    return value
      .replace(/\D/g, '')
      .substring(0, 10)
      .replace(/(\d{1,4})?(\d{1,3})?(\d)?/, '$1 $2 $3')
      .replace('  ', '')
      .trimEnd()
  },
  appVersion(input) {
    const versionRegex = /(\d{1,3})\.?/g

    const matches = input.match(versionRegex)

    if (!matches || matches.length === 0) return ''

    const versionParts = []
    for (let i = 0; i < matches.length; i++) {
      const part = matches[i].match(/\d{1,3}/)[0]
      versionParts.push(part)
    }

    const formattedParts = versionParts.slice(0, 3)

    return formattedParts.join('.')
  }
}
