import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStateProvider from './contexts/admin/GlobalStateContext'
import { getDashboardUrlParams, getRouteUrl } from './helpers'
import Routes from './routes/admin'
import store from './store'

const AdminApp = (props) => {
  const { user } = props

  if (!user) {
    window.location.href = '/sm/users/sign_in'
  } else if (!user?.isAdmin) {
    const { dashboardId, userType } = getDashboardUrlParams(props.user)
    window.location.href = getRouteUrl(dashboardId, { userType })
  }

  return (
    <ChakraProvider>
      <ToastContainer />
      <Provider store={store}>
        <GlobalStateProvider {...props}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </GlobalStateProvider>
      </Provider>
    </ChakraProvider>
  )
}

export default AdminApp
