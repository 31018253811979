import { Box, Text } from '@chakra-ui/react'
import { keys } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import RenderResults from './RenderResults'

const AutocompleteResults = (props) => {
  const { isGrouped, results } = props
  if (isGrouped ? !keys(results).length : !results.length) {
    return (
      <Box p="4" w="100%" backgroundColor="white">
        <Text fontSize="sm">No results found!</Text>
      </Box>
    )
  }

  return (
    <Box
      w="100%"
      backgroundColor="white"
      maxH={260}
      overflowY="auto"
      data-id="autocomplete-result"
    >
      <RenderResults {...props} />
    </Box>
  )
}

AutocompleteResults.propTypes = {
  isGrouped: PropTypes.bool,
  results: PropTypes.any,
  attributeName: PropTypes.string,
  handleSelect: PropTypes.func,
  resultEndAdornment: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.number
  ]),
  onClose: PropTypes.func
}

export default AutocompleteResults
