import { Button, Stack, useMediaQuery, VisuallyHidden } from '@chakra-ui/react'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import { getNumberFromText } from '../../helpers'
import { usePageProperties } from '../../hooks'
import Notification from '../../services/Notification'
import OlingaAPI from '../../services/OlingaAPI'
import AlertModal from './AlertModal'

const AlertPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [alert, setAlert] = useState(null)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({ pageCount: 1, totalItems: 0 })
  const location = useLocation()
  const triggerRef = useRef(null)

  const { pageTitle, singularTitle } = usePageProperties({ pageName: 'alerts' })
  const { singularTitle: templatesSingularTitle } = usePageProperties({
    pageName: 'templates'
  })

  const [isMobile] = useMediaQuery('(max-width: 799px)')

  const fetchAlerts = useCallback(
    async (params = { page: 1, per_page: 15 }) => {
      setIsLoading(true)
      try {
        const {
          data,
          meta: { last: pageCount, count: totalItems }
        } = await OlingaAPI.alertsList({ ...params })
        setData(data)
        setPagination({ pageCount, totalItems })
      } catch (error) {
        Notification({
          icon: 'error',
          text: error?.message || ''
        })
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  useEffect(() => {
    fetchAlerts()
  }, [fetchAlerts])

  useEffect(() => {
    const fetchAlert = async (alertId) => {
      try {
        const alertInfo = await OlingaAPI.alertShow(alertId)
        setAlert(alertInfo)
        _.defer(() => {
          if (_.isFunction(triggerRef.current?.click)) {
            triggerRef.current.click()
          }
        })
      } catch (err) {
        //
      }
    }
    if (location.search) {
      const alertId = getNumberFromText(location.search)
      if (alertId) {
        fetchAlert(alertId)
      }
    }
  }, [location.search])

  const tableData = useMemo(() => {
    return data.map(({ attributes: { templateName, createdAt, id } }) => ({
      templateName,
      createdAt,
      id
    }))
  }, [data])

  const columns = useMemo(() => {
    const ShowButtonColumn = {
      Header: 'Action',
      id: 'expander',
      Cell: ({ row }) => {
        const {
          values: { id }
        } = row

        const alertInfo = _.first(data.filter((d) => id === Number(d.id)))

        return <AlertModal alertInfo={alertInfo} />
      }
    }

    const columns = [
      {
        Header: 'Id',
        accessor: 'id'
      },
      {
        Header: `${templatesSingularTitle} Name`,
        accessor: 'templateName'
      },
      {
        Header: 'Created At',
        accessor: 'createdAt'
      }
    ]

    if (isMobile) {
      columns.unshift(ShowButtonColumn)
    } else {
      columns.push(ShowButtonColumn)
    }
    return columns
  }, [templatesSingularTitle, isMobile, data])

  return (
    <Stack as="section">
      <PageHeader title={pageTitle} />
      {alert && (
        <AlertModal alertInfo={alert} singularTitle={singularTitle}>
          <VisuallyHidden>
            <Button ref={triggerRef}>Open {singularTitle} Modal</Button>
          </VisuallyHidden>
        </AlertModal>
      )}
      <Table
        isLoading={isLoading}
        data={tableData}
        columns={columns}
        pageCount={pagination.pageCount}
        totalItems={pagination.totalItems}
        fetchPage={(params) => fetchAlerts(params)}
      />
    </Stack>
  )
}

export default AlertPage
