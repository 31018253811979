/* eslint-disable react-hooks/exhaustive-deps */
import { CloseIcon, SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
  useMediaQuery
} from '@chakra-ui/react'
import { AsyncSelect } from 'chakra-react-select'
import { debounce, defer } from 'lodash'
import PropTypes from 'prop-types'
import React, { memo, useCallback, useRef, useState } from 'react'

const SearchInput = ({
  value,
  setValue,
  isLoading,
  maxWidth,
  debounceTimeout,
  mechanism,
  fetchCallback,
  transformCallback,
  defaultValue,
  onSelect,
  placeholder,
  requestParams,
  isDisabled
}) => {
  const [isMobile] = useMediaQuery('(max-width: 799px)')
  const [innerLoading, setInnerLoading] = useState(false)
  const [lastFetchedOptions, setLastFetchedOptions] = useState([])

  const input = useRef(null)

  const loadOptions = useCallback(
    debounce((inputValue, callback) => {
      const fetch = async () => {
        setInnerLoading(true)
        try {
          const response = await fetchCallback(inputValue, ...requestParams)
          const users = transformCallback(response.data)
          callback(users)
          defer(() => {
            setLastFetchedOptions(users)
          })
        } catch (error) {
          //
        } finally {
          setInnerLoading(false)
        }
      }
      if (inputValue.length >= 3) {
        fetch()
      }
    }, debounceTimeout),
    []
  )

  const getMechanism = () => {
    if (mechanism === 'input') {
      return (
        <Input
          ref={input}
          placeholder={placeholder}
          onChange={({ target: { value } }) => setValue(value)}
          value={value}
          isReadOnly={isDisabled}
        />
      )
    }
    return (
      <Box minW="calc(100% - 100px)">
        <AsyncSelect
          isMulti={mechanism === 'multi_select'}
          cacheOptions
          defaultOptions={lastFetchedOptions}
          defaultValue={defaultValue}
          loadOptions={loadOptions}
          onInputChange={setValue}
          onChange={onSelect}
          placeholder={placeholder}
          closeMenuOnSelect={false}
          isDisabled={isDisabled}
        />
      </Box>
    )
  }

  return (
    <InputGroup mb={isMobile ? 4 : 0} w={isMobile ? '100%' : maxWidth}>
      <InputLeftAddon>
        <Flex align="center">
          <SearchIcon />
          {value.length < 3 && (
            <Text ml={2} fontSize="xs">
              type {3 - value.length}+
            </Text>
          )}
        </Flex>
      </InputLeftAddon>
      {getMechanism()}
      {mechanism === 'input' && value.length > 0 && (
        <InputRightAddon px={2}>
          <Flex align="center">
            <IconButton
              icon={<CloseIcon />}
              onClick={() => setValue('')}
              size="xs"
              isDisabled={innerLoading || isLoading || isDisabled}
            />
          </Flex>
        </InputRightAddon>
      )}
    </InputGroup>
  )
}

SearchInput.defaultProps = {
  value: '',
  maxWidth: '400px',
  debounceTimeout: 500,
  mechanism: 'input',
  placeholder: 'Filter by answer value or staff name',
  transformCallback: (input) => input,
  defaultValue: [],
  requestParams: [],
  isDisabled: false
}

SearchInput.propTypes = {
  debounceTimeout: PropTypes.number,
  value: PropTypes.string,
  setValue: PropTypes.func,
  maxWidth: PropTypes.string,
  isDisabled: PropTypes.bool,
  mechanism: PropTypes.oneOf(['input', 'single_select', 'multi_select'])
}

export default memo(SearchInput)
