import { getRouteUrl, setFormErrors } from 'helpers'
import { usePageProperties, useTableColumns, useTableFilter } from 'hooks'
import { capitalize, map, noop } from 'lodash'
import EditDashboardActions from 'pages/ManageDashboards/EditDashboardActions'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Notification from 'services/Notification'
import OlingaAPI from 'services/OlingaAPI'

const hiddenColumnsKey = 'form_answers_persisted_columns.chart_contexts'

const useManageDashboards = () => {
  const history = useHistory()

  const onDelete = async (id) => {
    try {
      const response = await OlingaAPI.deleteDashboard(id)
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: response?.data?.message || 'Dashboard deleted successfully'
        })

        refetch()
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    }
  }

  const { shouldCreate: hasPermissionToCreateDashboard } = usePageProperties({
    pageName: 'manage_dashboards'
  })

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id',
        disableSortBy: true
      },
      {
        Header: 'Title',
        accessor: 'title',
        disableSortBy: true
      },
      {
        Header: 'Context',
        accessor: 'context',
        disableSortBy: true
      }
    ],
    hiddenColumnsKey,
    true,
    EditDashboardActions,
    ({ original: { id, userPermissions } }) => ({
      id,
      onDelete,
      userPermissions
    })
  )

  const fieldsExtractor = useCallback((data) => {
    return data.map(
      ({
        attributes: { title, id, layout, order, userPermissions, settings }
      }) => {
        return {
          title,
          id,
          layout,
          order,
          userPermissions,
          context: capitalize(settings.context)
        }
      }
    )
  }, [])

  const { refetch, isLoading, tableData } = useTableFilter({
    id: null,
    fetchCallback: OlingaAPI.manageDashboards,
    fieldsExtractor,
    onRefetch: useCallback(
      (newParams) => {
        history.replace(getRouteUrl('manageDashboards', newParams))
      },
      [history]
    )
  })

  const onDragEnd = async (data) => {
    const newIdsOrder = map(data, (itemData) => itemData.id)

    try {
      const response = await OlingaAPI.reorderDashboards({
        dashboard_ids: newIdsOrder
      })

      if (response?.status === 200) {
        refetch()
      }
    } catch (error) {
      //
    }
  }

  return {
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data: tableData,
    isLoading,
    refetch,
    pageTitle: 'Manage Dashboards',
    hasPermissionToCreateDashboard,
    onDragEnd
  }
}

export default useManageDashboards
