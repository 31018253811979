import { ViewIcon } from '@chakra-ui/icons'
import { Flex, IconButton } from '@chakra-ui/react'
import { getRouteUrl } from 'helpers'
import moment from 'moment'
import ExportForms from 'pages/Form/ExportForms'
import React from 'react'
import { Link } from 'react-router-dom'

const FormAnswerAction = ({ id, date, template }) => {
  const { id: templateId, templateName } = template
  return (
    <Flex>
      <Link
        to={getRouteUrl('formAnswer', {
          id: templateId,
          formId: id
        })}
      >
        <IconButton
          icon={<ViewIcon />}
          colorScheme="blue"
          size="sm"
          variant="outline"
          mr={4}
          aria-label={`Open form responses from form ${id}`}
        />
      </Link>
      <ExportForms
        templateName={templateName}
        templateId={templateId}
        size="sm"
        params={{ form_id: id, start_date: moment(date).toDate() }}
        ariaLabel={`Export individual options from form ${id}`}
      />
    </Flex>
  )
}

export default FormAnswerAction
