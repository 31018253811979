import { DragHandleIcon } from '@chakra-ui/icons'
import { Box, Checkbox, Flex, Stack, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useFormContext } from 'react-hook-form'
import { useTemplateEditorContext } from '../../../contexts/TemplateEditor/index'
import Component from '../Component'
import Ghost from '../Component/Ghost'
import Dropzone from '../Dropzone'
import { Wrapper as FocusButton } from '../FormActions/MobileActions/styles'
import RichTextEditor from '../RichTextEditor'
import {
  BoxInnerContent,
  ChildrenWrapper,
  DraggableContainer,
  ItemsNumberLabel,
  MobileWrapper,
  Wrapper
} from '../styles'
import useSectionOperations from './hooks/useSectionOperations'
import MaterialIcon from 'components/MaterialIcon'

const Section = ({
  currentKey,
  field: section,
  droppableType,
  index,
  isNested,
  fromType = '',
  shouldHideLevelIndicators = false,
  parentExpanded,
  field
}) => {
  const { register } = useFormContext()
  const { focusedField, indexes, ghostElement, errors } =
    useTemplateEditorContext()

  const {
    handleDragClick,
    handleParentFocus,
    hasSupportToRepeat,
    isFocused,
    isMobile,
    isRemovable,
    name,
    onChangeTextValue,
    ref,
    shouldKeepFocused
  } = useSectionOperations({
    currentKey,
    field,
    isNested
  })

  return (
    <Draggable
      key={currentKey}
      draggableId={currentKey}
      index={index}
      type={droppableType}
    >
      {(provided) => (
        <Wrapper
          onMouseDown={handleDragClick}
          ref={ref}
          elementType="section"
          data-type="section"
          data-position={currentKey}
          isFocused={isFocused}
          isNested={isNested}
          fromType={fromType}
          data-removable={isRemovable}
          data-expanded={parentExpanded}
          shouldHideLevelIndicators={shouldHideLevelIndicators}
        >
          <Box
            {...provided.draggableProps}
            ref={provided.innerRef}
            rounded="sm"
            p="3"
            mb="0"
            bg="white"
            boxShadow="base"
          >
            <Stack>
              <BoxInnerContent>
                <DraggableContainer
                  data-id={currentKey}
                  {...provided.dragHandleProps}
                  data-expanded={parentExpanded}
                >
                  <DragHandleIcon />
                </DraggableContainer>
                <BoxInnerContent justify>
                  <MobileWrapper>
                    <RichTextEditor
                      minWidth={isMobile ? '100%' : '75%'}
                      modules={{ toolbar: false }}
                      value={name}
                      onChangeValue={onChangeTextValue}
                      placeholder="Section name"
                      register={{
                        ...register(`${currentKey}.name`, {
                          required: true
                        })
                      }}
                      focusBorderColor={
                        errors.includes(`${currentKey}.name`)
                          ? '#E53E3E'
                          : '#3182CE'
                      }
                    />
                    <MobileWrapper>
                      <Flex w="100%" justify="space-between">
                        <Flex align="center">
                          {!isFocused && (
                            <ItemsNumberLabel>
                              {section.questions?.length} hidden{' '}
                              {section.questions?.length === 1
                                ? 'item'
                                : 'items'}
                            </ItemsNumberLabel>
                          )}
                          {shouldKeepFocused && (
                            <Tooltip label="Set focus to the parent element">
                              <FocusButton
                                color="#1787f1"
                                noborder
                                onClick={handleParentFocus}
                                data-id={`${currentKey}.focus`}
                              >
                                <MaterialIcon icon='center_focus_weak' />
                              </FocusButton>
                            </Tooltip>
                          )}
                        </Flex>
                        {hasSupportToRepeat && (
                          <Checkbox
                            mb="0"
                            {...register(`${currentKey}.repeat`)}
                          >
                            Repeat section
                          </Checkbox>
                        )}
                      </Flex>
                    </MobileWrapper>
                  </MobileWrapper>
                </BoxInnerContent>
              </BoxInnerContent>
              {isFocused && section.questions?.length > 0 && (
                <ChildrenWrapper>
                  <Dropzone
                    fromType="section"
                    isNested
                    id={currentKey}
                    type={currentKey}
                  >
                    {section.questions.map((question, index) => {
                      const thisCurrentKey = `${currentKey}.questions.${index}`
                      const key = indexes.getUniqKey(thisCurrentKey)
                      return (
                        <Component
                          key={key}
                          index={index}
                          field={question}
                          currentKey={thisCurrentKey}
                          droppableType={currentKey}
                          isNested
                          fromType="section"
                        />
                      )
                    })}
                    {currentKey === focusedField && ghostElement && (
                      <Ghost type={ghostElement} />
                    )}
                  </Dropzone>
                </ChildrenWrapper>
              )}
              {!section.questions?.length &&
                focusedField === currentKey &&
                ghostElement && <Ghost type={ghostElement} />}
            </Stack>
          </Box>
        </Wrapper>
      )}
    </Draggable>
  )
}

export default Section
