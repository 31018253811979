import { Box, useConst, VStack } from '@chakra-ui/react'
import { differenceBy, uniqBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import FormField from '../../../../../../components/FormField'
import ListBuilder from '../../../../../../components/ListBuilder'
import { defaultContextValue, sortOptions } from '../../../helpers'
import RelatedQuestion from './RelatedQuestion'

const getIntersectionQuestions = (questions, relatedQuestions) => {
  const intersectBodyQuestions = questions?.map((question) => ({
    ...question,
    id: question.data.id
  }))
  const selectedRelatedQuestions = relatedQuestions?.map(
    ({ question_id: id }) => ({ id })
  )
  return sortOptions(
    differenceBy(intersectBodyQuestions, selectedRelatedQuestions, 'id')
  )
}

const RelatedQuestionsStep = ({
  bodyQuestions,
  headerQuestions,
  counterType,
  formFieldKey,
  structure
}) => {
  const { setValue, watch } = useFormContext()
  const relatedQuestionsAttibute = useConst(() => `related_questions`)
  const [listRerenderKey, setListRerenderKey] = useState(uuid())

  const [relatedQuestionsList, context] = watch([
    `${formFieldKey}.${relatedQuestionsAttibute}`,
    `${formFieldKey}.context`
  ])

  useEffect(() => {
    if (!relatedQuestionsList?.length) {
      setListRerenderKey(uuid)
    }
  }, [relatedQuestionsList])

  const disableListCondition = useMemo(() => {
    return context?.value === defaultContextValue.value
      ? relatedQuestionsList?.length
      : relatedQuestionsList?.length === 5
  }, [context, relatedQuestionsList?.length])

  const remainingRelatedQuestionsToBeSelected = useMemo(() => {
    if (context?.value === defaultContextValue.value) {
      return getIntersectionQuestions(bodyQuestions, relatedQuestionsList)
    }
    return getIntersectionQuestions(
      uniqBy([...headerQuestions, ...bodyQuestions], ({ data: { id } }) => id),
      relatedQuestionsList
    )
  }, [context?.value, headerQuestions, bodyQuestions, relatedQuestionsList])

  const onAddItem = ({ _id }, index) => {
    const key = `${formFieldKey}.${relatedQuestionsAttibute}.${index}`
    setValue(key, { _id, order: index })
  }

  const onBodyQuestionsChange = (changedList) => {
    setValue(`${formFieldKey}.${relatedQuestionsAttibute}`, changedList)
  }

  const onRemoveItem = (questions) => {
    const relatedQuestionsKey = `${formFieldKey}.${relatedQuestionsAttibute}`
    setValue(relatedQuestionsKey, questions)
  }

  return (
    <Box>
      <VStack align="flex-start">
        <FormField isRequired label="Select the related questions">
          <ListBuilder
            key={listRerenderKey}
            droppableId="relatedQuestions"
            resourceName="Related question"
            isDisabled={disableListCondition}
            list={relatedQuestionsList ?? []}
            onAddItem={onAddItem}
            onRemoveItem={onRemoveItem}
            onChange={(list) => onBodyQuestionsChange(list)}
            ItemComponent={RelatedQuestion}
            idAttribute="_id"
            itemProps={{
              parentFormFieldKey: `${formFieldKey}.${relatedQuestionsAttibute}`,
              bodyQuestions: remainingRelatedQuestionsToBeSelected,
              relatedQuestionsAttibute,
              counterType,
              structure
            }}
            dragOffset="0"
            minHeight="calc(100vh - 200px)"
            customRemoveItemAction={(index, _, fallback) => fallback(index)}
          />
        </FormField>
      </VStack>
    </Box>
  )
}

RelatedQuestionsStep.displayName = 'RelatedQuestionsStep'

export default RelatedQuestionsStep
