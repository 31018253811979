import { omit } from 'lodash'
import ManageDashboardEditor from 'pages/ManageDashboards/ManageDashboardEditor'
import React from 'react'
import BasePage from '../../../pages/BasePage'
import OlingaAPI from '../../../services/OlingaAPI'

const ManageDashboardEditorPage = ({ id }) => {
  const callbacks = omit(
    {
      dashboard: {
        callback: () => OlingaAPI.manageDashboard(id),
        params: {}
      },
      enumerators: {
        callback: OlingaAPI.manageDashboardsEditorEnumerators,
        params: {}
      }
    },
    !id || id === 'new' ? ['dashboard'] : []
  )
  return (
    <BasePage
      fetchCallback={callbacks}
      id={id}
      fetchWithoutId
      resourceName="data"
      otherParams={{
        id: id === 'new' || !id ? null : id
      }}
    >
      <ManageDashboardEditor />
    </BasePage>
  )
}

export default ManageDashboardEditorPage
