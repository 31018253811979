import React, { Children, cloneElement } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { DropzoneContainer } from './styles'

const Dropzone = ({
  id,
  children,
  type,
  isNested,
  fromType,
  isDisabled = false
}) => {
  return (
    <Droppable droppableId={id} type={type} isDropDisabled={isDisabled}>
      {(provided, snapshot) => (
        <>
          <DropzoneContainer
            isDraggingOver={snapshot.isDraggingOver}
            {...provided.droppableProps}
            ref={provided.innerRef}
            isNested={isNested}
            fromType={fromType}
            id="dropzone-container"
          >
            {Children.map(children, (child) => {
              return (
                child &&
                cloneElement(child, {
                  shouldHideLevelIndicators: snapshot.isDraggingOver
                })
              )
            })}
            {provided.placeholder}
          </DropzoneContainer>
        </>
      )}
    </Droppable>
  )
}

export default Dropzone
