import { EditIcon } from '@chakra-ui/icons'
import { IconButton } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { getRouteUrl } from '../../../../helpers/admin'

const EditAssetsButton = ({ id }) => (
  <Link to={getRouteUrl('editAssetType', { id })}>
    <IconButton
      icon={<EditIcon />}
      colorScheme="blue"
      size="sm"
      variant="outline"
      aria-label={`Edit asset ${id}`}
    />
  </Link>
)

export default EditAssetsButton
