import { Button, Flex, Text } from '@chakra-ui/react'
import { isFunction } from 'lodash'
import CustomDatePopover from 'pages/Dashboard/CustomDatePopover'
import React from 'react'

const filterItemProps = {
  size: 'sm',
  role: 'menuitem',
}

const Filters = ({ value, onChange, isDisabled }) => {
  const onRegularFilterClick = (filter, onClose) => {
    onChange(filter)

    if (isFunction(onClose)) {
      onClose()
    }
  }
  const [startDate, endDate] = value?.params?.date ?? []
  return (
    <Flex gap='5px' alignItems='baseline'>
      <Text fontSize='sm'><b>Form date filter: </b></Text>
      <Button
        {...filterItemProps}
        colorScheme={value?.name === 'today' ? 'blue' : 'gray'}
        onClick={() => onRegularFilterClick({ name: 'today', params: {} })}
        isDisabled={isDisabled}
        position="relative"
      >
        Today
      </Button>
      <Button
        {...filterItemProps}
        colorScheme={value?.name === 'yesterday' ? 'blue' : 'gray'}
        onClick={() => onRegularFilterClick({ name: 'yesterday', params: {} })}
        isDisabled={isDisabled}
        position="relative"
      >
        Yesterday
      </Button>
      <Button
        {...filterItemProps}
        colorScheme={value?.name === 'last_7_days' ? 'blue' : 'gray'}
        onClick={() =>
          onRegularFilterClick({ name: 'last_7_days', params: {} })
        }
        isDisabled={isDisabled}
      >
        Last 7 days
      </Button>
      {isDisabled ? (
        <Button
          {...filterItemProps}
          colorScheme={value?.name === 'custom' ? 'blue' : 'gray'}
          isDisabled
          position="relative"
        >
          Custom
        </Button>
      ) : (
        <CustomDatePopover
          onConfirm={onRegularFilterClick}
          startDate={startDate}
          endDate={endDate}
        >
          <Button
            {...filterItemProps}
            colorScheme={value?.name === 'custom' ? 'blue' : 'gray'}
            position="relative"
          >
            Custom
          </Button>
        </CustomDatePopover>
      )}
    </Flex>
  )
}

export default Filters
