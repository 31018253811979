import { Button, VisuallyHidden } from '@chakra-ui/react'
import React, { forwardRef, useRef } from 'react'
import Dialog from '../../../../components/AlertDialog'

const DestroyAssetAssociationsDialog = forwardRef(
  ({ onClose, onConfirm }, ref) => {
    const dialogRef = useRef()
    return (
      <Dialog
        ref={ref}
        header="You have a pending confirmation"
        body="All associated asset types will be deleted if you proceed. Do you want to proceed ?"
        handleClose={onClose}
        footer={
          <Button colorScheme="red" onClick={onConfirm}>
            Proceed
          </Button>
        }
        cancelLabel="Cancel"
        continueLabel="Proceed"
        onContinue={onConfirm}
      >
        <VisuallyHidden>
          <Button ref={dialogRef}>Open dialog</Button>
        </VisuallyHidden>
      </Dialog>
    )
  }
)

DestroyAssetAssociationsDialog.displayName = 'DestroyAssetAssociationsDialog'

export default DestroyAssetAssociationsDialog
