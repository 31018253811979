import { FormControl, FormLabel, Stack } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import React from 'react'
import useAssetSelector from './hooks/useAssetSelector'

const AssetSelector = ({ onChange, value, menuPlacement }) => {
  const { assets } = useAssetSelector({ value, onChange })

  return (
    <Stack gap={4}>
      <FormControl>
        <FormLabel>Assets</FormLabel>
        <Select
          placeholder="Select an asset"
          options={assets}
          onChange={(newAsset) => onChange([newAsset, {}])}
          value={value}
          menuPlacement={menuPlacement}
        />
      </FormControl>
    </Stack>
  )
}

AssetSelector.defaultValue = {
  allowSelection: () => true,
  association: 'templates',
  menuPlacement: 'bottom',
}

export default AssetSelector
