import { isEmpty, omit } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import {
  getRouteUrl,
  normalizedRoles,
  setFormErrors
} from '../../../../helpers'
import Notification from '../../../../services/Notification'
import OlingaAPI from '../../../../services/OlingaAPI'

const useAssessorsEditor = ({ data }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errors, setErrors] = useState({})

  const onError = (e) => {
    setErrors(e)
  }

  const manageFormRef = useRef(null)

  const assessor = useMemo(() => data?.assessor || {}, [data])
  const [isActive, setIsActive] = useState(false)

  const manageAssessorData = useMemo(() => {
    if (!isEmpty(data?.assessor)) {
      const {
        attributes: { roles }
      } = data?.assessor

      return omit(
        {
          ...data,
          user: {
            ...data.assessor,
            attributes: {
              ...data.assessor.attributes,
              roles: normalizedRoles(roles)
            }
          }
        },
        ['assessor']
      )
    }
    return {
      ...data,
      user: {
        attributes: {
          roles: [],
          name: '',
          profile: {
            roles: []
          }
        }
      }
    }
  }, [data])

  const history = useHistory()

  const { register, handleSubmit, setValue, getValues, watch, reset } = useForm(
    {
      defaultValues: null
    }
  )

  useEffect(() => {
    if (assessor?.attributes) {
      const { name, email, active } = assessor.attributes
      reset({ name, email })
      setIsActive(active)
    }
  }, [data, assessor?.attributes, reset])

  const [name, email] = watch(
    ['name', 'email'],
    [getValues('name'), getValues('email')]
  )

  const onSubmit = async (formData) => {
    setIsSubmitting(true)
    const submitData = {
      assessor: formData
    }
    try {
      const endpoint = assessor?.id
        ? () => OlingaAPI.assessorsUpdate(assessor.id, submitData)
        : () => OlingaAPI.assessorsCreate(submitData)
      const response = await endpoint()

      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: !assessor.id ? 'Assessor created!' : 'Assessor updated!'
        })
        if (history.location?.from) {
          history.goBack()
        } else {
          history.push(getRouteUrl('assessors'))
        }
      }
    } catch (error) {
      setFormErrors(error, setErrors, Notification)
    } finally {
      setIsSubmitting(false)
    }
  }

  const onManageUserSubmit = (params) => {
    setValue('roles_params', params)
    handleSubmit(onSubmit, onError)()
  }

  const handleSequentialSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    manageFormRef.current.submit()
  }

  return {
    assessor,
    isSubmitting,
    errors,
    onManageUserSubmit,
    manageFormRef,
    isActive,
    manageAssessorData,
    name,
    email,
    register,
    setIsActive,
    handleSequentialSubmit
  }
}

export default useAssessorsEditor
