import { yupResolver } from '@hookform/resolvers/yup'
import {
  filter,
  first,
  flatten,
  get,
  isEmpty,
  map,
  noop,
  orderBy,
  union,
  uniqBy
} from 'lodash'
import { useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import Notification from '../../../..//services/Notification'
import {
  emptyRolesByCategory,
  getHasRolesByCategory,
  getRouteUrl,
  groupNormalizedList,
  normalizedRoles,
  setFormErrors
} from '../../../../helpers'
import { usePageProperties } from '../../../../hooks'
import OlingaAPI from '../../../../services/OlingaAPI'

const useManageUserForm = ({ data, isSoloPage, onExternalSubmit, ref }) => {
  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    reset,
    setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(yup.object({}))
  })

  const [
    name,
    roles,
    templatesOfTemplateRole,
    templatesOfFormRole,
    checklistsOfChecklistRole,
    assetTemplatesOfAssetTemplateRole,
    foldersOfDocumentRole,
    dashboardsOfDashbordRole
  ] = useWatch({
    name: [
      'name',
      'roles',
      'templates_of_template_role',
      'templates_of_form_role',
      'checklists_of_checklist_role',
      'asset_templates_of_asset_template_role',
      'folders_of_document_role',
      'dashboards_of_dashboard_role'
    ],
    control,
    defaultValue: [
      getValues('name') || null,
      getValues('roles') || [],
      [],
      [],
      [],
      [],
      [],
      []
    ]
  })

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [user, setUser] = useState({})
  const [isUserActive, setIsUserActive] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState({})
  const [lists, setLists] = useState({
    profiles: [],
    templates: [],
    checklists: [],
    assetTemplates: [],
    documents: [],
    dashboards: []
  })
  const [hasProfileRolesByCategory, setProfileHasRolesByCategory] = useState({})
  const [hasUserRolesByCategory, setHasUserRolesByCategory] = useState({})
  const [rolesByProfile, setRolesByProfile] = useState([])

  const isEdit = useMemo(() => !isEmpty(user), [user])

  const { pageTitle: templatesPageTitle } = usePageProperties({
    pageName: 'templates'
  })
  const { pageTitle: checklistsPageTitle } = usePageProperties({
    pageName: 'checklists'
  })
  const { pageTitle: assetTemplatesPageTitle } = usePageProperties({
    pageName: 'asset_templates'
  })
  const { pageTitle } = usePageProperties({ pageName: 'manage_users' })

  const history = useHistory()

  const normalizedObj = (obj, groupAttribute = null) =>
    map(obj, (item) => {
      const id = item?.attributes?.id || item?.id
      const name = item?.attributes?.name || item?.name
      const normalizedObject = {
        label: item?.attributes?.label || item?.label || name,
        description: item?.attributes?.description || item?.description,
        name,
        value: id,
        id,
        roles: item?.attributes?.roles || item?.roles
      }

      if (groupAttribute) {
        return { ...normalizedObject, group: get(item, groupAttribute) }
      }

      return {
        label: item?.attributes?.label || item?.label || name,
        description: item?.attributes?.description || item?.description,
        name,
        value: id,
        id,
        roles: item?.attributes?.roles || item?.roles
      }
    })

  useEffect(() => {
    const getTemplates = (roleType, rolesArray, key = 'name') => {
      let role = filter(rolesArray, (role) => {
        if (key === 'name') {
          return (role?.[key] ?? '')?.includes(roleType)
        }

        return role?.[key] === roleType
      })

      if (!isEmpty(role)) {
        const selectedRoles = isEmpty(first(role).resources)
          ? normalizedRoles(role)
          : role
        const resources = first(selectedRoles)?.resources || {}
        return map(resources?.ids, (id, idx) => ({
          label: resources.names[idx],
          name: resources.names[idx],
          value: id,
          id
        }))
      }

      return []
    }
    const { user: userData } = data || {}
    if (!isEmpty(userData)) {
      setUser(userData)
      const userAttr = userData?.attributes
      setIsUserActive(userAttr.active)
      const profileRoles = userAttr?.profile?.roles || []
      const profileRolesNames = map(profileRoles, 'name') || []

      const selectedRoles = normalizedObj(
        filter(userAttr.roles, (r) => !profileRolesNames.includes(r.name))
      )
      setValue('name', userAttr.name)
      setValue('roles', uniqBy(selectedRoles, 'name'))
      setSelectedProfile(userAttr?.profile)
      const templates = {
        template_role: getTemplates('Template', userAttr.roles, 'resourceType'),
        form_role: getTemplates('form', userAttr.roles)
      }

      const checklists = {
        checklist_role: getTemplates('checklist', userAttr.roles)
      }

      const assetTemplates = {
        asset_template_role: getTemplates(
          'AssetTemplate',
          userAttr.roles,
          'resourceType'
        )
      }

      const documents = {
        document_role: getTemplates('document', userAttr.roles)
      }

      const dashboards = {
        dashboard_role: getTemplates('dashboard', userAttr.roles)
      }

      setValue('templates_of_template_role', templates.template_role)
      setValue('templates_of_form_role', templates.form_role)
      setValue('checklists_of_checklist_role', checklists.checklist_role)
      setValue(
        'asset_templates_of_asset_template_role',
        assetTemplates.asset_template_role
      )
      setValue('folders_of_document_role', documents.document_role)
      setValue('dashboards_of_dashboard_role', dashboards.dashboard_role)
    }

    const profiles = normalizedObj(data?.profiles)
    const templates = orderBy(
      groupNormalizedList(
        (data?.templates ?? []).map((item) => ({
          ...item,
          group: item.folderName
        })),
        'label'
      )
    )

    const checklists = orderBy(
      groupNormalizedList(
        (data?.checklists ?? []).map((item) => ({
          ...item,
          group: item.folderName
        })),
        'label'
      )
    )

    const assetTemplates = orderBy(
      groupNormalizedList(
        (data?.assetTemplates?.data?.asset_templates ?? []).map((item) => ({
          ...item,
          group: item.asset_template_name
        })),
        'label'
      )
    )

    const documents = orderBy(
      groupNormalizedList(
        (data?.documents ?? []).map((item) => ({
          ...item
        })),
        'label'
      )
    )

    let dashboards = map(data?.dashboards, ({ attributes: { id, title } }) => ({
      label: title,
      value: id
    }))

    dashboards = orderBy(
      groupNormalizedList(
        (dashboards ?? []).map((item) => ({
          ...item
        })),
        'label'
      )
    )

    setLists({
      profiles,
      templates,
      checklists,
      assetTemplates,
      documents,
      dashboards
    })
  }, [data, setValue])

  const updateProfile = (evt) => {
    const profileId = evt?.target?.value

    if (profileId) {
      const profile = first(
        filter(lists?.profiles, (item) => item.id === Number(profileId))
      )
      setSelectedProfile(profile)
    } else {
      setSelectedProfile({})
    }

    setValue('roles', [])
    setValue('templates_of_template_role', [])
    setValue('templates_of_form_role', [])
    setValue('folders_of_document_role', [])
    setValue('dashboards_of_dashboard_role', [])
  }

  const filterRole = (roleKey, roleList) =>
    first(
      roleList.filter((r) =>
        r?.name ? r?.name?.includes(roleKey) : r?.includes(roleKey)
      )
    )

  const getRole = (key, submitData) => {
    return (
      filterRole(key, submitData?.roles || []) ||
      filterRole(key, selectedProfile?.roles || []) ||
      {}
    )
  }

  const transformDataToSubmit = (submitData, profileRoles = []) => {
    const params = {
      profile_id: submitData.profile ? Number(submitData.profile) : null,
      roles: map(roles, 'name'),
      scope: {}
    }

    const templatesOfTemplateRole = submitData.templates_of_template_role
    const templatesOfFormRole = submitData.templates_of_form_role
    const checklistsOfChecklistRole = submitData.checklists_of_checklist_role
    const assetTemplatesOfAssetTemplateRole =
      submitData.asset_templates_of_asset_template_role
    const foldersOfDocumentRole = submitData.folders_of_document_role
    const dashboardsOfDashbordRole = submitData.dashboards_of_dashboard_role

    const getScopedRoles = (params, contextRoles, context) => {
      const allRoles = data?.roles?.[context]?.map(({ name }) => name)
      const roles = union(profileRoles, submitData?.roles)
      const role = roles?.find(({ name }) => {
        return allRoles.includes(name)
      })
      if (role?.name) {
        params.scope[role.name] = map(contextRoles, 'id')
      }
    }

    if (!isEmpty(templatesOfTemplateRole)) {
      getScopedRoles(params, templatesOfTemplateRole, 'template')
    }

    if (!isEmpty(templatesOfFormRole)) {
      const formRole = getRole('form', submitData)
      if (formRole?.name) {
        params.scope[formRole.name] = map(templatesOfFormRole, 'id')
      }
    }

    if (!isEmpty(checklistsOfChecklistRole)) {
      const checklistRole = getRole('checklist', submitData)
      if (checklistRole?.name) {
        params.scope[checklistRole.name] = map(checklistsOfChecklistRole, 'id')
      }
    }

    if (!isEmpty(assetTemplatesOfAssetTemplateRole)) {
      getScopedRoles(params, assetTemplatesOfAssetTemplateRole, 'asset')
    }

    if (!isEmpty(foldersOfDocumentRole)) {
      const documentRole = getRole('document', submitData)
      if (documentRole?.name) {
        params.scope[documentRole.name] = map(foldersOfDocumentRole, 'value')
      }
    }

    if (!isEmpty(dashboardsOfDashbordRole)) {
      const dashbordRole = getRole('dashboard', submitData)
      if (dashbordRole?.name) {
        params.scope[dashbordRole.name] = map(dashboardsOfDashbordRole, 'value')
      }
    }

    if (isEdit) {
      return params
    } else {
      const { profile_id, scope, roles } = params

      return {
        user: {
          email: submitData.email,
          roles_attributes: {
            scope,
            roles,
            profile_id
          }
        }
      }
    }
  }

  const handleErrors = (errors) => {
    const baseErrors = errors?.base
    if (!isEmpty(baseErrors)) {
      Notification({
        icon: 'error',
        text: baseErrors.join(', ')
      })
    } else if (!isEmpty(errors)) {
      for (var key in errors) {
        setError(key, { message: errors[key] })
      }
    }
  }

  const onSubmit = async (submitData) => {
    try {
      const params = transformDataToSubmit(submitData, selectedProfile?.roles)
      if (!isSoloPage) {
        onExternalSubmit(params)
      } else {
        setIsLoadingSubmit(true)

        const endpoint = isEdit ? OlingaAPI.updateUserRoles : OlingaAPI.newUser
        const response = await endpoint(user.id, params)
        if (response?.status === 200) {
          Notification({
            icon: 'success',
            text: response.data?.message
          })
          setIsLoadingSubmit(false)
          if (isEdit) {
            if (history?.location?.from) {
              history.goBack()
            } else {
              history.push(getRouteUrl('manageUsers'))
            }
          } else {
            history.push(getRouteUrl('manageUsers'))
          }
        } else {
          setIsLoadingSubmit(false)
          const enpointErrors = response?.response?.data?.errors
          if (enpointErrors) {
            handleErrors(enpointErrors)
          }
        }
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const onError = (error) => {
    if (error.templates_of_template_role || error.templates_of_form_role) {
      Notification({
        icon: 'warning',
        text: 'Select templates'
      })
    }
  }

  useEffect(() => {
    if (isEmpty(selectedProfile)) return setRolesByProfile([])

    const getRoleByName = (name) => {
      const role = filter(
        flatten(Object.values(data.roles)),
        (role) => role.name === name
      )
      if (!isEmpty(role)) return first(role)
      return {}
    }

    const selectedRoles = map(selectedProfile?.roles, (role) => {
      const r = getRoleByName(role?.name || role)
      return {
        label: r.label,
        description: r.description,
        name: r.name
      }
    })

    setRolesByProfile(uniqBy(selectedRoles, 'name'))
  }, [data?.roles, selectedProfile])

  const onChangeTemplatesSelector = (key, values) => {
    const templatesValue = []

    values.forEach((v) => {
      const name = v.name || v.label
      const value = v.value

      templatesValue.push({
        name,
        label: name,
        value,
        id: v.value
      })
    })
    setValue(key, templatesValue)
  }

  useEffect(() => {
    setProfileHasRolesByCategory(getHasRolesByCategory(rolesByProfile, false))
  }, [rolesByProfile])

  useEffect(() => {
    setHasUserRolesByCategory(getHasRolesByCategory(roles, false))
  }, [roles])

  const disabledRolesCategories = useMemo(() => {
    const result = {}
    Object.keys(emptyRolesByCategory).forEach((key) => {
      result[key] =
        hasUserRolesByCategory[key] || hasProfileRolesByCategory[key]
    })

    return result
  }, [hasUserRolesByCategory, hasProfileRolesByCategory])

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit(onSubmit, onError)()
    },
    reset() {
      reset()
    }
  }))

  return {
    register,
    name,
    templatesOfTemplateRole: templatesOfTemplateRole ?? [],
    templatesOfFormRole: templatesOfFormRole ?? [],
    checklistsOfChecklistRole: checklistsOfChecklistRole ?? [],
    assetTemplatesOfAssetTemplateRole: assetTemplatesOfAssetTemplateRole ?? [],
    foldersOfDocumentRole: foldersOfDocumentRole ?? [],
    dashboardsOfDashbordRole: dashboardsOfDashbordRole ?? [],
    isLoadingSubmit,
    isUserActive,
    templatesList: lists.templates,
    checklistsList: lists.checklists,
    assetTemplatesList: lists.assetTemplates,
    profilesList: lists?.profiles,
    documentsList: lists?.documents,
    dashboardsList: lists?.dashboards,
    hasProfileRolesByCategory,
    updateProfile,
    disabledRolesCategories,
    onChangeTemplatesSelector,
    hasUserRolesByCategory,
    user,
    setIsUserActive,
    handleSubmit,
    onSubmit,
    onError,
    selectedProfile,
    roles,
    setValue,
    getValues,
    pageTitle,
    templatesPageTitle,
    checklistsPageTitle,
    assetTemplatesPageTitle,
    errors,
    isEdit,
    rolesByProfile
  }
}

export default useManageUserForm
