import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Stack,
  Tag,
  Text
} from '@chakra-ui/react'
import { isNull, isUndefined } from 'lodash'
import React from 'react'
import { v4 as uuid } from 'uuid'
import TextClamper from '../../../components/TextClamper'
import { useRecursiveStructureContext } from '../../../contexts/RecursiveStructureSelection'
import { mapQuestionTypes } from '../../../pages/Form/FillForm/ActionsBar/constants'
import AttributeSwitch from '../../../pages/TemplateStructure/TemplateDetails/AttributeSwitch'
import BaseStructure, { getDefaultStyles } from '../BaseStructure'
import Child from '../Child'
import QuestionLayout from '../QuestionLayout'
import SelectionComponent from '../SelectionComponent'

const Logic = (props) => {
  const {
    id,
    text,
    question_type: questionType,
    main,
    compulsory,
    active,
    children,
    childrenIndexes,
    answer,
    answerLabel
  } = props

  const { allowSelection } = useRecursiveStructureContext()

  return (
    <BaseStructure
      childrenIndexes={childrenIndexes}
      type="logic"
      hasChildren={children.length}
      otherProps={{ ...props }}
    >
      <AccordionItem
        {...getDefaultStyles('logic')}
        sx={{
          _hover: {
            shadow: 'lg'
          }
        }}
      >
        <AccordionButton>
          <QuestionLayout
            leftSlot={
              <HStack spacing={3}>
                {allowSelection({ ...props, type: 'logic' }) && (
                  <SelectionComponent id={id} type="logic" {...props} />
                )}
                <Stack>
                  <HStack spacing={2} flexDir={['column', 'row']}>
                    <Text fontWeight="bold">Question: </Text>
                    <TextClamper lines={2} isHtml text={text} />
                  </HStack>
                  <HStack spacing={2} flexDir={['column', 'row']}>
                    <Text fontWeight="bold">Type: </Text>
                    <Text>{mapQuestionTypes[questionType]}</Text>
                  </HStack>
                  {!isNull(answer?.value) && !isUndefined(answer?.value) && (
                    <HStack spacing={2} color="orange.500">
                      <Text fontWeight="bold">{answerLabel}: </Text>
                      <Text>
                        {answer?.value ? (
                          answer?.value
                        ) : (
                          <Tag size="sm" colorScheme="gray">
                            Empty
                          </Tag>
                        )}
                      </Text>
                    </HStack>
                  )}
                </Stack>
              </HStack>
            }
            rightSlot={
              <>
                {main && <AttributeSwitch label="Main" answer={main} />}
                {compulsory && (
                  <AttributeSwitch label="Compulsory" answer={compulsory} />
                )}
                {compulsory && (
                  <AttributeSwitch label="Active" answer={active} />
                )}
              </>
            }
          />
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Stack gap={4} pt={4}>
            {children.map((child) => (
              <Child {...child} type="condition" key={uuid()} />
            ))}
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </BaseStructure>
  )
}

export default Logic
