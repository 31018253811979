import { EditIcon, ViewIcon } from '@chakra-ui/icons'
import { Flex, IconButton } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import DeleteButton from '../../../components/DeleteButton'
import { getRouteUrl } from '../../../helpers'

const EditAssetButton = ({ id, onDelete, permissions }) => (
  <>
    {permissions?.modify ? (
      <Flex gap={4} align="center">
        <Link to={getRouteUrl('editAsset', { id })}>
          <IconButton
            icon={<EditIcon />}
            colorScheme="blue"
            size="sm"
            variant="outline"
            aria-label={`Edit asset ${id}`}
          />
        </Link>
        <DeleteButton
          index={id}
          onDelete={onDelete}
          alertTitle="Delete asset"
          alertBody="This action cannot be undone."
          continueLabel="Yes, remove it!"
          size="sm"
          variant="outline"
          borderRadius={6}
        />
      </Flex>
    ) : permissions?.view ? (
      <Link to={getRouteUrl('editAsset', { id })}>
        <IconButton
          icon={<ViewIcon />}
          colorScheme="blue"
          size="sm"
          variant="outline"
          aria-label={`View asset ${id}`}
        />
      </Link>
    ) : null}
  </>
)

export default EditAssetButton
