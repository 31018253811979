import { first, isEmpty } from 'lodash'
import { IndexMap } from 'pages/TemplateEditor/helpers'
import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { getIdx } from '../../helpers'

const useFillFormSection = ({ field, localIndex }) => {
  const { unregister } = useFormContext()

  const [sections, setSections] = useState([
    { sequence: 1, section_question_id: field.id, idx: getIdx(localIndex) }
  ])
  const [removedSections, setRemovedSections] = useState([])

  const indexes = useMemo(() => {
    return new IndexMap()
  }, [])

  const onAdd = () => {
    const validRemovedLength = removedSections.filter((r) => r).length || 0
    const lastSequence = sections.length - validRemovedLength

    setSections([
      ...sections,
      {
        sequence: lastSequence + 1,
        section_question_id: field.id,
        idx: getIdx(localIndex),
        is_new: true
      }
    ])
  }

  const unregisterSectionQuestions = (dataKeys) => {
    dataKeys.forEach((el) => {
      const dataKeyValue = el?.dataset?.key
      if (!isEmpty(dataKeyValue)) {
        const regex = /questions.(?:...)/g
        const key = first(dataKeyValue?.match(regex))
        unregister(key)
      }
    })
  }

  const onRemove = (evt, sectionIndex) => {
    evt.preventDefault()
    const newRemovedSections = [...removedSections]
    newRemovedSections[sectionIndex] = true
    setRemovedSections([...newRemovedSections])
    let index = 0

    const updatedSections = sections.map((section, idx) => {
      if (!newRemovedSections[idx]) {
        section.sequence = index + 1
        index++
      }

      return section
    })

    setSections([...updatedSections])

    const parentElement =
      evt?.target?.parentElement?.parentElement?.parentElement
    const dataKeys = parentElement?.querySelectorAll('[data-key]')
    if (!isEmpty(dataKeys)) {
      unregisterSectionQuestions(dataKeys)
    }
  }

  return {
    onRemove,
    unregisterSectionQuestions,
    onAdd,
    indexes,
    sections,
    removedSections
  }
}

export default useFillFormSection
