import { setFormErrors } from 'helpers'
import { getRouteUrl } from 'helpers/admin'
import { useResize } from 'hooks'
import { isString, map, noop } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import AdminOlingaAPI from 'services/admin/AdminOlingaAPI'
import Notification from 'services/Notification'
import { v4 as uuidv4 } from 'uuid'

const useAssetDefaultFieldEditor = ({ data, id }) => {
  const [shouldRenderForm, setShouldRenderForm] = useState(!id)

  const { assetDefaultField, assetTypes, fieldTypes } = useMemo(() => {
    const { field_types } = data?.enumerators?.data ?? {
      field_types: []
    }
    if (id && data?.assetDefaultField) {
      return {
        assetDefaultField: {
          id,
          ...(data?.assetDefaultField?.data?.attributes ?? {})
        },
        fieldTypes: field_types
      }
    }

    return {
      assetDefaultField: {},
      fieldTypes: field_types
    }
  }, [data, id])

  const history = useHistory()

  const { navbarHeight } = useResize()

  const [breadcrumbName, setBreadcrumbName] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const fieldsRefs = useRef({})

  const formProps = useForm({
    defaultValues: {
      name: '',
      field_type: '',
      active: true,
      main: false,
      options: []
    }
  })

  useEffect(() => {
    if (id) {
      reset({
        name: assetDefaultField?.name,
        active: assetDefaultField?.active,
        main: assetDefaultField?.main,
        field_type: assetDefaultField?.field_type?.toLowerCase() || '',
        settings: {
          list_options: map(
            assetDefaultField?.settings?.list_options,
            (field) => {
              if (isString(field)) {
                return {
                  name: field,
                  _id: uuidv4()
                }
              } else if (field?.name) {
                return {
                  ...field,
                  _id: uuidv4()
                }
              } else {
                return {
                  name: '',
                  _id: uuidv4()
                }
              }
            }
          )
        }
      })
      setBreadcrumbName(assetDefaultField?.name)
      setShouldRenderForm(true)
    }
  }, [assetDefaultField, reset, id])

  const { handleSubmit, reset, setValue } = formProps

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)
      const endpointKey = id
        ? 'updateAssetDefaultField'
        : 'newAssetDefaultField'
      const response = await AdminOlingaAPI[endpointKey](id, {
        ...data,
        ...(data?.settings && {
          settings: {
            list_options: map(
              data?.settings?.list_options,
              (field) => field.name
            )
          }
        })
      })
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: 'Success'
        })

        history.push(getRouteUrl('assetDefaultFields'))
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeFieldOptionsList = (updatedList) => {
    setValue(
      'settings.list_options',
      updatedList.map((item) => ({
        ...item,
        name: item?.name ?? ''
      }))
    )
  }

  const onSubmitForm = () => handleSubmit(onSubmit)()

  const [focusedFieldIndex, setFocusedFieldIndex] = useState(null)

  const onAddFieldOption = (_, index) => {
    setFocusedFieldIndex(index)
  }

  return {
    assetTypes,
    fieldTypes,
    navbarHeight,
    formProps,
    onSubmitForm,
    isLoading,
    fieldsRefs,
    breadcrumbName,
    onChangeFieldOptionsList,
    onAddFieldOption,
    focusedFieldIndex,
    shouldRenderForm
  }
}

export default useAssetDefaultFieldEditor
