import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal'
import { HStack, IconButton, Tag, Text } from '@chakra-ui/react'
import { ViewIcon } from '@chakra-ui/icons'
import { chakraComponentsSizes } from '../../helpers'
import _ from 'lodash'

const AsyncContentModal = ({
  id,
  count,
  getHeader,
  getBody,
  footer,
  icon,
  fetchCallback,
  size,
  customButtonLabel,
  buttonMinWidth
}) => {
  const [asyncContent, setAsyncContent] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const ref = useRef({ open: () => ({}), close: () => ({}) })

  const fetchContent = async () => {
    setIsLoading(true)
    try {
      const content = await fetchCallback(id ?? '')
      if (content) {
        setAsyncContent(content)
        ref.current.open()
      }
    } catch (error) {
      //
    } finally {
      setIsLoading(false)
    }
  }

  const CountIcon = () => {
    return (
      <HStack flex={1} px={2} spacing={2} justify="center">
        <Text color="blue.500">
          {_.isEmpty(customButtonLabel) ? count : customButtonLabel}
        </Text>
        {icon}
      </HStack>
    )
  }

  if (!count || count === '0') {
    return <Tag color="gray">Empty</Tag>
  }

  return (
    <Modal
      scrollType="outside"
      footer={footer}
      header={getHeader(asyncContent)}
      body={getBody(asyncContent)}
      ref={ref}
      shouldInjectButton={false}
      size={size}
    >
      <IconButton
        icon={<CountIcon />}
        colorScheme="blue"
        size="sm"
        variant="outline"
        onClick={fetchContent}
        isLoading={isLoading}
        minW={buttonMinWidth}
      />
    </Modal>
  )
}

AsyncContentModal.defaultProps = {
  id: null,
  count: 0,
  icon: <ViewIcon />,
  getHeader: () => null,
  getBody: () => null,
  footer: null,
  size: 'xl',
  customButtonLabel: '',
  buttonMinWidth: '64px'
}

AsyncContentModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getHeader: PropTypes.func,
  getBody: PropTypes.func,
  getFooter: PropTypes.func,
  fetchCallback: PropTypes.func.isRequired,
  size: PropTypes.oneOf(chakraComponentsSizes),
  customButtonLabel: PropTypes.string,
  buttonMinWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default AsyncContentModal
