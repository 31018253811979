import React from 'react'
import { Container } from './styles'
import 'react-quill/dist/quill.snow.css'

const QuillViewer = ({ text }) => (
  <Container className="ql-snow">
    <div className="ql-editor">
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  </Container>
)

export default QuillViewer
