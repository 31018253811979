import React from 'react'
import PropTypes from 'prop-types'
import EmptyState from '../../EmptyState'
import { isEmpty, noop } from 'lodash'
import BoardElement from '../BoardElement'
import { mapQuestionTypes } from '../../../pages/Form/FillForm/ActionsBar/constants'
import { sanitizeHTML } from '../../../pages/Reports/ReportsEditor/helpers'

const BoardOperations = ({ operations, onChange, displayEmptyState }) => {
  if (displayEmptyState && isEmpty(operations)) {
    return <EmptyState />
  }
  return operations.map(
    (
      {
        label,
        value: v,
        symbol,
        color: operationColor,
        question_type: type,
        number
      },
      index
    ) => (
      <BoardElement
        label={type ? `Question ${mapQuestionTypes[type]}` : v}
        tooltip={symbol ? '' : sanitizeHTML(label)}
        value={v}
        onChange={onChange}
        key={index}
        color={operationColor || 'blue.500'}
        isOperation={!!symbol}
        isNumber={!!number}
      />
    )
  )
}

BoardOperations.defaultProps = {
  onChange: noop,
  operations: [],
  displayEmptyState: true
}

BoardOperations.propTypes = {
  operations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      symbol: PropTypes.bool,
      color: PropTypes.string,
      question_type: PropTypes.string,
      number: PropTypes.any
    })
  ),
  displayEmptyState: PropTypes.bool
}

export default BoardOperations
