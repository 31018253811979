import { Input } from '@chakra-ui/react'
import React from 'react'
import { useFormContext } from 'react-hook-form'

const Text = ({ index, hasPermissionToEdit, field: { _active, main } }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  return (
    <Input
      placeholder="Text field"
      {...register(`asset_fields_attributes.${index}.value`, {
        required: main
      })}
      isInvalid={!!errors?.asset_fields_attributes?.index?.value?.message}
      isDisabled={!_active}
      isReadOnly={!hasPermissionToEdit}
    />
  )
}

export default Text
