import React, { useCallback, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { globalEvents } from '../../../contexts/GlobalStateContext/events'
import { useGlobalEventListener } from '../../../hooks'
import BasePage from '../../../pages/BasePage'
import TemplateSettings from '../../../pages/TemplateSettings'
import OlingaAPI from '../../../services/OlingaAPI'

const { ON_REFRESH_FORM } = globalEvents

const TemplateSettingsPage = ({ id }) => {
  const [lastFocusedField, setLastFocusedField] = useState(null)
  const [renderKey, setRenderKey] = useState(uuidv4())

  useGlobalEventListener(
    useCallback((event) => {
      if (event.type === ON_REFRESH_FORM) {
        const lastFocusedField = event.payload
        setLastFocusedField(lastFocusedField)
        setRenderKey(uuidv4())
      }
    }, [])
  )

  return (
    <BasePage
      id={id}
      fetchWithoutId
      key={renderKey}
      resourceName="template"
      fetchCallback={{
        templateStructure: {
          callback: () => OlingaAPI.templateShow(id),
          params: {}
        },
        completeStructure: {
          callback: () => OlingaAPI.templateStructure(id),
          params: {}
        },
        enumerators: {
          callback: () => OlingaAPI.templateEditorEnumerators()
        },
        formFolders: {
          callback: () => OlingaAPI.foldersEnumerator('templates')
        },
        subUsersList: {
          callback: () =>
            OlingaAPI.businessSubUsersList('', {
              actives: true,
              include_current_user: true,
              all: true,
            })
        }
      }}
      otherParams={{
        id,
        lastFocusedField
      }}
    >
      <TemplateSettings />
    </BasePage>
  )
}

export default TemplateSettingsPage
