import { PlusSquareIcon } from '@chakra-ui/icons'
import { Button, SimpleGrid, theme, VStack } from '@chakra-ui/react'
import { noop } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useKeyPress from '../../../hooks/useKeyPress'
import Notification from '../../../services/Notification'
import InputNumber from '../../Inputs/Number'
import QuestionsSelector from '../../QuestionsSelector'
import CalculatedOperation from './CalculatedOperation'
import { defaultOperations } from './constants'

const isZero = (number) => number.match(/^[0]+$/)

const CalculatedOperations = ({
  initialSelectedNodes,
  onChange,
  structure,
  isEmpty,
  isDisabled,
  isMobile,
  operations
}) => {
  const ref = useRef({ open: noop, close: noop })

  const isEnterPressed = useKeyPress('Enter', true, true)

  const [number, setNumber] = useState('')

  const onApplyNumber = useCallback(() => {
    if (isZero(number) || !number) {
      return Notification({
        icon: 'warning',
        text: 'Invalid number'
      })
    }
    onChange({
      value: number,
      type: 'number',
      label: `number ${number}`,
      number: true,
      color: 'blue.500'
    })

    setNumber('')
  }, [number, onChange])

  useEffect(() => {
    if (isEnterPressed) {
      onApplyNumber()
    }
  }, [isEnterPressed, onApplyNumber])

  const onQuestionChoose = (chosenQuestion) => {
    const question = structure.linear.object[chosenQuestion]
    onChange({ ...(question ?? {}), label: question?.text ?? '' })
  }

  const onChooseOperation = (operation) => {
    onChange(operation)
  }

  const allOperations = useMemo(() => {
    const getOperatorColor = (value) => {
      switch (value) {
        case '(':
          return 'orange.500'
        case ')':
          return 'orange.500'
        default:
          return 'gray.900'
      }
    }

    const correctOrder = [
      'sum',
      'subtraction',
      'multiplication',
      'division',
      'opening_parentesis',
      'closing_parentesis'
    ]

    return [
      ...correctOrder.reduce((acc, key, index) => {
        let itemToPush = {}
        if (operations.mathOperators[index]?.label === key) {
          itemToPush = operations.mathOperators[index]
        } else {
          const item = operations.mathOperators.find(
            ({ label }) => label === key
          )
          itemToPush = item
        }

        return [
          ...acc,
          {
            ...itemToPush,
            symbol: true,
            color: getOperatorColor(itemToPush.value)
          }
        ]
      }, []),
      ...defaultOperations
    ]
  }, [operations])

  const shouldDisableOperations = (label) => {
    if (label.match('parentesis')) {
      return false
    }
    return label !== 'remove'
      ? isDisabled.shouldDisableOperations || isEmpty
      : isEmpty
  }
  return (
    <VStack>
      <VStack gap={4} justify="flex-start">
        <QuestionsSelector
          ref={ref}
          direction={isMobile ? 'column-reverse' : 'column'}
          childrenStructure={structure?.complete?.children ?? []}
          displayLabels
          initialSelectedNodes={initialSelectedNodes}
          onConfirm={onQuestionChoose}
          allowSelection={({
            type,
            question_type: questionType,
            id,
            compulsory,
            active
          }) =>
            type === 'question' &&
            compulsory &&
            active &&
            !initialSelectedNodes?.includes(id) &&
            ['counter'].includes(questionType)
          }
          selectionMode="single"
          label="Select a Question"
        >
          <Button
            leftIcon={<PlusSquareIcon />}
            colorScheme="blue"
            size="sm"
            isDisabled={isDisabled.shouldDisableChooseQuestion}
            isFullWidth
          >
            Select a question
          </Button>
        </QuestionsSelector>
        <VStack align="flex-start" gap={4}>
          <VStack w="100%" gap={2}>
            <InputNumber
              placeholder="Type a number"
              size="sm"
              value={number}
              onChange={setNumber}
              min={-Infinity}
              autoFocus
            />
            <Button
              leftIcon={<PlusSquareIcon />}
              size="sm"
              colorScheme="green"
              isFullWidth
              onClick={onApplyNumber}
            >
              Apply number
            </Button>
          </VStack>
          <SimpleGrid w="100%" spacing={2} gridTemplateColumns="repeat(2, 1fr)">
            {allOperations.map(
              ({ label, value, symbol, color, type, keyboardKey }, index) => {
                return (
                  <CalculatedOperation
                    key={index}
                    aria-label={label}
                    onChange={onChooseOperation}
                    operation={{
                      label,
                      value,
                      type: 'math_operator',
                      symbol,
                      color
                    }}
                    isDisabled={shouldDisableOperations(label)}
                    bg={
                      type === 'remove'
                        ? theme.colors.red[800]
                        : theme.colors.gray[900]
                    }
                    isFullWidth={type === 'remove'}
                    keyboardKey={keyboardKey || value}
                  >
                    {value}
                  </CalculatedOperation>
                )
              }
            )}
          </SimpleGrid>
        </VStack>
      </VStack>
    </VStack>
  )
}

export default CalculatedOperations
