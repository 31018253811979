import { useDisclosure } from '@chakra-ui/react'
import { debounce, groupBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

const useAutocomplete = ({
  groupByAttribute,
  asyncCallback,
  getNestedAttribute,
  searchInterval,
  onSelect
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [results, setResults] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const containerRef = useRef()

  const location = useLocation()

  const isGrouped = useMemo(() => {
    return !!groupByAttribute
  }, [groupByAttribute])

  const inputRef = useRef(null)

  useEffect(() => {
    return () => {
      setResults([])
    }
  }, [])

  const handleSearch = useCallback(
    async (value) => {
      setIsLoading(true)
      let searchResults = await asyncCallback(value)
      if (getNestedAttribute) {
        searchResults = searchResults?.map((data) => data[getNestedAttribute])
      }
      if (groupByAttribute) {
        searchResults = groupBy(searchResults, groupByAttribute)
      }
      setResults(searchResults)
      setIsLoading(false)
      onOpen()
    },
    [asyncCallback, getNestedAttribute, groupByAttribute, onOpen]
  )

  const changeInput = ({ target: { value } }) => {
    if (value.length >= 3) {
      search(value)
    } else {
      onClose()
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(
    debounce((value) => {
      handleSearch(value)
    }, searchInterval),
    []
  )

  const handleSelect = (result) => {
    onSelect(result)
  }

  useEffect(() => {
    onClose()
  }, [location.pathname, onClose])

  return {
    search,
    handleSelect,
    changeInput,
    handleSearch,
    inputRef,
    containerRef,
    isGrouped,
    isOpen,
    onOpen,
    onClose,
    results,
    isLoading
  }
}

export default useAutocomplete
