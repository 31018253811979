import { DownloadIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useMediaQuery
} from '@chakra-ui/react'
import MaterialIcon from 'components/MaterialIcon'
import { keys, pick } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { handleExportForms } from '../../../helpers'
import ExportPDFAction from './ExportPDFAction'

const sanitizeParams = (params) => {
  const selectedKeys = pick(params, [
    'start_date',
    'end_date',
    'text',
    'form_id',
    'last_sort',
    'export_options',
    'page',
    'per_page'
  ])
  if (keys(selectedKeys).includes('form_id')) {
    return {
      form_id: selectedKeys.form_id,
      last_sort: selectedKeys.last_sort,
      start_date: selectedKeys.start_date,
      export_options: selectedKeys.export_options
    }
  }
  return pick(params, [
    'start_date',
    'end_date',
    'text',
    'last_sort',
    'count_forms',
    'export_options',
    'page',
    'per_page'
  ])
}

const ExportForms = ({
  templateId,
  templateName,
  size,
  inline,
  prefix,
  params,
  ariaLabel,
  formCount,
  tooltipLabel,
  isDisabled = false,
}) => {
  const [isExporting, setIsExporting] = useState(false)

  const handleExport = (key, extraParams = {}) => {
    handleExportForms(
      templateId,
      templateName,
      key,
      sanitizeParams({ ...params, count_forms: formCount, ...extraParams }),
      setIsExporting
    )
  }

  const [isMobile] = useMediaQuery('(max-width: 799px)')

  const pdfParams = {
    onConfirm: handleExport,
    prefix,
    inline,
    size
  }

  const getButtons = () => {
    if (inline) {
      return (
        <Flex justify={isMobile ? 'space-between' : 'flex-end'}>
          <Button
            mr={4}
            leftIcon={<MaterialIcon icon="insert_drive_file" />}
            colorScheme="green"
            size={size}
            onClick={() => handleExport('xlsx')}
          >
            {prefix} as Spreadsheet
          </Button>
          {<ExportPDFAction {...pdfParams} />}
        </Flex>
      )
    }
    return (
      <Menu closeOnSelect>
        <MenuButton
          as={IconButton}
          icon={<DownloadIcon />}
          variant="outline"
          colorScheme="green"
          size={size}
          isLoading={isExporting}
          aria-label={ariaLabel}
          isDisabled={isDisabled}
        />
        <MenuList>
          <Box>
            <Tooltip label={tooltipLabel}>
              <Box>
                <MenuItem
                  alignItems="center"
                  icon={
                    <MaterialIcon
                      icon="insert_drive_file"
                      styles={{ fontSize: '20px' }}
                    />
                  }
                  onClick={() => handleExport('xlsx')}
                  aria-label={`${ariaLabel} option`}
                >
                  {prefix} as Spreadsheet
                </MenuItem>
                {<ExportPDFAction {...pdfParams} />}
              </Box>
            </Tooltip>
          </Box>
        </MenuList>
      </Menu>
    )
  }

  return getButtons()
}

ExportForms.defaultProps = {
  size: 'sm',
  inline: false,
  prefix: 'Export',
  searchParams: null,
  params: {},
  ariaLabel: ''
}

ExportForms.propTypes = {
  templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  templateName: PropTypes.string,
  searchParams: PropTypes.object,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  inline: PropTypes.bool,
  prefix: PropTypes.string,
  params: PropTypes.object,
  ariaLabel: PropTypes.string
}

export default ExportForms
