import { useMediaQuery, useOutsideClick } from '@chakra-ui/react'
import { chunk, defer, isFunction, isNumber } from 'lodash'
import { useCallback, useRef } from 'react'
import { useFieldArray } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useTemplateEditorContext } from '../../../../../contexts'
import { useGlobalStateContext } from '../../../../../contexts/GlobalStateContext'
import useTemplateEditorSettings from '../../../../../contexts/TemplateEditor/TemplateEditorSettings/useTemplateEditorSettings'
import { useGlobalEventListener } from '../../../../../hooks'
import { actions as FormActions } from '../../../../../pages/TemplateEditor/FormActions/actions'
import {
  changeQuestionsOrder,
  findParentNodeByCondition,
  getDepthLevel,
  getElementTypeFromKey,
  getPerformActionOnElementAttributes,
  getTypeFromAction,
  updateNestedQuestions,
  updateOrder
} from '../../helpers'

const {
  ADD_INSTRUCTION,
  ADD_LOGIC,
  ADD_QUESTION,
  ADD_SECTION,
  APPEND_INSTRUCTION,
  APPEND_LOGIC,
  APPEND_QUESTION,
  APPEND_SECTION,
  REMOVE,
  REMOVE_ALL_LOGICS,
  REMOVE_PERSISTED_ELEMENT,
  SET_DIALOG_CONTENT,
  SET_FOCUS
} = FormActions

const useTemplateFormOperations = ({
  control,
  getValues,
  indexes,
  setFocus
}) => {
  const {
    getDefaultInstructionField,
    getDefaultLogicField,
    getDefaultQuestionField,
    getDefaultSectionField
  } = useTemplateEditorSettings()

  const { focusedField } = useTemplateEditorContext()

  const { fields, replace, insert, append, remove } = useFieldArray({
    control,
    name: 'questions'
  })

  const outsideClickRef = useRef()

  const deleteElementRef = useRef()

  const history = useHistory()

  const { global$ } = useGlobalStateContext()

  const [isMobile] = useMediaQuery('(max-width: 730px)')

  const focusElement = useCallback(
    (elementKey) => {
      global$.next({
        type: SET_FOCUS,
        payload: elementKey
      })
    },
    [global$]
  )

  const addElement = useCallback(
    ({
      key,
      type = 'question',
      elementToBeAdded = null,
      shouldRemoveQuestion = false
    }) => {
      let returnedElement = null
      let element = elementToBeAdded
      let parentType = ''
      let shouldAddWrappedLogic = true

      const getElementByType = (type) => {
        switch (type) {
          case 'question':
            return getDefaultQuestionField()
          case 'instruction':
            return getDefaultInstructionField()
          case 'section':
            return getDefaultSectionField()
          case 'logic': {
            if (key) {
              const parentType = getElementTypeFromKey(key, getValues)
              shouldAddWrappedLogic =
                parentType !== 'template_questions_attributes'
              return getDefaultLogicField(shouldAddWrappedLogic)
            }
            return getDefaultLogicField(true)
          }
          default:
            return getDefaultQuestionField()
        }
      }

      if (!element) {
        element = getElementByType(type)
      }
      const insertNestedElement = (
        shouldInsertAsSibling = false,
        specifPosition = null
      ) => {
        const insert = (level, key) => {
          let currentQuestions = level.questions
          let focusedElement = key
          if (isNumber(specifPosition)) {
            currentQuestions.splice(specifPosition, 0, element)
            focusedElement = `${key}.questions.${specifPosition}`
          } else {
            currentQuestions = currentQuestions.concat(element)
            focusedElement = `${key}.questions.${currentQuestions.length - 1}`
          }
          updateOrder(currentQuestions)
          return { updatedQuestions: currentQuestions, focusedElement }
        }
        const { updatedQuestions, focusedElement } = updateNestedQuestions(
          shouldInsertAsSibling ? key.split('.').slice(0, -2).join('.') : key,
          getValues,
          insert
        )
        if (elementToBeAdded && shouldRemoveQuestion) {
          const _question = elementToBeAdded
          const questionKey = _question._key
          if (questionKey) {
            const splittedKey = questionKey.split('.')
            const index = Number(splittedKey.slice(-1))
            const isNested = chunk(splittedKey, 2).length > 1
            if (isNested) {
              const parentKey = splittedKey.slice(0, -2).join('.')
              const level = getDepthLevel(parentKey, {
                questions: updatedQuestions
              })
              level?.questions?.splice(index, 1)
              updateOrder(level?.questions)
            } else {
              updatedQuestions.splice(index, 1)
              updateOrder(updatedQuestions)
            }
          }
        }
        updateOrder(updatedQuestions)
        replace(updatedQuestions)
        focusElement(focusedElement)
        if (shouldRemoveQuestion) {
          const focusKey = elementToBeAdded._focusKey
          focusElement(focusKey)
        } else {
          defer(() => {
            let field = null
            if (type === 'question' || type === 'instruction') {
              field = 'text'
            } else if (type === 'section') {
              field = 'name'
            } else if (type === 'logic') {
              if (shouldAddWrappedLogic) {
                field = 'text'
              } else {
                field = 'value'
              }
            }
            defer(() => {
              if (getValues(key)?._selectedTab === 0) {
                setFocus(`${focusedElement}.${field}`)
              }
            })
          })
        }
        return focusedElement
      }
      if (key) {
        const {
          isRootElement,
          newPosition: position,
          focusedElement
        } = getPerformActionOnElementAttributes(key)

        parentType = getElementTypeFromKey(key, getValues)

        if (isRootElement) {
          const focusElementType = focusedElement
            ? getValues(focusedElement)?.origin
            : null
          const shouldInsertNestedElement =
            focusElementType === 'question_logics_attributes' ||
            parentType === 'section_questions_attributes' ||
            (parentType === 'template_questions_attributes' && type === 'logic')
          if (shouldInsertNestedElement) {
            returnedElement = insertNestedElement()
          } else {
            insert(position, element)
            focusElement(focusedElement)
            returnedElement = focusedElement
          }
        } else {
          if (
            parentType === 'instruction' ||
            (parentType === 'template_questions_attributes' && type !== 'logic')
          ) {
            returnedElement = insertNestedElement(true, position)
          } else {
            returnedElement = insertNestedElement()
          }
        }
      } else {
        element.order = fields.length
        append(element)
        if (element._shouldFocusFirstChild) {
          focusElement(`questions.${element.order}`)
          returnedElement = `questions.${element.order}.questions.0`
        } else {
          focusElement(`questions.${element.order}`)
          returnedElement = `questions.${element.order}`
        }
      }
      indexes.clearKeys()

      return returnedElement
    },
    [
      indexes,
      getDefaultQuestionField,
      getDefaultInstructionField,
      getDefaultSectionField,
      getDefaultLogicField,
      getValues,
      replace,
      focusElement,
      setFocus,
      insert,
      fields.length,
      append
    ]
  )

  const removeElement = useCallback(
    (key) => {
      const { isRootElement, index } = getPerformActionOnElementAttributes(key)
      const parentKey = key.split('.').slice(0, -2).join('.')
      if (isRootElement) {
        remove(index)
        focusElement(null)
      } else {
        const removeQuestion = (level) => {
          const { questions } = level
          questions.splice(index, 1)
          level.questions = [...questions]
          return {
            updatedQuestions: questions
          }
        }
        const { updatedQuestions } = updateNestedQuestions(
          parentKey,
          getValues,
          removeQuestion
        )
        updateOrder(updatedQuestions)
        replace(updatedQuestions)
        focusElement(parentKey)
      }
      indexes.clearKeys()
    },
    [focusElement, getValues, indexes, remove, replace]
  )

  const removeChildElements = useCallback(
    (key) => {
      const removeQuestion = (level) => {
        let { questions } = level
        questions = []
        level.questions = []
        return {
          updatedQuestions: questions
        }
      }
      const { updatedQuestions } = updateNestedQuestions(
        key,
        getValues,
        removeQuestion
      )
      replace(updatedQuestions)
      indexes.clearKeys()
    },
    [getValues, indexes, replace]
  )

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const { destination, source, type, draggableId } = result
    const destinationSplitted = draggableId.split('.')
    destinationSplitted[destinationSplitted.length - 1] = destination.index

    if (type === 'root') {
      const updatedQuestions = changeQuestionsOrder({
        indexes,
        source,
        destination,
        questionsArray: getValues().questions
      })
      updateOrder(updatedQuestions)
      replace(updatedQuestions)
    } else {
      const updateQuestions = (level) => {
        const { questions } = level
        const updatedQuestions = changeQuestionsOrder({
          indexes,
          source,
          destination,
          questionsArray: questions
        })
        updateOrder(updatedQuestions)
        return {
          updatedQuestions
        }
      }
      const { updatedQuestions } = updateNestedQuestions(
        type,
        getValues,
        updateQuestions
      )
      updateOrder(updatedQuestions)
      replace(updatedQuestions)
    }

    if (isMobile) {
      const destinationKey = destinationSplitted.join('.')
      defer(() => {
        focusElement(destinationKey)
      })
    }
  }

  useGlobalEventListener(
    useCallback(
      (event) => {
        const { payload } = event
        const key = payload?.focusedField
        switch (event.type) {
          case ADD_QUESTION:
          case ADD_INSTRUCTION:
          case ADD_SECTION:
          case ADD_LOGIC:
            return addElement({
              key,
              type: getTypeFromAction(event.type)
            })

          case APPEND_QUESTION:
          case APPEND_INSTRUCTION:
          case APPEND_LOGIC:
          case APPEND_SECTION:
            return addElement({
              key: null,
              type: getTypeFromAction(event.type),
              elementToBeAdded: payload?.element || null
            })

          case REMOVE:
            return removeElement(key)

          case REMOVE_PERSISTED_ELEMENT: {
            const { dialogContent } = payload

            deleteElementRef.current = payload

            global$.next({
              type: SET_DIALOG_CONTENT,
              payload: dialogContent
            })

            break
          }

          case REMOVE_ALL_LOGICS:
            return removeChildElements(key)
        }
      },
      [addElement, global$, removeChildElements, removeElement]
    )
  )

  const handleClickAway = (event) => {
    if (
      findParentNodeByCondition(event.target, (parent) => {
        if (
          parent &&
          isFunction(parent?.getAttribute) &&
          (parent.getAttribute('data-id') === 'form-actions' ||
            parent.getAttribute('data-focus') === 'true' ||
            parent.classList.contains('Toastify__toast'))
        ) {
          return true
        }
        return false
      })
    ) {
      return null
    } else {
      focusElement(null)
    }
  }

  const handleScrollToFocusedField = () => {
    const field = document.querySelector(`[data-position="${focusedField}"]`)
    if (field) {
      field.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }

  const onGoBack = () => {
    history.goBack()
  }

  useOutsideClick({
    ref: outsideClickRef,
    handler: handleClickAway
  })

  return {
    fields,
    onDragEnd,
    handleClickAway,
    handleScrollToFocusedField,
    onGoBack,
    outsideClickRef,
    deleteElementRef
  }
}

export default useTemplateFormOperations
