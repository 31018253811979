import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/number-input'
import React from 'react'

const InputNumber = ({
  value,
  onChange,
  size,
  isInvalid,
  placeholder,
  min,
  autoFocus
}) => (
  <NumberInput
    value={value}
    onChange={onChange}
    size={size}
    isInvalid={isInvalid}
    min={min}
    placeholder={placeholder}
    data-testid='number-input'
  >
    <NumberInputField autoFocus={autoFocus} />
    <NumberInputStepper>
      <NumberIncrementStepper />
      <NumberDecrementStepper />
    </NumberInputStepper>
  </NumberInput>
)

InputNumber.defaultProps = {
  size: 'md',
  placeholder: 'Type a number',
  min: 0,
  autoFocus: false
}

export default InputNumber
