import actions from '../../actions'

const {
  SET_DEPENDENCIES,
  SET_SHOULD_FETCH_AGAIN,
  SET_ASSETS_TEMPLATES,
  SET_IS_LOADING
} = actions.edit_template

const initialState = {
  isLoading: false,
  dependencies: {},
  shouldfetchAgain: true,
  assetsTemplates: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEPENDENCIES: {
      state = { ...state, dependencies: action.payload }
      return state
    }
    case SET_SHOULD_FETCH_AGAIN: {
      state = { ...state, shouldfetchAgain: action.payload }
      return state
    }
    case SET_IS_LOADING: {
      state = { ...state, isLoading: action.payload }
      return state
    }
    case SET_ASSETS_TEMPLATES: {
      state = { ...state, assetsTemplates: action.payload }
      return state
    }
    default:
      return state
  }
}

export default reducer
