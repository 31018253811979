import { Button } from '@chakra-ui/button'
import { Stack } from '@chakra-ui/layout'
import { RadioGroup as ChakraRadioGroup, Radio } from '@chakra-ui/radio'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

const RadioGroup = ({
  options,
  value,
  onChange,
  isInvalid,
  isClearable,
  direction,
  align = 'center'
}) => {
  const [isChecked, setIsChecked] = useState(value || '')

  useEffect(() => {
    setIsChecked(value)
  }, [value])

  const onClear = () => {
    onChange('')
  }

  return (
    <span data-testid='radio-group'>
      <ChakraRadioGroup value={isChecked} onChange={onChange}>
        <Stack align={align} flexDir={direction} gap={2}>
          {options.map((opt, idx) => (
            <Radio
              mt="0!important"
              key={idx}
              value={opt.value}
              isInvalid={isInvalid}
              mb={0}
              data-testid='radio-group-option'
            >
              {opt?.label ?? opt.value}
            </Radio>
          ))}
        </Stack>
      </ChakraRadioGroup>
      {isChecked && isClearable && (
        <Button data-testid='radio-group-clear-action' mt={4} size="sm" onClick={onClear}>
          Clear
        </Button>
      )}
    </span>
  )
}

RadioGroup.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  isClearable: PropTypes.bool,
  direction: PropTypes.string
}

RadioGroup.defaultProps = {
  options: [],
  onChange: _.noop,
  isInvalid: false,
  isClearable: true,
  direction: 'column'
}

export default RadioGroup
