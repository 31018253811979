import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import {
  getDashboardUrlParams,
  getRouteUrl,
  setFormErrors
} from '../../../../helpers'
import Notification from '../../../../services/Notification'
import OlingaAPI from '../../../../services/OlingaAPI'

const useResetPassword = ({ token }) => {
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const { register, handleSubmit, setError } = useForm({})

  const onSubmit = async (values) => {
    setIsLoading(true)
    const params = {
      user: {
        password: values.password,
        password_confirmation: values.password_confirmation,
        reset_password_token: token
      }
    }
    setIsLoading(true)
    try {
      const response = await OlingaAPI.resetPassword(params)
      if (response?.user) {
        const { dashboardId, userType } = getDashboardUrlParams(response?.user)
        history.push({
          pathname: getRouteUrl(dashboardId, { userType })
        })
        window.location.reload()
      }
    } catch (error) {
      setFormErrors(error, setError, Notification)
    } finally {
      setIsLoading(false)
    }
  }

  const onError = (error) => {
    setErrors(error)
  }

  return {
    handleSubmit,
    onError,
    onSubmit,
    register,
    errors,
    isLoading
  }
}

export default useResetPassword
