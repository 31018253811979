import { Button } from '@chakra-ui/button'
import { Checkbox } from '@chakra-ui/checkbox'
import { EmailIcon } from '@chakra-ui/icons'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/input'
import { Box, Flex } from '@chakra-ui/layout'
import { Select } from '@chakra-ui/select'
import React from 'react'
import PasswordInput from '../../../components/Inputs/Password'
import useSignIn from './useSignIn'

const SignIn = ({ usersProfile, setCurrentPage, defaultPersonableType }) => {
  const {
    handleSubmit,
    onSubmit,
    onError,
    register,
    isLoading,
    errors,
    email,
    onForgotPassword
  } = useSignIn({
    setCurrentPage,
    defaultPersonableType
  })

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Box p={1}>
        <InputGroup>
          <InputLeftAddon>
            <EmailIcon />
          </InputLeftAddon>
          <Input
            type="email"
            placeholder="Email"
            {...register('email', { required: true })}
            isInvalid={!!errors.email}
            isDisabled={isLoading}
            autoFocus={!email}
          />
        </InputGroup>
      </Box>
      <Box p={1}>
        <PasswordInput
          placeholder="Password"
          register={register}
          name="password"
          isRequired
          isInvalid={!!errors.password}
          isDisabled={isLoading}
          shouldShowLockIcon
          autoFocus={!!email}
        />
      </Box>
      <Box p={1}>
        <Select
          isDisabled={isLoading}
          placeholder="Select Profile"
          {...register('personable_type', { required: true })}
          isInvalid={!!errors.personable_type}
        >
          {usersProfile.map((user) => (
            <option key={user} value={user}>
              {user}
            </option>
          ))}
        </Select>
      </Box>
      <Flex justify="space-between" w="100%" p={2}>
        <Checkbox {...register('remember_me', { required: false })}>
          Remember me
        </Checkbox>
        <Button onClick={onForgotPassword} colorScheme="blue" variant="link">
          Forgot password
        </Button>
      </Flex>
      <Flex justify="flex-end" w="100%" p={2}>
        <Button colorScheme="orange" type="submit" isLoading={isLoading}>
          Login
        </Button>
      </Flex>
    </form>
  )
}

export default SignIn
