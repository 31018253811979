import React from 'react'
import BasePage from '../../../pages/BasePage'
import FormAnswer from '../../../pages/FormAnswer'
import OlingaAPI from '../../../services/OlingaAPI'

const FormAnswerPage = ({ templateId, formId }) => {
  return (
    <BasePage
      id={templateId}
      resourceName="data"
      fetchCallback={OlingaAPI.templateShow}
      otherParams={{
        templateId,
        formId
      }}
    >
      <FormAnswer />
    </BasePage>
  )
}

export default FormAnswerPage
