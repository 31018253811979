import { Box, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { getStyles } from './styles'

const BoardElement = ({ label, tooltip, color, isOperation, isNumber }) => {
  return (
    <Tooltip hasArrow label={tooltip}>
      <Box
        bg={color}
        color="white"
        {...getStyles(isOperation, isNumber, color)}
      >
        {label}
      </Box>
    </Tooltip>
  )
}

export default BoardElement
