import { isNumber } from 'lodash'
import { useEffect, useState } from 'react'
import OlingaAPI from 'services/OlingaAPI'

const useAssetSelector = ({ value, onChange }) => {
  const [assets, setAssets] = useState([])

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const {
          data: { assets }
        } = await OlingaAPI.assetsEnumerators()
        setAssets(assets)

        if (isNumber(value)) {
          const selectedAsset = assets?.find(
            ({ value: assetId }) => assetId === value
          )
          if (selectedAsset) {
            onChange([selectedAsset, {}])
          }
        }
      } catch (error) {
        //
      }
    }

    fetchAssets()
  }, [value, onChange])

  return {
    assets
  }
}

export default useAssetSelector
