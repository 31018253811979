import { Flex } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import CalculatedFieldsModal from '../../../../pages/TemplateSettings/TemplateSettingsTabs/components/CalculatedFields/CalculatedFieldsModal'
import {
  calculatedFieldsEnumTypes,
  templateStructureTypes
} from '../../../../pages/TemplateSettings/TemplateSettingsTabs/components/CalculatedFields/constants'
import BoardOperations from '../../BoardOperations'

const EditableOperationCard = (props) => {
  const { index, attributeKey, watch } = props
  const operations = watch(`${attributeKey}.${index}.expression.data`, [])
  return (
    <Flex gap={4} align="center" flexWrap="wrap" w="100%">
      {!isEmpty(operations) && (
        <Flex
          gap={4}
          align="center"
          flex={1}
          flexWrap="wrap"
          flexDir={['column', 'row']}
        >
          <BoardOperations operations={operations} displayEmptyState={false} />
        </Flex>
      )}
      <CalculatedFieldsModal
        {...props}
        isAddAction={!operations || isEmpty(operations)}
      />
    </Flex>
  )
}

EditableOperationCard.propTypes = {
  operations: calculatedFieldsEnumTypes,
  structure: templateStructureTypes,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentKey: PropTypes.string,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  allowSetOperator: PropTypes.bool,
  setEditionAlertMessage: PropTypes.func,
  alertMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setAddOperatorsButtonRef: PropTypes.any,
  setEditOperationsButtonRef: PropTypes.any
}

export default EditableOperationCard
