import colors from '../../utils/colors'
import { Button } from '@chakra-ui/button'
import styled from '@emotion/styled'
import theme from '@chakra-ui/theme'

export const LinkButton = styled(Button)`
  background-color: #fff !important;
  color: ${theme.colors.gray[400]} !important;
  display: flex;
  padding: 0;
  align-items: center;
  font-weight: normal;
  width: 100%;
  &:focus {
    box-shadow: none !important;
  }

  & svg {
    color: ${colors.primary};
  }
`

export const Icon = styled.div`
  .material-icons {
    margin-right: 4px;
    color: ${colors.primary};
  }
`

export const Wrapper = styled.div`
  color: #54433a;
  font-weight: bold;
  width: max-content;
  max-width: 100%;
  & .chakra-breadcrumb__list {
    margin-bottom: 0px;
  }

  & a {
    color: ${colors.primary};
    font-weight: normal;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: #e77d20;
    }
  }
  & .chakra-breadcrumb__list-item {
    &:last-of-type {
      & .chakra-breadcrumb__link {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
`
