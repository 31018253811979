import { Box, Center, CircularProgress } from '@chakra-ui/react'
import { styles } from './styles'
import React from 'react'

const OverlayLoading = () => {
  return (
    <Box {...styles}>
      <Center>
        <CircularProgress isIndeterminate color="orange" />
      </Center>
    </Box>
  )
}

export default OverlayLoading
