import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Stack
} from '@chakra-ui/react'
import { orderBy } from 'lodash'
import React, { memo } from 'react'
import DefaultFieldItem from './DefaultFieldItem'
import TypeFieldItem from './TypeFieldItem'

const SidebarContent = ({
  fieldTypes,
  defaultFields,
  onOpenFieldTypeForm,
  onOpenDefaultFieldConfirmation,
  isDisabled,
  hasMainFieldSet
}) => {
  return (
    <Stack w="100%" py={8} overflowY="auto">
      <Heading pb={2} size="md">
        Add Fields
      </Heading>

      <Accordion allowToggle allowMultiple defaultIndex={[0, 1]}>
        <AccordionItem>
          <h2>
            <AccordionButton px="0">
              <Box as="span" flex="1" textAlign="left">
                Default Fields
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel px="0" pb={4} h="100%">
            <Stack gap={2} pt={2} h="100%" maxH="350px" overflowY="auto">
              {orderBy(defaultFields, ['main', 'name'], ['desc', 'asc']).map(
                (field, index) => {
                  return (
                    <DefaultFieldItem
                      key={index}
                      index={index}
                      field={field}
                      isDisabled={isDisabled}
                      onOpenFieldTypeForm={onOpenDefaultFieldConfirmation}
                      hasMainFieldSet={hasMainFieldSet}
                    />
                  )
                }
              )}
            </Stack>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton px="0">
              <Box as="span" flex="1" textAlign="left">
                Field Types
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel
            px="0"
            pb={4}
            height="100%"
            maxH={['300px', '50%']}
            overflowY="auto"
          >
            <Stack gap={2} pt={2} h="100%" maxH="350px" overflowY="auto">
              {fieldTypes.map(({ value: type, label }, index) => {
                return (
                  <TypeFieldItem
                    key={index}
                    index={index}
                    label={label}
                    type={type}
                    isDisabled={isDisabled}
                    onOpenFieldTypeForm={onOpenFieldTypeForm}
                  />
                )
              })}
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  )
}

export default memo(SidebarContent)
