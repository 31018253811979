import { Box, Flex, Stack, Switch, Text } from '@chakra-ui/react'
import React from 'react'
import FloatingLabelBox from '../../../../components/FloatingLabelBox'
import LinkWrapper from '../../../../components/LinkWrapper'
import { getRouteUrl } from '../../../../helpers'
import { usePageProperties } from '../../../../hooks'
import { mapQuestionTypes } from '../../../../pages/Form/FillForm/ActionsBar/constants'
import Field from '../Field'

const QuestionCard = ({
  id,
  main,
  active,
  compulsory,
  questionType,
  templateId,
  text
}) => {
  const { shouldCreate: hasPermissionToCreateTemplates } = usePageProperties({
    pageName: 'templates'
  })
  if (!id) {
    return null
  }
  return (
    <Box p={4} shadow="md">
      <FloatingLabelBox label="Question">
        <Stack gap={2} w="100%">
          <Field label="ID">{<Text fontSize="sm">{id}</Text>}</Field>
          {templateId && hasPermissionToCreateTemplates && (
            <Field label="Form options">
              <Stack>
                <LinkWrapper
                  size="md"
                  url={getRouteUrl('fillForm', { id: templateId })}
                >
                  Fill form
                </LinkWrapper>
                <LinkWrapper
                  size="md"
                  url={getRouteUrl('templateForm', { id: templateId })}
                >
                  Form responses
                </LinkWrapper>
              </Stack>
            </Field>
          )}
          <Field label="Active">
            <Flex gap={2}>
              <Switch
                isDisabled
                size="sm"
                colorScheme="green"
                defaultChecked={active}
              ></Switch>
              <Text fontSize="sm">{active === true ? 'Yes' : 'No'}</Text>
            </Flex>
          </Field>
          <Field label="Main">
            <Flex gap={2}>
              <Switch
                isDisabled
                size="sm"
                colorScheme="green"
                defaultChecked={main}
              ></Switch>
              <Text fontSize="sm">{main === true ? 'Yes' : 'No'}</Text>
            </Flex>
          </Field>
          <Field label="Compulsory">
            <Flex gap={2}>
              <Switch
                isDisabled
                size="sm"
                colorScheme="green"
                defaultChecked={compulsory}
              ></Switch>
              <Text fontSize="sm">{compulsory === true ? 'Yes' : 'No'}</Text>
            </Flex>
          </Field>
          <Field label="Question type">
            {
              <Text fontSize="sm" isTruncated>
                {mapQuestionTypes[questionType]}
              </Text>
            }
          </Field>
          <Field label="Question title">
            {<Text fontSize="sm" dangerouslySetInnerHTML={{ __html: text }} />}
          </Field>
        </Stack>
      </FloatingLabelBox>
    </Box>
  )
}

export default QuestionCard
