import { omit } from 'lodash'
import AssetDefaultFieldEditor from 'pages/admin/AssetDefaultFields/AssetDefaultFieldEditor'
import React from 'react'
import BasePage from '../../../../pages/BasePage'
import AdminOlingaAPI from '../../../../services/admin/AdminOlingaAPI'

const AssetDefaultFieldEditorPage = ({ id }) => {
  const callbacks = omit(
    {
      assetDefaultField: {
        callback: () => AdminOlingaAPI.assetDefaultField(id),
        params: {}
      },
      enumerators: {
        callback: AdminOlingaAPI.assetDefaultFieldsEnumerator,
        params: {}
      }
    },
    [!id || id === 'new' ? 'assetDefaultField' : ''].filter((v) => v)
  )
  return (
    <BasePage
      fetchCallback={callbacks}
      id={id}
      fetchWithoutId
      resourceName="data"
      otherParams={{
        id: id === 'new' || !id ? null : id
      }}
    >
      <AssetDefaultFieldEditor />
    </BasePage>
  )
}

export default AssetDefaultFieldEditorPage
