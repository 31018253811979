import Dashboard from 'pages/Dashboard/v2'
import React from 'react'
import BasePage from '../../../pages/BasePage'
import OlingaAPI from '../../../services/OlingaAPI'

const DashboardPage = () => {
  return (
    <BasePage
      fetchCallback={{
        dashboardsEnums: {
          callback: () => OlingaAPI.getDashboardEnums(),
          params: {}
        },
      }}
      id={null}
      fetchWithoutId
      resourceName="data"
    >
      <Dashboard />
    </BasePage>
  )
}

export default DashboardPage
