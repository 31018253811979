import { Button, Stack } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import { getRouteUrl } from '../../helpers'
import FormDetails from '../Form/FormDetails'
import TemplatePageBreadcrumb from '../Template/TemplatePagesBreadcrumb'
import useFormAnswer from './useFormAnswer'

const FormAnswer = ({ data, templateId, formId }) => {
  const { templateDetails, pageTitle, singularTitle, cancelRouteParams } =
    useFormAnswer({
      data,
      templateId
    })

  if (!templateId || !formId || isEmpty(templateDetails)) {
    return null
  }

  return (
    <Stack>
      <PageHeader
        title={`Form response ${formId}`}
        Breadcrumb={
          <TemplatePageBreadcrumb
            templateId={templateId}
            templateDetails={templateDetails}
            pageTitle={pageTitle}
            singularTitle={singularTitle}
            routeName={[
              {
                title: 'Form responses',
                url: getRouteUrl(cancelRouteParams?.name, {
                  id: cancelRouteParams?.id
                })
              },
              {
                title: `Form response ${formId}`
              }
            ]}
          />
        }
        Actions={
          <>
            <Link
              to={getRouteUrl(cancelRouteParams?.name, {
                id: cancelRouteParams?.id
              })}
            >
              <Button>Cancel</Button>
            </Link>
          </>
        }
      />
      <Stack gap={4}>
        <FormDetails
          formId={formId}
          templateId={templateId}
          templateName={templateDetails?.name}
        />
      </Stack>
      <Link
        to={getRouteUrl(cancelRouteParams?.name, { id: cancelRouteParams?.id })}
      >
        <Button>Cancel</Button>
      </Link>
    </Stack>
  )
}

export default FormAnswer
