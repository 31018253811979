import { DownloadIcon } from '@chakra-ui/icons'
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import useExportAssets from './hooks/useExportAssets'

const ExportAssets = ({ assetTemplateId, variant = 'single' }) => {
  const { handleExport, isLoading } = useExportAssets()
  if (variant === 'single') {
    return (
      <Button
        isLoading={isLoading}
        variant="outline"
        size="sm"
        colorScheme="blue"
        onClick={() => handleExport(assetTemplateId)}
      >
        Export Assets
      </Button>
    )
  }
  if (variant === 'all') {
    return (
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<DownloadIcon />}
          isLoading={isLoading}
          colorScheme="green"
          variant="outline"
        />
        <MenuList>
          <MenuItem onClick={() => handleExport(assetTemplateId)}>
            Export all as Spreadsheet
          </MenuItem>
        </MenuList>
      </Menu>
    )
  }

  return null
}

ExportAssets.defaultProps = {
  variant: 'single'
}

ExportAssets.propTypes = {
  variant: PropTypes.string,
  assetTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default ExportAssets
