import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Divider,
  Flex,
  IconButton,
  Select,
  Spacer,
  Stack
} from '@chakra-ui/react'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTemplateEditorContext } from '../../../../contexts'
import Component from '../../Component'
import Ghost from '../../Component/Ghost'
import Dropzone from '../../Dropzone'
import { Wrapper as FocusButton } from '../../FormActions/MobileActions/styles'
import { ChildrenWrapper } from '../../styles'
import useLogicOperations from './hooks/useLogicOperations'
import LogicQuestions from './LogicQuestions'
import MaterialIcon from '../../../../components/MaterialIcon'

const Logic = ({ currentKey, questionType, optionsKey, parentKey }) => {
  const { onChangeOptions, getOptionLabel, isFocused, options, handleFocus } =
    useLogicOperations({
      currentKey,
      questionType,
      parentKey
    })

  const { register, control } = useFormContext()

  const { handleFocusedField, focusedField, ghostElement, indexes } =
    useTemplateEditorContext()

  const [questions, id, currentOptionsValue] = useWatch({
    name: [
      `${currentKey}.questions`,
      `${currentKey}._id`,
      `${currentKey}.aux_options`
    ],
    control
  })

  return (
    <Box
      bg="white"
      border="1px"
      borderColor={isFocused ? 'teal.500' : 'gray.200'}
      borderRadius="2px"
      p="3"
      mb="3"
      data-removable={!id}
      data-position={currentKey}
      data-id="is-condition"
      data-type="logic"
    >
      <Flex align="center">
        <Stack minW="50%">
          <Select
            mb="12px"
            {...register(`${currentKey}.question_logic_option_id`, {
              required: true
            })}
            onFocus={() => handleFocusedField(currentKey)}
            icon={<ChevronDownIcon />}
          >
            {options &&
              options.map((logic) => (
                <option key={logic.id} value={logic.id}>
                  {logic.text}
                </option>
              ))}
          </Select>
          <LogicQuestions
            currentKey={currentKey}
            currentOptionsValue={currentOptionsValue}
            getOptionLabel={getOptionLabel}
            optionsKey={optionsKey}
            questionType={questionType}
            onChangeOptions={onChangeOptions}
          />
        </Stack>
        <Spacer />
        <Flex gap={4} align="center">
          <FocusButton
            color="#36cec8"
            noborder
            onClick={() => handleFocusedField(currentKey)}
          >
            <MaterialIcon icon='center_focus_weak' />
          </FocusButton>
          <IconButton
            size="sm"
            aria-label="Expand Logic"
            icon={isFocused ? <ChevronUpIcon /> : <ChevronDownIcon />}
            colorScheme="teal"
            variant="outline"
            onClick={(event) => handleFocus(event, isFocused)}
          />
        </Flex>
      </Flex>
      {isFocused && (
        <Box>
          <Divider my="6px" />
          {((currentKey === focusedField && !!ghostElement) ||
            questions?.length > 0) && (
            <ChildrenWrapper>
              <Dropzone
                fromType="logic"
                isNested
                id={currentKey}
                type={currentKey}
              >
                {questions &&
                  questions.map((question, index) => {
                    const newKey = currentKey + `.questions.${index}`
                    const key = indexes.getUniqKey(newKey)

                    return (
                      <Component
                        key={key}
                        index={index}
                        field={question}
                        currentKey={newKey}
                        droppableType={currentKey}
                        isNested
                        fromType="logic"
                        parentExpanded={isFocused}
                      />
                    )
                  })}
                {currentKey === focusedField && !!ghostElement && (
                  <Ghost type={ghostElement} />
                )}
              </Dropzone>
            </ChildrenWrapper>
          )}
        </Box>
      )}
    </Box>
  )
}

export default Logic
