import { useDisclosure } from '@chakra-ui/react'
import { getRouteUrl } from 'helpers'
import { debounce } from 'lodash'
import { useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import OlingaAPI from 'services/OlingaAPI'
import { usePageProperties } from '../../../hooks'

const useChecklists = () => {
  const history = useHistory()
  const checklistListRef = useRef({})
  const { isOpen, onClose, onOpen } = useDisclosure()

  const {
    pageTitle,
    singularTitle,
    shouldCreate: hasPermissionToCreateChecklists
  } = usePageProperties({ pageName: 'checklists' })

  const getFolderListCallbackParams = useCallback(
    ({ shouldShowAllFolders }) => {
      return {
        all: shouldShowAllFolders
      }
    },
    []
  )
  const getAutocompleteCallback = (value) => {
    return OlingaAPI.searchChecklists({ params: { name: value } })
  }

  const handleAutocompleteSelect = (id) => {
    onClose()
    history.push(
      getRouteUrl('showChecklist', {
        id
      })
    )
  }

  const onAutocompleteOpen = () => {
    onOpen()
    debounce(() => {
      const element = document.querySelector('#checklist-autocomplete-input')
      if (element) {
        element.focus()
      }
    }, 100)()
  }

  return {
    checklistListRef,
    pageTitle,
    singularTitle,
    hasPermissionToCreateChecklists,
    getAutocompleteCallback,
    getFolderListCallbackParams,
    onAutocompleteOpen,
    isOpen,
    onClose,
    handleAutocompleteSelect
  }
}

export default useChecklists
