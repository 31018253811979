import { ViewIcon } from '@chakra-ui/icons'
import { Box, Flex, Heading, IconButton, Stack } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import Clipboard from '../../../components/Clipboard'
import EmptyState from '../../../components/EmptyState'
import { getRouteUrl } from '../../../helpers'
import Answers from '../Answers'
import { Wrapper } from '../styles'
import TemplateGroups from '../TemplateGroups'
import TemplateKebab from '../TemplateKebab'
import TemplateName from '../TemplateName'

const TemplateListItem = ({ templates, shinyResourceId, pageTitle }) => {
  if (isEmpty(templates)) {
    return <EmptyState text="No templates found" />
  }
  return templates?.map((template) => {
    const { id } = template
    const { name, active, userPermissions, totalForms, groups } =
      template.attributes
    const key = `${id}-${uuidv4()}`
    return (
      <Wrapper
        data-id={`templates-${template.id}`}
        key={key}
        className={shinyResourceId === Number(template.id) ? 'shiny' : ''}
      >
        <Box shadow="sm" p="4">
          <Flex
            align={['flex-start', 'center']}
            justify={['flex-start', 'space-between']}
            direction={['column', 'row']}
          >
            <Stack>
              <Clipboard value={template.attributes.name}>
                <TemplateName
                  id={id}
                  name={name}
                  active={active}
                  userPermissions={userPermissions}
                />
              </Clipboard>
              <Flex
                align={['flex-end', 'center']}
                justify="flex-start"
                minW="200px"
                gap={4}
              >
                <Answers
                  id={id}
                  shouldShowFormAnswers={userPermissions?.forms_list}
                  totalForms={totalForms}
                />
                <Link to={getRouteUrl('showTemplate', { id })}>
                  <IconButton
                    icon={<ViewIcon />}
                    aria-label="Show template"
                    size="sm"
                    variant="outline"
                    colorScheme="teal"
                  />
                </Link>
                <TemplateKebab
                  id={template.id}
                  ariaLabel={`Template list Template options ${template.id}`}
                  permissions={userPermissions}
                  pageTitle={pageTitle}
                />
              </Flex>
            </Stack>
            <Flex
              align="center"
              mr="2"
              justify={['flex-end', 'flex-start']}
              w={['100%', 'auto']}
            >
              <Heading mr="2" color="gray.700" size="xs">
                Groups:
              </Heading>
              <TemplateGroups groups={groups} />
            </Flex>
          </Flex>
        </Box>
      </Wrapper>
    )
  })
}

TemplateListItem.defaultProps = {
  templates: [],
  shinyResourceId: null,
  pageTitle: 'template'
}

export default TemplateListItem
