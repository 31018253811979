import React, { useMemo } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import { getRouteUrl } from '../../../../helpers'

const AssetTemplateEditorBreadcrumb = ({ id, name, hasPermissionToEdit }) => {
  const options = useMemo(() => {
    const items = [
      {
        title: 'Asset Types',
        url: getRouteUrl('assetTemplates')
      }
    ]
    if (id) {
      items.push({
        title: hasPermissionToEdit ? `Edit Asset Type` : 'View Asset Type'
      })
      items.push({
        title: name
      })
    } else {
      items.push({
        title: `New Asset Type`
      })
    }
    return items
  }, [id, name, hasPermissionToEdit])
  return <Breadcrumb routeTree={options} />
}

export default AssetTemplateEditorBreadcrumb
