import {
  Alert,
  AlertIcon,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import AssetsHandler from 'pages/TemplateEditor/AssetsHandler'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import Ghost from '../../Component/Ghost'
import NotificationInfo from '../../NotificationsHandler/NotificationInfo'
import QuestionNotificationsHandler from '../../NotificationsHandler/QuestionNotificationsHandler'
import OptionsHandler from '../../OptionsHandler'
import Logic from '../Logic'
import ShowOperations from '../ShowOperations'

const FocusedQuestionBody = ({
  id,
  currentKey,
  focusedField,
  isNested,
  getOptions,
  indexes,
  questionType,
  isFocused,
  selectedTab,
  ghostElement,
  logics,
  shouldRenderSettingsTab,
  shouldRenderOperationsTab,
  shouldRenderNotificationsTab,
  shouldRenderAssetsTab,
  shouldRenderOptions,
  operationsItems,
  isNotifying,
  operations,
  structure,
  handleTabsChange,
  notifiedOptions,
  options
}) => {
  const {
    optionsKey,
    deletedOptionsKey,
    notificationsKey,
    sliderValueMinKey,
    sliderValueMaxKey
  } = {
    optionsKey: `${currentKey}.template_question_options_attributes`,
    deletedOptionsKey: `${currentKey}.template_question_options_attributes_deleted`,
    notificationsKey: `${currentKey}.alert_trigger_attributes`,
    sliderValueMinKey: `${currentKey}.template_question_setting_attributes.settings.validations.slider_values.min`,
    sliderValueMaxKey: `${currentKey}.template_question_setting_attributes.settings.validations.slider_values.max`
  }
  const [isMobile] = useMediaQuery('(max-width: 730px)')

  const { register, control, watch, setValue } = useFormContext()

  const [sliderValueMin, sliderValueMax] = watch([
    sliderValueMinKey,
    sliderValueMaxKey
  ])

  if (!isFocused) {
    return null
  }
  return (
    <>
      <hr />
      <Tabs index={selectedTab} isLazy onChange={handleTabsChange}>
        <TabList>
          {(logics?.length > 0 || ghostElement === 'logic') && (
            <Tab>
              Logics{' '}
              <Tag size="sm" ml="1" colorScheme="blue">
                {logics?.length}
              </Tag>
            </Tab>
          )}
          {shouldRenderSettingsTab && <Tab>Settings</Tab>}
          {shouldRenderAssetsTab && <Tab>Assets</Tab>}
          {shouldRenderOperationsTab && (
            <Tab>
              <Flex align="center" gap={2}>
                <Text>Calculations</Text>
                <Tag
                  size="sm"
                  colorScheme={operationsItems?.length > 0 ? 'blue' : 'gray'}
                >
                  {operationsItems?.length || 'Empty'}
                </Tag>
              </Flex>
            </Tab>
          )}
          {shouldRenderNotificationsTab && (
            <Tab>
              <NotificationInfo
                isNotify={isNotifying}
                usersArray={notifiedOptions || []}
              />
            </Tab>
          )}
          {shouldRenderOptions && (
            <Tab>
              Options
              <Tag ml="1" colorScheme="blue" size="sm">
                {options?.length}
              </Tag>
            </Tab>
          )}
        </TabList>

        <TabPanels>
          {(logics?.length > 0 || ghostElement === 'logic') && (
            <TabPanel>
              {logics.map((_, index) => {
                const newKey = `${currentKey}.questions.${index}`
                const key = indexes.getUniqKey(newKey)
                return (
                  <Logic
                    key={key}
                    control={control}
                    questionType={questionType}
                    currentKey={newKey}
                    parentKey={currentKey}
                    optionsKey={optionsKey}
                  />
                )
              })}
              {focusedField === currentKey && ghostElement === 'logic' && (
                <Ghost type={ghostElement} />
              )}
            </TabPanel>
          )}
          {shouldRenderSettingsTab && (
            <TabPanel>
              <Stack>
                <Flex gap={4} align="center">
                  <Checkbox
                    defaultChecked={isMobile && !isNested}
                    {...register(`${currentKey}.compulsory`)}
                  >
                    Compulsory
                  </Checkbox>
                  <Checkbox
                    defaultChecked={isMobile && !isNested}
                    {...register(`${currentKey}.main`)}
                  >
                    Main
                  </Checkbox>
                </Flex>
                {['scale'].includes(questionType) && (
                  <>
                    <Divider />
                    <VStack w="100%" align="flex-start">
                      <Alert borderRadius={4} size="xs" status="info">
                        <AlertIcon />
                        The maximium value must be greater than the minimum
                        value
                      </Alert>
                      <HStack w="max-content" justify>
                        <FormControl>
                          <FormLabel>Min</FormLabel>
                          <NumberInput
                            {...register(
                              `${currentKey}.template_question_setting_attributes.settings.validations.slider_values.min`,
                              {
                                valueAsNumber: false,
                                required: true
                              }
                            )}
                            onChange={(v) => {
                              setValue(
                                `${currentKey}.template_question_setting_attributes.settings.validations.slider_values.min`,
                                v
                              )
                            }}
                            value={sliderValueMin ?? 1}
                          >
                            <NumberInputField />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        </FormControl>
                        <FormControl>
                          <FormLabel>Max</FormLabel>
                          <NumberInput
                            {...register(
                              `${currentKey}.template_question_setting_attributes.settings.validations.slider_values.max`,
                              {
                                required: true
                              }
                            )}
                            onChange={(v) => {
                              setValue(
                                `${currentKey}.template_question_setting_attributes.settings.validations.slider_values.max`,
                                v
                              )
                            }}
                            value={sliderValueMax ?? 100}
                          >
                            <NumberInputField />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        </FormControl>
                      </HStack>
                    </VStack>
                  </>
                )}
              </Stack>
            </TabPanel>
          )}
          {shouldRenderAssetsTab && (
            <TabPanel>
              <AssetsHandler currentKey={currentKey} />
            </TabPanel>
          )}
          {shouldRenderNotificationsTab && (
            <TabPanel>
              <QuestionNotificationsHandler
                id={id}
                control={control}
                notifyKey={`${currentKey}.notify`}
                selfFormNotifyKey={`${currentKey}.notify_user_form`}
                notificationsKey={notificationsKey}
                notificationsUsersKey={`${notificationsKey}.alert_triggers_users_attributes`}
                deletedNotifiedUsersKey={`${currentKey}.alert_triggers_users_attributes_deleted`}
                isInitiallyNotifying={isNotifying}
                getOptions={getOptions}
                questionType={questionType}
              />
            </TabPanel>
          )}
          {shouldRenderOperationsTab && (
            <TabPanel>
              <ShowOperations
                id={id}
                operations={operations}
                structure={structure}
                watch={watch}
                currentKey={`${currentKey}.question_calculation_fields_attributes`}
              />
            </TabPanel>
          )}
          {shouldRenderOptions && (
            <TabPanel>
              <OptionsHandler
                control={control}
                optionsKey={optionsKey}
                deletedOptionsKey={deletedOptionsKey}
                getOptions={getOptions}
                questionKey={currentKey}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </>
  )
}

export default FocusedQuestionBody
