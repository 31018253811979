import { Image, SimpleGrid, theme } from '@chakra-ui/react'
import React, { useMemo, useRef, useState } from 'react'
import ImageCarousel from '../../../../components/ImageCarousel'
import OverlayElement from '../../../../components/OverlayElement'

const ImageAnswer = ({ images }) => {
  const ref = useRef({ open: () => ({}), close: () => ({}) })
  const [selectedImage, setSelectedImage] = useState(0)

  const imagesUrls = useMemo(() => {
    return images.map(({ url }) => url)
  }, [images])

  const handleImageSelection = (index) => {
    const {
      current: { open }
    } = ref
    setSelectedImage(index)
    open()
  }

  return (
    <>
      <ImageCarousel
        images={imagesUrls}
        ref={ref}
        initialSelectedImage={selectedImage}
      />
      <SimpleGrid columns={{ sm: 1, md: 3, lg: 4, xl: 5 }} gap={4}>
        {imagesUrls.map((url, index) => {
          return (
            <OverlayElement
              height="300px"
              width="100%"
              onClick={() => handleImageSelection(index)}
              text="Click to open the full image"
              key={index}
            >
              <Image
                border={`1px solid ${theme.colors.gray[400]}`}
                borderRadius={4}
                objectFit="cover"
                w="inherit"
                height="inherit"
                fallbackSrc="https://via.placeholder.com/200"
                src={url}
              />
            </OverlayElement>
          )
        })}
      </SimpleGrid>
    </>
  )
}

ImageAnswer.defaultProps = {
  images: []
}

export default ImageAnswer
