import { useMediaQuery } from '@chakra-ui/react'
import { useTemplateEditorContext } from 'contexts'
import { defer, isArray, isFunction, isString } from 'lodash'
import { findParentNodeByCondition } from 'pages/TemplateEditor/Form/helpers'
import { shouldKeepOpened } from 'pages/TemplateEditor/helpers'
import { useMemo, useRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const useSectionOperations = ({ currentKey, isNested, field }) => {
  const { control, getValues, setValue } = useFormContext()
  const { focusedField, handleFocusedField } = useTemplateEditorContext()
  const tempId = useMemo(() => {
    return field?.temp_id ?? null
  }, [field])

  const [isMobile] = useMediaQuery('(max-width: 730px)')

  const ref = useRef(null)

  getValues()

  const [id, name, children] = useWatch({
    name: [
      `${currentKey}._id`,
      `${currentKey}.name`,
      `${currentKey}.questions`
    ],
    control,
    defaultValue: [
      getValues(`${currentKey}._id`),
      getValues(`${currentKey}.name`),
      getValues(`${currentKey}.questions`)
    ]
  })

  const shouldKeepFocused = useMemo(() => {
    return isString(focusedField)
      ? shouldKeepOpened(currentKey, focusedField)
      : false
  }, [currentKey, focusedField])

  const { isRemovable, isFocused, hasSupportToRepeat } = useMemo(() => {
    return {
      isRemovable:
        !id &&
        !tempId &&
        isArray(children) &&
        !children.some((question) => !!question._id),
      isFocused: shouldKeepFocused || focusedField === currentKey,
      hasSupportToRepeat: false
    }
  }, [children, currentKey, focusedField, id, shouldKeepFocused, tempId])

  const handleDragClick = (event) => {
    const { target } = event
    const compare = (node) => {
      if (node) {
        if (isFunction(node?.getAttribute)) {
          const dataId = node.getAttribute('data-id')
          if (dataId && dataId === currentKey) {
            return true
          }
        }
      }
      return false
    }
    const node = findParentNodeByCondition(target, compare)
    if (node) {
      const splittedKey = currentKey.split('.')
      let parent = ''
      const dataIgnore = node.getAttribute('data-ignore') === 'true'
      if (isNested && splittedKey?.length > 2) {
        parent = currentKey.split('.').slice(0, -2).join('.')
      }
      if (parent && (isNested || shouldKeepFocused)) {
        if (dataIgnore) {
          return
        }
        handleFocusedField(parent)
      } else {
        handleFocusedField(null)
      }
    } else {
      if (shouldKeepFocused) {
        return
      }
      defer(() => {
        handleFocusedField(currentKey)
      })
    }
  }

  const onChangeTextValue = (value) => {
    setValue(`${currentKey}.name`, value)
  }

  const handleParentFocus = () => {
    handleFocusedField(currentKey)
  }

  return {
    handleParentFocus,
    onChangeTextValue,
    handleDragClick,
    isRemovable,
    isFocused,
    hasSupportToRepeat,
    name,
    ref,
    isMobile,
    shouldKeepFocused
  }
}

export default useSectionOperations
