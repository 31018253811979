import { AddIcon, DragHandleIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  List,
  ListItem,
  VStack
} from '@chakra-ui/react'
import { isFunction, noop } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { v4 as uuid } from 'uuid'
import DeleteButton from '../DeleteButton'
import EmptyState from '../EmptyState'
import { Wrapper, getListItemStyles, getListStyles } from './styles'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const ListBuilder = ({
  list,
  onChange,
  onAddItem,
  onRemoveItem,
  resourceName,
  listSpacing,
  ItemComponent,
  itemProps,
  droppableId,
  dragOffset,
  minHeight,
  isDisabled,
  idAttribute,
  allowDelete,
  allowDrag,
  AddResourceAdorment,
  displayAddItemButton,
  deleteButtonStyles,
  deleteButtonAttributes,
  customRemoveItemAction,
  setAddItemButtonRef,
  children
}) => {
  const [listState, setListState] = useState(list)

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const reorderedList = reorder(
      listState,
      result.source.index,
      result.destination.index
    )

    setListState(reorderedList)
    onChange(reorderedList)
  }

  const addItem = () => {
    const currentList = [...listState]
    const itemToCreate = {
      [idAttribute]: uuid()
    }
    currentList.push(itemToCreate)
    setListState(currentList)
    onChange(currentList)
    onAddItem(itemToCreate, currentList.length - 1)
  }

  const removeItem = (index) => {
    const currentList = [...listState]
    const [removedItem] = currentList.splice(index, 1)
    setListState(currentList)
    onChange(currentList)
    onRemoveItem(currentList, index, removedItem)
  }

  const handleDialogClose = (closeDialog) => {
    if (isFunction(closeDialog)) {
      closeDialog()
    }
  }

  useEffect(() => {
    setListState(list)
  }, [list])

  return (
    <Wrapper data-testid="list-builder">
      <VStack minH={minHeight} align="flex-start">
        <Flex
          gap={4}
          flexDir={{ sm: 'column', md: 'row' }}
          alignItems="center"
          justify="space-between"
          w="100%"
        >
          {displayAddItemButton && (
            <Button
              isDisabled={isDisabled}
              colorScheme="blue"
              leftIcon={<AddIcon />}
              onClick={addItem}
              w="max-content"
              data-testid="list-builder-add"
              ref={setAddItemButtonRef}
            >
              Add {resourceName}
            </Button>
          )}
          {AddResourceAdorment && AddResourceAdorment}
        </Flex>
        {children}
        {!listState?.length && <EmptyState text="The list is empty" />}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={droppableId} isDropDisabled={isDisabled}>
            {(provided, snapshot) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                w="100%"
              >
                <List
                  spacing={listSpacing}
                  {...getListStyles(snapshot.isDraggingOver)}
                >
                  {listState.map((item, index) => {
                    const id = uuid()
                    return (
                      <Draggable
                        key={item[idAttribute] || id}
                        draggableId={allowDrag(item) ? item[idAttribute] : id}
                        index={index}
                        isDragDisabled={isDisabled}
                      >
                        {(provided, itemSnapshot) => (
                          <ListItem
                            {...getListItemStyles(
                              itemSnapshot.isDragging,
                              dragOffset
                            )}
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            position="relative"
                          >
                            <Flex
                              align="center"
                              justify="space-between"
                              gap={2}
                            >
                              <Box
                                {...provided.dragHandleProps}
                                visibility={isDisabled ? 'hidden' : 'visible'}
                              >
                                <Center>
                                  {allowDrag(item) && (
                                    <DragHandleIcon color="gray" />
                                  )}
                                </Center>
                              </Box>
                              <Box
                                data-testid="list-builder-item"
                                flex={1}
                                ml={isDisabled ? -4 : 0}
                              >
                                <ItemComponent
                                  data={item}
                                  index={index}
                                  {...itemProps}
                                />
                              </Box>
                              {allowDelete(item) && (
                                <Box {...deleteButtonStyles(index)}>
                                  <DeleteButton
                                    index={index}
                                    onDialogClose={handleDialogClose}
                                    {...deleteButtonAttributes(index)}
                                    onDelete={() =>
                                      customRemoveItemAction(
                                        index,
                                        itemProps,
                                        removeItem
                                      )
                                    }
                                  />
                                </Box>
                              )}
                            </Flex>
                          </ListItem>
                        )}
                      </Draggable>
                    )
                  })}
                </List>
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </VStack>
    </Wrapper>
  )
}

ListBuilder.defaultProps = {
  resourceName: 'item',
  list: [],
  onChange: noop,
  onAddItem: noop,
  onRemoveItem: noop,
  listSpacing: 3,
  idAttribute: 'id',
  itemProps: {},
  droppableId: 'list',
  dragOffset: null,
  minHeight: 'auto',
  isDisabled: false,
  allowDelete: () => true,
  allowDrag: () => true,
  AddResourceAdorment: null,
  displayAddItemButton: true,
  deleteButtonStyles: () => ({
    position: 'relative'
  }),
  deleteButtonAttributes: () => ({
    alertTitle: 'Remove item',
    alertBody: 'Do you want to remove this item ?',
    continueColor: 'red'
  }),
  customRemoveItemAction: undefined,
  setAddItemButtonRef: noop,
  children: null
}

export default memo(ListBuilder)
