import { EditIcon } from '@chakra-ui/icons'
import { IconButton, Stack } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../../components/PageHeader'
import Table from '../../../components/Table'
import { getRouteUrl } from '../../../helpers/admin'
import { useTableColumns } from '../../../hooks'
import AdminOlingaAPI from '../../../services/admin/AdminOlingaAPI'
import MasqueradeButton from './MasqueradeButton'

const EditBusinessButton = ({ id }) => (
  <Link to={getRouteUrl('editBusiness', { id })}>
    <IconButton
      icon={<EditIcon />}
      colorScheme="blue"
      size="sm"
      variant="outline"
      aria-label={`Edit business ${id}`}
    />
  </Link>
)

const hiddenColumnsKey = 'persisted_columns.businesses'

const columnsMapper = ({ original: { id } }) => ({ id })

const Businesses = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({ pageCount: 0, totalItems: 0 })

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Name',
        accessor: 'attributes.businessName'
      },
      {
        Header: 'Main user email',
        Cell: ({ row }) => <MasqueradeButton user={row.original?.attributes?.mainUser} />,
        modify: true,
      },
      {
        Header: 'Trading Name',
        accessor: 'attributes.tradingName'
      },
      {
        Header: 'Created at',
        accessor: 'attributes.createdAt'
      },
      {
        Header: 'Abn/Acn',
        accessor: 'attributes.abnAcn'
      },
      {
        Header: 'Industry',
        accessor: 'attributes.industry'
      }
    ],
    hiddenColumnsKey,
    true,
    EditBusinessButton,
    columnsMapper
  )

  const fetchBusinesses = useCallback(
    async (params = { page: 1, per_page: 15 }) => {
      setIsLoading(true)
      try {
        const {
          data,
          meta: { last: pageCount, count: totalItems }
        } = await AdminOlingaAPI.businessesList(params)
        setData(data)
        setPagination({ pageCount, totalItems })
      } catch (error) {
        setData([])
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  useEffect(() => {
    fetchBusinesses()
  }, [fetchBusinesses])

  return (
    <Stack>
      <PageHeader title="Businesses" />
      <Table
        isLoading={isLoading}
        data={data}
        columns={columns}
        initiallyHiddenColumns={hiddenColumns}
        persistedColumnsKey={hiddenColumnsKey}
        pageCount={pagination.pageCount}
        totalItems={pagination.totalItems}
        fetchPage={(params) => fetchBusinesses(params)}
      />
    </Stack>
  )
}

export default Businesses
