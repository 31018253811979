import { isEmpty, isFunction, isObject, pick } from 'lodash'
import PropTypes from 'prop-types'
import React, {
  cloneElement,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../store/actions'
import BasePageSkeleton from './Skeleton'

const { SET_CACHED_DEPENDENCIES } = actions.dependencies

const BasePage = ({
  children,
  id,
  fetchCallback,
  resourceName,
  fetchWithoutId,
  otherParams,
  cachedKeys,
  Skeleton
}) => {
  const [dependencies, setDependencies] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const fetchRef = useRef(false)

  const dispatch = useDispatch()

  const storedDependencies = useSelector(
    ({ dependenciesReducer }) => dependenciesReducer.dependencies
  )
  const restoreFromCache = useCallback(() => {
    const storedAttributes = pick(storedDependencies, cachedKeys)
    return storedAttributes ?? {}
  }, [cachedKeys, storedDependencies])

  const fetchDependencies = useCallback(
    async (id) => {
      try {
        if (isFunction(fetchCallback)) {
          setIsLoading(true)
          const { data: dependencies } = await fetchCallback(id)
          setDependencies(dependencies)
        } else if (isObject(fetchCallback)) {
          const cachedResources = restoreFromCache()
          const keys = Object.keys(fetchCallback).filter(
            (key) => !Object.keys(cachedResources).includes(key)
          )
          const callbacks = pick(fetchCallback, keys)
          const promises = Object.values(callbacks).map(
            ({
              callback: callbackReference,
              params,
              ids = [],
              shouldIgnoreMainId = false
            }) =>
              callbackReference(
                ...[shouldIgnoreMainId ? null : id, ...ids].filter((i) => i),
                params
              )
          )
          setIsLoading(true)
          const data = await Promise.all(promises)
          const formattedData = data.reduce((acc, dataObject, index) => {
            acc[keys[index]] = Object.keys(dataObject).includes('data')
              ? dataObject.data
              : dataObject
            return acc
          }, {})
          const pageData = { ...formattedData, ...cachedResources }
          setDependencies(pageData)
          dispatch({
            payload: pageData,
            type: SET_CACHED_DEPENDENCIES
          })
          setIsLoading(false)
        }
      } catch (error) {
        setDependencies(null)
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch, fetchCallback, restoreFromCache]
  )

  const onRefetch = useCallback(() => {
    fetchDependencies(id)
  }, [fetchDependencies, id])

  useEffect(() => {
    if ((id || fetchWithoutId) && !fetchRef.current) {
      window.scrollTo(0, 0)
      fetchRef.current = true
      fetchDependencies(id)
    }
  }, [fetchDependencies, fetchWithoutId, id])

  const getPageContent = () => {
    if (isLoading) {
      return Skeleton || <BasePageSkeleton />
    }
    if (id && isEmpty(dependencies)) {
      return null
    }
    return cloneElement(children, {
      [resourceName]: dependencies,
      isLoading,
      onRefetch,
      ...otherParams
    })
  }

  return <>{getPageContent()}</>
}

BasePage.defaultProps = {
  fetchCallback: null,
  fetchWithoutId: false,
  otherParams: {},
  cachedKeys: [],
  Skeleton: null
}

BasePage.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fetchCallback: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  resourceName: PropTypes.string.isRequired,
  fetchWithoutId: PropTypes.bool,
  otherParams: PropTypes.object,
  cachedKeys: PropTypes.arrayOf(PropTypes.string),
  Skeleton: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.number
  ])
}

export default memo(BasePage)
