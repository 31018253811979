import { ChevronDownIcon } from '@chakra-ui/icons'
import { Heading, HStack, Tag, Text } from '@chakra-ui/react'
import _ from 'lodash'
import React from 'react'
import AsyncContentModal from '../../../components/AsyncContentModal'
import List from '../../../components/List'
import { getPrefixes } from '../../../helpers'
import OlingaAPI from '../../../services/OlingaAPI'
import TemplateKebab from '../../Templates/TemplateKebab'

const TemplatesModal = ({
  id,
  count,
  folderName,
  hasPermission,
  customButtonLabel
}) => {
  const getHeader = (templateList) => {
    if (_.isObject(templateList)) {
      const { data: templates } = templateList
      const { before, after } = getPrefixes(count, 'template')

      const getText = () => {
        if (!count || count === 0) {
          return 'There are no templates for this folder'
        }
        return `${_.capitalize(before)} ${
          templates.length === 1 ? 'only' : templates.length
        } ${after} of folder ${folderName}`
      }

      return <Heading size="md">{getText()}</Heading>
    }
    return null
  }

  const getItemBody = (item) => {
    return (
      <HStack align="center">
        <Text fontSize="md" color="gray.700">
          {item.name}
        </Text>
        {hasPermission && (
          <TemplateKebab
            id={item.id}
            size="xs"
            zIndex={9999999}
            icon={<ChevronDownIcon />}
            hasBorder={false}
            permissions={item.userPermissions}
          />
        )}
      </HStack>
    )
  }

  const getBody = (templateList) => {
    const templates = templateList
    if (_.isObject(templates)) {
      const { data: templates } = templateList
      if (!templates?.length) {
        return <Tag color="gray">Empty</Tag>
      }
      const names = templates.map(
        ({ attributes: { name, id, userPermissions } }) => ({
          name,
          id,
          userPermissions
        })
      )
      return <List getItemBody={getItemBody} items={names} />
    }
    return null
  }

  return (
    <AsyncContentModal
      getHeader={getHeader}
      getBody={getBody}
      id={id}
      count={count}
      fetchCallback={OlingaAPI.formFolderTemplatesList}
      customButtonLabel={customButtonLabel}
    />
  )
}

TemplatesModal.defaultProps = {
  customButtonLabel: ''
}

export default TemplatesModal
