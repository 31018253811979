import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text
} from '@chakra-ui/react'
import { first, isEmpty } from 'lodash'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import MaterialIcon from '../../../components/MaterialIcon'
import { settingsRoutes } from '../../../contexts/GlobalStateContext/useGlobalContext/helpers'
import DesktopSubNav from './DesktopSubNav'

const DesktopNav = ({ navbar, renderKey }) => {
  const location = useLocation()
  const menus = navbar.menus

  const currentRoute = first(
    location.pathname
      .replace(/\/web|\/sm/g, '')
      .replace(/.*?\/(.*?)/i, '')
      .split('/')
  )

  return (
    <HStack
      key={renderKey}
      spacing={2}
      flex={1}
      maxW="100%"
      overflowX="auto"
      flexWrap="wrap"
    >
      {menus?.map(({ name, icon, id, url, dropdown }) => (
        <Box
          rounded="md"
          bg={
            currentRoute === id ||
            (id === 'settings' &&
              (settingsRoutes ?? []).includes(location.pathname))
              ? navbar.highlight
              : 'transparent'
          }
          key={name}
        >
          <Popover
            closeOnBlur
            isLazy
            trigger={'hover'}
            placement={'bottom-start'}
          >
            <PopoverTrigger>
              <Link to={url ?? '#'}>
                <Flex
                  p={2}
                  _hover={{
                    textDecoration: 'none',
                    color: 'orange.200'
                  }}
                  align="center"
                  gap={2}
                >
                  {icon && (
                    <MaterialIcon
                      icon={icon}
                      styles={{ color: 'white', fontSize: '16px' }}
                    />
                  )}
                  <Text
                    noOfLines={1}
                    fontSize="sm"
                    fontWeight="500"
                    color="white"
                  >
                    {name}
                  </Text>
                  {!isEmpty(dropdown) && <ChevronDownIcon color="white" />}
                </Flex>
              </Link>
            </PopoverTrigger>

            {!isEmpty(dropdown) && (
              <PopoverContent
                border={0}
                boxShadow="xl"
                sx={{
                  bg: 'white'
                }}
                p={4}
                rounded="xl"
                minW="sm"
              >
                <Stack>
                  {dropdown.map((child, index) => (
                    <DesktopSubNav key={index} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </HStack>
  )
}

export default DesktopNav
