import { Stack } from '@chakra-ui/react'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { v4 as uuid } from 'uuid'
import RecursiveStructureProvider from '../../contexts/RecursiveStructureSelection'
import EmptyState from '../EmptyState'
import Child from './Child'
import Labels from './Labels'

const TemplateRecursiveStructure = ({
  childrenStructure,
  answers,
  answerLabel,
  displayLabels,
  initialSelectedNodes,
  onSelectionChange,
  allowSelection,
  selectionMode,
  children
}) => {
  if (!childrenStructure?.length) {
    return <EmptyState text="This template has no questions" />
  }
  return (
    <RecursiveStructureProvider
      initialSelectedNodes={initialSelectedNodes}
      onSelectionChange={onSelectionChange}
      allowSelection={allowSelection}
      selectionMode={selectionMode}
      answers={answers}
      answerLabel={answerLabel}
    >
      <Stack gap={4}>
        {displayLabels && <Labels />}
        {childrenStructure.map((child) => {
          return <Child {...child} child={children} key={uuid()} />
        })}
      </Stack>
    </RecursiveStructureProvider>
  )
}

TemplateRecursiveStructure.defaultProps = {
  initialSelectedNodes: [],
  onSelectionChange: noop,
  selectionMode: 'disabled',
  allowSelection: noop,
  answers: {},
  answerLabel: 'Answer'
}

TemplateRecursiveStructure.propTypes = {
  selectionMode: PropTypes.oneOf(['single', 'multiple', 'disabled'])
}

export default TemplateRecursiveStructure
