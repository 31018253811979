import { Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import FloatingLabelBox from '../../../../components/FloatingLabelBox'
import ExportForms from '../../ExportForms'

const Header = ({
  id,
  templateId,
  templateName,
  formId,
  date,
  dateTime,
  employee,
  subject,
  isLoading
}) => {
  return (
    <Stack>
      {!isLoading && id && templateId && (
        <ExportForms
          inline
          templateId={templateId}
          templateName={templateName}
          params={{
            form_id: formId,
            start_date: moment(date).toDate()
          }}
          size="sm"
        />
      )}
      <FloatingLabelBox label="Form details">
        <Stack>
          {id && (
            <SimpleGrid columns={{ sm: 1, md: 2 }}>
              <Heading size="sm" isTruncated>
                ID
              </Heading>
              <Text fontSize="sm" isTruncated>
                {id}
              </Text>
            </SimpleGrid>
          )}
          {dateTime && (
            <SimpleGrid my={4} columns={{ sm: 1, md: 2 }}>
              <Heading size="sm" isTruncated>
                Date
              </Heading>
              <Text fontSize="sm" isTruncated>
                {dateTime}
              </Text>
            </SimpleGrid>
          )}
          {employee && (
            <SimpleGrid columns={{ sm: 1, md: 2 }}>
              {subject && (
                <Heading size="sm" isTruncated>
                  {subject}
                </Heading>
              )}
              <Text fontSize="sm" isTruncated>
                {employee}
              </Text>
            </SimpleGrid>
          )}
        </Stack>
      </FloatingLabelBox>
    </Stack>
  )
}

export default Header
