import { useMediaQuery } from '@chakra-ui/react'
import { throttle } from 'lodash'
import { useTemplateEditorContext } from '../../../../../contexts'
import { useGlobalStateContext } from '../../../../../contexts/GlobalStateContext'
import { actions } from '../../actions'

const {
  ADD_QUESTION,
  APPEND_QUESTION,
  ADD_SECTION,
  APPEND_SECTION,
  ADD_INSTRUCTION,
  APPEND_INSTRUCTION,
  ADD_LOGIC,
  APPEND_LOGIC,
  REMOVE,
  OPEN_SETTINGS,
  REMOVE_PERSISTED_ELEMENT,
  SET_GHOST_FIELD,
  UNFOCUS
} = actions

const useFormActions = () => {
  const { global$ } = useGlobalStateContext()
  const { focusedField } = useTemplateEditorContext()
  const [isMobile] = useMediaQuery('(max-width: 599px)')

  const dispatchAction = (type, payload) => {
    global$.next({
      type,
      payload
    })
  }

  const addQuestion = () => {
    if (!focusedField) {
      dispatchAction(APPEND_QUESTION)
    } else {
      dispatchAction(ADD_QUESTION, { focusedField })
    }
  }

  const addSection = () => {
    if (!focusedField) {
      dispatchAction(APPEND_SECTION)
    } else {
      dispatchAction(ADD_SECTION, { focusedField })
    }
  }

  const addInstruction = () => {
    if (!focusedField) {
      dispatchAction(APPEND_INSTRUCTION)
    } else {
      dispatchAction(ADD_INSTRUCTION, { focusedField })
    }
  }

  const addLogic = () => {
    if (!focusedField) {
      dispatchAction(APPEND_LOGIC)
    } else {
      dispatchAction(ADD_LOGIC, { focusedField })
    }
  }

  const removeElement = () => {
    let action = REMOVE_PERSISTED_ELEMENT
    const element = document.querySelector(`[data-position="${focusedField}"]`)
    let type = null
    if (element) {
      const isElementRemovable = element.getAttribute('data-removable')
      type = element.getAttribute('data-type')
      if (isElementRemovable === 'true') {
        action = REMOVE
      }
    }
    let dialogContent = null
    if (action === REMOVE_PERSISTED_ELEMENT) {
      dialogContent = {
        type,
        focusedField
      }
    }
    dispatchAction(action, { focusedField, dialogContent })
  }

  const setGhostField = (type) => {
    dispatchAction(SET_GHOST_FIELD, type)
  }

  const onMouseOver = throttle((elementType, isDisabled = false) => {
    if (isMobile || isDisabled) return
    setGhostField(elementType)
  }, 500)

  const onMouseOut = () => {
    if (isMobile) return
    setGhostField(null)
  }

  const onOpenSettings = () => {
    dispatchAction(OPEN_SETTINGS)
  }

  const removeFocus = () => {
    dispatchAction(UNFOCUS)
  }

  return {
    addQuestion,
    addLogic,
    addSection,
    addInstruction,
    removeElement,
    onMouseOut,
    onMouseOver,
    onOpenSettings,
    removeFocus
  }
}

export default useFormActions
