import {
  CheckIcon,
  ChevronRightIcon,
  DownloadIcon,
  EditIcon
} from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  Button,
  Divider,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip
} from '@chakra-ui/react'
import UploadFile from 'components/Inputs/UploadFile'
import MaterialIcon from 'components/MaterialIcon'
import { isEmpty } from 'lodash'
import React, {
  cloneElement,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

const getItems = (itemsDescriptions) => [
  {
    text: '1. Download',
    description: itemsDescriptions[0],
    icon: <DownloadIcon />,
    color: 'purple'
  },
  {
    text: '2. Upload',
    description: itemsDescriptions[1],
    icon: <MaterialIcon type='material-icons-outlined' icon='cloud_upload' />,
    description: itemsDescriptions[1],
    color: 'blue'
  }
]

const DownloadAndUploadModal = ({
  isOpen,
  onClose,
  onOpenDownloadItemSidebar,
  loadingText,
  onBulkImportItem,
  onChangeFiles,
  children,
  files,
  selectedItem,
  onSelectItem,
  isLoading,
  itemsDescriptions,
  shouldSelectItem,
  onDownloadItem,
  downloadLabel
}) => {
  const uploadRef = useRef()

  const [step, setStep] = useState(0)

  const items = getItems(itemsDescriptions)

  useEffect(() => {
    return () => {
      setStep(0)
    }
  }, [isOpen])

  const label = useMemo(() => {
    if (selectedItem?.label?.length > 36) {
      return selectedItem?.label?.slice(0, 33) + '...'
    }
    return selectedItem?.label
  }, [selectedItem?.label])

  return (
    <>
      {shouldSelectItem &&
        cloneElement(children, {
          setSelectedItem: onSelectItem
        })}
      <Modal
        size="2xl"
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={!isLoading}
        closeOnOverlayClick={!isLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Bulk Import</ModalHeader>
          <ModalCloseButton isDisabled={isLoading} />
          <ModalBody>
            <Tabs align="center" index={step} onChange={setStep}>
              <TabList>
                <Tab>Download CSV</Tab>
                <Tab isDisabled={!selectedItem && shouldSelectItem}>
                  Upload CSV
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Stack w="100%">
                    <SimpleGrid
                      columns={[1, items.length]}
                      borderRadius="md"
                      bg="gray.100"
                      borderWidth={1}
                      borderColor="gray.400"
                      p={2}
                      spacing={2}
                    >
                      {items.map(
                        ({ text, description, icon, color }, index) => (
                          <Stack key={index} justify="center" align="center">
                            <Flex
                              borderRadius="100%"
                              borderWidth={`${color}.200`}
                              bg={`${color}.100`}
                              w="14"
                              h="14"
                              align="center"
                              justify="center"
                              fontSize="xl"
                            >
                              {cloneElement(icon, { color: `${color}.500` })}
                            </Flex>
                            <Text
                              mt="auto"
                              fontWeight="bold"
                              textAlign="center"
                            >
                              {text}
                            </Text>
                            <Text textAlign="center" fontSize="sm">
                              {description}
                            </Text>
                          </Stack>
                        )
                      )}
                    </SimpleGrid>
                    <Stack
                      gap={2}
                      justify="flex-start"
                      align="flex-start"
                      w="100%"
                    >
                      <Heading mt="4" size="md">
                        Important!
                      </Heading>
                      <Divider />
                      <List spacing={3} textAlign="left">
                        {shouldSelectItem && (
                          <ListItem>
                            <ListIcon as={CheckIcon} color="green.500" />
                            Use the provided template to enter asset details.
                          </ListItem>
                        )}
                        <ListItem>
                          <ListIcon as={CheckIcon} color="green.500" />
                          {`Don't add new columns or edit column headers.`}
                        </ListItem>
                        {shouldSelectItem && (
                          <ListItem>
                            <ListIcon as={CheckIcon} color="green.500" />
                            CSV file should only contain assets of one type.
                          </ListItem>
                        )}
                        <ListItem>
                          <ListIcon as={CheckIcon} color="green.500" />
                          Only CSV files will be accepted.
                        </ListItem>
                      </List>
                    </Stack>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack gap={2}>
                    <Alert status="info" borderRadius="md">
                      <AlertIcon />
                      After filling out the downloaded template, drag and drop
                      the file below to continue the import process
                    </Alert>
                    <UploadFile
                      onChange={onChangeFiles}
                      maxFiles={1}
                      acceptedExtensions={['csv']}
                      ref={uploadRef}
                    />
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <p>three!</p>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter display="flex" gap={4}>
            <Button colorScheme="gray" onClick={onClose}>
              Close
            </Button>
            {!step ? (
              <Flex gap={4}>
                {shouldSelectItem && (
                  <Tooltip
                    label={
                      selectedItem?.label === label ? '' : selectedItem?.label
                    }
                    hasArrow
                  >
                    <Button
                      onClick={onOpenDownloadItemSidebar}
                      colorScheme="green"
                      leftIcon={<EditIcon />}
                    >
                      {!selectedItem
                        ? 'Click to select an asset type'
                        : `Asset type: ${label}`}
                    </Button>
                  </Tooltip>
                )}
                {!shouldSelectItem && (
                  <Button
                    onClick={() => {
                      onDownloadItem()
                    }}
                    leftIcon={<DownloadIcon />}
                    colorScheme="blue"
                  >
                    {downloadLabel}
                  </Button>
                )}
                {selectedItem && shouldSelectItem && (
                  <Button
                    onClick={() => setStep(1)}
                    colorScheme="blue"
                    rightIcon={<ChevronRightIcon />}
                  >
                    Continue
                  </Button>
                )}
              </Flex>
            ) : (
              <Button
                isDisabled={
                  isEmpty(files) || (shouldSelectItem && !selectedItem?.value)
                }
                onClick={onBulkImportItem}
                colorScheme="green"
                isLoading={isLoading}
                loadingText={loadingText}
                leftIcon={<MaterialIcon icon='upload_file' />}
              >
                Submit
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

DownloadAndUploadModal.defaultProps = {
  itemsDescriptions: [
    'Select a type to download its template',
    'Upload the filled file to import assets'
  ],
  shouldSelectItem: true,
  loadingText: 'Importing assets'
}

export default DownloadAndUploadModal
