import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Stack,
  Text
} from '@chakra-ui/react'
import React from 'react'
import { v4 as uuid } from 'uuid'
import TextClamper from '../../../components/TextClamper'
import { useRecursiveStructureContext } from '../../../contexts/RecursiveStructureSelection'
import AttributeSwitch from '../../../pages/TemplateStructure/TemplateDetails/AttributeSwitch'
import BaseStructure, { getDefaultStyles } from '../BaseStructure'
import Child from '../Child'
import QuestionLayout from '../QuestionLayout'
import SelectionComponent from '../SelectionComponent'

const Section = (props) => {
  const { id, name, repeat, type, children, childrenIndexes } = props

  const { allowSelection } = useRecursiveStructureContext()

  return (
    <BaseStructure
      childrenIndexes={childrenIndexes}
      type={type}
      hasChildren={children.length}
      otherProps={{ ...props }}
    >
      <AccordionItem
        {...getDefaultStyles(type)}
        sx={{
          _hover: {
            shadow: 'lg'
          }
        }}
      >
        <AccordionButton>
          <QuestionLayout
            leftSlot={
              <HStack spacing={3} flexDir={['column', 'row']}>
                {allowSelection(props) && (
                  <SelectionComponent id={id} type="section" />
                )}
                <HStack spacing={2} flexDir={['column', 'row']}>
                  <Text fontWeight="bold">Section: </Text>
                  <TextClamper text={name} isHtml lines={2} />
                </HStack>
              </HStack>
            }
            rightSlot={<AttributeSwitch label="Repeat" answer={repeat} />}
          />
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Stack gap={4} pt={4}>
            {children.map((child) => (
              <Child {...child} key={uuid()} />
            ))}
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </BaseStructure>
  )
}

export default Section
