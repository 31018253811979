import { ViewIcon } from '@chakra-ui/icons'
import { Button, Flex, Tag, useDisclosure } from '@chakra-ui/react'
import InfoTip from 'components/Infotip'
import { getRouteUrl } from 'helpers'
import {
  usePageProperties,
  useQuery,
  useTableColumns,
  useTableFilter
} from 'hooks'
import { get, isBoolean, isEmpty } from 'lodash'
import { formatText } from 'pages/Reports/ReportsEditor/helpers'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import OlingaAPI from 'services/OlingaAPI'
import { frequencies } from '../constants'
import EditReportActions from '../EditReportsActions'

const hiddenColumnsKey = 'form_answers_persisted_columns.template_reports'

const mapId = ({
  original: {
    id,
    title,
    templateId,
    emails,
    userPermissions,
    afterDelete,
    reportType
  }
}) => {
  return {
    id,
    title,
    templateId,
    emails,
    userPermissions,
    afterDelete,
    reportType
  }
}

const useReportsList = () => {
  const query = useQuery()

  const [isLoadingTemplates, setIsLoadingTemplates] = useState()
  const [reportTemplates, setReportTemplates] = useState([])

  const {
    isOpen: isReportTemplatesModalOpen,
    onClose: onCloseReportTemplatesModal,
    onOpen: onOpenReportTemplatesModal
  } = useDisclosure()

  const history = useHistory()

  const { shouldRender: shouldRenderScheduledReports } = usePageProperties({
    pageName: 'scheduled_reports'
  })
  const { shouldRender: shouldRenderSummaryReports } = usePageProperties({
    pageName: 'summary_reports'
  })

  const [reportType, setReportType] = useState(
    ['scheduled', 'summary'].includes(query?.context)
      ? query?.context
      : 'scheduled'
  )

  const sortType = useCallback((rowA, rowB, columnId) => {
    const getAttribute = (object) => {
      return get(object, `original.${columnId}.props.children`)
    }
    if (getAttribute(rowA) > getAttribute(rowB)) return 1
    if (getAttribute(rowA) < getAttribute(rowB)) return -1
    return 0
  }, [])

  const hasPermissionToModifyTemplateReport =
    reportType === 'scheduled'
      ? shouldRenderScheduledReports
      : reportType === 'summary'
      ? shouldRenderSummaryReports
      : false

  const { columns, hiddenColumns } = useTableColumns(
    reportType === 'scheduled'
      ? [
          {
            Header: 'ID',
            accessor: 'id'
          },
          {
            Header: 'Report Title',
            accessor: 'title'
          },
          {
            Header: 'Frequency',
            accessor: 'frequency'
          },
          {
            Header: 'Range',
            accessor: 'query_range'
          },
          {
            Header: 'Nº of questions',
            accessor: 'template_questions',
            sortType
          }
        ]
      : reportType === 'summary'
      ? [
          {
            Header: 'ID',
            accessor: 'id'
          },
          {
            Header: 'Templates',
            accessor: 'templates'
          },
          {
            Header: 'Report Title',
            accessor: 'title'
          },
          {
            Header: 'Frequency',
            accessor: 'frequency'
          }
        ]
      : [],
    hiddenColumnsKey,
    hasPermissionToModifyTemplateReport,
    EditReportActions,
    mapId
  )

  const fetchReportTemplates = useCallback(
    async (reportType, reportId) => {
      setIsLoadingTemplates(true)
      try {
        const templates = await OlingaAPI.reportTemplates(reportId, reportType)

        setReportTemplates(templates)

        onOpenReportTemplatesModal()
      } catch (error) {
        //
      } finally {
        setIsLoadingTemplates(false)
      }
    },
    [onOpenReportTemplatesModal]
  )

  const fieldsExtractor = useCallback(
    (data) => {
      return data.map(
        ({
          attributes: {
            id,
            templateId,
            reportType,
            fields: {
              title,
              frequency,
              query_range,
              template_questions,
              emails
            },
            userPermissions
          }
        }) => {
          if (reportType == 'scheduled') {
            return {
              id,
              title,
              frequency: (
                <Flex gap={2}>
                  {formatText(frequency)}{' '}
                  {frequencies[frequency] && (
                    <InfoTip label={frequencies[frequency]} />
                  )}
                </Flex>
              ),
              query_range: formatText(query_range),
              template_questions: (
                <Tag colorScheme="green">{template_questions?.length}</Tag>
              ),
              templateId,
              emails,
              userPermissions,
              reportType,
              afterDelete: () => refetch({ reportType, page: 1, per_page: 15 })
            }
          } else if (reportType === 'summary') {
            const reportId = id
            return {
              id,
              title,
              frequency: (
                <Flex gap={2}>
                  {formatText(frequency)}{' '}
                  {frequencies[frequency] && (
                    <InfoTip label={frequencies[frequency]} />
                  )}
                </Flex>
              ),
              userPermissions,
              reportType,
              emails,
              templates: (
                <Button
                  size="sm"
                  variant="outline"
                  colorScheme="blue"
                  leftIcon={<ViewIcon />}
                  onClick={() => fetchReportTemplates(reportType, reportId)}
                  isLoading={isLoadingTemplates}
                >
                  See templates
                </Button>
              ),
              afterDelete: () => refetch({ reportType, page: 1, per_page: 15 })
            }
          }
          return {}
        }
      )
    },
    [fetchReportTemplates, isLoadingTemplates, refetch]
  )

  useEffect(() => {
    const setSelectedReport = () => {
      const validOptions = [
        shouldRenderScheduledReports ? 'scheduled' : null,
        shouldRenderSummaryReports ? 'summary' : null
      ].filter((v) => !!v)
      if (isEmpty(validOptions)) {
        history.push('unauthorized')
      } else {
        const contextualOption = validOptions?.find(
          (option) => option === query?.context
        )
        if (contextualOption) {
          onChangeReportType(contextualOption)
        } else {
          const [option] = validOptions
          onChangeReportType(option)
        }
      }
    }
    if (
      isBoolean(shouldRenderScheduledReports) &&
      isBoolean(shouldRenderSummaryReports)
    ) {
      setSelectedReport()
    }
  }, [
    history,
    onChangeReportType,
    query?.context,
    shouldRenderScheduledReports,
    shouldRenderSummaryReports
  ])

  const {
    refetch,
    isLoading,
    updateParams,
    fetchParams,
    tableData,
    pagination
  } = useTableFilter({
    id: null,
    fetchCallback: OlingaAPI.reportsList,
    fieldsExtractor,
    initialParams: {
      page: 1,
      per_page: 15,
      reportType
    }
  })

  const onChangeReportType = useCallback(
    (newReportType) => {
      setReportType(newReportType)
      refetch({ reportType: newReportType })

      history.replace(
        getRouteUrl('templateReports', { reportType: newReportType })
      )
    },
    [history, refetch]
  )

  return {
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    isLoading,
    updateParams,
    fetchParams,
    data: tableData,
    pagination,
    hasPermissionToModifyTemplateReport,
    pageTitle: 'Reports',
    reportType,
    refetch,
    onChangeReportType,
    shouldRenderScheduledReports,
    shouldRenderSummaryReports,
    reportTemplates,
    isReportTemplatesModalOpen,
    onCloseReportTemplatesModal
  }
}

export default useReportsList
