import _, { isArray, isEmpty, isNumber, isObject } from 'lodash'
import moment from 'moment'

/* eslint-disable @typescript-eslint/no-unused-vars */
export const getLogicOperator = (expressionValue) => {
  switch (expressionValue) {
    case 'equal_to': {
      return '==='
    }
    case 'not_equal_to': {
      return '!=='
    }
    case 'is': {
      return '==='
    }
    case 'is_not': {
      return '!=='
    }
    case 'less_than': {
      return '<'
    }
    case 'less_than_or_equal_to': {
      return '<='
    }
    case 'greater_than_or_equal_to': {
      return '>='
    }
    default:
      return false
  }
}

const transformNote = (note, userId) => {
  if (!note) {
    return null
  }
  return _.omit({ ...note, text: note.title, user_id: userId }, [
    'title',
    'currentKey'
  ])
}

const transformFiles = (files) => {
  if (!files) {
    return null
  }
  return files.map((imageItem) => {
    return {
      media: imageItem
    }
  })
}

const getQuestionValue = (question, questions, userId) => {
  if (Array.isArray(question)) {
    question.forEach((q) => {
      getQuestionValue(q, questions, userId)
    })
  } else if (
    question.value ||
    (['scale', 'counter'].includes(question.question_type) &&
      isNumber(question.value)) ||
    ['calculation'].includes(question.question_type)
  ) {
    const {
      section_is_removed,
      section_repeats_attributes,
      question_type,
      template_question_id,
      value,
      actions,
      notes,
      files
    } = question

    questions.result.push({
      section_repeats_attributes,
      section_is_removed,
      question_type,
      template_question_id,
      value,
      actions: actions ?? [],
      note_attributes: transformNote(notes, userId),
      attachments_attributes: transformFiles(files)
    })

    if (question.questions) {
      getQuestionValue(question.questions, questions, userId)
    }
  } else if (question.questions) {
    if (question.actions) {
      question.questions = { ...question.questions, actions: question.actions }
    }
    getQuestionValue(question.questions, questions, userId)
  }
}

export const getFormValues = (questions, userId) => {
  questions.result = []
  questions.forEach((question) => {
    getQuestionValue(question, questions, userId)
  })

  return questions.result
}

const getSectionRepeatsAttr = (repeatsAttr) => {
  repeatsAttr = JSON.parse(repeatsAttr)
  return repeatsAttr.map((r) => ({
    sequence: r.sequence,
    section_question_id: r.section_question_id
  }))
}

const getActionsFromQuestion = (values, userId, actions = []) => {
  const getTemplateQuestionId = (object) => {
    return (
      object?.template_question_id ?? object?.questions?.template_question_id
    )
  }
  const v = _.cloneDeep(values)
  if (Array.isArray(v)) {
    for (let i = 0; i < v.length; i++) {
      if (v[i]?.actions) {
        const templateId = getTemplateQuestionId(v[i])
        if (
          !actions.some((action) => action.template_question_id === templateId)
        ) {
          actions = actions.concat(
            v[i].actions.map((action) => ({
              template_question_id: templateId,
              creator_id: userId,
              ..._.pick(action, [
                'template_question_id',
                'status',
                'priority',
                'due_date',
                'title',
                'description'
              ]),
              user_ids: action.user_ids.map(({ value }) => value)
            }))
          )
        }
      }
      if (v[i]?.questions) {
        getActionsFromQuestion(v[i].questions, userId, actions)
      }
    }
  } else if (_.isObject(v)) {
    getActionsFromQuestion(v.questions, userId, actions)
  }
  return actions
}

const getNotesFromQuestions = (values, notes = []) => {
  const v = _.cloneDeep(values)
  if (Array.isArray(v)) {
    for (let i = 0; i < v.length; i++) {
      const questionNotes = v[i]?.notes
      if (questionNotes) {
        notes = notes.concat(questionNotes)
      }
      if (v[i]?.questions) {
        notes = getNotesFromQuestions(v[i].questions, notes)
      }
    }
  }

  return notes
}

const handleQuestionValues = (questions, templateId, geolocation, userId) => {
  const formValue = {
    form_answers_attributes: getFormValues(Object.values(questions), userId)
  }
  const formData = {}

  formData.template_id = templateId
  formData.latitude = geolocation.latitude
  formData.longitude = geolocation.longitude
  formData.form_answers_attributes = []

  formValue.form_answers_attributes.forEach((item) => {
    if (!['date_time', 'calculation'].includes(item.question_type)) {
      if (
        (isArray(item.value) || isObject(item.value)) &&
        isEmpty(item.value)
      ) {
        return
      }
      if (!item.value) {
        if (['counter', 'scale'].includes(item.question_type)) {
          if (!isNumber(item.value)) {
            return
          }
        }
      }
    }

    const data = {}
    data.template_question_id = item.template_question_id
    if (
      item.section_repeats_attributes &&
      item.section_repeats_attributes !== '[]'
    ) {
      data.section_repeats_attributes = getSectionRepeatsAttr(
        item.section_repeats_attributes
      )
    }
    if (
      (item.question_type === 'image' || item.question_type === 'signature') &&
      item.value
    ) {
      data.attachments_attributes = []
      item.value.forEach((imageItem) => {
        data.attachments_attributes.push({
          media: imageItem
        })
      })
    } else if (item.question_type === 'date_time' && item.value) {
      data.value = moment(item.value).format('YYYY-MM-DD HH:MM')
    } else if (item.question_type === 'checkbox' && item.value) {
      data.value = item.value.join(' # ')
    } else if (item.question_type === 'select' && item.value) {
      data.value = item.value?.value
    } else if (item.question_type === 'calculation') {
      data.value = ''
    } else if (item.question_type === 'asset') {
      const value = item?.value?.value ?? null
      const assetId = item?.value?.asset_id ?? null
      data.value = value
      data.asset_id = assetId
    } else {
      data.value = item.value
    }

    if (item.note_attributes !== null) {
      data.note_attributes = item.note_attributes
    }
    if (item.attachments_attributes !== null) {
      data.attachments_attributes = item.attachments_attributes
    }

    formData.form_answers_attributes.push(data)
  })
  formData.actions_attributes = []
  formData.actions_attributes = formData.actions_attributes.concat(
    getActionsFromQuestion(
      Object.values({
        ...questions,
        ...formValue.form_answers_attributes.filter(
          (question) => question.actions.length
        )
      }),
      userId
    )
  )

  return formData
}

export const convertAttributesToSubmit = (submitData, geolocation, user) => {
  const { questions, id } = submitData
  const userId = user?.id ?? null
  return handleQuestionValues(questions, id, geolocation, userId)
}

export class LocalIndex {
  constructor(templateId) {
    this.key = `idx_${templateId}`
    this.setIndex()
  }
  setIndex() {
    const idx = localStorage.getItem(this.key)
    if (!idx) {
      localStorage.setItem(this.key, 100)
    }
  }
  getIndex() {
    return localStorage.getItem(this.key)
  }
  incrementLocalIndex = () => {
    const idx = Number(this.getIndex())
    localStorage.setItem(this.key, idx + 1 || 100)
  }
  reset() {
    localStorage.removeItem(this.key)
  }
}

export const sortByActiveAndName = (argData) => {
  const reducedData = argData.reduce(
    (acc, assessor) => {
      if (assessor.active) {
        acc.active = acc.active.concat(assessor)
      } else {
        acc.inactive = acc.inactive.concat(assessor)
      }
      return acc
    },
    { active: [], inactive: [] }
  )
  reducedData.result = []
  reducedData.result = _.sortBy(reducedData.active, ['name']).concat(
    _.sortBy(reducedData.inactive, ['name'])
  )
  return reducedData.result
}
