import { globalEvents } from 'contexts/GlobalStateContext/events'
import { useGlobalEventListener } from 'hooks'
import Assets from 'pages/Assets'
import BasePage from 'pages/BasePage'
import React, { useCallback, useState } from 'react'
import OlingaAPI from 'services/OlingaAPI'
import { v4 as uuidv4 } from 'uuid'

const AssetsPage = () => {
  const [key, setKey] = useState(uuidv4())

  useGlobalEventListener(
    useCallback(({ type: event }) => {
      if (event === globalEvents.ON_REFRESH_ASSETS_PAGE) {
        setKey(uuidv4())
      }
    }, [])
  )

  return (
    <BasePage
      fetchCallback={{
        assetTemplates: {
          callback: () => OlingaAPI.assetTemplatesAsEnumerator(),
          params: {}
        }
      }}
      fetchWithoutId
      resourceName="data"
    >
      <Assets key={key} />
    </BasePage>
  )
}

export default AssetsPage
