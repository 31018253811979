import { AddIcon, EditIcon } from '@chakra-ui/icons'
import { Button, Flex, IconButton, Stack, Tag, Text } from '@chakra-ui/react'
import { capitalize, get } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import { getRouteUrl } from '../../helpers'
import { getPermissions } from '../../helpers/permissions'
import { useTableColumns } from '../../hooks'
import OlingaAPI from '../../services/OlingaAPI'
import { getDayPeriodByTime } from '../TemplateFrequencyEditor/useTemplateFrequencyEditor'

const EditTemplateFrequencyButton = ({ id, templateId }) => (
  <Link
    to={getRouteUrl('editTemplateFrequency', { frequencyId: id, templateId })}
  >
    <IconButton
      icon={<EditIcon />}
      colorScheme="blue"
      size="sm"
      variant="outline"
      aria-label={`Edit template frequency ${id}`}
    />
  </Link>
)

const hiddenColumnsKey = 'form_answers_persisted_columns.template_frequencies'

const TemplateFrequencies = ({ id }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({ pageCount: 0, totalItems: 0 })
  const user = useSelector(({ globalReducer }) => globalReducer.user)

  const permissions = useMemo(() => getPermissions(user), [user])

  const sortType = useCallback((rowA, rowB, columnId) => {
    const getAttribute = (object) => {
      return get(object, `original.${columnId}.props.count`)
    }
    if (getAttribute(rowA) > getAttribute(rowB)) return 1
    if (getAttribute(rowA) < getAttribute(rowB)) return -1
    return 0
  }, [])

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Form',
        accessor: 'title'
      },
      {
        Header: 'Check Frequency',
        accessor: 'check_frequency'
      },
      {
        Header: 'Start time',
        accessor: 'start_time',
        sortType
      },
      {
        Header: 'End time',
        accessor: 'end_time',
        sortType
      },
      {
        Header: 'Check rule',
        accessor: 'check_rule'
      }
    ],
    hiddenColumnsKey,
    permissions?.hasPermissionToModifyScheduledForms ?? false,
    EditTemplateFrequencyButton,
    ({ original: { id: templateFrequencyId } }) => ({
      id: templateFrequencyId,
      templateId: id
    })
  )

  const fetchTemplateFrequencies = useCallback(
    async (params = { page: 1, per_page: 15 }) => {
      setIsLoading(true)
      try {
        const {
          data,
          meta: { last: pageCount, count: totalItems }
        } = await OlingaAPI.getTemplateFrequencies(id, params)
        setData(data)
        setPagination({ pageCount, totalItems })
      } catch (error) {
        setData([])
      } finally {
        setIsLoading(false)
      }
    },
    [id]
  )

  useEffect(() => {
    fetchTemplateFrequencies()
  }, [fetchTemplateFrequencies])

  const tableData = useMemo(() => {
    return data.map(({ id, attributes }) => ({
      id,
      title: get(attributes, 'title'),
      check_frequency: capitalize(get(attributes, 'check_frequency.type')),
      start_time: (
        <Tag
          colorScheme="blue"
          count={get(attributes, 'check_frequency.start_time')}
        >
          <Flex gap={4}>
            {getDayPeriodByTime(get(attributes, 'check_frequency.start_time'))}
            <Text>{get(attributes, 'check_frequency.start_time')}</Text>
          </Flex>
        </Tag>
      ),
      end_time: (
        <Tag
          colorScheme="blue"
          count={get(attributes, 'check_frequency.end_time')}
        >
          <Flex gap={4}>
            {getDayPeriodByTime(get(attributes, 'check_frequency.end_time'))}
            <Text>{get(attributes, 'check_frequency.end_time')}</Text>
          </Flex>
        </Tag>
      ),
      week_day: get(attributes, 'check_frequency.week_day') || <Tag>None</Tag>,
      check_rule: capitalize(get(attributes, 'check_rule.type')),
      question_id: get(attributes, 'check_rule.question_id') ?? <Tag>None</Tag>
    }))
  }, [data])

  return (
    <Stack as="section">
      <PageHeader
        Actions={
          permissions?.hasPermissionToModifyScheduledForms ? (
            <Link to={getRouteUrl('createTemplateFrequency', { id })}>
              <Button colorScheme="blue" leftIcon={<AddIcon />}>
                New
              </Button>
            </Link>
          ) : null
        }
        title="Form schedule"
      />
      <Table
        isLoading={isLoading}
        data={tableData}
        columns={columns}
        pageCount={pagination.pageCount}
        totalItems={pagination.totalItems}
        fetchPage={(params) => fetchTemplateFrequencies(params)}
        persistedColumnsKey={hiddenColumnsKey}
        initiallyHiddenColumns={hiddenColumns}
      />
    </Stack>
  )
}

export default TemplateFrequencies
