import _, {
  capitalize,
  first,
  get,
  isObject,
  isString,
  map,
  pick,
  snakeCase,
  sortBy
} from 'lodash'
import { v4 as uuid } from 'uuid'

export const selectedQuestionAttributes = ['id', 'question_type', 'text']

export const formatText = (text) => capitalize(text.replaceAll('_', ' '))

export const sanitizeHTML = (html) => {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  let res = tmp.textContent || tmp.innerText || ''
  res.replace('\u200B', '')
  res = res.trim()
  return res
}

export const defaultContextValue = {
  value: 'main_question_vs_related_questions',
  label: 'Main question vs related questions'
}

export const findChildrenByCondition = (children, condition, items = []) => {
  if (Array.isArray(children)) {
    children.forEach((innerChildren) => {
      findChildrenByCondition(innerChildren, condition, items)
    })
  } else if (_.isObject(children)) {
    if (condition(children)) {
      items.push(children)
    }
    if (children?.children) {
      findChildrenByCondition(children.children, condition, items)
    }
  }
  return items
}

export const bodyQuestionsAdapter = (questions) => {
  return questions.map(({ id }) => ({ id }))
}

const formatHTMLAttributeToOption = (data, labelAttribute, pickAttributes) => ({
  label: sanitizeHTML(get(data, labelAttribute)),
  value: pick(data, pickAttributes),
  data: pick(data, pickAttributes)
})

export const formatDataToSelectOptions = (
  data,
  labelAttribute,
  pickAttributes = []
) => {
  return data.map((item) =>
    formatHTMLAttributeToOption(item, labelAttribute, pickAttributes)
  )
}

export const formatDataToSelectOptionsWithCustomValue = (
  data,
  customValue = '',
  customPickAttributes = [],
  labelAttribute,
  pickAttribute
) => {
  return data.map((item) => ({
    label: sanitizeHTML(get(item, labelAttribute)),
    [customValue]: pick(item, customPickAttributes),
    value: item[pickAttribute]
  }))
}

export const formatStringsToSelectOptions = (strings) =>
  sortBy(
    strings.map((opt) => ({
      label: formatText(opt),
      value: opt
    })),
    (o) => o.label
  )

export const formatFrequencyRanges = (frequency) => {
  return Object.entries(frequency).reduce((acc, [key, values]) => {
    acc[key] = formatStringsToSelectOptions(values)
    return acc
  }, {})
}

export const formatReportAttributes = (report) => {
  return {
    ...report,
    chart_type: report.chart_type,
    related_questions: Object.values(report.related_questions)
  }
}

const getContext = (context, contexts) => {
  if (isObject(context)) {
    return context
  }
  if (isString(context)) {
    return (
      contexts.find(({ value }) => value === snakeCase(context)) ??
      defaultContextValue
    )
  }
  return defaultContextValue
}

export const editQuestionsAdapter = (
  data,
  headerQuestions,
  bodyQuestions,
  contexts
) => {
  const transformStringToLabelValueObject = (value) => ({
    label: formatText(value),
    value
  })

  const formatQuestions = (questions) => {
    const formatRelatedQuestions = (relatedQuestions) => {
      const getRelatedQuestionAttributes = (
        data,
        counterType,
        question_label
      ) => {
        if (data) {
          const { label } = data
          const {
            data: { id, question_type }
          } = data
          let counterTypeObject = {}
          if (counterType) {
            counterTypeObject = {
              ...counterType,
              label: formatText(counterType),
              value: counterType
            }
          }
          return {
            id,
            _id: uuid(),
            question_id: id,
            label,
            question_type,
            text: label,
            question_label,
            counter_type: counterTypeObject
          }
        }
        return {}
      }
      return relatedQuestions.map(
        ({ id: relatedQuestionId, counter_type, label: question_label }) => ({
          ...getRelatedQuestionAttributes(
            [...headerQuestions, ...bodyQuestions].find(
              ({ data: { id } }) => id === relatedQuestionId
            ),
            counter_type,
            question_label
          )
        })
      )
    }
    const getQuestionAttributes = (question, data) => {
      return {
        text: question?.label,
        id: question?.data?.id,
        label: data?.label
      }
    }
    return questions.map((data, index) => ({
      ...data,
      main_question: getQuestionAttributes(
        [...bodyQuestions, ...headerQuestions].find(
          ({ data: { id } }) => id === data?.main_question?.id
        ),
        data?.main_question
      ),
      label: data?.label ?? '',
      context: getContext(data?.context, contexts),
      related_questions: formatRelatedQuestions(data.related_questions),
      has_table: data.report_type?.includes('table'),
      chart_type: first(data?.report_type) ?? '',
      _id: uuid(),
      _key: `template_questions.${index}`
    }))
  }

  const {
    title,
    frequency,
    query_range: queryRange,
    emails,
    template_questions: templateQuestions,
    with_zero
  } = data
  return {
    title,
    emails: emails.map((email) => ({
      label: email,
      value: email
    })),
    frequency: transformStringToLabelValueObject(frequency),
    query_range: transformStringToLabelValueObject(queryRange),
    template_questions: formatQuestions(templateQuestions),
    with_zero: with_zero ?? false
  }
}

export const submitDataAdapter = (templateId, data) => {
  const mapValueToString = (array) =>
    array.map(({ value, email }) => email ?? value)

  const getReportType = (selectedType) => {
    return [selectedType]
  }

  const formatRelatedQuestions = (relatedQuestions) =>
    relatedQuestions.map(
      ({ question_id, question_type, counter_type, question_label }) => ({
        id: question_id,
        counter_type:
          question_type === 'counter'
            ? counter_type?.value ?? counter_type
            : 'records',
        label: question_label ?? ''
      })
    )

  const formatTemplateQuestions = (questions) =>
    questions.map(
      ({
        chart_type,
        has_table,
        main_question,
        related_questions,
        context
      }) => ({
        main_question: {
          id: main_question?.id ?? main_question,
          label: main_question?.label
        },
        report_type: getReportType(chart_type),
        context: context?.value,
        related_questions: formatRelatedQuestions(related_questions)
      })
    )
  const reportFields = {
    title: data.title,
    emails: mapValueToString(data.emails ?? []),
    frequency: data?.frequency?.value,
    query_range: data?.query_range?.value,
    with_zero: data.with_zero,
    template_questions: formatTemplateQuestions(data.template_questions)
  }
  return {
    template_report: {
      template_id: templateId,
      fields: reportFields
    }
  }
}

export const sortOptions = (options) => sortBy(options, (o) => o.label)

export const getSelectableRanges = (frequency, ranges) => {
  switch (frequency) {
    case 'daily':
      return ranges.filter((option) => option?.value !== 'last_week')
    case 'weekly':
      return ranges.filter((option) => ['last_week'].includes(option?.value))
    case 'fortnight':
      return ranges.filter((option) => ['last_week'].includes(option?.value))
    default:
      return ranges
  }
}

export const formattedValues = (values = []) => map(values, (value) => ({ value, label: value})) 