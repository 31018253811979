import { Box, Flex, Text, theme } from '@chakra-ui/react'
import React from 'react'
import ActionStatusTag from '../ActionStatusTag'
import RemoveActionHandler from '../RemoveActionHandler'
import MaterialIcon from 'components/MaterialIcon'

const ActionCard = ({ action, icon, onOpenSidebar, index }) => {
  return (
    <>
      <Box
        _hover={{ backgroundColor: theme.colors.gray[100] }}
        cursor="pointer"
        transition="hover .2s ease"
        onClick={onOpenSidebar}
        shadow="sm"
        px={2}
        py={3}
        borderRadius={4}
        border={`1px solid ${theme.colors.gray[400]}`}
        role="button"
        aria-label={`Action from ${action.currentKey}`}
      >
        <Flex align="center" justify="space-between">
          <Flex gap={2} align="center">
            <MaterialIcon icon={icon} />
            <Text fontSize="sm" fontWeight="bold">
              {action.title}
            </Text>
          </Flex>
          <Flex gap={2} align="center">
            <ActionStatusTag status={action.status} />
            <RemoveActionHandler currentKey={action.currentKey} index={index} />
          </Flex>
        </Flex>
      </Box>
    </>
  )
}

ActionCard.defaultProps = {
  icon: 'library_add_check'
}

export default ActionCard
