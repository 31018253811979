import { Box, Heading } from '@chakra-ui/layout'
import { useMediaQuery } from '@chakra-ui/media-query'
import React from 'react'

const DashboardBox = ({ title, header, children, ...rest }) => {
  const [isMobile] = useMediaQuery('(max-width: 799px)')

  return (
    <Box p={5} shadow="md" borderWidth="1px" {...rest}>
      <Heading fontSize="xl">{title}</Heading>
      {header}
      <Box position="relative" h={isMobile ? '180px' : '276px'}>
        {children}
      </Box>
    </Box>
  )
}

export default DashboardBox
