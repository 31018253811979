import styled from '@emotion/styled'
import { lighten } from 'polished'

export const ghostTransformer = {
  question: `
    border: 2px dashed ${lighten(0.05, '#ff871c')};
    color: ${lighten(0.05, '#ff871c')};
  `,
  logic: `
    border: 2px dashed #36cfc9;
    color: #36cfc9;
  `,
  section: `
    border: 2px dashed #1890ff;
    color: #1890ff;
  `,
  instruction: `
    border: 2px dashed #4A5568;
    color: #4A5568;
  `
}

export const Wrapper = styled.div`
  ${({ type }) => `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    height: 64px;
    margin-bottom: 16px;
    border-radius: 4px;

    ${ghostTransformer[type]};
  `}
`
