/* eslint-disable react-hooks/exhaustive-deps */
import { debounce, isEmpty, isFunction, isObject, noop, omit } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const getFilteredParams = (params = {}) => {
  return Object.entries(params).reduce((acc, [k, v]) => {
    if (
      isObject(v) &&
      !isEmpty(Object.keys(v)) &&
      Object.keys(v).every((k) => ['label', 'value'].includes(k))
    ) {
      if (!v?.value) {
        return acc
      }
      acc[k] = v?.value
    } else {
      acc[k] = v
    }
    return acc
  }, {})
}

const useTableFilter = ({
  id,
  fetchCallback = noop,
  debounceTime = 500,
  initialParams = { page: 1, per_page: 15 },
  initialData = { data: [], meta: {} },
  fieldsExtractor = () => ({}),
  responseTransformer = (data) => data,
  shouldFetch = true,
  onRefetch = null // make sure to pass it as as memoized callback using useCallback hook
}) => {
  const [data, setData] = useState(initialData)
  const [fetchParams, setFetchParams] = useState(initialParams)
  const [isLoading, setIsLoading] = useState(false)

  const fetch = useCallback(
    async (updatedParams = undefined) => {
      try {
        setIsLoading(true)
        const params = getFilteredParams(updatedParams)
        const response = await fetchCallback(id, params)
        setData(responseTransformer(response))
        if (isFunction(onRefetch)) {
          onRefetch(params)
        }
      } catch (err) {
        //
      } finally {
        setIsLoading(false)
      }
    },
    [fetchCallback, onRefetch, id]
  )

  const refetch = useCallback(
    async (params) => {
      setFetchParams(params)
      setIsLoading(true)
      fetch(params)
      if (isFunction(onRefetch)) {
        onRefetch(params)
      }
    },
    [fetchParams, onRefetch]
  )

  const debouncedFetchCallback = useCallback(
    debounce(async (newParams = undefined) => {
      await fetch(newParams)
    }, debounceTime),
    []
  )

  const updateFetchParams = useCallback(
    (newParams = {}, refetchOnChange = true, omitParams = []) => {
      let updatedParams = {}
      setFetchParams((params) => {
        updatedParams = omit(
          {
            ...params,
            ...newParams,
            page: newParams?.page ?? 1
          },
          omitParams
        )
        return updatedParams
      })
      if (refetchOnChange) {
        debouncedFetchCallback(updatedParams)
      }
    },
    []
  )

  useEffect(() => {
    if (shouldFetch) {
      fetch(initialParams)
    }
  }, [shouldFetch])

  const tableData = useMemo(() => {
    if (isEmpty(data?.data)) {
      return []
    }
    return fieldsExtractor(data?.data)
  }, [data])

  return {
    fetch: debouncedFetchCallback,
    refetch,
    updateParams: updateFetchParams,
    tableData,
    isLoading,
    fetchParams,
    pagination: data?.meta
  }
}

export default useTableFilter
