import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag
} from '@chakra-ui/react'
import EmptyState from 'components/EmptyState'
import React from 'react'

const TemplatesModal = ({ isOpen, templates, onClose }) => {
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex gap={4} align="center">
            Templates{' '}
            <Tag colorScheme={templates?.length > 0 ? 'blue' : 'gray'}>
              {templates?.length > 0 ? templates?.length : 'Empty'}
            </Tag>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack gap={2}>
            {templates?.length ? (
              templates.map(([id, name]) => {
                return (
                  <Flex gap={4} key={id}>
                    <Heading size="sm">{name}</Heading>
                    <Tag size="sm" colorScheme="blue">
                      ID: {id}
                    </Tag>
                  </Flex>
                )
              })
            ) : (
              <EmptyState text="There are no templates for this report" />
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TemplatesModal
