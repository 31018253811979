import { Textarea } from '@chakra-ui/react'
import FloatingLabelBox from 'components/FloatingLabelBox'
import { isEmpty } from 'lodash'
import React from 'react'
import ActionCreateForm from '../ActionCreateForm'
import { CREATE_FORM_VALUES, RENDER_FORM_TYPES } from '../constants'
import NotesCard from '../NotesCard'

const { NOTES } = RENDER_FORM_TYPES

const Notes = ({
  notes,
  isEditingNotes,
  setIsEditingNotes,
  handleChangeNotes
}) => {
  if (!isEmpty(notes)) {
    if (isEditingNotes) {
      return (
        <ActionCreateForm
          placeholder="Edit notes"
          initialValue={notes.title}
          onSuccess={handleChangeNotes}
          onCancel={() => setIsEditingNotes(false)}
          fieldName={CREATE_FORM_VALUES[NOTES].fielName}
          as={Textarea}
          shouldPreventEnterEventBehavior={false}
        />
      )
    }
    return (
      <FloatingLabelBox label="Notes">
        <NotesCard notes={notes} onClick={() => setIsEditingNotes(true)} />
      </FloatingLabelBox>
    )
  }
  return null
}

export default Notes
