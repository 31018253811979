import React from 'react'
import ActionDetails from '../../../pages/Actions/ActionDetails'
import BasePage from '../../../pages/BasePage'
import OlingaAPI from '../../../services/OlingaAPI'

const ActionPage = ({ id }) => {
  return (
    <BasePage fetchCallback={OlingaAPI.getAction} id={id} resourceName="data">
      <ActionDetails />
    </BasePage>
  )
}

export default ActionPage
