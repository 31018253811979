/* eslint-disable react/display-name */
import _, { first, isArray, isEmpty, noop, startCase } from 'lodash'
import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Notification from '../../../..//services/Notification'
import { setFormErrors } from '../../../../helpers'
import { getRouteUrl } from '../../../../helpers/admin'
import AdminOlingaAPI from '../../../../services/admin/AdminOlingaAPI'
import { formatBusinessFormData } from './SettingsFields/FormBuilder/helpers'
import { formattedData } from './SettingsFields/helpers'
import useSettingsFields from './SettingsFields/useSettingsFields'

const tabsIndexes = {
  businessName: 0,
  tradingName: 0,
  abnAcn: 0,
  industry: 0,
  features: 1,
  screens: 2,
  validations: 3
}

const useBusinessesForm = ({ ref, data }) => {
  const methods = useForm({
    reValidateMode: 'onSubmit'
  })
  const { reset, getValues, handleSubmit, watch } = methods

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const [business, setBusiness] = useState({})
  const isEdit = useMemo(() => !_.isEmpty(business), [business])
  const { rolesData } = useSettingsFields()
  const [tabIndex, setTabIndex] = useState(0)

  const onChangeTabIndex = (index) => setTabIndex(index)

  useEffect(() => {
    reset({
      tradingName: business?.tradingName,
      businessName: business?.businessName,
      abnAcn: business?.abnAcn,
      industry: business?.industry,
      ...business
    })
  }, [business, reset])

  const [tradingName, businessName, abnAcn, industry] = watch(
    ['tradingName', 'businessName', 'abnAcn', 'industry'],
    [
      getValues('tradingName'),
      getValues('businessName'),
      getValues('abnAcn'),
      getValues('industry')
    ]
  )

  const history = useHistory()

  useEffect(() => {
    const businessData = data?.business?.attributes
    setBusiness(formatBusinessFormData(businessData))
  }, [data?.business])

  const onSubmit = useCallback(
    async (submitData) => {
      try {
        const data = formattedData(submitData)
        setIsLoadingSubmit(true)
        const endpoint = isEdit
          ? AdminOlingaAPI.updateBusiness
          : AdminOlingaAPI.newBusiness
        const response = await endpoint(business?.id, data)

        if (response?.status === 200) {
          Notification({
            icon: 'success',
            text: 'Business updated successfully!'
          })
          const updatedData = response?.data?.data?.attributes
          setBusiness(formatBusinessFormData(updatedData))
        } else {
          setIsLoadingSubmit(false)
        }
      } catch (error) {
        setFormErrors(error, noop, Notification)
        setIsLoadingSubmit(false)
      } finally {
        setIsLoadingSubmit(false)
      }
    },
    [business?.id, isEdit]
  )

  const cancelAction = () => {
    history.push(getRouteUrl('businesses'))
  }

  const handleFormError = useCallback((error) => {
    if (!isEmpty(Object.keys(error))) {
      const tabName = first(Object.keys(error))
      const tabIndex = tabsIndexes[tabName]

      onChangeTabIndex(tabIndex)

      let filteredError = first(Object.values(error).filter((v) => !!v))
      filteredError = isArray(filteredError) ? filteredError : [filteredError]

      const errors = filteredError.reduce((acc, field) => {
        if (field?.type === 'required') {
          acc += startCase(tabName)
        } else {
          const key = first(Object.keys(field))
          acc += startCase(key)
        }
        return acc
      }, '')

      Notification({
        icon: 'error',
        text: `Please check the following errors in ${errors}`
      })
    }
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      submit() {
        handleSubmit(onSubmit, handleFormError)()
      },
      reset() {
        reset()
      }
    }),
    [handleFormError, handleSubmit, onSubmit, reset]
  )

  return {
    methods,
    business,
    isLoadingSubmit,
    onSubmit,
    cancelAction,
    tradingName,
    businessName,
    abnAcn,
    industry,
    settingsFields: business.settingsFields,
    rolesData,
    tabIndex,
    onChangeTabIndex,
    handleFormError
  }
}

export default useBusinessesForm
