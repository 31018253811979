import { Box, Heading, theme } from '@chakra-ui/react'
import React from 'react'

const FakeInput = ({ children, minHeight = 'unset' }) => {
  return (
    <Box
      p={2}
      borderRadius={4}
      minH={minHeight}
      border={`1px solid ${theme.colors.gray[300]}`}
      color="gray.400"
    >
      <Heading size="sm" noOfLines={1}>
        {children}
      </Heading>
    </Box>
  )
}

export default FakeInput
