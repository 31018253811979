import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react'
import useTemplateSettingsTab from './hooks/useTemplateSettingsTab'

const TemplateSettingsTabs = ({ tabs, children }) => {
  const { currentTab, onTabChange, fetchControl } = useTemplateSettingsTab()

  return (
    <Tabs index={currentTab} onChange={onTabChange}>
      <TabList>
        {tabs.map(({ label, icon, id }) => (
          <Tab key={id}>
            <Flex align="center" gap={4}>
              {label}
              {icon}
            </Flex>
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {tabs.map((tab, index) => (
          <TabPanel p={0} key={tab.id}>
            {children({
              tab,
              index,
              currentTab,
              shouldFetch: fetchControl[tab.id]
            })}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

TemplateSettingsTabs.defaultProps = {
  tabs: []
}

export default TemplateSettingsTabs
