import { IconButton as ChakraIconButton } from '@chakra-ui/react'
import styled from '@emotion/styled'
import colors from 'utils/colors'

export const Badge = styled.span`
  ${({ color }) => `
    background-color: ${color};
  `}
  position: absolute;
  border-radius: 3px;
  font-size: 11px;
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 15px;
  top: -2px;
`

export const IconButton = styled(ChakraIconButton)`
  height: 27px;
  width: 27px;
  background-color: ${colors.primary};
  &:hover {
    background-color: ${colors.primary};
  }
`
