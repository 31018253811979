import { Select } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, { useCallback, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import OperationsBoard from '..'
import {
  calculatedFieldsEnumTypes,
  templateStructureTypes
} from '../../../pages/TemplateSettings/TemplateSettingsTabs/components/CalculatedFields/constants'

const SetValueAdapter = ({
  watch,
  setValue,
  attributeKey,
  index,
  structure,
  operations,
  id,
  allowSetOperator,
  setEditionAlertMessage
}) => {
  const [renderKey, setRenderKey] = useState(uuidv4())
  const value = watch(`${attributeKey}.${index}.expression.data`, [])
  const ref = useRef(false)
  const onChange = useCallback(
    (newValue) => {
      const applyOperationMessage = (items) => {
        const updatedOperationChangesMessage =
          'This operation will be applied only for forms answered after this changes'
        if (!ref.current && items?.some(({ removable }) => !removable)) {
          ref.current = true
          setEditionAlertMessage(updatedOperationChangesMessage)
        }
        if (items.length && ref.current) {
          setEditionAlertMessage(updatedOperationChangesMessage)
        }
      }
      if (!newValue.label) {
        return
      }
      if (newValue?.label === 'remove') {
        const currentItems = [...(value ?? [])]
        currentItems.pop()
        applyOperationMessage(currentItems)
        setValue(`${attributeKey}.${index}.expression.data`, currentItems)
      } else {
        newValue = { ...newValue, removable: true }
        applyOperationMessage([...(value ?? []), newValue])
        setValue(`${attributeKey}.${index}.expression.data`, [
          ...(value ?? []),
          newValue
        ])
      }
      setRenderKey(uuidv4())
    },
    [value, setValue, attributeKey, index, setEditionAlertMessage]
  )

  const onChangeDoAction = useCallback(
    (value) => {
      setValue(`${attributeKey}.${index}.do_action`, value)
    },
    [attributeKey, index, setValue]
  )

  return (
    <OperationsBoard
      value={value}
      onChange={onChange}
      operations={operations}
      structure={structure}
      id={id}
      key={renderKey}
      placeholder="Action"
      allowSetOperator={allowSetOperator}
      RightAddon={
        <Select onChange={onChangeDoAction} size="sm" minW="190px" isDisabled>
          {(operations?.doActions ?? []).map(({ label, value }, index) => {
            return (
              <option key={index} value={value}>
                {label}
              </option>
            )
          })}
        </Select>
      }
    />
  )
}

SetValueAdapter.propTypes = {
  operations: calculatedFieldsEnumTypes,
  structure: templateStructureTypes,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentKey: PropTypes.string,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  allowSetOperator: PropTypes.bool,
  setEditionAlertMessage: PropTypes.func,
  alertMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setAddOperatorsButtonRef: PropTypes.any,
  index: PropTypes.number
}

export default SetValueAdapter
