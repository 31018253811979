import { Center, Flex, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import EmptyIllustration from '../../../assets/illustrations/EmptyInbox.png'

const EmptyState = ({ text, Action }) => {
  return (
    <Stack
      align="center"
      justify="center"
      p="4"
      w="100%"
      data-testid="empty-state"
    >
      <Flex>
        <Center>
          <img src={EmptyIllustration} alt="Empty templates" />
        </Center>
      </Flex>
      <Text data-testid="empty-state-text" size="md" color="gray.500">
        {text}
      </Text>
      {Action && <span data-testid="empty-state-action">{Action}</span>}
    </Stack>
  )
}

EmptyState.defaultProps = {
  Action: null
}

export default EmptyState
