import {
  differenceBy,
  get,
  isEqual,
  noop,
  omit,
  pick,
  snakeCase,
  sortBy,
  toLower
} from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { setFormErrors } from '../../../../../helpers'
import { usePageProperties } from '../../../../../hooks'
import Notification from '../../../../../services/Notification'
import OlingaAPI from '../../../../../services/OlingaAPI'

const useInlineHeader = ({ defaultValues, permissions }) => {
  const { register, setValue, watch, control } = useForm({
    defaultValues
  })

  const [groupContext, setGroupContext] = useState({
    isLoading: false,
    groups: []
  })

  const lastFormRef = useRef(defaultValues)
  const destroyRef = useRef({ groups: [] })
  const notificationsRef = useRef()

  const { user } = useSelector(({ globalReducer }) => globalReducer)

  const formPermissions = useMemo(() => {
    if (!permissions) {
      return []
    }
    return sortBy(
      permissions.map((permission) => ({
        ...permission,
        value: snakeCase(toLower(permission.value))
      })),
      ({ label }) => label
    )
  }, [permissions])

  const formValues = watch()

  const setSavedGroups = useCallback(
    (fetchGroups, persistedGroups) => {
      setValue(
        'template_groups_attributes',
        fetchGroups.reduce((acc, group) => {
          const findGroup = persistedGroups.find(
            ({ groupId }) => groupId === group.id
          )
          if (findGroup) {
            acc.push({ ...group, _id: findGroup.id })
          }
          return acc
        }, [])
      )
    },
    [setValue]
  )

  const fetchGroups = useCallback(
    async (formFolderId, shouldSetGroups) => {
      try {
        setGroupContext((prev) => ({
          ...prev,
          isLoading: false,
          groups: []
        }))
        setValue('template_groups_attributes', [])
        const response = await OlingaAPI.groupsSelectOptionsList(formFolderId)
        if (response?.length) {
          const fetchGroups = response.map(
            ({ attributes: { name: label, id: value } }) => ({
              label,
              value,
              id: value
            })
          )
          const persistedGroups = defaultValues.groups ?? []
          if (shouldSetGroups && !!persistedGroups?.length) {
            setSavedGroups(fetchGroups, persistedGroups)
          }

          setGroupContext((prev) => ({
            ...prev,
            isLoading: false,
            groups: fetchGroups
          }))

          return fetchGroups
        }
      } catch (e) {
        //
      } finally {
        setGroupContext((prev) => ({
          ...prev,
          isLoading: false
        }))
      }
    },
    [defaultValues.groups, setSavedGroups, setValue]
  )

  useEffect(() => {
    if (defaultValues?.selectedFormFolder?.value) {
      fetchGroups(defaultValues?.selectedFormFolder.value, true)
    }
  }, [defaultValues?.selectedFormFolder?.value, fetchGroups])

  const handlePutAttribute = async (attributeKey, attributeValue) => {
    try {
      const payload = {
        [attributeKey]: attributeValue
      }
      switch (attributeKey) {
        case 'name':
          if (!attributeValue) {
            return Notification({
              icon: 'error',
              text: 'The template name cannot be blank'
            })
          }
          break
        case 'map_view':
          setValue('map_view', attributeValue)
          break
        case 'active':
          setValue('active', attributeValue)
          break
        case 'template_setting_attributes':
          setValue('template_setting_attributes', attributeValue)
          if (formValues?.template_setting_attributes?.id) {
            payload.template_setting_attributes_id =
              formValues?.template_setting_attributes?.id
            payload.template_setting_attributes = {
              ...attributeValue,
              form_permission: attributeValue.value,
              id: formValues?.template_setting_attributes?.id
            }
          } else {
            payload.template_setting_attributes = {
              form_permission: attributeValue.value,
              id: null
            }
          }
          break
        case 'form_folder_id':
        case 'template_groups_attributes':
          {
            const groupsToDestroy = destroyRef.current.groups
              .flatMap((group) => group)
              .map((group) => ({ ...group, _destroy: true }))
              .filter(({ _id }) => !!_id)
            destroyRef.current.groups = []
            const groupsToSubmit =
              attributeKey === 'form_folder_id'
                ? [...groupsToDestroy]
                : [...attributeValue, ...groupsToDestroy]
            payload.template_groups_attributes = groupsToSubmit.map(
              ({ id, _id, _destroy }) =>
                omit(
                  {
                    group_id: id,
                    id: _id,
                    _destroy: _destroy ?? false
                  },
                  [!_id ? 'id' : ''].filter((v) => v)
                )
            )
            if (!payload?.form_folder_id) {
              payload.form_folder_id = formValues.form_folder_id
            }
          }
          break
        case 'notify':
          {
            const {
              notify,
              notify_user_form,
              notify_templates_users_attributes,
              notify_templates_users_attributes_deleted
            } = attributeValue
            payload.notify = notify
            payload.notify_user_form = notify_user_form
            payload.notify_templates_users_attributes = [
              ...differenceBy(
                notify_templates_users_attributes ?? [],
                notify_templates_users_attributes_deleted ?? [],
                'id'
              ),
              ...(notify_templates_users_attributes_deleted ?? [])
            ]
          }
          break
        default:
          break
      }
      if (isEqual(attributeValue, lastFormRef.current[attributeKey])) {
        return null
      }
      lastFormRef.current = { ...lastFormRef.current, ...payload }
      const response = await OlingaAPI.templatesUpdate(defaultValues, {
        template: {
          ...payload
        }
      })
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: response.message ?? 'Template updated successfully',
          autoClose: 1000,
          position: 'top-right'
        })

        const data = response?.data?.data?.attributes

        switch (attributeKey) {
          case 'template_groups_attributes':
          case 'form_folder_id': {
            const updatedGroups = await fetchGroups(data.form_folder_id, false)
            setSavedGroups(updatedGroups, data.template_groups_attributes)
            break
          }
          case 'notify':
            setValue(
              'notify_templates_users_attributes',
              data?.notify_templates_users_attributes
            )
            setValue('notify_templates_users_attributes_deleted', [])
            setValue('notify', data?.notify)
            break
          case 'template_setting_attributes':
            setValue('template_setting_attributes', {
              ...formValues.template_setting_attributes,
              value: data.template_setting_attributes.formPermission,
              id: data.template_setting_attributes.id
            })
            break
        }
      }
    } catch (e) {
      //
    }
  }

  const handleToggleActive = async (currentActiveState) => {
    setValue('active', currentActiveState)
    try {
      const response = await OlingaAPI.templateToggleActive(defaultValues.id)
      Notification({
        icon: 'success',
        text: response.message ?? 'Template updated successfully',
        autoClose: 1000,
        position: 'top-right'
      })
    } catch (error) {
      setFormErrors(error, noop, Notification)
      setValue('active', !currentActiveState)
    }
  }

  const handleGroupsToDestroy = (selectedGroups) => {
    destroyRef.current.groups = differenceBy(
      formValues.template_groups_attributes,
      selectedGroups,
      'id'
    )
  }

  const onFormFolderSelect = async (formFolder) => {
    const { value: id } = formFolder
    destroyRef.current.groups = formValues.template_groups_attributes
    setValue('template_groups_attributes', [])
    setValue('form_folder_id', formFolder)
    fetchGroups(id)

    handlePutAttribute('form_folder_id', formFolder.value ?? null)
  }

  const onChangeGroups = (selectedGroups) => {
    handleGroupsToDestroy(selectedGroups)
    setValue('template_groups_attributes', selectedGroups)

    handlePutAttribute('template_groups_attributes', selectedGroups)
  }

  const onSaveNotifiedUsers = () => {
    const values = pick(formValues, [
      'notify',
      'notify_user_form',
      'notify_templates_users_attributes',
      'notify_templates_users_attributes_deleted'
    ])
    notificationsRef.current.close()
    handlePutAttribute('notify', values)
  }

  const { pageTitle, singularTitle } = usePageProperties({
    pageName: 'templates'
  })

  const isBusiness = isEqual(get(user, 'personable_type'), 'Business')

  return {
    handlePutAttribute,
    handleToggleActive,
    formPermissions,
    register,
    formValues,
    fetchGroups,
    groupContext,
    onFormFolderSelect,
    onChangeGroups,
    notificationsRef,
    control,
    setValue,
    watch,
    onSaveNotifiedUsers,
    pageTitle,
    singularTitle,
    isBusiness
  }
}

export default useInlineHeader
