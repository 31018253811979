import { Button } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../../components/PageHeader'
import { getRouteUrl } from '../../../helpers'
import BreadcrumbChecklist from './Breadcrumb'

const ChecklistPageHeader = ({
  id,
  name,
  readOnly,
  singularTitle,
  pageTitle
}) => {
  return (
    <PageHeader
      Actions={
        <Link to={getRouteUrl('checklists')}>
          <Button colorScheme="gray">Cancel</Button>
        </Link>
      }
      Breadcrumb={
        <BreadcrumbChecklist
          readOnly={readOnly}
          name={name}
          id={id}
          pageTitle={pageTitle}
          singularTitle={singularTitle}
        />
      }
      title={
        id
          ? readOnly
            ? `Show ${singularTitle}`
            : `Edit ${singularTitle}`
          : `New ${singularTitle}`
      }
    />
  )
}

export default ChecklistPageHeader
