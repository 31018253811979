import theme from '@chakra-ui/theme'
import styled from '@emotion/styled'

export const InputWrapper = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

  &:focus,
  &:hover {
    border-color: #2196f3;
    cursor: pointer;
  }

  ${({ isInvalid }) =>
    isInvalid &&
    `
    border-color: ${theme.colors.red[500]};
  `}
`

export const PreviewInputWrapper = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ file }) => (file ? '0px' : '24px')};
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

  &:focus,
  &:hover {
    border-color: #2196f3;
    cursor: pointer;
  }

  ${({ isInvalid }) =>
    isInvalid &&
    `
    border-color: ${theme.colors.red[500]};
  `}
`

export const Input = styled.input`
  display: none;
`

export const ThumbsWrapper = styled.aside`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;

  & > *:not(:last-child) {
    margin-right: 16px;
  }
`

export const Thumb = styled.div`
  display: inline-flex;
  border-radius: 2px;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  justify-content: center;
  padding: 4px;
  box-sizing: border-box;
`

export const ThumbInner = styled.div`
  display: flex;
  min-width: 0px;

  &:hover {
    cursor: pointer;

    & > img {
      opacity: 0.5;
    }
    & > i {
      display: block;
    }
  }
`

export const Img = styled.img`
  display: block;
  width: auto;
  height: 100%;
  object-fit: cover;
`

export const RemoveIcon = styled.i`
  position: absolute;
  font-style: normal;
  font-size: 12px;
  display: block;
  display: none;
  padding: 5px;
  background: #eaeaea;
  width: 92px;
  margin-top: 68px;
`
export const IconPreview = styled.div`
  ${({ color }) => `
    width: 100px;
    height: 100px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eaeaea;
    flex-direction: column;
    padding: 8px;
    box-sizing: border-box;
    cursor: pointer;

    & p,
    & b {
      font-size: 12px;
      color: #333;
      margin-top: 16px;
      text-align: center;
    }

    & i {
      font-size: 32px;
      color: ${color};
    }
  `}
`
