export const actions = {
  ADD_QUESTION: 'add_question',
  APPEND_QUESTION: 'append_question',
  ADD_SECTION: 'add_section',
  APPEND_SECTION: 'append_section',
  ADD_INSTRUCTION: 'add_instruction',
  APPEND_INSTRUCTION: 'append_instruction',
  ADD_LOGIC: 'add_logic',
  APPEND_LOGIC: 'append_logic',
  REMOVE: 'remove',
  REMOVE_ALL_LOGICS: 'remove_all_logics',
  REMOVE_PERSISTED_ELEMENT: 'remove_persisted_element',
  OPEN_SETTINGS: 'open_settings',
  SET_GHOST_FIELD: 'set_ghost_field',
  UNFOCUS: 'unfocus',
  SET_FOCUS: 'set_focus',
  SET_DIALOG_CONTENT: 'set_dialog_content'
}
