/* eslint-disable react/no-unescaped-entities */
import { Alert, AlertDescription, AlertIcon, CloseButton, Flex, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { useSelector } from 'react-redux'

const MasqueradeAlert = () => {
  const { user, userMasquerade } = useSelector(
    ({ globalReducer }) => globalReducer
  )
  const {
    isOpen: isVisible,
    onClose,
  } = useDisclosure({ defaultIsOpen: true })

  return (
    <>
      {!user.isAdmin && userMasquerade && isVisible ? 
        (
          <Alert status='info'>
            <AlertIcon />
            <Flex w='100%' justifyContent='space-between' alignItems='center'>
              <AlertDescription>You're now logged in with user {user.email}, any actions made from here will be persisted as if it was this user who made it.</AlertDescription>
            
              <CloseButton
              onClick={onClose}
            />
            </Flex>
          </Alert>
        ) : null
      }
    </>
  )
}

export default MasqueradeAlert