import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Input,
  Select,
  VStack
} from '@chakra-ui/react'
import { noop } from 'lodash'
import React, { useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import Dialog from '../../../../../../components/AlertDialog'
import FormField from '../../../../../../components/FormField'
import QuestionsSelector from '../../../../../../components/QuestionsSelector'
import RadioGroup from '../../../../../../components/RadioGroup'
import { defaultContextValue } from '../../../helpers'

const TemplateStep = ({
  reportType,
  contexts,
  headerQuestions,
  structure,
  formFieldKey
}) => {
  const { register, watch, setValue } = useFormContext()
  const dialogRef = useRef()
  const contextChangeDialogRef = useRef()
  const contextRef = useRef({ currentContext: null, contextToBeSelected: null })
  const questionsSelectorRef = useRef({ open: noop, close: noop })
  const [mainQuestion, relatedQuestions, chartType, context] = watch(
    [
      `${formFieldKey}.main_question`,
      `${formFieldKey}.related_questions`,
      `${formFieldKey}.chart_type`,
      `${formFieldKey}.context`
    ],
    { context: defaultContextValue }
  )

  const onMainQuestionDialogClose = (onClose) => {
    onClose()
  }

  const onContextChangeDialogClose = (onClose) => {
    setValue(`${formFieldKey}.context`, contextRef.current.currentContext)
    onClose()
  }

  const onContextChange = (selectedContext) => {
    const { open } = contextChangeDialogRef.current
    const contextToBeSelected = contexts.find(
      ({ value }) => value === selectedContext?.value
    )
    contextRef.current.contextToBeSelected = contextToBeSelected
    contextRef.current.currentContext = context
    if (
      relatedQuestions?.length &&
      context?.value !== contextToBeSelected.value
    ) {
      open()
    } else {
      setValue(`${formFieldKey}.context`, contextToBeSelected)
      setValue(`${formFieldKey}.main_question`, null)
    }
  }

  const onContextChangeContinue = (onClose) => {
    setValue(`${formFieldKey}.context`, contextRef.current.contextToBeSelected)
    setValue(`${formFieldKey}.related_questions`, [])
    setValue(`${formFieldKey}.main_question`, null)
    onClose()
  }

  const onMainQuestionConfirm = (mainQuestion) => {
    const selectedMainQuestion = headerQuestions.find(
      ({ data: { id } }) => id === mainQuestion
    )
    if (selectedMainQuestion) {
      setValue(`${formFieldKey}.main_question`, {
        id: mainQuestion,
        text: selectedMainQuestion.label
      })
    }
  }

  return (
    <Box>
      <Dialog
        ref={dialogRef}
        header="Warning"
        body="This template hasn't any question that could be used as a main question"
        cancelLabel="Close"
        handleClose={onMainQuestionDialogClose}
        hasConfirmButton={false}
      />
      <Dialog
        ref={contextChangeDialogRef}
        header="Warning"
        body="You are changing the context of this report item, if you continue all related questions will be deleted."
        cancelLabel="Close"
        handleClose={onContextChangeDialogClose}
        onContinue={onContextChangeContinue}
      />
      <VStack align="flex-start">
        <FormField label="Select the report context">
          <Select
            options={contexts}
            onChange={({ target: { value } }) => {
              onContextChange({ value })
            }}
            value={context?.value}
          >
            {contexts.map(({ value, label }, index) => (
              <option key={index} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField label="Chart type" isRequired={false}>
          <RadioGroup
            options={reportType}
            value={chartType}
            onChange={(value) => setValue(`${formFieldKey}.chart_type`, value)}
            direction="row"
          />
        </FormField>
        {context?.value === defaultContextValue.value && (
          <FormField isRequired label="Select the main question">
            <>
              <Alert borderRadius="sm" status="info">
                <AlertIcon />
                For this context only List and Single Select questions can be
                selected
              </Alert>
              <QuestionsSelector
                ref={questionsSelectorRef}
                childrenStructure={structure}
                displayLabels
                initialSelectedNodes={mainQuestion?.id}
                onConfirm={onMainQuestionConfirm}
                allowSelection={({ id }) =>
                  headerQuestions.some(
                    ({ data: { id: mainQuestionId } }) => mainQuestionId === id
                  )
                }
                selectionMode="single"
                label="Select the main question"
              >
                <Button colorScheme="blue" variant="outline">
                  {mainQuestion?.text
                    ? `Main Question: ${mainQuestion?.text}`
                    : 'Click to Select the main question'}
                </Button>
              </QuestionsSelector>
            </>
          </FormField>
        )}
        {mainQuestion && context?.value === defaultContextValue.value && (
          <FormField isRequired={false} label="Question label">
            <Input
              {...register(`${formFieldKey}.main_question.label`)}
              placeholder="Question label"
            />
          </FormField>
        )}
      </VStack>
    </Box>
  )
}

TemplateStep.displayName = 'TemplateStep'

export default TemplateStep
