import styled from '@emotion/styled'
import { keyframes, css } from '@emotion/react'
import { theme } from '@chakra-ui/react'

const slide = keyframes`
  from {
    transform: translateY(0px);
    opacity: 0;
  }
  to {
    transform: translateY(-16px);
    opacity: 1;
  }
`

export const Label = styled.div`
  ${({ delay, labelCustomStyles }) => css`
    font-size: 12px;
    padding: 4px;
    background-color: white;
    color: ${theme.colors['blue'][700]};
    font-weight: bold;
    top: 0px;
    left: 12px;
    opacity: 0;
    position: absolute;

    animation: ${slide} 0.5s ${delay} ease forwards;

    ${labelCustomStyles && labelCustomStyles};
  `}
`
