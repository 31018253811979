import { ViewIcon } from '@chakra-ui/icons'
import { IconButton } from '@chakra-ui/react'
import { getNumberFromText, getRouteUrl } from 'helpers'
import React from 'react'
import { useHistory } from 'react-router-dom'

const FormAnswerView = ({ templateId, formId }) => {
  const history = useHistory()
  return (
    <a
      rel="noreferrer noopener"
      href={getRouteUrl('formAnswer', {
        id: templateId,
        formId,
        context: 'asset',
        contextId: getNumberFromText(history.location.pathname)
      })}
    >
      <IconButton
        icon={<ViewIcon />}
        colorScheme="blue"
        size="sm"
        variant="outline"
        mr={4}
        aria-label={`Open form responses from form ${formId}`}
      />
    </a>
  )
}

export default FormAnswerView
