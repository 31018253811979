import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Stack,
  Tag,
  Text,
  useMediaQuery
} from '@chakra-ui/react'
import React from 'react'
import { v4 as uuid } from 'uuid'
import { useRecursiveStructureContext } from '../../../contexts/RecursiveStructureSelection'
import BaseStructure, {
  getDefaultStyles,
  getMobileFlexStyles
} from '../BaseStructure'
import Child from '../Child'
import QuestionLayout from '../QuestionLayout'
import SelectionComponent from '../SelectionComponent'

const Condition = (props) => {
  const { id, question_logic_option, value, childrenIndexes, children } = props
  const { allowSelection } = useRecursiveStructureContext()
  const [isMobile] = useMediaQuery('(max-width: 799px)')
  const getAllValues = (string) => {
    if (string.match(';')) {
      const values = string.split(';')
      return (
        <HStack spacing={2}>
          {values.map((value, index) => (
            <Tag colorScheme="cyan" key={index}>
              {value}
            </Tag>
          ))}
        </HStack>
      )
    }
    return <Tag colorScheme="cyan">{string}</Tag>
  }

  const buildCondition = (expressionText) => {
    return (
      <HStack gap={2} flexWrap="wrap">
        <Text>If</Text>
        <Tag colorScheme="blue" variant="outline">
          the answer
        </Tag>
        <Text>
          {expressionText.includes('is ')
            ? expressionText
            : `is ${expressionText}`}
        </Text>
        {getAllValues(value)}
      </HStack>
    )
  }

  const getLabel = () => {
    const { text: expressionText } = question_logic_option
    return buildCondition(expressionText)
  }

  return (
    <BaseStructure
      childrenIndexes={childrenIndexes}
      type="condition"
      hasChildren={children.length}
    >
      <AccordionItem
        {...getDefaultStyles('condition')}
        sx={{
          _hover: {
            shadow: 'lg'
          }
        }}
      >
        <AccordionButton>
          <QuestionLayout
            leftSlot={
              <HStack spacing={3}>
                {allowSelection({ ...props, type: 'condition' }) && (
                  <SelectionComponent id={id} type="condition" />
                )}
                <Stack {...getMobileFlexStyles(isMobile)} spacing={2}>
                  <Text fontWeight="bold">Condition: </Text>
                  {getLabel()}
                </Stack>
              </HStack>
            }
            rightSlot={null}
          />
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Stack gap={4} pt={4}>
            {children.map((child) => (
              <Child {...child} key={uuid()} />
            ))}
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </BaseStructure>
  )
}

export default Condition
