import { theme } from '@chakra-ui/react'

export const styles = {
  bg: 'rgba(0, 0, 0, 0.48)',
  zIndex: theme.zIndices.modal + 10,
  w: '100vw',
  h: '100vh',
  overflow: 'hidden',
  position: 'fixed',
  top: '-10px',
  left: '0',
  display: 'flex',
  align: 'center',
  justifyContent: 'center'
}
