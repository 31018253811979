import { Skeleton, Stack } from '@chakra-ui/react'
import { pick } from 'lodash'
import React from 'react'

const FolderContent = ({
  foldersStates,
  id,
  resourceName,
  getResources,
  otherAttributes,
  shinyResourceId
}) => {
  if (foldersStates[id].isLoading) {
    return (
      <Stack w="100%" minH="200px">
        {Array.from(new Array(15)).map((_, index) => {
          return <Skeleton h="50px" key={index} />
        })}
      </Stack>
    )
  }
  return getResources(
    foldersStates[id]?.isLoading ? null : foldersStates?.[id]?.[resourceName],
    shinyResourceId,
    id,
    foldersStates[id][resourceName].map((resource) =>
      pick(resource, otherAttributes)
    )
  )
}

FolderContent.defaultProps = {
  foldersStates: {},
  id: null,
  resourceName: '',
  getResources: () => null,
  otherAttributes: {},
  shinyResourceId: null
}

export default FolderContent
