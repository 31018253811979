import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useState } from 'react';
import ProfileRolesContent from './Profile';
import UserRolesContent from './UserRoles';

const RolesContent = ({
  profilesList,
  register,
  selectedProfile,
  updateProfile,
  displayProfileSelector,
  hasUserRolesByCategory,
  hasProfileRolesByCategory,
  rolesByProfile,
  onChangeTemplatesSelector,
  checklistsList,
  checklistsOfChecklistRole,
  templatesList,
  templatesOfFormRole,
  templatesOfTemplateRole,
  foldersOfDocumentRole,
  dashboardsOfDashbordRole,
  documentsList,
  dashboardsList,
  templatesPageTitle,
  checklistsPageTitle,
  assetTemplatesPageTitle,
  assetTemplatesOfAssetTemplateRole,
  assetTemplatesList,
  roles,
  getValues,
  data,
  setValue,
  disabledRolesCategories,
}) => {
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Tabs pt='20px' index={tabIndex} onChange={setTabIndex}>
      <TabList>
        {displayProfileSelector && <Tab>Profile</Tab>}
        <Tab>User Roles</Tab>
      </TabList>
      <TabPanels px={4} py={2}>
        {displayProfileSelector && 
          <TabPanel>
            <ProfileRolesContent
              profilesList={profilesList}
              isActive={displayProfileSelector && tabIndex === 0}
              register={register}
              selectedProfile={selectedProfile}
              updateProfile={updateProfile}
              displayProfileSelector={displayProfileSelector}
              hasProfileRolesByCategory={hasProfileRolesByCategory}
              rolesByProfile={rolesByProfile}
            />
          </TabPanel>
        }
        <TabPanel>
          <UserRolesContent
            register={register}
            onChangeTemplatesSelector={onChangeTemplatesSelector}
            checklistsList={checklistsList}
            checklistsOfChecklistRole={checklistsOfChecklistRole}
            templatesList={templatesList}
            templatesOfFormRole={templatesOfFormRole}
            templatesOfTemplateRole={templatesOfTemplateRole}
            foldersOfDocumentRole={foldersOfDocumentRole}
            dashboardsOfDashbordRole={dashboardsOfDashbordRole}
            documentsList={documentsList}
            dashboardsList={dashboardsList}
            templatesPageTitle={templatesPageTitle}
            checklistsPageTitle={checklistsPageTitle}
            assetTemplatesPageTitle={assetTemplatesPageTitle}
            assetTemplatesOfAssetTemplateRole={assetTemplatesOfAssetTemplateRole}
            assetTemplatesList={assetTemplatesList}
            hasUserRolesByCategory={hasUserRolesByCategory}
            disabledRolesCategories={disabledRolesCategories}
            roles={roles}
            data={data}
            getValues={getValues}
            setValue={setValue}
            isActive={(displayProfileSelector && tabIndex === 1 || !displayProfileSelector && tabIndex === 0)}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default RolesContent;