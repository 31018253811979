import { AddIcon } from '@chakra-ui/icons'
import { Button, Flex, Stack } from '@chakra-ui/react'
import SearchInput from 'components/SearchInput'
import React from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import { getRouteUrl } from '../../helpers'
import useLibrary from './useLibrary'

const Documents = () => {
  const {
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data,
    isLoading,
    refetch,
    fetchParams,
    pagination,
    onTextChange,
    pageTitle,
    hasPermissionToCreate
  } = useLibrary()

  return (
    <Stack as="section">
      <PageHeader
        Actions={
          <Flex align="center" gap={4}>
            <SearchInput
              value={fetchParams?.name}
              setValue={onTextChange}
              isLoading={isLoading}
              debounceTimeout={25}
              placeholder="Filter by name"
            />
            {hasPermissionToCreate ? (
              <Link
                to={{
                  pathname: getRouteUrl('createDocument'),
                  from: 'documentsLibrary'
                }}
              >
                <Button colorScheme="blue" leftIcon={<AddIcon />}>
                  New
                </Button>
              </Link>
            ) : null}
          </Flex>
        }
        title={pageTitle}
      />
      <Table
        shouldResetPage={fetchParams?.page === 1}
        isLoading={isLoading}
        data={data}
        columns={columns}
        pageCount={pagination?.last}
        totalItems={pagination?.items}
        fetchPage={refetch}
        initiallyHiddenColumns={hiddenColumns}
        persistedColumnsKey={hiddenColumnsKey}
      />
    </Stack>
  )
}

export default Documents
