import { Button } from '@chakra-ui/button'
import { LockIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement
} from '@chakra-ui/input'
import { Flex, Text } from '@chakra-ui/layout'
import { Tooltip } from '@chakra-ui/tooltip'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { RequiredInfo } from './styles'

const PasswordInput = ({
  placeholder,
  label,
  register,
  onChange,
  name,
  isRequired,
  isInvalid,
  shouldShowLockIcon,
  isDisabled,
  autoFocus
}) => {
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  return (
    <>
      {label && (
        <Flex pt={4}>
          <Text fontSize="sm" fontWeight="bold">
            {label}
          </Text>
          {isRequired && (
            <Tooltip label="This field is required">
              <RequiredInfo>*</RequiredInfo>
            </Tooltip>
          )}
        </Flex>
      )}
      <InputGroup size="md">
        {shouldShowLockIcon && (
          <InputLeftAddon>
            <LockIcon />
          </InputLeftAddon>
        )}
        <Input
          pr="4.5rem"
          type={show ? 'text' : 'password'}
          placeholder={placeholder}
          onChange={onChange}
          isInvalid={isInvalid}
          {...register(name, { required: isRequired })}
          isDisabled={isDisabled}
          autoFocus={autoFocus}
          data-testid='password-input'
        />

        <InputRightElement width="4.5rem">
          <Tooltip label={show ? 'Hide password' : 'Show password'}>
            <Button data-testid='password-change-view-btn' h="1.75rem" size="sm" onClick={handleClick}>
              {show ? <ViewOffIcon data-testid='view-off-icon' /> : <ViewIcon data-testid='view-icon' />}
            </Button>
          </Tooltip>
        </InputRightElement>
      </InputGroup>
    </>
  )
}

PasswordInput.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  register: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string,
  isRequired: PropTypes.bool,
  isInvalid: PropTypes.bool,
  shouldShowLockIcon: PropTypes.bool,
  isDisabled: PropTypes.bool,
  autoFocus: PropTypes.bool
}

PasswordInput.defaultProps = {
  placeholder: 'Enter password',
  onChange: _.noop,
  shouldShowLockIcon: false,
  isDisabled: false,
  register: _.noop,
  autoFocus: false
}

export default PasswordInput
