/* eslint-disable react/jsx-key */
import { isEmpty } from 'lodash'
import React from 'react'
import FormBuilder from '..'
import Component from './Component'

const Field = ({ field, formKey, rolesData, attribute }) => {
  if (isEmpty(field)) return '-'

  return React.Children.toArray(
    field?.map((fieldProps, index) => {
      if (!fieldProps.attribute) {
        return (
          <FormBuilder
            rolesData={rolesData}
            formKey={`${formKey}.${attribute}.${index}`}
            values={[fieldProps]}
            isNested
          />
        )
      }

      return (
        <Component
          rolesData={rolesData}
          fieldKey={`${formKey}.${attribute}.${fieldProps.attribute}`}
          fieldProps={fieldProps}
        />
      )
    })
  )
}

export default Field
