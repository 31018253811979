export const globalEvents = {
  ON_TEMPLATE_SETTINGS_TAB_CHANGE: 'ON_TEMPLATE_SETTINGS_TAB_CHANGE',
  ON_CHANGE_TEMPLATE_SETTINGS_TAB: 'ON_CHANGE_TEMPLATE_SETTINGS_TAB',
  ON_MANAGE_STAFF_SUCCESS: 'ON_MANAGE_STAFF_PERSIST_SUCCESS',
  ON_RELOAD_CHECKLIST_EDITOR: 'ON_RELOAD_CHECKLIST_EDITOR',
  ON_REFRESH_FORM: 'ON_REFRESH_FORM',
  ON_ADD_ASSET_TEMPLATE_FIELD: 'ON_ADD_ASSET_TEMPLATE_FIELD',
  ON_EDIT_ASSET_TEMPLATE_FIELD: 'ON_EDIT_ASSET_TEMPLATE_FIELD',
  ON_OPEN_ASSET_TEMPLATE_FIELDS_MOBILE_DRAWER:
    'ON_OPEN_ASSET_TEMPLATE_FIELDS_MOBILE_DRAWER',
  ON_OPEN_ASSET_FIELD_ATTRIBUTES_MODAL: 'ON_OPEN_ASSET_FIELD_ATTRIBUTES_MODAL',
  ON_SET_ASSET_TEMPLATE_ACTION_LOADING_STATE:
    'ON_SET_ASSET_TEMPLATE_ACTION_LOADING_STATE',
  ON_FETCH_ASSETS_TEMPLATES: 'ON_FETCH_ASSETS_TEMPLATES',
  ON_DESTROY_TEMPLATE_TYPE_QUESTION_ASSET_TYPES_ASSOCIATIONS:
    'ON_DESTROY_TEMPLATE_TYPE_QUESTION_ASSET_TYPES_ASSOCIATIONS',
  ON_OPEN_SET_DEFAULT_MAIN_FIELD_SELECTOR:
    'ON_OPEN_SET_DEFAULT_MAIN_FIELD_SELECTOR',
  ON_REFRESH_ASSETS_PAGE: 'ON_REFRESH_ASSETS_PAGE'
}

export const onTemplateSettingsTabChange = (payload) => ({
  type: globalEvents.ON_TEMPLATE_SETTINGS_TAB_CHANGE,
  payload
})

export const onChangeTemplateSettingsTab = (payload) => ({
  type: globalEvents.ON_CHANGE_TEMPLATE_SETTINGS_TAB,
  payload
})

export const onManageStaffSuccess = () => ({
  type: globalEvents.ON_MANAGE_STAFF_SUCCESS
})

export const onReloadChecklistEditor = () => ({
  type: globalEvents.ON_RELOAD_CHECKLIST_EDITOR
})

export const onRefreshForm = (focusedField) => ({
  type: globalEvents.ON_REFRESH_FORM,
  payload: focusedField
})

export const onOpenAssetTemplateFieldsMobileDrawer = () => ({
  type: globalEvents.ON_OPEN_ASSET_TEMPLATE_FIELDS_MOBILE_DRAWER
})

export const onAddAssetTemplateField = (data) => ({
  type: globalEvents.ON_ADD_ASSET_TEMPLATE_FIELD,
  payload: data
})

export const onEditAssetTemplateField = (data) => ({
  type: globalEvents.ON_EDIT_ASSET_TEMPLATE_FIELD,
  payload: data
})

export const onSetAssetTemplateActionLoadingState = (data) => ({
  type: globalEvents.ON_SET_ASSET_TEMPLATE_ACTION_LOADING_STATE,
  payload: data
})

export const onOpenAssetFieldAttributesModal = (data) => ({
  type: globalEvents.ON_OPEN_ASSET_FIELD_ATTRIBUTES_MODAL,
  payload: data
})

export const onFetchAssetsTemplates = () => ({
  type: globalEvents.ON_FETCH_ASSETS_TEMPLATES
})

export const onDestroyTemplateTypeQuestionAssetTypesAssociation = (data) => ({
  type: globalEvents.ON_DESTROY_TEMPLATE_TYPE_QUESTION_ASSET_TYPES_ASSOCIATIONS,
  payload: data
})

export const onOpenSetDefaultMainFieldSelector = () => ({
  type: globalEvents.ON_OPEN_SET_DEFAULT_MAIN_FIELD_SELECTOR
})

export const onRefreshAssetsPage = () => ({
  type: globalEvents.ON_REFRESH_ASSETS_PAGE
})
