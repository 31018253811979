import { Flex, FormControl, Stack, Tooltip } from '@chakra-ui/react'
import DatePckr from 'components/DatePicker'
import PageHeader from 'components/PageHeader'
import SearchInput from 'components/SearchInput'
import Table from 'components/Table'
import { usePageProperties } from 'hooks'
import { DatePickerContainer } from 'pages/Form/styles'
import TemplatePageBreadcrumb from 'pages/Template/TemplatePagesBreadcrumb'
import React from 'react'
import FormAnswersActions from './FormAnswersActions'
import useFormAnswers from './hooks/useFormAnswers'

const FormAnswers = ({ data, id }) => {
  const {
    onChangePage,
    onTextChange,
    onSearchRangeChange,
    template,
    templateDetails,
    isMobile,
    isLoading,
    fetchParams,
    tableData,
    pagination,
    columns,
    hiddenColumns,
    tableKey,
    formIds,
    places,
    formAnswersActionsRef
  } = useFormAnswers({ id, data })

  const { pageTitle, singularTitle } = usePageProperties({
    pageName: 'templates'
  })

  const getCustomColumnStyles = (cell) => {
    if (!['id', 'staff', 'date'].includes(cell?.column?.id)) {
      return {
        maxW: ['100px', '400px']
      }
    }
  }

  return (
    <Stack w="100%">
      <PageHeader
        title="Form responses"
        Breadcrumb={
          <TemplatePageBreadcrumb
            routeName="Form responses"
            ariaLabel={`Form responses kebab options ${id}`}
            templateId={id}
            templateDetails={templateDetails}
            pageTitle={pageTitle}
            singularTitle={singularTitle}
          />
        }
        Actions={
          <Flex flexDir={['column', 'row']} gap={4} align="center">
            <Tooltip label='Filter by template name, staff name, answer, asset name, asset type or asset fields'>
              <FormControl mt="auto">
                <SearchInput
                  value={fetchParams?.text}
                  setValue={onTextChange}
                  isLoading={isLoading}
                  debounceTimeout={25}
                  placeholder="Filter"
                />
              </FormControl>
            </Tooltip>
            <FormAnswersActions
              template={template}
              places={places}
              formIds={formIds}
              exportParams={fetchParams}
              isLoading={isLoading}
              ref={formAnswersActionsRef}
            />
          </Flex>
        }
      />
      <DatePickerContainer ismobile={isMobile}>
        <DatePckr
          isRangePicker
          onChange={onSearchRangeChange}
          showTimeSelect={false}
          minW="380px"
          keepDateOnClear
          monthsShown={2}
          placeholder="Search by date range"
          leftDistance="-180px"
        />
      </DatePickerContainer>
      <Table
        shouldResetPage={fetchParams?.page === 1}
        columns={columns}
        data={tableData}
        isLoading={isLoading}
        pageCount={pagination?.last}
        totalItems={pagination?.items}
        totalCount={pagination?.count}
        fetchPage={onChangePage}
        initiallyHiddenColumns={hiddenColumns}
        persistedColumnsKey={tableKey}
        customColumnStyles={getCustomColumnStyles}
        shouldItemsMessageOnFooter
      />
    </Stack>
  )
}

export default FormAnswers
