import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Stack
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import ActionCard from '../ActionCard'

const ActionsAccordion = ({ actions, onOpenSidebar }) => {
  return (
    <Accordion defaultIndex={0} allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Created Actions
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel p={0}>
          <Stack py={2} gap={2}>
            {actions.map((action, index) => {
              return (
                <ActionCard
                  onOpenSidebar={() => onOpenSidebar(action, index)}
                  action={action}
                  key={index}
                  index={index}
                />
              )
            })}
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

ActionsAccordion.defaultProps = {
  actions: []
}

ActionsAccordion.propTypes = {
  actions: PropTypes.array,
  onOpenSidebar: PropTypes.func
}

export default ActionsAccordion
