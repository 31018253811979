import {Button, Container, Stack} from '@chakra-ui/react'
import moment from 'moment'
import React, {useRef} from 'react'
import {Link, useHistory} from 'react-router-dom'
import LazyComponent from '../../../components/LazyComponent'
import PageHeader from '../../../components/PageHeader'
import {getRouteUrl, setFormErrors} from '../../../helpers'
import Notification from '../../../services/Notification'
import OlingaAPI from '../../../services/OlingaAPI'
import BasePageSkeleton from '../../BasePage/Skeleton'
import ActionEditor from '../../Form/FillForm/ActionsBar/ActionSidebar/ActionEditor'
import {useQuestionActions} from '../../Form/FillForm/ActionsContext'
import ActionBreadcrumb from '../ActionBreadcrumb'

const PageActionEditor = ({ id }) => {
  const { setIsLoading, action } = useQuestionActions()
  const history = useHistory()
  const saveButtonRef = useRef(null)

  const onEdit = async (data, setError) => {
    setIsLoading(true)
    const submitData = {
      ...data,
      due_date: moment(data.due_date).format(),
      user_ids: (data?.user_ids ?? []).map(({ value }) => value)
    }
    try {
      const endpoint = id ? 'editAction' : 'createAction'
      const response = await OlingaAPI[endpoint](submitData, id)
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: response.data.message
        })
        if (history?.location?.from) {
          history.goBack()
        } else {
          history.push(getRouteUrl('actions'))
        }
      }
    } catch (error) {
      setFormErrors(error, setError, Notification)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Stack gap={4} w="100%">
      <Container maxW="1200px">
        <PageHeader
          Breadcrumb={<ActionBreadcrumb action={action} />}
          title={id ? `Action ${id}` : 'New Action'}
          Actions={
            <>
              <Link to={getRouteUrl('actions')}>
                <Button>Cancel</Button>
              </Link>
            </>
          }
        />
        <LazyComponent condition={id ? action?.id : true}>
          <ActionEditor
            hasFullWidthTitle={false}
            action={action}
            ref={saveButtonRef}
            onEdit={onEdit}
            CustomSkeleton={<BasePageSkeleton />}
            isCreation
          />
        </LazyComponent>
      </Container>
    </Stack>
  )
}

export default PageActionEditor
