import { Box, Flex, Heading, Stack, Tag, theme } from '@chakra-ui/react'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import EmptyState from '../../../components/EmptyState'
import DocumentKebab from '../DocumentKebab'
import { Wrapper } from '../styles'

const DocumentListItem = ({ documents, pageTitle }) => {
  if (documents && documents.length === 0) {
    return <EmptyState text={`No ${pageTitle} found`} />
  }
  return (
    Array.isArray(documents) &&
    documents.map((document) => {
      const key = `${document.id}-${uuidv4()}`
      return (
        <Wrapper data-id={`documents-${document.id}`} key={key}>
          <Box shadow="sm" p="4">
            <Stack>
              <Flex align="center" justify="flex-start">
                <Flex
                  align={['flex-start', 'center']}
                  direction={['column', 'row']}
                  w="100%"
                >
                  <Box
                    as="a"
                    href={document.attributes?.attachment?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    w={['inherit', 'max-content']}
                  >
                    <Heading
                      cursor={
                        !document.attributes?.attachment?.url
                          ? 'not-allowed'
                          : 'pointer'
                      }
                      _hover={{ color: theme.colors.orange[700] }}
                      color="orange.500"
                      size="sm"
                      w="inherit"
                      isTruncated
                    >
                      {document.attributes.name}
                    </Heading>
                  </Box>
                  <Flex align="center">
                    <Tag
                      ml={2}
                      mr={4}
                      h="20px"
                      colorScheme={
                        document.attributes?.active ? 'green' : 'gray'
                      }
                      size="sm"
                      minWidth="auto"
                    >
                      {document.attributes?.active ? 'Active' : 'Inactive'}
                    </Tag>
                    {document.attributes?.userPermissions?.edit && (
                      <DocumentKebab
                        id={document.id}
                        blackList={[{ route: 'createDocument' }]}
                        size="sm"
                        hasPermissionToModifyDocument={
                          document.attributes?.userPermissions?.edit
                        }
                        pageTitle={pageTitle}
                        from="documents"
                      />
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Stack>
          </Box>
        </Wrapper>
      )
    })
  )
}

DocumentListItem.defaultProps = {
  documents: [],
  pageTitle: 'Documents'
}

export default DocumentListItem
