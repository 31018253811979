import { Text } from '@chakra-ui/react'
import AssetSelector from 'components/AssetSelector'
import AssetTemplateSelector from 'components/AssetTemplateSelector'
import TemplatesSelector from 'components/TemplatesSelector'
import { noop } from 'lodash'
import React, { memo } from 'react'

const AssociationSelector = ({
  association,
  selections = {},
  onChange,
  value,
  onOpen
}) => {
  let Component = null
  switch (association?.value) {
    case 'template':
    case 'template_question':
    case 'template_question_option':
      Component = (
        <TemplatesSelector
          menuPlacement='top'
          association={association?.value}
          onChange={onChange}
          allowSelection={({ type, question_type }) =>
            ['logic', 'question'].includes(type) &&
            (association?.value === 'template_question_option'
              ? ['radio_btn', 'checkbox', 'list'].includes(question_type)
              : true)
          }
          onOpen={
            association?.value === 'template_question_option' ? onOpen : noop
          }
          selections={selections}
        />
      )
      break
    case 'asset':
      Component = <AssetSelector onChange={onChange} value={value} menuPlacement='top' />
      break
    case 'asset_template':
    case 'asset_template_field':
    case 'asset_template_field_option':
      Component = (
        <AssetTemplateSelector
          onChange={onChange}
          association={association?.value}
          selections={selections}
          menuPlacement='top'
        />
      )
      break
    case null:
      Component = <Text>Please, select an association</Text>
      break
    default:
      Component = null
      break
  }

  return Component
}

export default memo(AssociationSelector)
