import { Box, Button, HStack, Input, Stack } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import React, { useMemo, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import FormField from '../../../../../../../components/FormField'
import QuestionsSelector from '../../../../../../../components/QuestionsSelector'

const RelatedQuestion = ({
  bodyQuestions,
  structure,
  parentFormFieldKey,
  counterType,
  index
}) => {
  const { register, setValue, watch } = useFormContext()
  const questionsSelectorRef = useRef()
  const fieldKey = useMemo(() => {
    return `${parentFormFieldKey}.${index}`
  }, [index, parentFormFieldKey])
  const relatedQuestion = watch(`${parentFormFieldKey}.${index}`, {})

  const onRelatedQuestionConfirm = (selectedQuestion) => {
    const selectedRelatedQuestion = bodyQuestions.find(
      ({ data: { id } }) => id === selectedQuestion
    )
    if (selectedRelatedQuestion) {
      setValue(fieldKey, {
        ...relatedQuestion,
        question_id: selectedQuestion,
        text: selectedRelatedQuestion?.label,
        question_type: selectedRelatedQuestion?.data?.question_type
      })
    }
  }

  return (
    <Stack>
      <HStack px={4} justify="flex-start" spacing={4} w="100%">
        <Box flex={1}>
          <FormField label="Select a question ">
            <QuestionsSelector
              ref={questionsSelectorRef}
              childrenStructure={structure}
              displayLabels
              initialSelectedNodes={relatedQuestion?.question_id}
              onConfirm={onRelatedQuestionConfirm}
              allowSelection={({ id }) =>
                bodyQuestions.some(
                  ({ data: { id: relatedQuestion } }) => relatedQuestion === id
                )
              }
              selectionMode="single"
              label="Select a Question"
            >
              <Button colorScheme="blue">
                {relatedQuestion?.text
                  ? `Selected Question: ${relatedQuestion?.text}`
                  : 'Select a Question'}
              </Button>
            </QuestionsSelector>
          </FormField>
        </Box>
        {relatedQuestion?.question_type === 'counter' && (
          <Box minW="200px">
            <FormField label="Counter type">
              <Select
                options={counterType}
                placeholder="Counter type"
                onChange={({ label, value }) =>
                  setValue(`${fieldKey}.counter_type`, {
                    value,
                    label
                  })
                }
                defaultValue={{
                  label: relatedQuestion?.counter_type?.label,
                  value: relatedQuestion?.counter_type?.value
                }}
              />
            </FormField>
          </Box>
        )}
      </HStack>
      {relatedQuestion?.question_id && (
        <Box px={4}>
          <FormField isRequired={false} label="Question label">
            <Input
              {...register(`${fieldKey}.question_label`)}
              placeholder="Question label"
            />
          </FormField>
        </Box>
      )}
    </Stack>
  )
}

RelatedQuestion.defaultProps = {
  bodyQuestions: []
}

export default RelatedQuestion
