const routes = {
  assetTypes: () => '/sm/web/asset_types',
  newAssetType: () => '/sm/web/asset_types/new',
  editAssetType: ({ id }) => `/sm/web/asset_types/${id}`,
  assetDefaultFields: () => '/sm/web/asset_default_fields',
  newAssetDefaultFields: () => '/sm/web/asset_default_fields/new',
  editAssetDefaultFields: ({ id }) => `/sm/web/asset_default_fields/${id}`,
  businesses: () => '/sm/web/businesses',
  newBusiness: () => '/sm/web/businesses/new',
  editBusiness: ({ id }) => `/sm/web/business/${id}`,
  settings: () => '/sm/web/settings'
}

export default routes
