import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'
import { styles as overlayStyles } from './styles'

const OverlayElement = ({
  children,
  text,
  height,
  width,
  onClick,
  ariaLabel
}) => {
  return (
    <Box
      onClick={onClick}
      position="relative"
      h={height}
      width={width}
      borderRadius={4}
      _hover={{
        _after: {
          opacity: 1
        }
      }}
      _after={{ content: `"${text}"`, ...overlayStyles }}
      aria-label={ariaLabel}
    >
      {children}
    </Box>
  )
}

OverlayElement.defaultProps = {
  height: '100%',
  width: '100%',
  text: '',
  onClick: () => ({}),
  ariaLabel: 'Overlay element'
}

OverlayElement.propTypes = {
  children: PropTypes.any,
  text: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string
}

export default OverlayElement
