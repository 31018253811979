import { yupResolver } from '@hookform/resolvers/yup'
import {
  differenceBy,
  first,
  get,
  isEmpty,
  isNumber,
  isObject,
  isUndefined,
  omit,
  orderBy
} from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import { useGlobalStateContext } from '../../../../contexts/GlobalStateContext'
import { onReloadChecklistEditor } from '../../../../contexts/GlobalStateContext/events'
import { getRouteUrl, normalizeList, setFormErrors } from '../../../../helpers'
import { usePageProperties } from '../../../../hooks'
import Notification from '../../../../services/Notification'
import OlingaAPI from '../../../../services/OlingaAPI'

const schema = yup
  .object({
    name: yup.string().required('The name is required'),
    form_folder_id: yup.object().required('Form Folder is Required'),
    checklist_groups_attributes: yup.array().min(1)
  })
  .required()

const frequenciesOrders = {
  daily: 0,
  weekly: 1,
  fortnightly: 2,
  monthly: 3,
  quarterly: 4
}

const useChecklistEditor = (data) => {
  const history = useHistory()
  const { global$ } = useGlobalStateContext()

  const {
    pageTitle,
    singularTitle,
    shouldCreate: hasPermissionToCreateChecklist
  } = usePageProperties({ pageName: 'checklists' })

  useEffect(() => {
    if (!data?.data?.checklist?.id && !hasPermissionToCreateChecklist) {
      history.push(getRouteUrl('checklists'))
    }
  }, [data?.data?.checklist?.id, hasPermissionToCreateChecklist, history])

  const {
    checklist,
    formFolders,
    formFolder,
    frequencies,
    checklistItems,
    checklistGroups,
    limitForCompletionLimit,
    id,
    readOnly,
    isEdit
  } = useMemo(() => {
    const objData = data?.data ?? {}
    const { checklist, formFolders, enumerators } = objData
    const { user_tasks: show } =
      get(checklist, 'attributes.userPermissions') ?? {}
    const readOnly = !history.location.pathname.includes('edit') ? show : false
    const isEdit = history.location.pathname.includes('edit')
    const frequencies = orderBy(
      (get(enumerators, 'frequencies') ?? [])
        .filter(({ value }) =>
          ['daily', 'weekly', 'monthly', 'quarterly', 'fortnightly'].includes(
            value
          )
        )
        .reduce((acc, frequency) => {
          const order = frequenciesOrders[frequency.value]
          if (frequency.value === 'weekly') {
            const weekDays = (get(frequency, 'options.week_days') ?? []).map(
              (day, index) => ({ ...day, order: index })
            )

            acc.push({
              ...frequency,
              order,
              options: {
                ...(frequency?.options ?? {}),
                weekDays
              }
            })
          } else {
            acc.push({ ...frequency, order })
          }
          return acc
        }, []),
      ['order'],
      ['asc']
    )

    const limitForCompletionLimit =
      Number(get(enumerators, 'limit_for_completion_limit')) ?? 10
    if (checklist?.attributes) {
      const {
        attributes: { checklistGroups, formFolderId }
      } = checklist
      const folders = formFolders
      const allChecklistItems = [
        'checklistItemsDaily',
        'checklistItemsBiannual',
        'checklistItemsMonthly',
        'checklistItemsQuarterly',
        'checklistItemsWeekly',
        'checklistItemsFortnightly'
      ].reduce((acc, key) => {
        const formatSpecificObject = (key, obj) => {
          switch (key) {
            case 'monthly':
              return {
                ...obj,
                repeat_values: !isEmpty(obj?.repeat_values)
                  ? first(obj.repeat_values)
                  : {}
              }
            default:
              return obj
          }
        }
        const formattedKey = key.replace(/^checklistItems/g, '').toLowerCase()
        const items = checklist?.attributes[key] ?? []
        acc[formattedKey] = items.map((item) =>
          formatSpecificObject(formattedKey, {
            ...item,
            _id: uuidv4(),
            frequency: frequencies.find(
              ({ value }) => value === item.frequency
            ),
            repeat_values: item?.settings?.repeats ?? [],
            completion_limit: item?.settings?.completion_limit ?? 0
          })
        )

        return acc
      }, {})
      return {
        checklist: {
          ...(checklist?.attributes ?? {})
        },
        formFolder: folders.find(({ value }) => Number(value) === formFolderId),
        formFolders: folders,
        checklistGroups,
        checklistItems: allChecklistItems,
        frequencies,
        limitForCompletionLimit,
        id: Number(objData?.checklist?.id),
        readOnly,
        isEdit
      }
    }
    return {
      checklist: null,
      formFolder: null,
      formFolders: formFolders,
      checklistItems: {},
      checklistGroups: [],
      frequencies,
      limitForCompletionLimit,
      id: null,
      readOnly: false,
      isEdit
    }
  }, [data?.data, history.location.pathname])

  useEffect(() => {
    if (!isUndefined(formFolders) && isEmpty(formFolders)) {
      history.push(getRouteUrl('newFormFolder'))
      Notification({
        icon: 'error',
        text: 'This business has no folders. Please add a folder to create a new checklist'
      })
    }
  }, [formFolders, data, history])

  const {
    setValue,
    handleSubmit,
    register,
    watch,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: checklist?.name ?? '',
      form_folder_id: formFolder,
      active: checklist?.active ?? true,
      checklist_items_attributes: checklistItems,
      checklist_groups_attributes: []
    },
    resolver: yupResolver(schema)
  })

  const [groups, setGroups] = useState([])
  const [isLoadingGroups, setIsLoadingGroups] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const destroyRef = useRef({ groups: [], items: [] })
  const fetchGroupsRef = useRef(false)

  const currentGroups = watch('checklist_groups_attributes')

  const fetchGroups = useCallback(
    async (formFolderId, shouldSetGroups) => {
      try {
        setIsLoadingGroups(true)
        setValue('checklist_groups_attributes', [])
        const response = await OlingaAPI.groupsSelectOptionsList(formFolderId)
        if (response?.length) {
          const fetchGroups = normalizeList(response)
          const persistedGroups = checklistGroups
          if (shouldSetGroups && !!persistedGroups?.length) {
            setValue(
              'checklist_groups_attributes',
              fetchGroups.reduce((acc, group) => {
                const findGroup = persistedGroups.find(
                  ({ groupId }) => groupId === group.id
                )
                if (findGroup) {
                  acc.push({ ...group, _id: findGroup.id })
                }
                return acc
              }, [])
            )
          }

          setGroups(fetchGroups)
        }
      } catch (e) {
        //
      } finally {
        setIsLoadingGroups(false)
      }
    },
    [checklistGroups, setValue]
  )

  useEffect(() => {
    if (checklist?.id) {
      if (checklist?.formFolderId && !fetchGroupsRef.current) {
        fetchGroupsRef.current = true
        fetchGroups(checklist.formFolderId, true)
      }
    }
  }, [checklist?.id, checklist?.formFolderId, fetchGroups])

  const handleGroupsToDestroy = (selectedGroups) => {
    destroyRef.current.groups.push(
      differenceBy(currentGroups, selectedGroups, 'id')
    )
  }

  const onFormFolderSelect = (formFolder) => {
    const { value: id } = formFolder
    destroyRef.current.groups.push(currentGroups)
    setValue('checklist_groups_attributes', [])
    setValue('form_folder_id', formFolder)
    fetchGroups(id)
  }

  const onChangeGroups = (selectedGroups) => {
    handleGroupsToDestroy(selectedGroups)
    setValue('checklist_groups_attributes', selectedGroups)
  }

  const onSubmit = async (data) => {
    const getItem = (item, index) => {
      const settingsKeys = ['repeat_values', 'completion_limit']
      const settings = {}
      if (Object.keys(item).some((key) => settingsKeys.includes(key))) {
        settings.repeats = get(item, 'repeat_values')
        if (
          ['monthly'].includes(item?.frequency?.value) &&
          isObject(settings?.repeats)
        ) {
          settings.repeats = [settings?.repeats]
        }
        if (
          ['fortnightly'].includes(item?.frequency?.value) &&
          isNumber(item?.settings?.starting_week)
        ) {
          settings.starting_week = Number(
            get(item, 'settings.starting_week') ?? 0
          )
        }
        settings.completion_limit = Number(get(item, 'completion_limit')) ?? 0
      }
      return omit(
        {
          ...item,
          _destroy: false,
          order: index,
          frequency: get(item, 'frequency.value'),
          settings
        },
        ['context', 'key', '_id', 'repeat_values', 'completion_limit', 'index']
      )
    }

    if (isEmpty(data?.checklist_items_attributes ?? [])) {
      return Notification({
        icon: 'error',
        text: `The ${singularTitle} must have at least one task`
      })
    }

    let groupsToDestroy = destroyRef.current.groups
      .flatMap((group) => group)
      .map((group) => ({ ...group, _destroy: true }))
      .filter(({ _id }) => !!_id)
    const groupsToSubmit = [
      ...(!data.checklist_groups_attributes?.length
        ? groups.map(({ id }) => ({ id }))
        : data.checklist_groups_attributes),
      ...groupsToDestroy
    ]

    const submitData = {
      ...data,
      form_folder_id: data.form_folder_id.value,
      checklist_groups_attributes: groupsToSubmit.map(({ id, _id, _destroy }) =>
        omit(
          {
            group_id: id,
            id: _id,
            _destroy: _destroy ?? false
          },
          [!_id ? 'id' : ''].filter((v) => v)
        )
      ),
      checklist_items_attributes: [
        ...Object.values(data.checklist_items_attributes)
          .flatMap((a) => a)
          .map((item, index) => getItem(item, index)),
        ...destroyRef.current.items
      ].map((data) =>
        omit(
          {
            ...data
          },
          ['_id']
        )
      )
    }

    const callback = checklist?.id
      ? () => OlingaAPI.editChecklist(checklist.id, submitData)
      : () => OlingaAPI.createChecklist(submitData)

    try {
      setIsLoading(true)
      const response = await callback()
      if (response?.data?.id) {
        Notification({
          icon: 'success',
          text: checklist?.id
            ? `${singularTitle} updated`
            : `${singularTitle} created`
        })
        if (!checklist?.id) {
          history.push(getRouteUrl('checklists'))
        } else {
          global$.next(onReloadChecklistEditor())
        }
      }
    } catch (e) {
      setFormErrors(e, setError, Notification)
    } finally {
      setIsLoading(false)
    }
  }

  const onRemoveChecklistItem = (...args) => {
    const removedItem = args.at(2)
    if (removedItem?.id) {
      destroyRef.current.items.push({ ...removedItem, _destroy: true })
    }
  }

  return {
    checklist,
    setValue,
    handleSubmit,
    onSubmit,
    register,
    watch,
    formFolders,
    formFolder,
    frequencies,
    groups,
    onFormFolderSelect,
    isLoadingGroups,
    isLoading,
    onChangeGroups,
    onRemoveChecklistItem,
    errors,
    limitForCompletionLimit,
    id,
    readOnly,
    isEdit,
    singularTitle,
    pageTitle
  }
}

export default useChecklistEditor
