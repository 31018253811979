import BasePage from 'pages/BasePage'
import SummaryReportEditor from 'pages/Reports/SummaryReportEditor'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { getNumberFromText } from '../../../helpers'
import ReportsEditor from '../../../pages/Reports/ReportsEditor'
import OlingaAPI from '../../../services/OlingaAPI'

const ReportsEditorPage = ({ id }) => {
  const history = useHistory()

  const reportType = history.location.pathname.match('scheduled')
    ? 'scheduled'
    : history.location.pathname.match('summary')
    ? 'summary'
    : ''

  const callbacks = useMemo(() => {
    let returnedCallbacks = {
      enumerators: {
        callback: () => OlingaAPI.reportEnumerators(reportType)
      }
    }
    if (history.location.search?.includes('template_id')) {
      const templateId = getNumberFromText(history.location.search)
      returnedCallbacks = {
        ...returnedCallbacks,
        template: {
          callback: () =>
            OlingaAPI.templateStructure(templateId, {
              shouldIgnoreMainId: true
            })
        }
      }
    }
    if (id) {
      returnedCallbacks = {
        ...returnedCallbacks,
        report: {
          callback: () => OlingaAPI.editReport(id, reportType)
        }
      }
    }
    if (reportType === 'summary') {
      returnedCallbacks = {
        ...returnedCallbacks,
        templates: { callback: OlingaAPI.templatesEnumerators, params: {} }
      }
    }
    return returnedCallbacks
  }, [history.location.search, id, reportType])

  return (
    <BasePage
      fetchWithoutId
      id={id}
      resourceName="data"
      fetchCallback={callbacks}
      otherParams={{ id }}
    >
      {reportType === 'scheduled' ? <ReportsEditor /> : <SummaryReportEditor />}
    </BasePage>
  )
}

export default ReportsEditorPage
