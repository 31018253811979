import { AddIcon, EditIcon } from '@chakra-ui/icons'
import MaterialIcon from '../../../components/MaterialIcon'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import Kebab from '../../../components/Kebab'

const DocumentKebab = ({
  id,
  pageTitle,
  size,
  icon,
  zIndex,
  hasBorder,
  blackList,
  hasPermissionToModifyDocument,
  from
}) => {
  const location = useLocation()

  const currentRoute = useMemo(() => {
    const path = location.pathname
    if (path.match('new')) {
      return 'createDocument'
    } else if (path.match('edit')) {
      return 'editDocument'
    }
    return undefined
  }, [location])

  const routes = useMemo(() => {
    let items = [
      {
        name: `Create ${pageTitle}`,
        icon: <AddIcon />,
        route: 'createDocument',
        isVisible: hasPermissionToModifyDocument
      },
      {
        name: `Edit ${pageTitle}`,
        icon: <EditIcon />,
        route: 'editDocument',
        isVisible: hasPermissionToModifyDocument
      }
    ]
    if (!id) {
      items = items.filter(({ route }) => route !== 'editDocument')
    }
    if (blackList?.length) {
      return items.filter(({ route }) =>
        blackList.find(({ route: bRoute }) => bRoute !== route)
      )
    }
    return items
  }, [blackList, id, hasPermissionToModifyDocument, pageTitle])

  if (!hasPermissionToModifyDocument) {
    return null
  }

  return (
    <Kebab
      id={id}
      icon={icon}
      size={size}
      zIndex={zIndex}
      hasBorder={hasBorder}
      items={routes}
      blackList={[currentRoute]}
      blackListAttribute="route"
      hasVisibilityRestriction
      from={from}
    />
  )
}

DocumentKebab.defaultProps = {
  size: 'sm',
  pageTitle: 'Documents',
  zIndex: 10,
  hasBorder: true,
  icon: <MaterialIcon icon='more_vert' />,

  blackList: [],
  hasPermissionToModifyDocument: true,
  from: 'documents'
}

DocumentKebab.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageTitle: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  blackList: PropTypes.array,
  hasPermissionToModifyDocument: PropTypes.bool,
  from: PropTypes.string
}

export default DocumentKebab
