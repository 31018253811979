import { Button, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import GoogleMaps from 'components/GoogleMaps'
import MaterialIcon from 'components/MaterialIcon'
import Modal from 'components/Modal'
import ExportForms from 'pages/Form/ExportForms'
import React, { forwardRef } from 'react'

const FormAnswersActions = forwardRef(
  ({ template, places, exportParams, formIds, isLoading }, ref) => {
    if (!template?.id) {
      return null
    }

    const { templateName, id, mapView } = template
    return (
      <Flex gap={4} align="center">
        <Modal
          ref={ref}
          bodyHeight="auto"
          header={
            <Stack>
              <Heading isTruncated size="md">
                {templateName} - Map view
              </Heading>
              <Text fontSize="sm">
                This Map displays forms that were sent over a period of time
              </Text>
            </Stack>
          }
          body={<GoogleMaps places={places} template={template} />}
          size="full"
        >
          <Button
            mx={4}
            colorScheme="blue"
            leftIcon={<MaterialIcon icon='person_pin_circle' />}
            variant="outline"
            isDisabled={!mapView}
            isLoading={isLoading}
          >
            Map view
          </Button>
        </Modal>
        <ExportForms
          templateId={id}
          templateName={templateName}
          formCount={formIds.length}
          size="md"
          prefix="Export"
          params={exportParams}
          ariaLabel="Export"
          isDisabled={!formIds.length}
        />
      </Flex>
    )
  }
)

FormAnswersActions.displayName = 'FormAnswersActions'

export default FormAnswersActions
