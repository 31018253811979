import theme from '@chakra-ui/theme'
import styled from '@emotion/styled'

export const SectionWrapper = styled.div`
  border: 2px solid ${theme.colors.gray[200]};
  border-top: 0;
  padding: 0 16px;
  padding-bottom: 16px;

  & button {
    display: flex;
    justify-content: flex-start;
  }
`

export const SectionActionsWrapper = styled.footer`
  border: 2px solid ${theme.colors.gray[200]};
  padding: 0 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`

export const RemoveIcon = styled.div`
  font-style: normal;
  font-size: 16px;
  padding-left: 8px;
  padding-right: 4px;
  color: #e53e3e;
`

export const ButtonLabel = styled.span`
  p {
    margin-bottom: 0;
  }
`
