import PropTypes from 'prop-types'
import React from 'react'
import ResultGrouped from './ResultGrouped'
import ResultLine from './ResultLine'

const RenderResults = (props) => {
  const { isGrouped } = props
  if (isGrouped) {
    return <ResultGrouped {...props} />
  }
  return <ResultLine {...props} />
}

RenderResults.propTypes = {
  isGrouped: PropTypes.bool,
  results: PropTypes.any,
  attributeName: PropTypes.string,
  handleSelect: PropTypes.func,
  resultEndAdornment: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.number
  ]),
  onClose: PropTypes.func
}

export default RenderResults
