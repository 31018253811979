import { Flex, useMediaQuery, VStack } from '@chakra-ui/react'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import EmptyState from '../EmptyState'
import BoardOperations from './BoardOperations'
import CalculationOperations from './CalculatedOperations'
import { getStyles } from './styles'

const CalculationBoard = ({
  value,
  onChange,
  structure,
  id,
  RightAddon,
  operations,
  allowSetOperator
}) => {
  const [isMobile] = useMediaQuery('(max-width: 799px)')
  const isDisabled = useMemo(() => {
    return {
      shouldDisableOperations: false,
      shouldDisableChooseQuestion: false
    }
  }, [])

  const selectedNodes = useMemo(() => {
    const getSelection = (data) => {
      return [...data?.filter((v) => !!v?.id)?.flatMap(({ id }) => id), id]
    }
    if (value?.data) {
      return getSelection(value.data)
    }
    return getSelection(value ?? [])
  }, [id, value])

  return (
    <Flex {...getStyles(isMobile)} gap={4}>
      {value?.length ? (
        <Flex flexGrow={1} w="100%" gap={4} flexWrap="wrap">
          <BoardOperations operations={value} onChange={onChange} />
        </Flex>
      ) : (
        <EmptyState text="Operators cannot be empty" />
      )}
      {allowSetOperator && (
        <VStack gap={4}>
          {RightAddon && RightAddon}
          <CalculationOperations
            value={value}
            onChange={onChange}
            structure={structure}
            isDisabled={isDisabled}
            isEmpty={!value?.length}
            initialSelectedNodes={selectedNodes}
            isMobile={isMobile}
            operations={operations}
          />
        </VStack>
      )}
    </Flex>
  )
}

CalculationBoard.defaultProps = {
  value: [],
  onChange: noop,
  RightAddon: null,
  allowSetOperator: true
}

CalculationBoard.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  structure: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  RightAddon: PropTypes.element
}

export default CalculationBoard
