import React from 'react'
import { SimpleGrid, Skeleton, Stack } from '@chakra-ui/react'

const BasePageSkeleton = () => (
  <Stack>
    <Stack>
      <SimpleGrid columns={2}>
        <Skeleton w="100%" h="24px" />
        <Skeleton w="100%" h="24px" />
      </SimpleGrid>
      <Stack mt={6}>
        {Array.from(new Array(20)).map((_, index) => {
          return <Skeleton key={index} h="42px" w="100%" />
        })}
      </Stack>
    </Stack>
  </Stack>
)

export default BasePageSkeleton
