import { Divider } from '@chakra-ui/react'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { useTemplateEditorContext } from '../../../contexts/TemplateEditor'
import { ActionButton, FloatingActions } from '../styles'
import useFormActions from './hooks/useFormActions'
import useFormActionsPositioning from './hooks/useFormActionsPositioning'
import MobileActions from './MobileActions'
import MaterialIcon from '../../../components/MaterialIcon'

const FormActions = ({ top, template }) => {
  const { focusedField } = useTemplateEditorContext()

  const { position, attributeDisabled, elementType, isRemovable, ref } =
    useFormActionsPositioning({ template })

  const {
    addInstruction,
    addLogic,
    addQuestion,
    addSection,
    removeElement,
    removeFocus,
    onOpenSettings,
    onMouseOut,
    onMouseOver
  } = useFormActions()

  if (isMobile) {
    return <MobileActions />
  }

  return (
    <FloatingActions
      offsetY={position}
      data-id="form-actions"
      aria-label="Template Editor Action"
      ref={ref}
      top={top}
      role="list"
    >
      <ActionButton
        onClick={addQuestion}
        onMouseOver={() =>
          onMouseOver('question', attributeDisabled === 'question')
        }
        onMouseOut={onMouseOut}
        aria-label="Add question"
        type="question"
        disabled={attributeDisabled === 'question'}
        role="listitem"
        tabIndex={0}
      >
        <MaterialIcon icon='question_mark' />
        Question
      </ActionButton>
      <ActionButton
        onClick={addSection}
        onMouseOver={() =>
          onMouseOver('section', attributeDisabled === 'section')
        }
        onMouseOut={onMouseOut}
        aria-label="Add section"
        type="section"
        disabled={attributeDisabled === 'section'}
        role="listitem"
        tabIndex={0}
      >
        <MaterialIcon icon='format_list_numbered' />
        Section
      </ActionButton>
      <ActionButton
        onClick={addLogic}
        onMouseOver={() => onMouseOver('logic', attributeDisabled === 'logic')}
        onMouseOut={onMouseOut}
        aria-label="Add logic"
        type="logic"
        disabled={attributeDisabled === 'logic'}
        role="listitem"
        tabIndex={0}
      >
        <MaterialIcon icon='playlist_add_check' />
        Logic
      </ActionButton>
      <ActionButton
        onClick={addInstruction}
        onMouseOver={() =>
          onMouseOver('instruction', attributeDisabled === 'instruction')
        }
        onMouseOut={onMouseOut}
        aria-label="Add instruction"
        type="instruction"
        disabled={attributeDisabled === 'instruction'}
        role="listitem"
        tabIndex={0}
      >
        <MaterialIcon icon='info' />
        Instruction
      </ActionButton>
      {focusedField && isRemovable && (
        <ActionButton
          onClick={removeElement}
          aria-label="Remove"
          type="remove"
          role="listitem"
          tabIndex={0}
        >
          <MaterialIcon icon='delete_forever' />
          Remove {elementType ?? ''}
        </ActionButton>
      )}
      <Divider />
      {focusedField && (
        <ActionButton
          onClick={removeFocus}
          aria-label="Remove focus"
          type="focus"
          role="listitem"
          tabIndex={0}
        >
          <MaterialIcon icon='crop' />
          Unfocus
        </ActionButton>
      )}
      <ActionButton
        onClick={onOpenSettings}
        aria-label="Open Settings"
        type="instruction"
        role="listitem"
        tabIndex={0}
      >
        <MaterialIcon icon='settings' />
        Settings
      </ActionButton>
    </FloatingActions>
  )
}

FormActions.defaultProps = {
  top: '250px'
}

export default FormActions
