import { get } from 'lodash'
import ActionsBar from 'pages/Form/FillForm/ActionsBar'
import ActionSidebar from 'pages/Form/FillForm/ActionsBar/ActionSidebar'
import React from 'react'
import { useSelector } from 'react-redux'

const Action = ({ field, index, currentKey, template, isNested = false }) => {
  const { features } = useSelector(({ globalReducer }) => globalReducer)
  const hasPermission = get(features, 'actions.shouldCreate')
  const shouldRenderActions =
    template.action &&
    hasPermission &&
    !['calculation', 'instruction'].field?.question_type &&
    field.type === 'question'
  if (shouldRenderActions) {
    return (
      <>
        <ActionsBar
          fieldType={field.type}
          field={field}
          index={index}
          currentKey={currentKey}
          template={template}
          shouldDisablePhotosAndNotes={false}
          isNested={isNested}
          hasBorder
        />
        <ActionSidebar
          fieldType={field.type}
          field={field}
          index={index}
          currentKey={currentKey}
          template={template}
          shouldDisablePhotosAndNotes={false}
          isNested={isNested}
          hasBorder
        />
      </>
    )
  }
  return null
}

export default Action
