import {
  Button,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Text
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { capitalize } from 'lodash'
import React from 'react'
import Dialog from '../../../components/AlertDialog'
import FormField from '../../../components/FormField'
import LazyComponent from '../../../components/LazyComponent'
import useOnboardStaff from './useOnboardStaff'

const ManageStaffEditorModal = () => {
  const {
    register,
    handleSubmit,
    onSubmit,
    errors,
    isLoading,
    id,
    shouldRenderFormFields,
    onClose,
    groupDialogRef,
    onCloseGroupDialog,
    onGroupDialogConfirm,
    subject,
    additionalFields,
    formValues,
    onCustomFieldChange,
    onTelephoneChange
  } = useOnboardStaff()

  return (
    <>
      <Dialog
        ref={groupDialogRef}
        header="Confirm this action"
        body={`Wish to manage ${subject} group?`}
        cancelLabel="No"
        continurLabel="Yes"
        continueColor="blue"
        handleClose={onCloseGroupDialog}
        onContinue={onGroupDialogConfirm}
      />
      <Modal size="xl" isOpen onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Onboard {capitalize(subject)}</ModalHeader>
          <ModalCloseButton />
          <ModalBody gap={4}>
            <Stack>
              <LazyComponent
                skeletonHeight="72px"
                condition={shouldRenderFormFields}
              >
                <FormControl>
                  <FormField label="Full Name">
                    <Flex align="center" gap={2} w="100%">
                      <Flex flex={1}>
                        <Stack flex={1}>
                          <>
                            <Input
                              {...register('full_name')}
                              placeholder="Full Name"
                              isInvalid={!!errors?.full_name}
                            />
                            {!!errors?.full_name && (
                              <Text color="red" fontSize="xs">
                                {errors?.full_name?.message}
                              </Text>
                            )}
                          </>
                        </Stack>
                      </Flex>
                      {id === 'new' && (
                        <Switch
                          {...register('user_attributes.active')}
                          mb={errors?.full_name?.message ? 5 : 0}
                        >
                          Active
                        </Switch>
                      )}
                    </Flex>
                  </FormField>
                </FormControl>
              </LazyComponent>
              {id === 'new' && (
                <FormControl>
                  <FormField label="Email">
                    <>
                      <Input
                        type="email"
                        {...register('user_attributes.email')}
                        placeholder="Email"
                        isInvalid={!!errors?.user_attributes?.email}
                        isDisabled={id !== 'new'}
                      />
                      {!!errors?.user_attributes?.email && (
                        <Text color="red" fontSize="xs">
                          {errors?.user_attributes?.email?.message}
                        </Text>
                      )}
                    </>
                  </FormField>
                </FormControl>
              )}
              {additionalFields &&
                additionalFields.map(
                  (
                    { attribute_name, label, collection, compulsory },
                    index
                  ) => (
                    <LazyComponent
                      skeletonHeight="72px"
                      condition={shouldRenderFormFields}
                      key={index}
                    >
                      <FormControl>
                        <FormField
                          label={label}
                          isRequired={compulsory}
                          Action={
                            formValues?.[attribute_name] ? (
                              <Button
                                onClick={() =>
                                  onCustomFieldChange(attribute_name, null)
                                }
                                size="sm"
                                colorScheme="gray"
                              >
                                Clear
                              </Button>
                            ) : null
                          }
                        >
                          <>
                            <Select
                              isMulti={false}
                              options={collection}
                              onChange={(selectedOption) =>
                                onCustomFieldChange(
                                  attribute_name,
                                  selectedOption
                                )
                              }
                              isInvalid={!!errors?.[attribute_name]?.message}
                              value={formValues?.[attribute_name]}
                              hideSelectedOptions
                              placeholder={label}
                            />
                            {!!errors?.[attribute_name]?.message && (
                              <Text color="red" fontSize="xs">
                                {errors?.[attribute_name]?.message}
                              </Text>
                            )}
                          </>
                        </FormField>
                      </FormControl>
                    </LazyComponent>
                  )
                )}
              <LazyComponent
                skeletonHeight="72px"
                condition={shouldRenderFormFields}
              >
                <FormControl>
                  <FormField label="Telephone" isRequired={false}>
                    <Input
                      {...register('telephone')}
                      onChange={({ target: { value } }) =>
                        onTelephoneChange(value)
                      }
                      placeholder="Telephone"
                      isInvalid={!!errors?.telephone}
                    />
                  </FormField>
                </FormControl>
              </LazyComponent>
            </Stack>
          </ModalBody>

          <ModalFooter gap={4}>
            <Button isDisabled={isLoading} colorScheme="gray" onClick={onClose}>
              Close
            </Button>
            <Button
              isLoading={isLoading}
              loadingText="Saving"
              colorScheme="blue"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ManageStaffEditorModal
