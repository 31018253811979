import { AddIcon } from '@chakra-ui/icons'
import { Box, Flex, IconButton, Tag } from '@chakra-ui/react'
import MaterialIcon from 'components/MaterialIcon'
import { iconsTransformer } from 'pages/AssetTemplates/AssetTemplatesEditor/hooks/useAssetTemplateEditor'
import React, { memo } from 'react'

const DefaultFieldItem = ({
  index,
  field,
  isDisabled,
  onOpenFieldTypeForm,
  hasMainFieldSet
}) => {
  const { name, main, field_type: fieldType } = field

  return (
    <Flex
      p={4}
      borderRadius="md"
      shadow="sm"
      key={index}
      justify="space-between"
      w="100%"
      gap={4}
      _hover={{
        shadow: 'md'
      }}
      borderWidth={1}
      borderColor="gray.200"
    >
      <Flex fontSize="md" align="center" gap={2}>
        {name}
        {fieldType && <MaterialIcon icon={iconsTransformer[fieldType]} />}
      </Flex>
      <Flex gap={2}>
        {main && (
          <Tag size="sm" py={2} colorScheme="green">
            Required
          </Tag>
        )}
        <IconButton
          icon={<AddIcon />}
          colorScheme="blue"
          size="sm"
          isRound
          aria-label={`Add field text`}
          onClick={() => onOpenFieldTypeForm(field)}
          isDisabled={isDisabled || (main && hasMainFieldSet)}
        />
      </Flex>
    </Flex>
  )
}

export default memo(DefaultFieldItem)
