import { omit } from 'lodash'
import React from 'react'
import AssetTypeEditor from '../../../../pages/admin/AssetTypes/AssetTypeEditor'
import BasePage from '../../../../pages/BasePage'
import AdminOlingaAPI from '../../../../services/admin/AdminOlingaAPI'

const AssetTypeEditorPage = ({ id }) => {
  const callbacks = omit(
    {
      assetType: {
        callback: () => AdminOlingaAPI.assetType(id),
        params: {}
      },
      businesses: {
        callback: AdminOlingaAPI.businessesEnumerator,
        params: {}
      }
    },
    [!id || id === 'new' ? 'assetType' : ''].filter((v) => v)
  )
  return (
    <BasePage
      fetchCallback={callbacks}
      id={id}
      fetchWithoutId
      resourceName="data"
      otherParams={{
        id: id === 'new' || !id ? null : id
      }}
    >
      <AssetTypeEditor />
    </BasePage>
  )
}

export default AssetTypeEditorPage
