import { Box, Divider, Heading, Input, Stack } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import FormSubmitActions from '../../../components/FormSubmitActions'
import EditInline from '../../../components/Inputs/EditInline'
import ManageUserForm from '../../../pages/ManageUsers/ManageUserForm'
import ActiveHolder from '../ActiveHolder'
import AssessorEditorBreadcrumb from './AssessorEditorBreadcrumb'
import useAssessorsEditor from './useAssessorsEditor'

const AssessorEditor = ({ data, isLoading }) => {
  const {
    assessor,
    email,
    errors,
    isActive,
    isSubmitting,
    manageAssessorData,
    manageFormRef,
    name,
    onManageUserSubmit,
    register,
    setIsActive,
    handleSequentialSubmit
  } = useAssessorsEditor({ data })

  const getContent = () => {
    return (
      <Stack w="100%">
        <AssessorEditorBreadcrumb assessor={assessor} />
        <form onSubmit={handleSequentialSubmit}>
          <Heading pb={2} size="sm">
            Assessor Details
          </Heading>
          {assessor?.id && (
            <ActiveHolder
              isActive={isActive}
              onSuccess={(active) => setIsActive(active)}
              shouldToggleBoth
              id={assessor?.id}
              label="Set assessor active"
            />
          )}
          <Stack pt={4}>
            <Box w={['100%', '40%']}>
              <EditInline
                placeholder="Assessor Name"
                registerProps={{ ...register('name', { required: true }) }}
                defaultValue={name}
                startWithEditView={!name}
                autoFocus={!name}
                fontSize="md"
              />
            </Box>
            <Box w="50%" pt={4}>
              <Input
                type="email"
                placeholder="Email"
                {...register('email', {
                  required: true
                })}
                isInvalid={!!errors.email}
                isDisabled={isLoading || assessor?.id}
                defaultValue={email}
              />
            </Box>
          </Stack>
        </form>
        <Divider py={4} />
        <Stack w="inherit">
          <Heading size="sm">Assessor Permissions</Heading>
          <ManageUserForm
            data={manageAssessorData}
            ref={manageFormRef}
            isSoloPage={false}
            onExternalSubmit={onManageUserSubmit}
            hasActiveHandler={false}
          />
        </Stack>
        <FormSubmitActions
          margin={4}
          isLoading={isSubmitting}
          cancelRoute="assessors"
        />
      </Stack>
    )
  }

  return getContent()
}

AssessorEditor.propTypes = {
  data: PropTypes.shape({
    assessor: PropTypes.object
  }),
  isLoading: PropTypes.bool
}

export default AssessorEditor
