import { yupResolver } from '@hookform/resolvers/yup'
import { differenceBy, isEmpty } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { setFormErrors } from '../../../../../../helpers'
import { getRouteUrl } from '../../../../../../helpers/admin'
import AdminOlingaAPI from '../../../../../../services/admin/AdminOlingaAPI'
import Notification from '../../../../../../services/Notification'

export const schema = yup
  .object({
    name: yup.string().required('The asset type name is required')
  })
  .required()

const useAssetsEditor = ({ data, id }) => {
  const history = useHistory()

  const [renderNameInput, setRenderNameInput] = useState(!id)

  const { businesses, assetType } = useMemo(() => {
    const {
      businesses: { data: businessesData }
    } = data
    const mappedBusinesses = businessesData.map(({ attributes }) => ({
      ...attributes
    }))
    if (data?.assetType?.data) {
      const {
        assetType: { data: assetType }
      } = data

      if (id) {
        return {
          businesses: mappedBusinesses,
          assetType: {
            id: assetType.id,
            ...(assetType?.attributes ?? {}),
            asset_type_businesses_attributes: (
              assetType?.attributes?.asset_type_businesses ?? []
            )?.map(({ id, business_id }) => ({
              id,
              ...(mappedBusinesses.find(({ value }) => value === business_id) ??
                {})
            }))
          }
        }
      }
    }
    return {
      assetType: null,
      businesses: mappedBusinesses
    }
  }, [data, id])

  const destroyedBusinessesRef = useRef([])

  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      active: true,
      asset_type_businesses_attributes: []
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (assetType?.id) {
      reset(assetType)

      setRenderNameInput(true)
    }

    return () => {
      setIsLoading(false)
    }
  }, [reset, assetType])

  const onSubmit = async (data) => {
    if (isEmpty(data.asset_type_businesses_attributes)) {
      return Notification({
        icon: 'error',
        text: 'At least a business is required'
      })
    }
    try {
      setIsLoading(true)

      const allBusinesses = [
        ...data.asset_type_businesses_attributes,
        ...destroyedBusinessesRef.current
      ].map(({ value, id, _destroy }) => ({
        id,
        business_id: value,
        _destroy: !!_destroy
      }))

      const endpoint = id ? 'updateAssetType' : 'newAssetType'
      const response = await AdminOlingaAPI?.[endpoint](id, {
        ...data,
        asset_type_businesses_attributes: allBusinesses
      })

      if (response?.status === 200) {
        Notification({
          icon: 'success',
          text: response.data?.message
        })
        history.push(getRouteUrl('assetTypes'))
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setFormErrors(error, setFormErrors, Notification)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const onError = (errors) => {
    Object.entries(errors).forEach(([name, error]) => setError(name, error))
  }

  const submitForm = handleSubmit(onSubmit, onError)

  const [name, assetTypesBusinesses] = watch(
    ['name', 'asset_type_businesses_attributes'],
    {
      name: '',
      asset_type_businesses_attributes: []
    }
  )

  const onSetBusinesses = (value) => {
    const businessesThatWillBeDestroyed = destroyedBusinessesRef.current.map(
      ({ value }) => value
    )
    const businessesToBeDestroyed = differenceBy(
      assetTypesBusinesses,
      value,
      'value'
    )?.filter(({ id }) => !!id)
    const valueToBeSet = [...value]
    const persistedBusinessIndexThatWasNotDestroyedYet = value.findIndex(
      ({ value }) => businessesThatWillBeDestroyed.includes(value)
    )
    if (!isEmpty(businessesToBeDestroyed)) {
      const [business] = businessesToBeDestroyed
      destroyedBusinessesRef.current.push({ ...business, _destroy: true })
    } else if (persistedBusinessIndexThatWasNotDestroyedYet >= 0) {
      const refIndex = destroyedBusinessesRef.current.findIndex(
        ({ value: v }) =>
          v === value[persistedBusinessIndexThatWasNotDestroyedYet].value
      )
      const [business] = destroyedBusinessesRef.current.splice(refIndex, 1)
      valueToBeSet.splice(
        persistedBusinessIndexThatWasNotDestroyedYet,
        1,
        business
      )
    }
    setValue('asset_type_businesses_attributes', valueToBeSet)
  }

  return {
    businesses,
    assetType,
    submitForm,
    isLoading,
    register,
    setValue,
    watch,
    errors,
    renderNameInput,
    name,
    assetTypesBusinesses,
    onSetBusinesses
  }
}

export default useAssetsEditor
