import { IconButton } from '@chakra-ui/react'
import MaterialIcon from 'components/MaterialIcon'
import React from 'react'

const SettingsButton = ({ onOpenSettings }) => {
  return (
    <IconButton
      aria-label="Open settings drawer"
      colorScheme="gray"
      onClick={onOpenSettings}
      icon={<MaterialIcon icon='settings' />}
    />
  )
}

export default SettingsButton
