import { Stack } from '@chakra-ui/react'
import FormField from 'components/FormField'
import ListBuilder from 'components/ListBuilder'
import React from 'react'
import AdditionalFieldForm from './AdditionalFieldForm'

const AdditionalFieldsList = ({
  value,
  register,
  watch,
  setValue,
  fieldKey
}) => {
  const handleListChange = (changedList) => {
    setValue(fieldKey, changedList)
  }

  return (
    <Stack>
      <FormField label="Additional Fields" isRequired={false}>
        <ListBuilder
          allowDrag={() => true}
          ItemComponent={AdditionalFieldForm}
          list={value ?? []}
          idAttribute="_id"
          itemProps={{ fieldKey, register, watch }}
          customRemoveItemAction={(index, _, fallback) => fallback(index)}
          resourceName="Additional Field"
          onChange={(updatedList) => handleListChange(updatedList)}
        />
      </FormField>
    </Stack>
  )
}

export default AdditionalFieldsList
