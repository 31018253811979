import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { DragHandleIcon } from '@chakra-ui/icons'
import { Draggable } from 'react-beautiful-dnd'
import {
  Box,
  Flex,
  Input,
  CloseButton,
  Tooltip,
  FormControl,
  Switch,
  FormLabel
} from '@chakra-ui/react'
import { DraggableContainer } from './styles'
import { get } from 'lodash'

const Option = ({
  control,
  droppableType,
  index,
  optionKey,
  handleRemoveOption,
  questionKey,
  isActive,
  hasActivationController = true
}) => {
  const { getValues, setValue } = useFormContext()
  const [value, id, _id, logics] = useWatch({
    name: [
      `${optionKey}.label`,
      `${optionKey}.id`,
      `${optionKey}._id`,
      `${questionKey}.questions`
    ],
    control,
    defaultValue: [
      getValues(optionKey)?.label ?? '',
      getValues(optionKey)?.id,
      getValues(optionKey)?._id,
      getValues(questionKey)?.questions || []
    ]
  })

  const handleSetOptionValue = ({ target: { value } }) => {
    const optionId = id || _id
    setValue(`${optionKey}.label`, value)
    logics.forEach((logic, index) => {
      const selectedOptionId = get(logic, '_selectedOptionId')
      if (selectedOptionId === optionId) {
        setValue(`${questionKey}.questions.${index}.value`, value)
      }
    })
  }

  return (
    <Draggable draggableId={optionKey} index={index} type={droppableType}>
      {(provided) => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          <Box pb={[2, 2, 4]}>
            <Flex align="center" w="100%">
              <DraggableContainer {...provided.dragHandleProps}>
                <DragHandleIcon />
              </DraggableContainer>
              <Input ml="3" value={value} onChange={handleSetOptionValue} />
              {hasActivationController ? (
                <FormControl as={Flex} gap={[2, 2, 4]} justify="flex-end">
                  <FormLabel
                    mb="0"
                    cursor="pointer"
                    htmlFor={`active-${optionKey}`}
                  >
                    Active:{' '}
                  </FormLabel>
                  <Switch
                    id={`active-${optionKey}`}
                    isChecked={isActive}
                    onChange={({ target: { checked } }) =>
                      setValue(`${optionKey}.active`, checked)
                    }
                    mb={0}
                  />
                </FormControl>
              ) : (
                <Tooltip label="Remove option">
                  <CloseButton
                    size="md"
                    onClick={() => handleRemoveOption(index)}
                  />
                </Tooltip>
              )}
            </Flex>
          </Box>
        </div>
      )}
    </Draggable>
  )
}

export default Option
