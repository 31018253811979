import React from 'react';
import AdminOlingaAPI from '../../../../services/admin/AdminOlingaAPI';
import BasePage from '../../../BasePage';
import FormContent from './FormContent';

const BusinessForm = ({ id }) => {
  const callbacks = id ? {
    business: { callback: AdminOlingaAPI.getBusiness, params: {} },
  } : {};

  return (
    <BasePage
      id={id}
      resourceName="data"
      fetchCallback={callbacks}
      fetchWithoutId
      otherParams={{
        hasActiveHandler: true
      }}
    >
      <FormContent />
    </BasePage>
  )
}

export default BusinessForm
