import { Box, Flex, FormControl, FormLabel, Stack } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import React from 'react'
import DatePckr from '../../../components/DatePicker'
import PageHeader from '../../../components/PageHeader'
import SearchInput from '../../../components/SearchInput'
import Table from '../../../components/Table'
import { DatePickerContainer } from '../../Form/styles'
import BreadcrumbChecklist from '../PageHeader/Breadcrumb'
import ExportChecklists from './ExportChecklists'
import useChecklistsTasks from './useChecklistsTasks'

const ChecklistsTasks = ({ data }) => {
  const {
    onChangePage,
    onFrequencyChange,
    onTextChange,
    onSearchRangeChange,
    isMobile,
    name,
    frequencies,
    isLoading,
    fetchParams,
    tableData,
    pagination,
    columns,
    hiddenColumns,
    id,
    tableKey,
    singularTitle,
    pageTitle
  } = useChecklistsTasks(data)

  return (
    <Stack w="100%">
      <PageHeader
        title={`${name} tasks`}
        Breadcrumb={
          <BreadcrumbChecklist
            id={id}
            readOnly
            pageTitle={pageTitle}
            singularTitle={singularTitle}
          />
        }
        Actions={
          <Flex flexDir={['column', 'row']} gap={4} align="center">
            <FormControl minW="200px">
              <FormLabel>Frequency</FormLabel>
              <Select
                placeholder="Tasks"
                value={fetchParams?.frequency}
                onChange={onFrequencyChange}
                options={frequencies ?? []}
              />
            </FormControl>
            <FormControl mt="auto">
              <SearchInput
                value={fetchParams?.name}
                setValue={onTextChange}
                isLoading={isLoading}
                debounceTimeout={25}
                placeholder="Filter by task or staff name"
              />
            </FormControl>
            <Box mt="auto">
              <ExportChecklists
                id={id}
                name={'TaskTracker'}
                size="md"
                params={fetchParams}
                ariaLabel="Export all"
              />
            </Box>
          </Flex>
        }
      />
      <DatePickerContainer ismobile={isMobile}>
        <DatePckr
          isRangePicker
          onChange={onSearchRangeChange}
          showTimeSelect={false}
          minW="380px"
          keepDateOnClear
          monthsShown={2}
          placeholder="Search by date range"
          leftDistance="-180px"
        />
      </DatePickerContainer>
      <Table
        shouldResetPage={fetchParams?.page === 1}
        columns={columns}
        data={tableData}
        isLoading={isLoading}
        pageCount={pagination?.last}
        totalItems={pagination?.items}
        fetchPage={onChangePage}
        initiallyHiddenColumns={hiddenColumns}
        persistedColumnsKey={tableKey}
      />
    </Stack>
  )
}

export default ChecklistsTasks
