import { Stack } from '@chakra-ui/react'
import React from 'react'
import Action from './Action'
import InnerContent from './InnerContent'

const Content = ({
  localIndex,
  index,
  field,
  currentKey,
  key,
  sectionRepeatAttributes,
  template
}) => {
  return (
    <Stack key={key}>
      <InnerContent
        localIndex={localIndex}
        field={field}
        currentKey={currentKey}
        key={key}
        sectionRepeatAttributes={sectionRepeatAttributes}
        template={template}
        index={index}
      />
      <Action
        template={template}
        index={index}
        field={field}
        currentKey={currentKey}
      />
    </Stack>
  )
}

export default Content
