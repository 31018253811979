import { Tag } from '@chakra-ui/react'
import { getRouteUrl } from 'helpers'
import { isEmpty, pick, trim } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  usePageProperties,
  useQuery,
  useTableColumns,
  useTableFilter
} from '../../../hooks'
import OlingaAPI from '../../../services/OlingaAPI'
import AssessorsActions from '../AssessorsActions'

const hiddenColumnsKey = 'form_answers_persisted_columns.assessors'

const useAssessors = () => {
  const { pageTitle, shouldCreate: hasPermissionsToCreateAssessors } =
    usePageProperties({ pageName: 'manage_assessors' })

  const history = useHistory()

  const query = useQuery()

  const fieldsExtractor = useCallback((data) => {
    if (!isEmpty(data)) {
      return data.map(({ attributes: { name, email, id, active } }) => ({
        name,
        email,
        id,
        active: (
          <Tag colorScheme={active ? 'green' : 'gray'} size="md">
            {active ? 'Active' : 'Inactive'}
          </Tag>
        )
      }))
    }

    return []
  }, [])

  const initialParams = useMemo(() => {
    return isEmpty(query)
      ? {
          page: 1,
          per_page: 15
        }
      : pick(query, ['page', 'per_page', 'email'])
  }, [query])

  const {
    refetch,
    fetchParams,
    tableData,
    pagination,
    isLoading,
    updateParams
  } = useTableFilter({
    id: null,
    fetchCallback: OlingaAPI.assessorsList,
    fieldsExtractor,
    initialParams,
    onRefetch: useCallback(
      (newParams) => {
        history.replace(getRouteUrl('assessors', newParams))
      },
      [history]
    )
  })

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Active',
        accessor: 'active'
      }
    ],
    hiddenColumnsKey,
    hasPermissionsToCreateAssessors,
    AssessorsActions,
    (row) => {
      const {
        values: { id, active }
      } = row
      const isActive = active.props.children === 'Active'
      return {
        id,
        isActive,
        fetchAssessors: refetch,
        currentPage: fetchParams.page
      }
    }
  )

  const onTextChange = useCallback(
    (updatedText) => {
      if (!updatedText || !trim(updatedText)?.length) {
        return refetch({ per_page: 15, page: 1 })
      }
      updateParams({ email: updatedText }, updatedText?.length >= 3)
    },
    [refetch, updateParams]
  )

  return {
    isLoading,
    data: tableData,
    pagination,
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    refetch,
    fetchParams,
    pageTitle,
    onTextChange
  }
}

export default useAssessors
