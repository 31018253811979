import { EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Text,
  theme,
  Tooltip
} from '@chakra-ui/react'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import DeleteButton from '../../../../../components/DeleteButton'
import MaterialIcon from '../../../../../components/MaterialIcon'

const NotesCard = ({ notes, icon, onClick }) => {
  const { setValue } = useFormContext()
  const onDelete = () => {
    setValue(`${notes.currentKey}.notes`, null)
  }
  return (
    <>
      <Box
        _hover={{ backgroundColor: theme.colors.gray[100] }}
        cursor="pointer"
        transition="hover .2s ease"
        onClick={onClick}
        shadow="sm"
        px={2}
        py={3}
        borderRadius={4}
        border={`1px solid ${theme.colors.gray[400]}`}
        role="button"
        aria-label={`Action from ${notes.currentKey}`}
      >
        <Flex align="center" justify="space-between">
          <Flex gap={2} align="center">
            <MaterialIcon icon={icon} />
            <Tooltip
              hasArrow
              label={notes.title.length > 110 ? notes.title : ''}
            >
              <Text fontSize="sm" fontWeight="bold" className="clamp">
                {notes.title}
              </Text>
            </Tooltip>
          </Flex>
          <HStack spacing={2}>
            <IconButton
              icon={<EditIcon />}
              aria-label="Edit notes"
              size="xs"
              colorScheme="blue"
              borderRadius={2}
              sx={{ justifyContent: 'center!important' }}
            />
            <DeleteButton
              onDelete={onDelete}
              alertTitle="Delete notes"
              alertBody="This action cannot be undone."
              continueLabel="Yes, remove it!"
            />
          </HStack>
        </Flex>
      </Box>
    </>
  )
}

NotesCard.defaultProps = {
  icon: 'edit_note'
}

export default NotesCard
