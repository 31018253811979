import { isEmpty } from 'lodash'
import Component from 'pages/Form/FillForm/Component'
import Logic from 'pages/Form/FillForm/Logic'
import Section from 'pages/Form/FillForm/Section'
import React from 'react'

const InnerContent = ({
  localIndex,
  field,
  currentKey,
  key,
  sectionRepeatAttributes,
  index,
  template
}) => {
  if (field.type === 'section') {
    return (
      <Section
        localIndex={localIndex}
        key={key}
        field={field}
        sectionKey={currentKey}
        sectionRepeatAttributes={sectionRepeatAttributes}
        template={template}
        index={index}
      />
    )
  }

  if (field.type === 'question' && !isEmpty(field.children)) {
    return (
      <Logic
        localIndex={localIndex}
        key={key}
        field={field}
        logicKey={currentKey}
        sectionRepeatAttributes={sectionRepeatAttributes}
        template={template}
        index={index}
      />
    )
  }

  return <Component key={key} field={field} currentKey={currentKey} />
}

export default InnerContent
