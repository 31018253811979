import { Box, Flex, SimpleGrid, Skeleton, Stack } from '@chakra-ui/react'
import React from 'react'

const LoadingTreeSkeleton = () => (
  <Box minH="100vh">
    <Stack>
      <SimpleGrid columns={{ sm: 1, md: 2 }}>
        <Skeleton h="40px" w="200px" />
        <Flex justify="flex-end">
          <Skeleton h="40px" w="300px" />
        </Flex>
      </SimpleGrid>
      <Flex justify="flex-end">
        <Skeleton w="325px" h="40px" />
      </Flex>
      {Array.from(Array(20)).map((_, index) => {
        return <Skeleton key={index} h="64px" w="100%" />
      })}
    </Stack>
  </Box>
)

export default LoadingTreeSkeleton
