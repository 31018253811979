import { useTemplateEditorContext } from 'contexts'
import { useEffect, useState } from 'react'
import { useGlobalStateContext } from '../../../../../contexts/GlobalStateContext'
import { onRefreshForm } from '../../../../../contexts/GlobalStateContext/events'
import { actions as FormActions } from '../../../../../pages/TemplateEditor/FormActions/actions'
import Notification from '../../../../../services/Notification'
import OlingaAPI from '../../../../../services/OlingaAPI'
import { actionTypes, getSelectedContentValue } from './helpers'

const { UNFOCUS } = FormActions

const useDeleteFormElement = ({ type, getValues, handleClose, context }) => {
  const [shouldRemoveAllChildren, setShouldRemoveAllChildren] = useState(false)
  const [logicRemoveDecision, setLogicRemoveDecision] = useState('remove')
  const [isLoading, setIsLoading] = useState(false)

  const { template } = useTemplateEditorContext()

  const { global$ } = useGlobalStateContext()

  const onClose = (closeDialog) => {
    if (!isLoading) {
      handleClose(closeDialog)
    }
  }

  const refreshForm = () => {
    global$.next(onRefreshForm())
  }

  const handleDelete = async (closeDialog) => {
    const { focusedField } = context.current
    const focusedElement = getValues(focusedField)
    const { _id: elementId } = focusedElement
    const actionType = actionTypes[type]
    setIsLoading(true)
    try {
      const response = await OlingaAPI.handleDeleteEditTemplateElement({
        type: actionType,
        templateId: template.id,
        elementId,
        selectedContentValue: getSelectedContentValue(actionType, {
          [actionTypes.section]: !shouldRemoveAllChildren,
          [actionTypes.logic]: logicRemoveDecision
        })
      })
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: response.data.message
        })
        global$.next({
          type: UNFOCUS
        })
        refreshForm()
        handleClose(closeDialog)
      }
    } catch (err) {
      handleClose(closeDialog)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    return () => {
      setIsLoading(false)
      setShouldRemoveAllChildren(false)
    }
  }, [])

  return {
    handleDelete,
    isLoading,
    shouldRemoveAllChildren,
    setShouldRemoveAllChildren,
    logicRemoveDecision,
    setLogicRemoveDecision,
    onClose
  }
}

export default useDeleteFormElement
