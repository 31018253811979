import { ChevronDownIcon } from '@chakra-ui/icons'
import { Box, Skeleton, Stack, useMediaQuery } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { differenceBy, get, intersectionBy, isEmpty, isUndefined } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import Breadcrumb from '../../../components/Breadcrumb'
import FormErrorMessage from '../../../components/FormErrorMessage'
import FormField from '../../../components/FormField'
import FormSubmitActions from '../../../components/FormSubmitActions'
import EditInline from '../../../components/Inputs/EditInline'
import LazyComponent from '../../../components/LazyComponent'
import PageHeader from '../../../components/PageHeader'
import TransferList, {
  getTransferListSkeleton
} from '../../../components/TransferList'
import { getPrefixes, getRouteUrl, setFormErrors } from '../../../helpers'
import TemplatesModal from '../../../pages/FormFolder/TemplatesModal'
import Notification from '../../../services/Notification'
import OlingaAPI from '../../../services/OlingaAPI'
import FormFolderKebab from '../FormFolderKebab'

const schema = yup
  .object({
    name: yup.string().required('The name is required')
  })
  .required()

const FormFolderEditor = ({ data, isLoading }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [formFolder, groups] = useMemo(() => {
    if (data) {
      const { formFolder, groups } = data
      return [formFolder || {}, groups || []]
    }
    return [{}, []]
  }, [data])

  const history = useHistory()

  const [isMobile] = useMediaQuery('(max-width: 799px)')

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: null,
    resolver: yupResolver(schema)
  })

  const watchName = watch('name', getValues('name'))

  useEffect(() => {
    if (formFolder?.attributes) {
      const { name, groupIds } = formFolder.attributes
      reset({ name, group_ids: groupIds })
    }
  }, [data, formFolder?.attributes, reset])

  const selectedUsers = useMemo(() => {
    const { formFolder } = data
    if (formFolder?.attributes) {
      const { groupIds } = formFolder.attributes
      return intersectionBy(
        groups,
        groupIds.map((id) => ({ value: id })),
        'value'
      ).map((group) => ({ ...group, id: get(group, 'value') }))
    }
    return []
  }, [data, groups])

  const unselectedUsers = useMemo(() => {
    if (formFolder?.attributes) {
      const { groupIds } = formFolder.attributes
      return differenceBy(
        groups,
        groupIds.map((id) => ({ value: id })),
        'value'
      ).map((group) => ({ ...group, id: get(group, 'value') }))
    }
    return groups.map((group) => ({ ...group, id: get(group, 'value') }))
  }, [formFolder.attributes, groups])

  const onSubmit = async (data) => {
    const formData = { form_folder: data }
    setIsSubmitting(true)
    try {
      const endpoint = formFolder?.id
        ? () => OlingaAPI.formFolderUpdate(formFolder.id, formData)
        : () => OlingaAPI.formFolderCreate(formData)
      const response = await endpoint()
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: !formFolder.id ? 'Folder created!' : 'Folder updated!'
        })
        history.push(getRouteUrl('folders'))
      }
    } catch (error) {
      setFormErrors(error, setError, Notification)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleTransferChange = useCallback(
    (list) => {
      const ids = list.map(({ id }) => id)
      setValue('group_ids', ids)
    },
    [setValue]
  )

  const getTransferList = useCallback(() => {
    if (isLoading) {
      return getTransferListSkeleton()
    }
    return (
      <FormField label="Groups">
        <LazyComponent
          condition={!isEmpty(unselectedUsers) || !isEmpty(selectedUsers)}
        >
          <TransferList
            leftList={unselectedUsers}
            rightList={selectedUsers}
            onChange={(_, rightList) => handleTransferChange(rightList)}
            isLoading={isLoading}
            itemAttribute="label"
          />
        </LazyComponent>
      </FormField>
    )
  }, [handleTransferChange, isLoading, selectedUsers, unselectedUsers])

  const getBreadcrumb = () => {
    const options = [
      {
        title: 'Folders',
        url: getRouteUrl('folders')
      }
    ]
    if (formFolder?.attributes) {
      options.push(
        {
          title: formFolder?.attributes?.name,
          options: (
            <FormFolderKebab
              id={formFolder.attributes.id}
              size="xs"
              hasBorder={false}
              icon={<ChevronDownIcon />}
            />
          )
        },
        {
          title: 'Edit Folder',
          route: null
        }
      )
    } else {
      options.push({
        title: 'New Folder',
        url: null
      })
    }
    return <Breadcrumb routeTree={options} />
  }

  const getEditInline = () => {
    if (formFolder?.id && isUndefined(watchName)) {
      return <Skeleton w="90%" h="24px" />
    }
    return (
      <Box w={isMobile ? '100%' : '40%'}>
        <Box>
          <FormField label="Folder Name">
            <EditInline
              placeholder="Folder Name"
              registerProps={{ ...register('name', { required: true }) }}
              defaultValue={watchName}
              startWithEditView={!watchName}
              autoFocus={!watchName}
              fontSize="md"
              isInvalid={!!errors?.name}
            />
          </FormField>
          <FormErrorMessage message={errors?.name?.message} />
        </Box>
      </Box>
    )
  }

  const getActions = () => {
    if (formFolder?.attributes) {
      const { name, totalTemplates, id } = formFolder.attributes
      const { before: templatesNoun, after: templatesSubstantive } =
        getPrefixes(totalTemplates, 'template')
      return (
        <Stack direction={isMobile ? 'column' : 'row'}>
          {totalTemplates > 0 && (
            <TemplatesModal
              folderName={name}
              count={totalTemplates}
              id={id}
              customButtonLabel={`See ${templatesNoun} ${
                totalTemplates === 1 ? 'only' : totalTemplates
              } ${templatesSubstantive} of this folder`}
            />
          )}
        </Stack>
      )
    }
    return null
  }

  const getContent = () => {
    return (
      <Stack>
        <PageHeader
          title={formFolder?.id ? 'Edit folder' : 'New Folder'}
          Breadcrumb={getBreadcrumb()}
          Actions={getActions()}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            {getEditInline()}
            {getTransferList()}
          </Stack>
          <FormSubmitActions
            margin={4}
            isLoading={isSubmitting}
            cancelRoute="folders"
          />
        </form>
      </Stack>
    )
  }

  return getContent()
}

export default FormFolderEditor
