import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { IconButton } from '@chakra-ui/react'
import React, { useMemo } from 'react'

const horizontalOffsetLeft = '20px'
const horizontalOffsetRight = '35px'

const Arrow = ({ onClick, ariaLabel, isDisabled, direction }) => {
  const Icon = useMemo(() => {
    if (direction === 'left') {
      return <ChevronLeftIcon />
    } else if (direction === 'right') {
      return <ChevronRightIcon />
    }
    return null
  }, [direction])

  return (
    <IconButton
      onClick={onClick}
      aria-label={ariaLabel}
      colorScheme="orange"
      isRound
      size="lg"
      shadow="md"
      icon={Icon}
      isDisabled={isDisabled}
      position="fixed"
      left={direction === 'left' ? horizontalOffsetLeft : 'unset'}
      right={direction === 'right' ? horizontalOffsetRight : 'unset'}
      top="50%"
      transform="translateY(-50%)"
    />
  )
}

Arrow.defaultProps = {
  onClick: () => ({})
}

export default Arrow
