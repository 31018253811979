import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import HomePage from '..'
import store from '../../../store'

const HomeProvider = (props) => {
  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        <ChakraProvider>
          <HomePage {...props} />
        </ChakraProvider>
      </BrowserRouter>
    </Provider>
  )
}

export default HomeProvider
