import React, { useMemo } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import { getRouteUrl } from '../../../../helpers'

const ProfileBreadcrumb = ({ profile, pageTitle, singularTitle }) => {
  const options = useMemo(() => {
    const opts = [
      {
        title: pageTitle,
        url: getRouteUrl('profiles')
      }
    ]
    if (profile?.attributes) {
      opts.push(
        {
          title: profile?.attributes?.name,
          route: null
        },
        {
          title: `Edit ${singularTitle}`,
          route: null
        }
      )
    } else {
      opts.push({
        title: `New ${singularTitle}`,
        url: null
      })
    }
    return opts
  }, [pageTitle, profile?.attributes, singularTitle])

  return <Breadcrumb routeTree={options} />
}

export default ProfileBreadcrumb
