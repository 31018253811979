import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch
} from '@chakra-ui/react'
import ToggleValueModalBody from 'components/ToggleValueModal/ToggleValueModalBody'
import UsersSelector from 'pages/Form/FillForm/ActionsBar/ActionSidebar/UsersSelector'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import OlingaAPI from 'services/OlingaAPI'
import useNotificationsModal from './hooks/useNotificationsModal'

const options = [
  {
    label: '1 day',
    value: '1',
    icon: null
  },
  {
    label: '7 days',
    value: '7',
    icon: null
  },
  {
    label: '14 days',
    value: '14',
    icon: null
  },
  {
    label: '21 days',
    value: '21',
    icon: null
  },
  {
    label: '30 days',
    value: '30',
    icon: null
  }
]

const NotificationsModal = ({ isOpen, onClose, attributeKey, onEditField }) => {
  const {
    notifiedUsers,
    onChangeUsers,
    notifyDays,
    notify,
    onChangeNotifyDays,
    isLoading,
    onSave
  } = useNotificationsModal({ attributeKey, onClose, onEditField })

  const { register, setValue } = useFormContext()

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justify="space-between" gap={4} mr="8">
              <Heading size="md">Date type Notifications</Heading>
              <Switch
                {...register(`${attributeKey}.notify`)}
                mb="0!important"
                minW="100px"
                colorScheme="green"
                onChange={({ target: { checked } }) =>
                  setValue(`${attributeKey}.notify`, checked)
                }
                isChecked={notify}
                fontSize="md"
              >
                Notify
              </Switch>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack gap={4}>
              <UsersSelector
                defaultUsers={notifiedUsers}
                onSelect={onChangeUsers}
                closeMenuOnSelect
                fetchCallback={OlingaAPI.businessSubUsersList}
                requestParams={[]}
              />
              <FormControl>
                <FormLabel>
                  How long before the date, the notification should be triggered
                </FormLabel>
                <ToggleValueModalBody
                  options={options}
                  value={notifyDays}
                  onChange={onChangeNotifyDays}
                  attributeKey="value"
                  colorScheme="blue"
                  columns={[2, 2, 5]}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter display="flex" gap={4}>
            <Button colorScheme="gray" onClick={onClose}>
              Close
            </Button>
            <Button isLoading={isLoading} onClick={onSave} colorScheme="green">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default NotificationsModal
