import { Flex, Input, Stack, useMediaQuery } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Breadcrumb from '../../components/Breadcrumb'
import FormSubmitActions from '../../components/FormSubmitActions'
import PageHeader from '../../components/PageHeader'
import { getRouteUrl } from '../../helpers'
import OlingaAPI from '../../services/OlingaAPI'

const LibraryEditor = ({ data }) => {
  const [isMobile] = useMediaQuery('(max-width: 799px)')
  const library = useMemo(() => {
    return data?.attributes || {}
  }, [data])
  const endpointActionName = useMemo(() => {
    return library?.id ? 'editLibrary' : 'createLibrary'
  }, [library])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: ''
    }
  })

  useEffect(() => {
    if (library?.id) {
      reset({
        name: library?.name
      })
    }
  }, [library, reset])

  const history = useHistory()

  const onSubmit = async (data) => {
    setIsSubmitting(true)
    try {
      const ids = [library?.id].filter((id) => !!id)
      const result = await OlingaAPI[endpointActionName](...ids, {
        library: {
          ...data
        }
      })
      if (result.status === 200) {
        Notification({
          icon: 'success',
          text: result.data.message
        })
      }
      history.push(getRouteUrl('libraries'))
    } catch (error) {
      //
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Stack>
      <Breadcrumb
        routeTree={[
          {
            title: 'Libraries List',
            url: getRouteUrl('libraries')
          },
          {
            title: 'New library',
            url: null
          }
        ]}
      />
      <PageHeader title={library?.id ? 'Edit Library' : 'Create Library'} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          direction={isMobile ? 'column' : 'row'}
          w="100%"
          align="center"
          mb={4}
        >
          <Input
            {...register('name', { required: true })}
            placeholder="Library name"
            w="100%"
          />
        </Flex>
        <FormSubmitActions isLoading={isSubmitting} cancelRoute="libraries" />
      </form>
    </Stack>
  )
}

LibraryEditor.defaultProps = {
  data: {}
}

LibraryEditor.propTypes = {
  data: PropTypes.shape({
    formFolder: PropTypes.object,
    document: PropTypes.object
  })
}

export default LibraryEditor
