import { formatDistanceToNow } from 'date-fns';
import { useEffect, useState } from 'react';

export function useNotificationCard({ notification }) {
  const {
    createdAt,
    data,
    readOn,
    id,
    notificationType,
  } = notification;

  const formattedDate = () => {
    setDateFromNow(formatDistanceToNow(new Date(createdAt)));
  };
  
  const [dateFromNow, setDateFromNow] = useState(formatDistanceToNow(new Date(createdAt)));

  useEffect(() => {
    const updateDate = setInterval(() => formattedDate(createdAt), 1000);

    return () => clearInterval(updateDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { data, readOn, id, notificationType, dateFromNow };
}
