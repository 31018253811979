import { Button, useDisclosure } from '@chakra-ui/react'
import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useFormContext } from 'react-hook-form'
import Sidebar from '../../../../components/Sidebar'
import Notification from '../../../../services/Notification'
import { defaultContextValue } from '../helpers'
import ReportEditorForm from './ReportEditorForm'

const submitLabels = {
  0: 'Continue',
  1: 'Finish'
}

const triggerError = (text) => {
  Notification({
    icon: 'error',
    text
  })
  return false
}

const ReportEditorSidebar = forwardRef(
  (
    {
      reportType,
      contexts,
      counterType,
      headerQuestions,
      bodyQuestions,
      formFieldKey,
      onFormFieldKeyClean,
      structure
    },
    ref
  ) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [tabIndex, setTabIndex] = React.useState(0)
    const { getValues, setValue, watch } = useFormContext()

    const onSidebarClose = () => {
      onFormFieldKeyClean()
      onClose()
    }

    const [relatedQuestions, question] = watch(
      [
        `${formFieldKey.key}.related_questions`,
        `template_questions.${formFieldKey.index}`
      ],
      [[], {}]
    )

    const handleTemplateStepSubmission = () => {
      const { chart_type, has_table, main_question, context } = getValues(
        `${formFieldKey.key}`
      )
      if (context?.value === defaultContextValue.value && !main_question) {
        return triggerError(`You have to choose a main question`)
      }
      if (!has_table && !chart_type) {
        return triggerError(`The question must have a chart type`)
      }
      const returnedReport = {
        ...question,
        main_question,
        has_table: context?.value === defaultContextValue?.value,
        chart_type,
        _key: formFieldKey.key,
        related_questions: relatedQuestions
      }
      setValue(`template_questions.${formFieldKey.index}`, returnedReport)
      return !!returnedReport
    }

    const handleRelatedQuestionsSubmission = () => {
      if (!relatedQuestions?.length) {
        return triggerError(
          `A main question should have at least one related question`
        )
      }
      if (relatedQuestions.some(({ question_id }) => !question_id)) {
        return triggerError(
          `You have to select a question for a related question`
        )
      }
      if (
        relatedQuestions.some(
          ({ counter_type, question_type }) =>
            question_type === 'counter' && !counter_type
        )
      ) {
        return triggerError(
          `Related questions of type 'counter' should have a defined counter type`
        )
      }
      return true
    }

    const onSubmit = () => {
      switch (tabIndex) {
        case 0:
          if (handleTemplateStepSubmission()) {
            setTabIndex(1)
          }
          break
        case 1:
          if (handleRelatedQuestionsSubmission()) {
            onSidebarClose()
          }
          break
        default:
          onSidebarClose()
      }
    }

    useImperativeHandle(ref, () => ({
      open: onOpen,
      close: onSidebarClose
    }))

    useEffect(() => {
      return () => {
        setTabIndex(0)
      }
    }, [isOpen])

    return (
      <>
        <Sidebar
          isOpen={isOpen}
          onClose={onSidebarClose}
          onOpen={onOpen}
          size="lg"
          header="Add report item"
          body={
            <ReportEditorForm
              contexts={contexts}
              reportType={reportType}
              counterType={counterType}
              headerQuestions={headerQuestions}
              bodyQuestions={bodyQuestions}
              setTabIndex={setTabIndex}
              tabIndex={tabIndex}
              formFieldKey={formFieldKey.key}
              structure={structure}
            />
          }
          footer={
            <Button onClick={onSubmit} colorScheme="blue">
              {submitLabels[tabIndex]}
            </Button>
          }
        />
      </>
    )
  }
)

ReportEditorSidebar.displayName = 'ReportEditorSidebar'

export default ReportEditorSidebar
