import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import useCookie from 'react-use-cookie'
import { getRouteUrl } from '../../../../../helpers/admin'
import { useLocalStorage } from '../../../../../hooks'
import Notification from '../../../../../services/Notification'
import AdminOlingaAPI from '../../../../../services/admin/AdminOlingaAPI'

const defaultPersistedUser = 'null,false'
const defaultCookiesSettings = {
  days: 365,
  SameSite: 'Strict',
  Secure: true
}

const useSignIn = () => {
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [persistedUser, setPersistedUser] = useCookie(
    '_olinga_admin_session_user',
    defaultPersistedUser
  )
  const { setData } = useLocalStorage()

  const { email, rememberMe } = useMemo(() => {
    if (persistedUser !== defaultPersistedUser) {
      const [email, remember] = persistedUser.split(',')
      return { email, rememberMe: remember === 'true' }
    }
    return { email: '', profile: '', rememberMe: false }
  }, [persistedUser])

  const { register, handleSubmit } = useForm({
    defaultValues: {
      remember_me: rememberMe,
      email
    }
  })

  const history = useHistory()

  const onError = (error) => {
    setErrors(error)
  }

  const onSubmit = async (values) => {
    setErrors([])
    setIsLoading(true)
    const params = {
      email: values.email,
      password: values.password,
      remember_me: values.remember_me ? 1 : 0,
      authenticity_token: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
    }

    try {
      await AdminOlingaAPI.signIn(params)
      if (values.remember_me) {
        setPersistedUser(
          `${values.email},${values.remember_me}`,
          defaultCookiesSettings
        )
      } else {
        setPersistedUser(defaultPersistedUser, defaultCookiesSettings)
      }
      setData({}, 'olinga_admin_signed_user')
      history.push({
        pathname: getRouteUrl('businesses')
      })
      window.location.reload()
    } catch (error) {
      return Notification({
        icon: 'error',
        text: error?.response?.data?.message || 'Try again.'
      })
    } finally {
      setIsLoading(false)
    }
  }

  return {
    register,
    isLoading,
    errors,
    handleSubmit,
    onSubmit,
    onError,
    email
  }
}

export default useSignIn
