import { AddIcon, EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack
} from '@chakra-ui/react'
import Dialog from 'components/AlertDialog'
import { capitalize } from 'lodash'
import React from 'react'
import ListBuilder from '../../../../components/ListBuilder'
import { iconsTransformer } from '../hooks/useAssetTemplateEditor'
import DefaultFieldContent from './DefaultFieldContent'
import useAssetTemplateSidebar from './hooks/useAssetTemplateSidebar'
import ItemOption from './ItemOption'
import SidebarContent from './SidebarContent'
import MaterialIcon from '../../../../components/MaterialIcon'

const Sidebar = ({
  navbarHeight,
  fieldTypes = [],
  defaultFields = [],
  isDisabled = false,
  hasMainFieldSet
}) => {
  const {
    fieldType,
    handleSubmit,
    isOpen,
    isDrawerOpen,
    onCloseDrawer,
    onClose,
    onOpenFieldTypeForm,
    onCloseDefaultFieldConfirmation,
    onOpenDefaultFieldConfirmation,
    onConfirmDefaultFieldSelection,
    selectedDefaultField,
    selectedDefaultFieldDialogRef,
    onSubmit,
    setValue,
    register,
    errors,
    onClickSubmitButton,
    isMobile,
    options,
    setFocus,
    focusedFieldIndex,
    onAddFieldOption,
    onChangeFieldOptionsList,
    isEdit,
    defaultFieldId
  } = useAssetTemplateSidebar()

  return (
    <>
      <Dialog
        ref={selectedDefaultFieldDialogRef}
        continueColor="green"
        continueLabel="Add field"
        onContinue={onConfirmDefaultFieldSelection}
        handleClose={onCloseDefaultFieldConfirmation}
        header="Add default field confirmation"
        body={
          selectedDefaultField ? (
            <DefaultFieldContent defaultField={selectedDefaultField} />
          ) : null
        }
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={fieldType === 'list' ? '2xl' : 'lg'}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Field attributes</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack w="100%" as="form" onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  {...register('name', { required: true })}
                  isInvalid={!!errors?.name?.message}
                  isReadOnly={defaultFieldId}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Type</FormLabel>
                {fieldType && (
                  <InputGroup>
                    <InputLeftAddon>
                      <MaterialIcon icon={iconsTransformer[fieldType]} />
                    </InputLeftAddon>
                    <Input
                      id="field_type"
                      isDisabled
                      value={capitalize(fieldType)}
                    />
                  </InputGroup>
                )}
              </FormControl>
              {fieldType === 'list' && (
                <FormControl>
                  <FormLabel>Options</FormLabel>
                  <ListBuilder
                    list={options ?? []}
                    allowDrag={() => true}
                    ItemComponent={ItemOption}
                    onRemoveItem={(values) => {
                      setValue('options', values)
                    }}
                    onChange={onChangeFieldOptionsList}
                    onAddItem={onAddFieldOption}
                    idAttribute="_id"
                    itemProps={{
                      fieldKey: 'settings.list_options',
                      register,
                      setFocus,
                      focusedFieldIndex,
                      isReadOnly: !!defaultFieldId
                    }}
                    customRemoveItemAction={(index, _, fallback) =>
                      fallback(index)
                    }
                    isDisabled={!!defaultFieldId}
                    allowDelete={() => !defaultFieldId}
                  />
                </FormControl>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter display="flex" gap={4}>
            <Button colorScheme="gray" onClick={onClose}>
              Close
            </Button>
            {!defaultFieldId && (
              <Button
                onClick={onClickSubmitButton}
                leftIcon={isEdit ? <EditIcon /> : <AddIcon />}
                colorScheme="green"
              >
                {isEdit ? 'Edit field' : 'Add field'}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isMobile ? (
        <Drawer isOpen={isDrawerOpen} placement="right" onClose={onCloseDrawer}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Template Fields</DrawerHeader>

            <DrawerBody>
              <SidebarContent
                fieldTypes={fieldTypes}
                defaultFields={defaultFields}
                onOpenFieldTypeForm={onOpenFieldTypeForm}
                onOpenDefaultFieldConfirmation={onOpenDefaultFieldConfirmation}
                isDisabled={isDisabled}
                hasMainFieldSet={hasMainFieldSet}
              />
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue">Save</Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      ) : (
        <Box
          w="448px"
          h={`calc(100vh - ${navbarHeight}px)`}
          top={`${navbarHeight}px`}
          right="0px"
          position="fixed"
          shadow="md"
          p={4}
          bg="white"
        >
          <SidebarContent
            defaultFields={defaultFields}
            fieldTypes={fieldTypes}
            onOpenFieldTypeForm={onOpenFieldTypeForm}
            onOpenDefaultFieldConfirmation={onOpenDefaultFieldConfirmation}
            isDisabled={isDisabled}
            hasMainFieldSet={hasMainFieldSet}
          />
        </Box>
      )}
    </>
  )
}

export default Sidebar
