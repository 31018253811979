import { Input } from '@chakra-ui/input'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import Content from '../Form/Content'
import Action from '../Form/Content/Action'
import InnerContent from '../Form/Content/InnerContent'
import useFillFormLogic from './hooks/useFillFormLogic'

const Logic = ({
  field,
  logicKey,
  sectionRepeatAttributes,
  localIndex,
  template,
  index
}) => {
  const { question_type, compulsory, text, template_question_options, id } =
    field
  const { register } = useFormContext()
  const { fieldCurrentKey, fieldKey, visibleChildrens } = useFillFormLogic({
    field,
    logicKey
  })
  return (
    <>
      <Input
        hidden
        {...register(`${fieldCurrentKey}.section_repeats_attributes`, {
          value: JSON.stringify(sectionRepeatAttributes)
        })}
      />
      <Content
        localIndex={localIndex}
        field={{
          question_type,
          compulsory,
          text,
          template_question_options,
          id
        }}
        currentKey={fieldCurrentKey}
        key={fieldKey}
        sectionRepeatAttributes={sectionRepeatAttributes}
        template={template}
        index={index}
      />
      {visibleChildrens.map((children) => {
        if (!field.is_new_section) {
          return (
            <span key={children.id}>
              <Input
                hidden
                {...register(
                  `${children.currentChildrenKey}.section_repeats_attributes`,
                  {
                    value: JSON.stringify(sectionRepeatAttributes)
                  }
                )}
              />
              <Action
                currentKey={fieldCurrentKey}
                template={template}
                index={index}
                field={field}
                isNested
              />
              <InnerContent
                sectionRepeatAttributes={sectionRepeatAttributes}
                template={template}
                index={index}
                key={children.key}
                currentKey={children.currentChildrenKey}
                field={children}
                localIndex={localIndex}
              />
            </span>
          )
        }

        return null
      })}
    </>
  )
}

Logic.defaultProps = {
  sectionRepeatAttributes: []
}

export default Logic
