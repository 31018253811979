import {
  Box,
  Button,
  Checkbox,
  IconButton,
  ListItem,
  Stack,
  Tooltip,
  UnorderedList
} from '@chakra-ui/react'
import { setFormErrors } from 'helpers'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import Modal from '../../../components/Modal'
import TransferList from '../../../components/TransferList'
import Notification from '../../../services/Notification'
import OlingaAPI from '../../../services/OlingaAPI'
import MaterialIcon from '../../../components/MaterialIcon'

const ReportResendButton = ({ id, emails, label, variant, reportType }) => {
  const ref = useRef()
  const [selectedEmails, setSelectedEmails] = useState([])
  const [shouldSendToAllEmails, setShouldSendToAllEmails] = useState(true)
  const leftEmailsList = useMemo(() => {
    return emails.map((email) => ({ email, id: uuid() }))
  }, [emails])
  const [isLoading, setIsLoading] = useState(false)

  const onChangeEmails = (emails) => {
    setSelectedEmails(emails)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    const emailsToResend = shouldSendToAllEmails
      ? []
      : selectedEmails.map(({ email }) => email)
    if (!shouldSendToAllEmails && !emailsToResend?.length) {
      return Notification({
        icon: 'error',
        text: 'You have to select at least a email'
      })
    }
    try {
      const res = await OlingaAPI.resendReportEmails(
        id,
        emailsToResend,
        reportType
      )
      if (res?.message) {
        Notification({
          icon: 'success',
          text: res?.message
        })
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    } finally {
      setIsLoading(false)
      ref.current.close()
    }
  }
  const icon = <MaterialIcon icon='repeat' />
  return (
    <>
      <Modal
        header={`${label} to selected emails`}
        size="lg"
        ref={ref}
        body={
          <Stack>
            <Checkbox
              isChecked={shouldSendToAllEmails}
              onChange={({ target: { checked } }) =>
                setShouldSendToAllEmails(checked)
              }
            >
              Should {label} to all emails
            </Checkbox>
            {shouldSendToAllEmails && (
              <Box px={6}>
                <UnorderedList>
                  {emails.map((email, index) => (
                    <ListItem key={index}>{email}</ListItem>
                  ))}
                </UnorderedList>
              </Box>
            )}
            {!shouldSendToAllEmails && (
              <TransferList
                leftList={leftEmailsList}
                itemAttribute="email"
                onChange={(_, rightList) => onChangeEmails(rightList)}
              />
            )}
          </Stack>
        }
        footer={
          <Button onClick={onSubmit} colorScheme="blue">
            Submit
          </Button>
        }
      >
        <Box aria-label="Open Resend report modal">
          <Tooltip hasArrow label="Resend report">
            {variant === 'icon-only' ? (
              <IconButton
                aria-label={label}
                icon={icon}
                colorScheme="orange"
                isLoading={isLoading}
                size="sm"
                variant="outline"
              />
            ) : (
              <Button
                leftIcon={icon}
                colorScheme="orange"
                isLoading={isLoading}
              >
                {label}
              </Button>
            )}
          </Tooltip>
        </Box>
      </Modal>
    </>
  )
}

ReportResendButton.defaultProps = {
  label: 'Resend'
}

ReportResendButton.propTypes = {
  variant: PropTypes.oneOf(['icon-only', 'default'])
}

export default ReportResendButton
