import axios from 'axios'
import { serialize } from 'object-to-formdata'
import api, { handleErrors, handleNotificationError } from '../api'

const CancelToken = axios.CancelToken
export let cancelSource = CancelToken.source()
export let requestsPending = 0

axios.interceptors.request.use(
  function (config) {
    requestsPending++
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    requestsPending--
    cancelSource = CancelToken.source()
    return response
  },
  function (error) {
    requestsPending--
    cancelSource = CancelToken.source()
    return Promise.reject(error)
  }
)

const getFormDataFromObject = (object, params = {}) =>
  serialize(object, {
    indices: false,
    ...params
  })

const AdminOlingaAPI = {
  signIn: async (params) => {
    const formData = getFormDataFromObject(params)

    try {
      const response = api.post('/sm/login', formData)
      return response
    } catch (error) {
      throw handleErrors(error)
    }
  },
  logout: async () => {
    try {
      const response = api.delete('/sm/logout')
      return response
    } catch (error) {
      throw handleErrors(error)
    }
  },
  businessesList: async (params) => {
    try {
      const response = await api.get('/sm/businesses', { params })
      return response?.data
    } catch (error) {
      throw handleErrors(error)
    }
  },
  getBusiness: async (id) => {
    try {
      const response = await api.get(`/sm/businesses/${id}`)
      return response?.data
    } catch (error) {
      throw handleErrors(error)
    }
  },
  newBusiness: async (_, params) => {
    try {
      const response = await api.post(`/sm/businesses`, {
        business: { ...params }
      })
      return response
    } catch (error) {
      throw handleErrors(error)
    }
  },
  updateBusiness: async (id, params) => {
    try {
      const response = await api.put(`/sm/businesses/${id}`, params)
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  rolesList: async (params) => {
    try {
      const response = await api.get('/sm/roles', {
        params: {
          grouped: params.isGrouped
        }
      })
      return response
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  backMasquerade: async () => {
    try {
      const response = await api.get('/users/masquerade/back')
      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },
  assetTypes: async (params) => {
    try {
      const response = await api.get('/sm/asset_types', { params })
      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  assetType: async (id) => {
    try {
      const response = await api.get(`/sm/asset_types/${id}`)
      return response
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  businessesEnumerator: async () => {
    try {
      const response = await api.get('/sm/businesses/as_enumerator')
      return response
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  newAssetType: async (_, params) => {
    try {
      const response = await api.post(`/sm/asset_types`, { ...params })
      return response
    } catch (error) {
      throw handleErrors(error)
    }
  },
  updateAssetType: async (id, params) => {
    try {
      const response = await api.put(`/sm/asset_types/${id}`, params)
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  assetsDefaultFields: async (_, params) => {
    try {
      const response = await api.get('/sm/asset_default_fields', { params })
      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  assetDefaultField: async (id) => {
    try {
      const response = await api.get(`/sm/asset_default_fields/${id}`)
      return response
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  assetDefaultFieldsEnumerator: async () => {
    try {
      const response = await api.get('/sm/asset_default_fields/enumerators')
      return response
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  newAssetDefaultField: async (_, params) => {
    try {
      const response = await api.post(`/sm/asset_default_fields`, {
        ...params
      })
      return response
    } catch (error) {
      throw handleErrors(error)
    }
  },
  updateAssetDefaultField: async (id, params) => {
    try {
      const response = await api.put(`/sm/asset_default_fields/${id}`, {
        ...params
      })
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  updateSettings: async (id, params) => {
    try {
      const response = await api.put(`/sm/settings/${id}`, params)
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  getSettings: async (id, params) => {
    try {
      const response = await api.get('/sm/settings/edit', params)
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  deleteAssetDefaultField: async (id) => {
    try {
      const response = await api.delete(`/sm/asset_default_fields/${id}`)
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  }
}

export default AdminOlingaAPI
