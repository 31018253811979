import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const useTabsParams = () => {
  const history = useHistory()
  const location = useLocation()

  const onChangeParams = useCallback(
    (newParams) => {
      const params = new URLSearchParams({
        ...newParams
      })
      history.push({
        pathname: location.pathname,
        search: params.toString()
      })
    },
    [history, location.pathname]
  )
  return {
    onChangeParams
  }
}

export default useTabsParams
