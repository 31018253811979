import PropTypes from 'prop-types'
import React from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import { getRouteUrl } from '../../../helpers'
import TemplatePageBreadcrumb from '../../../pages/Template/TemplatePagesBreadcrumb'

const TemplateEditorBreadcrumb = ({
  templateDetails,
  pageTitle,
  singularTitle,
  id
}) => {
  if (!id) {
    return (
      <Breadcrumb
        routeTree={[
          {
            title: pageTitle,
            url: getRouteUrl('templates')
          },
          {
            title: `New ${singularTitle}`
          }
        ]}
      />
    )
  }
  return (
    <TemplatePageBreadcrumb
      templateDetails={templateDetails}
      templateId={id}
      routeName={`Edit ${singularTitle}`}
      pageTitle={pageTitle}
      singularTitle={singularTitle}
    />
  )
}

TemplateEditorBreadcrumb.propTypes = {
  templateDetails: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  id: PropTypes.number,
  pageTitle: PropTypes.string,
  singularTitle: PropTypes.string
}

export default TemplateEditorBreadcrumb
