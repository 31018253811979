import { LinkIcon } from '@chakra-ui/icons'
import { IconButton, Menu, MenuButton, Portal } from '@chakra-ui/react'
import _, { noop } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { chakraComponentsSizes } from '../../helpers'
import KebabMenuList from './KebabMenuList'

const Kebab = ({
  id,
  otherIds,
  size,
  icon,
  hasBorder,
  zIndex,
  restrictionCondition,
  blackList,
  blackListAttribute,
  items,
  restrictedItems,
  isLoading,
  hasVisibilityRestriction,
  ariaLabel,
  colorScheme,
  renderPortal,
  onClose,
  from
}) => {
  const kebabItems = useMemo(() => {
    if (restrictionCondition) {
      return items
        .concat(restrictedItems)
        .filter((item) => !blackList.includes(_.get(item, blackListAttribute)))
    }

    return items.filter((item) => {
      const isValid = !blackList.includes(_.get(item, blackListAttribute))
      if (hasVisibilityRestriction) {
        return isValid && item.isVisible
      }

      return isValid
    })
  }, [
    blackList,
    blackListAttribute,
    hasVisibilityRestriction,
    items,
    restrictedItems,
    restrictionCondition
  ])

  return (
    <span data-testid="kebab">
      <Menu closeOnSelect={false} isLazy>
        <MenuButton
          data-testid="kebab-button"
          as={IconButton}
          aria-label={ariaLabel}
          icon={icon}
          variant="outline"
          size={size}
          colorScheme={colorScheme}
          border={hasBorder ? '1px solid currentColor' : 'none'}
        />
        {renderPortal && (
          <Portal>
            <KebabMenuList
              ariaLabel={ariaLabel}
              id={id}
              isLoading={isLoading}
              kebabItems={kebabItems}
              otherIds={otherIds}
              zIndex={zIndex}
              onSelect={onClose}
              from={from}
            />
          </Portal>
        )}
        {!renderPortal && (
          <KebabMenuList
            ariaLabel={ariaLabel}
            id={id}
            isLoading={isLoading}
            kebabItems={kebabItems}
            otherIds={otherIds}
            zIndex={zIndex}
            onSelect={onClose}
            from={from}
          />
        )}
      </Menu>
    </span>
  )
}

Kebab.defaultProps = {
  size: 'sm',
  zIndex: 10,
  icon: <LinkIcon />,
  hasBorder: true,
  items: [],
  restrictedItems: [],
  restrictionCondition: false,
  blackList: [],
  blackListAttribute: 'name',
  otherIds: {},
  hasVisibilityRestriction: false,
  ariaLabel: 'Options',
  colorScheme: 'blue',
  renderPortal: true,
  onClose: noop,
  from: ''
}

Kebab.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(chakraComponentsSizes),
  hasBorder: PropTypes.bool,
  items: PropTypes.array,
  restrictedItems: PropTypes.array,
  restrictionCondition: PropTypes.bool,
  blackListAttribute: PropTypes.string,
  blackList: PropTypes.array,
  otherIds: PropTypes.object,
  hasVisibilityRestriction: PropTypes.bool,
  ariaLabel: PropTypes.string,
  colorScheme: PropTypes.string,
  from: PropTypes.string,
  renderPortal: PropTypes.bool,
  onClose: PropTypes.func
}

export default Kebab
