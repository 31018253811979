import { omit } from 'lodash'
import React from 'react'
import BasePage from '../../../pages/BasePage'
import ProfileForm from '../../../pages/Profiles/ProfileForm'
import OlingaAPI from '../../../services/OlingaAPI'

const ProfilePage = ({ id }) => {
  const callbacks = omit(
    {
      profile: { callback: () => OlingaAPI.profileShow(id), params: {} },
      templates: { callback: OlingaAPI.templatesEnumerators, params: {} },
      checklists: { callback: OlingaAPI.checklistsEnumerators, params: {} },
      assetTemplates: {
        callback: () =>
          OlingaAPI.assetTemplatesAsEnumerator({ with_assets: false })
      },
      documents: {
        callback: () => OlingaAPI.foldersEnumerator('documents'),
        params: {}
      },
      roles: {
        callback: () => OlingaAPI.profileRolesList({ isGrouped: true }),
        params: {}
      }
    },
    [!id ? 'profile' : '']
  )

  return (
    <BasePage
      id={id}
      fetchWithoutId
      resourceName="data"
      fetchCallback={callbacks}
      otherParams={{
        id
      }}
      startLoading={!id}
    >
      <ProfileForm />
    </BasePage>
  )
}

export default ProfilePage
