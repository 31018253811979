import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Spinner,
  Stack,
  Switch,
  Tag,
  Text
} from '@chakra-ui/react'
import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { FormProvider } from 'react-hook-form'
import FormSubmitActions from '../../../components/FormSubmitActions'
import EditInline from '../../../components/Inputs/EditInline'
import PageHeader from '../../../components/PageHeader'
import AssetTemplateEditorBreadcrumb from './AssetTemplateEditorBreadcrumb'
import Fields from './Fields'
import useAssetTemplateEditor from './hooks/useAssetTemplateEditor'
import MainFieldSelector from './MainFieldSelector'
import Sidebar from './Sidebar'

const AssetTemplatesEditor = ({ data, id }) => {
  const {
    assetTypes,
    fieldTypes,
    defaultFields,
    isMobile,
    navbarHeight,
    formProps,
    onSubmitForm,
    shouldRenderForm,
    formValues,
    onEditField,
    fieldsRefs,
    editInlineRenderKey,
    breadcrumbName,
    isLoading,
    onDragEnd,
    shouldAllowAssetTypesCreation,
    isMainFieldSelectorOpen,
    onCloseMainFieldSelector,
    selectedDefaultMainField,
    onSelectDefaultMainField,
    setSelectedDefaultMainField,
    hasMainFieldSet
  } = useAssetTemplateEditor({
    data,
    id
  })

  const { name, asset_type_id } = formValues

  const {
    register,
    setValue,
    formState: { errors }
  } = formProps

  if (!shouldRenderForm) {
    return null
  }

  return (
    <Box minH="100vh">
      <MainFieldSelector
        isOpen={isMainFieldSelectorOpen}
        onClose={onCloseMainFieldSelector}
        value={selectedDefaultMainField}
        onChange={setSelectedDefaultMainField}
        onConfirm={onSelectDefaultMainField}
        defaultFields={defaultFields}
      />
      <Sidebar
        navbarHeight={navbarHeight}
        fieldTypes={fieldTypes}
        defaultFields={defaultFields}
        isMobile={isMobile}
        isDisabled={isLoading || !shouldAllowAssetTypesCreation}
        hasMainFieldSet={hasMainFieldSet}
      />
      <PageHeader
        Breadcrumb={
          <AssetTemplateEditorBreadcrumb
            id={id}
            name={breadcrumbName}
            hasPermissionToEdit={shouldAllowAssetTypesCreation}
          />
        }
      />
      <FormProvider {...formProps}>
        <Stack
          as="section"
          w={['100%', '100%', 'calc(100% - 448px)', 'calc(100% - 416px)']}
          h="100%"
          pb={16}
        >
          <Stack w="100%" h="100%" flex="1 1 auto">
            <Stack gap={4} mb={4}>
              <Flex
                flexDir={['column', 'row']}
                gap={4}
                align={['flex-start', 'center']}
              >
                <EditInline
                  width="100%"
                  justify={false}
                  placeholder="Name"
                  isInvalid={!!errors?.name}
                  defaultValue={name}
                  registerProps={{
                    ...register('name', { required: true }),
                    onChange: ({ target: { value } }) => {
                      if (!value) {
                        fieldsRefs.current['name'] = name
                      }

                      setValue('name', value)
                    }
                  }}
                  key={editInlineRenderKey}
                  onSubmit={(value) => onEditField('name', value)}
                  isDisabled={isLoading || !shouldAllowAssetTypesCreation}
                />
                <Switch
                  minW="100px"
                  mb="0!important"
                  {...register('active')}
                  onChange={({ target: { checked } }) => {
                    onEditField('active', checked)
                  }}
                  colorScheme="green"
                  isDisabled={isLoading || !shouldAllowAssetTypesCreation}
                >
                  Active
                </Switch>
              </Flex>
              <Flex align="center" gap={4}>
                <FormControl>
                  <FormLabel>Asset category</FormLabel>
                  <Select
                    placeholder="Asset category"
                    {...register('asset_type_id', { required: true })}
                    onChange={({ target: { value } }) => {
                      if (!value) {
                        fieldsRefs.current['asset_type_id'] = asset_type_id
                      }
                      onEditField('asset_type_id', value)
                    }}
                    isInvalid={!!errors?.asset_type_id}
                    isDisabled={isLoading || !shouldAllowAssetTypesCreation}
                  >
                    {assetTypes.map(({ label, value }, index) => (
                      <option key={index} value={value}>
                        {label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
            </Stack>
            <DragDropContext onDragEnd={onDragEnd}>
              <Box
                sx={{
                  '& #dropzone-container': {
                    display: 'flex',
                    flexDir: 'column',
                    gap: 4
                  }
                }}
              >
                <Fields
                  fieldTypes={fieldTypes}
                  id={id}
                  isDisabled={isLoading || !shouldAllowAssetTypesCreation}
                />
              </Box>
            </DragDropContext>
          </Stack>
          {shouldAllowAssetTypesCreation && (
            <Flex
              bg="white"
              borderTopWidth={1}
              borderRightWidth={1}
              borderTopColor="gray.200"
              p={4}
              position="fixed"
              bottom="0"
              left="0"
              w={['100vw', 'calc(100% - 448px)']}
              justify="flex-end"
            >
              {isLoading ? (
                <Flex align="center" gap={2}>
                  <Spinner colorScheme="green" />
                  <Text color="green">Saving...</Text>
                </Flex>
              ) : !id ? (
                <FormSubmitActions
                  cancelRoute="assetTemplates"
                  onSubmit={onSubmitForm}
                />
              ) : (
                <Tag colorScheme="blue">
                  All changes will be saved automatically
                </Tag>
              )}
            </Flex>
          )}
        </Stack>
      </FormProvider>
    </Box>
  )
}

export default AssetTemplatesEditor
