import Actions from 'pages/Actions'
import BasePage from 'pages/BasePage'
import React from 'react'
import OlingaAPI from '../../../services/OlingaAPI'

const ActionsPage = () => {
  return (
    <BasePage
      fetchCallback={{
        enumerators: {
          callback: OlingaAPI.actionsEnums,
          params: {}
        }
      }}
      id={null}
      fetchWithoutId
      resourceName="data"
    >
      <Actions />
    </BasePage>
  )
}

export default ActionsPage
