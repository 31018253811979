import { first, isEmpty, isNull, isUndefined } from 'lodash'
import { quickSubmitQrCodeValidQuestions } from '../../../../../pages/Form/FillForm/ActionsBar/constants'

export const getQuestionsIndexed = (templateAnswers, data = {}) => {
  if (Array.isArray(templateAnswers)) {
    templateAnswers.forEach((child) => {
      getQuestionsIndexed(child, data)
    })
  }
  if (!Array.isArray(templateAnswers)) {
    if (
      quickSubmitQrCodeValidQuestions.includes(
        templateAnswers?.question_type
      ) &&
      !isNull(templateAnswers?.value) &&
      !isUndefined(templateAnswers?.value)
    ) {
      let value = templateAnswers?.value
      if (templateAnswers?.value?.value) {
        const { value: objectValue } = templateAnswers?.value
        value = objectValue
      }
      data[templateAnswers?.template_question_id] = {
        value: null,
        question_type: ''
      }
      data[templateAnswers?.template_question_id] = {
        value,
        question_type: templateAnswers.question_type
      }
    }
    if (!isEmpty(templateAnswers?.questions)) {
      getQuestionsIndexed(templateAnswers.questions, data)
    }
  }

  return data
}

/**
 *
 * @param {*} context
 * @param {*} text
 * @param {*} maxWidth
 * @returns The words to be drawned into context
 */
export const getTextLines = (context, text, maxWidth) => {
  const words = text.split(' ')
  const lines = []
  let currentLine = first(words)

  for (let i = 1; i < words.length; i++) {
    const word = words[i]
    const width = context.measureText(currentLine + ' ' + word).width
    if (width < maxWidth) {
      currentLine += ' ' + word
    } else {
      lines.push(currentLine)
      currentLine = word
    }
  }
  lines.push(currentLine)
  return lines
}
