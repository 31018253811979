import { Button } from '@chakra-ui/button'
import { Flex } from '@chakra-ui/layout'
import { Tag } from '@chakra-ui/tag'
import { noop } from 'lodash'
import React, { forwardRef } from 'react'

const NotificationInfo = forwardRef(
  ({ isNotify, usersArray, label, variant, onClick }, ref) => (
    <Button
      onClick={onClick}
      ref={ref}
      size="sm"
      variant={variant}
      colorScheme="blue"
    >
      <Flex gap={2} align="center">
        {label}
        <Tag
          colorScheme={isNotify && usersArray?.length > 0 ? 'green' : 'red'}
          size="sm"
        >
          {isNotify && usersArray?.length > 0 ? 'ON' : 'OFF'}
        </Tag>
        <Tag colorScheme="blue" size="sm" ml="1">
          {usersArray?.length}
        </Tag>
      </Flex>
    </Button>
  )
)

NotificationInfo.displayName = 'NotificationInfo'

NotificationInfo.defaultProps = {
  label: 'Notifications',
  variant: 'outline',
  onClick: noop
}

export default NotificationInfo
