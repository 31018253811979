/* eslint-disable react/jsx-key */
import { Box, Heading, Select } from '@chakra-ui/react';
import FormField from 'components/FormField';
import { filter, first, groupBy, isEmpty, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import OlingaAPI from 'services/OlingaAPI';
import SelectedRoles from '../../SelectedRoles';

const ProfileRolesContent = ({
    profilesList,
    register,
    selectedProfile,
    updateProfile,
    displayProfileSelector,
    hasProfileRolesByCategory,
    rolesByProfile,
    isActive,
  }) => {
  const [rolesDetail, setRolesDetail] = useState([]);

  const fetchProfileDetail = async () => {
    if (!selectedProfile?.id) return setRolesDetail([]);
    try {
      const response = await OlingaAPI.profileShow(selectedProfile?.id)
      setRolesDetail(response?.data?.attributes?.roles)
    } catch (err) {
      //
    }
  };

  useEffect(() => {
    fetchProfileDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesByProfile]);

  const getScopedRoles = (context, groupByKey = 'name') => {
    const roles = groupBy(rolesDetail, groupByKey)
    let roleByContextName = filter(Object.keys(roles), (key) => {
      if (groupByKey === 'name') return key.includes(context);
      return key === context
    })
    return map(roles[first(roleByContextName)], (r) => r.resource)
  }

  if (!isActive) return null;
  
  return (
    <Box>
      <Box w='30%'>
        {!isEmpty(profilesList) && displayProfileSelector && (
          <>
            <FormField isRequired={false}>
              <Select
                placeholder="Select Profile"
                size="sm"
                {...register('profile')}
                defaultValue={selectedProfile?.id}
                onChange={(evt) => updateProfile(evt)}
                minW='300px'
              >
                {profilesList.map((option, index) => {
                  return (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  )
                })}
              </Select>
            </FormField>
          </>
        )}
        </Box>
        {!isEmpty(rolesByProfile) && (
          <Box>
            <Heading as="h3" size="md" pt={8}>
              Roles by Profile
            </Heading>
            {React.Children.toArray(
              (rolesByProfile).map((role) => (
                  <SelectedRoles 
                    hasRolesByCategory={hasProfileRolesByCategory}
                    role={role}
                    checklistsOfChecklistRole={getScopedRoles('checklist')}
                    templatesOfTemplateRole={getScopedRoles('Template', 'resourceType')}
                    templatesOfFormRole={getScopedRoles('form')}
                    foldersOfDocumentRole={getScopedRoles('document')}
                    dashboardsOfDashbordRole={getScopedRoles('dashboard')}
                    assetTemplatesOfAssetTemplateRole={getScopedRoles('AssetTemplate', 'resourceType')}
                    readOnly
                    register={register}
                  />
                )
              )
            )}
          </Box>
        )}
      </Box>
  )
}

export default ProfileRolesContent;
