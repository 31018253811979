import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/react'
import React from 'react'
import { useFormContext } from 'react-hook-form'

const Number = ({ index, hasPermissionToEdit, field: { _active, main } }) => {
  const {
    setValue,
    register,
    watch,
    formState: { errors }
  } = useFormContext()
  const value = watch(`asset_fields_attributes.${index}.value`) || ''
  return (
    <NumberInput
      {...register(`asset_fields_attributes.${index}.value`, {
        required: main
      })}
      value={value}
      onChange={(_, newValue) =>
        setValue(`asset_fields_attributes.${index}.value`, newValue)
      }
      isDisabled={!_active}
      isInvalid={!!errors?.asset_fields_attributes?.index?.value?.message}
      isReadOnly={!hasPermissionToEdit}
    >
      <NumberInputField placeholder="Number field" />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  )
}

export default Number
