import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Modal,
  VStack
} from '@chakra-ui/react'
import { isFunction, noop } from 'lodash'
import PropTypes from 'prop-types'
import React, { forwardRef, useRef } from 'react'
import TemplateRecursiveStructure from '../TemplateRecursiveStructure'

const QuestionsSelector = forwardRef(
  (
    {
      isOpen,
      onClose,
      childrenStructure,
      displayLabels,
      initialSelectedNodes,
      allowSelection,
      selectionMode,
      children,
      label,
      size,
      onConfirm,
      direction,
      modalContentStyles,
      useExternalDisclosure
    },
    ref
  ) => {
    const selectionRef = useRef()
    const onClosing = () => {
      if (useExternalDisclosure) {
        if (isFunction(onClose)) {
          onClose()
        }
      } else {
        const { close } = ref.current
        close()
      }
    }
    const onQuestionsConfirm = () => {
      if (!selectionRef.current) {
        onClosing()
      } else {
        onConfirm(selectionRef.current)
        onClosing()
      }
      selectionRef.current = null
    }
    const onChange = (nodes) => {
      selectionRef.current = nodes
    }

    return useExternalDisclosure ? (
      <>
        <Drawer size="full" isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>{label}</DrawerHeader>

            <DrawerBody>
              <VStack pb={direction === 'column-reverse' ? '100px' : 0}>
                <TemplateRecursiveStructure
                  childrenStructure={childrenStructure}
                  displayLabels={displayLabels}
                  initialSelectedNodes={initialSelectedNodes}
                  onSelectionChange={onChange}
                  allowSelection={allowSelection}
                  selectionMode={selectionMode}
                />
              </VStack>
            </DrawerBody>

            <DrawerFooter>
              <Button colorScheme="gray" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="green" onClick={onQuestionsConfirm}>
                Confirm
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
        {children}
      </>
    ) : (
      <Modal
        size={size}
        ref={ref}
        header={label}
        direction={direction}
        modalContentStyles={modalContentStyles}
        body={
          <VStack pb={direction === 'column-reverse' ? '100px' : 0}>
            <TemplateRecursiveStructure
              childrenStructure={childrenStructure}
              displayLabels={displayLabels}
              initialSelectedNodes={initialSelectedNodes}
              onSelectionChange={onChange}
              allowSelection={allowSelection}
              selectionMode={selectionMode}
            />
          </VStack>
        }
        footer={
          <Button colorScheme="green" onClick={onQuestionsConfirm}>
            Confirm
          </Button>
        }
      >
        {children}
      </Modal>
    )
  }
)

QuestionsSelector.displayName = 'QuestionsSelector'

QuestionsSelector.defaultProps = {
  initialSelectedNodes: [],
  selectionMode: 'disabled',
  allowSelection: noop,
  onConfirm: noop,
  size: 'full',
  direction: 'column',
  modalContentStyles: {}
}

QuestionsSelector.propTypes = {
  selectionMode: PropTypes.oneOf(['single', 'multiple', 'disabled'])
}

export default QuestionsSelector
