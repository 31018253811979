import React, { useMemo } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import { getRouteUrl } from '../../../../helpers'

const ManageUserFormBreadcrumb = ({ isSoloPage, user }) => {
  const options = useMemo(() => {
    const opts = [
      {
        title: 'Manage Users',
        url: getRouteUrl('manageUsers')
      }
    ]
    if (user?.attributes) {
      opts.push(
        {
          title: user?.attributes?.email,
          route: null
        },
        {
          title: 'Edit user',
          route: null
        }
      )
    }

    return opts
  }, [user])

  if (!isSoloPage) {
    return null
  }

  return <Breadcrumb routeTree={options} />
}

export default ManageUserFormBreadcrumb
