export const navbarItems = {
  dashboards: {
    order: 0
  },
  documents: {
    order: 1
  },
  templates: {
    order: 2
  },
  assets: {
    order: 3
  },
  checklists: {
    order: 3
  },
  actions: {
    order: 4
  },
  groups: {
    order: 5
  },
  folders: {
    order: 6
  },
  settings: {
    order: 7,
    dropdown: [
      'profiles',
      'manage_users',
      'manage_assessors',
      'manage_staff',
      'manage_dashboards'
    ]
  }
}

export const itemsTransformer = {
  dashboard: {
    icon: 'dashboard',
    id: 'dashboards'
  },
  documents: {
    icon: 'document_scanner',
    id: 'documents'
  },
  templates: {
    icon: 'format_list_bulleted',
    id: 'templates'
  },
  assets: {
    icon: 'web_asset',
    id: 'assets'
  },
  asset_templates: {
    icon: 'list_bulleted',
    id: 'assetTemplates'
  },
  checklists: {
    icon: 'playlist_add_check',
    id: 'checklists'
  },
  actions: {
    icon: 'directions_run',
    id: 'actions'
  },
  groups: {
    icon: 'group',
    id: 'groups'
  },
  folders: {
    icon: 'folder_open',
    id: 'folders'
  },
  alerts: {
    icon: 'doorbell',
    id: 'alerts'
  },
  template_reports: {
    icon: 'pie_chart',
    id: 'templateReports'
  },
  library: {
    icon: 'local_library',
    id: 'documentsLibrary'
  },
  profiles: {
    icon: 'local_library',
    id: 'profiles'
  },
  manage_users: {
    icon: 'manage_accounts',
    id: 'manageUsers'
  },
  manage_assessors: {
    icon: 'settings',
    id: 'assessors'
  },
  manage_staff: {
    icon: 'settings',
    id: 'manageStaff'
  },
  settings: {
    icon: 'settings',
    id: 'settings',
    name: 'Settings'
  },
  manage_dashboards: {
    icon: 'dashboard',
    id: 'manageDashboards'
  },
  dashboards: {
    icon: 'dashboard',
    id: 'dashboards'
  }
}
