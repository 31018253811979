import { EditIcon } from '@chakra-ui/icons'
import { Flex, IconButton } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Dialog from '../../../components/AlertDialog'
import { getRouteUrl } from '../../../helpers'
import MaterialIcon from '../../../components/MaterialIcon'

const ManageStaffActions = ({ id, onToggle, onToggling }) => {
  const dialogTriggerRef = useRef(null)
  const location = useLocation()

  const handleClose = (closeDialog) => {
    closeDialog()
  }

  return (
    <Flex gap={4} align="center">
      <Dialog
        ref={dialogTriggerRef}
        header="Confirm this action"
        body="By proceeding, the user's status will be changed."
        continueColor="green"
        continueLabel="Confirm"
        onContinue={(onCloseDialog) => onToggle(id, onCloseDialog)}
        handleClose={handleClose}
      >
        <IconButton
          aria-label="To Active staff"
          icon={<MaterialIcon type='material-icons-outlined' icon='toggle_on' />}
          variant="outline"
          colorScheme="orange"
          size="sm"
          isLoading={onToggling}
        />
      </Dialog>
      <Link
        to={{
          pathname: getRouteUrl('editStaff', { id }),
          state: { background: location }
        }}
      >
        <IconButton
          icon={<EditIcon />}
          aria-label="Edit staff"
          colorScheme="blue"
          variant="outline"
          size="sm"
        />
      </Link>
    </Flex>
  )
}

export default ManageStaffActions
