import { ChevronDownIcon } from '@chakra-ui/icons'
import React, { useMemo } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import { getRouteUrl } from '../../../../helpers'
import GroupKebab from '../../GroupKebab'

const GroupUsersEditorBreadcrumb = ({ group, pageTitle, singularTitle }) => {
  const options = useMemo(() => {
    const opts = [
      {
        title: pageTitle,
        url: getRouteUrl('groups')
      }
    ]
    if (group?.attributes) {
      opts.push(
        {
          title: group?.attributes?.name,
          options: (
            <GroupKebab
              id={group.attributes.id}
              size="xs"
              hasBorder={false}
              icon={<ChevronDownIcon />}
            />
          )
        },
        {
          title: `Edit ${singularTitle}`,
          route: null
        }
      )
    } else {
      opts.push({
        title: `New ${singularTitle}`,
        url: null
      })
    }

    return opts
  }, [group.attributes, pageTitle, singularTitle])
  return <Breadcrumb routeTree={options} />
}

export default GroupUsersEditorBreadcrumb
