import { useDisclosure, useMediaQuery } from '@chakra-ui/react'
import { debounce, isEmpty, noop, omit } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { useGlobalStateContext } from '../../../../../../contexts/GlobalStateContext'
import {
  globalEvents,
  onAddAssetTemplateField,
  onEditAssetTemplateField
} from '../../../../../../contexts/GlobalStateContext/events'
import {
  useGlobalEventListener,
  useKeyPress,
  useResize
} from '../../../../../../hooks'
import Notification from '../../../../../../services/Notification'

const useAssetTemplateSidebar = () => {
  const [isMobile] = useMediaQuery('(max-width: 799px)')
  const { isOpen, onClose, onOpen } = useDisclosure()

  const {
    isOpen: isDrawerOpen,
    onClose: onCloseDrawer,
    onOpen: onOpenDrawer
  } = useDisclosure()

  const { size } = useResize()
  const { global$ } = useGlobalStateContext()

  const [focusedFieldIndex, setFocusedFieldIndex] = useState(null)
  const [selectedDefaultField, setSelectedDefaultField] = useState(null)

  const selectedDefaultFieldDialogRef = useRef({ open: noop, close: noop })

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    setFocus,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      active: true,
      field_type: null,
      settings: {
        list_options: []
      }
    }
  })

  useGlobalEventListener(
    useCallback(
      ({ type, payload }) => {
        if (type === globalEvents.ON_OPEN_ASSET_TEMPLATE_FIELDS_MOBILE_DRAWER) {
          onOpenDrawer()
        }
        if (type === globalEvents.ON_OPEN_ASSET_FIELD_ATTRIBUTES_MODAL) {
          onOpen()
          reset(payload)
        }
      },
      [onOpenDrawer, onOpen, reset]
    )
  )

  const onOpenFieldTypeForm = (fieldType) => {
    reset({
      name: '',
      active: true,
      field_type: fieldType
    })

    onOpen()

    debounce(() => {
      setFocus('name')
    }, 100)()
  }

  const onOpenDefaultFieldConfirmation = (field) => {
    setSelectedDefaultField(field)
    selectedDefaultFieldDialogRef.current.open()
  }

  const onCloseDefaultFieldConfirmation = (onClose) => {
    setSelectedDefaultField(null)

    onClose()
  }

  const onConfirmDefaultFieldSelection = (onCloseDialog) => {
    const submitData = {
      ...omit(selectedDefaultField, ['id']),
      asset_default_field_id: selectedDefaultField.id
    }
    global$.next(
      onAddAssetTemplateField({
        ...submitData,
        uuid: uuidv4()
      })
    )

    onCloseDialog()
  }

  const onSubmit = useCallback(
    (data) => {
      if (
        data?.field_type === 'list' &&
        (!data?.settings?.list_options || isEmpty(data?.settings?.list_options))
      ) {
        return Notification({
          icon: 'error',
          text: 'At least one option need to be added!'
        })
      }
      try {
        if (data?.uuid) {
          global$.next(
            onEditAssetTemplateField({
              ...data
            })
          )
        } else {
          global$.next(
            onAddAssetTemplateField({
              ...data,
              uuid: uuidv4()
            })
          )
        }

        onClose()
        onCloseDrawer()

        setFocusedFieldIndex(null)
      } catch (error) {
        Notification({
          icon: 'error',
          text: 'All fields must be filled'
        })
      }
    },
    [global$, onClose, onCloseDrawer]
  )

  const [id, fieldType, options, defaultFieldId] = watch(
    ['uuid', 'field_type', 'settings.list_options', 'asset_default_field_id'],
    {
      defaultValues: {
        field_type: null,
        options: []
      }
    }
  )

  const onClickSubmitButton = () => handleSubmit(onSubmit)()

  const isEnterPressed = useKeyPress('Enter', true, true)

  useEffect(() => {
    if (isEnterPressed) {
      handleSubmit(onSubmit)()
    }
  }, [handleSubmit, isEnterPressed, onSubmit])

  useEffect(() => {
    if (size < 799) {
      onCloseDrawer()
    } else {
      onOpenDrawer()
    }
  }, [onCloseDrawer, onOpenDrawer, size])

  const onAddFieldOption = (_, index) => {
    setFocusedFieldIndex(index)
  }

  const onChangeFieldOptionsList = (updatedList) => {
    setValue(
      'settings.list_options',
      updatedList.map((item) => ({
        ...item,
        name: item?.name ?? ''
      }))
    )
  }

  return {
    fieldType,
    onSubmit,
    handleSubmit,
    register,
    setFocus,
    setValue,
    onOpenFieldTypeForm,
    selectedDefaultField,
    onOpenDefaultFieldConfirmation,
    onCloseDefaultFieldConfirmation,
    onConfirmDefaultFieldSelection,
    selectedDefaultFieldDialogRef,
    isOpen,
    onClose,
    isDrawerOpen,
    onCloseDrawer,
    errors,
    onClickSubmitButton,
    isMobile,
    options,
    onAddFieldOption,
    focusedFieldIndex,
    onChangeFieldOptionsList,
    isEdit: !!id,
    defaultFieldId
  }
}

export default useAssetTemplateSidebar
