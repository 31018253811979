/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from '@chakra-ui/input'
import { Flex, Text } from '@chakra-ui/layout'
import { Textarea } from '@chakra-ui/textarea'
import { Select } from 'chakra-react-select'
import React, { forwardRef, Fragment, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import CheckboxGroup from '../../../../components/CheckboxGroup'
import DatePicker from '../../../../components/DatePicker'
import InputNumber from '../../../../components/Inputs/Number'
import InputImageUpload from '../../../../components/Inputs/UploadFile'
import QuillViewer from '../../../../components/QuillViewer'
import RadioGroup from '../../../../components/RadioGroup'
import Signature from '../../../../components/Signature'
import Slider from '../../../../components/Slider'
import AssetQuestion from './AssetQuestion'
import Boolean from './Boolean'
import useComponent from './hooks/useComponent'
import { FieldContainer, RequiredInfo } from './styles'

const Component = forwardRef((props, componentRef) => {
  const { field, currentKey } = props
  const name = useMemo(() => `${currentKey}.value`)
  const { register } = useFormContext()

  const { getFieldProps } = useComponent({ name, field })

  const FieldComponent = useMemo(() => {
    switch (field.question_type) {
      case 'scale':
        return Slider
      case 'free_field_multiline':
        return Textarea
      case 'date_time':
        return DatePicker
      case 'checkbox':
        return CheckboxGroup
      case 'radio_btn':
        return RadioGroup
      case 'select':
        return Select
      case 'counter':
        return InputNumber
      case 'instruction':
        return QuillViewer
      case 'image':
        return InputImageUpload
      case 'boolean':
        return Boolean
      case 'calculation':
        return Fragment
      case 'signature':
        return Signature
      case 'asset':
        return AssetQuestion
      default:
        return Input
    }
  }, [field])

  return (
    <FieldContainer>
      <>
        {!['instruction', 'calculation'].includes(field.question_type) && (
          <Flex paddingBottom="8px">
            <Text fontSize="sm" fontWeight="bold">
              <span dangerouslySetInnerHTML={{ __html: field.text }} />
            </Text>
            {(field.compulsory || field.main) && <RequiredInfo>*</RequiredInfo>}
          </Flex>
        )}
        <Input
          hidden
          {...register(`${currentKey}.question_type`)}
          defaultValue={field.question_type}
        />
        <Input
          hidden
          {...register(`${currentKey}.template_question_id`)}
          defaultValue={field.id}
        />
      </>
      <FieldComponent ref={componentRef} {...getFieldProps()} />
    </FieldContainer>
  )
})

Component.displayName = 'Component'

export default Component
