import _ from 'lodash'

const getUnnordedList = (value) => {
  return `<ul style='margin-left: 20px'>${value.map(
    (i) => '<li>' + i + '</li>'
  )}</ul>`.replace(/,/g, '')
}

export const getCurrentValue = (fieldType, currentValue) => {
  const currentFormValue = currentValue
  if (currentFormValue) {
    switch (fieldType) {
      case 'question':
        if (currentFormValue.questions) {
          if (_.isArray(currentFormValue.questions?.value)) {
            return getUnnordedList(currentFormValue.questions?.value)
          }
          return currentFormValue.questions?.value
        }
        if (
          ['select', 'counter', 'checkbox'].includes(
            currentFormValue.question_type
          )
        ) {
          if (_.isArray(currentFormValue.value)) {
            return getUnnordedList(currentFormValue.value)
          }
          if (_.isObject(currentFormValue.value)) {
            return currentFormValue.value?.label ?? null
          }
          return currentFormValue.value
        }
        if (currentFormValue.question_type === 'image') {
          const files = currentFormValue.value
          if (!files.length) {
            return null
          }
          const images = files.map(
            (file) =>
              `<img src=${URL.createObjectURL(
                file
              )} style="display: block; width: auto; height: 100%; object-fit: cover; border-radius: 4px;" />`
          )
          return `<div style="display: grid; grid-template-columns: repeat(4, 1fr); grid-gap: 8px;">${images}</div>`
            .replace(/,/g, '')
            .replace('1fr', ', 1fr')
        }
        return currentFormValue?.value
      default:
        return currentFormValue
    }
  }
  return null
}

export const transformUsers = (users) => {
  return users.map(({ attributes: { id, name, email } }) => ({
    value: id,
    label: name || email,
    email: email
  }))
}
