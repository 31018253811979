import { useDisclosure } from '@chakra-ui/react'
import { downloadDocument, setFormErrors } from 'helpers'
import { first, isEmpty, noop, snakeCase } from 'lodash'
import { useState } from 'react'
import Notification from 'services/Notification'
import OlingaAPI from 'services/OlingaAPI'

const useAssetsImport = ({ refetch }) => {
  const {
    isOpen: isImportCsvModalOpen,
    onClose: onCloseImportCsvModal,
    onOpen
  } = useDisclosure()
  const [assetTemplates, setAssetTemplates] = useState([])
  const [selectedAssetTemplate, setSelectedAssetTemplate] = useState(null)
  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchAssetTemplates = async () => {
    try {
      const response = await OlingaAPI.assetsEnumerator()
      if (response.status === 200) {
        setAssetTemplates(response?.data?.data?.asset_templates ?? [])
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    }
  }

  const onOpenImportCsvModal = async () => {
    setSelectedAssetTemplate(null)
    if (isEmpty(assetTemplates)) {
      await fetchAssetTemplates()
      onOpen()

      return
    }

    onOpen()
  }

  const onDownloadTemplate = async (assetTemplate) => {
    try {
      const response = await OlingaAPI.exportAssetTemplateCsv(
        assetTemplate.value
      )
      const fileName = snakeCase(assetTemplate.label)
      if (response.status === 200) {
        downloadDocument(response.data, `${fileName}.csv`)
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    }
  }

  const onBulkImportAssets = async () => {
    setIsLoading(true)
    try {
      const data = {
        file: first(files)
      }
      const response = await OlingaAPI.importAssetTemplateCsv(
        selectedAssetTemplate?.value,
        data
      )
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: response?.data?.message
        })
        onCloseImportCsvModal()
        refetch({
          asset_template_id: selectedAssetTemplate?.value,
          page: 1,
          per_page: 15
        })
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeFiles = (newFiles) => {
    setFiles(newFiles)
  }

  return {
    isImportCsvModalOpen,
    onCloseImportCsvModal,
    onOpenImportCsvModal,
    selectedAssetTemplate,
    setSelectedAssetTemplate,
    onDownloadTemplate,
    assetTemplates,
    onChangeFiles,
    files,
    onBulkImportAssets,
    isLoading
  }
}

export default useAssetsImport
