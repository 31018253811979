import React, { useMemo } from 'react'
import BasePage from '../../../pages/BasePage'
import TemplateFrequencyEditor from '../../../pages/TemplateFrequencyEditor'
import OlingaAPI from '../../../services/OlingaAPI'
import { getNumberFromText } from '../../../helpers'
import { useHistory } from 'react-router-dom'

const TemplateFrequencyEditorPage = ({ templateId }) => {
  const history = useHistory()
  const id = history.location.search?.includes('id')
    ? getNumberFromText(history.location.search)
    : null
  const callbacks = useMemo(() => {
    if (id) {
      return {
        data: {
          callback: () =>
            OlingaAPI.getTemplateFrequenciesDetails(templateId, id),
          params: {}
        },
        enums: {
          callback: () => OlingaAPI.getTemplateFrequenciesEnums(templateId),
          params: {}
        },
        templateStructure: {
          callback: () => OlingaAPI.templateStructure(templateId)
        }
      }
    }
    return {
      enums: {
        callback: () => OlingaAPI.getTemplateFrequenciesEnums(templateId),
        params: {}
      },
      templateStructure: {
        callback: () => OlingaAPI.templateStructure(templateId)
      }
    }
  }, [templateId, id])
  return (
    <BasePage
      id={templateId}
      fetchWithoutId
      resourceName="data"
      fetchCallback={callbacks}
      otherParams={{ templateId, id }}
    >
      <TemplateFrequencyEditor />
    </BasePage>
  )
}

export default TemplateFrequencyEditorPage
