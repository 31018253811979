import { Stack, Tag } from '@chakra-ui/react'
import { capitalize, first, get, last } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DatePckr from '../../../components/DatePicker'
import Table from '../../../components/Table'
import { useTableColumns } from '../../../hooks'
import OlingaAPI from '../../../services/OlingaAPI'
import ChecklistUserTasksPageHeader from './PageHeader'

const hiddenColumnsKey = 'form_answers_persisted_columns.checkl'

const ChecklistUsersTasks = ({ checklistData, id, itemId }) => {
  const checklist = get(checklistData, 'checklist') ?? {}
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({ pageCount: 0, totalItems: 0 })
  const paramsRef = useRef({ page: 1, per_page: 10 })

  const taskName = useMemo(() => {
    const attributes = [
      'checklistItemsDaily',
      'checklistItemsWeekly',
      'checklistItemsMonthly',
      'checklistItemsQuarterly',
      'checklistItemsBiannual'
    ]
    const tasks = attributes.flatMap(
      (key) => checklist?.attributes?.[key] ?? []
    )
    return tasks?.find((task) => task.id === Number(itemId))?.title ?? ''
  }, [checklist?.attributes, itemId])

  const sortType = useCallback((rowA, rowB, columnId) => {
    const getAttribute = (object) => {
      return get(object, `original.${columnId}.props.count`)
    }
    if (getAttribute(rowA) > getAttribute(rowB)) return 1
    if (getAttribute(rowA) < getAttribute(rowB)) return -1
    return 0
  }, [])

  const columnsMapper = ({
    original: { id: checklistUserId, checklistItemId }
  }) => ({
    id: checklistUserId,
    checklistItemId,
    checklistId: Number(id),
    name: get(checklistData, 'checklist.attributes.name')
  })

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Completed by',
        accessor: 'fullName'
      },
      {
        Header: 'Date',
        accessor: 'dateTime'
      },
      {
        Header: 'Latitude',
        accessor: 'latitude'
      },
      {
        Header: 'Longitude',
        accessor: 'longitude'
      },
      {
        Header: 'Status',
        accessor: 'status',
        sortType
      }
    ],
    hiddenColumnsKey,
    true,
    null,
    columnsMapper,
    ['id', 'latitude', 'longitude', 'actions']
  )

  const fetchChecklistUsersTasks = useCallback(
    async (params = { page: 1, per_page: 10 }) => {
      paramsRef.current = params
      setIsLoading(true)
      try {
        const {
          data: foldersData,
          meta: { last: pageCount, count: totalItems }
        } = await OlingaAPI.getUsersTasks(id, itemId, params)
        setData(foldersData)
        setPagination({ pageCount, totalItems })
      } catch (error) {
        setData([])
      } finally {
        setIsLoading(false)
      }
    },
    [id, itemId]
  )

  useEffect(() => {
    fetchChecklistUsersTasks()
  }, [fetchChecklistUsersTasks])

  const tableData = useMemo(() => {
    return data.map(
      ({
        attributes: {
          id,
          userId,
          checklistItemId,
          dateTime,
          latitude,
          longitude,
          status,
          user
        }
      }) => {
        return {
          id,
          userId,
          checklistItemId,
          dateTime,
          latitude,
          longitude,
          status: status ? (
            <Tag colorScheme="blue">{capitalize(status)}</Tag>
          ) : (
            <Tag>{status}</Tag>
          ),
          email: user?.email ?? <Tag>Empty</Tag>,
          fullName: user?.fullName ?? <Tag>Empty</Tag>
        }
      }
    )
  }, [data])

  const onChangeRange = (dates) => {
    if (dates.every((date) => !!date)) {
      const datesParams = {
        start_date: moment(first(dates)).toDate(),
        end_date: moment(last(dates)).toDate()
      }
      fetchChecklistUsersTasks({
        ...paramsRef.current,
        page: 1,
        ...datesParams
      })
    } else if (dates.every((date) => !date)) {
      fetchChecklistUsersTasks()
    }
  }

  return (
    <Stack as="section" minH="calc(100vh - 140px)">
      <ChecklistUserTasksPageHeader
        id={checklist?.id}
        name={checklist?.attributes?.name}
        taskName={taskName}
      />
      <Table
        isLoading={isLoading}
        data={tableData}
        columns={columns}
        initialRowsPerPage={10}
        pageCount={pagination.pageCount}
        totalItems={pagination.totalItems}
        fetchPage={(params) => fetchChecklistUsersTasks(params)}
        persistedColumnsKey={hiddenColumnsKey}
        initiallyHiddenColumns={hiddenColumns}
      >
        <DatePckr
          isRangePicker
          onChange={onChangeRange}
          showTimeSelect={false}
          minW="380px"
          keepDateOnClear
          monthsShown={2}
          placeholder="Search by date range"
          showYearDropdown
          leftDistance="-20px"
        />
      </Table>
    </Stack>
  )
}

ChecklistUsersTasks.defaultProps = {
  id: null,
  itemId: null,
  persistedHiddenColumns: [],
  hiddenColumnsKey: ''
}

export default ChecklistUsersTasks
