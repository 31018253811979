import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text
} from '@chakra-ui/react'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import TextClamper from '../../../components/TextClamper'
import { useLocalStorage } from '../../../hooks'
import MaterialIcon from '../../MaterialIcon'

const ColumnsVisibilityController = ({
  columns,
  persistedColumnsKey,
  blackList,
  columnsLimit,
  isLoading
}) => {
  const { setData, data: persistedHiddenColumns } =
    useLocalStorage(persistedColumnsKey)
  const { data: allPersistedTemplateColumns } = useLocalStorage(
    _.first(persistedColumnsKey.split('.'))
  )
  const ref = useRef({})
  const columnsRef = useRef(columns)

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [allColumns, setAllColumns] = useState(columns)
  const [canHideColumns, setCanHideColumns] = useState(true)

  const { hiddenColumnsCount } = useMemo(() => {
    const columns = allColumns.filter(({ id }) => !blackList.includes(id))
    return {
      hiddenColumnsCount: columns.filter(({ isVisible }) => !isVisible).length
    }
  }, [allColumns, blackList])

  const hideColumnsAdornment = useMemo(() => {
    let limit = columnsLimit - hiddenColumnsCount
    if (limit < 0) {
      return null
    }
    if (limit <= 0) {
      return <Text fontSize="xs">{`You can't hide more columns`}</Text>
    }
    return (
      <Text fontSize="xs">
        You can hide {limit} more {limit === 1 ? 'column' : 'columns'}
      </Text>
    )
  }, [columnsLimit, hiddenColumnsCount])

  useEffect(() => {
    if (columnsLimit < 0) {
      setCanHideColumns(true)
    } else if (columnsLimit - hiddenColumnsCount <= 0) {
      setCanHideColumns(false)
    } else {
      setCanHideColumns(true)
    }
  }, [columnsLimit, hiddenColumnsCount])

  useEffect(() => {
    if (
      Array.isArray(persistedHiddenColumns) &&
      persistedHiddenColumns?.length > 0
    ) {
      const columnsClone = [...columnsRef.current]
      columnsClone.forEach((column) => {
        if (persistedHiddenColumns.includes(column.id)) {
          column.isVisible = false
        }
      })
      setAllColumns(columnsClone)
    }
  }, [persistedHiddenColumns])

  useEffect(() => {
    setAllColumns(columns)
    columns.forEach((column) => {
      if (_.isFunction(column?.getToggleHiddenProps)) {
        const { onChange } = column?.getToggleHiddenProps()
        ref.current = {
          ...ref.current,
          [column.id]: onChange
        }
      }
    })
  }, [columns])

  const getColumnLabel = useCallback((column) => {
    if (column?.RawHeader) {
      return <TextClamper text={column.RawHeader} lines={1} isHtml />
    }
    if (column.id === 'expander') {
      return 'Actions'
    }
    return <TextClamper text={column.Header} lines={1} />
  }, [])

  const handleChange = useCallback(
    (index) => {
      const columnsClone = [...allColumns]
      columnsClone.splice(index, 1, {
        ...columnsClone[index],
        isVisible: !columnsClone[index].isVisible
      })

      setAllColumns(columnsClone)
    },
    [allColumns]
  )

  const handleMenuOpen = useCallback(() => {
    setIsMenuOpen((prevState) => !prevState)
  }, [])

  const handleSave = useCallback(() => {
    allColumns.forEach(({ isVisible: checked, id }) => {
      if (_.isFunction(ref.current[id])) {
        ref.current[id]({ target: { checked } })
      }
    })
    if (persistedColumnsKey) {
      const columnsToPersist = allColumns
        .filter(({ isVisible }) => !isVisible)
        .map(({ id }) => id)
      const [storeKey, templateId] = persistedColumnsKey.split('.')
      setData({
        [storeKey]: {
          ...(allPersistedTemplateColumns ?? {}),
          [templateId]: columnsToPersist
        }
      })
    }
    handleMenuOpen()
  }, [
    allColumns,
    allPersistedTemplateColumns,
    persistedColumnsKey,
    setData,
    handleMenuOpen
  ])

  return (
    <Menu isLazy isOpen={isMenuOpen}>
      <MenuButton
        data-testid='table-visible-columns-btn'
        size="sm"
        w="max-content"
        as={Button}
        
        rightIcon={<MaterialIcon type='material-icons-outlined' icon='view_column' />}
        aria-label="Columns Controller"
        colorScheme="blue"
        variant="outline"
        onClick={handleMenuOpen}
        isLoading={isLoading}
      >
        Visible Columns
      </MenuButton>
      <MenuList maxW="250px" px={4}>
        <CheckboxGroup colorScheme="blue">
          <Stack gap={1}>
            {allColumns.map((column, index) => {
              return (
                <Checkbox
                  data-testid='table-visible-action'
                  mb={0}
                  key={column.id}
                  onChange={() => handleChange(index)}
                  isChecked={column.isVisible}
                  isDisabled={blackList?.includes(column.id)}
                >
                  {getColumnLabel(column)}
                </Checkbox>
              )
            })}
          </Stack>
        </CheckboxGroup>
        <Box my={2}>{hideColumnsAdornment}</Box>
        <Button
          isFullWidth
          variant="outline"
          mt={4}
          colorScheme="blue"
          size="sm"
          onClick={handleSave}
          isDisabled={!canHideColumns}
          data-testid='table-save-visible-columns'
        >
          Save
        </Button>
      </MenuList>
    </Menu>
  )
}

export default ColumnsVisibilityController
