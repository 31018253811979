/* eslint-disable react/display-name */
import {
  Box,
  CloseButton,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Portal,
  theme,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  Controls,
  LeftArrow,
  RightArrow
} from '../../components/Carousel/Customizations'
import Carousel from '../Carousel'
import OverlayLoading from '../OverlayLoading'

const getImageElement = (url, index, isMobile, isFull) => {
  let offset = '180px'
  if (isMobile) {
    offset = '200px'
  } else if (isFull) {
    offset = '100px'
  }
  return (
    <div
      key={index}
      style={{
        height: `calc(100vh - ${offset})`
      }}
    >
      <img
        src={url}
        style={{ height: '100%', width: 'auto', objectFit: 'contain' }}
      />
    </div>
  )
}

const getImages = (images, isMobile, isDisplayingThumbs) => {
  return images.map((url, index) =>
    getImageElement(url, index, isMobile, !isDisplayingThumbs)
  )
}

const ImageCarousel = forwardRef(
  ({ images, initialSelectedImage, isDefaultOpened }, ref) => {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [isVisible, setIsVisible] = useState(false)
    const [selectedImage, setSelectedImage] = useState(initialSelectedImage)
    const [isMobile] = useMediaQuery('(max-width: 799px)')
    const [isPlaying, setIsPlaying] = useState(true)
    const [isLooping, setIsLooping] = useState(true)
    const [isDisplayingThumbs, setIsDisplayingThumbs] = useState(true)
    const [displayedImages, setDisplayedImages] = useState(
      getImages(images, isMobile, isDisplayingThumbs)
    )

    const carouselRef = useRef()

    const currentImage = useMemo(() => {
      const url = images[selectedImage]
      const [fileName] = url.split('/').slice(-1)
      return {
        url,
        fileName
      }
    }, [images, selectedImage])

    // this method delays the modal opening to desguise the slowed carousel opening
    const handleAsyncOpen = () => {
      setIsVisible(false)
      onOpen()
      _.debounce(() => {
        setIsVisible(true)
        if (_.isFunction(carouselRef.current?.carouselWrapperRef?.focus)) {
          carouselRef.current.carouselWrapperRef.focus()
        }
      }, 300)()
    }

    useEffect(() => {
      return () => {
        setIsVisible(false)
        setSelectedImage(0)
        setIsPlaying(true)
        setIsLooping(true)
      }
    }, [])

    useEffect(() => {
      setSelectedImage(initialSelectedImage)

      return () => {
        setSelectedImage(0)
      }
    }, [initialSelectedImage])

    useLayoutEffect(() => {
      if (isDefaultOpened) {
        onOpen()
      }
    }, [isDefaultOpened, onOpen])

    useEffect(() => {
      if (!isLooping) {
        setSelectedImage(0)
      }
    }, [isLooping])

    useEffect(() => {
      setDisplayedImages(getImages(images, isMobile, isDisplayingThumbs))
    }, [images, isDisplayingThumbs, isMobile])

    useImperativeHandle(ref, () => ({
      close: onClose,
      open: handleAsyncOpen
    }))

    const getCarousel = useCallback(() => {
      return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
          <ModalOverlay />
          <Box
            top={0}
            right="40px"
            position="fixed"
            zIndex={theme.zIndices.modal + 10}
          >
            <Flex align="center" gap={4}>
              <Controls
                isPlaying={isPlaying}
                isLooping={isLooping}
                isDisplayingThumbs={isDisplayingThumbs}
                onPlayClick={() => setIsPlaying(!isPlaying)}
                onLoopClick={() => setIsLooping(!isLooping)}
                onThumbsClick={() => setIsDisplayingThumbs(!isDisplayingThumbs)}
                currentImage={currentImage}
              />
              <CloseButton color="white" onClick={onClose} />
            </Flex>
          </Box>
          <ModalContent
            maxW={isMobile ? '100%' : '70%'}
            bg="transparent"
            boxShadow="none"
            overflow="hidden"
            visibility={isVisible ? 'visible' : 'hidden'}
          >
            <Carousel
              items={displayedImages}
              selectedItem={selectedImage}
              onChange={setSelectedImage}
              customPrevArrow={
                isMobile
                  ? null
                  : (onClick, hasPreviousItem, ariaLabel) => (
                      <LeftArrow
                        onClick={onClick}
                        hasPreviousItem={hasPreviousItem}
                        ariaLabel={ariaLabel}
                      />
                    )
              }
              customNextArrow={
                isMobile
                  ? null
                  : (onClick, hasNextItem, ariaLabel) => (
                      <RightArrow
                        onClick={onClick}
                        hasNextItem={hasNextItem}
                        ariaLabel={ariaLabel}
                      />
                    )
              }
              infiniteLoop={isLooping}
              autoPlay={isPlaying}
              shouldDisplayIndicators={false}
              shouldDisplayThumbs={isDisplayingThumbs}
              ref={carouselRef}
            />
          </ModalContent>
        </Modal>
      )
    }, [
      currentImage,
      displayedImages,
      isDisplayingThumbs,
      isLooping,
      isMobile,
      isOpen,
      isPlaying,
      isVisible,
      onClose,
      selectedImage
    ])

    return (
      <>
        {isOpen && !isVisible && (
          <Portal>
            <OverlayLoading />
          </Portal>
        )}
        {getCarousel()}
      </>
    )
  }
)

ImageCarousel.defaultProps = {
  initialSelectedImage: 0,
  carouselProps: {},
  isDefaultOpened: false
}

ImageCarousel.propTypes = {
  images: PropTypes.array.isRequired,
  initialSelectedImage: PropTypes.number,
  carouselProps: PropTypes.object,
  isDefaultOpened: PropTypes.bool
}

export default ImageCarousel
