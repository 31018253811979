import React from 'react'
import TemplateForm from '../../../../../pages/TemplateEditor/TemplateForm'

const TabbedTemplateForm = ({ data, templateData }) => {
  const { template } = templateData
  return (
    <TemplateForm
      data={{
        ...data,
        template
      }}
      top="400px"
      hasHeader={false}
    />
  )
}

export default TabbedTemplateForm
