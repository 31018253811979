import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { EditIcon, AddIcon, LinkIcon } from '@chakra-ui/icons'
import Kebab from '../../../components/Kebab'
import { useLocation } from 'react-router-dom'
import { chakraComponentsSizes } from '../../../helpers'

const GroupKebab = ({ id, size, icon, hasBorder, zIndex }) => {
  const location = useLocation()

  const currentRoute = useMemo(() => {
    const path = location.pathname
    if (path.match('new')) {
      return 'newAssessor'
    } else if (path.match('edit')) {
      return 'editAssessor'
    }
    return undefined
  }, [location])

  return (
    <Kebab
      id={id}
      size={size}
      icon={icon}
      hasBorder={hasBorder}
      zIndex={zIndex}
      items={[
        {
          name: 'New Assessor',
          icon: <AddIcon />,
          route: 'newAssessor'
        },
        {
          name: 'Edit Assessor',
          icon: <EditIcon />,
          route: 'editAssessor'
        }
      ]}
      blackList={[currentRoute]}
      blackListAttribute="route"
    />
  )
}

GroupKebab.defaultProps = {
  size: 'sm',
  zIndex: 10,
  icon: <LinkIcon />,
  hasBorder: true
}

GroupKebab.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(chakraComponentsSizes),
  hasBorder: PropTypes.bool
}

export default GroupKebab
