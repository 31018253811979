import React from 'react'
import BaseComponent from '../../../BasePage'

const TemplateSettingsTabsChildren = ({
  templateId,
  children,
  tab,
  shouldFetch,
  additionalProps
}) => {
  if (!tab) {
    return null
  }
  const { fetchCallback, params, resourceName, cachedKeys, Skeleton } = tab
  return (
    <BaseComponent
      id={templateId}
      fetchCallback={fetchCallback}
      fetchWithoutId
      otherParams={{ ...(params ?? {}), ...additionalProps }}
      resourceName={resourceName}
      shouldFetch={shouldFetch}
      cachedKeys={cachedKeys}
      Skeleton={Skeleton ?? null}
    >
      {children}
    </BaseComponent>
  )
}

TemplateSettingsTabsChildren.defaultProps = {
  additionalProps: {}
}

export default TemplateSettingsTabsChildren
