import React from 'react'
import Arrow from '../index'

const RightArrow = ({ onClick, hasNextItem, ariaLabel }) => {
  return (
    <Arrow
      onClick={onClick}
      isDisabled={!hasNextItem}
      ariaLabel={ariaLabel}
      direction="right"
    />
  )
}

export default RightArrow
