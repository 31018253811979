import { useMediaQuery } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { differenceBy, intersectionBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { getRouteUrl, setFormErrors } from '../../../../helpers'
import { usePageProperties } from '../../../../hooks'
import Notification from '../../../../services/Notification'
import OlingaAPI from '../../../../services/OlingaAPI'

const schema = yup
  .object({
    name: yup.string().required('The name is required')
  })
  .required()

const useGroupUsersEditor = ({ data }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { pageTitle, singularTitle } = usePageProperties({ pageName: 'groups' })

  const [group, employees] = useMemo(() => {
    if (data) {
      const { group, employees } = data
      return [group || {}, employees || []]
    }
    return [{}, []]
  }, [data])

  const history = useHistory()

  const [isMobile] = useMediaQuery('(max-width: 799px)')

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: null,
    resolver: yupResolver(schema)
  })

  const name = watch('name', getValues('name'))

  useEffect(() => {
    if (group?.attributes) {
      const { name, userIds } = group.attributes
      reset({ name, user_ids: userIds })
    }
  }, [data, group?.attributes, reset])

  const selectedUsers = useMemo(() => {
    const group = data?.group
    if (group?.attributes) {
      const { userIds } = group.attributes
      return intersectionBy(
        employees,
        userIds.map((id) => ({ id: id.toString() })),
        'id'
      ).map(({ attributes }) => attributes)
    }
    return []
  }, [data, employees])

  const unselectedUsers = useMemo(() => {
    if (group?.attributes) {
      const { userIds } = group.attributes
      return differenceBy(
        employees,
        userIds.map((id) => ({ id: id.toString() })),
        'id'
      ).map(({ attributes }) => attributes)
    }
    return employees.map(({ attributes }) => attributes)
  }, [employees, group])

  const onSubmit = async (data) => {
    const formData = { group: data }
    setIsSubmitting(true)
    try {
      const endpoint = group?.id
        ? () => OlingaAPI.groupsUpdate(group.id, formData)
        : () => OlingaAPI.groupsCreate(formData)
      const response = await endpoint()
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: !group.id ? 'Group created!' : 'Group updated!'
        })
        if (history.location?.from) {
          history.goBack()
        } else {
          history.push(getRouteUrl('groups'))
        }
      }
    } catch (error) {
      setFormErrors(error, setError, Notification)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleTransferChange = useCallback(
    (list) => {
      const ids = list.map(({ id }) => id)
      setValue('user_ids', ids)
    },
    [setValue]
  )

  return {
    isSubmitting,
    onSubmit,
    selectedUsers,
    unselectedUsers,
    name,
    errors,
    register,
    handleSubmit,
    isMobile,
    handleTransferChange,
    group,
    pageTitle,
    singularTitle
  }
}

export default useGroupUsersEditor
