import { first, isArray } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

export const getTitle = (value) => {
  return first(Object.keys(value))
    ?.replaceAll('_', ' ')
    ?.replace('attributes', '')
}

export const getDescription = (key) => {
  const descriptions = {
    features: 'Features that Business will have access on Olinga.',
    screens:
      'Screens custom settings regarding contextualized texts and others.',
    validations: 'Business custom validations'
  }

  return descriptions[key]
}

export const formattedRolesOptions = (roles = []) => {
  if (!first(roles)?.name) {
    return []
  }

  return roles?.map((role) => ({
    ...role,
    label: role.label || role.name || role,
    value: role.value || role.name || role
  }))
}

export const getRolesOptions = ({ rolesData }) => {
  return Object.keys(rolesData)?.map((roleKey) => ({
    label: roleKey,
    options: rolesData[roleKey]?.map(({ attributes: { name } }) => ({
      value: name,
      group: roleKey,
      name,
      label: name
    }))
  }))
}

const formatRecursiveStructure = (screens) => {
  return screens.map((screensData) => {
    const mutadedData = Object.entries(screensData).reduce((acc, [k, v]) => {
      acc[k] = v.reduce((iAcc, currentValue) => {
        if (currentValue.attribute) {
          iAcc[currentValue.attribute] = currentValue.value
        } else {
          return formatRecursiveStructure(v)
        }

        return iAcc
      }, {})
      return acc
    }, {})
    return mutadedData
  })
}

const formatFeatures = (features) => {
  return features.map((featuresData) => {
    const mutatedFeaturedData = Object.entries(featuresData).reduce(
      (acc, [k, v]) => {
        acc[k] = v.reduce((iAcc, currentValue) => {
          let value = currentValue.value
          if (
            currentValue.type === 'Array' &&
            isArray(currentValue.value) &&
            currentValue.attribute === 'additional_fields'
          ) {
            if (
              currentValue.value?.every((values) =>
                ['label', 'attribute_name', 'model'].every(
                  (field) => !values?.[field]
                )
              )
            ) {
              value = []
            } else {
              value = currentValue.value.map((currentData) => ({
                ...currentData,
                _id: uuidv4()
              }))
            }
          }
          iAcc[currentValue.attribute] = value

          return iAcc
        }, {})
        return acc
      },
      {}
    )
    return mutatedFeaturedData
  })
}

export const formatBusinessFormData = (businessData) => {
  return {
    ...businessData,
    ...businessData.settingsFields,
    features: formatFeatures(businessData.settingsFields.features),
    screens: formatRecursiveStructure(businessData.settingsFields.screens),
    validations: formatRecursiveStructure(
      businessData.settingsFields.validations
    )
  }
}
