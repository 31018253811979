import { Box, Button, Divider, Flex, Input } from '@chakra-ui/react'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { useFormContext, useWatch } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { useTemplateEditorContext } from '../../../contexts/TemplateEditor/index'
import Dropzone from '../Dropzone'
import { changeQuestionsOrder } from '../Form/helpers'
import Option from './Option'

const OptionsHandler = ({
  control,
  getOptions,
  optionsKey,
  deletedOptionsKey,
  questionKey
}) => {
  const { setValue, getValues } = useFormContext()
  const { indexes } = useTemplateEditorContext()
  const [optionTitle, setOptionTitle] = useState('')
  const [isInvalidInput, setIsInvalidInput] = useState(false)

  const [options, deletedOptions, logics] = useWatch({
    name: [optionsKey, deletedOptionsKey, `${questionKey}.questions`],
    control,
    defaultValue: [
      getOptions().options,
      getOptions().deletedOptions,
      getValues(`${questionKey}.questions`)
    ]
  })

  useEffect(() => {
    if (logics.length > 0) {
      logics.forEach((_, index) => {
        const logicValue = logics[index].value
        const logicMatchingValue = options.find(
          ({ label }) => label.toString() === logicValue.toString()
        )

        if (logicMatchingValue) {
          setValue(
            `${questionKey}.questions.${index}._selectedOptionId`,
            logicMatchingValue?.id || logicMatchingValue._id
          )
        }
      })
    }
  }, [logics, options, questionKey, getValues, setValue])

  const inputRef = useRef(null)

  const onAddOption = () => {
    const string = optionTitle.trim()

    const insertOption = (title) => {
      const currentOptions = [...options]
      currentOptions.push({
        label: title,
        order: null,
        _id: uuidv4(),
        active: true
      })
      setValue(optionsKey, currentOptions)
    }

    if (!string) {
      setIsInvalidInput(true)
      return
    }

    insertOption(string)
    setOptionTitle('')
    setIsInvalidInput(false)

    _.defer(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    })
  }

  const onDragEnd = (result) => {
    const { destination, source } = result
    const newOptions = changeQuestionsOrder({
      source,
      destination,
      questionsArray: options,
      indexes
    })
    newOptions.forEach((option, index) => (option.order = index))
    setValue(optionsKey, newOptions)
  }

  const handleRemoveOption = (idx) => {
    const optionsClone = [...options]
    const option = optionsClone[idx]
    option._destroy = true
    const [removedElement] = optionsClone.splice(idx, 1)

    setValue(optionsKey, optionsClone)
    if (option.id) {
      const deletedOptionsClone = [...deletedOptions]
      deletedOptionsClone.push(removedElement)

      setValue(deletedOptionsKey, deletedOptionsClone)
    }
  }

  const handleKeyPress = (event) => {
    const { code, key } = event
    if (key === 'Enter' || code === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      onAddOption()
    }
  }

  return (
    <Box shadow="sm">
      <Flex flexDir={['column', 'column', 'row']}>
        <Box minW={['100%', '100%', '400px']}>
          <Flex py="4" px="2" align="center">
            <Input
              maxW="250px"
              placeholder="Type the option label"
              mr="3"
              size="sm"
              onChange={({ target: { value } }) => setOptionTitle(value)}
              value={optionTitle}
              isInvalid={isInvalidInput}
              ref={inputRef}
              onKeyPress={handleKeyPress}
            />
            <Button size="sm" onClick={onAddOption} colorScheme="blue">
              Add Option
            </Button>
          </Flex>
        </Box>
        <Flex w="100%" pt={0}>
          <Divider orientation="vertical" />
          <DragDropContext onDragEnd={onDragEnd}>
            <Dropzone
              id={optionsKey}
              type={optionsKey}
              isNested={false}
              fromType=""
            >
              {options.map((option, index) => {
                const optionKey = `${optionsKey}.${index}`
                const key = indexes.getUniqKey(optionKey)
                return (
                  <Option
                    key={key}
                    optionKey={optionKey}
                    control={control}
                    droppableType={optionsKey}
                    index={index}
                    handleRemoveOption={handleRemoveOption}
                    questionKey={questionKey}
                    hasActivationController
                    isActive={option.active}
                  />
                )
              })}
            </Dropzone>
          </DragDropContext>
        </Flex>
      </Flex>
    </Box>
  )
}

export default OptionsHandler
