/* eslint-disable react/display-name */
import React, { cloneElement, forwardRef, memo, useMemo } from 'react'
import PropTypes, { bool } from 'prop-types'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel'

const propsKeys = {
  direction: 'axis',
  customPrevArrow: 'renderArrowPrev',
  customNextArrow: 'renderArrowNext',
  customIndicator: 'renderIndicator',
  customItem: 'renderItem',
  customThumbs: 'renderThumbs',
  shouldDisplayArrows: 'showArrows',
  shouldDisplayStatus: 'showStatus',
  shouldDisplayIndicators: 'showIndicators',
  shouldDisplayThumbs: 'showThumbs',
  transitionTime: PropTypes.bool,
  shouldEnableKeyboardKeys: 'useKeyboardArrows'
}

const propsAdapter = (props) => {
  // it`s allow to pass a custom prop name and also injects only passed values into the lib component, keeping the original component integrity
  return Object.entries(props)
    .map(([key, value]) => [propsKeys[key] ?? key, value])
    .filter(([, value]) => value !== null)
    .reduce((acc, [key, value]) => {
      acc[key] = value
      return acc
    }, {})
}

const Carousel = forwardRef((props, ref) => {
  const { items } = props
  const carouselTransformerProps = useMemo(() => {
    return propsAdapter(props)
  }, [props])
  return (
    <ResponsiveCarousel {...carouselTransformerProps} ref={ref}>
      {items.map((item, index) => {
        return cloneElement(item, { key: index, index })
      })}
    </ResponsiveCarousel>
  )
})

Carousel.defaultProps = {
  arialLabel: '',
  direction: 'horizontal',
  autoFocus: true,
  autoPlay: true,
  centerMode: false,
  centerSlidePercentage: 0,
  dynamicHeight: false,
  emulateTouch: true,
  infiniteLoop: true,
  items: [],
  labels: null, // will get the defaultProps of the library
  onClickItem: null, //(index, currentItem) => ({}),
  onClickThumb: null, //(index, currentItem) => ({}),
  onChange: null, //(index, currentItem) => ({}),
  onSwipeStart: null, //(index, currentItem) => ({}),
  onSwipeEnd: null, //(index, currentItem) => ({}),
  onSwipeMove: null, //(index, currentItem) => ({}),
  swipeable: true,
  preventMovementUntilSwipeScrollTolerance: false,
  swipeScrollTolerance: 5,
  customPrevArrow: null, //( onClick, isTheLastOne, arialLabel ) => ({}),
  customNextArrow: null, //( onClick, isTheLastOne, arialLabel ) => ({}),
  customIndicator: null, //( onClick, isItemSelected, itemIndex, arialLabel ) => ({}),
  customItem: null, //( item, { isSelected } ) => ({}),
  customThums: null, //( shouldDisplayThumbs, items, imgsTags ) => ({}),
  selectedItem: 0,
  shouldDisplayArrows: true,
  shouldDisplayStatus: true,
  shouldDisplayIndicators: true,
  shouldDisplayThumbs: true,
  statusFormatter: null, //(currentItem, totalCount) => string
  stopOnHover: true,
  thumbWidth: 110,
  transitionTime: 3,
  shouldEnableKeyboardKeys: true,
  verticalSwipe: 'standard',
  width: '100%'
}

Carousel.propTypes = {
  arialLabel: PropTypes.string,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  autoFocus: PropTypes.bool,
  autoPlay: PropTypes.bool,
  centerMode: PropTypes.bool,
  centerSlidePercentage: PropTypes.number,
  dynamicHeight: PropTypes.bool,
  emulateTouch: PropTypes.bool, // for non touchable devices
  infiniteLoop: PropTypes.bool,
  labels: PropTypes.shape({
    leftArrow: PropTypes.string,
    rightArrow: PropTypes.string,
    item: PropTypes.string
  }),
  onClickItem: PropTypes.func,
  onClickThumb: PropTypes.func,
  onChange: PropTypes.func,
  onSwipeStart: PropTypes.func,
  onSwipeEnd: PropTypes.func,
  onSwipeMove: PropTypes.func,
  swipeable: bool,
  preventMovementUntilSwipeScrollTolerance: PropTypes.bool, // will only works if 'swipeScrollTolerance' is set,
  swipeScrollTolerance: PropTypes.number,
  customPrevArrow: PropTypes.func,
  customNextArrow: PropTypes.func,
  customIndicator: PropTypes.func,
  customItem: PropTypes.func,
  customThumbs: PropTypes.func,
  selectedItem: PropTypes.number,
  shouldDisplayArrows: PropTypes.bool,
  shouldDisplayStatus: PropTypes.bool,
  shouldDisplayIndicators: PropTypes.bool,
  shouldDisplayThumbs: PropTypes.bool,
  statusFormatter: PropTypes.func,
  stopOnHover: PropTypes.bool,
  thumbWidth: PropTypes.number,
  items: PropTypes.array,
  transitionTime: PropTypes.number,
  shouldEnableKeyboardKeys: PropTypes.bool,
  verticalSwipe: PropTypes.oneOf(['vertical', 'standard']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default memo(Carousel)
