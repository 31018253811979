import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  theme,
  useDisclosure
} from '@chakra-ui/react'
import { isNull } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import DatePckr from '../../../components/DatePicker'

const CustomDatePopover = ({ onConfirm, startDate, endDate, children }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [date, setDate] = useState([
    startDate ? startDate : new Date(),
    endDate ? endDate : new Date()
  ])
  const [initialStartDate, initialEndDate] = date

  const isConfirmationDisabled = date.some((date) => isNull(date))

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      zIndex={theme.zIndices.overlay}
      placement="right"
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent bg="white">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Select a date range</PopoverHeader>
          <PopoverBody>
            <Stack gap={2}>
              <DatePckr
                initialStartDate={initialStartDate}
                initialEndDate={initialEndDate}
                isRangePicker
                onChange={setDate}
                showTimeSelect={false}
                minW="380px"
                keepDateOnClear
                monthsShown={2}
                placeholder="Select a date range"
                leftDistance="-180px"
                omittedShortcuts={[
                  'today',
                  'yesterday',
                  'last_7_days',
                  'last_6_months',
                  'this_year',
                  'last_year'
                ]}
              />
              <Button
                size="sm"
                w="100%"
                colorScheme="green"
                isDisabled={isConfirmationDisabled}
                onClick={() =>
                  onConfirm({ name: 'custom', params: { date } }, onClose)
                }
              >
                Confirm
              </Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

CustomDatePopover.propTypes = {
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  children: PropTypes.any,
  selectedRange: PropTypes.any
}

export default CustomDatePopover
