import { AddIcon, ViewIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Tag,
  useDisclosure
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import DownloadAndUploadModal from 'components/DownloadAndUploadModal'
import ItemDownloadSidebar from 'components/DownloadAndUploadModal/ItemDownloadSidebar'
import MaterialIcon from 'components/MaterialIcon'
import SearchInput from 'components/SearchInput'
import ExportAssets from 'pages/AssetTemplates/AssetTemplatesEditor/ExportAssets'
import React from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import { getRouteUrl } from '../../helpers'
import useAssets from './hooks/useAssets'
import useAssetsImport from './hooks/useAssetsImport'

const Assets = ({ data: dependenciesData }) => {
  const {
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data,
    refetch,
    isLoading,
    pagination,
    fetchParams,
    shouldAllowAssetTypesRender,
    shouldAllowAssetsCreation,
    shouldAllowAssetsRender,
    shouldRenderBulkImportButton,
    template,
    onReplaceUrl,
    onTextChange,
    selectedAssetTemplateFilter,
    onSelectAssetTemplateFilter,
    assetTemplatesEnumerators,
    shouldFetchAssetTemplate
  } = useAssets({ data: dependenciesData })

  const {
    isImportCsvModalOpen,
    onCloseImportCsvModal,
    onOpenImportCsvModal,
    assetTemplates,
    selectedAssetTemplate,
    setSelectedAssetTemplate,
    onDownloadTemplate,
    onChangeFiles,
    onBulkImportAssets,
    files,
    isLoading: isImporting
  } = useAssetsImport({ refetch })

  const {
    isOpen: isDownloadAssetTemplateSidebarOpen,
    onOpen: onOpenDownloadAssetTemplateSidebar,
    onClose: onCloseDownloadAssetTemplateSidebar
  } = useDisclosure()

  const onSelectAssetTemplate = (value) => {
    setSelectedAssetTemplate(value)
    onCloseDownloadAssetTemplateSidebar()
  }

  return (
    <>
      <DownloadAndUploadModal
        isOpen={isImportCsvModalOpen}
        onClose={onCloseImportCsvModal}
        onOpenDownloadItemSidebar={onOpenDownloadAssetTemplateSidebar}
        onBulkImportItem={onBulkImportAssets}
        onChangeFiles={onChangeFiles}
        files={files}
        selectedItem={selectedAssetTemplate}
        onSelectItem={onSelectAssetTemplate}
        isLoading={isImporting}
      >
        <ItemDownloadSidebar
          isOpen={isDownloadAssetTemplateSidebarOpen}
          onClose={onCloseDownloadAssetTemplateSidebar}
          items={assetTemplates}
          setSelectedItem={setSelectedAssetTemplate}
          onDownloadItem={onDownloadTemplate}
        />
      </DownloadAndUploadModal>
      <Stack>
        <PageHeader
          title={
            <Flex gap={4} align="center">
              <Heading size="md">Assets</Heading>
              {shouldFetchAssetTemplate && (
                <Flex gap={2}>
                  {selectedAssetTemplateFilter?.label && (
                    <Tag size="sm" colorScheme="blue">
                      Type: {selectedAssetTemplateFilter?.label}
                    </Tag>
                  )}
                  {shouldAllowAssetsRender && (
                    <Button
                      size="sm"
                      colorScheme="blue"
                      variant="ghost"
                      leftIcon={<ViewIcon />}
                      onClick={onReplaceUrl}
                    >
                      See all assets
                    </Button>
                  )}
                </Flex>
              )}
            </Flex>
          }
          Actions={
            <Stack gap={4}>
              <Flex
                justify="flex-end"
                flexDir={['column', 'row']}
                gap={4}
                align="center"
              >
                {(selectedAssetTemplateFilter?.value || template?.id) && (
                  <ExportAssets
                    variant="all"
                    assetTemplateId={
                      selectedAssetTemplateFilter?.value || template?.id
                    }
                  />
                )}
                {shouldAllowAssetTypesRender && (
                  <Link to={getRouteUrl('assetTemplates')}>
                    <Button
                      colorScheme="orange"
                      leftIcon={<MaterialIcon icon="list" />}
                    >
                      Asset types
                    </Button>
                  </Link>
                )}
                {shouldRenderBulkImportButton && (
                  <Button
                    leftIcon={<MaterialIcon icon="drive_file_move" />}
                    colorScheme="green"
                    as={Button}
                    onClick={onOpenImportCsvModal}
                  >
                    Bulk Import
                  </Button>
                )}
                {shouldAllowAssetsCreation && (
                  <Link
                    to={
                      template?.id
                        ? getRouteUrl('newAsset', {
                            asset_template_id: template?.id
                          })
                        : getRouteUrl('newAsset')
                    }
                  >
                    <Button leftIcon={<AddIcon />} colorScheme="blue">
                      New
                    </Button>
                  </Link>
                )}
              </Flex>
              <Flex gap={4} w="fit-content" minW="400px">
                <Box mt="auto">
                  <SearchInput
                    value={fetchParams?.asset_field_value}
                    setValue={onTextChange}
                    isLoading={isLoading}
                    debounceTimeout={25}
                    placeholder="Filter by asset fields"
                  />
                </Box>
                <FormControl minWidth="400px">
                  <FormLabel>Asset Type</FormLabel>
                  <Select
                    options={assetTemplatesEnumerators}
                    value={selectedAssetTemplateFilter}
                    onChange={onSelectAssetTemplateFilter}
                    placeholder="Asset Type"
                    isClearable
                  />
                </FormControl>
              </Flex>
            </Stack>
          }
        />
        <Table
          shouldResetPage={fetchParams?.page === 1}
          isLoading={isLoading}
          data={data}
          columns={columns}
          pageCount={pagination?.last}
          totalItems={pagination?.items}
          fetchPage={refetch}
          initiallyHiddenColumns={hiddenColumns}
          persistedColumnsKey={hiddenColumnsKey}
        />
      </Stack>
    </>
  )
}

export default Assets
