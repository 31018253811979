import { AddIcon, Search2Icon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger
} from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import Autocomplete from '../../components/Autocomplete'
import FoldersTree from '../../components/FoldersTree'
import MaterialIcon from '../../components/MaterialIcon'
import { getRouteUrl } from '../../helpers'
import OlingaAPI from '../../services/OlingaAPI'
import TemplateKebab from './TemplateKebab'
import TemplateListItem from './TemplateListItem'
import useTemplates from './useTemplates'

const TemplateList = () => {
  const {
    getAutocompleteCallback,
    getFolderListCallbackParams,
    handleAutocompleteSelect,
    hasPermissionToCreateTemplate,
    isOpen,
    onAutocompleteOpen,
    pageTitle,
    singularTitle,
    templateListRef,
    onClose,
    templateReportsIcon,
    templateReportsPageTitle,
    shouldRenderReports,
    alertsIcon,
    alertsPageTitle,
    shouldRenderAlerts
  } = useTemplates()

  return (
    <FoldersTree
      ref={templateListRef}
      shouldActivateInactiveFiltering
      resourceFetchCallback={OlingaAPI.formFolderTemplatesList}
      folderListCallback={OlingaAPI.templateFormFolderList}
      resourceName="templates"
      pageName={pageTitle}
      getResources={(templates, shinyResourceId) => (
        <TemplateListItem
          templates={templates}
          shinyResourceId={shinyResourceId}
          pageTitle={singularTitle}
        />
      )}
      title={pageTitle}
      expandedResourceKey="expanded_templates_folders"
      getFolderListCallbackParams={getFolderListCallbackParams}
      Autocomplete={
        <Popover isOpen={isOpen} onOpen={onAutocompleteOpen} onClose={onClose}>
          <PopoverTrigger>
            <Button
              leftIcon={<Search2Icon />}
              variant="ghost"
              colorScheme="orange"
              size="sm"
              aria-label={`Search ${pageTitle}`}
            >
              Search {pageTitle}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody p={4}>
              <Autocomplete
                asyncCallback={getAutocompleteCallback}
                placeholder={`Search ${pageTitle}`}
                attributeName="name"
                resultEndAdornment={(template) => (
                  <TemplateKebab
                    permissions={template.userPermissions}
                    id={template.id}
                    size="xs"
                  />
                )}
                onSelect={(result) => handleAutocompleteSelect(result.id)}
                minWidth="100%"
                groupByAttribute="formFolderName"
                getNestedAttribute="attributes"
                hasFixedResults
                shouldAutofocus
                inputId="templates-autocomplete-input"
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      }
      ActionsAdornment={
        <Flex gap={4}>
          {hasPermissionToCreateTemplate && (
            <Link to={getRouteUrl('createTemplate')}>
              <Button leftIcon={<AddIcon />} colorScheme="blue">
                New
              </Button>
            </Link>
          )}
          {shouldRenderReports && (
            <Link
              to={getRouteUrl('templateReports', { reportType: 'scheduled' })}
            >
              <Button
                leftIcon={<MaterialIcon icon={templateReportsIcon} />}
                colorScheme="orange"
              >
                {templateReportsPageTitle}
              </Button>
            </Link>
          )}
          {shouldRenderAlerts && (
            <Link to={getRouteUrl('alerts')}>
              <Button
                leftIcon={<MaterialIcon icon={alertsIcon} />}
                colorScheme="orange"
              >
                {alertsPageTitle}
              </Button>
            </Link>
          )}
        </Flex>
      }
    />
  )
}

export default TemplateList
