import { useDisclosure, useMediaQuery } from '@chakra-ui/react'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { getRouteUrl } from '../../../helpers'
import { useLocalStorage } from '../../../hooks'
import OlingaAPI from '../../../services/OlingaAPI'
import AdminOlingaAPI from '../../../services/admin/AdminOlingaAPI'

const useNavbar = () => {
  const { navbar, user, userMasquerade, isAdmin } = useSelector(
    ({ globalReducer }) => globalReducer
  )
  const history = useHistory()
  const location = useLocation()

  const { deleteData, destroyStoredResources } =
    useLocalStorage('olinga_signed_user')

  const [renderKey, setRenderKey] = useState(uuidv4())

  const colors = {
    bg: navbar.bg,
    hover: navbar.hover
  }

  const { isOpen, onClose, onToggle } = useDisclosure()

  const [renderOnSmartphone] = useMediaQuery(['(max-width: 730px)'])

  const getAutocompleteCallback = (value) => {
    return OlingaAPI.searchTemplates({ params: { name: value } })
  }

  const handleAutocompleteSelect = (id) => {
    history.push(
      getRouteUrl('templateForm', {
        id
      })
    )
  }

  const onAutocompleteOpen = () => {
    debounce(() => {
      const element = document.querySelector('#autocomplete-input')
      if (element) {
        element.focus()
      }
    }, 100)()
  }

  const onToggleScrolling = () => {
    window.scrollTo(0, 0)
    onToggle()
  }

  const onBackMasquerade = async () => {
    const response = await AdminOlingaAPI.backMasquerade()

    if (response?.success) {
      window.location.href = '/sm/web/businesses'
    }
  }

  const onAdminLogout = async () => {
    deleteData('olinga_admin_signed_user')
    const response = await AdminOlingaAPI.logout()
    if (response.status === 200) {
      history.push({
        pathname: '/sm/users/sign_in'
      })
      history.go()
    }
  }

  const onSignOut = () => {
    if (user.isAdmin) {
      onAdminLogout()
    } else {
      deleteData('olinga_signed_user')
      destroyStoredResources()
      window.location.pathname = '/users/sign_out'
    }
  }

  useEffect(() => {
    setRenderKey(uuidv4())
  }, [location.pathname, setRenderKey])

  return {
    navbar,
    handleAutocompleteSelect,
    getAutocompleteCallback,
    renderOnSmartphone,
    userName: user.email,
    isAdmin: user?.isAdmin || isAdmin,
    userMasquerade,
    isOpen,
    onClose,
    onToggle: onToggleScrolling,
    colors,
    onAutocompleteOpen,
    renderKey,
    onSignOut,
    onBackMasquerade
  }
}

export default useNavbar
