import { useTemplateEditorContext } from 'contexts'
import { isFunction, isString, keys } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const useLogicOperations = ({ currentKey, questionType, parentKey }) => {
  const { logicOptions, focusedField, handleFocusedField } =
    useTemplateEditorContext()

  const { control, setValue } = useFormContext()
  const [optionId, currentValue] = useWatch({
    name: [`${currentKey}.question_logic_option_id`, `${currentKey}.value`],
    control
  })

  const options = useMemo(() => {
    if (keys(logicOptions).includes(questionType)) {
      return logicOptions[questionType]
    }
    return []
  }, [logicOptions, questionType])

  useEffect(() => {
    if (['scale', 'counter'].includes(questionType)) {
      if (
        currentValue &&
        isFunction(currentValue?.match) &&
        currentValue?.match(';')
      ) {
        setValue(`${currentKey}.value`, '')
        setValue(`${currentKey}.aux_options`, [])
      }
    }
  }, [questionType, setValue, currentKey, currentValue])

  const shouldKeepFocused = useMemo(() => {
    return isString(focusedField) ? !!focusedField.match(currentKey) : false
  }, [currentKey, focusedField])

  const isFocused = useMemo(() => {
    return shouldKeepFocused || currentKey === focusedField
  }, [currentKey, focusedField, shouldKeepFocused])

  const getOptionLabel = () => {
    if (optionId) {
      return (
        logicOptions[questionType]?.find(
          (option) => Number(option.id) === Number(optionId)
        )?.attributes?.text || ''
      )
    }
    return ''
  }

  const onChangeOptions = (value) => {
    // a monkey patch to avoid useForm to detect empty options that prevents form to be submitted
    const mappedValue = `[VALUE]`
    setValue(`${currentKey}.aux_value`, mappedValue)
    setValue(`${currentKey}.value`, mappedValue)
    setValue(`${currentKey}.aux_options`, value)
  }

  const handleFocus = (event, shouldHide = false) => {
    if (event) {
      event.stopPropagation()
    }
    if (shouldHide) {
      handleFocusedField(parentKey)
    } else {
      handleFocusedField(currentKey)
    }
  }

  return {
    onChangeOptions,
    getOptionLabel,
    isFocused,
    options,
    handleFocus
  }
}

export default useLogicOperations
