const actions = {
  templates: {
    SET_EXPANDED_FOLDERS: 'SET_EXPANDED_FOLDERS',
    SET_SHOULD_RELOAD_FOLDERS: 'SET_SHOULD_RELOAD_FOLDERS'
  },
  edit_template: {
    SET_DEPENDENCIES: 'SET_DEPENDENCIES',
    SET_SHOULD_FETCH_AGAIN: 'SET_SHOULD_FETCH_AGAIN',
    SET_ASSETS_TEMPLATES: 'SET_ASSETS_TEMPLATES',
    SET_IS_LOADING: 'SET_IS_LOADING'
  },
  dependencies: {
    SET_CACHED_DEPENDENCIES: 'SET_CACHED_DEPENDENCIES'
  },
  global: {
    SET_GLOBAL_SETTINGS: 'SET_GLOBAL_SETTINGS'
  }
}

export default actions
