/* eslint-disable no-async-promise-executor */
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  SimpleGrid,
  Stack,
  Switch,
  Text
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { capitalize, startCase } from 'lodash'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { Link } from 'react-router-dom'
import EmptyState from '../../../components/EmptyState'
import FormField from '../../../components/FormField'
import FormSubmitActions from '../../../components/FormSubmitActions'
import EditInline from '../../../components/Inputs/EditInline'
import LazyComponent from '../../../components/LazyComponent'
import ListBuilder from '../../../components/ListBuilder'
import PageHeader from '../../../components/PageHeader'
import { getRouteUrl } from '../../../helpers'
import UsersSelector from '../../../pages/Form/FillForm/ActionsBar/ActionSidebar/UsersSelector'
import { frequencies } from '../List/constants'
import ReportDownloadButton from '../ReportDownloadButton'
import ReportResendButton from '../ReportResendButton'
import { formattedValues } from './helpers'
import ReportEditorBreadcrumb from './ReportEditorBreadcrumb'
import ReportEditorSidebar from './ReportEditorSidebar'
import ReportItem from './ReportItem'
import useReportsEditor from './useReportsEditor'

const ReportsEditor = ({ data }) => {
  const {
    onEmailsSelect,
    onFormFieldKeyClean,
    onRemoveReport,
    onAddReport,
    onSubmit,
    handleBodyQuestionsChange,
    withZero,
    title,
    emails,
    queryRange,
    structure,
    templateName,
    fields,
    isMobile,
    rangeFieldKey,
    formFieldKey,
    isSubmitting,
    methods,
    ref,
    headerQuestions,
    bodyQuestions,
    id,
    formFrequency,
    templateQuestions,
    setFormFieldKey,
    pageTitle,
    singularTitle,
    isReadOnly
  } = useReportsEditor({ data })

  const { setValue, watch, register } = methods

  const [frequency] = watch(['frequency'])

  if (!data?.template) {
    return (
      <EmptyState
        text={`You have to select a template to create a ${singularTitle}`}
        Action={
          <Link
            to={getRouteUrl('templateReports', { reportType: 'scheduled' })}
          >
            <Button colorScheme="gray">Go to {pageTitle} list</Button>
          </Link>
        }
      />
    )
  }

  return (
    <FormProvider {...methods}>
      <ReportEditorSidebar
        ref={ref}
        counterType={data.enumerators.counter_type}
        reportType={data.enumerators.report_chart_type}
        contexts={data.enumerators.context}
        headerQuestions={headerQuestions}
        bodyQuestions={bodyQuestions}
        formFieldKey={formFieldKey}
        onFormFieldKeyClean={onFormFieldKeyClean}
        structure={structure}
      />
      <Stack>
        <PageHeader
          title={`${
            id
              ? isReadOnly
                ? `View ${singularTitle}`
                : `Edit ${singularTitle}`
              : `New ${singularTitle}`
          }`}
          Breadcrumb={
            <ReportEditorBreadcrumb
              fields={fields}
              singularTitle={singularTitle}
              pageTitle={pageTitle}
              reportType="scheduled"
            />
          }
          Actions={
            id &&
            !isReadOnly && (
              <Flex gap={2}>
                <ReportDownloadButton
                  id={id}
                  variant="default"
                  title={fields?.title}
                  isDisabled={!fields?.title}
                  label={`Download ${singularTitle}`}
                  reportType="scheduled"
                />
                <ReportResendButton
                  id={id}
                  emails={fields?.emails}
                  label={`Resend ${singularTitle}`}
                  reportType="scheduled"
                />
              </Flex>
            )
          }
        />
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            {templateName && (
              <Box w="100%">
                <Text>Template: {templateName}</Text>
              </Box>
            )}
            <FormField label={`${singularTitle} Title`}>
              <Box w={isMobile ? '100%' : '60%'}>
                <LazyComponent condition={id ? fields?.title : true}>
                  <EditInline
                    placeholder={`${singularTitle} Title`}
                    defaultValue={fields?.title}
                    startWithEditView={!title}
                    autoFocus={!title}
                    fontSize="md"
                    registerProps={{
                      ...methods.register('title')
                    }}
                    isDisabled={isReadOnly}
                  />
                </LazyComponent>
              </Box>
            </FormField>
            <SimpleGrid columns={2} gap={4}>
              <FormField
                label="Frequency"
                infoLabel={
                  formFrequency?.value ? frequencies[formFrequency?.value] : ''
                }
              >
                <Select
                  {...register('query_range', { required: true })}
                  options={data.enumerators.frequency}
                  onChange={(option) => {
                    setValue('frequency', option)
                    setValue('query_range', null)
                  }}
                  value={formFrequency}
                  isDisabled={isReadOnly}
                />
              </FormField>

              <FormField label="Range">
                <Select
                  {...register('query_range', { required: true })}
                  key={rangeFieldKey}
                  value={queryRange}
                  options={(
                    frequency?.ranges ||
                    data?.enumerators?.frequency?.find(
                      ({ value }) => value === frequency?.value
                    )?.ranges
                  )?.map((range) => ({
                    label: startCase(capitalize(range)),
                    value: range
                  }))}
                  onChange={(option) => {
                    setValue('query_range', option)
                  }}
                  isDisabled={isReadOnly}
                />
              </FormField>
            </SimpleGrid>
            <FormField label="Emails">
              <UsersSelector
                defaultUsers={formattedValues(data?.report?.attributes?.fields?.emails) || emails}
                onSelect={onEmailsSelect}
                closeMenuOnSelect
                requestParams={[true, { show_email: true }]}
                isDisabled={isReadOnly}
              />
            </FormField>
            <Divider pt={2} />
            <Box w="100%">
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="with-zero" mb="0">
                  Include values with zero
                </FormLabel>
                <Switch
                  isReadOnly={isReadOnly}
                  id="with-zero"
                  mb={0}
                  isChecked={withZero}
                  onChange={({ target: { checked } }) =>
                    methods.setValue('with_zero', checked)
                  }
                />
              </FormControl>
            </Box>
            <Divider pt={2} />
            <FormField label={`${singularTitle} items`}>
              <>
                <ListBuilder
                  list={templateQuestions}
                  resourceName={`${singularTitle} item`}
                  onChange={(list) => handleBodyQuestionsChange(list)}
                  ItemComponent={ReportItem}
                  onAddItem={onAddReport}
                  onRemoveItem={onRemoveReport}
                  idAttribute="_id"
                  itemProps={{
                    fieldName: 'template_questions',
                    formFieldKey,
                    headerQuestions,
                    bodyQuestions,
                    setFormFieldKey,
                    sidebarRef: ref,
                    isDisabled: isReadOnly
                  }}
                  deleteButtonStyles={() => ({ position: 'relative' })}
                  customRemoveItemAction={(index, _, fallback) =>
                    fallback(index)
                  }
                  isDisabled={isReadOnly}
                  allowDelete={() => !isReadOnly}
                />
              </>
            </FormField>
            <FormSubmitActions
              margin={4}
              isLoading={isSubmitting}
              cancelRoute="templateReports"
              routeParams={{ reportType: 'scheduled' }}
              isDisabled={isReadOnly}
            />
          </Stack>
        </form>
      </Stack>
    </FormProvider>
  )
}

export default ReportsEditor
