import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const Container = styled(Flex)`
  background-color: #f5f5f7;
  width: 100%;
  height: 100px;
  min-height: 100px;
  border-radius: 5px;
  padding: 5px;
  color: black;

  &:hover {
    background-color: #e5e5e5;
  }

  ${({ read }) => `
    cursor: ${read === 'true' ? 'default' : 'pointer'};
  `}
`

export const StatusWrapper = styled(Flex)`
  flex-direction: column;
  align-items: start;
  align-items: center;
  gap: 20px;
  min-width: 30px;
  width: 30px;
  margin-top: 5px;

  a {
    margin-right: 6px;
  }
`

export const TypeWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  min-width: 40px;
  width: 40px;
`
