import React from 'react'
import PropTypes from 'prop-types'
import OperationsList from '../../../../../../components/OperationsList'
import { calculatedFieldsEnumTypes, templateStructureTypes } from '../constants'
import { noop } from 'lodash'

const CalculatedField = ({
  operations,
  structure,
  id,
  currentKey,
  setValue,
  watch
}) => {
  return (
    <OperationsList
      structure={structure}
      operations={operations}
      watch={watch}
      setValue={setValue}
      id={id}
      attributeKey={currentKey}
    />
  )
}

CalculatedField.defaultProps = {
  operations: [],
  structure: {
    complete: {},
    linear: {
      data: [],
      object: {}
    }
  },
  id: null,
  currentKey: '',
  setValue: noop,
  watch: noop
}

CalculatedField.propTypes = {
  operations: calculatedFieldsEnumTypes,
  structure: templateStructureTypes,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentKey: PropTypes.string,
  setValue: PropTypes.func,
  watch: PropTypes.func
}

export default CalculatedField
