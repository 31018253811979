import { Flex, Heading, Tooltip, theme } from '@chakra-ui/react'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import MaterialIcon from '../../../../MaterialIcon'

const ResultLine = ({
  results,
  attributeName,
  isGrouped,
  handleSelect,
  resultEndAdornment,
  onClose
}) => {
  if (isEmpty(results)) {
    return null
  }

  return (
    <>
      {results.map((result) => {
        const name = get(result, attributeName)
        return (
          <Flex
            borderBottom="1px"
            borderBottomColor="gray.300"
            p={4}
            align="center"
            justify="space-between"
            key={result.id}
            backgroundColor="white"
            maxW="100%"
          >
            <Flex align="center" maxW="calc(100% - 60px)">
              {isGrouped && (
                <MaterialIcon icon='subdirectory_arrow_right' styles={{ color: theme.colors.blue[400]}} />
              )}
              <Tooltip hasArrow label={name}>
                <Heading
                  onClick={() => handleSelect(result)}
                  size="sm"
                  color="black"
                  isTruncated
                  ml={isGrouped ? 2 : 0}
                  _hover={{
                    color: '#FF871C',
                    transition: 'all 0.2s ease',
                    cursor: 'pointer'
                  }}
                >
                  <span data-testid='autocomplete-result-name'>{name}</span>
                </Heading>
              </Tooltip>
            </Flex>
            {resultEndAdornment && resultEndAdornment(result, onClose)}
          </Flex>
        )
      })}
    </>
  )
}

ResultLine.propTypes = {
  results: PropTypes.any,
  attributeName: PropTypes.string,
  isGrouped: PropTypes.bool,
  handleSelect: PropTypes.func,
  resultEndAdornment: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.number
  ]),
  onClose: PropTypes.func
}

export default ResultLine
