import { Button, Checkbox, MenuItem, Stack } from '@chakra-ui/react'
import MaterialIcon from 'components/MaterialIcon'
import React, { useState } from 'react'
import Dialog from '../../../../components/AlertDialog'

const INCLUDE_INSTRUCTIONS = 'include_instructions'
const INCLUDE_NOTES = 'include_notes'

const ExportPDFAction = ({ onConfirm, inline, size, prefix }) => {
  const [exportOptions, setExportOptions] = useState([
    INCLUDE_INSTRUCTIONS,
    INCLUDE_NOTES
  ])

  const updateOptions = (value, key) => {
    if (!value) {
      setExportOptions((prevValue) => prevValue.filter((v) => v !== key))
    } else {
      const newValue = exportOptions
      newValue.push(key)
      setExportOptions([...newValue])
    }
  }

  const Body = () => (
    <Stack>
      <Checkbox
        isChecked={exportOptions?.includes(INCLUDE_INSTRUCTIONS)}
        onChange={({ target: { checked } }) =>
          updateOptions(checked, INCLUDE_INSTRUCTIONS)
        }
      >
        Include instructions
      </Checkbox>
      <Checkbox
        isChecked={exportOptions?.includes(INCLUDE_NOTES)}
        onChange={({ target: { checked } }) =>
          updateOptions(checked, INCLUDE_NOTES)
        }
      >
        Include response notes
      </Checkbox>
    </Stack>
  )

  const onCloseDialog = (onClose) => {
    setExportOptions([INCLUDE_INSTRUCTIONS, INCLUDE_NOTES])
    onClose()
  }

  const label = `${prefix} as PDF`

  return (
    <Dialog
      body={<Body />}
      header={`${prefix} PDF Options`}
      continueColor="green"
      handleClose={onCloseDialog}
      onContinue={(onClose) => {
        onCloseDialog(onClose)
        onConfirm('pdf', { export_options: exportOptions })
      }}
    >
      {inline ? (
        <Button
          leftIcon={<MaterialIcon icon="picture_as_pdf" />}
          colorScheme="red"
          size={size}
        >
          {label}
        </Button>
      ) : (
        <MenuItem
          alignItems="center"
          icon={
            <MaterialIcon icon="picture_as_pdf" styles={{ fontSize: '20px' }} />
          }
        >
          {label}
        </MenuItem>
      )}
    </Dialog>
  )
}

export default ExportPDFAction
