import { Box } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const List = ({ field, hasPermissionToEdit, index, field: { main } }) => {
  const { setValue, control } = useFormContext()
  return (
    <Controller
      name={`asset_fields_attributes.${index}.value`}
      rules={{
        required: main
      }}
      control={control}
      render={({ field: formField, formState: { errors } }) => (
        <Box
          sx={{
            '& button': {
              pointerEvents:
                !field?._active || !hasPermissionToEdit ? 'none' : 'auto'
            }
          }}
        >
          <Select
            options={field.options}
            onChange={(values) =>
              setValue(`asset_fields_attributes.${index}.value`, values)
            }
            defaultValue={formField.value}
            key={formField.value}
            hideSelectedOptions
            placeholder="List type"
            isInvalid={!!errors?.asset_fields_attributes?.index?.value?.message}
            isDisabled={!field._active || !hasPermissionToEdit}
          />
        </Box>
      )}
    />
  )
}

export default List
