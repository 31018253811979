import { Box, Center } from '@chakra-ui/react'
import { FixedSizeList as List } from 'react-window'
import React from 'react'

const VirtualizedList = ({ items, getItemBody }) => {
  const ItemRow = ({ index, style }) => (
    <div style={style} key={index}>
      {getItemBody(items[index])}
    </div>
  )

  const getVirtualizedList = () => {
    if (!items?.length) {
      return (
        <Box h="30px">
          <Center h="100%">No data</Center>
        </Box>
      )
    }
    return (
      <List height={450} itemCount={items.length} width="100%" itemSize={30}>
        {ItemRow}
      </List>
    )
  }
  return getVirtualizedList()
}

export default VirtualizedList
