import { Box, Checkbox } from '@chakra-ui/react'
import React from 'react'

const ListUser = ({ style, user, handleCheckUser, isChecked }) => {
  return (
    <div style={style}>
      <Box h="50px" p="1">
        <Checkbox
          isChecked={isChecked}
          mb="0"
          fontWeight="normal"
          onChange={() => handleCheckUser(user.id, user)}
        >
          {user.attributes.email}
        </Checkbox>
      </Box>
    </div>
  )
}

export default ListUser
