import { AttachmentIcon, EditIcon } from '@chakra-ui/icons'
import { Flex, IconButton } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { getRouteUrl } from '../../../helpers'

const DocumentsActions = (props) => {
  const { id, userPermissions, url } = props
  return (
    <Flex gap={4}>
      <Link
        to={{
          pathname: getRouteUrl('editDocument', { id }),
          from: 'documentsLibrary'
        }}
      >
        {userPermissions.edit && (
          <IconButton
            icon={<EditIcon />}
            colorScheme="blue"
            size="sm"
            variant="outline"
          />
        )}
      </Link>
      <a href={url} rel="noreferrer noopener" target="_blank">
        <IconButton
          icon={<AttachmentIcon />}
          colorScheme="teal"
          size="sm"
          variant="outline"
        />
      </a>
    </Flex>
  )
}

export default DocumentsActions
