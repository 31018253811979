import { Box, Flex, theme, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import AllFoldersButton from '../AllFoldersButton'
import ShowAllCheckbox from '../ShowAllCheckbox'

const StickyActions = ({
  shouldRenderStickyActions,
  isLoadingFolders,
  isLoadingAllResources,
  shouldDisplayAllResources,
  handleActiveFilter,
  ActionsAdornment,
  handleFetchAllResources,
  shouldDisplayAllLoadedFoldersButton,
  handleDisplayLoadedFolders,
  pageName
}) => {
  const [isMobile] = useMediaQuery('(max-width: 799px)')
  const [shouldWrapButtons] = useMediaQuery('(max-width: 576px)')
  return (
    <Box
      p={4}
      w="100%"
      position="fixed"
      top={isMobile ? 'unset' : '60px'}
      bottom={isMobile ? '0px' : 'unset'}
      left="0"
      bg={theme.colors.white}
      borderBottomWidth={1}
      borderTopWidth={1}
      borderBottomColor={theme.colors.gray[200]}
      borderTopColor={theme.colors.gray[200]}
      zIndex="100"
      visibility={shouldRenderStickyActions ? 'visible' : 'hidden'}
      shadow="md"
    >
      <Flex
        w="100%"
        align="center"
        justify={isMobile ? 'space-between' : 'flex-end'}
        flexWrap="wrap"
      >
        <ShowAllCheckbox
          isDisabled={isLoadingFolders || isLoadingAllResources}
          isChecked={shouldDisplayAllResources}
          onChange={({ target: { checked } }) => handleActiveFilter(checked)}
          alignSelf={isMobile ? 'center' : 'flex-start'}
          mb={isMobile ? 4 : 0}
          pageName={pageName}
        />
        {ActionsAdornment && ActionsAdornment}
        <AllFoldersButton
          isLoading={isLoadingAllResources}
          loadedLabel="All folders"
          label="Open all folders"
          onClick={handleFetchAllResources}
          icon="folder_copy"
          isOutlinedIcon
          ml={isMobile && shouldRenderStickyActions ? 0 : 4}
        />
        {shouldDisplayAllLoadedFoldersButton && (
          <AllFoldersButton
            loadedLabel="All loaded folders"
            label="Open all loaded folders"
            onClick={handleDisplayLoadedFolders}
            icon="drive_folder_upload"
            ml={isMobile && shouldRenderStickyActions ? 0 : 4}
            mt={shouldWrapButtons && !shouldRenderStickyActions ? 4 : 0}
          />
        )}
      </Flex>
    </Box>
  )
}

export default StickyActions
