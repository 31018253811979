import { DownloadIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { Button, Tooltip } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { noop } from 'lodash';
import React from 'react';
import api, { getCSRFToken, handleErrors } from 'services/api';
import colors from 'utils/colors';

export const getIcon = () => {
  return <InfoOutlineIcon color={colors.primary} />;
};

export const saveToFile = (url, fileName) => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_self';
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const downloadData = (response, name, type = 'xlsx') => {
  if (!response?.data) return null;

  const fileName = response.headers['content-disposition']
    ? response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
    : name;
  const blobURL = window.URL.createObjectURL(response.data);

  saveToFile(blobURL, fileName, type);
};

export const requestDownloadFromURL = ({ url, name }) => {
  return api
    .get(url, {
      responseType: 'blob',
      headers: {
        ...getCSRFToken(),
        responseType: 'blob',
      },
    })
    .then((response) => {
      downloadData(response, name);

      return response;
    })
    .catch((e) => {
      captureException(e);
      handleErrors(e).then();
    });
};

export const DownloadFileUrl = ({ url, executeBeforeAction }) => {
  if (url) {
    return (
      <Tooltip size="lg" label="Download">
        <Button
          variant="link"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            executeBeforeAction();
            requestDownloadFromURL({ url }).then();
          }}
          rightIcon={<DownloadIcon color={colors.primary} />}
        />
      </Tooltip>
    );
  }

  return null;
};

export const getAction = ({ data, executeBeforeAction = noop }) => {
  return <DownloadFileUrl url={data.fileUrl} executeBeforeAction={executeBeforeAction} />
};

export const requestDocument = ({ url }) => {
  return api
    .get(url, {
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((e) => {
      captureException(e);
      handleErrors(e);
    });
};

const onDownloadClick = async ({ url }) => {
  const exportData = await requestDocument({ url });
  downloadData(exportData);
};

export const getActionFunc = () => {
  return onDownloadClick
};
