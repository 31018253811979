import React, { useState } from 'react'
import SearchInput from '../../../../../../components/SearchInput'
import { useKeyPress } from '../../../../../../hooks'
import OlingaAPI from '../../../../../../services/OlingaAPI'
import { transformUsers } from '../../helpers'

const UsersSelector = ({
  defaultUsers,
  onSelect,
  closeMenuOnSelect,
  requestParams,
  fetchCallback,
  isDisabled
}) => {
  const [userName, setUserName] = useState('')
  const handleSelect = (users) => {
    onSelect(users)
  }

  useKeyPress('Enter', false, true)
  return (
    <SearchInput
      value={userName}
      setValue={setUserName}
      mechanism="multi_select"
      fetchCallback={fetchCallback}
      requestParams={requestParams}
      onSelect={handleSelect}
      defaultValue={defaultUsers}
      closeMenuOnSelect={closeMenuOnSelect}
      transformCallback={transformUsers}
      maxWidth="100%"
      placeholder="Type a name to search users"
      isDisabled={isDisabled}
    />
  )
}

UsersSelector.defaultProps = {
  defaultUsers: [],
  closeMenuOnSelect: true,
  transformCallback: null,
  requestParams: [],
  fetchCallback: OlingaAPI.staffSearch,
  isDisabled: false
}

export default UsersSelector
