import { useMediaQuery } from '@chakra-ui/react'
import { defer } from 'lodash'
import {
  convertAttributesToSubmit,
  LocalIndex
} from 'pages/Form/FillForm/helpers'
import { IndexMap } from 'pages/TemplateEditor/helpers'
import { useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Notification from 'services/Notification'
import OlingaAPI from 'services/OlingaAPI'

const useRootForm = ({ template, geolocation }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const user = useSelector(({ globalReducer }) => globalReducer.user)

  const methods = useForm({
    defaultValues: null,
    criteriaMode: 'all',
    mode: 'onChange'
  })
  const { handleSubmit, control, reset } = methods
  const { fields } = useFieldArray({
    control,
    name: 'children'
  })

  const indexes = useMemo(() => {
    return new IndexMap()
  }, [])

  useEffect(() => {
    reset(template)
  }, [template, reset])

  const [isMobile] = useMediaQuery('(max-width: 730px)')

  const localIndex = useMemo(() => new LocalIndex(template.id), [template.id])

  const onSubmit = async (submitData) => {
    try {
      setIsLoadingSubmit(true)
      const formData = convertAttributesToSubmit(submitData, geolocation, user)
      const result = await OlingaAPI.submitForm(template.id, formData)

      if (result?.status === 200) {
        Notification({
          icon: 'success',
          text: result?.data?.message
        })

        localIndex.reset()
        window.scrollTo(0, 0)
        window.location.reload()
      }
    } catch (error) {
      //
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const onError = () => {
    Notification({
      icon: 'error',
      text: 'Please answer the compulsory questions'
    })

    defer(() => {
      const element = document.querySelector('body')

      element.scrollIntoView({
        behavior: 'smooth',
        block: isMobile ? 'start' : 'center'
      })
    })
  }

  return {
    indexes,
    fields,
    isLoadingSubmit,
    isMobile,
    localIndex,
    methods,
    handleSubmit,
    onSubmit,
    onError
  }
}

export default useRootForm
