/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

/*
  Use example: const targetKey = useKeyPress('Enter')
  useEffect(() => {
    if (targetKey) {
      // do something
    }
  }, [targetKey])
*/

const useKeyPress = (
  targetKey,
  shouldStopPropagation = false,
  shouldPreventDefault = false
) => {
  const [keyPressed, setKeyPressed] = useState(false)

  function downHandler(event) {
    const { key } = event
    if (key === targetKey) {
      if (shouldPreventDefault) {
        event.preventDefault()
      }
      if (shouldStopPropagation) {
        event.stopPropagation()
      }
      setKeyPressed(true)
    }
  }
  const upHandler = (event) => {
    const { key } = event
    if (key === targetKey) {
      if (shouldPreventDefault) {
        event.preventDefault()
      }
      if (shouldStopPropagation) {
        event.stopPropagation()
      }
      setKeyPressed(false)
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])
  return keyPressed
}

export default useKeyPress
