import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  CloseButton,
  Divider,
  Flex,
  Heading,
  Stack,
  Text
} from '@chakra-ui/react'
import GoogleMapReact from 'google-map-react'
import React, { useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import {
  DEFAULT_LOCATION,
  DEFAULT_ZOOM,
  MAPS_KEY
} from '../../const/maps_const'
import FormDetails from '../../pages/Form/FormDetails'

const GoogleMaps = ({ places, template }) => {
  const { initialPosition, initialZoom } = useMemo(() => {
    let location = DEFAULT_LOCATION
    const placeWithLocation = (places ?? [])?.find(
      ({ latitude, longitude }) => !!latitude && !!longitude
    )
    if (placeWithLocation) {
      const { latitude: lat, longitude: lng } = placeWithLocation
      location = { lat: Number(lat), lng: Number(lng) }
    }

    return {
      initialZoom: DEFAULT_ZOOM,
      initialPosition: location
    }
  }, [places])

  const [showDetails, setShowDetails] = useState(false)
  const [formId, setFormId] = useState(null)

  const handleDisplayDetails = (formId) => {
    setShowDetails((prev) => !prev)
    setFormId(formId)
  }

  const getInfoWindowString = (place) => (
    <Box onClick={() => handleDisplayDetails(place.formId)}>
      <Button
        leftIcon={<ExternalLinkIcon />}
        variant="outline"
        colorScheme="orange"
      >
        <Heading size="xs" isTruncated>
          {`Form #${place.formId}`}
        </Heading>
      </Button>
      <Text size="sm">{`${place.employee} - ${place.dateTime}`}</Text>
    </Box>
  )

  const handleApiLoaded = (map, maps) => {
    if (places.length > 0) {
      const markers = []
      const infowindows = []

      places.forEach((place) => {
        markers.push(
          new maps.Marker({
            position: {
              lat: parseFloat(place.latitude),
              lng: parseFloat(place.longitude)
            },
            map
          })
        )

        const div = document.createElement('div')
        const infowindow = new maps.InfoWindow()

        ReactDOM.render(getInfoWindowString(place), div)
        infowindow.setContent(div)

        infowindows.push(infowindow)
      })

      markers.forEach((marker, i) => {
        marker.addListener('click', () => {
          infowindows[i].open(map, marker)
          infowindows[i].setZIndex(1000)

          map.setZoom(20)
          map.setCenter(marker.getPosition())
        })
      })
    }
  }

  const getContent = () => {
    if (showDetails) {
      return (
        <Stack>
          <Flex align="center" justify="space-between">
            <Heading size="sm" isTruncated>
              Form Details
            </Heading>
            <CloseButton onClick={() => setShowDetails(false)} />
          </Flex>
          <Divider />
          <FormDetails
            templateId={template.id}
            formId={formId}
            templateName={template.templateName}
          />
        </Stack>
      )
    }
    return (
      <GoogleMapReact
        defaultZoom={initialZoom}
        defaultCenter={initialPosition}
        bootstrapURLKeys={{ key: MAPS_KEY }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        style={{ height: 'inherit', width: '100%' }}
      />
    )
  }

  return <>{getContent()}</>
}

export default GoogleMaps
