import { useDisclosure } from '@chakra-ui/react'
import {
  differenceBy,
  isBoolean,
  isEmpty,
  isObject,
  isString,
  noop,
  omit,
  startCase
} from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { globalEvents } from '../../../../../contexts/GlobalStateContext/events'
import { getRouteUrl, setFormErrors } from '../../../../../helpers'
import {
  useGlobalEventListener,
  usePageProperties,
  useResize
} from '../../../../../hooks'
import Notification from '../../../../../services/Notification'
import OlingaAPI from '../../../../../services/OlingaAPI'
import { transformOptions } from '../../Fields/hooks/useAssetTemplateFields'
import { reorderList } from '../../helpers'

export const iconsTransformer = {
  text: 'text_format',
  number: 'calculate',
  list: 'list_alt',
  date: 'calendar_today'
}

const useAssetTemplateEditor = ({ data, id }) => {
  const { assetTemplate, assetTypes, fieldTypes, defaultFields } =
    useMemo(() => {
      const { asset_types, field_types, default_fields } = data?.enumerators
        ?.data ?? {
        asset_types: [],
        field_types: [],
        default_fields: []
      }
      if (id && data?.assetTemplate) {
        const assetTemplate = {
          id,
          ...(data?.assetTemplate?.data?.attributes ?? {})
        }
        return {
          assetTemplate,
          assetTypes: asset_types,
          fieldTypes: field_types,
          defaultFields: default_fields.map((field) => ({
            ...field
          }))
        }
      }
      return {
        assetTemplate: null,
        assetTypes: asset_types,
        fieldTypes: field_types,
        defaultFields: default_fields.map((field) => ({
          ...field
        }))
      }
    }, [data, id])

  const history = useHistory()

  const { navbarHeight } = useResize()

  const [breadcrumbName, setBreadcrumbName] = useState(
    assetTemplate?.name ?? ''
  )

  const [shouldRenderForm, setShouldRenderForm] = useState(!id)
  const [editInlineRenderKey, setEditInlineRenderKey] = useState(uuidv4())
  const [isLoading, setIsLoading] = useState(false)
  const [selectedDefaultMainField, setSelectedDefaultMainField] = useState(null)

  const {
    isOpen: isMainFieldSelectorOpen,
    onOpen: onOpenMainFieldSelector,
    onClose: onCloseMainFieldSelector
  } = useDisclosure()

  const fieldsRefs = useRef({})

  const formProps = useForm({
    defaultValues: {
      name: '',
      asset_type_id: null,
      active: true,
      asset_template_fields_attributes: []
    }
  })

  const { handleSubmit, reset, watch, setValue, getValues } = formProps

  useEffect(() => {
    if (assetTemplate?.id) {
      const form = {
        ...assetTemplate,
        asset_type_id: assetTemplate.asset_type?.id,
        asset_template_fields_attributes: (
          assetTemplate.asset_template_fields ?? []
        ).map((field) => ({
          ...field,
          uuid: uuidv4(),
          notify_days: `${field?.notify_days ?? '30'}`,
          notify_field_users_attributes: (field?.notify_field_users).map(
            (f) => ({
              ...f,
              user_id: f.user.id,
              name: f.user.name,
              label: f.user.name,
              value: f.user.id
            })
          ),
          settings: {
            list_options: (field?.settings?.list_options ?? []).map(
              (option) => ({
                name: isObject(option)
                  ? option?.name
                  : isString(option)
                  ? option
                  : '',
                _id: uuidv4()
              })
            )
          }
        }))
      }
      reset(form)

      setAllDefaultFields()
      setShouldRenderForm(true)
    } else {
      setAllDefaultFields()
      // use onOpenMainFieldSelector() here for re-use the modal selection strategy
    }
  }, [assetTemplate, onOpenMainFieldSelector, reset, setAllDefaultFields])

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)
      if (
        !data?.asset_template_fields_attributes ||
        isEmpty(data?.asset_template_fields_attributes)
      ) {
        return Notification({
          icon: 'error',
          text: 'An asset type must have with at least one field'
        })
      }
      const response = await OlingaAPI.newAssetTemplate(null, {
        ...data,
        asset_template_fields_attributes: (
          data?.asset_template_fields_attributes ?? []
        ).map((field) => {
          return {
            ...omit(field, ['uuid']),
            settings: {
              list_options: (field?.settings?.list_options ?? [])?.map(
                (option) => {
                  return isObject(option)
                    ? option?.name
                    : isString(option)
                    ? option
                    : ''
                }
              )
            }
          }
        })
      })
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: 'Success'
        })

        history.push(getRouteUrl('assetTemplates'))
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmitForm = () => handleSubmit(onSubmit)()

  const formValues = watch()

  const onEditField = useCallback(
    async (fieldName, values) => {
      let shouldSave = !!id
      if (!id) {
        setValue(fieldName, values)
        return
      }
      if (['asset_type_id', 'name'].includes(fieldName)) {
        if (fieldsRefs.current[fieldName] !== values) {
          if (!values) {
            setValue(fieldName, fieldsRefs.current[fieldName])
            if (fieldName === 'name') {
              setEditInlineRenderKey(uuidv4())
            }
            Notification({
              icon: 'error',
              text: `${startCase(fieldName)} can't be blank`
            })
            shouldSave = false
          }
        } else {
          shouldSave = true
        }
      }
      if (!shouldSave) {
        return
      }
      setValue(fieldName, values)
      try {
        setIsLoading(true)
        const response = await OlingaAPI.updateAssetTemplate(id, {
          [fieldName]: values
        })
        if (response.status === 200) {
          if (fieldName === 'name') {
            setBreadcrumbName(values)
          }
          return Notification({
            icon: 'success',
            text: response?.data?.message || 'Asset Type Saved Successfully'
          })
        }
      } catch (error) {
        setFormErrors(error, noop, Notification)
        throw error
      } finally {
        setIsLoading(false)
      }
    },
    [id, setValue]
  )

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const fields = getValues('asset_template_fields_attributes')

    const reorderedList = reorderList({
      list: fields ?? [],
      startIndex: result.source.index,
      endIndex: result.destination.index
    })

    setValue('asset_template_fields_attributes', reorderedList)

    if (id) {
      await onEditField(
        'asset_template_fields_attributes',
        reorderedList.map((field) => transformOptions(field))
      )
    }
  }

  const setAllDefaultFields = useCallback(async () => {
    const fields = getValues('asset_template_fields_attributes') ?? []
    try {
      const allPersistedFields = fields?.filter(({ main }) => main) ?? []
      const defaultFieldsNotIncludedYet = differenceBy(
        defaultFields
          .filter(({ main }) => main)
          .map((field) => ({
            ...field,
            asset_default_field_id: field.id
          })),
        allPersistedFields,
        'asset_default_field_id'
      )
      let newFields = []
      if (!isEmpty(defaultFieldsNotIncludedYet)) {
        newFields = [
          ...defaultFieldsNotIncludedYet.map((field) => ({
            ...omit(field, ['id']),
            asset_default_field_id: field.id
          })),
          ...fields
        ]
      } else {
        newFields = [...fields]
      }
      const updatedFields = [
        ...newFields.flatMap((field, index) => ({
          ...field,
          uuid: uuidv4(),
          order: index + 1
        }))
      ]

      if (assetTemplate?.id) {
        if (!isEmpty(defaultFieldsNotIncludedYet)) {
          await onEditField('asset_template_fields_attributes', updatedFields)
        }
      } else {
        setValue('asset_template_fields_attributes', updatedFields)
      }
    } catch (error) {
      setValue('asset_template_fields_attributes', fields)
    }
  }, [assetTemplate?.id, defaultFields, getValues, onEditField, setValue])

  const onSelectDefaultMainField = useCallback(async () => {
    const fields = getValues('asset_template_fields_attributes')
    try {
      const updatedFields = [
        {
          ...omit(selectedDefaultMainField, ['id']),
          uuid: uuidv4(),
          asset_default_field_id: selectedDefaultMainField?.id
        },
        ...fields
      ].map((field, index) => ({ ...field, order: index + 1 }))
      if (assetTemplate?.id) {
        await onEditField('asset_template_fields_attributes', updatedFields)
      } else {
        setValue('asset_template_fields_attributes', updatedFields)
      }
    } catch (error) {
      setValue('asset_template_fields_attributes', fields)
    } finally {
      onCloseMainFieldSelector()

      setSelectedDefaultMainField(null)
    }
  }, [
    assetTemplate?.id,
    getValues,
    onCloseMainFieldSelector,
    onEditField,
    selectedDefaultMainField,
    setValue
  ])

  useGlobalEventListener(
    useCallback(({ type, payload }) => {
      if (type === globalEvents.ON_SET_ASSET_TEMPLATE_ACTION_LOADING_STATE) {
        setIsLoading(payload)
      }
      if (type === globalEvents.ON_OPEN_SET_DEFAULT_MAIN_FIELD_SELECTOR) {
        // use onOpenMainFieldSelector() here for re-use the modal selection strategy
      }
    }, [])
  )

  const {
    shouldCreate: shouldAllowAssetTypesCreation,
    shouldRender: shouldAllowAssetTypesView
  } = usePageProperties({
    pageName: 'asset_templates'
  })

  useEffect(() => {
    if (isBoolean(shouldAllowAssetTypesView) && !shouldAllowAssetTypesView) {
      history.push(getRouteUrl('assetTemplates'))
    }
  }, [history, shouldAllowAssetTypesView])

  const hasMainFieldSet = !!formValues?.asset_template_fields_attributes?.find(
    ({ main }) => main
  )

  return {
    assetTemplate,
    assetTypes,
    defaultFields,
    fieldTypes,
    navbarHeight,
    formProps,
    onSubmitForm,
    isLoading,
    shouldRenderForm,
    formValues,
    onEditField,
    fieldsRefs,
    editInlineRenderKey,
    breadcrumbName,
    onDragEnd,
    shouldAllowAssetTypesCreation,
    isMainFieldSelectorOpen,
    onCloseMainFieldSelector,
    selectedDefaultMainField,
    setSelectedDefaultMainField,
    onSelectDefaultMainField,
    hasMainFieldSet
  }
}

export default useAssetTemplateEditor
