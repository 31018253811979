import { Input } from '@chakra-ui/input'
import React, { useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import { Container } from './styles'

export const quillModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'ltr' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ['link']
  ]
}

const RichTextEditor = ({
  minWidth,
  maxWidth,
  modules,
  value,
  onChangeValue,
  placeholder,
  isInvalid = false,
  focusBorderColor = '#3182CE',
  register,
  autoFocus
}) => {
  const [focused, onFocused] = useState(false)
  const ref = useRef(null)

  const onInputFocus = () => {
    ref?.current.focus()
  }

  useEffect(() => {
    if (autoFocus) {
      onInputFocus()
    }
  }, [autoFocus])

  return (
    <Container
      minWidth={minWidth}
      maxWidth={maxWidth}
      focusBorderColor={focusBorderColor}
      focused={focused}
      isInvalid={isInvalid}
    >
      <Input
        {...register}
        style={{ opacity: 0, height: 0 }}
        onFocus={onInputFocus}
      />
      <ReactQuill
        ref={ref}
        defaultValue={value}
        modules={modules}
        onChange={onChangeValue}
        placeholder={placeholder}
        onBlur={() => onFocused(false)}
        onFocus={() => onFocused(true)}
      />
    </Container>
  )
}

RichTextEditor.defaultProps = {
  autoFocus: false
}

export default RichTextEditor
