import { EditIcon } from '@chakra-ui/icons'
import { HStack, IconButton } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { getRouteUrl } from '../../../helpers'
import ToggleUserActive from '../ToggleUserActive'

const AssessorsActions = ({ id, fetchAssessors, currentPage, isActive }) => (
  <HStack spacing={4}>
    <Link
      to={{ pathname: getRouteUrl('editAssessor', { id }), from: 'assessors' }}
    >
      <IconButton
        icon={<EditIcon />}
        aria-label="Edit assessor"
        colorScheme="blue"
        size="sm"
        variant="outline"
      />
    </Link>
    <ToggleUserActive
      onSuccess={() => fetchAssessors(currentPage)}
      id={id}
      isActive={isActive}
    />
  </HStack>
)

export default AssessorsActions
