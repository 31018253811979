export const FIELDS_WITH_OPTIONS = ['select', 'radio_btn', 'checkbox'];
export const FIELDS_WITH_NOTIFICATION_OPTION = [
  'select',
  'boolean',
  'radio_btn',
];
export const BOOLEAN_NOTIFY_OPTIONS = [{label: 'Yes'}, {label: 'No'}];

export const VALID_LOGIC_QUESTION_TYPES = [
  'boolean',
  'counter',
  'free_field_one_line',
  'radio_btn',
  'checkbox',
  'scale',
  'select'
]

export const FIELDS_WITH_LOGIC_SELET_OPTIONS = [
  'select',
  'radio_btn',
  'checkbox',
  'boolean'
];


export const LOGIC_TYPE_IS_A_NUMBER = [
  'scale',
  'counter'
]
