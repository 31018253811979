import { useTemplateEditorContext } from 'contexts'
import { useFormContext } from 'react-hook-form'

const useLogicQuestions = ({ currentKey, getOptionLabel }) => {
  const { getValues, register, setValue } = useFormContext()
  const { handleFocusedField } = useTemplateEditorContext()
  const optionLabel = getOptionLabel()

  const handleNumberChange = (number, isAux = false) => {
    const key = isAux ? 'aux_value' : 'value'
    setValue(`${currentKey}.${key}`, number)
  }

  return {
    getValues,
    setValue,
    handleNumberChange,
    register,
    optionLabel,
    handleFocusedField
  }
}

export default useLogicQuestions
