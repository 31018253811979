import { useTemplateEditorContext } from 'contexts'
import { debounce, isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import {
  formatAttributes,
  getLinearStructure
} from '../../../../../pages/TemplateEditor/helpers'
import Notification from '../../../../../services/Notification'

const useFormFormatting = ({ reset, template }) => {
  const [isFormattingTemplate, setIsFormattingTemplate] = useState(
    !!template?.id
  )

  const { initialDataRef, structure } = useTemplateEditorContext()

  useEffect(() => {
    const asyncFormatting = async () => {
      const formatted = await new Promise((resolve, reject) => {
        try {
          let templateStructure = {}
          if (!isEmpty(structure)) {
            templateStructure = {
              complete: structure?.attributes,
              linear: getLinearStructure(structure?.attributes)
            }
          }
          debounce(() => {
            initialDataRef.current = template ?? {}
            const data = formatAttributes(
              template?.attributes,
              templateStructure
            )
            resolve(data)
          }, 100)()
        } catch (error) {
          Notification({
            icon: 'error',
            text: 'Unable to format this template'
          })

          reject()
        }
      })

      reset(formatted)

      setIsFormattingTemplate(false)
    }

    asyncFormatting()
  }, [initialDataRef, reset, template, structure])

  return {
    isFormattingTemplate
  }
}

export default useFormFormatting
