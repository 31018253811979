import { DeleteIcon } from '@chakra-ui/icons'
import { IconButton } from '@chakra-ui/react'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import Dialog from '../AlertDialog'

const DeleteButton = ({
  index,
  onDelete,
  onDialogClose,
  children,
  alertTitle,
  alertBody,
  cancelLabel,
  cancelColor,
  continueColor,
  continueLabel,
  isDeleting,
  size,
  variant,
  borderRadius,
  isDisabled
}) => {
  const dialogTriggerRef = useRef(null)

  const handleClose = (closeDialog) => {
    closeDialog()
    onDialogClose()
  }

  const removeAction = (closeDialog) => {
    onDelete(index)
    closeDialog()
  }

  return (
    <Dialog
      header={alertTitle}
      body={alertBody}
      handleClose={handleClose}
      cancelLabel={cancelLabel}
      cancelColor={cancelColor}
      continueLabel={continueLabel}
      continueColor={continueColor}
      onContinue={removeAction}
    >
      {children || (
        <IconButton
          isLoading={isDeleting}
          icon={<DeleteIcon />}
          aria-label="Delete"
          size={size}
          variant={variant}
          ref={dialogTriggerRef}
          colorScheme="red"
          borderRadius={borderRadius}
          sx={{ justifyContent: 'center!important' }}
          isDisabled={isDisabled}
          data-testid="list-builder-remove-action"
        />
      )}
    </Dialog>
  )
}

DeleteButton.defaultProps = {
  index: null,
  onDialogClose: noop,
  alertTitle: 'Delete this?',
  alertBody: `This action cannot be undone.`,
  cancelColor: 'gray',
  cancelLabel: 'Cancel',
  continueColor: 'red',
  continueLabel: 'Continue',
  isDeleting: false,
  size: 'xs',
  variant: 'solid',
  borderRadius: 2,
  isDisabled: false
}

DeleteButton.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.any,
  onDelete: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
  isDisabled: PropTypes.bool
}

export default DeleteButton
