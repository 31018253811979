import { Flex, Stack, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { getMobileFlexStyles } from '../BaseStructure'

const QuestionLayout = ({ leftSlot, rightSlot, children }) => {
  const [isMobile] = useMediaQuery('(max-width: 799px)')
  return (
    <Stack w="100%" gap={4}>
      <Flex {...getMobileFlexStyles(isMobile, true)} w="100%">
        {leftSlot && <Stack gap={isMobile ? 4 : 0}>{leftSlot}</Stack>}
        {rightSlot && (
          <Flex {...getMobileFlexStyles(isMobile)} gap={4} minW="fit-content">
            {rightSlot}
          </Flex>
        )}
      </Flex>
      {children}
    </Stack>
  )
}

export default QuestionLayout
