import styled from '@emotion/styled'

export const Wrapper = styled.div`
  ${({ color, noborder }) => `
    position: relative;
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 100%;
    ${!noborder && `border: 2px solid ${color}`};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-sizing: border-box;
    cursor: pointer;

    & > i {
      color: ${color} !important;
      font-style: normal !important;
      font-size: 20px;
    }
  `}
`

export const ActionsList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 4px);
  height: calc(100% - 4px);

  z-index: -1;

  & > * {
    position: absolute !important;
    top: 0;
    left: 0;
    opacity: 0;
    transition-property: transform, opacity;
    transition-timing-function: cubic-bezier(0.35, -0.59, 0.47, 0.97);
    transition-duration: 0.3s, 0.3s;

    &:nth-of-type(1) {
      transition-delay: 0.3s;
    }
    &:nth-of-type(2) {
      transition-delay: 0.2s;
    }
    &:nth-of-type(3) {
      transition-delay: 0.1s;
    }
    &:nth-of-type(4) {
      transition-delay: 0s;
    }
  }

  &.active {
    z-index: 9999;
  }

  &.active > * {
    transition-timing-function: cubic-bezier(0.35, 0.03, 0.47, 1.59);
    opacity: 1;

    &:nth-of-type(1) {
      transition-delay: 0s;
      transform: translate3d(0px, -80px, 0);
    }
    &:nth-of-type(2) {
      transition-delay: 0.1s;
      transform: translate3d(40px, -65px, 0px);
    }
    &:nth-of-type(3) {
      transition-delay: 0.2s;
      transform: translate3d(65px, -29px, 0px);
    }
    &:nth-of-type(4) {
      transition-delay: 0.3s;
      transform: translate3d(71px, 13px, 0px);
    }
  }
`
export const EndActions = styled.div`
  display: flex;
  align-items: center;
  & > *:not(:last-of-type) {
    margin-right: 12px;
  }
`
