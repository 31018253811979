import React, { useMemo } from 'react'
import Breadcrumb from '../../../../../components/Breadcrumb'

const SettingsEditor = ({ id, name }) => {
  const options = useMemo(() => {
    const items = []
    if (id) {
      items.push({
        title: 'Edit Setting'
      })
      items.push({
        title: name
      })
    }
    return items
  }, [id, name])
  return <Breadcrumb nativeHomeRoute={false} routeTree={options} />
}

export default SettingsEditor
