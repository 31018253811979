import React from 'react'
import { theme } from '@chakra-ui/react'
import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import { rgba, darken } from 'polished'

const sizesTransformer = {
  small: `
    width: 20px;
    height: 20px;
    & i {
      font-size: 14px;
    }
  `,
  medium: `
    width: 30px;
    height: 30px;
    & i {
      font-size: 18px;
    }
  `,
  large: `
    width: 40px;
    height: 40px;
    & i {
      font-size: 22px;
    }
  `
}

const colorTransformer = {
  extraSmall: '10px',
  small: '12px',
  medium: '14px',
  large: '16px',
  extraLarge: '18px',
  max: '20px'
}

export const Wrapper = styled.div`
  ${({ variant }) => `
    background-color: white;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colorTransformer[variant]};
    border-radius: 2px;
    cursor: pointer;

    & i {
      color: ${colorTransformer[variant]};
    }

    ${({ size }) => size && sizesTransformer[size]};

    &:hover {
      background-color: ${rgba(colorTransformer[variant], 0.2)};
      & i {
        color: ${darken(0.1, colorTransformer[variant])};
      }
    }
  `}
`
export const ItemsWrapper = styled.div`
  display: flex;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  flex-direction: column;
  min-width: 100px;
`

export const GlobalStyle = () => {
  return (
    <Global
      styles={css`
        .cxmp a {
          color: #2d3748 !important;
          text-decoration: none !important;
        }

        @media screen and (max-width: 730px) {
          .chakra-tabs__tablist,
          .chakra-tabs__tab-panel {
            overflow-x: auto;
            overflow-y: hidden;
          }
          .chakra-tabs__tab-panel {
            padding: 2px !important;
          }
        }

        .carousel {
          & .thumb {
            height: 80px !important;
            &:hover {
              border-color: ${theme.colors.orange[200]} !important;
            }
            @media screen and (min-width: 600px) {
              display: flex;
              justify-content: center;
            }
          }
          & .thumbs {
            @media screen and (min-width: 600px) {
              width: 100%;
              display: flex;
              justify-content: center;
            }
          }
          .thumb.selected {
            border-color: ${theme.colors.orange[300]} !important;
          }
        }
      `}
    />
  )
}
