import { yupResolver } from '@hookform/resolvers/yup'
import { get, omit } from 'lodash'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import {
  getRouteUrl,
  normalizedRoles,
  setFormErrors
} from '../../../../helpers'
import { usePageProperties } from '../../../../hooks'
import Notification from '../../../../services/Notification'
import OlingaAPI from '../../../../services/OlingaAPI'

const getDefaultStructure = (roles = [], scope = {}) => ({
  attributes: {
    id: null,
    active: true,
    name: 'user',
    roles: normalizedRoles(roles),
    scope,
    profile: null
  }
})

const schema = yup
  .object({
    name: yup.string().required('The profile name is required')
  })
  .required()

const useProfileForm = (data, profileId) => {
  const { defaultValues, userData } = useMemo(() => {
    if (data?.profile?.attributes) {
      const { name, roles, description } = get(data.profile, 'attributes')
      return {
        defaultValues: {
          name,
          description
        },
        userData: getDefaultStructure(roles)
      }
    }
    return {
      defaultValues: {
        name: '',
        description: ''
      },
      userData: getDefaultStructure([])
    }
  }, [data?.profile])

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const { pageTitle, singularTitle } = usePageProperties({
    pageName: 'profiles'
  })

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const history = useHistory()

  const manageFormRef = useRef()

  const formRef = useRef({})

  const onManageUserSubmit = useCallback(
    async (managerUserData) => {
      formRef.current = omit(managerUserData, ['profile_id'])
      try {
        await handleSubmit(onSubmit)()
      } catch (e) {
        //
      }
    },
    [handleSubmit, onSubmit]
  )

  const onSubmit = useCallback(
    async (submitData) => {
      setIsLoadingSubmit(true)
      try {
        const { description, name } = submitData
        const formData = {
          profile: {
            description,
            name,
            ...formRef.current
          }
        }

        const response = profileId
          ? await OlingaAPI.profileUpdate(profileId, formData)
          : await OlingaAPI.profileCreate(formData)

        if (response.status === 200) {
          Notification({
            icon: 'success',
            text: response?.data?.message
          })
          if (history.location?.from) {
            history.goBack()
          } else {
            history.push(getRouteUrl('profiles'))
          }
        } else {
          //
        }
      } catch (e) {
        setFormErrors(e, setError, Notification)
      } finally {
        setIsLoadingSubmit(false)
      }
    },
    [history, profileId, setError]
  )

  const handleSequentialSubmit = () => {
    manageFormRef.current.submit()
  }

  return {
    errors,
    onSubmit,
    manageFormRef,
    isLoading: isLoadingSubmit,
    register,
    handleSubmit,
    userData,
    handleSequentialSubmit,
    onManageUserSubmit,
    pageTitle,
    singularTitle
  }
}

export default useProfileForm
