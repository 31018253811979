import React, { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { getPermissions } from '../../../helpers/permissions'
import ActionsContext from '../../../pages/Form/FillForm/ActionsContext'
import PageActionEditor from '../PageActionEditor'

const EditAction = ({ id }) => {
  const methods = useForm({
    defaultValues: null
  })
  const user = useSelector(({ globalReducer }) => globalReducer.user)
  const permissions = useMemo(() => getPermissions(user), [user])
  return (
    <FormProvider {...methods}>
      <ActionsContext actionId={id} permissions={permissions}>
        <PageActionEditor id={id} />
      </ActionsContext>
    </FormProvider>
  )
}

export default EditAction
