import { ChatIcon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { getRouteUrl } from '../../../helpers'

const AnswerButton = ({ totalForms, shouldShowFormAnswers }) => (
  <Button
    leftIcon={<ChatIcon />}
    variant="outline"
    colorScheme="green"
    size="sm"
    isDisabled={!shouldShowFormAnswers}
  >
    {!totalForms
      ? 'There are no responses yet'
      : `View ${totalForms} ${totalForms === 1 ? 'response' : 'responses'}`}
  </Button>
)

const Answers = ({ totalForms, shouldShowFormAnswers, id }) => {
  return (
    <>
      {shouldShowFormAnswers && (
        <Link to={getRouteUrl('templateForm', { id })}>
          <AnswerButton
            totalForms={totalForms}
            shouldShowFormAnswers={shouldShowFormAnswers}
          />
        </Link>
      )}
      {!shouldShowFormAnswers && (
        <AnswerButton
          totalForms={totalForms}
          shouldShowFormAnswers={shouldShowFormAnswers}
        />
      )}
    </>
  )
}

export default Answers
