import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Flex, IconButton, Tooltip } from '@chakra-ui/react'
import React from 'react'

const HomeHeader = ({ currentPage, onGoBack }) => {
  if (['newPassword', 'resetPassword'].includes(currentPage)) {
    return (
      <Flex alignItems="center">
        <Tooltip placement="topLeft" title="Back to Sign In">
          <IconButton
            onClick={onGoBack}
            icon={<ChevronLeftIcon />}
            size="xs"
            variant="link"
            colorScheme="blue"
            fontSize={24}
            mr={2}
          />
        </Tooltip>
        {currentPage === 'newPassword' ? 'New Password' : 'Reset Password'}
      </Flex>
    )
  }

  return 'Sign In'
}

export default HomeHeader
