/* eslint-disable react/jsx-key */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Skeleton,
  Stack,
  Tab,
  TabList,
  Tabs
} from '@chakra-ui/react'
import { startCase } from 'lodash'
import moment from 'moment'
import ChartPreview from 'pages/ManageDashboards/ManageDashboardEditor/ChartItem/ChartPreview'
import React from 'react'
import Filters from './Filters'
import useDashboard from './hooks/useDashboard'

const Dashboard = ({ data }) => {
  const {
    dashboards,
    dashboardsContext,
    dashboardCharts,
    loadingIndex,
    index,
    onExpandDashboard,
    filter,
    onChangeFilters,
    contextIndex,
    onChangeDashboardFilter
  } = useDashboard({ data })

  return (
    <Stack>
      <Heading size="md">Dashboards</Heading>
      <Tabs
        pb={4}
        onChange={onChangeDashboardFilter}
        colorScheme="blue"
        index={contextIndex}
      >
        <TabList gap={4}>
          {React.Children.toArray(dashboardsContext?.map((filter) => <Tab>{filter.label}</Tab>))}
        </TabList>
      </Tabs>
      <Accordion
        defaultIndex={[0]}
        allowMultiple
        index={index}
        onChange={onExpandDashboard}
      >
        {dashboards?.map(({ id, title, charts, settings }, index) => {
          const [startDate, endDate] = filter?.[index]?.params?.date ?? []
          
          return (
            <Stack minH="100px" position="relative" key={id}>
              <AccordionItem
                key={id}
                borderWidth={1}
                my={4}
                borderRadius="md"
                position="relative"
              >
                {({ isExpanded }) => (
                  <>
                    <AccordionButton as="h2">
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        py={4}
                        fontSize="lg"
                        fontWeight="bold"
                      >
                        {title}{' '}
                        {settings?.context != 'assets' && (
                          filter?.[index]?.name !== 'custom'
                          ? `(${startCase(filter?.[index]?.name) || 'Today'})`
                          : `(From ${moment(startDate).format(
                              'DD/MM/YYYY'
                            )} to ${moment(endDate).format('DD/MM/YYYY')})`
                        )}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    {isExpanded ? (
                      <AccordionPanel pb={4} position="relative">
                        <>
                          <Flex
                            flexDirection='column'
                            gap={4}
                            w="100%"
                          >
                             <Filters
                              value={filter?.[index] || { name: 'today' }}
                              onChange={(filter) =>
                                onChangeFilters(index, filter)
                              }
                              isDisabled={loadingIndex === index}
                            />
                            <SimpleGrid minChildWidth='40%' spacing={5} flex={1}>
                             
                              {loadingIndex === index
                                ? charts?.map(({ chartType }, index) => {
                                    return (
                                      <Skeleton
                                        h={
                                          chartType === 'number'
                                            ? '150px'
                                            : '380px'
                                        }
                                        w="100%"
                                        key={index}
                                      />
                                    )
                                  })
                                : dashboardCharts?.[index]?.items?.map(
                                    (preview, index) => {
                                      return (
                                        <ChartPreview
                                          key={index}
                                          preview={preview}
                                        />
                                      )
                                    }
                                  )}
                            </SimpleGrid>
                          </Flex>
                        </>
                      </AccordionPanel>
                    ) : null}
                  </>
                )}
              </AccordionItem>
            </Stack>
          )
        })}
      </Accordion>
    </Stack>
  )
}

export default Dashboard
