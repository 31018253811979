import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStateProvider from './contexts/GlobalStateContext'
import { getRouteUrl } from './helpers/admin'
import Routes from './routes'
import store from './store'

const App = (props) => {
  const { isAdmin } = props

  if (isAdmin) {
    window.location.href = getRouteUrl('businesses');
  }

  return (
    <ChakraProvider>
      <ToastContainer />
      <Provider store={store}>
        <GlobalStateProvider {...props}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </GlobalStateProvider>
      </Provider>
    </ChakraProvider>
  )
}

export default App
