/* eslint-disable react/display-name */
import {
  Alert,
  AlertIcon,
  Flex,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, { forwardRef, memo } from 'react'
import { FormProvider } from 'react-hook-form'
import FormField from '../../../../components/FormField'
import FormSubmitActions from '../../../../components/FormSubmitActions'
import BusinessFormBreadcrumb from './BusinessFormBreadcrumb'
import FormBuilder from './SettingsFields/FormBuilder'
import { getDescription } from './SettingsFields/FormBuilder/helpers'
import useBusinessesForm from './useBusinessForm'

const FormContent = forwardRef(({ data }, ref) => {
  const {
    methods,
    business,
    isLoadingSubmit,
    onSubmit,
    cancelAction,
    tradingName,
    businessName,
    abnAcn,
    industry,
    settingsFields,
    rolesData,
    tabIndex,
    onChangeTabIndex,
    handleFormError
  } = useBusinessesForm({
    ref,
    data
  })

  const { register, handleSubmit, formState } = methods

  const { errors } = formState

  return (
    <Stack>
      <BusinessFormBreadcrumb business={business} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, handleFormError)}>
          <>
            <Tabs onChange={onChangeTabIndex} index={tabIndex}>
              <TabList>
                <Tab>Business Info</Tab>
                {settingsFields
                  ? Object.keys(settingsFields)
                      .sort()
                      .map((key) => (
                        <Tab key={key}>
                          <Text mr={4} textTransform="capitalize">
                            {key}
                          </Text>
                          <Tag
                            px="4"
                            colorScheme={
                              settingsFields[key]?.length ? 'blue' : 'gray'
                            }
                          >
                            {settingsFields[key]?.length || 'Empty'}
                          </Tag>
                        </Tab>
                      ))
                  : null}
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Flex flexDir="column" w="50%" gap="20px">
                    <FormField label="Name" isRequired>
                      <Input
                        type="text"
                        placeholder="Name"
                        {...register('businessName', {
                          required: true
                        })}
                        isInvalid={!!errors.businessName}
                        defaultValue={businessName}
                      />
                    </FormField>
                    <FormField label="Trading Name" isRequired>
                      <Input
                        type="text"
                        placeholder="Trading Name"
                        {...register('tradingName', {
                          required: true
                        })}
                        isInvalid={!!errors.tradingName}
                        defaultValue={tradingName}
                      />
                    </FormField>
                    <FormField label="Abn/Acn" isRequired>
                      <Input
                        type="text"
                        placeholder="Abn/Acn"
                        {...register('abnAcn', {
                          required: true
                        })}
                        isInvalid={!!errors.abnAcn}
                        defaultValue={abnAcn}
                      />
                    </FormField>
                    <FormField label="Industry" isRequired>
                      <Input
                        type="text"
                        placeholder="Industry"
                        {...register('industry', {
                          required: true
                        })}
                        isInvalid={!!errors.industry}
                        defaultValue={industry}
                      />
                    </FormField>
                  </Flex>
                </TabPanel>
                {settingsFields
                  ? Object.keys(settingsFields)
                      .sort()
                      .map((key) => (
                        <TabPanel key={key} px={0} py={2}>
                          <Flex flexDir="column" gap="10px">
                            <Alert status="info" borderRadius="md">
                              <AlertIcon />
                              {getDescription(key)}
                            </Alert>

                            <FormBuilder
                              rolesData={rolesData}
                              formKey={key}
                              values={settingsFields[key]}
                            />
                          </Flex>
                        </TabPanel>
                      ))
                  : null}
              </TabPanels>
            </Tabs>
            <FormSubmitActions
              margin={4}
              isLoading={isLoadingSubmit}
              cancelAction={cancelAction}
            />
          </>
        </form>
      </FormProvider>
    </Stack>
  )
})

FormContent.defaultProps = {
  data: {}
}

FormContent.propTypes = {
  data: PropTypes.object
}

export default memo(FormContent)
