/* eslint-disable react/display-name */
import {
  Button,
  Flex,
  Modal as ModalDialog,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react'
import { isFunction, noop } from 'lodash'
import PropTypes from 'prop-types'
import React, { cloneElement, forwardRef, useImperativeHandle } from 'react'

const Modal = forwardRef(
  (
    {
      header,
      body,
      footer,
      scrollType,
      size,
      children,
      bodyHeight,
      shouldInjectButton,
      direction,
      modalContentStyles,
      onModalOpen,
      onModalClose
    },
    ref
  ) => {
    const [isMobile] = useMediaQuery('(max-width: 799px)')
    const { isOpen, onOpen, onClose } = useDisclosure()

    const onOpenModal = () => {
      onOpen()
      onModalOpen()
    }

    const onCloseModal = () => {
      onClose()
      onModalClose()
    }

    useImperativeHandle(ref, () => ({
      open: () => {
        onOpenModal()
      },
      close: () => {
        onCloseModal()
      }
    }))

    return (
      <>
        {shouldInjectButton
          ? cloneElement(children, {
              onClick: (e) => {
                if (isFunction(e?.preventDefault)) {
                  e?.preventDefault()
                }
                if (isFunction(e?.stopPropagation)) {
                  e.stopPropagation()
                }

                onOpenModal()
              }
            })
          : children}
        <ModalDialog
          scrollBehavior={isMobile ? 'outside' : scrollType}
          isOpen={isOpen}
          onClose={onCloseModal}
          size={size}
          background="white"
        >
          <ModalOverlay />
          <ModalContent {...modalContentStyles}>
            {header && (
              <ModalHeader maxW="calc(100% - 64px)" w="100%">
                {header}
              </ModalHeader>
            )}
            {direction !== 'column-reverse' && <ModalCloseButton />}
            {body && <ModalBody h={bodyHeight || 'auto'}>{body}</ModalBody>}

            <ModalFooter>
              <Flex justify="flex-end" display="flex" gap={4} align="center">
                <Button colorScheme="gray" ml="2" onClick={onCloseModal}>
                  Close
                </Button>
                {footer && footer}
              </Flex>
            </ModalFooter>
          </ModalContent>
        </ModalDialog>
      </>
    )
  }
)

Modal.defaultProps = {
  header: null,
  body: null,
  footer: null,
  size: 'lg',
  scrollType: 'inside',
  bodyHeight: null,
  customZIndex: 999999,
  shouldInjectButton: true,
  direction: 'column',
  modalContentStyles: {},
  onModalOpen: noop,
  onModalClose: noop
}

Modal.propTypes = {
  header: PropTypes.any,
  body: PropTypes.any,
  footer: PropTypes.any,
  size: PropTypes.oneOf([
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    '2xl',
    '4xl',
    '6xl',
    'full'
  ]),
  scrollType: PropTypes.oneOf(['inside', 'outside']),
  bodyHeight: PropTypes.string,
  customZIndex: PropTypes.number,
  shouldInjectButton: PropTypes.bool,
  modalContentStyles: PropTypes.object,
  onModalClose: PropTypes.func,
  onModalOpen: PropTypes.func
}

export default Modal
