import { getRouteUrl } from 'helpers'
import { get, isEmpty, pick, trim } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  usePageProperties,
  useQuery,
  useTableColumns,
  useTableFilter
} from '../../../hooks'
import OlingaAPI from '../../../services/OlingaAPI'
import EditGroupButton from '../EditGroupButton'
import GroupPeopleModal from '../GroupPeopleModal'

const hiddenColumnsKey = 'form_answers_persisted_columns.group_users'

const useGroups = () => {
  const {
    pageTitle,
    singularTitle,
    shouldCreate: hasPermissionsToModifyGroup
  } = usePageProperties({ pageName: 'groups' })

  const history = useHistory()

  const sortType = useCallback((rowA, rowB, columnId) => {
    const getAttribute = (object) => {
      return get(object, `original.${columnId}.props.count`)
    }
    if (getAttribute(rowA) > getAttribute(rowB)) return 1
    if (getAttribute(rowA) < getAttribute(rowB)) return -1
    return 0
  }, [])

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'People',
        accessor: 'people',
        sortType
      }
    ],
    hiddenColumnsKey,
    hasPermissionsToModifyGroup,
    EditGroupButton,
    ({ original: { id } }) => ({ id })
  )

  const query = useQuery()

  const initialParams = useMemo(() => {
    return isEmpty(query)
      ? {
          page: 1,
          per_page: 15
        }
      : pick(query, ['page', 'per_page', 'name'])
  }, [query])

  const fieldsExtractor = useCallback((data) => {
    if (isEmpty(data)) {
      return []
    }
    return data.map(({ attributes: { name, totalPersons: people, id } }) => ({
      name,
      people: <GroupPeopleModal id={id} count={people} groupName={name} />,
      id
    }))
  }, [])

  const {
    refetch,
    isLoading,
    updateParams,
    fetchParams,
    tableData,
    pagination
  } = useTableFilter({
    id: null,
    fetchCallback: OlingaAPI.groupsList,
    fieldsExtractor,
    initialParams,
    onRefetch: useCallback(
      (newParams) => {
        history.replace(getRouteUrl('groups', newParams))
      },
      [history]
    )
  })

  const onTextChange = useCallback(
    (updatedText) => {
      if (!updatedText || !trim(updatedText)?.length) {
        return refetch({ per_page: 15, page: 1 })
      }
      updateParams({ name: updatedText }, updatedText?.length >= 3)
    },
    [refetch, updateParams]
  )

  return {
    hasPermissionsToModifyGroup,
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data: tableData,
    isLoading,
    refetch,
    updateParams,
    fetchParams,
    pagination,
    onTextChange,
    pageTitle,
    singularTitle
  }
}

export default useGroups
