import { useDisclosure } from '@chakra-ui/react'
import { debounce } from 'lodash'
import { useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { getRouteUrl } from '../../../helpers'
import { usePageProperties } from '../../../hooks'
import OlingaAPI from '../../../services/OlingaAPI'

const useTemplates = () => {
  const history = useHistory()
  const templateListRef = useRef({})
  const { isOpen, onClose, onOpen } = useDisclosure()

  const {
    pageTitle,
    singularTitle,
    shouldCreate: hasPermissionToCreateTemplate
  } = usePageProperties({
    pageName: 'templates'
  })
  const {
    pageTitle: templateReportsPageTitle,
    icon: templateReportsIcon,
    shouldRender: shouldRenderReports
  } = usePageProperties({
    pageName: 'template_reports'
  })
  const {
    pageTitle: alertsPageTitle,
    icon: alertsIcon,
    shouldRender: shouldRenderAlerts
  } = usePageProperties({
    pageName: 'alerts'
  })

  const getAutocompleteCallback = (value) => {
    return OlingaAPI.searchTemplates({ params: { name: value } })
  }

  const handleAutocompleteSelect = (id) => {
    onClose()
    history.push(
      getRouteUrl('templateForm', {
        id
      })
    )
  }

  const getFolderListCallbackParams = useCallback(
    ({ shouldShowAllFolders }) => {
      return {
        all: shouldShowAllFolders
      }
    },
    []
  )

  const onAutocompleteOpen = () => {
    onOpen()
    debounce(() => {
      const element = document.querySelector('#templates-autocomplete-input')
      if (element) {
        element.focus()
      }
    }, 100)()
  }

  return {
    isOpen,
    onClose,
    hasPermissionToCreateTemplate,
    pageTitle,
    templateListRef,
    onAutocompleteOpen,
    getFolderListCallbackParams,
    handleAutocompleteSelect,
    getAutocompleteCallback,
    singularTitle,
    alertsIcon,
    alertsPageTitle,
    shouldRenderAlerts,
    templateReportsPageTitle,
    templateReportsIcon,
    shouldRenderReports
  }
}

export default useTemplates
