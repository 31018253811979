/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  SimpleGrid,
  Skeleton,
  Stack,
  Switch,
  Text,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import { Select as MultiSelect } from 'chakra-react-select'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import FormErrorMessage from '../../../components/FormErrorMessage'
import EditInline from '../../../components/Inputs/EditInline'
import PermissionSelector from '../../../components/PermissionSelector'
import TemplateFrequencyMenu from '../../../components/TemplateFrequencyMenu'
import { useTemplateEditorContext } from '../../../contexts'
import CloneTemplateButton from '../../Template/CloneTemplateButton'
import DropdownSelection from '../../Template/QRCodeGenerator/DropdownSelection'
import NotificationInfo from '../NotificationsHandler/NotificationInfo'
import TemplateNotificationsHandler from '../NotificationsHandler/TemplateNotificationsHandler'
import { BoxInnerContent } from '../styles'
import useHeader from './hooks/useHeader'
import { SelectWrapper } from './styles'

const Header = ({ isEdit, templateId }) => {
  const {
    register,
    control,
    getValues,
    formState: { errors },
    hasPermissionToClone,
    hasPermissionToEditFrequencySettings
  } = useFormContext()

  const {
    onChangeFormFolderId,
    onChangeGroupsValue,
    onChangePermission,
    isLoadingFolders,
    isLoadingGroups,
    selectablePermissions,
    shouldRenderSwitches,
    shouldRenderFormFolderIdSelector,
    shouldShowNotifyHandler,
    defaultGroupValue,
    defaultFormFolderValue,
    groups,
    groupsOptions
  } = useHeader({ isEdit })

  const { formFolderList, singularTitle } = useTemplateEditorContext()

  const [isMobile] = useMediaQuery('(max-width: 730px')

  const [formFolderId, notify, name, notifiedUsers, selectedAppPermission] =
    useWatch({
      name: [
        'form_folder_id',
        'notify',
        'name',
        'notify_templates_users_attributes',
        'template_setting_attributes'
      ],
      control,
      defaultValue: [
        getValues('_id'),
        getValues('form_folder_id'),
        getValues('notify'),
        getValues('name') || null,
        getValues('notify_templates_users_attributes'),
        getValues('template_setting_attributes')
      ]
    })

  const getEditableTemplateName = () => {
    const EditableTemplateName = (
      <EditInline
        placeholder={`${singularTitle} name`}
        defaultValue={name}
        startWithEditView={!name}
        fontSize="2xl"
        registerProps={{ ...register('name') }}
      />
    )
    if (templateId) {
      if (name || name === '') {
        return EditableTemplateName
      }

      return (
        <Box paddingTop={4}>
          <Skeleton h="42px" w="100%" />
        </Box>
      )
    }
    return EditableTemplateName
  }

  const user = useSelector(({ globalReducer }) => globalReducer.user)

  return (
    <>
      <SimpleGrid
        columns={{ sm: 1, md: 2 }}
        spacing={10}
        p={4}
        paddingBottom={0}
      >
        <Box>
          {!isLoadingFolders && (
            <>
              <Box>
                {getEditableTemplateName()}
                <FormErrorMessage message={errors?.name?.message} />
              </Box>
              <Box paddingTop={4}>
                {shouldRenderFormFolderIdSelector && (
                  <Box>
                    <MultiSelect
                      placeholder="Select a folder"
                      size="sm"
                      options={formFolderList}
                      onChange={onChangeFormFolderId}
                      aria-label="form folder selector"
                      defaultValue={defaultFormFolderValue}
                    />
                    <FormErrorMessage message={errors?.form_folder?.message} />
                  </Box>

                )}
              </Box>
            </>
          )}
          {(isLoadingGroups || isLoadingFolders) && (
            <Box paddingTop={4}>
              <Stack w="100%">
                <Skeleton h="24px" w="100%" />
              </Stack>
            </Box>
          )}
          {groups && formFolderId && !isLoadingGroups && (
            <Box paddingTop={4}>
              <SelectWrapper>
                <MultiSelect
                  isMulti
                  placeholder="Select a group"
                  size="sm"
                  {...register('groupIds')}
                  options={groupsOptions}
                  defaultValue={defaultGroupValue}
                  onChange={onChangeGroupsValue}
                  aria-label="group-selector"
                />
              </SelectWrapper>
              <Text fontSize="xs" paddingBottom="10px">
                By default, all groups will have access if none is selected.
              </Text>
            </Box>
          )}
        </Box>
        {shouldRenderSwitches ? (
          <Box>
            <Box paddingTop={4}>
              <Flex>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="map_view" mb="0">
                    Map View:
                  </FormLabel>
                  <Switch
                    size="sm"
                    id="map_view"
                    {...register('map_view')}
                    mb="0"
                  />
                </FormControl>
                {isEdit && (
                  <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="active" mb="0">
                      Active:
                    </FormLabel>
                    <Switch
                      size="sm"
                      id="active"
                      {...register('active')}
                      mb="0"
                      colorScheme="green"
                    />
                  </FormControl>
                )}
              </Flex>
            </Box>
            {isEdit &&
              formFolderId &&
              templateId &&
              user?.personable_type === 'Business' && (
                <VStack spacing={4} align="flex-start">
                  <Flex justify="space-between" w={290} align="flex-end">
                    <DropdownSelection
                      templateId={templateId}
                      templateName={name}
                      isEdit
                    />
                    {hasPermissionToClone && (
                      <CloneTemplateButton
                        label={`Duplicate ${singularTitle}`}
                        templateId={templateId}
                      />
                    )}
                  </Flex>
                </VStack>
              )}
            <Flex gap={4} mt={4} flexDir={['column', 'row']}>
              <PermissionSelector
                blankPlaceholder="Access permission"
                placeholder="For this template, select whether the employee will have access to see all forms submitted, only his submissions or none"
                onSelect={onChangePermission}
                permissions={selectablePermissions}
                selectedPermission={selectedAppPermission}
                colorScheme="blue"
                size="sm"
                minW="142px"
                selectedPermissionPrefix="Permission"
              />
              {templateId && (
                <TemplateFrequencyMenu
                  templateId={templateId}
                  hasPermissionToEdit={hasPermissionToEditFrequencySettings}
                />
              )}
            </Flex>
          </Box>
        ) : (
          <Stack pt="4" w="100%">
            <SimpleGrid columns={{ sm: 1, md: 2 }}>
              <Skeleton h="24px" />
              <Skeleton h="24px" />
            </SimpleGrid>
          </Stack>
        )}
      </SimpleGrid>
      <HStack>
        <Box w={isMobile ? '100%' : '80%'} padding="5px 17px 5px">
          <Accordion allowToggle>
            <AccordionItem border={'transparent'}>
              <Box w={isMobile ? '100%' : '40%'} border="1px solid #E2E8F0">
                <AccordionButton>
                  <NotificationInfo
                    isNotify={notify}
                    usersArray={notifiedUsers || []}
                    label={`${singularTitle} Notifications`}
                    variant="ghost"
                  />
                  <AccordionIcon />
                </AccordionButton>
              </Box>
              <AccordionPanel pb={isMobile ? 0 : 4}>
                <Box w="20%">
                  <BoxInnerContent>
                    <Checkbox isChecked={notify} {...register('notify')}>
                      Notify
                    </Checkbox>
                  </BoxInnerContent>
                </Box>
                <Box>
                  {shouldShowNotifyHandler && (
                    <TemplateNotificationsHandler
                      control={control}
                      isInitiallyNotifying={notify}
                      initiallyNotifiedUsers={notifiedUsers || []}
                    />
                  )}
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </HStack>
    </>
  )
}

export default Header
