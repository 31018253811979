import { Button } from '@chakra-ui/button'
import { Flex, Heading } from '@chakra-ui/layout'
import { Switch } from '@chakra-ui/switch'
import React, { forwardRef } from 'react'
import Modal from '../../../../components/Modal'
import NotificationInfo from '../NotificationInfo'
import TemplateNotificationsHandler from '../TemplateNotificationsHandler'

const NotificationsHandlerModal = forwardRef(
  (
    {
      control,
      notify,
      users,
      setValue,
      watch,
      notifyKey,
      onSave,
      subUsersList
    },
    ref
  ) => {
    return (
      <Modal
        size="4xl"
        ref={ref}
        header={
          <Flex align="center" justify="space-between" w="100%">
            <Heading size="md">Notifications</Heading>
            <Switch
              isChecked={notify}
              onChange={({ target: { checked } }) =>
                setValue(notifyKey, checked)
              }
              mb="0!important"
              cursor="pointer"
              fontSize="sm"
              colorScheme="green"
            >
              Notify
            </Switch>
          </Flex>
        }
        footer={
          <Button onClick={onSave} colorScheme="green">
            Save
          </Button>
        }
        body={
          <TemplateNotificationsHandler
            control={control}
            isInitiallyNotifying={notify}
            initiallyNotifiedUsers={users}
            setValue={setValue}
            watchValue={watch}
            subUsersList={subUsersList}
          />
        }
      >
        <NotificationInfo
          isNotify={notify}
          usersArray={users}
          label="Notifications"
          variant="ghost"
        />
      </Modal>
    )
  }
)

NotificationsHandlerModal.displayName = 'NotificationsHandlerModal'

export default NotificationsHandlerModal
