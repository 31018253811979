import React from 'react'
import PropTypes from 'prop-types'
import { HStack, Stack, Tag, Text } from '@chakra-ui/react'
import ToggleUserActive from '../ToggleUserActive'
import OlingaAPI from '../../../services/OlingaAPI'

const ActiveHolder = ({
  isActive,
  label,
  onSuccess,
  shouldToggleBoth,
  id,
  type
}) => {
  return (
    <Stack>
      <Text fontSize="xs">{label}</Text>
      <HStack spacing={2}>
        <Tag colorScheme={isActive ? 'green' : 'gray'}>
          {isActive ? 'Active' : 'Inactive'}
        </Tag>
        <ToggleUserActive
          isActive={isActive}
          onSuccess={onSuccess}
          shouldToggleBoth={shouldToggleBoth}
          id={id}
          type={type}
        />
      </HStack>
    </Stack>
  )
}

ActiveHolder.defaultProps = {
  shouldToggleBoth: true,
  endpoint: OlingaAPI.assessorsToggleActive,
  type: 'assessor'
}

ActiveHolder.propTypes = {
  shouldToggleBoth: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  type: PropTypes.oneOf(['assessor', 'user'])
}

export default ActiveHolder
