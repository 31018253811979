/* eslint-disable react/jsx-key */
import {
  Box,
  Heading,
  Stack
} from '@chakra-ui/layout'
import { Button, Input, VisuallyHidden } from '@chakra-ui/react'
import FormSubmitActions from 'components/FormSubmitActions'
import PropTypes from 'prop-types'
import React, { forwardRef, memo } from 'react'
import FormErrorMessage from '../../../components/FormErrorMessage'
import FormField from '../../../components/FormField'
import ActiveHolder from '../../../pages/Assessors/ActiveHolder'
import ManageUserFormBreadcrumb from './ManageUserFormBreadcrumb'
import RolesContent from './RolesContent'
import useManageUserForm from './useManageUserForm'

const ManageUsers = forwardRef(
  (
    {
      data,
      isSoloPage,
      onExternalSubmit,
      hasActiveHandler,
      displayProfileSelector
    },
    ref
  ) => {
    const {
      setIsUserActive,
      user,
      disabledRolesCategories,
      hasProfileRolesByCategory,
      hasUserRolesByCategory,
      isLoadingSubmit,
      isUserActive,
      name,
      register,
      updateProfile,
      handleSubmit,
      onSubmit,
      onError,
      profilesList,
      selectedProfile,
      roles,
      setValue,
      getValues,
      pageTitle,
      errors,
      isEdit,
      onChangeTemplatesSelector,
      checklistsList,
      checklistsOfChecklistRole,
      templatesList,
      templatesOfFormRole,
      templatesOfTemplateRole,
      foldersOfDocumentRole,
      dashboardsOfDashbordRole,
      documentsList,
      dashboardsList,
      templatesPageTitle,
      checklistsPageTitle,
      assetTemplatesPageTitle,
      assetTemplatesOfAssetTemplateRole,
      assetTemplatesList,
      rolesByProfile,
    } = useManageUserForm({ ref, data, isSoloPage, onExternalSubmit })

    return (
      <Stack>
        <ManageUserFormBreadcrumb
          user={user}
          isSoloPage={isSoloPage}
          pageTitle={pageTitle}
        />
        {isSoloPage && (
          <Heading as="h2" size="md" pb={2}>
            {name}
          </Heading>
        )}
        {hasActiveHandler && user?.id && (
          <ActiveHolder
            id={user?.id}
            onSuccess={(active) => setIsUserActive(active)}
            isActive={isUserActive}
            label="Set user active"
            shouldToggleBoth={false}
            type="user"
          />
        )}
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <>
            <Box w="100%">
              {!isEdit && (
                <>
                  <FormField label="Email" isRequired>
                    <Input
                      type="email"
                      maxW='50%'
                      placeholder="Email"
                      {...register('email', {
                        required: true
                      })}
                      isInvalid={!!errors.email || errors?.user?.email}
                    />
                  </FormField>
                  <FormErrorMessage
                    message={
                      errors?.email?.message || errors?.user?.email?.message
                    }
                  />
                </>
              )}
              <RolesContent
                profilesList={profilesList}
                data={data}
                roles={roles}
                register={register}
                selectedProfile={selectedProfile}
                updateProfile={updateProfile}
                displayProfileSelector={displayProfileSelector}
                hasProfileRolesByCategory={hasProfileRolesByCategory}
                rolesByProfile={rolesByProfile}
                onChangeTemplatesSelector={onChangeTemplatesSelector}
                checklistsList={checklistsList}
                checklistsOfChecklistRole={checklistsOfChecklistRole}
                templatesList={templatesList}
                templatesOfFormRole={templatesOfFormRole}
                templatesOfTemplateRole={templatesOfTemplateRole}
                foldersOfDocumentRole={foldersOfDocumentRole}
                dashboardsOfDashbordRole={dashboardsOfDashbordRole}
                documentsList={documentsList}
                dashboardsList={dashboardsList}
                templatesPageTitle={templatesPageTitle}
                checklistsPageTitle={checklistsPageTitle}
                assetTemplatesPageTitle={assetTemplatesPageTitle}
                assetTemplatesOfAssetTemplateRole={assetTemplatesOfAssetTemplateRole}
                assetTemplatesList={assetTemplatesList}
                getValues={getValues}
                setValue={setValue}
                hasUserRolesByCategory={hasUserRolesByCategory}
                disabledRolesCategories={disabledRolesCategories}
              />
            </Box>
            {isSoloPage && (
              <FormSubmitActions
                margin={4}
                isLoading={isLoadingSubmit}
                cancelRoute="manageUsers"
              />
            )}
            {!isSoloPage && (
              <VisuallyHidden>
                <Button ref={ref} type="submit">
                  Submit
                </Button>
              </VisuallyHidden>
            )}
          </>
        </form>
      </Stack>
    )
  }
)

ManageUsers.defaultProps = {
  isSoloPage: true,
  data: {},
  onExternalSubmit: () => ({}),
  children: null,
  hasActiveHandler: true,
  displayProfileSelector: true
}

ManageUsers.propTypes = {
  isSoloPage: PropTypes.bool,
  data: PropTypes.object,
  onExternalSubmit: PropTypes.func,
  hasActiveHandler: PropTypes.bool
}

ManageUsers.displayName = 'ManageUsers'

export default memo(ManageUsers)
