import * as Sentry from '@sentry/react'
import axios from 'axios'
import { debounce, isEmpty, isString } from 'lodash'
import { getRouteUrl } from '../helpers'
import { getRouteUrl as getAdminRouteUrl } from '../helpers/admin'
import Notification from './Notification'

export const getCSRFToken = () => {
  return {
    'X-CSRF-Token': document
      ?.querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content')
  }
}
const api = axios.create({
  baseURL: '/',
  headers: {
    ...getCSRFToken(),
    'Content-Type': 'application/json',
    responseType: 'json'
  }
})

const statusToDiscardErrorMessage = [401]

export const handleNotificationError = async (error) => {
  let message = ''
  if (axios.isCancel(error)) {
    return
  }
  if (error?.response) {
    Sentry.captureException(error)
    if (error.response.data instanceof Blob) {
      const content = await error.response.data.text()
      if (content) {
        try {
          const json = JSON.parse(content)
          if (json.message) {
            message = json.message
          } else {
            message = error
          }
        } catch (error) {
          Sentry.captureException(error)
          message = 'Internal Server Error'
        }
      }
    } else {
      message =
        error.response.data?.message || error.response.data?.errors || error
    }

    if (!statusToDiscardErrorMessage.includes(error.response.status)) {
      Notification({
        icon: 'error',
        text: message
      })
    }

    switch (error.response.status) {
      case 403:
        debounce(() => {
          window.location.href = getRouteUrl('signIn')
        }, 1500)()
        break
      case 401:
        debounce(() => {
          if (window.location.href.match('admin')) {
            window.location.href = getAdminRouteUrl('businesses')
            return
          }
          window.location.href = getRouteUrl('unauthorized')
        }, 1500)()
        break
      default:
        return
    }
  } else {
    Notification({
      icon: 'error',
      text: error || 'An Error has ocurred!'
    })
  }
}

export const handleErrors = (capturedError) => {
  if (
    capturedError?.response?.status === 500 &&
    isString(capturedError?.response?.data)
  ) {
    return { error: [capturedError?.response?.data] }
  }
  if (!isEmpty(Object.keys(capturedError?.response?.data?.errors))) {
    const baseErrors = capturedError?.response?.data?.errors?.base
    if (!isEmpty(baseErrors)) {
      const errorsStrings = baseErrors.join(' | ')
      Notification({
        icon: 'error',
        text: errorsStrings
      })
    }
    return capturedError?.response?.data?.errors
  }
  return null
}

export default api
