import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

import { CheckCircleIcon } from '@chakra-ui/icons';
import colors from 'utils/colors';
import { getAction, getIcon } from './helpers';
import { Container, StatusWrapper, TypeWrapper } from './styles';
import { useNotificationCard } from './useNotificationCard';

const NotificationCard = ({ notification, onCardClick }) => {
  const { data, readOn, id, dateFromNow } = useNotificationCard({
    notification,
  });

  return (
    <Container
      read={(!!readOn).toString()}
      onClick={() => !readOn && onCardClick({ data, id })}
    >
      <Flex w='100%' justifyContent='space-between' alignItems='center'>
        <Flex w='100%' flexDir='row'>
          <TypeWrapper>{getIcon()}</TypeWrapper>
          <Flex justifyContent="center" flexDir="column">
            <Text noOfLines={3}>{data.message}</Text>
            <Text noOfLines={1} pt="2px" fontSize="10px">
              {dateFromNow} ago
            </Text>
          </Flex>
        </Flex>
        <StatusWrapper read={(!!readOn).toString()}>
          {readOn ? <CheckCircleIcon color={colors.primary} /> : <CheckCircleIcon opacity='0.1' color='black' />}
          {getAction({ data })}
        </StatusWrapper>
      </Flex>
    </Container>
  );
};

export default NotificationCard;