import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

// This hook can only be used inside a Router (from react-router-dom)

const useRoutes = (routes = []) => {
  const location = useLocation()
  const background = location.state && location.state.background
  const getRoute = useCallback((route) => {
    const { path, isExact, render, component } = route
    return _.omit(
      {
        path,
        exact: isExact,
        render,
        component
      },
      component ? ['render'] : ['component']
    )
  }, [])

  const { regularRoutes, backgroundRoutes } = useMemo(
    () =>
      routes.reduce(
        (acc, route) => {
          if (route.isBackground) {
            acc.backgroundRoutes.push(route)
          } else {
            acc.regularRoutes.push(route)
          }
          return acc
        },
        { regularRoutes: [], backgroundRoutes: [] }
      ),
    [routes]
  )

  return useMemo(
    () => (
      <>
        <Switch location={background || location}>
          {regularRoutes.map((route, index) => (
            <Route {...getRoute(route)} key={index} />
          ))}
        </Switch>
        {background &&
          backgroundRoutes.map((route, index) => (
            <Route {...getRoute(route)} key={index} />
          ))}
      </>
    ),
    [background, backgroundRoutes, getRoute, location, regularRoutes]
  )
}

export default useRoutes
