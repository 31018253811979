import React, { useEffect, useState } from 'react'
import {
  Box,
  Divider,
  Flex,
  Heading,
  IconButton,
  Image,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  theme
} from '@chakra-ui/react'
import { getRouteUrl } from '../../../../../../helpers'
import { ChevronRightIcon } from '@chakra-ui/icons'
import FloatingLabelBox from '../../../../../../components/FloatingLabelBox'

const ActionDetailsCard = ({ action, notes, files, questionTitle }) => {
  const [previews, setPreviews] = useState([])
  const getCurrentValue = () => {
    if (action.currentValue) {
      return (
        <Text
          fontSize="sm"
          dangerouslySetInnerHTML={{ __html: action.currentValue }}
        />
      )
    }
    return (
      <Tag size="md" width="max-content" colorScheme="gray">
        Empty
      </Tag>
    )
  }

  useEffect(() => {
    return () => {
      previews.forEach(({ url }) => {
        if (url) {
          URL.revokeObjectURL(url)
        }
      })
    }
  }, [previews])

  useEffect(() => {
    setPreviews(
      files.map((file, index) => ({
        url: URL.createObjectURL(file),
        index
      }))
    )
  }, [files])

  const getFiles = () => {
    if (previews?.length) {
      return (
        <Stack w="100%">
          <Text>Files ({previews.length})</Text>
          <SimpleGrid columnGap={2} columns={{ sm: 2, md: 4, lg: 5 }}>
            {previews.map(({ url, index }) => {
              return (
                <Image
                  w="auto"
                  h="100%"
                  minH="80px"
                  objectFit="cover"
                  key={index}
                  src={url}
                />
              )
            })}
          </SimpleGrid>
        </Stack>
      )
    }
    return null
  }

  return (
    <Box
      border={`1px solid ${theme.colors.gray[400]}`}
      shadow="sm"
      borderRadius={4}
      bg="white"
    >
      <Stack borderRadius="inherit">
        <Flex
          align="center"
          bg={theme.colors.gray[100]}
          p={2}
          justify="space-between"
        >
          <Flex align="center" gap={1}>
            <Text fontSize="xs">Template:</Text>
            <a
              href={getRouteUrl('templateSettings', {
                id: action.template?.id ?? null
              })}
              target="_blank"
              rel="noreferrer noopener"
            >
              {action.template && (
                <Text fontSize="xs" color="blue.500">
                  {action.template.formFolderName}/{action.template.name}
                </Text>
              )}
            </a>
          </Flex>
          <a
            href={getRouteUrl('templateSettings', {
              id: action.template?.id ?? null
            })}
            target="_blank"
            rel="noreferrer noopener"
          >
            <IconButton
              icon={<ChevronRightIcon color={theme.colors.gray[400]} />}
              size="xs"
              colorScheme="gray"
            />
          </a>
        </Flex>
        <Stack p={2}>
          <Heading
            size="sm"
            dangerouslySetInnerHTML={{ __html: questionTitle }}
          />
          {getCurrentValue()}
          <Divider py={2} />
          {getFiles()}
          {notes?.title && (
            <Box pt={4}>
              <FloatingLabelBox label="Notes" borderColor="gray.400">
                {notes?.title}
              </FloatingLabelBox>
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}

ActionDetailsCard.defaultProps = {
  files: []
}

export default ActionDetailsCard
