import { theme } from '@chakra-ui/react'
import { get, orderBy } from 'lodash/'
import { getRouteUrl } from '../../../../helpers'
import actions from '../../../../store/actions'
import { itemsTransformer, navbarItems } from './settings'

const { SET_GLOBAL_SETTINGS } = actions.global

export const settingsRoutes = Object.entries(itemsTransformer).reduce(
  (acc, [key, { id }]) => {
    if (key.includes('settings_')) {
      acc.push(getRouteUrl(id))
    }
    return acc
  },
  []
)

export const formatGlobalObject = (globalProps) => {
  const { user, features, system } = globalProps
  const getMenuUrl = (id) => {
    const dashboardId =
      user?.personable_type === 'Assessor' ? 'client_dashboard' : 'dashboard'
    const userType = user?.personable_type === 'Assessor' ? '' : 'businesses'

    switch (id) {
      case 'dashboards':
      case 'dashboard':
        return getRouteUrl(dashboardId, { userType })
      case 'settings':
        return '#'
      default: {
        return getRouteUrl(id)
      }
    }
  }

  const navbarMenus = orderBy(
    Object.entries(navbarItems).reduce((acc, [key, additionalProps]) => {
      if (features?.[key] || ['settings'].includes(key)) {
        const props = itemsTransformer[key]
        const navbarItem = {
          ...(features?.[key] ?? {}),
          ...additionalProps,
          name:
            get(features?.[key], 'businessName') ||
            get(features?.[key], 'name'),
          url: getMenuUrl(props.id),
          ...props,
          dropdown: (get(additionalProps, 'dropdown') ?? []).reduce(
            (dropdown, dropdownItemKey) => {
              const dropdownItemProps = itemsTransformer[dropdownItemKey]
              if (features?.[dropdownItemKey]?.shouldRender) {
                dropdown.push({
                  ...features[dropdownItemKey],
                  ...dropdownItemProps,
                  name:
                    get(features?.[dropdownItemKey], 'businessName') ||
                    get(features?.[dropdownItemKey], 'name'),
                  url: getMenuUrl(dropdownItemProps.id)
                })
              }
              return dropdown
            },
            []
          )
        }
        if (key === 'settings') {
          navbarItem.shouldRender = navbarItem.dropdown.some(
            ({ shouldRender }) => shouldRender
          )
        }
        if (navbarItem.shouldRender) {
          acc.push(navbarItem)
        }
      }
      return acc
    }, []),
    ['order'],
    ['asc']
  )

  return {
    type: SET_GLOBAL_SETTINGS,
    payload: {
      ...globalProps,
      navbar: {
        logo_path: get(system, 'logo_path'),
        dashboardUrl: getMenuUrl('dashboard'),
        menus: navbarMenus,
        bg: '#ff871c',
        hover: theme.colors.orange[100],
        border: theme.colors.orange[200],
        highlight: theme.colors.orange[500],
        text: theme.colors.white
      }
    }
  }
}
