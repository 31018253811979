import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import MaterialIcon from 'components/MaterialIcon'
import useNavbar from 'components/Navbar/useNavbar'
import React from 'react'
import { Link } from 'react-router-dom'

const DesktopSubNav = ({ name, url, icon, description }) => {
  const {
    colors,
  } = useNavbar()

  return (
    <Link to={url ?? '#'}>
      <Box
        bg="white"
        role={'group'}
        display={'block'}
        p={2}
        rounded={'md'}
        transition="background 0.2s ease"
        _hover={{
          bg: colors.hover
        }}
      >
        <Stack direction={'row'} align={'center'}>
          <Stack>
            <Flex align="center" gap={2} color={colors.bg}>
              <MaterialIcon icon={icon} styles={{ fontSize: '16px'}} />
              <Text transition="all 0.3s ease" fontWeight={500}>
                {name}
              </Text>
            </Flex>
            {description && (
              <Text fontSize="xs" color="gray.800">
                {description}
              </Text>
            )}
          </Stack>
          <Flex
            transition="all .3s ease"
            transform="translateX(-10px)"
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify="flex-end"
            align="center"
            flex={1}
          >
            <ChevronRightIcon color={colors.bg} w={5} h={5} />
          </Flex>
        </Stack>
      </Box>
    </Link>
  )
}

export default DesktopSubNav
