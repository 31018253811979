import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, {
  cloneElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'

const Dialog = forwardRef(
  (
    {
      header,
      body,
      hideFooter,
      cancelLabel,
      cancelColor,
      continueLabel,
      continueColor,
      onContinue,
      handleClose,
      children,
      isLoading,
      isSubmittedDisabled,
      hasConfirmButton
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false)
    const onClose = () => setIsOpen(false)
    const cancelRef = React.useRef()

    const onOpen = (event) => {
      event.preventDefault()
      event.stopPropagation()

      setIsOpen(true)
    }

    useEffect(() => {
      return () => {
        setIsOpen(false)
      }
    }, [])

    useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
      close: onClose
    }))

    return (
      <>
        {children &&
          cloneElement(children, { onClick: (event) => onOpen(event) })}
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => handleClose(onClose)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              {header && (
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  {header}
                </AlertDialogHeader>
              )}

              {body && <AlertDialogBody>{body}</AlertDialogBody>}

              {!hideFooter && (
                <AlertDialogFooter>
                  <Flex gap={4}>
                    <Button
                      colorScheme={cancelColor}
                      ref={cancelRef}
                      onClick={() => handleClose(onClose)}
                      isDisabled={isLoading}
                    >
                      {cancelLabel}
                    </Button>
                    {hasConfirmButton && (
                      <Button
                        data-testid="dialog-confirm"
                        colorScheme={continueColor}
                        onClick={() => onContinue(onClose)}
                        isLoading={isLoading}
                        isDisabled={isSubmittedDisabled}
                      >
                        {continueLabel}
                      </Button>
                    )}
                  </Flex>
                </AlertDialogFooter>
              )}
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }
)

Dialog.defaultProps = {
  children: null,
  header: null,
  hideFooter: false,
  body: null,
  footer: null,
  handleClose: () => ({}),
  cancelColor: 'gray',
  cancelLabel: 'Cancel',
  continueColor: 'red',
  continueLabel: 'Continue',
  hasConfirmButton: true
}

Dialog.propTypes = {
  handleClose: PropTypes.func
}

Dialog.displayName = 'Dialog'

export default Dialog
