import { Heading, SimpleGrid, Switch } from '@chakra-ui/react'
import React from 'react'

const AttributeSwitch = ({ label, answer }) => {
  return (
    <SimpleGrid columnGap={2} columns={2}>
      <Heading size="sm" isTruncated>
        {label}
      </Heading>
      <Switch
        size="sm"
        isDisabled
        isChecked={answer}
        colorScheme="green"
        mb={0}
      >
        {answer ? 'Yes' : 'No'}
      </Switch>
    </SimpleGrid>
  )
}

export default AttributeSwitch
