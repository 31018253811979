import { useController, useFormContext } from 'react-hook-form'

const useComponent = ({ field, name }) => {
  const { control } = useFormContext()
  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid }
  } = useController({
    name,
    control,
    rules: {
      required: field.question_type !== 'instruction' ? field.compulsory : false
    },
    defaultValue: ''
  })

  const hasOptions =
    field.question_type === 'checkbox' ||
    field.question_type === 'select' ||
    field.question_type === 'radio_btn'

  const isRadio = field.question_type === 'radio_btn'

  const getFieldProps = () => {
    let props = {
      onChange,
      onBlur,
      value,
      name,
      isInvalid: invalid,
      size: 'sm',
      field
    }

    const isScale = field.question_type === 'scale'
    const isInstruction = field.question_type === 'instruction'
    const isCalculation = field.question_type === 'calculation'
    const isSignature = field.question_type === 'signature'
    const isAsset = field.question_type === 'asset'

    if (isCalculation) {
      return {}
    }

    if (isSignature) {
      props.onChange = async (value) => {
        let file = value
        if (value) {
          file = await fetch(value)
            .then((res) => res.blob())
            .then((blob) => {
              return [new File([blob], 'File name', { type: 'image/png' })]
            })
        } else {
          file = null
        }

        onChange(file)
      }
    }

    if (isAsset) {
      props.onChange = (values) => {
        onChange({ ...values, asset_id: Number(values.asset_id) })
      }
    }

    if (isScale) {
      props.interval =
        field?.question_settings_fields?.validations?.slider_values
    }

    if (hasOptions) {
      props.options = field.template_question_options.map((option) => ({
        label: option?.label,
        value: option?.label
      }))
      props.menuPortalTarget = document.body
      props.styles = {
        menuPortal: (provided) => ({ ...provided, zIndex: 10000 })
      }
    }

    if (isInstruction) {
      props.text = field.text
    }

    if (isRadio) {
      props.align = 'left'
    }

    return props
  }

  return {
    getFieldProps
  }
}

export default useComponent
