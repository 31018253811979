import { Box, Tag, Text } from '@chakra-ui/react'
import { Select as MultiSelect } from 'chakra-react-select'
import React from 'react'

const TemplateSelector = ({
  templatesList,
  onChangeTemplatesSelector,
  attributeKey,
  roleTypeLabel,
  defaultValue = [],
  pageTitle,
  register,
  readOnly
}) => {
  return (
    <Box w="50%">
      {!readOnly && roleTypeLabel && (
        <Text pb={2}>
          Select {pageTitle} to <Tag colorScheme="orange">{roleTypeLabel}</Tag>{' '}
          role
        </Text>
      )}
      <MultiSelect
        isMulti
        size="sm"
        isDisabled={readOnly}
        options={templatesList}
        value={defaultValue}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        selectedOptionStyle="check"
        {...register(attributeKey, { required: true })}
        onChange={(values) => onChangeTemplatesSelector(attributeKey, values)}
      />
    </Box>
  )
}

export default TemplateSelector
