import {
  Flex,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select
} from '@chakra-ui/react'
import { Select as MultiSelect } from 'chakra-react-select'
import { booleanValues } from 'pages/TemplateEditor/helpers'
import React from 'react'
import useLogicQuestions from './hooks/useLogicQuestions'

const LogicQuestions = ({
  questionType,
  getOptionLabel,
  optionsKey,
  currentKey,
  currentOptionsValue,
  onChangeOptions
}) => {
  const {
    getValues,
    setValue,
    handleFocusedField,
    handleNumberChange,
    optionLabel,
    register
  } = useLogicQuestions({ currentKey, getOptionLabel })

  const getOptions = () => {
    if (questionType === 'boolean') {
      return booleanValues.map((option, index) => {
        return (
          <option key={index} value={option.label}>
            {option.label}
          </option>
        )
      })
    }
    return getValues(optionsKey).map((option, index) => (
      <option key={index} value={option.label}>
        {option.label}
      </option>
    ))
  }

  if (['boolean', 'select', 'radio_btn', 'checkbox'].includes(questionType)) {
    const questionOptions = getValues(optionsKey)
    const shouldRenderMultiSelect = ['is one of', 'is not one of'].includes(
      optionLabel
    )
    if (shouldRenderMultiSelect) {
      const mappedOptions = questionOptions.map((option) => ({
        label: option?.label,
        value: option?.label
      }))
      return (
        <MultiSelect
          isMulti
          placeholder="Select options"
          {...register(`${currentKey}.value`, { required: true })}
          options={mappedOptions}
          defaultValue={currentOptionsValue}
          onChange={onChangeOptions}
          closeMenuOnSelect={false}
        />
      )
    }
    return (
      <Select
        minW="200px"
        {...register(`${currentKey}.value`, { required: true })}
        onChange={({ target: { value } }) => {
          const option = getValues(optionsKey).find(
            ({ label }) => label === value
          )
          setValue(`${currentKey}.value`, value)
          setValue(`${currentKey}._selectedOptionId`, option?.id || option?._id)
          setValue(`${currentKey}.aux_options`, [])
          setValue(`${currentKey}.aux_value`, '')
        }}
        onFocus={() => handleFocusedField(currentKey)}
      >
        {getOptions()}
      </Select>
    )
  }

  if (['counter', 'scale'].includes(questionType)) {
    return (
      <Flex>
        <NumberInput
          allowMouseWheel
          {...register(`${currentKey}.value`, { required: true })}
          onChange={(_, number) => handleNumberChange(number)}
          onFocus={() => handleFocusedField(currentKey)}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        {['between', 'not between'].includes(optionLabel) && (
          <NumberInput
            allowMouseWheel
            {...register(`${currentKey}.aux_value`)}
            onChange={(_, number) => handleNumberChange(number, true)}
            onFocus={() => handleFocusedField(currentKey)}
            ml="2"
            minW="50%"
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        )}
      </Flex>
    )
  }

  return (
    <Input
      minW="200px"
      {...register(`${currentKey}.value`, { required: true })}
      onFocus={() => handleFocusedField(currentKey)}
    />
  )
}

export default LogicQuestions
