import { theme } from '@chakra-ui/react'
import { rgba } from 'polished'

export const styles = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '18px',
  fontWeight: 'bold',
  backgroundColor: rgba(theme.colors.black, 0.5),
  color: theme.colors.white,
  opacity: 0,
  transition: 'opacity 0.2s ease',
  borderRadius: 'inherit',
  overflow: 'hidden',
  cursor: 'pointer',
  padding: theme.space[2],
  textAlign: 'center'
}
