import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Input, Stack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import FormSubmitActions from '../../../../../components/FormSubmitActions'
import { useKeyPress } from '../../../../../hooks'

const ActionCreateForm = ({
  fieldName,
  initialValue,
  placeholder,
  onSuccess,
  onCancel,
  as: Component,
  shouldPreventEnterEventBehavior
}) => {
  const [isLoading] = useState(false)
  const saveButtonRef = useRef(null)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()
  const enterPressed = useKeyPress(
    'Enter',
    true,
    shouldPreventEnterEventBehavior
  )
  const escPressed = useKeyPress('Escape', true, true)

  const onSubmit = (data) => {
    onSuccess(data)
  }

  useEffect(() => {
    if (
      shouldPreventEnterEventBehavior &&
      enterPressed &&
      saveButtonRef.current
    ) {
      saveButtonRef.current.click()
    }
  }, [enterPressed, shouldPreventEnterEventBehavior])

  useEffect(() => {
    if (escPressed) {
      onCancel()
    }
  }, [escPressed, onCancel])

  useEffect(() => {
    if (initialValue) {
      setValue(fieldName, initialValue)
    }
  }, [fieldName, initialValue, setValue])

  return (
    <Stack gap={1}>
      <Component
        isInvalid={!!errors[fieldName]}
        {...register(fieldName, { required: true })}
        w="100%"
        placeholder={placeholder}
        autoFocus
      />
      <FormSubmitActions
        my={0}
        cancelRoute={null}
        isLoading={isLoading}
        cancelAction={onCancel}
        size="sm"
        onSubmit={handleSubmit(onSubmit)}
        saveButtonRef={saveButtonRef}
      />
    </Stack>
  )
}

ActionCreateForm.defaultProps = {
  fieldName: 'name',
  placeholder: '',
  onSuccess: () => ({}),
  onCancel: () => ({}),
  initialValue: '',
  as: Input,
  shouldPreventEnterEventBehavior: true
}

ActionCreateForm.propTypes = {
  placeholder: PropTypes.string,
  fieldName: PropTypes.string,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  initialValue: PropTypes.string,
  as: PropTypes.any,
  shouldPreventEnterEventBehavior: PropTypes.bool
}

export default ActionCreateForm
