/* eslint-disable react/no-children-prop */
import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  SimpleGrid,
  Stack,
  Switch,
  Tag,
  Text,
  Tooltip
} from '@chakra-ui/react'
import React from 'react'
import { FixedSizeList as UsersList } from 'react-window'
import UsersSelector from '../../../pages/Form/FillForm/ActionsBar/ActionSidebar/UsersSelector'
import OlingaAPI from '../../../services/OlingaAPI'
import ListUser from './ListUser'
import useNotificationHandler from './hooks/useNotificationHandler'

const NotificationsHandler = ({
  selfFormNotifyKey,
  notificationsUsersKey,
  deletedNotifiedUsersKey,
  isNotifying,
  notifiedUsers,
  deletedNotifiedUsers,
  initiallyNotifiedUsers,
  checkboxAriaLabel,
  enableExternalUsersNotifications = false,
  enableSelfFormNotify = false,
  setValueMethod,
  watchValueMethod,
  subUsersList
}) => {
  const {
    checkedUsers,
    customUsers,
    handleBatchSelection,
    handleCheckUser,
    isMobile,
    onSelectCustomUsers,
    resetList,
    summaryList,
    usersList,
    userName,
    setUserName,
    isSelfNotifying,
    onToggleSelfNotify
  } = useNotificationHandler({
    notifiedUsers,
    selfFormNotifyKey,
    notificationsUsersKey,
    deletedNotifiedUsersKey,
    deletedNotifiedUsers,
    initiallyNotifiedUsers,
    setValueMethod,
    watchValueMethod,
    subUsersList
  })

  const ItemRow = ({ index, style }) => (
    <div style={style} key={index}>
      <ListItem my="1">{summaryList[index].attributes.email}</ListItem>
    </div>
  )

  return (
    <Stack gap={4}>
      {enableSelfFormNotify && (
        <Tooltip
          hasArrow
          label="When active, the user who submits this form will also receive a copy of it in their email."
        >
          <Box w="max-content">
            <Switch
              colorScheme="green"
              onChange={onToggleSelfNotify}
              isChecked={isSelfNotifying}
            >
              Send copy to the one who submitted
            </Switch>
          </Box>
        </Tooltip>
      )}
      {enableExternalUsersNotifications && (
        <Stack>
          <Text>External notified users</Text>
          <UsersSelector
            defaultUsers={customUsers}
            onSelect={onSelectCustomUsers}
            closeMenuOnSelect
            fetchCallback={OlingaAPI.emailInput}
            requestParams={[]}
          />
        </Stack>
      )}
      <Box minH="200px" p="3" shadow="sm">
        <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={4}>
          {!isMobile && (
            <Box>
              <Stack>
                <Box mt="3">
                  <Flex mb="2" align="center">
                    <div>Summary of notified users</div>
                    <Tag
                      ml="1"
                      size="sm"
                      colorScheme="blue"
                      aria-label="notified-users"
                    >
                      {summaryList?.length}
                    </Tag>
                  </Flex>
                  <Divider />
                  <List spacing={3}>
                    <UsersList
                      height={250}
                      itemCount={summaryList?.length}
                      width="100%"
                      itemSize={30}
                    >
                      {ItemRow}
                    </UsersList>
                  </List>
                </Box>
              </Stack>
            </Box>
          )}
          {isNotifying && (
            <Box>
              <Stack>
                <SimpleGrid columns={{ sm: 1, md: 2 }}>
                  <Flex align="end">
                    <Checkbox
                      isChecked={notifiedUsers?.length === usersList?.length}
                      onChange={handleBatchSelection}
                      mb="0"
                      mr="1"
                      aria-label={checkboxAriaLabel}
                    />
                    <div>Users list</div>
                    <Tag
                      ml="1"
                      size="sm"
                      colorScheme="blue"
                      aira-label="total-users"
                    >
                      {usersList?.length}
                    </Tag>
                    {!!userName && (
                      <Tag
                        colorScheme="blue"
                        cursor="pointer"
                        ml="1"
                        size="sm"
                        variant="outline"
                        onClick={resetList}
                      >
                        Reset
                      </Tag>
                    )}
                  </Flex>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<SearchIcon mb="2" color="gray.300" />}
                    />
                    <Input
                      size="sm"
                      placeholder="User email"
                      value={userName}
                      onChange={({ target: { value } }) => setUserName(value)}
                    />
                  </InputGroup>
                </SimpleGrid>
                <Divider />
                <Stack w="100%">
                  <UsersList
                    height={250}
                    itemCount={usersList?.length}
                    width="100%"
                    itemSize={35}
                  >
                    {({ index, style }) => (
                      <ListUser
                        key={index}
                        user={usersList[index]}
                        isChecked={!!checkedUsers[Number(usersList[index].id)]}
                        style={style}
                        handleCheckUser={handleCheckUser}
                      />
                    )}
                  </UsersList>
                </Stack>
              </Stack>
            </Box>
          )}
        </SimpleGrid>
      </Box>
    </Stack>
  )
}

NotificationsHandler.defaultProps = {
  notifiedUsers: [],
  subUsersList: []
}

export default NotificationsHandler
