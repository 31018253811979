import {
  Box,
  Button,
  CloseButton,
  Flex,
  Stack,
  Tag,
  Text,
  Textarea,
  theme
} from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import UploadFiles from '../../../../components/Inputs/UploadFile'
import ActionCreateForm from './ActionCreateForm'
import ActionsAccordion from './ActionsAccordion'
import { RENDER_FORM_TYPES } from './constants'
import useActionsBar from './hooks/useActionsBar'
import Notes from './Notes'
import MaterialIcon from 'components/MaterialIcon'

const { ACTION, FILES } = RENDER_FORM_TYPES

const ActionsBar = ({
  initialActions,
  template,
  fieldType,
  field,
  currentKey,
  hasBorder,
  shouldDisablePhotosAndNotes,
  isNested
}) => {
  const {
    handleFilesChange,
    handleNotesControl,
    handleFormRender,
    handleChangeNotes,
    handleSelectAction,
    shouldRenderActionForm,
    shouldRenderFilesForm,
    shouldRenderNotesForm,
    files,
    notes,
    actions,
    actionFormValues,
    isEditingNotes,
    setIsEditingNotes
  } = useActionsBar({
    initialActions,
    template,
    fieldType,
    field,
    currentKey
  })

  const { setValue } = useFormContext()

  return (
    <Stack
      position="relative"
      py={4}
      borderTop={hasBorder && `1px dashed ${theme.colors.gray[200]}`}
      paddingLeft={!actions?.length && isNested ? 4 : 0}
      _after={{
        content: !actions?.length && isNested ? "''" : 'none',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-80%)',
        height: 10,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderColor: theme.colors.gray[400],
        width: 4
      }}
    >
      {shouldRenderFilesForm && (
        <Box
          p={2}
          border={`1px solid ${theme.colors.gray[300]}`}
          borderRadius={4}
        >
          <Flex w="100%" justify="space-between" align="center">
            <Text>Photos ({files?.length ?? 0})</Text>
            <CloseButton
              onClick={() =>
                setValue(`${currentKey}.shouldRenderFilesForm`, false)
              }
            />
          </Flex>
          <UploadFiles
            initialFiles={files}
            onChange={handleFilesChange}
            maxFiles={5}
          />
        </Box>
      )}
      <Notes
        notes={notes}
        isEditingNotes={isEditingNotes}
        setIsEditingNotes={setIsEditingNotes}
        handleChangeNotes={handleChangeNotes}
      />
      {shouldRenderNotesForm && !isEmpty(actionFormValues) && (
        <ActionCreateForm
          placeholder={actionFormValues.label}
          onSuccess={actionFormValues.onSuccess}
          onCancel={actionFormValues.onCancel}
          fieldName={actionFormValues.fielName}
          icon={actionFormValues.icon}
          as={Textarea}
          shouldPreventEnterEventBehavior={false}
        />
      )}
      {actions?.length > 0 && (
        <ActionsAccordion
          actions={actions}
          onOpenSidebar={handleSelectAction}
        />
      )}
      {shouldRenderActionForm && !isEmpty(actionFormValues) && (
        <ActionCreateForm
          placeholder={actionFormValues.label}
          onSuccess={actionFormValues.onSuccess}
          onCancel={actionFormValues.onCancel}
          fieldName={actionFormValues.fielName}
          icon={actionFormValues.icon}
        />
      )}
      <Flex justify="space-between" gap={2}>
        <div>
          <Button
            size="sm"
            variant="outline"
            isDisabled={shouldDisablePhotosAndNotes || shouldRenderActionForm}
            colorScheme="blue"
            leftIcon={<MaterialIcon icon='edit_note' />}
            onClick={handleNotesControl}
            aria-label={`Create notes to ${currentKey}`}
          >
            <Flex align="center" gap={2}>
              <span>{isEmpty(notes) ? 'Add notes' : 'Edit notes'}</span>
              {shouldDisablePhotosAndNotes && <Tag color="gray">Soon</Tag>}
            </Flex>
          </Button>
        </div>
        <Flex gap={2}>
          {!['image', 'instruction'].includes(field.question_type) && (
            <Button
              size="sm"
              variant="outline"
              colorScheme="blue"
              leftIcon={<MaterialIcon icon='image' />}
              onClick={() => handleFormRender(FILES)}
              isDisabled={shouldDisablePhotosAndNotes}
            >
              <Flex align="center" gap={2}>
                <span>Photos</span>
                {shouldDisablePhotosAndNotes && <Tag color="gray">Soon</Tag>}
              </Flex>
            </Button>
          )}
          <Button
            size="sm"
            leftIcon={<MaterialIcon icon='library_add_check' />}
            colorScheme="blue"
            disabled={false}
            onClick={() => handleFormRender(ACTION)}
            aria-label={`Create action to ${currentKey}`}
          >
            Action
          </Button>
        </Flex>
      </Flex>
    </Stack>
  )
}

ActionsBar.defaultProps = {
  initialActions: [],
  hasBorder: true,
  isAllowedToCreateActionWithEmptyAnswer: true,
  shouldDisablePhotosAndNotes: false,
  isNested: false
}

ActionsBar.propTypes = {
  initialActions: PropTypes.array,
  fieldType: PropTypes.string,
  template: PropTypes.object.isRequired,
  index: PropTypes.number,
  hasBorder: PropTypes.bool,
  sectionRepeatAttributes: PropTypes.any,
  shouldDisablePhotosAndNotes: PropTypes.bool
}

export default memo(ActionsBar)
