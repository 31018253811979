import { CheckIcon, CopyIcon, EditIcon } from '@chakra-ui/icons'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Kebab from '../../../components/Kebab'
import MaterialIcon from '../../../components/MaterialIcon'
import { chakraComponentsSizes, handleTemplateClone } from '../../../helpers'

const TemplateKebab = ({
  id,
  permissions,
  size,
  icon,
  hasBorder,
  zIndex,
  ariaLabel,
  renderPortal,
  onClose,
  pageTitle
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()

  const currentRoute = useMemo(() => {
    const path = location.pathname
    if (path.match('fill_form')) {
      return 'fillForm'
    } else if (path.match('edit')) {
      return 'templateSettings'
    } else if (path.match('forms')) {
      return 'templateForm'
    } else if (path.match(/templates\/([0-9]+)(\/)?$/)) {
      return 'showTemplate'
    }
    return undefined
  }, [location])

  const items = useMemo(() => {
    const itemsResult = [
      {
        name: 'Fill Form',
        icon: <CheckIcon />,
        route: 'fillForm',
        isVisible: permissions.fill_form
      },
      {
        name: `Edit ${pageTitle}`,
        icon: <EditIcon />,
        route: 'templateSettings',
        isVisible: permissions.edit_form
      },
      {
        name: `Duplicate ${pageTitle}`,
        icon: <CopyIcon />,
        route: null,
        callback: () => handleTemplateClone(id, setIsLoading),
        isVisible: permissions.clone_form
      },
      {
        name: 'Form responses',
        icon: <MaterialIcon icon='playlist_add_check' styles={{ fontSize: '16px' }} />,
        route: 'templateForm',
        isVisible: permissions.forms_list
      },
      {
        name: `${pageTitle} structure`,
        icon: <MaterialIcon type='material-icons-outlined' icon='featured_play_list' styles={{ fontSize: '16px' }}/>,
        route: 'showTemplate',
        isVisible: permissions?.template_structure
      },
      {
        name: 'Create scheduled report',
        icon: <MaterialIcon icon='insert_chart_outlined' styles={{ fontSize: '16px' }}/>,
        route: 'newTemplateReport',
        isVisible: permissions?.create_scheduled_report,
        searchParams: `?template_id=${id}`,
        type: 'scheduled'
      },
      {
        name: 'Create summary report',
        icon: <MaterialIcon icon='insert_chart_outlined' styles={{ fontSize: '16px' }}/>,
        route: 'newSummaryTemplateReport',
        isVisible: permissions?.create_summary_report,
        searchParams: `?template_id=${id}`
      },
      {
        name: 'Form Schedule',
        icon: <MaterialIcon icon='calendar_month' styles={{ fontSize: '16px' }}/>,
        route: 'templateFrequencies',
        isVisible: permissions?.form_frequency_settings
      }
    ]

    return itemsResult.filter(({ isVisible }) => isVisible)
  }, [permissions, id, pageTitle])

  return (
    <Kebab
      id={id}
      size={size}
      zIndex={zIndex}
      hasBorder={hasBorder}
      icon={icon}
      items={items}
      blackList={[currentRoute]}
      blackListAttribute="route"
      isLoading={isLoading}
      hasVisibilityRestriction
      ariaLabel={ariaLabel}
      renderPortal={renderPortal}
      onClose={onClose}
    />
  )
}

TemplateKebab.defaultProps = {
  size: 'sm',
  zIndex: 10,
  hasBorder: true,
  permissions: {},
  renderPortal: false,
  onClose: noop,
  pageTitle: 'template'
}

TemplateKebab.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(chakraComponentsSizes),
  pageTitle: PropTypes.string,
  hasBorder: PropTypes.bool,
  permissions: PropTypes.object,
  ariaLabel: PropTypes.string,
  renderPortal: PropTypes.bool,
  onClose: PropTypes.func
}

export default TemplateKebab
