import styled from '@emotion/styled'

export const Container = styled.div`
  margin-top: -21px;
  ${({ maxWidth }) =>
    maxWidth &&
    `
    width: 100%;
    max-width: ${maxWidth};
  `}

  ${({ minWidth }) =>
    minWidth &&
    `
    min-width: ${minWidth};
  `}

  & .ql-container.ql-snow {
    border: 1px solid #e2e8f0;

    min-height: 40px;
    ${({ hasToolbar }) =>
      !hasToolbar &&
      `
        border-radius: 6px;
    `}
  }

  & .ql-editor {
    padding: 10px 15px;
  }

  ${({ focusBorderColor, isInvalid, focused }) =>
    (focused || isInvalid) &&
    `
      & .ql-container.ql-snow {
        border-color: ${focusBorderColor};
        box-shadow: 0 0 0 1px ${focusBorderColor};
      }
  `}
`
