import { CloseIcon, HamburgerIcon, Search2Icon } from '@chakra-ui/icons'
import {
  Avatar,
  Box,
  Link as ChakraLink,
  Collapse,
  Flex,
  IconButton,
  Img,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  theme
} from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import masqueradePath from '../../../assets/images/masquerade.svg'
import { getRouteUrl } from '../../helpers'
import TemplateKebab from '../../pages/Templates/TemplateKebab'
import Autocomplete from '../Autocomplete'
import DesktopNav from './DesktopNav'
import MobileNav from './MobileNav'
import Notifications from './Notifications'
import { Logo, LogoWrapper } from './styles'
import useNavbar from './useNavbar'
import MaterialIcon from '../MaterialIcon'

const Navbar = () => {
  const {
    navbar,
    isOpen,
    onToggle,
    onClose,
    colors,
    userName,
    isAdmin,
    userMasquerade,
    getAutocompleteCallback,
    handleAutocompleteSelect,
    onAutocompleteOpen,
    renderKey,
    onSignOut,
    onBackMasquerade,
  } = useNavbar()

  return (
    <Box>
      <Flex
        bg={colors.bg}
        color={navbar.text}
        py={{ base: 2 }}
        px={{ base: 8 }}
        borderBottom={1}
        borderStyle="solid"
        borderColor={navbar.border}
        align="center"
        position="fixed"
        top="0"
        left="0"
        w="100%"
        zIndex={theme.zIndices.overlay}
        id="olinga-navbar"
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', lg: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
            transition="background 0.3s ease"
            _active={{
              bg: 'transparent'
            }}
            _focus={{
              bg: 'transparent'
            }}
            _hover={{
              bg: navbar.bg
            }}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', lg: 'start' }}>
          <Link to={navbar.dashboardUrl ?? '#'}>
            <LogoWrapper>
              <Logo src={navbar.logo_path} alt="Olinga Logo" />
            </LogoWrapper>
          </Link>

          <Flex display={{ base: 'none', lg: 'flex' }} flex={1} ml={4}>
            <DesktopNav navbar={navbar} renderKey={renderKey} />
          </Flex>
        </Flex>
          {!isAdmin && userMasquerade && (
            <ChakraLink onClick={onBackMasquerade}>
              <Stack mr='20px'>
                <Img w="15px" src={masqueradePath} />
              </Stack>
            </ChakraLink>
          )}
        <Stack
          flex={{ base: 1, lg: 0 }}
          justify="flex-end"
          direction="row"
          spacing={6}
        >
          {!isAdmin && (
            <Popover onOpen={onAutocompleteOpen}>
              <PopoverTrigger>
                <IconButton
                  icon={<Search2Icon />}
                  variant="ghost"
                  size="sm"
                  aria-label="Search Templates"
                  _hover={{
                    bg: navbar.bg
                  }}
                  _active={{
                    bg: navbar.bg
                  }}
                  _focus={{
                    bg: navbar.bg
                  }}
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody p={4}>
                  <Autocomplete
                    asyncCallback={getAutocompleteCallback}
                    placeholder="Search templates"
                    attributeName="name"
                    resultEndAdornment={(template) => (
                      <TemplateKebab
                        permissions={template.userPermissions}
                        id={template.id}
                        size="xs"
                      />
                    )}
                    onSelect={(result) => handleAutocompleteSelect(result.id)}
                    minWidth="100%"
                    groupByAttribute="formFolderName"
                    getNestedAttribute="attributes"
                    hasFixedResults
                    shouldAutofocus
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          )}
          {isAdmin === false && <Notifications />}
          <Menu>
            <MenuButton
              size="sm"
              bg={navbar.bg}
              as={Avatar}
              borderWidth={1}
              borderColor={navbar.order}
              cursor="pointer"
            />
            <Portal>
              <MenuList
                bg="white"
                shadow="md"
                sx={{ '& > *': { cursor: 'pointer' } }}
              >
                {!isAdmin && (
                  <MenuGroup title={`${userName}`}>
                    <Link
                      to={getRouteUrl('editUser')}
                      style={{ color: 'black', textDecoration: 'none' }}
                    >
                      <MenuItem as={Flex} gap={2} align="center">
                        <MaterialIcon styles={{fontSize: '16px'}} icon='key' />
                        <Text>Change Password</Text>
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                )}
                <MenuItem
                  onClick={onSignOut}
                  color="black"
                  as={Flex}
                  gap={2}
                  align="center"
                >
                  <MaterialIcon styles={{fontSize: '16px'}} icon='logout' />
                  <Text>Sign out</Text>
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav navbar={navbar} onClose={onClose} />
      </Collapse>
    </Box>
  )
}

export default Navbar
