import { Button, Stack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../../components/PageHeader'
import { getRouteUrl } from '../../../helpers'
import ActionCard from './ActionCard'
import ActionDetailsBreadcrumb from './ActionDetailsBreadcrumb'
import BusinessCard from './BusinessCard'
import QuestionCard from './QuestionCard'
import useActionDetails from './useActionDetails'

const ActionDetails = ({ data }) => {
  const { action, singularTitle, pageTitle } = useActionDetails({ data })
  if (!action?.id) {
    return null
  }
  return (
    <Stack>
      <PageHeader
        Breadcrumb={
          <ActionDetailsBreadcrumb action={action} pageTitle={pageTitle} />
        }
        title={`${singularTitle} ${action.id}`}
        Actions={
          <>
            <Link to={getRouteUrl('actions')}>
              <Button>Cancel</Button>
            </Link>
          </>
        }
      />
      <Stack gap={4}>
        <ActionCard {...action} />
        <BusinessCard {...(action.business?.data?.attributes ?? {})} />
        <QuestionCard {...(action?.templateQuestion?.data?.attributes ?? {})} />
        <Link to={getRouteUrl('actions')}>
          <Button>Cancel</Button>
        </Link>
      </Stack>
    </Stack>
  )
}

export default ActionDetails
