import { DownloadIcon } from '@chakra-ui/icons'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Stack,
  Tooltip
} from '@chakra-ui/react'
import EmptyState from 'components/EmptyState'
import MaterialIcon from 'components/MaterialIcon'
import { isEmpty } from 'lodash'
import React from 'react'

const ItemDownloadSidebar = ({
  isOpen,
  onClose,
  items,
  setSelectedItem,
  onDownloadItem,
  selectLabel,
}) => {
  return (
    <>
      <Drawer size="md" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Please, select an asset type</DrawerHeader>

          <DrawerBody>
            <Stack w="100%" gap={4} overflowY="auto">
              {isEmpty(items) ? (
                <EmptyState text="There are no asset types" />
                
              ) : (
                (items ?? [])?.map((item) => {
                  const { value, label } = item
                  return (
                    <Stack
                      key={value}
                      borderRadius="md"
                      borderWidth={1}
                      borderColor="gray.200"
                      p={4}
                      gap={2}
                    >
                      <Tooltip label={label}>
                        <Heading
                          noOfLines={2}
                          size="sm"
                        >{`Name: ${label}`}</Heading>
                      </Tooltip>
                      <Flex justify="flex-end" gap={2}>
                        <Button
                          onClick={() => {
                            setSelectedItem(item)
                            onDownloadItem(item)
                          }}
                          leftIcon={<DownloadIcon />}
                          colorScheme="blue"
                          size="sm"
                        >
                          Download .csv Template
                        </Button>
                        <Button
                          colorScheme="green"
                          leftIcon={
                            <MaterialIcon icon='center_focus_weak' />
                          }
                          onClick={() =>
                            setSelectedItem(item)
                          }
                          size="sm"
                        >
                          {selectLabel}
                        </Button>
                      </Flex>
                    </Stack>
                  )
                })
              )}
            </Stack>
          </DrawerBody>

          <DrawerFooter display="flex" gap={4} align="center">
            <Button colorScheme="gray" onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

ItemDownloadSidebar.defaultProps = {
  selectLabel: 'Select asset type'
}

export default ItemDownloadSidebar
