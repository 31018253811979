import { AddIcon } from '@chakra-ui/icons'
import { Button, Flex, Stack } from '@chakra-ui/react'
import PageHeader from 'components/PageHeader'
import Table from 'components/Table'
import { getRouteUrl } from 'helpers'
import React from 'react'
import { Link } from 'react-router-dom'
import useManageDashboards from './hooks/useManageDashboards'

const ManageDashboards = () => {
  const {
    hasPermissionToCreateDashboard,
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data,
    isLoading,
    refetch,
    pageTitle,
    onDragEnd,
  } = useManageDashboards()

  return (
    <Stack as="section">
      <PageHeader
        Actions={
          <Flex flexDir={['column', 'row']} gap={4} align="center">
            {hasPermissionToCreateDashboard && (
              <Link to={getRouteUrl('newDashboard', { from: 'dashbords' })}>
                <Button colorScheme="blue" leftIcon={<AddIcon />}>
                  New
                </Button>
              </Link>
            )}
          </Flex>
        }
        title={pageTitle}
      />
      <Table
        isLoading={isLoading}
        data={data}
        columns={columns}
        fetchPage={refetch}
        initiallyHiddenColumns={hiddenColumns}
        persistedColumnsKey={hiddenColumnsKey}
        shouldShowPagination={false}
        customColumnStyles={(cell) => {
          if (['order'].includes(cell?.column?.id)) {
            return {
              width: ['20px']
            }
          }
        }}
        onDragEnd={onDragEnd}
        isDraggeable
      />
    </Stack>
  )
}

export default ManageDashboards
