import {
  Box,
  Flex,
  Heading,
  ListItem,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
  Switch,
  Tag,
  Text,
  UnorderedList
} from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import React from 'react'
import Answer from '..'
import FloatingLabelBox from '../../../../../components/FloatingLabelBox'
import AssetAnswer from '../../AssetAnswer'
import ImageAnswer from '../../ImageAnswer'

const CurrentAnswer = ({ answer }) => {
  const type = answer?.question_type || answer?.type
  const { name, children, id } = answer
  if (['section', 'logic'].includes(type)) {
    return (
      <Stack>
        {answer?.total_repeats && (
          <Flex w="100%" justify="flex-end">
            <Text
              fontSize="xs"
              dangerouslySetInnerHTML={{
                __html: `* Number of repetitions: <b>${answer.total_repeats}</b>`
              }}
              color="blue.700"
            />
          </Flex>
        )}
        <FloatingLabelBox
          delay="2s"
          margin="16px 0px 0px 0px"
          borderColor={type === 'section' ? 'blue.600' : 'cyan.400'}
          label={name || 'logics'}
        >
          {children.map((child, index) => (
            <Answer answer={child} key={index} />
          ))}
        </FloatingLabelBox>
      </Stack>
    )
  }
  if (type === 'boolean') {
    const { text: questionLabel, value: questionAnswer } = answer.answer
    return (
      <Stack>
        <Heading
          size="sm"
          dangerouslySetInnerHTML={{ __html: questionLabel }}
        />
        <Flex align="center">
          <Text mr={2} fontSize="sm">
            {questionAnswer}
          </Text>
          <Switch
            size="sm"
            isDisabled
            isChecked={questionAnswer?.toLowerCase() === 'yes'}
            colorScheme="green"
            mb={0}
          />
        </Flex>
        {children.map((child, index) => (
          <Answer answer={child} key={index} />
        ))}
      </Stack>
    )
  }
  if (type === 'scale') {
    const { text: questionLabel, value: questionAnswer } = answer.answer
    const { question_settings_fields: questionSettingsFields } = answer
    const { min, max } =
      questionSettingsFields?.validations?.slider_values || {}

    return (
      <Stack>
        <Heading
          size="sm"
          dangerouslySetInnerHTML={{ __html: questionLabel }}
        />
        <Stack>
          <Slider
            isDisabled
            aria-label={`slider`}
            defaultValue={Number(questionAnswer)}
            min={min}
            max={max}
          >
            {min && <SliderMark value={min}>{min}</SliderMark>}
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
            {max && (
              <SliderMark value={max} ml="-5.5">
                {max}
              </SliderMark>
            )}
          </Slider>
          <Text pt="5px" size="sm">
            Value: {questionAnswer}
          </Text>
        </Stack>
        {children.map((child, index) => (
          <Answer answer={child} key={index} />
        ))}
      </Stack>
    )
  }
  if (['checkbox', 'list'].includes(type)) {
    const { text: questionLabel, value: questionAnswer } = answer.answer
    return (
      <Stack>
        <Heading
          size="sm"
          dangerouslySetInnerHTML={{ __html: questionLabel }}
        />
        <UnorderedList pl={4}>
          {questionAnswer.split(`,`).map((value, index) => (
            <ListItem key={id + '-' + index}>
              <Text size="sm" dangerouslySetInnerHTML={{ __html: value }} />
            </ListItem>
          ))}
        </UnorderedList>
        {children.map((child, index) => (
          <Answer answer={child} key={index} />
        ))}
      </Stack>
    )
  }
  if (['free_field_multiline', 'instruction'].includes(type)) {
    let questionLabel = 'Instruction'
    let questionAnswer = ''
    if (type === 'free_field_multiline') {
      questionLabel = answer.answer.text
      questionAnswer = answer.answer.value.replace(/(\r\n|\n|\r)/gm, '<br/>')
    } else {
      questionAnswer = answer.text
    }
    return (
      <Box>
        <FloatingLabelBox
          delay="2s"
          borderColor={type === 'instruction' ? 'gray.400' : 'orange.300'}
          label={questionLabel}
        >
          <Text
            size="sm"
            dangerouslySetInnerHTML={{ __html: questionAnswer }}
          />
        </FloatingLabelBox>
      </Box>
    )
  }
  if (type === 'image') {
    const { image, attachments, text: questionLabel } = answer.answer
    if (image) {
      return (
        <Stack>
          <Heading
            size="sm"
            dangerouslySetInnerHTML={{ __html: questionLabel }}
          />
          <ImageAnswer images={[image]} />
        </Stack>
      )
    }
    if (attachments && !isEmpty(attachments)) {
      return (
        <Stack>
          <Heading
            size="sm"
            dangerouslySetInnerHTML={{ __html: questionLabel }}
          />
          <ImageAnswer images={attachments} />
        </Stack>
      )
    }
    return (
      <Tag w="max-content" colorScheme="gray">
        Empty
      </Tag>
    )
  }
  if (type === 'asset') {
    const { text: questionLabel, asset } = answer.answer
    return (
      <Stack>
        <Heading
          size="sm"
          dangerouslySetInnerHTML={{ __html: questionLabel }}
        />
        <AssetAnswer
          label={asset.label}
          name={asset.template_name}
          fields={asset.fields}
        />
      </Stack>
    )
  }
  return (
    <Stack>
      <Heading
        size="sm"
        dangerouslySetInnerHTML={{ __html: answer?.answer?.text }}
      />
      <Text
        fontSize="sm"
        dangerouslySetInnerHTML={{ __html: answer?.answer?.value }}
      />
      {children?.map((child, index) => (
        <Answer answer={child} key={index} />
      ))}
    </Stack>
  )
}

export default CurrentAnswer
