import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Input,
  Select,
  Stack,
  Text
} from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import useTemplateEditorSettings from './useTemplateEditorSettings'

const TemplateEditorSettings = ({
  isOpen,
  onClose,
  questionTypes = [],
  logicOptions = {}
}) => {
  const { data: defaultValues, saveSettings } = useTemplateEditorSettings()
  const btnRef = useRef(null)

  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: defaultValues
  })

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  const onSubmit = (newData) => {
    saveSettings(newData)
    onClose()
  }

  const defaultLogicQuestionType = useWatch({
    name: 'logic_default_question_type',
    control,
    defaultValue: defaultValues['logic_default_question_type']
  })

  if (!defaultValues) {
    return null
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Template Editor Settings</DrawerHeader>
        <DrawerBody>
          <form>
            <Box borderBottom="1px" borderBottomColor="gray.600" pb={4}>
              <Heading mb="2" as="h2" size="md" isTruncated>
                Questions settings
              </Heading>
              <Checkbox
                {...register('question_is_active')}
                defaultIsChecked={!!defaultValues['question_is_active']}
              >
                Is active
              </Checkbox>
              <Stack mb="12px">
                <Text fontSize="xs">Default label</Text>
                <Input
                  placeholder="Default label"
                  {...register('question_default_value')}
                  defaultValue={defaultValues['question_default_value']}
                />
              </Stack>
              <Stack mb="12px">
                <Text fontSize="xs">Default question type</Text>
                <Select
                  placeholder="Default question type"
                  {...register('question_default_question_type')}
                >
                  {questionTypes.map((option) => (
                    <option value={option.value} key={uuid()}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </Stack>
            </Box>
            <Box borderBottom="1px" borderBottomColor="gray.600" py={4}>
              <Heading mb="2" as="h2" size="md" isTruncated>
                Sections settings
              </Heading>
              <Stack mb="12px">
                <Text fontSize="xs">Default label</Text>
                <Input
                  placeholder="Default label"
                  {...register('section_default_value')}
                  defaultValue={defaultValues['section_default_value']}
                />
              </Stack>
            </Box>
            <Box borderBottom="1px" borderBottomColor="gray.600" py={4}>
              <Heading mb="2" as="h2" size="md" isTruncated>
                Logics settings
              </Heading>
              <Text fontSize="xs" mb="8px">
                * Will only work for root level logics
              </Text>
              <Checkbox
                {...register('logic_is_active')}
                defaultIsChecked={!!defaultValues['logic_is_active']}
              >
                Is active
              </Checkbox>
              <Stack mb="12px">
                <Text fontSize="xs">Default label</Text>
                <Input
                  placeholder="Default label"
                  {...register('logic_default_value')}
                  defaultValue={defaultValues['logic_default_value']}
                />
              </Stack>
              <Stack mb="12px">
                <Text fontSize="xs">Default question type</Text>
                <Select
                  placeholder="Default question type"
                  {...register('logic_default_question_type')}
                  defaultValue={defaultValues['logic_default_question_type']}
                >
                  {questionTypes.map((option) => (
                    <option value={option.value} key={uuid()}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </Stack>
              {defaultLogicQuestionType && (
                <Stack mb="12px">
                  <Text fontSize="xs">Default condition</Text>
                  <Select
                    placeholder="Default question type"
                    {...register('logic_default_condition')}
                    defaultValue={defaultValues['logic_default_condition']}
                  >
                    {logicOptions &&
                      logicOptions[defaultLogicQuestionType] &&
                      logicOptions[defaultLogicQuestionType].map((option) => (
                        <option value={option.id} key={uuid()}>
                          {option.text}
                        </option>
                      ))}
                  </Select>
                </Stack>
              )}
            </Box>
            <Box borderBottom="1px" borderBottomColor="gray.600" py={4}>
              <Heading mb="2" as="h2" size="md" isTruncated>
                Instructions settings
              </Heading>
              <Checkbox
                {...register('instruction_is_active')}
                defaultIsChecked={!!defaultValues['instruction_is_active']}
              >
                Is active
              </Checkbox>
              <Stack mb="12px">
                <Text fontSize="xs">Default label</Text>
                <Input
                  placeholder="Default label"
                  {...register('instruction_default_value')}
                  defaultValue={defaultValues['instruction_default_value']}
                />
              </Stack>
            </Box>
          </form>
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)} colorScheme="blue">
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default TemplateEditorSettings
