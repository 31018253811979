import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Breadcrumb from '../../../../components/Breadcrumb'
import { getRouteUrl } from '../../../../helpers'

const BreadcrumbChecklist = ({
  id,
  readOnly,
  name,
  pageTitle,
  singularTitle
}) => {
  const history = useHistory()
  const options = useMemo(() => {
    const items = [
      {
        title: pageTitle,
        url: getRouteUrl('checklists')
      }
    ]
    if (id) {
      if (history?.location?.pathname?.includes('tasks')) {
        items.push({
          title: 'Task Tracker',
          route: getRouteUrl('checklistTaskTraker', { id })
        })
      } else {
        items.push({
          title: readOnly ? `Show ${singularTitle}` : `Edit ${singularTitle}`
        })
      }
      if (name) {
        items.push({
          title: name
        })
      }
    } else {
      items.push({
        title: `New ${singularTitle}`,
        url: getRouteUrl('createChecklist')
      })
    }
    return items
  }, [
    history?.location?.pathname,
    id,
    name,
    pageTitle,
    readOnly,
    singularTitle
  ])
  return <Breadcrumb routeTree={options} />
}

export default BreadcrumbChecklist
