import { CloseIcon, EditIcon } from '@chakra-ui/icons'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Stack,
  useDisclosure
} from '@chakra-ui/react'
import EmptyState from 'components/EmptyState'
import { isEmpty } from 'lodash'
import AssetAnswer from 'pages/Form/FormDetails/AssetAnswer'
import React, { useRef } from 'react'
import AssetQuestionCard from './AssetQuestionCard'

const AssetQuestion = ({ value, onChange, field, isInvalid }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const assets = field?.question_assets

  const ref = useRef()

  const onChangeAsset = (asset) => {
    onChange(asset)

    onClose()
  }

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={ref}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading size="md">Choose Asset</Heading>
            <Button
              size="sm"
              variant="ghost"
              mr="8"
              leftIcon={<CloseIcon />}
              colorScheme="red"
              onClick={() => {
                onChange({ value: null, asset_id: null })
                onClose()
              }}
            >
              Clear
            </Button>
          </DrawerHeader>

          <DrawerBody mb={8}>
            <Stack gap={4}>
              {isEmpty(assets) ? (
                <EmptyState text="There are no assets for this question" />
              ) : (
                assets.map((asset) => (
                  <AssetQuestionCard
                    {...asset}
                    key={asset.label}
                    value={value}
                    onChange={onChangeAsset}
                  />
                ))
              )}
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <Button colorScheme="gray" onClick={onClose}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      {value?.value ? (
        <AssetAnswer
          name={value?.name}
          label={value?.label}
          fields={value?.fields}
        >
          <Button
            ref={ref}
            colorScheme="blue"
            variant="ghost"
            onClick={onOpen}
            leftIcon={<EditIcon />}
          >
            Choose another asset
          </Button>
        </AssetAnswer>
      ) : (
        <Button
          ref={ref}
          colorScheme={isInvalid ? 'red' : 'blue'}
          variant="ghost"
          onClick={onOpen}
          leftIcon={<EditIcon />}
        >
          Choose Asset
        </Button>
      )}
    </>
  )
}

export default AssetQuestion
