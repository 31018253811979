import { theme } from '@chakra-ui/react'

export const getStyles = (isMobile) => ({
  borderRadius: 2,
  border: `1px solid ${theme.colors.gray[400]}`,
  p: 4,
  display: 'flex',
  align: 'flex-start',
  justify: 'space-between',
  flexDir: isMobile ? 'column-reverse' : 'row'
})
