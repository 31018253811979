import { getRouteUrl, setFormErrors } from 'helpers'
import { usePageProperties, useQuery } from 'hooks'
import {
  first,
  get,
  isBoolean,
  isEmpty,
  isString,
  noop,
  pick,
  trim
} from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Notification from 'services/Notification'
import OlingaAPI from 'services/OlingaAPI'

const useAssetEditor = ({ data, id }) => {
  const history = useHistory()
  const initialImageFormRef = useRef()

  const [shouldDisplayAssetTypeForm, setShouldDisplayAssetTypeForm] =
    useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFetchingAssetTypes, setIsFetchingAssetTypes] = useState(false)
  const [isPerformingDialogConfirmAction, setIsPerformingDialogConfirmAction] =
    useState(false)

  const query = useQuery()

  const selectedAssetTypeRef = useRef()
  const changeAssetTypeDialogRef = useRef(null)
  const initialFormStateRef = useRef(null)

  const { asset, assetTypes } = useMemo(() => {
    const { asset_templates } = data?.enumerators?.data ?? {
      asset_templates: []
    }
    if (id && data?.asset) {
      return {
        asset: {
          id,
          ...(data?.asset?.data?.attributes ?? {})
        },
        assetTypes: asset_templates
      }
    }
    return {
      assetTemplate: null,
      assetTypes: asset_templates
    }
  }, [data, id])

  const {
    shouldCreate: hasPermissionToEdit,
    shouldRender: hasPermissionToView
  } = usePageProperties({
    pageName: 'assets'
  })

  const formMethods = useForm({
    defaultValues: {
      asset_template_id: '',
      active: true,
      asset_fields_attributes: [],
      attachment_attributes: { media: null }
    }
  })

  const { handleSubmit, setError, setValue, getValues } = formMethods

  const onSubmit = async (data) => {
    try {
      const endpoint = id ? 'updateAsset' : 'newAsset'
      setIsLoading(true)
      const formatValue = (field) => {
        switch (field?.field_type) {
          case 'list':
            if (isString(field?.value?.value)) {
              return field.value.value
            } else if (isString(field?.value)) {
              return field.value
            }
            return ''
          case 'date':
            return moment(field.value).isValid()
              ? moment(field.value).format('DD/MM/YYYY')
              : field.value
          default:
            return field.value
        }
      }
      let formData = {
        ...data,
        asset_template_id: data?.asset_template_id?.value,
        asset_fields_attributes: data?.asset_fields_attributes?.map((field) => {
          return {
            ...pick(field, [
              'asset_template_field_id',
              'id',
              'value',
              '_destroy'
            ]),
            value: formatValue(field),
            _destroy: false
          }
        })
      }
      if (
        !formData?.attachment_attributes?.media ||
        (id &&
          initialImageFormRef.current?.url ===
            formData?.attachment_attributes?.media)
      ) {
        delete formData.attachment_attributes
      }
      if (
        initialImageFormRef.current?.url &&
        !data?.attachment_attributes?.media
      ) {
        formData = {
          ...formData,
          attachment_attributes: {
            _destroy: true,
            id: initialImageFormRef.current.id
          }
        }
      }
      const response = await OlingaAPI[endpoint](id, formData)
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: response?.data?.message
        })

        history.push(getRouteUrl('assets'))
      }
    } catch (error) {
      setFormErrors(error, setError, Notification)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmitForm = () => {
    handleSubmit(onSubmit)()
  }

  const onFetchAssetType = useCallback(
    async (assetId) => {
      return new Promise((resolve, reject) => {
        setIsFetchingAssetTypes(true)
        OlingaAPI.assetTemplate(assetId)
          .then((response) => {
            const fields =
              response?.data?.data?.attributes?.asset_template_fields
            if (fields && !isEmpty(fields)) {
              setValue(
                'asset_fields_attributes',
                fields.map((field) => {
                  const assetTypeField = asset?.asset_fields?.find(
                    ({ asset_template_field_id }) =>
                      asset_template_field_id === field.id
                  )
                  return {
                    id: assetTypeField?.id,
                    asset_template_field_id: field.id,
                    field_type: field.field_type,
                    options: (get(field, 'settings.list_options') ?? []).map(
                      (option) => {
                        if (option?._id) {
                          const { name } = option
                          return {
                            label: name,
                            value: name
                          }
                        }
                        return {
                          label: option,
                          value: option
                        }
                      }
                    ),
                    name: field.name,
                    value: '',
                    _active: field?.active,
                    active: id ? assetTypeField?.active : true,
                    _destroy: false,
                    main: field?.main
                  }
                })
              )
              setShouldDisplayAssetTypeForm(true)

              resolve(fields)
            } else {
              setValue('asset_fields_attributes', [])
              Notification({
                icon: 'error',
                text: `This asset type doesn't have fields`
              })
            }
          })
          .catch((error) => {
            setFormErrors(error, noop, Notification)

            reject(error)
          })
          .finally(() => {
            setIsFetchingAssetTypes(false)
          })
      })
    },
    [asset?.asset_fields, setValue, id]
  )

  const setFormDefaultValues = useCallback(
    (updatedAsset) => {
      const currentAsset = updatedAsset ?? asset
      const getListValue = (value) => {
        const trimmedValue = trim(value)
        if (!trimmedValue) {
          return null
        }
        if (trimmedValue?.includes(';')) {
          const [firstValue] = trimmedValue.split(';')
          return { label: firstValue, value: firstValue }
        }
        return { label: trimmedValue, value: trimmedValue }
      }
      onFetchAssetType(currentAsset.asset_template_id)
        .then((fields) => {
          const assetTemplateId = assetTypes.findIndex(
            ({ value }) => value === Number(currentAsset.asset_template_id)
          )
          const assetFields = (currentAsset?.asset_fields ?? []).map(
            (field) => {
              const { value, asset_template_field_id } = field
              const fieldTypeIndex = fields?.findIndex(
                ({ id }) => id === asset_template_field_id
              )
              const fieldType = fields?.[fieldTypeIndex]?.field_type
              switch (fieldType) {
                case 'list':
                  return {
                    index: fieldTypeIndex,
                    value: getListValue(value)
                  }
                case 'date': {
                  let date = value
                  if (!date) {
                    return { index: fieldTypeIndex, value: '' }
                  }
                  if (
                    date.match(
                      /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/
                    )
                  ) {
                    const [day, month, year] = date.split('/')
                    date = `${month}-${day}-${year}`
                  } else {
                    const [year, month, day] = date.split('-')
                    date = `${month}-${first(day.split('T'))}-${year}`
                  }
                  return {
                    index: fieldTypeIndex,
                    value: date
                  }
                }
                default:
                  return { index: fieldTypeIndex, value }
              }
            }
          )
          if (assetTemplateId >= 0) {
            setValue('asset_template_id', assetTypes[assetTemplateId])
          }
          assetFields.forEach(({ value, index }) =>
            setValue(`asset_fields_attributes.${index}.value`, value)
          )

          setValue('active', currentAsset?.active)

          const image = currentAsset?.image?.url

          setValue('attachment_attributes.media', image)

          initialImageFormRef.current = currentAsset?.image

          initialFormStateRef.current = {
            asset_template_id: assetTemplateId,
            acitve: currentAsset?.acitve,
            image,
            asset_fields_attributes: currentAsset?.asset_fields.map(
              (field, index) => ({
                ...field,
                value: assetFields[index]
              })
            )
          }
        })
        .catch(() => {
          //
        })
    },
    [asset, assetTypes, onFetchAssetType, setValue]
  )

  const handleUpdatedAsset = async () => {
    try {
      const response = await OlingaAPI.asset(id)
      const updatedAsset = {
        id: response?.data?.data?.id,
        ...(response?.data?.data?.attributes ?? {})
      }
      setFormDefaultValues(updatedAsset)
    } catch (error) {
      setFormErrors(error, noop, Notification)
    }
  }

  const onSelectAssetType = (selectedAssetType) => {
    try {
      const filledFields = getValues('asset_fields_attributes')
      selectedAssetTypeRef.current = selectedAssetType
      if (id && !isEmpty(filledFields) && filledFields.every(({ id }) => id)) {
        changeAssetTypeDialogRef.current.open()
        return
      }
      if (
        id &&
        initialFormStateRef?.current?.asset_template_id?.value ===
          selectedAssetType?.value
      ) {
        handleUpdatedAsset()
        return
      }
      setShouldDisplayAssetTypeForm(false)
      setValue('asset_template_id', selectedAssetType)
      if (selectedAssetType?.value) {
        onFetchAssetType(selectedAssetType?.value)
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    }
  }

  const onConfirmAssetTypeChange = async (onClose) => {
    setIsPerformingDialogConfirmAction(true)
    try {
      const response = await OlingaAPI.updateAsset(id, {
        asset_fields_attributes: getValues('asset_fields_attributes').map(
          (assetField) => ({
            id: assetField?.id,
            active: false
          })
        )
      })

      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: 'All fields were inactived successfully'
        })
        setValue('asset_template_id', selectedAssetTypeRef.current)
        onFetchAssetType(selectedAssetTypeRef.current?.value)
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    } finally {
      setIsPerformingDialogConfirmAction(false)

      onClose()
    }
  }

  const onCancelDialog = (onClose) => {
    onClose()
  }

  useEffect(() => {
    if (asset?.id) {
      setFormDefaultValues()
    } else if (query?.asset_template_id) {
      setFormDefaultValues({
        asset_template_id: query?.asset_template_id,
        active: true
      })
    }
  }, [asset?.id, query?.asset_template_id, setFormDefaultValues])

  useEffect(() => {
    if (isBoolean(hasPermissionToView) && !hasPermissionToView) {
      history.push(getRouteUrl('assets'))
    }
  }, [history, hasPermissionToView])

  return {
    asset,
    assetTypes,
    formMethods,
    onSubmitForm,
    onSelectAssetType,
    shouldDisplayAssetTypeForm,
    isLoading,
    isFetchingAssetTypes,
    isPerformingDialogConfirmAction,
    changeAssetTypeDialogRef,
    onConfirmAssetTypeChange,
    onCancelDialog,
    hasPermissionToEdit
  }
}

export default useAssetEditor
