import { Divider, Flex, Input, Stack, Textarea } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import FormField from 'components/FormField'
import FormSubmitActions from 'components/FormSubmitActions'
import ListBuilder from 'components/ListBuilder'
import PageHeader from 'components/PageHeader'
import ActionConfirmSelector from 'components/Selectors/ActionConfirm'
import { first } from 'lodash'
import React, { useState } from 'react'
import ChartItem from './ChartItem'
import ManageDashboardEditorBreadcrumb from './ManageDashboardEditorBreadcrumb'
import useManageDashboardEditor from './hooks/useManageDashboardEditor'

const ManageDashboardEditor = ({ data, id }) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    enumerators,
    hasPermissionToEdit,
    onChangeCharts,
    onSubmit,
    isLoading,
    onRemoveChart,
    onAddChart,
    getDashboardPreview,
    previewModalRef,
    isLoadingPreview,
    preview,
    formData
  } = useManageDashboardEditor({
    id,
    data
  })

  const [charts, settings] = watch(['charts_attributes', 'settings'])
  const [clearChartSourceData, setClearChartSourceData] = useState(false)

  return (
    <>
      <Stack as="section">
        <PageHeader
          Breadcrumb={
            <ManageDashboardEditorBreadcrumb
              id={id}
              hasPermissionToEdit={true}
            />
          }
          title={
            id && hasPermissionToEdit
              ? 'Edit Dashboard'
              : id && !hasPermissionToEdit
              ? 'View Dashboard'
              : 'New Dashboard'
          }
        />
        <Flex w='50%' gap={2}>
          <FormField
            label="Context"
            infoLabel={enumerators?.dashboard?.descriptions?.context}
          >
            <ActionConfirmSelector
              register={register}
              onConfirm={(newContext) => {
                setValue('settings.context', newContext)
                if (newContext?.filters.length === 1) {
                  setValue('settings.filter', first(newContext?.filters))
                } else {
                  setValue('settings.filter', '')
                }

                setClearChartSourceData(true)
              }}
              value={settings?.context}
              placeholder="Context"
              formKey={'settings.context'}
              options={enumerators?.dashboard?.contexts}
              isRequired
            />
          </FormField>
          <FormField
            label="Filter"
            infoLabel={enumerators?.dashboard?.descriptions?.filter}
          >
            <Select
              {...register('settings.filter', { required: true })}
              placeholder="Filter"
              value={settings?.filter}
              options={settings?.context?.filters}
              isDisabled={!settings?.context?.value}
              onChange={(newDateFilter) => {
                setValue('settings.filter', newDateFilter)
              }}
            />
          </FormField>
        </Flex>
        <FormField label="Title">
          <Input
            {...register('title', { required: true })}
            placeholder="Title"
          />
        </FormField>
        <FormField label="Description" isRequired={false}>
          <Textarea
            {...register('description')}
            placeholder="Description"
          />
        </FormField>
        <Divider />
        <FormField label="Charts">
          <ListBuilder
            list={charts ?? []}
            resourceName="Chart"
            ItemComponent={ChartItem}
            onChange={onChangeCharts}
            onRemoveItem={onRemoveChart}
            onAddItem={onAddChart}
            customRemoveItemAction={(index, _, fallback) => fallback(index)}
            idAttribute="_id"
            allowDelete={() => true}
            allowDrag={() => true}
            itemProps={{
              id: formData?.dashboard?.data?.attributes?.id,
              register,
              watch,
              setValue,
              getValues,
              enumerators,
              context: settings?.context,
              chartSources: settings?.context?.chart_sources,
              clearChartSourceData,
              setClearChartSourceData,
              itemKey: 'charts_attributes',
              getDashboardPreview,
              previewModalRef,
              isLoadingPreview,
              preview
            }}
          />
        </FormField>
        <FormSubmitActions
          cancelRoute="manageDashboards"
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      </Stack>
    </>
  )
}

export default ManageDashboardEditor
