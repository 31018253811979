import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Stack,
  Switch,
  Text,
  theme
} from '@chakra-ui/react'
import React from 'react'
import FormSubmitActions from '../../../../components/FormSubmitActions'
import PageHeader from '../../../../components/PageHeader'
import AndroidIcon from '../icons/Android'
import IOSIcon from '../icons/IOS'
import useSettingsEditor from './hooks/useSettingsEditor'
import SettingsEditorBreadcrumb from './SettingsEditorBreadcrumb'

const SettingsEditor = ({ data, id }) => {
  const {
    register,
    watch,
    onChangeAppVersion,
    onSubmitForm,
    isLoading,
    errors
  } = useSettingsEditor({
    data,
    id
  })

  const [android, ios] = watch([
    'mobile_app_version.android',
    'mobile_app_version.ios'
  ])

  return (
    <>
      <PageHeader
        Breadcrumb={<SettingsEditorBreadcrumb id={id} name={'Setting'} />}
      />
      <Container maxW="1200px">
        <Box w="100%">
          <Stack gap={4} w="100%">
            <Stack
              borderRadius="md"
              borderWidth={1}
              borderColor="gray.200"
              p={4}
            >
              <Heading size="sm">Settings</Heading>
              <Divider />
              <Flex flexDir={['column', 'row']} gap={4}>
                <Stack w="100%">
                  <Flex gap={2}>
                    <Text>Android Version</Text>
                  </Flex>
                  <InputGroup>
                    <InputLeftAddon bg="transparent">
                      <Box
                        sx={{
                          svg: {
                            w: 8,
                            h: 8,
                            fill: theme.colors.green[400]
                          }
                        }}
                      >
                        <AndroidIcon />
                      </Box>
                    </InputLeftAddon>
                    <Input
                      {...register('mobile_app_version.android', {
                        required: true
                      })}
                      onChange={({ target: { value } }) => {
                        onChangeAppVersion('mobile_app_version.android', value)
                      }}
                      value={android}
                      placeholder="X.X.X"
                      isInvalid={!!errors?.mobile_app_version?.android}
                    />
                    <InputRightAddon bg="transparent">
                      <Switch
                        {...register('mobile_force_update.android')}
                        mt={2}
                        cursor="pointer"
                      >
                        Force Update
                      </Switch>
                    </InputRightAddon>
                  </InputGroup>
                </Stack>
                <Stack w="100%">
                  <Text>iOS Version</Text>
                  <InputGroup>
                    <InputLeftAddon bg="transparent">
                      <Box
                        sx={{
                          svg: {
                            w: 8,
                            h: 8,
                            fill: theme.colors.blue[400]
                          }
                        }}
                      >
                        <IOSIcon />
                      </Box>
                    </InputLeftAddon>
                    <Input
                      {...register('mobile_app_version.ios', {
                        required: true
                      })}
                      onChange={({ target: { value } }) => {
                        onChangeAppVersion('mobile_app_version.ios', value)
                      }}
                      value={ios}
                      placeholder="X.X.X"
                      isInvalid={!!errors?.mobile_app_version?.ios}
                    />
                    <InputRightAddon bg="transparent">
                      <Switch
                        {...register('mobile_force_update.ios')}
                        mt={2}
                        cursor="pointer"
                      >
                        Force Update
                      </Switch>
                    </InputRightAddon>
                  </InputGroup>
                </Stack>
              </Flex>
            </Stack>
            <FormSubmitActions
              isLoading={isLoading}
              isAdmin
              cancelRoute="businesses"
              onSubmit={onSubmitForm}
            />
          </Stack>
        </Box>
      </Container>
    </>
  )
}

export default SettingsEditor
