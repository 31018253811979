import { chunk, isEmpty, isObject, toLower } from 'lodash'

export const changeQuestionsOrder = ({
  indexes = {},
  source,
  destination,
  questionsArray = []
}) => {
  if (!isObject(source) || !isObject(destination) || isEmpty(questionsArray)) {
    return
  }
  const sourceIndex = source.index
  const destinationIndex = destination.index

  const copy = [...questionsArray]

  const [element] = copy.splice(sourceIndex, 1)
  copy.splice(destinationIndex, 0, element)
  indexes.clearKeys()

  return copy
}

export const getDepthLevel = (depthKey, currentFields) => {
  const keyMap = chunk(depthKey.split('.'), 2)
  const depthLevel = keyMap.reduce((acc, obj) => {
    if (isEmpty(Object.keys(acc))) {
      acc = currentFields
    }
    const [key, value] = obj
    const currentLevel = acc[key][value]
    acc = currentLevel
    return acc
  }, {})

  return depthLevel
}

export const getElementTypeFromKey = (key, getValues) => {
  const element = getValues(key)
  if (element) {
    if (element.question_type === 'instruction') {
      return element.question_type
    }
    return element.origin
  }
}

export const updateOrder = (questions) => {
  if (questions) {
    questions.forEach((question, index) => (question.order = index))
  }
}

export const updateNestedQuestions = (depthKey, getValues, callback) => {
  const currentFields = {
    questions: getValues().questions
  }
  const depthLevel = getDepthLevel(depthKey, currentFields)
  const { updatedQuestions, ...other } = callback(depthLevel, depthKey)
  depthLevel.questions = updatedQuestions
  return { updatedQuestions: currentFields.questions, ...other }
}

export const getPerformActionOnElementAttributes = (key) => {
  const splittedKey = key.split('.')
  const questionPosition = splittedKey.slice(-1)
  const index = Number(questionPosition)

  const newPosition = Number(questionPosition) + 1
  splittedKey[splittedKey.length - 1] = newPosition

  const focusedElement = splittedKey.join('.')
  const isRootElement = chunk(splittedKey, 2).length === 1

  return { newPosition, focusedElement, isRootElement, index }
}

const getHandleOptionsAttributes = (optionsKey) => {
  const split = optionsKey.split('.')
  const key = split.slice(0, -1).join('.')
  const keyAttribute = split.slice(-1)
  return { key, keyAttribute }
}

export const getOption = (nestedKey, values, customValues = null) => {
  const { key, keyAttribute } = getHandleOptionsAttributes(nestedKey)
  const attributes = {
    questions: customValues || values.questions
  }
  const level = getDepthLevel(key, attributes)
  return level[keyAttribute] || null
}

export const getTypeFromAction = (action) => {
  const lowerAction = toLower(action)
  if (lowerAction.includes('question')) {
    return 'question'
  }
  if (lowerAction.includes('instruction')) {
    return 'instruction'
  }
  if (lowerAction.includes('section')) {
    return 'section'
  }
  if (lowerAction.includes('logic')) {
    return 'logic'
  }
  return null
}

export const findParentNodeByCondition = (startNode, callbackCondition) => {
  let currentNode = startNode
  const parentNodes = []
  while (currentNode && !callbackCondition(currentNode)) {
    parentNodes.unshift(currentNode)
    currentNode = currentNode.parentNode
  }
  return currentNode
}
