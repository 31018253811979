import { snakeCase, toLower } from 'lodash'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  IndexMap,
  getLinearStructure
} from '../../pages/TemplateEditor/helpers'
import TemplateEditorSettings from './TemplateEditorSettings'
import { formatLogicOptions } from './helpers'
import useDynamicDependencies from './hooks/useDynamicDependencies'
import useEventsActions from './hooks/useEventsActions'

const defaultAppPermissions = {
  value: 'no_access',
  label: 'No Access'
}

const TemplateEditorContext = createContext({})

export default function TemplateEditorProvider({
  data,
  pageTitle,
  singularTitle,
  children
}) {
  const {
    template,
    structure,
    folders: formFolderList,
    enumerators: {
      calculationFieldsOptions,
      formsPermissionsOptions: formPermissions,
      questionLogicOptions: logicOptions,
      questionTypesOptions: questionTypes
    },
    subUsersList
  } = data

  const [operationsDependencies, setOperationsDependencies] = useState({
    structure: {},
    operations: {}
  })

  useEffect(() => {
    setOperationsDependencies(getLinearStructure(calculationFieldsOptions))
  }, [calculationFieldsOptions])

  const indexes = useMemo(() => {
    return new IndexMap()
  }, [])

  const [errors, setErrors] = useState([])

  const initialDataRef = useRef({})

  const {
    ghostElement,
    isTemplateSettingsOpen,
    onCloseTemplateSettings,
    focusedField,
    handleFocusedField
  } = useEventsActions(data?.lastFocusedField)

  const options = useMemo(
    () => formatLogicOptions(logicOptions ?? []),
    [logicOptions]
  )

  useDynamicDependencies()

  return (
    <TemplateEditorContext.Provider
      value={{
        defaultAppPermissions,
        indexes,
        focusedField,
        handleFocusedField,
        setErrors,
        errors,
        template,
        pageTitle,
        singularTitle,
        ghostElement,
        initialDataRef,
        formFolderList,
        formPermissions: (formPermissions ?? []).map((permission) => ({
          ...permission,
          value: snakeCase(toLower(permission.value))
        })),
        logicOptions: options,
        questionTypes: (questionTypes ?? []).filter(
          (question) => question.value !== 'instruction'
        ),
        subUsersList,
        operationsDependencies,
        calculationFieldsOptions,
        structure
      }}
    >
      <TemplateEditorSettings
        isOpen={isTemplateSettingsOpen}
        onClose={onCloseTemplateSettings}
        questionTypes={questionTypes}
        logicOptions={options}
      />
      {children}
    </TemplateEditorContext.Provider>
  )
}

export const useTemplateEditorContext = () => {
  const context = useContext(TemplateEditorContext)

  return {
    ...context
  }
}
