import { Tooltip } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import { sanitizeHTML } from '../../pages/Reports/ReportsEditor/helpers'

const TextClamper = ({
  lines = 1,
  text,
  isHtml = false,
  basedOn = 'letters'
}) => {
  const [isClamped, setIsClamped] = useState(false)
  const handleReflow = useCallback(({ clamped }) => {
    setIsClamped(clamped)
  }, [])
  if (isHtml) {
    return (
      <Tooltip hasArrow label={isClamped ? sanitizeHTML(text) : ''}>
        <div>
          <HTMLEllipsis
            unsafeHTML={text}
            maxLine={lines}
            ellipsis="..."
            basedOn={basedOn}
            onReflow={handleReflow}
          />
        </div>
      </Tooltip>
    )
  }
  return (
    <Tooltip hasArrow label={isClamped ? text : ''}>
      <div>
        <LinesEllipsis text={text} maxLine={3} />
      </div>
    </Tooltip>
  )
}

export default TextClamper
