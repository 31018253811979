import { MenuItem, MenuList } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { getRouteUrl } from '../../../helpers'

const KebabMenuList = ({
  zIndex,
  kebabItems,
  otherIds,
  ariaLabel,
  isLoading,
  id,
  onSelect,
  from = ''
}) => (
  <MenuList zIndex={zIndex}>
    {kebabItems.map(({ name, icon, route, callback, searchParams }, index) => {
      return route ? (
        <Link
          key={index}
          data-testid="kebab-item-link"
          to={
            from
              ? {
                  pathname: getRouteUrl(route, {
                    id,
                    searchParams,
                    ...otherIds
                  }),
                  from
                }
              : getRouteUrl(route, { id, searchParams, ...otherIds })
          }
        >
          <MenuItem
            aria-label={`${ariaLabel} option`}
            isDisabled={isLoading}
            icon={icon}
            onClick={onSelect}
          >
            {name}
          </MenuItem>
        </Link>
      ) : (
        <MenuItem
          aria-label={`${ariaLabel} option`}
          isDisabled={isLoading}
          data-testid="kebab-item"
          onClick={() => {
            onSelect()
            callback()
          }}
          key="clone"
          icon={icon}
          color="#2d3748"
        >
          {name}
        </MenuItem>
      )
    })}
  </MenuList>
)
export default KebabMenuList
