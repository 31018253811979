/* eslint-disable react-hooks/exhaustive-deps */
import { cloneDeep, pick } from 'lodash'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { handleErrors } from 'services/api'
import { useTemplateEditorContext } from '../../../../../contexts'
import { useGlobalStateContext } from '../../../../../contexts/GlobalStateContext'
import {
  globalEvents,
  onChangeTemplateSettingsTab,
  onRefreshForm
} from '../../../../../contexts/GlobalStateContext/events'
import { getRouteUrl } from '../../../../../helpers'
import { useGlobalEventListener } from '../../../../../hooks'
import {
  buildErrorsKeys,
  convertAttributesToSubmit
} from '../../../../../pages/TemplateEditor/helpers'
import Notification from '../../../../../services/Notification'
import OlingaAPI from '../../../../../services/OlingaAPI'

const useFormSubmission = ({ hasHeader, methods, template, indexes }) => {
  const { handleSubmit, control, reset } = methods
  const [isLoading, setIsLoading] = useState(false)
  const { global$ } = useGlobalStateContext()
  const { setErrors, focusedField } = useTemplateEditorContext()
  const history = useHistory()

  const onsubmit = useCallback(
    async (submitData) => {
      const clonedSubmitData = cloneDeep(submitData)
      try {
        const completeFormData = convertAttributesToSubmit(submitData)
        let formData = completeFormData
        if (!hasHeader) {
          formData = pick(completeFormData, [
            'template_questions_attributes',
            'section_questions_attributes'
          ])
        }
        setIsLoading(true)
        const result = template?.id
          ? await OlingaAPI.templatesUpdate(
              { id: template?.id },
              { template: { ...formData } }
            )
          : await OlingaAPI.templatesCreate(formData)
        if (result.data?.success) {
          setIsLoading(false)
          if (template?.id) {
            Notification({
              icon: 'success',
              text: 'Template updated',
              position: !hasHeader
                ? toast.POSITION.TOP_RIGHT
                : toast.POSITION.TOP_CENTER
            })
            if (result.data?.warnings?.hasCalculatedFields) {
              global$.next(
                onChangeTemplateSettingsTab({
                  id: 'calculated_fields'
                })
              )
            }
            global$.next(onRefreshForm(focusedField))
          } else {
            Notification({
              icon: 'success',
              text: 'Template created successfully',
              position: toast.POSITION.TOP_RIGHT
            })
            history.push(
              getRouteUrl('templateSettings', { id: result?.data?.data?.id })
            )
          }
        } else {
          indexes.clearKeys()
          reset(clonedSubmitData)
          setIsLoading(false)
          handleErrors(result)
        }
      } catch (error) {
        indexes.clearKeys()
        reset(clonedSubmitData)
        setIsLoading(false)
      }
    },
    [focusedField, global$, hasHeader, history, indexes, reset, template?.id]
  )

  const handleSubmitErrors = useCallback(
    (errors) => {
      setErrors(buildErrorsKeys(errors))
    },
    [setErrors]
  )

  const onPartialSubmit = useCallback(
    async ({ data, onSuccess }) => {
      setIsLoading(true)
      const submitData = data
      try {
        const response = await OlingaAPI.templatesUpdate(
          { id: template?.id },
          { template: submitData }
        )
        if (response?.success) {
          onSuccess()

          Notification({
            icon: 'success',
            text: 'Template updated',
            position: toast.POSITION.TOP_RIGHT
          })
        }
      } catch (error) {
        //
      } finally {
        setIsLoading(false)
      }
    },
    [template?.id]
  )

  const onSubmit = useCallback(() => {
    handleSubmit(onsubmit, handleSubmitErrors)()
  }, [handleSubmit, handleSubmitErrors, onsubmit])

  useGlobalEventListener(
    useCallback(
      (event) => {
        switch (event.type) {
          case globalEvents.ON_TEMPLATE_SETTINGS_TAB_CHANGE: {
            const isDirty = control._getDirty()
            if (event?.payload?.id !== 'edit_form' && isDirty) {
              handleSubmit(onSubmit, handleSubmitErrors)()
            }
            break
          }
          case globalEvents.ON_DESTROY_TEMPLATE_TYPE_QUESTION_ASSET_TYPES_ASSOCIATIONS: {
            const data = event.payload
            onPartialSubmit(data)
            break
          }
        }
      },
      [control, handleSubmit, onSubmit, handleSubmitErrors, onPartialSubmit]
    )
  )

  return {
    onSubmit,
    isLoading
  }
}

export default useFormSubmission
