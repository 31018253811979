/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { Badge, IconButton } from './styles';

const IconBadge = ({Icon, badgeColor, buttonColor, value}) => {
  return (
    <IconButton
      color={buttonColor}
      size='sm'
      icon={
        <>
          {Icon}
          {value && <Badge color={badgeColor}>{value}</Badge>}
        </>
      }
    />
  );
};

IconBadge.propTypes = {
  Icon: PropTypes.element,
  badgeColor: PropTypes.string,
  buttonColor: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconBadge.defaultProps = {
  badgeColor: '#3ba87e',
  buttonColor: '#fff',
  value: '',
};

export default IconBadge;
