import { EditIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  SimpleGrid,
  Stack,
  Switch,
  Tag
} from '@chakra-ui/react'
import { capitalize, isEmpty, sortBy } from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { getRouteUrl } from '../../../../../helpers'
import FakeInput from './FakeInput'
import MaterialIcon from 'components/MaterialIcon'

const formLabelProps = { fontSize: 'sm' }

const ChecklistItem = ({
  id,
  data,
  index,
  listKey,
  onEdit,
  frequencies,
  readOnly,
  isEdit,
  displayCompulsory
}) => {
  const repeatValues = useMemo(() => {
    const frequency = frequencies.find(({ value }) => value === listKey)
    const key = listKey.replace(/ly/g, '')
    const orderedDays = frequency?.options?.[`${key}Days`] ?? []
    if (!isEmpty(orderedDays) && !isEmpty(data?.repeat_values)) {
      return data?.repeat_values
        .reduce((acc, v) => {
          const item = orderedDays.find(({ value }) => v === value)
          acc.push(item)

          acc = sortBy(acc, 'order')
          return acc
        }, [])
        .map(({ value }) => capitalize(value))
    } else if (frequency?.value === 'monthly') {
      return data.repeat_values
    } else if (frequency?.value === 'quarterly') {
      return data.repeat_values.map((item) => ({
        ...item,
        date:
          item?.quarterly_day && item?.month
            ? moment()
                .set('D', item.quarterly_day)
                .set('M', item.month - 1)
                .format('DD/MM')
            : null
      }))
    } else if (!isEmpty(data?.repeat_values)) {
      return data?.repeat_values?.map(({ value }) => capitalize(value))
    }
    return []
  }, [data?.repeat_values, frequencies, listKey])

  return (
    <Stack p={[0, 4]}>
      <Flex
        w="100%"
        flexDir={['column', 'row']}
        justify={['flex-start', 'space-between']}
        align={['flex-start', 'center']}
        gap={4}
      >
        <FormControl flex={[1, 2, 6]}>
          <FormLabel>
            <FormLabel {...formLabelProps}>Task</FormLabel>
            <FakeInput>{data?.title}</FakeInput>
          </FormLabel>
        </FormControl>
        <FormLabel mt="8" display="flex">
          <FormLabel {...formLabelProps}>Active</FormLabel>
          <Switch isChecked={data?.active} isDisabled />
        </FormLabel>
        {!readOnly && (
          <Button
            size="sm"
            mt={[0, 5]}
            colorScheme="blue"
            leftIcon={<EditIcon />}
            onClick={() => onEdit(listKey, index)}
          >
            Edit task
          </Button>
        )}
        {data?.id && !isEdit && (
          <Link
            to={getRouteUrl('showChecklistUsersTasks', {
              id,
              itemId: data.id
            })}
          >
            <Button
              size="sm"
              mt={[0, 5]}
              colorScheme="orange"
              variant="outline"
              leftIcon={<MaterialIcon icon='list_alt' />}
              rightIcon={
                <Tag colorScheme="orange" size="sm">
                  {data?.usersTasksCount ?? 0}
                </Tag>
              }
            >
              Users tasks
            </Button>
          </Link>
        )}
      </Flex>
      {readOnly && (
        <Stack gap={4} w="100%">
          <Flex
            w="100%"
            flexDir={['column', 'row']}
            justify={['flex-start', 'space-between']}
            align="center"
            gap={4}
          >
            {data?.description && (
              <FormControl>
                <FormLabel>
                  <FormLabel {...formLabelProps}>Description</FormLabel>
                </FormLabel>
                <FakeInput minHeight="80px">{data?.description}</FakeInput>
              </FormControl>
            )}
            <Stack mt="auto" gap={4} minW="max-content">
              <FormControl>
                <Switch mb="0" isDisabled isChecked={data?.active}>
                  Active
                </Switch>
              </FormControl>
              {displayCompulsory && (
                <FormControl>
                  <Switch mb="0" isDisabled isChecked={data?.compulsory}>
                    Compulsory
                  </Switch>
                </FormControl>
              )}
            </Stack>
          </Flex>
          <SimpleGrid spacing={4} columns={[1, 2]}>
            <FormControl>
              <FormLabel>Frequency</FormLabel>
              <FakeInput>{data?.frequency?.label}</FakeInput>
            </FormControl>
            <FormControl>
              <FormLabel>Completion Limit</FormLabel>
              <FakeInput>
                {data?.completion_limit === 0
                  ? 'Unlimited'
                  : data?.completion_limit}
              </FakeInput>
            </FormControl>
          </SimpleGrid>
        </Stack>
      )}
      {listKey === 'weekly' && (
        <Stack w="100%">
          <FormControl
            flexWrap="wrap"
            display="flex"
            alignItems="center"
            gap={4}
          >
            <FormLabel m={0}>Repeats on:</FormLabel>
            {repeatValues &&
              repeatValues.map((item, index) => (
                <Tag m={0} colorScheme="orange" key={index}>
                  {item}
                </Tag>
              ))}
          </FormControl>
        </Stack>
      )}
      {listKey === 'monthly' && (
        <Stack w="100%">
          <FormControl
            flexWrap="wrap"
            display="flex"
            alignItems="center"
            gap={4}
          >
            <FormLabel m={0}>Repeats on day:</FormLabel>
            <Tag m={0} colorScheme="orange" key={index}>
              {repeatValues?.month_day}
            </Tag>
          </FormControl>
        </Stack>
      )}
      {listKey === 'quarterly' && !isEmpty(data?.repeat_values) && (
        <Stack w="100%">
          <FormControl
            flexWrap="wrap"
            display="flex"
            alignItems="center"
            gap={4}
          >
            <FormLabel m={0}>Repeats on:</FormLabel>
            {repeatValues &&
              repeatValues?.map((item, index) => (
                <Tag
                  m={0}
                  colorScheme={item?.date ? 'orange' : 'gray'}
                  key={index}
                >
                  {`${item?.date ?? 'Empty'}`}
                </Tag>
              ))}
          </FormControl>
        </Stack>
      )}
    </Stack>
  )
}
ChecklistItem.defaultProps = {
  displayCompulsory: false
}

export default ChecklistItem
