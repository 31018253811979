import { IconButton } from '@chakra-ui/react'
import MaterialIcon from 'components/MaterialIcon'
import React from 'react'

const RemoveFocusButton = ({ isActive, focusedField, removeFocus }) => {
  if (!isActive && focusedField) {
    return (
      <IconButton
        aria-label="Remove element focus"
        colorScheme="real"
        onClick={removeFocus}
        icon={<MaterialIcon icon='crop' />}
      />
    )
  }
  return null
}

export default RemoveFocusButton
