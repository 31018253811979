import { HStack, Text } from '@chakra-ui/react'
import React from 'react'
import AttributeSwitch from '../../../pages/TemplateStructure/TemplateDetails/AttributeSwitch'
import BaseStructure from '../BaseStructure'
import QuestionLayout from '../QuestionLayout'

const Instruction = (props) => {
  const { id, children, text, main, active, compulsory } = props

  return (
    <BaseStructure
      id={id}
      type="instruction"
      hasChildren={children.length}
      text={text}
      otherProps={{ ...props }}
    >
      <QuestionLayout
        leftSlot={
          <HStack spacing={2}>
            <Text fontWeight="bold">Question: </Text>
            <Text dangerouslySetInnerHTML={{ __html: text }} />
          </HStack>
        }
        rightSlot={
          <>
            {main && <AttributeSwitch label="Main" answer={main} />}
            {compulsory && (
              <AttributeSwitch label="Compulsory" answer={compulsory} />
            )}
            {compulsory && <AttributeSwitch label="Active" answer={active} />}
          </>
        }
      />
    </BaseStructure>
  )
}

export default Instruction
