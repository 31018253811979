/* eslint-disable react/jsx-props-no-spreading */
import 'material-icons/iconfont/material-icons.css';
import React from 'react';
import MasqueradeAlert from '../components/MasqueradeAlert';
import Navbar from '../components/Navbar';
import { LayoutChildren } from './styles';

const Layout = ({ children }) => (
  <>
    <Navbar />
    <MasqueradeAlert />
    <LayoutChildren>
      {children}
    </LayoutChildren>
  </>
)

export default Layout
