import { omit } from 'lodash'
import React from 'react'
import BasePage from '../../../pages/BasePage'
import TemplateEditor from '../../../pages/TemplateEditor'
import OlingaAPI from '../../../services/OlingaAPI'

const TemplateEditorPage = ({ id }) => {
  const callbacks = omit(
    {
      folders: {
        callback: () => OlingaAPI.foldersEnumerator('templates'),
        params: {}
      },
      enumerators: {
        callback: OlingaAPI.templateEditorEnumerators,
        params: {}
      },
      template: { callback: () => OlingaAPI.templateShow(id), params: {} },
      subUsersList: { callback: () => OlingaAPI.businessSubUsersList('', {
        actives: true,
        include_current_user: true,
        all: true,
      }), params: {} }
    },
    !id ? ['template'] : []
  )
  return (
    <BasePage
      id={id}
      resourceName="data"
      fetchCallback={callbacks}
      fetchWithoutId
    >
      <TemplateEditor />
    </BasePage>
  )
}

export default TemplateEditorPage
