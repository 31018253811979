/* eslint-disable no-prototype-builtins */
import _, { omit, uniqBy } from 'lodash'
import { v4 as uuid } from 'uuid'
import { formatText } from '../../..//pages/Reports/ReportsEditor/helpers'

const invalidKeys = {
  section_questions_attributes: [
    'active',
    'compulsory',
    'main',
    'text',
    'alert_trigger_attributes',
    'template_question_options_attributes',
    'question_logic_option_id',
    'value'
  ],
  template_questions_attributes: [
    'question_logic_option_id',
    'value',
    'repeat'
  ],
  question_logic_attributes: []
}

const getQuestions = (obj, key) => {
  return obj[key].flatMap((question) => {
    const obj = {
      ...question,
      origin: key
    }

    return obj
  })
}

const getFormattedCalculations = (items = [], structure = {}) => {
  return items?.map((item) => {
    return {
      ...item,
      expression: (item?.expression?.data ?? item?.expression ?? []).map(
        (operation) => {
          const question = structure.linear.object[operation?.value] ?? {}
          return {
            ...operation,
            removable: false,
            number: operation?.type === 'number',
            symbol:
              operation?.symbol ?? ['math_operator'].includes(operation?.type),
            ...question,
            label: question?.text ?? '',
            color: operation?.type === 'math_operator' ? 'gray.900' : ''
          }
        }
      ),
      _id: uuid()
    }
  })
}

export const formatAttributes = (obj, structure) => {
  const levelKeys = [
    'template_questions_attributes',
    'section_questions_attributes',
    'question_logics_attributes',
    'questions'
  ]
  for (const key in obj) {
    if (key === 'template_setting_attributes') {
      obj.template_setting_attributes_id = obj[key]?.id ?? null
      obj[key] = {
        value: obj[key].form_permission ?? null,
        label: obj[key]?.form_permission
          ? formatText(obj[key].form_permission)
          : ''
      }
    }
    if (
      levelKeys.includes(key) &&
      obj.hasOwnProperty(key) &&
      !Array.isArray(obj)
    ) {
      obj.questions = []
      levelKeys.forEach((innerKey) => {
        if (Array.isArray(obj[innerKey])) {
          obj._id = obj.id
          obj._selectedTab = 0
          obj.questions.push(...getQuestions(obj, innerKey))
          obj.questions = _.orderBy(
            obj.questions.map((question, index) => ({
              ...question,
              order: _.isNumber(question.order) ? question.order : index + 1
            })),
            ['order'],
            ['asc']
          )
          obj.questions = uniqBy(obj.questions, 'id')
          obj.template_question_options_attributes_deleted = []
          if (obj.alert_trigger_attributes) {
            obj.alert_triggers_users_attributes_deleted = []
            if (obj.alert_trigger_attributes.alert_triggers_users) {
              obj.alert_trigger_attributes.alert_triggers_users_attributes = []
            }
          }
          if (obj.question_logic_option_id) {
            if (obj.value.match(/^[-]*\d\d?[,]\d\d?$/)) {
              const split = obj.value.split(',')
              obj.aux_value = _.last(split)
              obj.value = _.first(split)
            }
            if (obj.value.match(/^.*[;].*$/)) {
              const options = obj.value
                .split(';')
                .filter((option) => !!option)
                .map((option) => ({
                  label: option.replace(/;/g, ''),
                  value: option.replace(/;/g, '')
                }))
              obj.aux_options = options
            }
          }
          if (obj?.value && obj?.value?.match(/^.*[;].*$/)) {
            obj.value = obj.value.replace(/;/g, '')
          }
          if (obj.question_calculation_fields_attributes) {
            obj.question_calculation_fields_attributes_deleted = []
            obj.question_calculation_fields_attributes =
              getFormattedCalculations(
                obj.question_calculation_fields_attributes,
                structure
              )
          }
          if (obj.template_question_assets_attributes) {
            obj.template_question_assets_attributes_deleted = []
            obj.template_question_assets_attributes =
              obj.template_question_assets_attributes.map((item) => ({
                ...item,
                _id: item.id
              }))
          }
        }
      })
      formatAttributes(obj[key], structure)
    }
    if (levelKeys.includes(key) && Array.isArray(obj[key])) {
      levelKeys.forEach((innerKey) => {
        if (obj[innerKey]) {
          obj[innerKey].forEach((question) => {
            formatAttributes(question, structure)
          })
        }
      })
    }
  }
  return obj
}

export const convertAttributesToSubmit = (obj) => {
  const deletedOptions = obj.notify_templates_users_attributes_deleted

  const questionKeys = [
    'section_questions_attributes',
    'template_questions_attributes',
    'question_logics_attributes'
  ]

  if (!_.isUndefined(deletedOptions)) {
    obj.notify_templates_users_attributes = [
      ...deletedOptions,
      ...obj.notify_templates_users_attributes
    ]

    delete obj.notify_templates_users_attributes_deleted
  }

  const attributeKey = 'questions'
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && !Array.isArray(obj)) {
      if (obj._id) {
        obj.id = obj._id
      }
      if (_.startsWith(key, '_')) {
        delete obj[key]
      }
      if (obj.aux_value && obj.aux_value !== '[VALUE]') {
        obj.aux_options = null
        obj.value = `${obj.value},${obj.aux_value}`
      }
      if (obj.aux_options?.length > 0) {
        obj.value = '' // removes the [VALUE] monkey patch to save the correct values
        obj.value = obj.aux_options.map((option) => option.value).join(';')
        if (!obj.value.match(`;`)) {
          obj.value += ';'
        }
      }
      if (_.keys(invalidKeys).includes(obj.origin)) {
        invalidKeys[obj.origin].forEach((key) => delete obj[key])
      }
      if (key === 'template_setting_attributes') {
        obj[key] = {
          form_permission: obj[key].value,
          id: obj['template_setting_attributes_id'] ?? null
        }
        delete obj[key].value
        delete obj[key].label
      }
      delete obj.aux_value
      delete obj.aux_options
      if (attributeKey === key) {
        const group = _.groupBy(obj[key], 'origin')
        const keysToOverride = _.keys(group)
        questionKeys.forEach((attributeKey) => {
          if (_.keys(obj).includes(attributeKey)) {
            obj[attributeKey] = []
          }
        })
        keysToOverride.forEach((overrideKey) => {
          obj[overrideKey] = []
          obj[overrideKey] = group[overrideKey]

          group[overrideKey].forEach((question) =>
            convertAttributesToSubmit(question)
          )
        })
        delete obj.origin
        convertAttributesToSubmit(obj[key])
      } else if (key === 'template_question_options_attributes') {
        const deletedOptions =
          obj.template_question_options_attributes_deleted || []
        obj.template_question_options_attributes = [
          ...deletedOptions,
          ...obj.template_question_options_attributes
        ].map((option, index) => ({ ...option, order: index + 1 }))
        delete obj.template_question_options_attributes_deleted
      } else if (key === 'alert_trigger_attributes') {
        if (obj.alert_trigger_attributes?.alert_triggers_users_attributes) {
          const deletedUsers = obj.alert_triggers_users_attributes_deleted || []
          obj.alert_trigger_attributes.alert_triggers_users_attributes = [
            ...deletedUsers,
            ...obj.alert_trigger_attributes.alert_triggers_users_attributes
          ]
        }
        if (
          obj.alert_trigger_attributes &&
          obj.alert_trigger_attributes.alert_triggers_users_attributes
        ) {
          if (obj.alert_trigger_attributes?.alert_triggers_users_attributes) {
            delete obj.alert_trigger_attributes.alert_triggers_users
          }
        }
        delete obj.alert_triggers_users_attributes_deleted
      } else if (
        [
          'template_question_options_attributes_deleted',
          'alert_triggers_users_attributes_deleted'
        ].includes(key)
      ) {
        delete obj[key]
      } else if (key === 'question_calculation_fields_attributes') {
        obj.question_calculation_fields_attributes =
          obj.question_calculation_fields_attributes.map((field) => ({
            ...omit(field, ['_id']),
            expression: {
              data: (field?.expression ?? [])?.map(({ value, id, type }) => ({
                value: type === 'question' ? id : value,
                type
              }))
            }
          }))
      } else if (key === 'question_calculation_fields_attributes_deleted') {
        obj.question_calculation_fields_attributes = [
          ...obj.question_calculation_fields_attributes,
          ...(obj.question_calculation_fields_attributes_deleted ?? [])
        ]?.flatMap((v) => v)
        delete obj.question_calculation_fields_attributes_deleted
      } else if (key === 'template_question_assets_attributes') {
        const sendIds = obj.template_question_assets_attributes.map(
          ({ asset_template_id }) => asset_template_id
        )
        obj.template_question_assets_attributes = [
          ...obj.template_question_assets_attributes,
          ...(obj.template_question_assets_attributes_deleted ?? [])
        ]?.flatMap((v) => ({
          id: v._id,
          _destroy: sendIds.includes(v.asset_template_id) ? false : v._destroy,
          asset_template_id: v.asset_template_id,
          association_type: v.association_type
        }))
        delete obj.template_question_assets_attributes_deleted
      }
    }
    if (_.isArray(obj[key]) && !_.isObject(obj[key])) {
      if (key === attributeKey) {
        obj[key].forEach((question) => convertAttributesToSubmit(question))
        delete obj[attributeKey]
      }
    }
  }
  return obj
}

export const buildErrorsKeys = (obj, currentKey = '', errorsKeys = []) => {
  for (const key in obj) {
    if (
      _.isObject(obj) &&
      obj[key] &&
      obj.hasOwnProperty(key) &&
      !Array.isArray(obj)
    ) {
      if (_.keys(obj).includes('ref')) {
        errorsKeys.push(currentKey)
      } else {
        currentKey += `${key}.`
        buildErrorsKeys(obj[key], currentKey, errorsKeys)
      }
    }
    if (obj[key] && Array.isArray(obj[key])) {
      obj[key].forEach((error, index) => {
        currentKey += `${index}.`
        buildErrorsKeys(error, currentKey, errorsKeys)
      })
    }
  }
  return _.uniq(errorsKeys.map((key) => key.slice(0, -1)))
}

export const getNodeById = (id, obj, depthKeys = ['questions']) => {
  let node = null
  const recursiveSearch = (id, obj, keys, element) => {
    if (element) {
      node = element
      return element
    }
    for (const key in obj) {
      if (obj[key] && obj.hasOwnProperty(key) && !Array.isArray(obj)) {
        const objKeys = _.keys(obj)
        if (objKeys.includes('id')) {
          if (obj.id === id) {
            element = obj
            recursiveSearch(id, null, keys, element)
          }
        }
      }
      if (keys.includes(key) && obj[key] && Array.isArray(obj[key])) {
        obj[key].forEach((node) => {
          recursiveSearch(id, node, keys, element)
        })
      }
    }
  }
  recursiveSearch(id, obj, depthKeys, null)

  return node
}

export const getLinearStructure = (structure, data = []) => {
  if (Array.isArray(structure)) {
    structure.forEach((child) => {
      getLinearStructure(child, data)
    })
  }
  if (!Array.isArray(structure)) {
    if (structure?.type === 'question') {
      data.push(structure)
    }
    if (structure?.children?.length) {
      getLinearStructure(structure.children, data)
    }
  }

  return {
    data,
    object: data.reduce((acc, question) => {
      acc[question.id] = question
      return acc
    }, {})
  }
}
