import { useDisclosure } from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useGlobalEventListener } from '../../../../hooks'
import { actions as FormActions } from '../../../../pages/TemplateEditor/FormActions/actions'

const { OPEN_SETTINGS, SET_GHOST_FIELD, UNFOCUS, SET_FOCUS } = FormActions

const useEventsActions = (lastFocusedField) => {
  const [ghostElement, setGhostElement] = useState(false)
  const [focusedField, setFocusedField] = useState(lastFocusedField)
  const {
    isOpen: isTemplateSettingsOpen,
    onOpen: onOpenTemplateSettings,
    onClose: onCloseTemplateSettings
  } = useDisclosure()

  useGlobalEventListener(
    useCallback(
      (event) => {
        const { payload } = event
        switch (event.type) {
          case OPEN_SETTINGS:
            onOpenTemplateSettings()
            break
          case SET_GHOST_FIELD:
            setGhostElement(payload)
            break
          case UNFOCUS:
            setFocusedField(null)
            break
          case SET_FOCUS:
            setFocusedField(payload)
            break
        }
      },
      [onOpenTemplateSettings]
    )
  )

  const handleFocusedField = (key) => {
    setFocusedField(key)
  }

  return {
    ghostElement,
    focusedField,
    isTemplateSettingsOpen,
    onCloseTemplateSettings,
    handleFocusedField
  }
}

export default useEventsActions
