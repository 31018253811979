import { omit } from 'lodash'
import React, { useCallback, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { globalEvents } from '../../../contexts/GlobalStateContext/events'
import { useGlobalEventListener } from '../../../hooks'
import BasePage from '../../../pages/BasePage'
import ChecklistEditor from '../../../pages/Checklists/ChecklistEditor'
import OlingaAPI from '../../../services/OlingaAPI'

const ChecklistEditorPage = ({ id, show = false }) => {
  const [renderKey, setRenderKey] = useState(uuidv4())

  const callbacks = omit(
    {
      checklist: {
        callback: () =>
          OlingaAPI.getChecklist(id, show ? { includeCounter: true } : null)
      },
      formFolders: {
        callback: () => OlingaAPI.foldersEnumerator('checklists')
      },
      enumerators: {
        callback: OlingaAPI.getChecklistEnums,
        params: {}
      }
    },
    [!id ? 'checklist' : ''].filter((v) => v)
  )

  useGlobalEventListener(
    useCallback((event) => {
      if (event.type === globalEvents.ON_RELOAD_CHECKLIST_EDITOR) {
        setRenderKey(uuidv4())
      }
    }, [])
  )

  return (
    <BasePage
      id={id}
      resourceName="data"
      fetchWithoutId
      fetchCallback={callbacks}
      otherParams={{ show }}
      key={renderKey}
    >
      <ChecklistEditor />
    </BasePage>
  )
}

export default ChecklistEditorPage
