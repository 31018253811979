import { omit } from 'lodash'
import React from 'react'
import BasePage from '../../../pages/BasePage'
import ManageUserForm from '../../../pages/ManageUsers/ManageUserForm'
import OlingaAPI from '../../../services/OlingaAPI'

const ManageUsersPage = ({ id }) => {
  const callbacks = omit(
    {
      profiles: { callback: OlingaAPI.profilesEnumerators, params: {} },
      user: { callback: OlingaAPI.managerUserShow, params: {} },
      templates: { callback: OlingaAPI.templatesEnumerators, params: {} },
      checklists: { callback: OlingaAPI.checklistsEnumerators, params: {} },
      dashboards: {
        callback: () => OlingaAPI.manageDashboards(null, { active: true }),
        params: {}
      },
      assetTemplates: {
        callback: () =>
          OlingaAPI.assetTemplatesAsEnumerator({ with_assets: false })
      },
      documents: {
        callback: () => OlingaAPI.foldersEnumerator('documents'),
        params: {}
      },
      roles: {
        callback: () => OlingaAPI.profileRolesList({ isGrouped: true }),
        params: {}
      }
    },
    [!id ? 'user' : '']
  )

  return (
    <BasePage
      id={id}
      resourceName="data"
      fetchCallback={callbacks}
      fetchWithoutId
      otherParams={{
        hasActiveHandler: true
      }}
    >
      <ManageUserForm />
    </BasePage>
  )
}

export default ManageUsersPage
