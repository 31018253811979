import { isArray } from 'lodash'

export const normalizeDashboardData = (
  info,
  dashboardColor,
  xArray,
  yArray,
  total
) => {
  const result = {}
  const data = []

  xArray.forEach((x, index) => data.push(yArray[index]))

  result.data = {
    labels: xArray,
    datasets: [
      {
        label: info,
        borderColor: dashboardColor,
        backgroundColor: dashboardColor,
        data
      }
    ]
  }

  result.total = total
  return result
}

export const tabs = ['today', 'yesterday', 'seven_days', 'custom']

export const getTabNameByIndex = (idx) => {
  return tabs[idx]
}

export const getTabIndex = (tabName) => {
  const tabsIndex = tabs.reduce((acc, tab, index) => {
    acc[tab] = index
    return acc
  }, {})

  const name = isArray(tabName) ? 'custom' : tabName

  return tabsIndex[name]
}
