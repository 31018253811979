import { Checkbox } from '@chakra-ui/react'
import { noop } from 'lodash'
import React from 'react'

const ShowAllCheckbox = ({
  isDisabled,
  isChecked,
  onChange,
  alignSelf,
  mb,
  pageName
}) => (
  <Checkbox
    isDisabled={isDisabled}
    isChecked={isChecked}
    onChange={onChange}
    alignSelf={alignSelf}
    mb={mb}
    mt={2}
    mr={4}
  >
    Show all {pageName}
  </Checkbox>
)

ShowAllCheckbox.defaultProps = {
  isDisabled: false,
  isChecked: false,
  onChange: noop,
  alignSelf: 'flex-start',
  mb: 0,
  pageName: ''
}

export default ShowAllCheckbox
