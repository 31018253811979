import { theme } from '@chakra-ui/react'

export const styles = {
  borderRadius: 2,
  p: 4,
  color: 'white'
}

export const operationsStyles = {
  borderRadius: 2,
  border: `1px solid ${theme.colors.gray[900]}`,
  p: 4,
  gap: 4
}
