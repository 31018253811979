import { Divider, Heading, Stack } from '@chakra-ui/react'
import Table from 'components/Table'
import React from 'react'
import useAssetResponses, { hiddenColumnsKey } from './hooks/useAssetResponses'

const AssetResponses = ({ id }) => {
  const {
    columns,
    hiddenColumns,
    data,
    fetchAssetsResponses,
    isLoading,
    pagination
  } = useAssetResponses({ id })

  return (
    <Stack p={4} borderRadius="sm" borderWidth={1} borderColor="gray.200">
      <Heading mb={2} size="sm">
        Form Responses
      </Heading>
      <Divider />
      <Table
        isLoading={isLoading}
        data={data}
        columns={columns}
        pageCount={pagination.pageCount}
        totalItems={pagination.totalItems}
        fetchPage={fetchAssetsResponses}
        persistedColumnsKey={hiddenColumnsKey}
        initiallyHiddenColumns={hiddenColumns}
      />
    </Stack>
  )
}

export default AssetResponses
