import { AddIcon } from '@chakra-ui/icons'
import { Button, Stack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../../components/PageHeader'
import Table from '../../../components/Table'
import { getRouteUrl } from '../../../helpers/admin'
import useAssetTypes, { hiddenColumnsKey } from './hooks/useAssetTypes'

const AssetTypes = () => {
  const { columns, hiddenColumns, data, fetchAssets, isLoading, pagination } =
    useAssetTypes()

  return (
    <Stack>
      <PageHeader
        title="Asset Types"
        Actions={
          <Link to={getRouteUrl('newAssetType')}>
            <Button leftIcon={<AddIcon />} colorScheme="blue">
              New
            </Button>
          </Link>
        }
      />
      <Table
        isLoading={isLoading}
        data={data}
        columns={columns}
        pageCount={pagination.pageCount}
        totalItems={pagination.totalItems}
        fetchPage={(params) => fetchAssets(params)}
        persistedColumnsKey={hiddenColumnsKey}
        initiallyHiddenColumns={hiddenColumns}
      />
    </Stack>
  )
}

export default AssetTypes
