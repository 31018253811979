import { AddIcon, EditIcon, ViewIcon } from '@chakra-ui/icons'
import { Button, Flex, IconButton } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import DeleteButton from '../../../components/DeleteButton'
import { getRouteUrl } from '../../../helpers'
import ExportAssets from '../AssetTemplatesEditor/ExportAssets'

const EditAssetTemplateButton = ({ id, onDelete, permissions }) => (
  <>
    <Flex align="center" gap={4}>
      {permissions?.modify ? (
        <Flex align="center" gap={4}>
          <Link to={getRouteUrl('editAssetTemplate', { id })}>
            <IconButton
              icon={<EditIcon />}
              colorScheme="blue"
              size="sm"
              variant="outline"
              aria-label={`Edit asset type ${id}`}
            />
          </Link>
          <DeleteButton
            index={id}
            onDelete={onDelete}
            alertTitle="Delete asset type"
            alertBody="This action cannot be undone."
            continueLabel="Yes, remove it!"
            size="sm"
            variant="outline"
            borderRadius={6}
          />
          <Link to={getRouteUrl('newAsset', { asset_template_id: id })}>
            <Button
              colorScheme="green"
              leftIcon={<AddIcon />}
              size="sm"
              variant="outline"
              borderRadius={6}
            >
              Create Asset
            </Button>
          </Link>
        </Flex>
      ) : permissions?.view ? (
        <Link to={getRouteUrl('editAssetTemplate', { id })}>
          <IconButton
            icon={<ViewIcon />}
            colorScheme="blue"
            size="sm"
            variant="outline"
            aria-label={`View asset type ${id}`}
          />
        </Link>
      ) : null}
      <ExportAssets assetTemplateId={id} />
    </Flex>
  </>
)

export default EditAssetTemplateButton
