import { Box, Flex, Heading, Stack } from '@chakra-ui/layout'
import { useMediaQuery } from '@chakra-ui/media-query'
import PropTypes from 'prop-types'
import React from 'react'

const PageHeader = ({ title, description, Actions, Breadcrumb }) => {
  const [isMobile] = useMediaQuery('(max-width: 799px)')
  return (
    <Stack py={5}>
      {Breadcrumb && (
        <Box data-testid="page-header-breadcrumb" minH="40px">
          {Breadcrumb}
        </Box>
      )}
      <Flex
        justify={isMobile ? 'flex-start' : 'space-between'}
        align={isMobile ? 'flex-start' : 'center'}
        direction={isMobile ? 'column' : 'row'}
      >
        <Stack>
          <Heading
            data-testid="page-header-title"
            as="h2"
            size="md"
            minW="max-content"
            display="block"
          >
            {title}
          </Heading>
          {description && (
            <span data-testid="page-header-description">{description}</span>
          )}
        </Stack>
        <Flex
          my={isMobile ? '20px' : '0px'}
          w="100%"
          align={isMobile ? 'flex-start' : 'center'}
          justify="flex-end"
          flexWrap="wrap"
          data-testid="page-header-actions"
        >
          {Actions}
        </Flex>
      </Flex>
    </Stack>
  )
}

PageHeader.defaultProps = {
  title: '',
  Actions: null,
  description: null,
  Breadcrumb: null
}

PageHeader.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object
  ]),
  Actions: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  Breadcrumb: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}

export default PageHeader
