import { Checkbox } from '@chakra-ui/checkbox'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  DownloadIcon
} from '@chakra-ui/icons'
import { Box } from '@chakra-ui/layout'
import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/popover'
import { Portal } from '@chakra-ui/portal'
import { Button, VStack } from '@chakra-ui/react'
import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack
} from '@chakra-ui/slider'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { QRCodeCanvas } from 'qrcode.react'
import React from 'react'
import InfoTip from '../../../components/Infotip'
import Modal from '../../../components/Modal'
import TemplateRecursiveStructure from '../../../components/TemplateRecursiveStructure'
import { cmToPxConvert } from '../../../helpers'
import {
  DropdownOverlay,
  OverlayTitle,
  QRCodeButton,
  QRCodeUnit
} from './styles'
import useQRCode from './useQRCode'

const QR_CODE_SIZE = 10

const QRCodeGenerator = ({
  type,
  templateId,
  templateName,
  getValues,
  popoverPlacement,
  onDisplayQrCodeParams,
  templateStructure
}) => {
  const {
    displayQrCode,
    params,
    handleButtonClick,
    title,
    qrCodeSize,
    setQrCodeSize,
    handleCheckBoxChange,
    renderMargins,
    ref,
    onOpenChooseQRCodeLabelModal,
    templateIndexedAnswers,
    onSelectQrCodeLabelQuestionId,
    selectedQuestionId,
    onConfirmQrCodeSelection,
    allowQuestionIdSelection
  } = useQRCode({
    type,
    templateId,
    getValues,
    templateName,
    templateStructure
  })

  if (!templateId) {
    return null
  }

  return (
    <>
      <canvas id="donwload-canvas" style={{ display: 'none' }} />
      {displayQrCode && (
        <Portal>
          <Box display="none">
            <QRCodeCanvas {...params} />
          </Box>
        </Portal>
      )}
      <Popover
        trigger="hover"
        onOpen={() => onDisplayQrCodeParams(true)}
        onClose={() => onDisplayQrCodeParams(false)}
        placement={popoverPlacement}
      >
        <PopoverTrigger>
          <QRCodeButton
            fontWeight="normal"
            withoutbg="true"
            onClick={() => handleButtonClick(null)}
          >
            {title} QR Code
            {popoverPlacement === 'bottom' ? (
              <ChevronDownIcon />
            ) : (
              <ChevronRightIcon />
            )}
            <InfoTip
              placement="auto-end"
              label="The QR Code will be generated only for answered Yes/No, Short Text, Number, Single Select, List & Slider questions. There is a limit of 10 answers per QR Code"
            />
          </QRCodeButton>
        </PopoverTrigger>
        <Portal>
          <PopoverContent width={200}>
            <DropdownOverlay>
              {type === 'quick-submit' && (
                <>
                  <OverlayTitle>QR Code Label option</OverlayTitle>
                  <VStack spacing={2}>
                    <Modal
                      ref={ref}
                      size="full"
                      header="Choose QR code label"
                      onModalOpen={onOpenChooseQRCodeLabelModal}
                      body={
                        <TemplateRecursiveStructure
                          childrenStructure={templateStructure?.children ?? []}
                          answers={templateIndexedAnswers}
                          answerLabel="QR Code Label"
                          allowSelection={allowQuestionIdSelection}
                          selectionMode="single"
                          onSelectionChange={onSelectQrCodeLabelQuestionId}
                          initialSelectedNodes={[selectedQuestionId]}
                        />
                      }
                      footer={
                        <Button
                          colorScheme="green"
                          onClick={onConfirmQrCodeSelection}
                          isDisabled={!selectedQuestionId}
                          leftIcon={<DownloadIcon />}
                        >
                          Confirm and Download
                        </Button>
                      }
                    >
                      <Button
                        colorScheme="orange"
                        variant="outline"
                        size="xs"
                        w="100%"
                        mt={2}
                      >
                        With label
                      </Button>
                    </Modal>
                    <Button
                      colorScheme="orange"
                      variant="outline"
                      size="xs"
                      w="100%"
                      onClick={() => handleButtonClick(null)}
                    >
                      Without label
                    </Button>
                  </VStack>
                </>
              )}
              <OverlayTitle>QR Code size in centimetres</OverlayTitle>
              <Box paddingTop={3} paddingBottom={3}>
                <Slider
                  aria-label="qr-code-padding-slider"
                  defaultValue={QR_CODE_SIZE}
                  onChangeEnd={(value) => setQrCodeSize(value)}
                  min={5}
                  max={60}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Box>

              <QRCodeUnit>
                {qrCodeSize} cm ({cmToPxConvert(qrCodeSize)} px)
              </QRCodeUnit>
              <Checkbox
                size="sm"
                checked={renderMargins}
                onChange={handleCheckBoxChange}
              >
                Has inner margins
              </Checkbox>
            </DropdownOverlay>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  )
}

QRCodeGenerator.defaultProps = {
  type: 'quick-access',
  getValues: noop,
  isValid: true,
  popoverPlacement: 'bottom',
  onError: noop,
  onDisplayQrCodeParams: noop
}

QRCodeGenerator.propTypes = {
  type: PropTypes.oneOf(['quick-access', 'quick-submit']),
  templateId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  getValues: PropTypes.func,
  popoverPlacement: PropTypes.oneOf(['right', 'bottom']),
  onDisplayQrCodeParams: PropTypes.func
}

export default QRCodeGenerator
