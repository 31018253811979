import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, HStack, Heading, IconButton, Stack, StackDivider, Tag, Text, useDisclosure } from '@chakra-ui/react';
import { roleIncludesKey } from 'helpers';

import React from 'react';
import ChecklistsSelector from '../ChecklistsSelector';
import TemplateSelector from '../TemplateSelector';

const SelectedRoles = ({
  role,
  hasRolesByCategory,
  onChangeTemplatesSelector,
  checklistsList,
  checklistsOfChecklistRole,
  templatesList,
  templatesOfFormRole,
  templatesOfTemplateRole,
  foldersOfDocumentRole,
  dashboardsOfDashbordRole,
  documentsList,
  dashboardsList,
  templatesPageTitle,
  checklistsPageTitle,
  assetTemplatesPageTitle,
  assetTemplatesOfAssetTemplateRole,
  assetTemplatesList,
  register,
  readOnly,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ id });
  const { label, description, id } = role
  const isDocument = roleIncludesKey(role, 'document')
  const isChecklist = roleIncludesKey(role, 'checklist') 
  const isTemplate = roleIncludesKey(role, 'template') && !roleIncludesKey(role, 'asset_template')
  const isAssetTempate = roleIncludesKey(role, 'template') && roleIncludesKey(role, 'asset_template')
  const isForm = roleIncludesKey(role, 'form')
  const isDashboard = roleIncludesKey(role, 'dashboard')
  const notAll = !roleIncludesKey(role, 'all')

  const shouldShowCollapse = (isDocument || isChecklist || isTemplate || isAssetTempate || isForm || isDashboard) && notAll
  
  const getTagValue = () => {
    if (isDocument && notAll) {
      return foldersOfDocumentRole?.length
    }

    if (isDashboard && notAll) {
      return dashboardsOfDashbordRole?.length
    }

    if (isChecklist && notAll) {
      return checklistsOfChecklistRole?.length
    }

    if (isTemplate && notAll) {
      return templatesOfTemplateRole?.length
    }

    if (isAssetTempate && notAll) {
      return assetTemplatesOfAssetTemplateRole?.length
    }

    if (isForm && notAll) {
      return templatesOfFormRole?.length
    }
  }

  return (
    <Box
      shadow="md"
      border="1px"
      mt='10px'
      borderColor={
        isOpen && shouldShowCollapse ? 'blue.400' : 'transparent'
      }
      borderRadius="2px"
      key={id}
    >
      <Flex
        justify="space-between"
        align="center"
        onClick={isOpen ? onClose : onOpen}
        p="4"
        cursor="pointer"
        w="100%"
      >
        <Flex
          boxSizing="border-box"
          mr={2}
          maxW='calc(100% - 30px)'
          flexDir='column'
        >
           <Heading
            data-testid="folders-tree-heading"
            size="sm"
            display='flex'
            alignItems='center'
            gap='5px'
          >
            {label} {shouldShowCollapse && <Tag colorScheme="orange">{getTagValue() || 0}</Tag>}
          </Heading>
          <Text color='gray.500' fontSize='14px'>
            {description}
          </Text>
        </Flex>
        <Flex align="center">
          {shouldShowCollapse && <IconButton
            size="sm"
            colorScheme="blue"
            icon={isOpen ? (
              <ChevronUpIcon />
            ) : (
              <ChevronDownIcon />
            )}
            variant="outline"
            ml={4}
          />
          }
        </Flex>
      </Flex>
      {isOpen && shouldShowCollapse && (
        <Stack>
          <Divider />
            {isDocument && hasRolesByCategory.hasDocumentSelected && (
              <>
                <Heading size="sm" p="10px">Documents</Heading>
                <Divider />
                <HStack
                  p={4}
                  w="100%"
                  spacing="10px"
                  divider={<StackDivider borderColor="gray.200" />}
                  justify="start"
                >
                  {hasRolesByCategory.hasDocumentSelected &&
                    checklistsOfChecklistRole && (
                      <ChecklistsSelector
                        attributeKey="folders_of_document_role"
                        roleTypeLabel="Document"
                        defaultValue={foldersOfDocumentRole}
                        checklistsList={documentsList}
                        pageTitle={'Folders'}
                        onChangeChecklistsSelector={onChangeTemplatesSelector}
                        register={register}
                        readOnly={readOnly}
                      />
                    )}
                </HStack>
              </>
            )}
            {isDashboard && hasRolesByCategory.hasDashboardSelected && (
              <>
                <Heading size="sm" p="10px">Dashboards</Heading>
                <Divider />
                <HStack
                  p={4}
                  w="100%"
                  spacing="10px"
                  divider={<StackDivider borderColor="gray.200" />}
                  justify="start"
                >
                  {hasRolesByCategory.hasDashboardSelected &&
                    dashboardsOfDashbordRole && (
                      <ChecklistsSelector
                        attributeKey="dashboards_of_dashboard_role"
                        roleTypeLabel="Dashbord"
                        defaultValue={dashboardsOfDashbordRole}
                        checklistsList={dashboardsList}
                        pageTitle={'Dashboard'}
                        onChangeChecklistsSelector={onChangeTemplatesSelector}
                        register={register}
                        readOnly={readOnly}
                      />
                    )}
                </HStack>
              </>
            )}
            {isChecklist && hasRolesByCategory.hasChecklistSelected && (
              <>
                <Heading size="sm" p="10px">Checklists</Heading>
                <Divider />
                <HStack
                  p={4}
                  w="100%"
                  spacing="10px"
                  divider={<StackDivider borderColor="gray.200" />}
                  justify="start"
                >
                  {hasRolesByCategory.hasChecklistSelected &&
                    checklistsOfChecklistRole && (
                      <ChecklistsSelector
                        attributeKey="checklists_of_checklist_role"
                        roleTypeLabel="Checklist"
                        defaultValue={checklistsOfChecklistRole}
                        checklistsList={checklistsList}
                        pageTitle={checklistsPageTitle}
                        onChangeChecklistsSelector={onChangeTemplatesSelector}
                        register={register}
                        readOnly={readOnly}
                      />
                    )}
                </HStack>
              </>
            )}
            {isTemplate && hasRolesByCategory.hasTemplateSelected && (
              <>
                <Heading size="sm" p="10px">Templates</Heading>
                <Divider />
                <HStack
                  p={4}
                  w="100%"
                  spacing="10px"
                  divider={<StackDivider borderColor="gray.200" />}
                  justify="start"
                >
                  <TemplateSelector
                    attributeKey="templates_of_template_role"
                    roleTypeLabel="Template"
                    defaultValue={templatesOfTemplateRole}
                    onChangeTemplatesSelector={onChangeTemplatesSelector}
                    register={register}
                    templatesList={templatesList}
                    pageTitle={templatesPageTitle}
                    readOnly={readOnly}
                  />
                </HStack>
              </>
            )}
            {isForm && hasRolesByCategory.hasFormSelected && (
              <>
                <Heading size="sm" p="10px">Templates</Heading>
                <Divider />
                <HStack
                  p={4}
                  w="100%"
                  spacing="10px"
                  divider={<StackDivider borderColor="gray.200" />}
                  justify="start"
                >
                  <TemplateSelector
                    attributeKey="templates_of_form_role"
                    roleTypeLabel="Form"
                    defaultValue={templatesOfFormRole}
                    onChangeTemplatesSelector={onChangeTemplatesSelector}
                    register={register}
                    templatesList={templatesList}
                    pageTitle={templatesPageTitle}
                    readOnly={readOnly}
                  />
                </HStack>
              </>
            )}
            {isAssetTempate && hasRolesByCategory.hasAssetTemplateSelected && (
              <>
                <Heading size="sm" p="10px">
                  Asset Templates
                </Heading>
                <Divider />
                <HStack
                  p={4}
                  w="100%"
                  spacing="10px"
                  divider={<StackDivider borderColor="gray.200" />}
                  justify="start"
                >
                  {hasRolesByCategory.hasAssetTemplateSelected && (
                    <ChecklistsSelector
                      attributeKey="asset_templates_of_asset_template_role"
                      roleTypeLabel="Asset Template"
                      defaultValue={assetTemplatesOfAssetTemplateRole}
                      checklistsList={assetTemplatesList}
                      pageTitle={assetTemplatesPageTitle}
                      onChangeChecklistsSelector={onChangeTemplatesSelector}
                      register={register}
                      readOnly={readOnly}
                    />
                  )}
                </HStack>
              </>
            )}
        </Stack>
      )}
    </Box>
  )
}

export default SelectedRoles
