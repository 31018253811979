import React from 'react'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'
import DeleteButton from '../../../../../components/DeleteButton'

const RemoveActionHandler = ({ currentKey, onRemove, children }) => {
  const { watch, setValue } = useFormContext()
  const actions = watch(`${currentKey}.actions`, [])

  const removeAction = (deleteIndex) => {
    const clonedActions = _.cloneDeep(actions)
    clonedActions.splice(deleteIndex, 1)
    setValue(`${currentKey}.actions`, clonedActions)

    if (_.isFunction(onRemove)) {
      onRemove()
    }
  }

  return (
    <DeleteButton
      onDelete={removeAction}
      alertTitle="Delete action"
      alertBody="Do you want to delete this action ? This can't be undone."
      cancelLabel="Cancel"
      continueColor="red"
      continueLabel="Yes, delete it!"
    >
      {children}
    </DeleteButton>
  )
}

export default RemoveActionHandler
