import { Button, VisuallyHidden } from '@chakra-ui/react'
import React, { forwardRef, useRef } from 'react'
import Dialog from '../../../../components/AlertDialog'

const ScaleQuestionChangeDialog = forwardRef(({ onClose, onConfirm }, ref) => {
  const dialogRef = useRef()
  return (
    <Dialog
      ref={ref}
      header="You have a pending confirmation"
      body="Your settings (min and max values) will be deleted when you change this question type"
      handleClose={onClose}
      footer={
        <Button colorScheme="red" onClick={onConfirm}>
          Proceed
        </Button>
      }
      cancelLabel="Cancel"
      continueLabel="Proceed"
      onContinue={onConfirm}
    >
      <VisuallyHidden>
        <Button ref={dialogRef}>Open dialog</Button>
      </VisuallyHidden>
    </Dialog>
  )
})

ScaleQuestionChangeDialog.displayName = 'ScaleQuestionChangeDialog'

export default ScaleQuestionChangeDialog
