import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Spinner, Tag } from '@chakra-ui/react'
import { chakraComponentsSizes } from '../../../../../helpers'
import { mapStatusColor } from '../constants'
import { useQuestionActions } from '../../ActionsContext'

const ActionStatusTag = ({ status, size }) => {
  const { statuses } = useQuestionActions()

  const mapStatusTitle = useMemo(() => {
    if (!statuses?.length) {
      return []
    }
    return statuses.reduce((acc, { label, value }) => {
      acc[value] = label
      return acc
    }, {})
  }, [statuses])

  if (!statuses?.length) {
    return <Spinner colorScheme="blue" size="xs" />
  }

  return (
    <Tag size={size} colorScheme={mapStatusColor[status]}>
      {mapStatusTitle[status]}
    </Tag>
  )
}

ActionStatusTag.defaultProps = {
  size: 'sm'
}

ActionStatusTag.propTypes = {
  status: PropTypes.oneOf(Object.keys(mapStatusColor)),
  size: PropTypes.oneOf(chakraComponentsSizes)
}

export default ActionStatusTag
