import { FormControl, FormLabel, Stack } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import React from 'react'
import useAssetTemplateSelector from './hooks/useAssetTemplateSelector'

const AssetTemplateSelector = ({ association, selections, onChange, menuPlacement }) => {
  const {
    assetTemplates,
    assetTemplateFields,
    options,
    isLoadingAssetTemplates,
    isLoadingAssetTemplateFields,
    selectedAssetTemplate,
    selectedAssetTemplateField,
    selectedAssetTemplateFieldOption,
    onChangeAssetTemplate,
    onChangeAssetTemplateField,
    onChangeAssetTemplateFieldOption
  } = useAssetTemplateSelector({ onChange, selections, association })

  return (
    <Stack gap={4}>
      <FormControl>
        <FormLabel>Asset Type</FormLabel>
        <Select
          placeholder="Select an asset type"
          options={assetTemplates}
          isLoading={isLoadingAssetTemplates}
          onChange={onChangeAssetTemplate}
          menuPlacement={menuPlacement}
          value={selectedAssetTemplate}
        />
      </FormControl>
      {['asset_template_field_option', 'asset_template_field'].includes(
        association
      ) &&
        selectedAssetTemplate?.value && (
          <FormControl>
            <FormLabel>Asset type fields</FormLabel>
            <Select
              placeholder="Select an asset type field"
              options={assetTemplateFields}
              isLoading={isLoadingAssetTemplateFields}
              onChange={onChangeAssetTemplateField}
              value={selectedAssetTemplateField}
              menuPlacement={menuPlacement}
            />
          </FormControl>
        )}
      {association === 'asset_template_field_option' &&
        selectedAssetTemplateField?.value && (
          <FormControl>
            <FormLabel>Asset type field options</FormLabel>
            <Select
              placeholder="Select an option"
              options={options}
              onChange={onChangeAssetTemplateFieldOption}
              value={selectedAssetTemplateFieldOption}
              menuPlacement={menuPlacement}
            />
          </FormControl>
        )}
    </Stack>
  )
}

AssetTemplateSelector.defaultValue = {
  allowSelection: () => true,
  association: 'templates',
  menuPlacement: 'bottom',
}

export default AssetTemplateSelector
