import { Box, Divider, Stack } from '@chakra-ui/react'
import { pick } from 'lodash'
import React from 'react'
import EmptyState from '../../components/EmptyState'
import TemplatePageBreadcrumb from '../Template/TemplatePagesBreadcrumb'
import InlineHeader from '../TemplateEditor/Header/InlineHeader'
import useTemplateSettings from './hooks'
import TemplateSettingsTabs from './TemplateSettingsTabs'
import CalculatedFields from './TemplateSettingsTabs/components/CalculatedFields'
import TemplateForm from './TemplateSettingsTabs/components/TemplateForm'
import TemplateSettingsTabsChildren from './TemplateSettingsTabs/TemplateSettingsTabsChildren'

const components = {
  edit_form: TemplateForm,
  calculated_fields: CalculatedFields
}

const TemplateSettings = ({ template, lastFocusedField }) => {
  const {
    templateDetails,
    tabs,
    onSelectUnauthorizedTab,
    pageTitle,
    singularTitle
  } = useTemplateSettings(template)

  if (!template?.templateStructure?.attributes) {
    return <EmptyState text="This template does not exists !" />
  }

  return (
    <Stack>
      {templateDetails && (
        <TemplatePageBreadcrumb
          templateDetails={templateDetails}
          templateId={templateDetails?.id}
          routeName={`${singularTitle} Settings`}
          pageTitle={pageTitle}
          singularTitle={singularTitle}
        />
      )}
      <Box py={4}>
        <InlineHeader
          permissions={template?.enumerators?.formsPermissionsOptions ?? []}
          defaultValues={templateDetails}
          formFolders={template?.formFolders ?? []}
          subUsersList={template?.subUsersList}
        />
      </Box>
      <Divider />
      <TemplateSettingsTabs tabs={tabs} templateId={templateDetails?.id}>
        {({ tab, index, currentTab, shouldFetch }) => {
          const selectedTab = tabs?.[currentTab]?.id
          if (!selectedTab) {
            return onSelectUnauthorizedTab()
          }
          const RenderedComponent = components[selectedTab]
          const formFolders = template.formFolders
          const props = {
            edit_form: {
              data: {
                ...pick(template, ['enumerators', 'subUsersList']),
                structure: template.completeStructure ?? {},
                folders: formFolders,
                lastFocusedField
              }
            },
            calculated_fields: {
              enumerators: template?.enumerators,
              templateId: template?.id
            }
          }
          return currentTab === index ? (
            <TemplateSettingsTabsChildren
              tab={tab}
              templateId={templateDetails?.id}
              shouldFetch={shouldFetch}
              additionalProps={props[selectedTab]}
            >
              <RenderedComponent />
            </TemplateSettingsTabsChildren>
          ) : null
        }}
      </TemplateSettingsTabs>
    </Stack>
  )
}

export default TemplateSettings
