import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { Divider, Flex, SimpleGrid, Stack, Text } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/react'
import { Switch } from '@chakra-ui/switch'
import { Select } from 'chakra-react-select'
import { isEmpty } from 'lodash'
import React from 'react'
import InfoTip from '../../../../components/Infotip'
import EditInline from '../../../../components/Inputs/EditInline'
import LazyComponent from '../../../../components/LazyComponent'
import PermissionSelector from '../../../../components/PermissionSelector'
import CloneTemplateButton from '../../../../pages/Template/CloneTemplateButton'
import DropdownSelection from '../../../../pages/Template/QRCodeGenerator/DropdownSelection'
import NotificationsHandlerModal from '../../NotificationsHandler/NotificationsHandlerModal'
import useInlineHeader from './useInlineHeader/index.jsx'

const InlineHeader = ({
  defaultValues,
  permissions,
  formFolders,
  subUsersList
}) => {
  const {
    handlePutAttribute,
    handleToggleActive,
    register,
    formPermissions,
    formValues,
    groupContext,
    onFormFolderSelect,
    onChangeGroups,
    notificationsRef,
    control,
    setValue,
    watch,
    onSaveNotifiedUsers,
    singularTitle,
    isBusiness
  } = useInlineHeader({ defaultValues, permissions, formFolders })

  return (
    <Flex flexDir={['column', 'row']} gap={4} w="100%">
      <Stack w="100%">
        <SimpleGrid alignItems="center" columns={[1, 2]} spacing={4} w="100%">
          <EditInline
            placeholder={`${singularTitle} name`}
            defaultValue={defaultValues?.name ?? ''}
            registerProps={{ ...register('name') }}
            onSubmit={(value) => handlePutAttribute('name', value)}
            justify={false}
            width="100%"
          />
          <Flex flexWrap="wrap" align="center" gap={4}>
            <Switch
              mb="0!important"
              mt="1"
              size="sm"
              {...register('map_view')}
              onChange={({ target: { checked } }) =>
                handlePutAttribute('map_view', checked)
              }
              sx={{
                span: {
                  cursor: 'pointer'
                }
              }}
            >
              Map View
            </Switch>
            <Switch
              mb="0!important"
              mt="1"
              size="sm"
              colorScheme="green"
              {...register('active')}
              onChange={({ target: { checked } }) =>
                handleToggleActive(checked)
              }
              sx={{
                span: {
                  cursor: 'pointer'
                }
              }}
            >
              Active
            </Switch>
            <Stack
              border="1px solid #dedede"
              p={0}
              borderRadius={4}
              align="flex-start"
              gap={2}
              flex={1}
            >
              <Flex w="100%" justify="center" align="center" p={2} pb={0}>
                <Text fontSize="md" fontWeight="bold">
                  Settings
                </Text>
              </Flex>
              <Divider my="0!important" />
              <Flex
                justify="space-between"
                align="flex-start"
                p={2}
                pt={0}
                w="100%"
                flexWrap="wrap"
              >
                {isBusiness && (
                  <DropdownSelection
                    templateId={defaultValues.id}
                    templateName={defaultValues.name}
                    isEdit
                  />
                )}
                <CloneTemplateButton
                  label={`Duplicate ${singularTitle}`}
                  templateId={defaultValues?.id}
                />
                {!isEmpty(formPermissions) ? (
                  <PermissionSelector
                    blankPlaceholder="Access permission"
                    placeholder={`For this ${singularTitle}, choose whether the employee will have access to view all submitted forms, only their own submissions, or none`}
                    onSelect={(value) =>
                      handlePutAttribute('template_setting_attributes', value)
                    }
                    permissions={formPermissions}
                    selectedPermission={formValues?.template_setting_attributes}
                    colorScheme="blue"
                    size="sm"
                    minW="142px"
                    selectedPermissionPrefix="Permission"
                    closeOnSelect
                    variant="ghost"
                  />
                ) : (
                  <Spinner size="sm" colorScheme="orange" />
                )}
                <NotificationsHandlerModal
                  ref={notificationsRef}
                  notify={formValues.notify}
                  users={formValues.notify_templates_users_attributes ?? []}
                  control={control}
                  setValue={setValue}
                  watch={watch}
                  notifyKey="notify"
                  onSave={onSaveNotifiedUsers}
                  subUsersList={subUsersList}
                />
              </Flex>
            </Stack>
          </Flex>
        </SimpleGrid>
        <SimpleGrid py={4} columns={[1, 2]} spacing={4}>
          <LazyComponent condition={!!formFolders?.length}>
            <FormControl>
              <FormLabel htmlFor="selectedFormFolder">Folder</FormLabel>
              <Select
                options={formFolders}
                {...register(`selectedFormFolder`, { required: true })}
                onChange={onFormFolderSelect}
                defaultValue={formValues?.selectedFormFolder}
                hideSelectedOptions
                placeholder="Select a form folder"
                isInvalid={false}
              />
            </FormControl>
          </LazyComponent>
          {!isEmpty(groupContext?.groups) && !groupContext?.isLoading ? (
            <FormControl>
              <FormLabel htmlFor="groups">
                Groups{' '}
                <InfoTip label="By default, all groups will have access if none is selected." />
              </FormLabel>
              <Select
                isMulti
                options={groupContext.groups}
                {...register(`template_groups_attributes`)}
                onChange={onChangeGroups}
                defaultValue={formValues?.template_groups_attributes}
                hideSelectedOptions
                placeholder="Select groups"
                key={formValues?.template_groups_attributes}
              />
            </FormControl>
          ) : (
            <FormControl disabled>
              <FormLabel htmlFor="groups">Groups</FormLabel>
              <Select
                isDisabled
                options={[]}
                placeholder="Select a folder first"
              />
            </FormControl>
          )}
        </SimpleGrid>
      </Stack>
    </Flex>
  )
}

export default InlineHeader
