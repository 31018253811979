import { toast } from 'react-toastify'

const actionTransformer = (icon) => {
  if (icon === 'warning') {
    return 'warn'
  }
  return icon
}

const Notification = ({ icon, text, autoClose, position }) => {
  const textFilter = (text) => {
    if (text) {
      return text.length > 500 ? `${text.substring(0, 500)}...` : text
    }
    return ''
  }

  const defaultProps = {
    position: position || toast.POSITION.TOP_CENTER,
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  }

  const toastProps = {
    ...defaultProps,
    position: position || defaultProps.position,
    autoClose: autoClose ?? defaultProps.autoClose,
    closeButton: false
  }

  const action = actionTransformer(icon)

  return toast[action](textFilter(text), toastProps)
}

export default Notification
