import { Button } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../../../components/PageHeader'
import { getRouteUrl } from '../../../../helpers'
import BreadcrumbChecklist from './Breadcrumb'

const ChecklistUserTasksPageHeader = ({
  id,
  readOnly,
  checklistItemId,
  name,
  taskName
}) => {
  return (
    <PageHeader
      Actions={
        <Link to={getRouteUrl('showChecklist', { id })}>
          <Button colorScheme="gray">Cancel</Button>
        </Link>
      }
      Breadcrumb={
        <BreadcrumbChecklist
          name={`${name} ${taskName ? `- ${taskName}` : ''}`}
          checklistItemId={checklistItemId}
          id={id}
          readOnly={readOnly}
        />
      }
      title={`${name} ${taskName ? `- ${taskName}` : ''}`}
    />
  )
}

export default ChecklistUserTasksPageHeader
