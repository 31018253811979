import { Input, SimpleGrid, Switch } from '@chakra-ui/react'
import FormField from 'components/FormField'
import React from 'react'

const AdditionalFieldForm = ({ register, watch, fieldKey, index }) => {
  const key = `${fieldKey}.${index}`
  const compulsory = watch(`${key}.compulsory`)
  return (
    <SimpleGrid spacing={4} columns={[1, 2, 3, 4]} alignItems="center">
      <FormField label="Label">
        <Input
          placeholder="Label"
          {...register(`${key}.label`, { required: true })}
        />
      </FormField>
      <FormField label="Model">
        <Input
          placeholder="Model"
          {...register(`${key}.model`, { required: true })}
        />
      </FormField>
      <FormField label="Attribute name">
        <Input
          placeholder="Attribute Name"
          {...register(`${key}.attribute_name`, { required: true })}
        />
      </FormField>
      <Switch
        {...register(`${key}.compulsory`)}
        isChecked={compulsory}
        colorScheme="green"
      >
        Compulsory
      </Switch>
    </SimpleGrid>
  )
}

export default AdditionalFieldForm
