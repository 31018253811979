import { yupResolver } from '@hookform/resolvers/yup'
import {
  first,
  intersectionBy,
  isBoolean,
  isEmpty,
  isUndefined,
  omit
} from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { getRouteUrl, setFormErrors } from '../../../helpers'
import { usePageProperties } from '../../../hooks'
import Notification from '../../../services/Notification'
import OlingaAPI from '../../../services/OlingaAPI'

const schema = yup
  .object({
    name: yup.string().required('The name is required')
  })
  .required()

const useDocumentEditor = ({ data }) => {
  const document = useMemo(() => {
    return data?.document?.attributes || {}
  }, [data])

  const currentAttachment = useMemo(() => {
    const file = document?.attachment
    if (file?.url) {
      return { ...file, name: `file-${file.id}.${file?.extension}` }
    }
    return null
  }, [document])

  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    reset,
    watch,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      active: true,
      formFolderIds: []
    },
    resolver: yupResolver(schema)
  })

  const fileToUpload = watch('attachment_attributes')

  const uploadRef = useRef(null)

  const { pageTitle, singularTitle, shouldCreate } = usePageProperties({
    pageName: 'documents'
  })

  useEffect(() => {
    if (document?.id) {
      reset({
        name: document?.name,
        active: document?.active,
        formFolderIds: document?.formFolderIds ?? []
      })
    }
  }, [document, reset])

  const history = useHistory()

  const onSubmit = async (data) => {
    if (!document?.id && !data?.attachment_attributes) {
      return Notification({
        icon: 'error',
        text: 'You must choose at least one file to uplaod'
      })
    }
    if (!data?.formFolderIds.length) {
      return Notification({
        icon: 'error',
        text: 'You must choose at least one folder'
      })
    }
    data.form_folder_ids = data.formFolderIds
    data = omit(data, 'formFolderIds')
    setIsSubmitting(true)
    try {
      const ids = [document?.id].filter((id) => !!id)
      const endpoint = document?.id ? 'editDocument' : 'createDocument'
      const result = await OlingaAPI[endpoint](...ids, {
        document: omit(
          {
            ...data,
            library_id: 1
          },
          document?.id ? ['library_id'] : []
        )
      })
      if (result.status === 200) {
        Notification({
          icon: 'success',
          text: result.data.message
        })
      }
      history.push(getRouteUrl('documentsLibrary'))
    } catch (error) {
      setFormErrors(error, setError, Notification)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleChangeDocument = (documents) => {
    if (documents && documents.length) {
      const document = first(documents)
      setValue('attachment_attributes', { media: document })
    }
  }

  const handleUploadUndo = () => {
    setValue('attachment_attributes', null)
    unregister('attachment_attributes')
    uploadRef.current.reset()
  }

  const defaultFolders = useMemo(() => {
    if (document?.formFolderIds) {
      const currentFolders = document.formFolderIds.map((folderId) => ({
        value: folderId
      }))
      return intersectionBy(data.folders, currentFolders, 'value')
    }
    return []
  }, [data.folders, document.formFolderIds])

  const onChangeFolder = (folderIds) => {
    setValue(
      'formFolderIds',
      folderIds.map(({ value }) => value)
    )
  }

  useEffect(() => {
    if (!isUndefined(data?.folders) && isEmpty(data?.folders)) {
      history.push(getRouteUrl('newFormFolder'))
      Notification({
        icon: 'error',
        text: 'This business has no folders. Please add a folder to create a new document'
      })
    }
  }, [data?.folders, history])

  useEffect(() => {
    if (
      !isUndefined(shouldCreate) &&
      isBoolean(shouldCreate) &&
      !shouldCreate
    ) {
      history.push(getRouteUrl('unauthorized'))
    }
  }, [shouldCreate, history])

  return {
    document,
    onChangeFolder,
    defaultFolders,
    handleUploadUndo,
    handleChangeDocument,
    onSubmit,
    fileToUpload,
    register,
    handleSubmit,
    currentAttachment,
    isSubmitting,
    uploadRef,
    pageTitle,
    singularTitle,
    errors
  }
}

export default useDocumentEditor
