import SettingsEditor from 'pages/admin/Settings/SettingsEditor'
import React from 'react'
import BasePage from '../../../../pages/BasePage'
import AdminOlingaAPI from '../../../../services/admin/AdminOlingaAPI'

const SettingsEditorPage = ({ id }) => {
  const callbacks = {
    settings: {
      callback: () => AdminOlingaAPI.getSettings(),
      params: {}
    }
  }

  return (
    <BasePage
      fetchCallback={callbacks}
      id={id}
      fetchWithoutId
      resourceName="data"
    >
      <SettingsEditor />
    </BasePage>
  )
}

export default SettingsEditorPage
