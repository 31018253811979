import { useMemo } from 'react'
import { usePageProperties, useQuery } from '../../../hooks'

const useFormAnswer = ({ data, templateId }) => {
  const templateDetails = useMemo(() => {
    const {
      attributes: {
        user_permissions: permissions,
        form_folder_name: formFolderName,
        name,
        id
      }
    } = data
    return {
      id,
      name,
      formFolderName,
      userPermissions: permissions
    }
  }, [data])

  const { pageTitle, singularTitle } = usePageProperties({
    pageName: 'templates'
  })

  const query = useQuery()

  const cancelRouteParams = {
    name: query?.context || 'templateForm',
    id: query?.contextId || templateId
  }

  return {
    templateDetails,
    pageTitle,
    singularTitle,
    cancelRouteParams
  }
}

export default useFormAnswer
