import styled from '@emotion/styled'

export const Container = styled.div`
  padding: 16px 0px;
  border-top: 2px dotted #f0f0f0;
  border-bottom: 2px dotted #f0f0f0;

  & a:hover {
    color: #06c;
  }
`
