import { EditIcon } from '@chakra-ui/icons'
import { Button, Stack } from '@chakra-ui/react'
import { isEmpty, noop } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import EmptyState from '../../../../../components/EmptyState'
import FormSubmitActions from '../../../../../components/FormSubmitActions'
import Question from '../../../../../components/TemplateRecursiveStructure/Question'
import CalculatedField from './CalculatedField'
import {
  calculatedFieldsEnumTypes,
  questionsTypes,
  templateTypes
} from './constants'
import useCalculatedFields from './hooks/useCalculatedFields'

const CalculatedFields = ({ data, enumerators, onRefetch }) => {
  const { template, questions } = data
  const operations = enumerators.calculationFieldsOptions

  const {
    isLoading,
    onSubmit,
    setValue,
    watch,
    handleSubmit,
    structure,
    goToTemplateEditor,
    onCancel
  } = useCalculatedFields({
    template,
    questions,
    onRefetch
  })

  if (isEmpty(questions)) {
    return (
      <EmptyState
        text="There are no Calculated questions"
        Action={
          <Button
            colorScheme="blue"
            leftIcon={<EditIcon />}
            onClick={goToTemplateEditor}
          >
            Edit template
          </Button>
        }
      />
    )
  }

  return (
    <Stack py={4} gap={4}>
      {questions.map((question) => (
        <Question
          {...question}
          key={question.id}
          hasSelectionComponent={false}
          child={() => (
            <CalculatedField
              watch={watch}
              setValue={setValue}
              structure={structure}
              id={question.id}
              operations={operations}
              currentKey={`${question.id}.question_calculation_fields`}
            />
          )}
        />
      ))}
      <FormSubmitActions
        isLoading={isLoading}
        margin="4"
        onSubmit={handleSubmit(onSubmit)}
        cancelAction={onCancel}
      />
    </Stack>
  )
}

CalculatedFields.defaultProps = {
  data: {
    template: {},
    questions: [],
    operations: []
  },
  onRefetch: noop
}

CalculatedFields.propTypes = {
  data: PropTypes.shape({
    template: templateTypes,
    questions: questionsTypes,
    operations: PropTypes.oneOfType([
      calculatedFieldsEnumTypes,
      PropTypes.arrayOf(calculatedFieldsEnumTypes)
    ])
  }),
  onRefetch: PropTypes.func
}

export default CalculatedFields
