/* eslint-disable react/no-unescaped-entities */
import { Button } from '@chakra-ui/button'
import { Box, Center, Flex, Stack, Text } from '@chakra-ui/layout'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import illustration from '../../..//assets/illustrations/Unauthorized.png'
import { getDashboardUrlParams, getRouteUrl } from '../../helpers'

const UnautorizedPage = () => {
  const user = useSelector(({ globalReducer }) => globalReducer.user)

  const history = useHistory()

  const goToHome = () => {
    const { dashboardId, userType } = getDashboardUrlParams(user)
    history.push({
      pathname: getRouteUrl(dashboardId, { userType })
    })
  }

  return (
    <Stack align="center" justify="center" p="4" w="100%">
      <Flex>
        <Center>
          <img src={illustration} alt="Empty templates" />
        </Center>
      </Flex>
      <Text size="md" color="gray.500">
        You don't have permission to access
      </Text>
      <Box pt={4}>
        <Button w="200px" colorScheme="blue" onClick={goToHome}>
          Go to home page
        </Button>
      </Box>
    </Stack>
  )
}

export default UnautorizedPage
