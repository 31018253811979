import { v4 as uuid } from 'uuid'

export const initialKeyReducerState = ['list', 'range', 'context'].map(
  (item) => ({ [item]: uuid() })
)

export const keyReducer = (state, action) => {
  switch (action.type) {
    case 'list':
      return {
        ...state,
        list: uuid()
      }
    case 'range':
      return {
        ...state,
        range: uuid()
      }
    case 'context':
      return {
        ...state,
        context: uuid()
      }
    default:
      throw new Error('Invalid Action')
  }
}

export const dispatchRerender = (dispatch, object) => dispatch(object)
