import { CheckIcon } from '@chakra-ui/icons'
import { Box } from '@chakra-ui/react'
import React from 'react'
import DefaultFieldContent from '../../Sidebar/DefaultFieldContent'

const DefaultFieldCard = ({ defaultField, onChange, value }) => {
  const isSelected = value?.id === defaultField?.id
  return (
    <Box
      p={4}
      borderRadius="md"
      borderWidth={1}
      borderColor={isSelected ? 'green.400' : 'gray.400'}
      bg={isSelected ? 'green.100' : 'white'}
      onClick={() => onChange(defaultField)}
      cursor="pointer"
      _hover={{
        borderColor: 'green.400'
      }}
      position="relative"
    >
      {isSelected && (
        <CheckIcon position="absolute" top="2" right="2" color="green.500" />
      )}
      <DefaultFieldContent defaultField={defaultField} />
    </Box>
  )
}

export default DefaultFieldCard
