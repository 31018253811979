import { Box, Container, Stack } from '@chakra-ui/layout'
import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { Textarea } from '@chakra-ui/textarea'
import { pick } from 'lodash'
import React, { useMemo } from 'react'
import FormSubmitActions from '../../../components/FormSubmitActions'
import ManageUserForm from '../../ManageUsers/ManageUserForm'
import ProfileBreadcrumb from './ProfileBreadcrumb/index.'
import useProfileForm from './useProfileForm'

const ProfileForm = ({ data, id: profileId }) => {
  const {
    register,
    manageFormRef,
    isLoading,
    userData,
    handleSequentialSubmit,
    onManageUserSubmit,
    errors,
    pageTitle,
    singularTitle
  } = useProfileForm(data, profileId)

  const selectorData = useMemo(() => {
    return {
      ...pick(data, [
        'checklists',
        'templates',
        'roles',
        'assetTemplates',
        'documents'
      ]),
      user: userData
    }
  }, [data, userData])

  return (
    <Stack>
      <Container maxW="1200px">
        <ProfileBreadcrumb
          profile={data?.profile ?? {}}
          pageTitle={pageTitle}
          singularTitle={singularTitle}
        />
        <Box py={4} as="section">
          <Stack gap={4}>
            <FormControl>
              <FormLabel>Profile name</FormLabel>
              <Input
                {...register('name', { required: true })}
                placeholder="Profile name"
                isInvalid={!!errors?.name?.message}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                {...register('description')}
                placeholder="Profile description"
                isInvalid={!!errors?.description?.message}
              />
            </FormControl>
            <ManageUserForm
              data={selectorData}
              ref={manageFormRef}
              isSoloPage={false}
              onExternalSubmit={onManageUserSubmit}
              hasActiveHandler={false}
              displayProfileSelector={false}
            />
          </Stack>
          <FormSubmitActions
            margin={4}
            isLoading={isLoading}
            cancelRoute="profiles"
            onSubmit={handleSequentialSubmit}
          />
        </Box>
      </Container>
    </Stack>
  )
}

export default ProfileForm
