import { omit } from 'lodash'
import React from 'react'
import AssetEditor from '../../../pages/Assets/AssetEditor'
import BasePage from '../../../pages/BasePage'
import OlingaAPI from '../../../services/OlingaAPI'

const AssetsEditor = ({ id }) => {
  const callbacks = omit(
    {
      asset: {
        callback: () => OlingaAPI.asset(id),
        params: {}
      },
      enumerators: {
        callback: OlingaAPI.assetsEnumerator,
        params: {}
      }
    },
    [!id || id === 'new' ? 'asset' : ''].filter((v) => v)
  )
  return (
    <BasePage
      fetchCallback={callbacks}
      id={id}
      fetchWithoutId
      resourceName="data"
      otherParams={{
        id: id === 'new' || !id ? null : id
      }}
    >
      <AssetEditor />
    </BasePage>
  )
}

export default AssetsEditor
