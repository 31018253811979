import { getNumberFromText } from 'helpers'
import { differenceBy, isEmpty, uniqBy } from 'lodash'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Notification from 'services/Notification'

const useNotificationsModal = ({ attributeKey, onClose, onEditField }) => {
  const { setValue, watch } = useFormContext()
  const [isLoading, setIsLoading] = useState(false)

  const [notify, notifiedUsers, deletedNotifiedUsers, notifyDays] = watch([
    `${attributeKey}.notify`,
    `${attributeKey}.notify_field_users_attributes`,
    `${attributeKey}.notify_field_users_attributes_deleted`,
    `${attributeKey}.notify_days`
  ])

  const [notifiedUsersKey, deletedNotifiedUsersKey, notifyDaysKey] = [
    `${attributeKey}.notify_field_users_attributes`,
    `${attributeKey}.notify_field_users_attributes_deleted`,
    `${attributeKey}.notify_days`
  ]

  const onChangeUsers = (users) => {
    const clonedList = [...(notifiedUsers ?? [])]

    const deletedItems = differenceBy(clonedList, users, 'user_id')

    if (!isEmpty(deletedItems.filter(({ id }) => id))) {
      setValue(
        deletedNotifiedUsersKey,
        [...(deletedNotifiedUsers ?? []), ...deletedItems].map((item) => ({
          ...item,
          _destroy: true
        }))
      )
    }

    setValue(notifiedUsersKey, users)
  }

  const onChangeNotifyDays = (newValue) => {
    setValue(notifyDaysKey, newValue)
  }

  const onSave = async () => {
    const index = Number(getNumberFromText(attributeKey))
    if (notify && isEmpty(notifiedUsers)) {
      return Notification({
        icon: 'error',
        text: 'You must notify at least one user'
      })
    }

    setIsLoading(true)
    try {
      await onEditField(index, {
        notify,
        notify_days: notifyDays,
        notify_field_users_attributes: uniqBy(
          [...(notifiedUsers ?? []), ...(deletedNotifiedUsers ?? [])].map(
            (user) => ({
              id: user.id,
              user_id: user.value,
              _destroy: user?._destroy || false
            })
          ),
          'user_id'
        )
      })
    } catch (error) {
      //
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return {
    notifiedUsers,
    onChangeUsers,
    notifyDays,
    notify,
    onChangeNotifyDays,
    onSave,
    isLoading
  }
}

export default useNotificationsModal
