import { useDisclosure } from '@chakra-ui/react'
import { getRouteUrl } from 'helpers'
import { useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import OlingaAPI from 'services/OlingaAPI'
import { usePageProperties } from '../../../hooks'

const useDocuments = () => {
  const history = useHistory()
  const documentListRef = useRef({})
  const { isOpen, onClose, onOpen } = useDisclosure()

  const getFolderListCallbackParams = useCallback(
    ({ shouldShowAllFolders }) => {
      return {
        all: shouldShowAllFolders
      }
    },
    []
  )

  const { pageTitle, singularTitle } = usePageProperties({
    pageName: 'documents'
  })

  const handleAutocompleteSelect = (document) => {
    onClose()
    if (document?.userPermissions?.edit) {
      history.push(
        getRouteUrl('editDocument', {
          id: document.id
        })
      )
    }
  }

  const getAutocompleteCallback = (value) => {
    return OlingaAPI.searchDocuments({ params: { name: value } })
  }

  return {
    documentListRef,
    getFolderListCallbackParams,
    pageTitle,
    singularTitle,
    getAutocompleteCallback,
    handleAutocompleteSelect,
    onAutocompleteOpen: onOpen,
    isOpen,
    onClose
  }
}

export default useDocuments
