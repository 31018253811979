import { debounce, isEmpty, isFunction, isNull } from 'lodash'
import { sanitizeHTML } from 'pages/Reports/ReportsEditor/helpers'
import { getLinearStructure } from 'pages/TemplateEditor/helpers'
import { useCallback, useEffect, useState } from 'react'
import Notification from 'services/Notification'
import OlingaAPI from 'services/OlingaAPI'

const useTemplatesSelector = ({
  contextType = 'templates',
  selector = 'templates',
  onChange,
  onOpen,
  selections = {}
}) => {
  const [{ folders, templates, templateStructure, options }, setResources] =
    useState({
      folders: [],
      templates: [],
      templateStructure: null,
      options: []
    })
  const [
    {
      selectedFolder,
      selectedTemplate,
      selectedQuestion,
      selectedQuestionOption
    },
    setSelectedResource
  ] = useState(
    !isEmpty(selections)
      ? selections
      : {
          selectedFolder: null,
          selectedTemplate: null,
          selectedQuestion: null,
          selectedQuestionOption: null
        }
  )
  const [
    { isLoadingFolders, isLoadingTemplates, isLoadingTemplateStructure },
    setIsLoading
  ] = useState({
    isLoadingFolders: false,
    isLoadingTemplates: false,
    isLoadingTemplateStructure: false
  })

  const fetchTemplates = useCallback(async (folderId) => {
    setIsLoading((prev) => ({
      ...prev,
      isLoadingTemplates: true
    }))
    try {
      const response = await OlingaAPI.formFolderTemplatesList(folderId)
      if (response?.data) {
        setResources((prev) => ({
          ...prev,
          templates: response.data.map(
            ({ attributes: { id: value, name: label } }) => ({ label, value })
          )
        }))
      }
    } catch (error) {
      //
    } finally {
      setIsLoading((prev) => ({
        ...prev,
        isLoadingTemplates: false
      }))
    }
  }, [])

  const fetchTemplateStructure = useCallback(
    async (templateId) => {
      setIsLoading((prev) => ({
        ...prev,
        isLoadingTemplateStructure: true
      }))
      try {
        const response = await OlingaAPI.templateStructure(templateId)
        if (response?.data?.attributes) {
          const complete = response?.data?.attributes
          const linear = getLinearStructure(complete)
          if (!isEmpty(selectedQuestion?.template_question_options)) {
            setResources((prev) => ({
              ...prev,
              options: selectedQuestion?.template_question_options?.map(
                ({ id: value, label }) => ({ label, value })
              )
            }))
          }
          setResources((prev) => {
            return {
              ...prev,
              templateStructure: {
                complete,
                linear
              }
            }
          })
        }
      } catch (error) {
        //
      } finally {
        setIsLoading((prev) => ({
          ...prev,
          isLoadingTemplateStructure: false
        }))
      }
    },
    [selectedQuestion?.template_question_options]
  )

  const onSelectFolder = async (folder) => {
    let updatedContext
    setSelectedResource((prev) => {
      updatedContext = {
        ...prev,
        selectedFolder: folder
      }
      return updatedContext
    })
    if (folder?.value) {
      try {
        setSelectedResource((prev) => ({
          ...prev,
          selectedTemplate: null,
          selectedQuestion: null,
          selectedQuestionOption: null
        }))
        await fetchTemplates(folder?.value)
      } catch (error) {
        Notification({
          icon: 'error',
          text: 'Unable to fetch templates'
        })
      }
    }
  }

  const onSelectTemplate = async (template) => {
    let updatedContext
    setSelectedResource((prev) => {
      updatedContext = {
        ...prev,
        selectedTemplate: template,
        selectedQuestion: null,
        selectedQuestionOption: null
      }
      return updatedContext
    })
    if (template?.value) {
      try {
        if (
          ['template_question', 'template_question_option'].includes(selector)
        ) {
          await fetchTemplateStructure(template.value)
        }

        if (['templates', 'template'].includes(selector)) {
          onChange([template, updatedContext])
        }
      } catch (error) {
        Notification({
          icon: 'error',
          text: 'Unable to fetch templates'
        })
      }
    }
  }

  const onSelectQuestion = (question) => {
    const selectedQuestion = {
      ...(templateStructure?.linear?.object?.[question] ?? {}),
      text: sanitizeHTML(templateStructure?.linear?.object?.[question]?.text),
      label: sanitizeHTML(templateStructure?.linear?.object?.[question]?.text),
      value: templateStructure?.linear?.object?.[question].id
    }
    let updatedContext
    setSelectedResource((prev) => {
      updatedContext = {
        ...prev,
        selectedQuestion: selectedQuestion,
        selectedQuestionOption: null
      }
      return updatedContext
    })

    if (selector === 'template_question') {
      onChange([selectedQuestion, updatedContext])
    } else if (selector === 'template_question_option') {
      setResources((prev) => ({
        ...prev,
        options: selectedQuestion?.template_question_options?.map(
          ({ id: value, label }) => ({ label, value })
        )
      }))
    }

    debounce(() => {
      if (isFunction(onOpen)) {
        onOpen()
      }
    }, 500)()
  }

  const onSelectOptions = (newOption) => {
    let updatedContext
    setSelectedResource((prev) => {
      updatedContext = {
        ...prev,
        selectedQuestionOption: newOption
      }
      return updatedContext
    })

    if (selector === 'template_question_option') {
      onChange([newOption, updatedContext])
    }
  }

  useEffect(() => {
    const fetchFolders = async () => {
      setIsLoading((prev) => ({
        ...prev,
        isLoadingFolders: true
      }))
      try {
        const data = await OlingaAPI.foldersEnumerator(contextType)
        setResources((prev) => ({
          ...prev,
          folders: data
        }))
      } catch (error) {
        //
      } finally {
        setIsLoading((prev) => ({
          ...prev,
          isLoadingFolders: false
        }))
      }
    }

    fetchFolders()
  }, [contextType])

  useEffect(() => {
    const definedEntries = Object.entries(selections)
      .filter(([, v]) => !isNull(v) && !isEmpty(v))
      .reduce((acc, [k]) => {
        acc = [...acc, k]
        return acc
      }, [])
    if (definedEntries.includes('selectedFolder')) {
      fetchTemplates(selections.selectedFolder.value)
    }
    if (definedEntries.includes('selectedTemplate')) {
      fetchTemplateStructure(selections.selectedTemplate.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isLoadingFolders,
    isLoadingTemplates,
    isLoadingTemplateStructure,
    fetchTemplates,
    onSelectFolder,
    onSelectTemplate,
    onSelectQuestion,
    onSelectOptions,
    selectedFolder,
    selectedTemplate,
    selectedQuestion,
    selectedQuestionOption,
    folders,
    templates,
    options,
    templateStructure
  }
}

export default useTemplatesSelector
