import { theme } from '@chakra-ui/react'
import logoPath from '../../../../../../assets/images/cxmp/olinga_white_logo.png'
import { getRouteUrl } from '../../../../../helpers/admin'
import actions from '../../../../../store/actions'
const { SET_GLOBAL_SETTINGS } = actions.global

export const formatGlobalObject = (globalProps) => {
  return {
    type: SET_GLOBAL_SETTINGS,
    payload: {
      ...globalProps,
      navbar: {
        logo_path: logoPath,
        bg: theme.colors.blue[500],
        border: theme.colors.blue[200],
        highlight: theme.colors.blue[700],
        hover: theme.colors.blue[100],
        text: theme.colors.white,
        menus: [
          {
            id: 'businesses',
            name: 'Businesses',
            icon: 'business_center',
            url: getRouteUrl('businesses')
          },
          {
            id: 'assets',
            name: 'Assets',
            icon: 'web_asset',
            dropdown: [
              {
                name: 'Types',
                url: getRouteUrl('assetTypes')
              },
              {
                name: 'Default Fields',
                url: getRouteUrl('assetDefaultFields')
              }
            ]
          },
          {
            id: 'settings',
            name: 'Settings',
            icon: 'settings',
            url: getRouteUrl('settings')
          }
        ]
      }
    }
  }
}
