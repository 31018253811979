import {
  Box,
  Button,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  theme,
  useDisclosure
} from '@chakra-ui/react'
import React, { forwardRef, memo } from 'react'
import AssociationSelector from './AssociationSelector'

const MatchValueSelector = forwardRef(
  (
    {
      value,
      selections = {},
      onChange,
      onCustomChange,
      association,
      isInvalid
    },
    ref
  ) => {
    const { isOpen, onClose, onOpen } = useDisclosure()

    if (association?.value === 'custom') {
      return (
        <Input
          value={value?.value}
          onChange={onCustomChange}
          placeholder="Input a custom value"
        />
      )
    }

    return (
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        zIndex={theme.zIndices.overlay + 1}
      >
        <PopoverTrigger>
          {value ? (
            <Box
              borderWidth={1}
              p={4}
              py={1.5}
              tabIndex={1}
              cursor="pointer"
              borderRadius="md"
              ref={ref}
              borderColor={isInvalid ? 'red' : 'gray.200'}
            >
              <Text noOfLines={1}>{value?.text || value?.label}</Text>
            </Box>
          ) : (
            <Button variant="outline" colorScheme="blue">
              Select a match value
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>Select a Match Value</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            {association?.value ? (
              <AssociationSelector
                onChange={onChange}
                association={association}
                onOpen={onOpen}
                value={value}
                selections={selections}
              />
            ) : (
              <Text>Please, select an association first</Text>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    )
  }
)

MatchValueSelector.displayName = 'MatchValueSelector'

MatchValueSelector.defaultValues = {
  association: null
}

export default memo(MatchValueSelector)
