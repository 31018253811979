import { DeleteIcon } from '@chakra-ui/icons'
import { Flex, IconButton, Image, Stack, Tooltip } from '@chakra-ui/react'
import ImageCarousel from 'components/ImageCarousel'
import MaterialIcon from 'components/MaterialIcon'
import { first, isEmpty, isString, last, toLower } from 'lodash'
import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import Notification from 'services/Notification'
import { Input, PreviewInputWrapper } from '../UploadFile/styles'

const ImageInput = ({
  onChange,
  value,
  isInvalid,
  extensions = ['jpg', 'jpeg', 'png'],
  shouldDisplayPlaceholder = false
}) => {
  const inputRef = useRef(null)
  const carouselRef = useRef()

  const { system } = useSelector(({ globalReducer }) => globalReducer)

  const imagePlaceholder = system?.empty_image_path

  const file = useMemo(() => {
    if (value && isString(value)) {
      return value
    }
    if (value?.name) {
      return URL?.createObjectURL(value)
    }

    return null
  }, [value])

  const handleDrop = (event) => {
    preventDefaults(event)
    event.target.files = event?.dataTransfer?.files
    onChangeFiles(event)
  }

  const preventDefaults = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const validExtensions = extensions.map(
    (extension, index) =>
      `${extension}${index < extensions.length - 1 ? ' | ' : ''}`
  )

  const onChangeFiles = (evt) => {
    const newFiles = evt?.target?.files || []
    if (
      !Array.from(newFiles).every(({ name }) =>
        extensions.includes(toLower(last(name.split('.'))))
      )
    ) {
      return Notification({
        text: 'Invalid image extension',
        icon: 'error'
      })
    }
    if (!isEmpty(newFiles)) {
      onChange(first(newFiles))
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    }
  }

  const onRemoveFile = () => {
    onChange(null)
  }

  const handleInputChange = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const onOpenFullImage = () => {
    const {
      current: { open }
    } = carouselRef

    open()
  }

  return (
    <>
      {isString(file) && (
        <ImageCarousel
          images={[file]}
          ref={carouselRef}
          initialSelectedImage={0}
        />
      )}
      <Stack w="320px" h="180px">
        <Tooltip
          hasArrow
          label={
            file || shouldDisplayPlaceholder
              ? `Drag and drop a image here, or click to select an image! Only images extensions are valid! (${validExtensions})`
              : ''
          }
        >
          <PreviewInputWrapper
            onClick={handleInputChange}
            onDrop={handleDrop}
            onDragEnter={preventDefaults}
            onDragLeave={preventDefaults}
            onDragOver={preventDefaults}
            isInvalid={isInvalid}
            file={value}
          >
            <Input
              onChange={onChangeFiles}
              type="file"
              ref={inputRef}
              accept={extensions}
              multiple={false}
              data-testid="photo-uploader"
              id="photo-uploader"
            />
            {file ? (
              <Image
                maxW="100%"
                borderRadius="sm"
                w="auto"
                h="180px"
                src={file}
                fit
              />
            ) : shouldDisplayPlaceholder ? (
              <Image
                maxW="100%"
                borderRadius="md"
                w="100%"
                h="auto"
                src={imagePlaceholder}
                fit
              />
            ) : (
              <>
                <p>Drag and drop a image here, or click to select an image</p>
                <b>Only images extensions are valid ({validExtensions})</b>
              </>
            )}
          </PreviewInputWrapper>
        </Tooltip>
        {file && (
          <Flex gap={2} w="100%" justify="center">
            <IconButton
              size="sm"
              aria-label="Remove file"
              colorScheme="red"
              variant="solid"
              icon={<DeleteIcon />}
              onClick={onRemoveFile}
            />
            <IconButton
              size="sm"
              aria-label="Open full image"
              colorScheme="blue"
              variant="solid"
              icon={<MaterialIcon icon='fullscreen' />}
              onClick={onOpenFullImage}
            />
          </Flex>
        )}
      </Stack>
    </>
  )
}

export default ImageInput
