import { Flex, Heading, Skeleton, Spinner, Stack, Text } from '@chakra-ui/react'
import EmptyState from 'components/EmptyState'
import { invertColor } from 'helpers'
import Chart from 'pages/Dashboard/Chart'
import React from 'react'

const ChartPreview = ({ preview, isLoading }) => {
  if (isLoading) {
    return (
      <Flex align="center" gap={4} justify="center">
        <Text>Loading Chart Data</Text>
        <Spinner />
      </Flex>
    )
  }
  if (preview?.chartType === 'chart' && !preview?.chartData?.total) {
    return (
      <Stack borderWidth={1} borderRadius="md" p={6}>
        <Heading size="sm">{preview.name}</Heading>
        <EmptyState text="We couldn’t find any data for this chart" />
      </Stack>
    )
  }
  if (preview?.chartType === 'number') {
    return (
      <Stack
        w="100%"
        minH="150px"
        bg={invertColor(preview.color, true)}
        gap={4}
        align="center"
        justify="center"
        borderRadius="md"
        borderWidth={1}
      >
        <Heading color={preview.color} size="lg">
          {preview.name}
        </Heading>
        <Heading color={preview.color} size="lg" fontWeight="regular">
          {preview?.chartData?.total}
        </Heading>
      </Stack>
    )
  }
  if (preview?.chartType === 'chart' && preview?.chartData) {
    return (
      <Stack gap={4} w="100%">
        <Chart
          title={preview.name}
          dashboardData={preview.chartData}
          filter="today"
          defaultType="bar"
          shouldChangeFormatView
        />
      </Stack>
    )
  }

  return <Skeleton h="300px" w="100%" />
}

export default ChartPreview
