import { AddIcon } from '@chakra-ui/icons'
import { Button, Flex, FormControl, SimpleGrid, Stack } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import SearchInput from 'components/SearchInput'
import React from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import { getRouteUrl } from '../../helpers'
import useActions from './useActions/index'

const Actions = ({ data: { enumerators } }) => {
  const {
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data,
    isLoading,
    refetch,
    fetchParams,
    pagination,
    onTextChange,
    onPriorityChange,
    onStatusChange,
    pageTitle,
    hasPermissionToCreateActions
  } = useActions({ enumerators })

  const status = enumerators?.status ?? []
  const priorities = enumerators?.priorities ?? []

  return (
    <Stack as="section">
      <PageHeader
        title={pageTitle}
        Actions={
          <Flex align="end" gap={4} flexDir="column">
            {hasPermissionToCreateActions ? (
              <Link to={getRouteUrl('newAction')}>
                <Button colorScheme="blue" leftIcon={<AddIcon />}>
                  New
                </Button>
              </Link>
            ) : null}
            <SimpleGrid spacing={4} columns={[1, 2, 3]} w="100%">
              <FormControl>
                <SearchInput
                  value={fetchParams?.title}
                  setValue={onTextChange}
                  isLoading={isLoading}
                  debounceTimeout={25}
                  placeholder="Title"
                  maxWidth="300px"
                />
              </FormControl>
              <FormControl>
                <Select
                  options={status}
                  placeholder="Status"
                  isClearable
                  onChange={onStatusChange}
                  value={fetchParams?.status}
                />
              </FormControl>
              <FormControl>
                <Select
                  options={priorities}
                  placeholder="Priority"
                  isClearable
                  onChange={onPriorityChange}
                  value={fetchParams?.priority}
                />
              </FormControl>
            </SimpleGrid>
          </Flex>
        }
      />
      <Table
        shouldResetPage={fetchParams?.page === 1}
        isLoading={isLoading}
        data={data}
        columns={columns}
        pageCount={pagination?.last}
        totalItems={pagination?.items}
        fetchPage={refetch}
        initiallyHiddenColumns={hiddenColumns}
        initialPageIndex={Number(fetchParams?.page - 1)}
        persistedColumnsKey={hiddenColumnsKey}
      />
    </Stack>
  )
}

export default Actions
