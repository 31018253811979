import { Button } from '@chakra-ui/button'
import styled from '@emotion/styled'

export const QRCodeButton = styled(Button)`
  ${({ withoutbg }) =>
    withoutbg === 'true' && `background-color: #FFF !important`};
  color: #ff871c !important;
  display: flex;
  align-items: center;
  width: auto;
  & i {
    font-style: normal;
    font-size: 18px;
    color: #ff871c !important;
  }
  & i:not(:last-of-type) {
    margin-right: 8px;
    font-size: 18px;
  }
  & i:last-of-type {
    margin-left: 8px;
  }
  &:focus {
    box-shadow: none !important;
  }
`

export const DropdownOverlay = styled.div`
  padding: ${({ noPadding }) => (noPadding ? '0px' : '5px 10px')};
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const OverlayTitle = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  font-weight: bold;
`

export const QRCodeUnit = styled.div`
  font-size: 10px;
  text-align: left;
`
