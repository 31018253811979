import {
  Box,
  Flex,
  Heading,
  ListItem,
  OrderedList,
  Stack,
  Tag,
  Text
} from '@chakra-ui/react'
import { capitalize, isArray, isEmpty } from 'lodash'
import React from 'react'
import { iconsTransformer } from '../../hooks/useAssetTemplateEditor'
import MaterialIcon from 'components/MaterialIcon'

const DefaultFieldContent = ({ defaultField }) => {
  return (
    <Stack>
      <Flex align="center" gap={2}>
        <Heading size="sm">Name:</Heading>
        {defaultField?.name ? (
          <Text>{defaultField?.name}</Text>
        ) : (
          <Tag size="sm" colorScheme="gray">
            Empty
          </Tag>
        )}
      </Flex>
      <Flex align="center" gap={2}>
        <Heading size="sm">Field type:</Heading>
        {defaultField?.field_type ? (
          <Flex align="center" gap={2}>
            <Text>{capitalize(defaultField?.field_type)}</Text>
            <MaterialIcon icon={iconsTransformer[defaultField?.field_type]} />
          </Flex>
        ) : (
          <Tag size="sm" colorScheme="gray">
            Empty
          </Tag>
        )}
      </Flex>
      {isArray(defaultField?.settings?.list_options) &&
        !isEmpty(defaultField?.settings?.list_options) && (
          <Stack align="flex-start" gap={2} w="100%">
            <Heading size="sm">List options:</Heading>
            <OrderedList pl={4}>
              {defaultField?.settings?.list_options.map((option, index) => (
                <ListItem key={index}>{option?.name || option}</ListItem>
              ))}
            </OrderedList>
          </Stack>
        )}
    </Stack>
  )
}

export default DefaultFieldContent
