import { CopyIcon } from '@chakra-ui/icons'
import { Flex, IconButton, Tooltip, useClipboard } from '@chakra-ui/react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { chakraComponentsSizes } from '../../helpers'

const Clipboard = ({ children, value, buttonSize, dismissInterval }) => {
  const { onCopy, hasCopied } = useClipboard(value)
  const [colorScheme, setColorScheme] = useState('gray')

  const handleContentCopy = () => {
    onCopy()
  }

  useEffect(() => {
    if (hasCopied) {
      setColorScheme('blue')
      _.debounce(() => {
        setColorScheme('gray')
      }, dismissInterval)()
    }
  }, [dismissInterval, hasCopied])

  return (
    <Flex align="Center" data-testid='clipboard'>
      {children}
      <Tooltip hasArrow label="Copied!" isOpen={colorScheme === 'blue'}>
        <IconButton
          ml={2}
          size={buttonSize}
          icon={<CopyIcon />}
          aria-label="Copy content to clipboard"
          colorScheme={colorScheme}
          onClick={handleContentCopy}
          data-testid='copy-action'
          variant="outline"
          isRound
          border="none"
        />
      </Tooltip>
    </Flex>
  )
}

Clipboard.defaultProps = {
  buttonSize: 'sm',
  dismissInterval: 3000
}

Clipboard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  value: PropTypes.string.isRequired,
  dismissInterval: PropTypes.number,
  buttonSize: PropTypes.oneOf(chakraComponentsSizes)
}

export default Clipboard
