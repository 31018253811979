import { useGlobalStateContext } from '../../../../../contexts/GlobalStateContext'
import { onChangeTemplateSettingsTab } from '../../../../../contexts/GlobalStateContext/events'
import useTabsParams from '../../../../../pages/TemplateSettings/TemplateSettingsTabs/hooks/useTabsParams'

const useShowOperations = ({ watch, currentKey, id }) => {
  const { global$ } = useGlobalStateContext()
  const { onChangeParams } = useTabsParams()
  const items = watch(currentKey, [])

  const onCreateOperation = () => {
    const tabId = 'calculated_fields'
    global$.next(
      onChangeTemplateSettingsTab({
        id: tabId,
        questionId: id,
        action: 'create'
      })
    )
    onChangeParams({ tab: tabId, question_id: id, action: 'create' })
  }

  const onEditOperation = (id) => {
    const tabId = 'calculated_fields'
    global$.next(
      onChangeTemplateSettingsTab({
        id: tabId,
        operationId: id,
        action: 'edit'
      })
    )
    onChangeParams({ tab: tabId, operation_id: id, action: 'edit' })
  }

  return {
    onEditOperation,
    onCreateOperation,
    items
  }
}

export default useShowOperations
