import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { EditIcon, LinkIcon, ViewIcon } from '@chakra-ui/icons'
import Kebab from '../../../components/Kebab'
import { useLocation } from 'react-router-dom'
import { chakraComponentsSizes } from '../../../helpers'

const ActionKebab = ({ id, size, icon, hasBorder, zIndex }) => {
  const location = useLocation()

  const currentRoute = useMemo(() => {
    const path = location.pathname
    if (path.match('edit')) {
      return 'editAction'
    } else {
      return 'viewAction'
    }
  }, [location])

  return (
    <Kebab
      id={id}
      size={size}
      icon={icon}
      hasBorder={hasBorder}
      zIndex={zIndex}
      items={[
        {
          name: 'View Action',
          icon: <ViewIcon />,
          route: 'viewAction'
        },
        {
          name: 'Edit Action',
          icon: <EditIcon />,
          route: 'editAction'
        }
      ]}
      blackList={[currentRoute]}
      blackListAttribute="route"
    />
  )
}

ActionKebab.defaultProps = {
  size: 'sm',
  zIndex: 10,
  icon: <LinkIcon />,
  hasBorder: true
}

ActionKebab.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(chakraComponentsSizes),
  hasBorder: PropTypes.bool
}

export default ActionKebab
