import { MoonIcon, RepeatClockIcon, SunIcon } from '@chakra-ui/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { first, get, isEmpty, isNull, isString, noop, pick } from 'lodash'
import React, { useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { getRouteUrl } from '../../../helpers'
import { usePageProperties } from '../../../hooks'
import Notification from '../../../services/Notification'
import OlingaAPI from '../../../services/OlingaAPI'

const defaultValues = {
  template_id: null,
  title: '',
  active: true,
  check_frequency: {
    type: {
      value: ''
    },
    week_day: {
      value: ''
    },
    start_time: '',
    end_time: ''
  },
  check_rule: {
    type: {
      value: ''
    },
    question_id: null
  }
}

const blankDependencies = {
  checkFrequencyType: [],
  checkFrequencyTime: [],
  checkRuleType: [],
  weekDayCheck: [],
  templateStructure: [],
  templateDetails: {},
  formDefaultValues: defaultValues
}

const schema = yup
  .object({
    title: yup.string().required('The title is required'),
    active: yup.bool().required(),
    check_frequency: yup.object().shape({
      type: yup.object().shape({
        value: yup.string().required('The type is required')
      }),
      week_day: yup
        .object()
        .shape({
          value: yup.string()
        })
        .when('type', {
          is: ({ value }) => value === 'weekly',
          then: () =>
            yup.object().shape({
              value: yup.string().required()
            })
        }),
      start_time: yup.string().required('The Start time is required'),
      end_time: yup.string().required('The End time is required')
    }),
    check_rule: yup.object().shape({
      type: yup.object().shape({
        value: yup.string().required('The type is required')
      }),
      question_id: yup.number().when('type', {
        is: ({ value }) => {
          return value === 'question'
        },
        then: () => yup.number().required(),
        otherwise: () => yup.number().notRequired().nullable()
      })
    })
  })
  .required()

export const getDayPeriodByTime = (value) => {
  const castedValue =
    isString(value) && !!value ? Number(first(value?.split(':'))) : null
  if (isNaN(castedValue) || isNull(castedValue)) {
    return <RepeatClockIcon />
  }
  return castedValue >= 6 && castedValue <= 18 ? <SunIcon /> : <MoonIcon />
}

const getTimeAsNumber = (time) => {
  const [hours, minutes] = time.split(':')
  return Number(`${hours}${minutes}`)
}

const sanitizeForm = (form, templateId) => ({
  template_frequency_setting: {
    ...form,
    template_id: templateId,
    check_frequency: {
      ...form.check_frequency,
      type: form.check_frequency.type?.value,
      week_day: form.check_frequency.week_day?.value
    },
    check_rule: {
      ...form.check_rule,
      type: form.check_rule?.type?.value
    }
  }
})

const useTemplateFrequencyEditor = ({ data, templateId, id }) => {
  const history = useHistory()

  const {
    checkFrequencyTypeOptions,
    checkFrequencyTimeOptions,
    checkRuleTypeOptions,
    weekDayCheckOptions,
    templateStructure,
    templateDetails,
    formDefaultValues
  } = useMemo(() => {
    if (!isEmpty(data)) {
      const {
        checkFrequencyType,
        checkFrequencyTime,
        checkRuleType,
        weekDayCheck
      } = data.enums
      const structure = { data: null, children: [], answers: null }
      if (!isEmpty(data?.templateStructure)) {
        const { attributes } = data?.templateStructure
        structure.data = attributes
        structure.children = attributes?.children ?? []
      }
      if (!isEmpty(data?.data?.attributes)) {
        const answersData = data?.data?.attributes
        structure.answers = {
          template_id: answersData.template_id,
          title: answersData.title,
          active: answersData.active,
          check_frequency: {
            type: get(answersData, 'check_frequency.type')
              ? checkFrequencyType.find(
                  ({ value }) =>
                    value === get(answersData, 'check_frequency.type')
                )
              : { value: '' },
            week_day: get('check_frequency.week_day')
              ? weekDayCheck.find(
                  ({ value }) =>
                    value === get(answersData, 'check_frequency.week_day')
                )
              : { value: '' },
            start_time: get(answersData, 'check_frequency.start_time'),
            end_time: get(answersData, 'check_frequency.end_time')
          },
          check_rule: {
            type: get(answersData, 'check_rule.type')
              ? checkRuleType.find(
                  ({ value }) => value === get(answersData, 'check_rule.type')
                )
              : { value: '' },
            question_id: get(answersData, 'check_rule.question_id')
          }
        }
      }
      return {
        checkFrequencyTypeOptions: checkFrequencyType.map((option) => ({
          ...option,
          isDisabled: option.value === 'weekly'
        })),
        checkFrequencyTimeOptions: checkFrequencyTime.map((option) => {
          const { value } = option

          return {
            ...option,
            icon: getDayPeriodByTime(value)
          }
        }),
        checkRuleTypeOptions: checkRuleType.map((option) => ({
          ...option,
          isDisabled: option.value === 'question'
        })),
        weekDayCheckOptions: weekDayCheck,
        templateStructure: structure.children,
        templateDetails: pick(structure.data, [
          'id',
          'name',
          'formFolderName',
          'children',
          'action',
          'userPermissions'
        ]),
        formDefaultValues: structure.answers ?? defaultValues
      }
    }
    return blankDependencies
  }, [data])

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(schema)
  })

  const { pageTitle, singularTitle } = usePageProperties({
    pageName: 'templates'
  })

  const questionsSelectorRef = useRef({ open: noop, close: noop })

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (formData) => {
    try {
      setIsLoading(true)
      const sanitizedForm = sanitizeForm(formData, templateId)
      let response = null
      if (id) {
        response = await OlingaAPI.editTemplateFrequencySettings(
          id,
          templateId,
          sanitizedForm
        )
      } else {
        response = await OlingaAPI.createTemplateFrequencySettings(
          templateId,
          sanitizedForm
        )
      }
      if (response?.data?.id) {
        history.push(getRouteUrl('templateFrequencies', { id: templateId }))
        Notification({
          icon: 'success',
          text: 'Template frequency settings created successfully!'
        })
      }
    } catch (e) {
      //
    } finally {
      setIsLoading(false)
    }
  }

  const [
    title,
    checkFrequencyType,
    checkFrequencyStartTime,
    checkFrequencyEndTime,
    checkFrequencyWeekDay,
    checkRuleType,
    checkRuleQuestionId
  ] = watch([
    'title',
    'check_frequency.type',
    'check_frequency.start_time',
    'check_frequency.end_time',
    'check_frequency.week_day',
    'check_rule.type',
    'check_rule.question_id'
  ])

  const checkFrequencyTime = useMemo(() => {
    if (checkFrequencyStartTime && !checkFrequencyEndTime) {
      return checkFrequencyTimeOptions.map((option) => {
        return {
          ...option,
          disabled:
            getTimeAsNumber(option.value) <=
            getTimeAsNumber(checkFrequencyStartTime)
        }
      })
    }
    if (checkFrequencyEndTime && !checkFrequencyStartTime) {
      return checkFrequencyTimeOptions.map((option) => {
        return {
          ...option,
          disabled:
            getTimeAsNumber(option.value) >=
            getTimeAsNumber(checkFrequencyEndTime)
        }
      })
    }
    return checkFrequencyTimeOptions
  }, [
    checkFrequencyTimeOptions,
    checkFrequencyStartTime,
    checkFrequencyEndTime
  ])

  const onChangeCheckFrequencyStartTime = (value) => {
    if (!!value && checkFrequencyEndTime) {
      if (getTimeAsNumber(value) >= getTimeAsNumber(checkFrequencyEndTime)) {
        setValue('check_frequency.end_time', '')
      }
    }
    setValue('check_frequency.start_time', value)
  }

  const onChangeCheckFrequencyEndTime = (value) => {
    if (!!value && checkFrequencyStartTime) {
      if (getTimeAsNumber(value) <= getTimeAsNumber(checkFrequencyStartTime)) {
        setValue('check_frequency.start_time', '')
      }
    }
    setValue('check_frequency.end_time', value)
  }

  const onChangeCheckFrequencyType = (value) => {
    setValue('check_frequency.type', value)
  }

  const onChangeCheckFrequencyWeekDay = (value) => {
    setValue('check_frequency.week_day', value)
  }

  const onChangeCheckRuleType = (value) => {
    setValue('check_rule.type', value)
  }

  const onChangeCheckRuleQuestionId = (value) => {
    setValue('check_rule.question_id', value)
  }

  const onError = () => {
    const formErrors = Object.entries(errors)
    if (formErrors?.length > 0) {
      formErrors.forEach(([key, value]) => {
        if (value?.required) {
          Notification({
            icon: 'error',
            text: `The ${key} is required`
          })
        }
      })
    }
  }

  return {
    pageTitle,
    singularTitle,
    register,
    handleSubmit,
    setValue,
    title,
    templateStructure,
    checkFrequencyTypeOptions,
    checkFrequencyTimeOptions: checkFrequencyTime,
    checkRuleTypeOptions,
    weekDayCheckOptions,
    checkFrequencyType,
    checkFrequencyStartTime,
    checkFrequencyEndTime,
    checkFrequencyWeekDay,
    checkRuleType,
    checkRuleQuestionId,
    onChangeCheckFrequencyType,
    onChangeCheckFrequencyStartTime,
    onChangeCheckFrequencyEndTime,
    onChangeCheckFrequencyWeekDay,
    onChangeCheckRuleType,
    onChangeCheckRuleQuestionId,
    onSubmit,
    questionsSelectorRef,
    isLoading,
    templateDetails,
    errors,
    onError
  }
}

export default useTemplateFrequencyEditor
