import { Box } from '@chakra-ui/react'
import moment, { isDate } from 'moment'
import React from 'react'
import DatePicker from '../../../../../../components/DatePicker'

const QuarterlyChecklistRepeat = ({
  index,
  attributeKey,
  setValue,
  watch,
  scrollToModalEnd
}) => {
  const currentValue = watch(`${attributeKey}.${index}`)
  const onChange = (value) => {
    if (isDate(value)) {
      const momentDate = moment(value)
      const [day, month] = [momentDate.get('D'), momentDate.get('M')]
      setValue(`${attributeKey}.${index}`, {
        ...(currentValue ?? {}),
        quarterly_day: day,
        month: month + 1
      })
    }
  }
  const getFormattedValue = () => {
    if (['quarterly_day', 'month'].every((k) => !!currentValue[k])) {
      const { quarterly_day: day, month } = currentValue
      return moment()
        .set('D', day)
        .set('M', month - 1)
        .toDate()
    }
    return ''
  }
  return (
    <Box onClick={scrollToModalEnd}>
      <DatePicker
        showTimeSelect={false}
        displayPredefinedDates={false}
        onChange={onChange}
        initialStartDate={getFormattedValue()}
        placement="right-start"
        dateLabelFormatter={() => 'dd/MM'}
      />
    </Box>
  )
}

export default QuarterlyChecklistRepeat
