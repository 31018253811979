import colors from '../../../utils/colors'
import styled from '@emotion/styled'
import theme from '@chakra-ui/theme'

export const InfoWrapper = styled.div`
  color: ${theme.colors.gray[500]};
  ${({ isLink }) =>
    isLink &&
    `
    color: ${colors.primary};

    & input {
      cursor: pointer;
    }

    &:hover {
      color: ${theme.colors.orange[500]};
    }

  `}
`
