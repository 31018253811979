import React from 'react'
import BasePage from '../../../pages/BasePage'
import LibraryEditor from '../../../pages/LibraryEditor'
import OlingaAPI from '../../../services/OlingaAPI'

const LibraryEditorPage = ({ id }) => {
  return (
    <BasePage
      id={id}
      resourceName="data"
      fetchWithoutId={false}
      fetchCallback={OlingaAPI.showLibrary}
    >
      <LibraryEditor />
    </BasePage>
  )
}

export default LibraryEditorPage
