import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Switch,
  Text
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { isObject } from 'lodash'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import EditInline from '../../components/Inputs/EditInline'
import PageHeader from '../../components/PageHeader'
import QuestionsSelector from '../../components/QuestionsSelector'
import ToggleValueModal from '../../components/ToggleValueModal'
import { getRouteUrl } from '../../helpers'
import TemplatePageBreadcrumb from '../Template/TemplatePagesBreadcrumb'
import { selectWrapperStyles } from './styles'
import useTemplateFrequencyEditor, {
  getDayPeriodByTime
} from './useTemplateFrequencyEditor'
import MaterialIcon from '../../components/MaterialIcon'

const TemplateFrequencyEditor = ({ data, templateId, id }) => {
  const {
    pageTitle,
    singularTitle,
    register,
    handleSubmit,
    onSubmit,
    title,
    templateStructure,
    checkFrequencyTypeOptions,
    weekDayCheckOptions,
    checkRuleTypeOptions,
    checkFrequencyType,
    checkFrequencyTimeOptions,
    checkFrequencyStartTime,
    checkFrequencyEndTime,
    checkFrequencyWeekDay,
    checkRuleType,
    checkRuleQuestionId,
    onChangeCheckFrequencyType,
    onChangeCheckFrequencyStartTime,
    onChangeCheckFrequencyEndTime,
    onChangeCheckFrequencyWeekDay,
    onChangeCheckRuleType,
    onChangeCheckRuleQuestionId,
    questionsSelectorRef,
    isLoading,
    templateDetails,
    errors,
    onError
  } = useTemplateFrequencyEditor({
    data,
    templateId,
    id
  })

  const descriptionLabel = useMemo(() => {
    if (
      [
        title,
        checkFrequencyStartTime,
        checkFrequencyEndTime,
        checkFrequencyType,
        checkRuleType
      ].every((v) => (isObject(v) ? v?.value : !!v))
    ) {
      const { value: checkFrequency } = checkFrequencyType
      const { value: checkRule } = checkRuleType
      if (checkFrequency === 'hourly') {
        if (checkRule === 'form') {
          return (
            <Text>
              <Box as="strong">{title}</Box> checks on a {checkFrequency} basis
              for all form submissions from {checkFrequencyStartTime}h to{' '}
              {checkFrequencyEndTime}h
            </Text>
          )
        } else if (checkRule === 'question' && !!checkRuleQuestionId) {
          return (
            <Text>
              <Box as="strong">{title}</Box> checks on a {checkFrequency} basis
              for {`question id ${checkRuleQuestionId}`} submissions from{' '}
              {checkFrequencyStartTime}h to {checkFrequencyEndTime}h
            </Text>
          )
        }
      } else if (checkFrequency === 'weekly' && checkFrequencyWeekDay?.value) {
        const { value: weekDay } = checkFrequencyWeekDay
        return (
          <Text>
            <Box as="strong">{title}</Box> checks on a {checkFrequency} basis
            for all form submissions from {checkFrequencyStartTime}h to{' '}
            {checkFrequencyEndTime}h on every {weekDay}
          </Text>
        )
      }
    }
    return null
  }, [
    title,
    checkFrequencyStartTime,
    checkFrequencyEndTime,
    checkFrequencyType,
    checkRuleType,
    checkFrequencyWeekDay,
    checkRuleQuestionId
  ])

  return (
    <Container maxW="1200px">
      <Stack w="100%" as="section">
        <PageHeader
          title={`New Form Schedule`}
          Breadcrumb={
            <TemplatePageBreadcrumb
              pageTitle={pageTitle}
              singularTitle={singularTitle}
              templateId={templateId}
              templateDetails={templateDetails}
              routeName={[
                {
                  title: 'Form Schedule',
                  url: getRouteUrl('templateFrequencies', { id: templateId })
                },
                {
                  title: `New`
                }
              ]}
            />
          }
          Actions={
            <>
              <Link to={getRouteUrl('templateFrequencies', { id: templateId })}>
                <Button>Cancel</Button>
              </Link>
            </>
          }
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={4} w="100%">
            <Flex flexDir={['column', 'row']} gap={4} w="100%">
              <Flex w="100%">
                <EditInline
                  placeholder="Title"
                  registerProps={{ ...register('title', { required: true }) }}
                  defaultValue={title}
                  startWithEditView={!title}
                  autoFocus={!title}
                  fontSize="md"
                  justify
                  width="100%"
                  isInvalid={!!errors?.title?.message}
                />
              </Flex>
              <FormControl
                flex={[1, 0.1]}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                gap={4}
              >
                <FormLabel mb={0} htmlFor="active" cursor="pointer">
                  Active
                </FormLabel>
                <Switch
                  id="active"
                  {...register('active', { required: true })}
                  mb={0}
                  isInvalid={!!errors?.active?.message}
                />
              </FormControl>
            </Flex>
            <Divider />
            <Flex w="100%" gap={4} flexDir={['column', 'row']}>
              <FormControl {...selectWrapperStyles}>
                <FormLabel>Select the frequency</FormLabel>
                <Select
                  options={checkFrequencyTypeOptions}
                  onChange={onChangeCheckFrequencyType}
                  defaultValue={checkFrequencyType}
                  placeholder="Select the frequency"
                  isInvalid={!!errors?.check_frequency?.type?.value?.message}
                />
              </FormControl>
              {checkFrequencyType?.value === 'weekly' && (
                <FormControl {...selectWrapperStyles}>
                  <FormLabel>Select the week day</FormLabel>
                  <Select
                    options={weekDayCheckOptions}
                    onChange={onChangeCheckFrequencyWeekDay}
                    defaultValue={checkFrequencyWeekDay}
                    placeholder="Select the week day"
                    isInvalid={
                      !!errors?.check_frequency?.week_day?.value?.message
                    }
                  />
                </FormControl>
              )}
              <Flex
                gap={4}
                justify={['space-between', 'flex-end']}
                flexDir={['column', 'row']}
                mt="auto"
                w="100%"
              >
                <ToggleValueModal
                  options={checkFrequencyTimeOptions}
                  value={checkFrequencyStartTime}
                  onChange={onChangeCheckFrequencyStartTime}
                  title="Select the start time"
                  closeOnSelect
                  colorScheme="blue"
                >
                  <Button
                    leftIcon={getDayPeriodByTime(checkFrequencyStartTime)}
                    variant={checkFrequencyStartTime ? 'solid' : 'outline'}
                    w="100%"
                    colorScheme={
                      !checkFrequencyStartTime &&
                      errors?.check_frequency?.start_time?.message
                        ? 'red'
                        : 'blue'
                    }
                  >
                    Start Time{' '}
                    {`${
                      checkFrequencyStartTime
                        ? `: ${checkFrequencyStartTime}h`
                        : ''
                    }`}
                  </Button>
                </ToggleValueModal>
                <ToggleValueModal
                  options={checkFrequencyTimeOptions}
                  value={checkFrequencyEndTime}
                  onChange={onChangeCheckFrequencyEndTime}
                  title="Select the end time"
                  closeOnSelect
                  colorScheme="blue"
                >
                  <Button
                    leftIcon={getDayPeriodByTime(checkFrequencyEndTime)}
                    variant={checkFrequencyEndTime ? 'solid' : 'outline'}
                    w="100%"
                    colorScheme={
                      !checkFrequencyEndTime &&
                      errors?.check_frequency?.start_time?.message
                        ? 'red'
                        : 'blue'
                    }
                  >
                    End Time{' '}
                    {`${
                      checkFrequencyEndTime ? `: ${checkFrequencyEndTime}h` : ''
                    }`}
                  </Button>
                </ToggleValueModal>
              </Flex>
            </Flex>
            <Divider />
            <Flex flexDir={['column', 'row']} w="100%" gap={4}>
              <FormControl {...selectWrapperStyles}>
                <FormLabel>Select the checking rule</FormLabel>
                <Select
                  options={checkRuleTypeOptions}
                  onChange={onChangeCheckRuleType}
                  defaultValue={checkRuleType}
                  placeholder="Select the checking rule"
                  isInvalid={!!errors?.check_rule?.type?.value?.message}
                />
              </FormControl>
              {checkRuleType?.value === 'question' && (
                <Box mt="auto">
                  <QuestionsSelector
                    ref={questionsSelectorRef}
                    childrenStructure={templateStructure}
                    displayLabels
                    initialSelectedNodes={checkRuleQuestionId}
                    allowSelection={(node) => node?.type === 'question'}
                    selectionMode="single"
                    label="Select the question to be checked"
                    size="full"
                    onConfirm={onChangeCheckRuleQuestionId}
                    modalContentStyles={{ h: '100vh!important', maxH: '100vh' }}
                  >
                    <Button
                      leftIcon={<MaterialIcon icon='account_tree' />}
                      colorScheme={
                        !checkRuleQuestionId &&
                        errors?.check_rule?.question_id?.message
                          ? 'red'
                          : 'blue'
                      }
                    >
                      Select the question{' '}
                      {checkRuleQuestionId ? `ID: ${checkRuleQuestionId}` : ''}
                    </Button>
                  </QuestionsSelector>
                </Box>
              )}
            </Flex>
            {descriptionLabel}
            <Flex w="100%" justify="flex-end" gap={4} align="center">
              <Link to={getRouteUrl('templateFrequencies', { id: templateId })}>
                <Button>Cancel</Button>
              </Link>
              <Button
                onClick={onError}
                isLoading={isLoading}
                colorScheme="blue"
                type="submit"
              >
                Submit
              </Button>
            </Flex>
          </Stack>
        </form>
      </Stack>
    </Container>
  )
}

export default TemplateFrequencyEditor
