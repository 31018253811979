import { getRouteUrl } from 'helpers'
import { get, isEmpty, pick, trim } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  usePageProperties,
  useQuery,
  useTableColumns,
  useTableFilter
} from '../../../hooks'
import OlingaAPI from '../../../services/OlingaAPI'
import EditFolderButton from '../EditFolderButton'
import GroupsModal from '../GroupsModal'
import TemplatesModal from '../TemplatesModal'

const hiddenColumnsKey = 'form_answers_persisted_columns.form_folders'

const useFormFolders = () => {
  const sortType = useCallback((rowA, rowB, columnId) => {
    const getAttribute = (object) => {
      return get(object, `original.${columnId}.props.count`)
    }
    if (getAttribute(rowA) > getAttribute(rowB)) return 1
    if (getAttribute(rowA) < getAttribute(rowB)) return -1
    return 0
  }, [])

  const history = useHistory()

  const { pageTitle, shouldCreate: hasPermissionToModifyFolder } =
    usePageProperties({ pageName: 'folders' })

  const {
    shouldCreate: hasPermissionsToModifyTemplate,
    pageTitle: templatesPageTitle
  } = usePageProperties({
    pageName: 'templates'
  })

  const {
    shouldCreate: hasPermissionsToModifyGroup,
    pageTitle: groupsPageTitle
  } = usePageProperties({
    pageName: 'groups'
  })

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: templatesPageTitle,
        accessor: 'templates',
        sortType
      },
      {
        Header: groupsPageTitle,
        accessor: 'groups',
        sortType
      }
    ],
    hiddenColumnsKey,
    hasPermissionToModifyFolder,
    EditFolderButton,
    ({ original: { id } }) => ({ id })
  )

  const query = useQuery()

  const initialParams = useMemo(() => {
    return isEmpty(query)
      ? {
          page: 1,
          per_page: 15,
          profile: true
        }
      : pick({ ...query, profile: true }, [
          'page',
          'per_page',
          'profile',
          'email'
        ])
  }, [query])

  const fieldsExtractor = useCallback(
    (data) => {
      return data.map(
        ({ attributes: { name, totalTemplates: templates, groups, id } }) => {
          return {
            name,
            templates: (
              <TemplatesModal
                id={id}
                count={templates}
                folderName={name}
                hasPermission={hasPermissionsToModifyTemplate}
              />
            ),
            groups: (
              <GroupsModal
                hasPermission={hasPermissionsToModifyGroup}
                id={id}
                count={groups?.length || 0}
                folderName={name}
                groups={groups}
              />
            ),
            id
          }
        }
      )
    },
    [hasPermissionsToModifyGroup, hasPermissionsToModifyTemplate]
  )

  const {
    refetch,
    isLoading,
    updateParams,
    fetchParams,
    tableData,
    pagination
  } = useTableFilter({
    id: null,
    fetchCallback: OlingaAPI.formFolderList,
    fieldsExtractor,
    initialParams,
    onRefetch: useCallback(
      (newParams) => {
        history.replace(getRouteUrl('folders', newParams))
      },
      [history]
    )
  })

  const onTextChange = useCallback(
    (updatedText) => {
      if (!updatedText || !trim(updatedText)?.length) {
        return refetch({ per_page: 15, page: 1 })
      }
      updateParams({ name: updatedText }, updatedText?.length >= 3)
    },
    [refetch, updateParams]
  )

  return {
    hasPermissionToModifyFolder,
    hasPermissionsToModifyTemplate,
    hasPermissionsToModifyGroup,
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data: tableData,
    isLoading,
    refetch,
    updateParams,
    fetchParams,
    pagination,
    onTextChange,
    pageTitle
  }
}

export default useFormFolders
