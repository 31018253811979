import { AddIcon } from '@chakra-ui/icons'
import { Box, Flex, IconButton } from '@chakra-ui/react'
import MaterialIcon from 'components/MaterialIcon'
import { iconsTransformer } from 'pages/AssetTemplates/AssetTemplatesEditor/hooks/useAssetTemplateEditor'
import React from 'react'

const TypeFieldItem = ({
  index,
  label,
  type,
  isDisabled,
  onOpenFieldTypeForm
}) => {
  return (
    <Flex
      p={4}
      borderRadius="md"
      shadow="sm"
      key={index}
      justify="space-between"
      w="100%"
      gap={4}
      _hover={{
        shadow: 'md'
      }}
      borderWidth={1}
      borderColor="gray.200"
    >
      <Flex fontSize="md" align="center" gap={2}>
        {label}
        <MaterialIcon icon={iconsTransformer[type]} />
      </Flex>
      <IconButton
        icon={<AddIcon />}
        colorScheme="blue"
        size="sm"
        isRound
        aria-label={`Add field ${type}`}
        onClick={() => onOpenFieldTypeForm(type)}
        isDisabled={isDisabled}
      />
    </Flex>
  )
}

export default TypeFieldItem
