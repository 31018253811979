import { AddIcon, Search2Icon } from '@chakra-ui/icons'
import { Button, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import Autocomplete from 'components/Autocomplete'
import React from 'react'
import { Link } from 'react-router-dom'
import FoldersTree from '../../components/FoldersTree'
import { getRouteUrl } from '../../helpers'
import OlingaAPI from '../../services/OlingaAPI'
import ChecklistKebab from './ChecklistKebab'
import ChecklistListItem from './ChecklistListItem'
import useChecklists from './useChecklists'

const ChecklistsList = () => {
  const {
    checklistListRef,
    hasPermissionToCreateChecklists,
    pageTitle,
    singularTitle,
    getFolderListCallbackParams,
    getAutocompleteCallback,
    onAutocompleteOpen,
    onClose,
    isOpen,
    handleAutocompleteSelect,
  } = useChecklists()

  return (
    <FoldersTree
      resourceFetchCallback={OlingaAPI.getChecklistsFromFolders}
      folderListCallback={OlingaAPI.checklistsFolders}
      resourceName="checklists"
      pageName={pageTitle}
      getResources={(checklists) => (
        <ChecklistListItem checklists={checklists} pageTitle={singularTitle} />
      )}
      ref={checklistListRef}
      title={pageTitle}
      expandedResourceKey="expanded_checklists_folders"
      getFolderListCallbackParams={getFolderListCallbackParams}
      Autocomplete={
        <Popover isOpen={isOpen} onOpen={onAutocompleteOpen} onClose={onClose}>
          <PopoverTrigger>
            <Button
              leftIcon={<Search2Icon />}
              variant="ghost"
              colorScheme="orange"
              size="sm"
              aria-label={`Search ${pageTitle}`}
            >
              Search {pageTitle}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody p={4}>
              <Autocomplete
                asyncCallback={getAutocompleteCallback}
                placeholder={`Search ${pageTitle}`}
                attributeName="name"
                resultEndAdornment={(checklist) => (
                  <ChecklistKebab
                    hasPermissionToModifyChecklist={checklist?.userPermissions.update}
                    hasPermissionToShowChecklist={checklist?.userPermissions.user_tasks}
                    id={checklist?.id}
                    pageTitle={pageTitle}
                    size="xs"
                  />
                )}
                onSelect={(result) => handleAutocompleteSelect(result.id)}
                minWidth="100%"
                groupByAttribute="formFolderName"
                getNestedAttribute="attributes"
                hasFixedResults
                shouldAutofocus
                inputId="checklist-autocomplete-input"
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      }
      ActionsAdornment={
        hasPermissionToCreateChecklists ? (
          <Link to={getRouteUrl('createChecklist')}>
            <Button leftIcon={<AddIcon />} colorScheme="blue">
              New
            </Button>
          </Link>
        ) : null
      }
    />
  )
}

export default ChecklistsList
