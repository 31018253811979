/* eslint-disable react-hooks/exhaustive-deps */
import { useMediaQuery } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import useLocalStorage from '../useLocalStorage'

const useTableColumns = (
  allColumns = [],
  hiddenColumnsKey,
  hasPermission = true,
  Actions = null,
  customMapper = (row) => ({ id: row.id }),
  defaultHiddenColumns = ['id']
) => {
  const [isMobile] = useMediaQuery('(max-width: 799px)')
  const { data: persistedHiddenColumns } = useLocalStorage(hiddenColumnsKey)
  return useMemo(() => {
    let columnsToReturn = [...allColumns]
    if (
      Array.isArray(persistedHiddenColumns) &&
      persistedHiddenColumns?.length
    ) {
      columnsToReturn.forEach((column) => {
        if (persistedHiddenColumns.includes(column.accessor)) {
          column.isHidden = true
        }
      })
    }
    if (hasPermission && Actions) {
      const ActionsColumn = {
        Header: 'Actions',
        id: 'expander',
        Cell: ({ row }) => {
          const props = customMapper(row)
          return <Actions {...props} />
        }
      }
      if (isMobile) {
        columnsToReturn.unshift(ActionsColumn)
      } else {
        columnsToReturn.push(ActionsColumn)
      }
    }
    return columnsToReturn.reduce(
      (acc, column) => {
        if (column?.isHidden) {
          acc['hiddenColumns'] = [...acc['hiddenColumns'], column.accessor]
        }
        acc['columns'] = acc['columns'] ? [...acc['columns'], column] : [column]
        return acc
      },
      { hiddenColumns: [...defaultHiddenColumns], columns: [] }
    )
  }, [
    Actions,
    allColumns,
    customMapper,
    hasPermission,
    isMobile,
    persistedHiddenColumns,
    defaultHiddenColumns
  ])
}

export default useTableColumns
