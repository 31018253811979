import React from 'react'
import { useRecursiveStructureContext } from '../../../contexts/RecursiveStructureSelection'
import Condition from '../Condition'
import Instruction from '../Instruction'
import Logic from '../Logic'
import Question from '../Question'
import Section from '../Section'

const Child = (props, ref) => {
  const { answers, answerLabel } = useRecursiveStructureContext()
  const { type, children, question_type: questionType, id } = props
  if (type === 'question') {
    const answer = answers[id] ?? null
    if (children.length) {
      return (
        <Logic
          {...props}
          answer={answer}
          answerLabel={answerLabel}
          childrenIndexes={children.map((_, index) => index)}
        />
      )
    }
    if (questionType === 'instruction') {
      return <Instruction {...props} />
    }
    return (
      <Question
        {...props}
        answer={answer}
        answerLabel={answerLabel}
        ref={ref}
      />
    )
  } else if (type === 'section') {
    return (
      <Section {...props} childrenIndexes={children.map((_, index) => index)} />
    )
  } else if (type === 'condition') {
    return (
      <Condition
        {...props}
        childrenIndexes={children.map((_, index) => index)}
      />
    )
  }
  return null
}

export default Child
