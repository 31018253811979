/* eslint-disable no-param-reassign */
import CryptoES from 'crypto-es'
import {
  capitalize,
  clone,
  debounce,
  first,
  get,
  groupBy,
  isArray,
  isDate,
  isEmpty,
  isFunction,
  isString,
  last,
  map,
  reverse,
  values
} from 'lodash'
import moment from 'moment'
import pluralize from 'pluralize'
import { toast } from 'react-toastify'
import routes from '../const/routes'
import Notification from '../services/Notification'
import OlingaAPI from '../services/OlingaAPI'

export const getRouteUrl = (id, params = {}) => {
  const includeParams = Object.keys(params).length > 0
  if (includeParams) {
    return routes[id](params)
  }
  return routes[id]()
}

export const isItemSelected = (location, url, groupUrl) => {
  if (!url && isEmpty(groupUrl)) {
    return
  }

  if (!isEmpty(groupUrl)) {
    return groupUrl.some((url) => location.match(url))
  }

  const splittedUrl = url.split('/')
  if (splittedUrl.length > 1) {
    const exceptions = groupUrl || ['documents', 'templates']

    if (exceptions.some((exception) => location.match(exception))) {
      const exception = exceptions.find((route) => url.match(route))
      return location.includes(exception)
    }
  }
  return location.includes(url)
}

export const pxToCmConvert = (value) => Math.ceil(value * 0.0264)

export const cmToPxConvert = (value) => Math.floor(value / 0.0264)

export const generateQRCodeHash = (data) => {
  let formattedData = data
  if (!isString(data)) {
    formattedData = JSON.stringify(data)
  }
  const key =
    'a3139ea97e786c6ce4a6f0aa9a61dd712e5e97b27e368047c75dd3a1be764bce3f320016f82148d3203ec169ed08c6503fa5da6bc2656e3f0a9e99ed153c2b31'
  return CryptoES.AES.encrypt(formattedData, key).toString()
}

export const exploreTemplate = (
  attributes,
  searchableKey,
  nestedKey,
  filterCallback
) => {
  const allFields = []

  const recursiveExploration = (obj) => {
    allFields.push(obj[searchableKey])
    if (!obj[searchableKey]) {
      return
    }

    obj[nestedKey]?.forEach((child) => recursiveExploration(child))
  }
  recursiveExploration(attributes)

  const flattened = allFields.flatMap((field) => field)
  return isFunction(filterCallback)
    ? flattened.filter(filterCallback)
    : flattened
}

export const shouldDisplayQuickSubmitQRCodeOption = (
  attributes,
  hasChildrenKeys = false,
  isQuestionCallback
) => {
  const searchableKey = hasChildrenKeys
    ? 'children'
    : 'template_questions_attributes'
  const nestedKey = hasChildrenKeys
    ? 'children'
    : 'section_questions_attributes'

  const children = exploreTemplate(
    attributes,
    searchableKey,
    nestedKey,
    isQuestionCallback
  )

  return children.length > 0
}

export const applyCallbackOnTemplate = (
  attributes,
  callback,
  depthKeys,
  objectNestedKeys
) => {
  const attributesClone = attributes
  const recursiveApplyingCallback = (obj, searchableKey) => {
    if (!obj[searchableKey]) {
      return
    }
    if (Array.isArray(obj[searchableKey])) {
      obj[searchableKey].forEach((nestedObj) => {
        nestedObj = callback(nestedObj)
        objectNestedKeys.forEach((key) => {
          if (Object.keys(nestedObj).includes(key)) {
            nestedObj[key] = callback(nestedObj[key])
            objectNestedKeys.forEach((objectInternalKey) => {
              if (nestedObj[key][objectInternalKey])
                nestedObj[key][objectInternalKey] = callback(
                  nestedObj[key][objectInternalKey]
                )
            })
            depthKeys.forEach((nestedKey) => {
              recursiveApplyingCallback(nestedObj[key], nestedKey)
            })
          }
        })
        depthKeys.forEach((nestedKey) => {
          recursiveApplyingCallback(nestedObj, nestedKey)
        })
      })
    } else if (
      objectNestedKeys.some((key) => key === searchableKey) &&
      obj[searchableKey]
    ) {
      obj[searchableKey] = callback[obj[searchableKey]]
    }
  }
  depthKeys.forEach((key) => {
    recursiveApplyingCallback(attributesClone, key)
  })
  return attributesClone
}

export const getDashboardDatesLabel = (data, filter) => {
  if (isEmpty(data)) {
    return ''
  }

  const firstDate = first(data)
  const lastDate = last(data)

  if (isArray(filter) && filter?.length === 2) {
    const [customFirstDate, customLastDate] = filter
    if (filter.every((date) => moment(date).isValid())) {
      return `${moment(customFirstDate).format('DD/MM')} - ${moment(
        customLastDate
      ).format('DD/MM')}`
    }
  }

  if (filter !== 'seven_days') {
    return firstDate
  }

  return `${firstDate} - ${lastDate}`
}

const getDateRange = (params) => {
  if (!params?.end_date && !params?.start_date) {
    return ''
  }
  const separator = [params?.start_date, params?.end_date].every((v) => !!v)
    ? '_'
    : ''
  return `_${
    isDate(params.start_date)
      ? moment(params.start_date).format('YYYYMMDD')
      : ''
  }${separator}${
    isDate(params.end_date) ? moment(params.end_date).format('YYYYMMDD') : ''
  }`
}

export const downloadDocument = (blob, fileName) => {
  const aElement = document.createElement('a')
  aElement.href = window.URL.createObjectURL(new Blob([blob]))
  aElement.download = fileName
  document.body.appendChild(aElement)
  aElement.click()
  document.body.removeChild(aElement)
}

export const handleExportForms = async (
  templateId,
  templateName,
  type,
  params,
  setLoadingPage = () => ({})
) => {
  try {
    const response = await OlingaAPI.formExport(templateId, params, type)
    if (response.status === 200) {
      const { success, message } = response?.data || {}
      if (success) {
        toast.success(message)
      }
      setLoadingPage(false)
    }
  } catch (error) {
    //
  } finally {
    setLoadingPage(false)
  }
}

export const handleExportChecklists = async (
  id,
  name,
  format,
  params,
  setIsLoading = () => ({})
) => {
  try {
    const response = await OlingaAPI.exportChecklist(id, params, format)
    if (response.status === 200) {
      const { message } = response?.data || {}
      toast.success(message)
    }
  } catch (error) {
    Notification({
      icon: 'error',
      text: error?.message ?? 'Unable to export file'
    })
  } finally {
    setIsLoading(false)
  }
}

export async function searchTemplateByName(name) {
  return OlingaAPI.searchTemplates({ params: { name } }).then((templateList) =>
    templateList.map((t) => ({
      label: `#${t.id} - ${t.attributes.name}`,
      value: `${t.id} - ${t.attributes.name}`,
      key: t.id,
      'data-folder': t.attributes?.formFolderName || 'Without Folder',
      'data-folder-id': t.attributes?.folderId
    }))
  )
}

export const getDashboardUrlParams = (user) => {
  const dashboardId =
    user.personable_type === 'Assessor' ? 'client_dashboard' : 'dashboard'
  const userType = user.personable_type === 'Assessor' ? '' : 'businesses'
  return { dashboardId, userType }
}

export const getInjectedProps = (id) => {
  const element = document.querySelector(`[data-id="${id}"]`)
  const json = element?.textContent || null

  if (json) {
    element.remove()
    return JSON.parse(json)
  }

  return null
}

export const handleTemplateClone = async (id, setIsLoading) => {
  setIsLoading(true)
  try {
    const data = await OlingaAPI.cloneTemplate(id)
    if (data.id) {
      Notification({
        icon: 'success',
        text: 'Template cloned successfully !'
      })
      debounce(() => {
        window.location.replace(
          getRouteUrl('templateSettings', { id: data.id })
        )
      }, 1500)()
    }
  } catch (error) {
    Notification({
      icon: 'error',
      text: Object.keys(error).includes('response')
        ? error.response.data
        : error
    })
  } finally {
    setIsLoading(false)
  }
}

export const chakraComponentsSizes = ['xs', 'sm', 'md', 'lg', 'xl']
export const getExtension = (fileName) => {
  return last(fileName.split('.'))
}

export const getExtensionColor = (extension) => {
  switch (extension.toLowerCase()) {
    case 'pdf':
      return '#E53E3E'
    case 'xlsx':
    case 'xls':
    case 'csv':
      return '#38A169'
    default:
      return '#3182CE'
  }
}

export const getExtensionIcon = (extension) => {
  switch (extension.toLowerCase()) {
    case 'pdf':
      return 'picture_as_pdf'
    case 'xlsx':
    case 'xls':
    case 'csv':
      return 'insert_drive_file'
    default:
      return ''
  }
}

export const getConjunctionSeparatedString = (list) => {
  const formatter = new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction'
  })
  return formatter.format(list)
}

export const emptyRolesByCategory = {
  hasTemplateSelected: false,
  hasAssetTemplateSelected: false,
  hasAssetSelected: false,
  hasFolderSelected: false,
  hasFormSelected: false,
  hasGroupSelected: false,
  hasAlertSelected: false,
  hasDocumentSelected: false,
  hasDashboardSelected: false,
  hasActionSelected: false,
  hasScheduledReportSelected: false,
  hasSummaryReportSelected: false,
  hasChecklistSelected: false,
  hasOnboardStaffSelected: false
}

export const roleIncludesKey = (role, key) => role?.name?.includes(key)

export const getHasRolesByCategory = (rolesArray, discartAll = false) => {
  const obj = clone(emptyRolesByCategory)

  if (!isEmpty(rolesArray)) {
    rolesArray.forEach((r) => {
      if (roleIncludesKey(r, 'asset_template')) {
        obj.hasAssetTemplateSelected =
          discartAll && roleIncludesKey(r, 'all') ? false : true
      } else if (roleIncludesKey(r, 'asset')) {
        obj.hasAssetSelected = true
      } else if (roleIncludesKey(r, 'template')) {
        obj.hasTemplateSelected =
          discartAll && roleIncludesKey(r, 'all') ? false : true
      } else if (roleIncludesKey(r, 'folder')) {
        obj.hasFolderSelected = true
      } else if (roleIncludesKey(r, 'form')) {
        obj.hasFormSelected =
          discartAll && roleIncludesKey(r, 'all') ? false : true
      } else if (roleIncludesKey(r, 'group')) {
        obj.hasGroupSelected = true
      } else if (roleIncludesKey(r, 'alert')) {
        obj.hasAlertSelected = true
      } else if (roleIncludesKey(r, 'document')) {
        obj.hasDocumentSelected =
          discartAll && roleIncludesKey(r, 'all') ? false : true
      } else if (roleIncludesKey(r, 'dashboard')) {
        obj.hasDashboardSelected =
          discartAll && roleIncludesKey(r, 'all') ? false : true
      } else if (roleIncludesKey(r, 'action')) {
        obj.hasActionSelected = true
      } else if (roleIncludesKey(r, 'report_summary')) {
        obj.hasSummaryReportSelected = true
      } else if (['report_modify', 'report_view'].includes(r?.name)) {
        obj.hasScheduledReportSelected = true
      } else if (roleIncludesKey(r, 'checklist')) {
        obj.hasChecklistSelected =
          discartAll && roleIncludesKey(r, 'all') ? false : true
      } else if (roleIncludesKey(r, 'scheduled')) {
        obj.hasScheduledFormsSelected = true
      } else if (roleIncludesKey(r, 'onboard')) {
        obj.hasOnboardStaffSelected = true
      }
    })
  }

  return obj
}

export const getUserRolesObj = (roles = []) => {
  const result = {}

  if (Array.isArray(roles)) {
    roles.map((role) => {
      result[role] = true
    })
  }

  return result || roles
}

export const getNumberFromText = (text) => {
  return text.replace(/[^0-9]/g, '')
}

export const getPrefixes = (count, substantive) => {
  let prefixes = {}
  if (count === 1) {
    prefixes.before = 'the'
    prefixes.after = substantive
  } else {
    prefixes.before = 'all'
    prefixes.after = substantive === 'person' ? 'users' : `${substantive}s`
  }
  return prefixes
}

export const rgb2hex = (rgb) =>
  `#${rgb
    .match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
    .slice(1)
    .map((n) => parseInt(n, 10).toString(16).padStart(2, '0'))
    .join('')}`

export const setQuillValue = (
  value,
  setValue,
  attributeName = 'description'
) => {
  const regex = new RegExp('<[^>]*>', 'g')
  const isEmptyValue = !(value.trim && value.trim().replace(regex, ''))
  let newValue = isEmptyValue ? '' : value

  // eslint-disable-next-line no-useless-escape
  newValue = newValue.replace(/rgb\([^\)]+\)/g, (rgb) => rgb2hex(rgb))

  setValue(attributeName, newValue)
}

export const normalizeList = (obj, groupAttribute = null) =>
  map(obj, (item) => {
    const id = item?.attributes?.id || item?.id
    const name = item?.attributes?.name || item?.name
    const normalizedObject = {
      label: item?.attributes?.label || item?.label || name,
      name,
      value: id,
      id,
      ...obj
    }

    if (groupAttribute) {
      return { ...normalizedObject, group: get(item, groupAttribute) }
    }

    return {
      label: item?.attributes?.label || item?.label || name,
      name,
      value: id,
      id,
      ...obj
    }
  })

export const getValidDate = (dateTime = '') => {
  const splittedDate = first(dateTime.split(' ')).split('/')
  const [year] = splittedDate.splice(-1)
  return [year, ...reverse(splittedDate)].join('-')
}

export const setFormErrors = (
  errors = {},
  setErrors,
  Notification = undefined,
  notificationProps = {}
) => {
  const validErrors = Object.entries(errors)
    .filter(([k]) => k !== 'base')
    .reduce((acc, [k, v]) => {
      if (isArray(v)) {
        v.forEach((message) => {
          acc[k] = { type: 'manual', message }
        })
      }
      return acc
    }, {})
  if (isFunction(setErrors)) {
    Object.entries(validErrors).forEach(([key, error]) => {
      setErrors(key, error)
      let message = ''
      if (['must exist', `can't be blank`].includes(error?.message)) {
        message = `${capitalize(key)} ${error.message}`
      } else if (isString(error?.message)) {
        message = error.message
      }
      if (Notification) {
        Notification({
          icon: 'error',
          text: message,
          ...notificationProps
        })
      }
    })
  }

  return validErrors
}

export const singularize = (word) =>
  isString(word) ? pluralize.singular(word) : ''

function padZero(str, len) {
  len = len || 2
  var zeros = new Array(len).join('0')
  return (zeros + str).slice(-len)
}

export function invertColor(hex, bw) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1)
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.')
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16)
  if (bw) {
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF'
  }
  r = (255 - r).toString(16)
  g = (255 - g).toString(16)
  b = (255 - b).toString(16)
  return '#' + padZero(r) + padZero(g) + padZero(b)
}

export const normalizedRoles = (roles) => {
  let groupedRoles = groupBy(roles, 'name')
  Object.keys(groupedRoles).forEach((key) => {
    const resources = map(groupedRoles[key], (role) => role.resource)
    const ids = map(resources, (resource) => resource.id)
    const names = map(resources, (resource) => resource.label)

    groupedRoles[key] = {
      ...first(groupedRoles[key]),
      resources: {
        ids,
        names
      }
    }
  })

  return Object.values(groupedRoles)
}

export const groupNormalizedList = (list) =>
  values(
    list.reduce((acc, obj) => {
      const groupByAttribute = get(obj, 'group')
      if (!isEmpty(groupByAttribute)) {
        if (!acc[groupByAttribute]) {
          acc[groupByAttribute] = { label: groupByAttribute, options: [] }
        }
        acc[groupByAttribute].options.push(obj)
      } else {
        if (!acc['Without group']) {
          acc['Without group'] = { label: 'Without group', options: [] }
        }
        acc['Without group'].options.push(obj)
      }
      return acc
    }, {})
  )
