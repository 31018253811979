import { Flex } from '@chakra-ui/react'
import React from 'react'
import PageHeader from '../../../components/PageHeader'
import ManageStaffBreadcrumb from './Breadcrumb'

const ManageStaffPageHeader = ({ Actions, title }) => {
  return (
    <PageHeader
      Actions={
        <Flex gap={4} align="center">
          {Actions}
        </Flex>
      }
      Breadcrumb={<ManageStaffBreadcrumb title={title} />}
      title={title}
    />
  )
}

ManageStaffPageHeader.defaultProps = {
  Actions: null
}

export default ManageStaffPageHeader
