import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => {
    const query = new URLSearchParams(search)
    return Object.fromEntries(query.entries())
  }, [search])
}

export default useQuery
