import { getNumberFromText } from 'helpers'
import React from 'react'
import OlingaAPI from '../../../../services/OlingaAPI'
import CalculatedFieldsSkeleton from '../components/CalculatedFields/Skeleton'
import MaterialIcon from 'components/MaterialIcon'

const tabs = [
  {
    id: 'edit_form',
    label: 'Edit template',
    icon: <MaterialIcon icon='edit' />,
    fetchCallback: {
      template: {
        callback: () =>
          OlingaAPI.templateShow(getNumberFromText(window.location.pathname)),
        params: {}
      }
    },
    cachedKeys: [],
    resourceName: 'templateData',
    params: {}
  },
  {
    id: 'calculated_fields',
    label: 'Calculated Fields ',
    icon: <MaterialIcon icon='calculate' />,
    fetchCallback: {
      template: {
        callback: OlingaAPI.templateStructure
      },
      questions: {
        callback: OlingaAPI.templateQuestions,
        params: 'calculated'
      }
    },
    cachedKeys: ['operations'],
    ignoreCache: true,
    resourceName: 'data',
    params: {},
    Skeleton: <CalculatedFieldsSkeleton />
  }
]

export default tabs
