import actions from '../../actions'

const { SET_CACHED_DEPENDENCIES } = actions.dependencies

const initialState = {
  dependencies: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CACHED_DEPENDENCIES: {
      state = {
        ...state,
        dependencies: { ...state.dependencies, ...action.payload }
      }
      return state
    }
    default:
      return state
  }
}

export default reducer
