import { Tag } from '@chakra-ui/react'
import { getRouteUrl } from 'helpers'
import { capitalize, isEmpty, map, pick, trim, uniq, upperCase } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  usePageProperties,
  useQuery,
  useTableColumns,
  useTableFilter
} from '../../../hooks'
import OlingaAPI from '../../../services/OlingaAPI'
import ManageUsersActions from '../ManageUsersActions'

const hiddenColumnsKey = 'form_answers_persisted_columns.manage_users'

const useManageUsers = () => {
  const { pageTitle, shouldCreate: hasPermissionToCreateUsers } =
    usePageProperties({ pageName: 'manage_users' })
  const { pageTitle: templatesPageTitle } = usePageProperties({
    pageName: 'templates'
  })
  const { singularTitle: profilePageTitle } = usePageProperties({
    pageName: 'profiles'
  })
  const query = useQuery()
  const history = useHistory()

  const initialParams = useMemo(() => {
    return isEmpty(query)
      ? {
          page: 1,
          per_page: 15,
          profile: true
        }
      : pick({ ...query, profile: true }, [
          'page',
          'per_page',
          'profile',
          'email'
        ])
  }, [query])

  const fieldsExtractor = useCallback((data) => {
    if (!isEmpty(data)) {
      return data.map(
        ({ attributes: { email, id, profile, roles, active } }) => ({
          id,
          email,
          profile: profile?.name,
          roles: map(uniq(roles), (role) => capitalize(upperCase(role))).join(
            ', '
          ),
          active: (
            <Tag colorScheme={active ? 'green' : 'gray'} size="md">
              {active ? 'Active' : 'Inactive'}
            </Tag>
          )
        })
      )
    }

    return []
  }, [])

  const {
    refetch,
    isLoading,
    updateParams,
    fetchParams,
    tableData,
    pagination
  } = useTableFilter({
    id: null,
    fetchCallback: OlingaAPI.businessSubUsersList,
    fieldsExtractor,
    initialParams,
    onRefetch: useCallback(
      (newParams) => {
        history.replace(getRouteUrl('manageUsers', newParams))
      },
      [history]
    )
  })

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Roles',
        accessor: 'roles'
      },
      {
        Header: profilePageTitle,
        accessor: 'profile'
      },
      {
        Header: 'Active',
        accessor: 'active'
      }
    ],
    hiddenColumnsKey,
    true,
    ManageUsersActions,
    (row) => {
      const {
        values: { id, active }
      } = row
      const isActive = active.props.children === 'Active'
      return {
        id,
        isActive,
        fetchUsers: refetch
      }
    }
  )

  const onTextChange = useCallback(
    (updatedText) => {
      if (!updatedText || !trim(updatedText)?.length) {
        return refetch({ per_page: 15, page: 1, profile: true })
      }
      updateParams(
        { email: updatedText, profile: true },
        updatedText?.length >= 3
      )
    },
    [refetch, updateParams]
  )
  return {
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data: tableData,
    isLoading,
    refetch,
    updateParams,
    fetchParams,
    pagination,
    onTextChange,
    pageTitle,
    templatesPageTitle,
    profilePageTitle,
    hasPermissionToCreateUsers
  }
}

export default useManageUsers
