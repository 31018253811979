import { EditIcon } from '@chakra-ui/icons'
import { IconButton, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import DeleteButton from '../../../components/DeleteButton'
import { getRouteUrl } from '../../../helpers'

const ProfilesActions = ({ id, onConfirmDelete }) => (
  <>
    <Tooltip label="Edit">
      <Link
        to={{ pathname: getRouteUrl('editProfile', { id }), from: 'profiles' }}
      >
        <IconButton
          icon={<EditIcon />}
          colorScheme="blue"
          size="sm"
          variant="outline"
          mr="4"
          aria-label={`Edit profile ${id}`}
        />
      </Link>
    </Tooltip>
    <DeleteButton
      index={id}
      onDelete={onConfirmDelete}
      variant="outline"
      size="sm"
      borderRadius={4}
    />
  </>
)

export default ProfilesActions
