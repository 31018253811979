import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Stack,
  Switch,
  Text
} from '@chakra-ui/react'
import { Select as MultiSelect } from 'chakra-react-select'
import FormField from 'components/FormField'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as DOMLink, useHistory } from 'react-router-dom'
import Breadcrumb from '../../components/Breadcrumb'
import FormErrorMessage from '../../components/FormErrorMessage'
import UploadDocument from '../../components/Inputs/UploadFile'
import IconPreview from '../../components/Inputs/UploadFile/IconPreview'
import PageHeader from '../../components/PageHeader'
import { getRouteUrl } from '../../helpers'
import useDocumentEditor from './useDocumentEditor'

const DocumentEditor = ({ data }) => {
  const {
    document,
    uploadRef,
    currentAttachment,
    defaultFolders,
    fileToUpload,
    handleChangeDocument,
    handleSubmit,
    handleUploadUndo,
    isSubmitting,
    onChangeFolder,
    onSubmit,
    register,
    pageTitle,
    singularTitle,
    errors
  } = useDocumentEditor({ data })

  const history = useHistory()

  return (
    <Stack>
      <Breadcrumb
        routeTree={[
          {
            title: pageTitle,
            url: getRouteUrl(history?.location?.from || 'documentsLibrary')
          },
          {
            title: document?.name || `New ${singularTitle}`,
            url: null
          }
        ]}
      />
      <PageHeader
        title={
          document?.id ? `Edit ${singularTitle}` : `Create ${singularTitle}`
        }
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction={['column', 'row']} w="100%" align="center" mb={4}>
          <Box w="100%">
            <FormField label="Name" isRequired>
              <Input
                {...register('name', { required: true })}
                placeholder={`${singularTitle} name`}
                isInvalid={!!errors?.name?.message}
                w="100%"
              />
              <FormErrorMessage message={errors?.name?.message} />
            </FormField>
          </Box>

          <FormControl
            my={[4, 0]}
            ml={4}
            display="flex"
            alignItems="center"
            w="max-content"
          >
            <FormLabel cursor="pointer" htmlFor="active-document" mb="0">
              Active
            </FormLabel>
            <Switch
              mb={0}
              id="active-document"
              {...register('active')}
              colorScheme="green"
            />
          </FormControl>
        </Flex>
        <FormLabel>Folders</FormLabel>
        <MultiSelect
          isMulti
          placeholder="Select folders"
          {...register('formFolderIds')}
          options={data.folders}
          defaultValue={defaultFolders}
          onChange={onChangeFolder}
        />
        <Stack gap={4} pt='10px'>
          {currentAttachment && (
            <Stack>
              <Text fontSize="xs">Current file</Text>
              <a
                href={currentAttachment?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconPreview file={currentAttachment} />
              </a>
            </Stack>
          )}
          <Box mt={currentAttachment ? 0 : 4}>
            <UploadDocument
              onChange={handleChangeDocument}
              maxFiles={1}
              acceptedExtensions={['pdf']}
              ref={uploadRef}
            />
          </Box>
          {currentAttachment && fileToUpload && (
            <Flex align="center">
              <Text color="red.400">
                * If you save now, this file will overwrite the current saved
                file above
              </Text>
              <Button size="sm" ml={4} onClick={() => handleUploadUndo()}>
                Undo
              </Button>
            </Flex>
          )}
        </Stack>
        <Flex mt={4} justify="space-between" w={180}>
          <Link
            as={DOMLink}
            to={getRouteUrl(history?.location?.from || 'documentsLibrary')}
          >
            <Button>Cancel</Button>
          </Link>
          <Button
            isLoading={isSubmitting}
            loadingText="Saving"
            type="submit"
            colorScheme="blue"
          >
            Save
          </Button>
        </Flex>
      </form>
    </Stack>
  )
}

DocumentEditor.defaultProps = {
  document: {}
}

DocumentEditor.propTypes = {
  data: PropTypes.shape({
    document: PropTypes.object,
    folders: PropTypes.array
  })
}

export default DocumentEditor
