import { Flex, Heading, Tag, theme, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { getRouteUrl } from '../../../helpers'

const Name = ({ active, name }) => {
  const [isMobile] = useMediaQuery('(max-width: 799px)')
  return (
    <Flex direction={isMobile ? 'column-reverse' : 'row'}>
      <Heading
        color="orange.500"
        size="sm"
        _hover={{ color: theme.colors.orange[700] }}
      >
        {name}
      </Heading>
      <Tag
        ml={isMobile ? 0 : 2}
        mr={isMobile ? 0 : 4}
        mb={isMobile ? 2 : 0}
        minW="auto"
        colorScheme={active ? 'green' : 'gray'}
        size="sm"
        w="max-content"
      >
        {active ? 'Active' : 'Inactive'}
      </Tag>
    </Flex>
  )
}

const TemplateName = ({ name, id, userPermissions, active }) => {
  return (
    <>
      {userPermissions?.fill_form && (
        <Link to={getRouteUrl('fillForm', { id })}>
          <Name active={active} name={name} />
        </Link>
      )}
      {!userPermissions?.fill_form && <Name active={active} name={name} />}
    </>
  )
}

export default TemplateName
