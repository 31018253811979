import { EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Spinner,
  Stack,
  Text,
  theme,
  useDisclosure
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import QuestionsSelector from 'components/QuestionsSelector'
import { isEmpty } from 'lodash'
import React, { useRef } from 'react'
import useTemplatesSelector from './hooks/useTemplatesSelector'

const TemplatesSelector = ({
  association,
  allowSelection,
  onChange,
  onOpen,
  selections,
  menuPlacement
}) => {
  const {
    isLoadingFolders,
    isLoadingTemplates,
    isLoadingTemplateStructure,
    onSelectFolder,
    onSelectTemplate,
    onSelectQuestion,
    onSelectOptions,
    selectedFolder,
    selectedTemplate,
    selectedQuestion,
    selectedQuestionOption,
    folders,
    options,
    templates,
    templateStructure
  } = useTemplatesSelector({
    contextType: 'templates',
    selector: association,
    onChange,
    onOpen,
    selections
  })

  const questionsSelectorRef = useRef()

  const {
    isOpen: isOpenQuestionSelector,
    onClose: onCloseQuestionSelector,
    onOpen: onOpenQuestionSelector
  } = useDisclosure()

  return (
    <Stack gap={4}>
      <FormControl>
        <FormLabel>Folders</FormLabel>
        <Select
          placeholder="Select a folder"
          options={folders}
          isLoading={isLoadingFolders}
          onChange={onSelectFolder}
          value={selectedFolder}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Templates</FormLabel>
        <Select
          placeholder="Select a Template"
          options={templates}
          isLoading={isLoadingTemplates}
          onChange={onSelectTemplate}
          value={selectedTemplate}
          menuPlacement={menuPlacement}
        />
      </FormControl>
      {['template_question', 'template_question_option'].includes(
        association
      ) && (
        <FormControl>
          <FormLabel>Questions</FormLabel>
          {isLoadingTemplateStructure ? (
            <Flex gap={4} align="center">
              <Text>Loading Template Structure</Text>
              <Spinner size="sm" />
            </Flex>
          ) : (
            <QuestionsSelector
              ref={questionsSelectorRef}
              childrenStructure={templateStructure?.complete?.children ?? []}
              displayLabels
              initialSelectedNodes={selections?.selectedQuestion?.value}
              onConfirm={onSelectQuestion}
              allowSelection={allowSelection}
              selectionMode="single"
              label="Select a Question"
              isOpen={isOpenQuestionSelector}
              onClose={onCloseQuestionSelector}
              useExternalDisclosure
            >
              <Box cursor="pointer">
                {selectedQuestion?.text ? (
                  <Flex
                    onClick={onOpenQuestionSelector}
                    color={theme.colors.blue[500]}
                    align="center"
                    gap={2}
                    as="button"
                  >
                    <EditIcon aria-label="Edit selected question" size="sm" />
                    <Text noOfLines={1}>{selectedQuestion?.text}</Text>
                  </Flex>
                ) : (
                  <Button
                    onClick={onOpenQuestionSelector}
                    colorScheme="blue"
                    size="sm"
                    leftIcon={<EditIcon />}
                  >
                    Select a question
                  </Button>
                )}
              </Box>
            </QuestionsSelector>
          )}
        </FormControl>
      )}
      {['template_question_option'].includes(association) && !isEmpty(options) && (
        <FormControl>
          <FormLabel>Options</FormLabel>
          <Select
            placeholder="Select an option"
            options={options}
            onChange={onSelectOptions}
            value={selectedQuestionOption}
            hideSelectedOptions
            menuPlacement={menuPlacement}
          />
        </FormControl>
      )}
    </Stack>
  )
}

TemplatesSelector.defaultValue = {
  allowSelection: () => true,
  association: 'templates',
  menuPlacement: 'bottom',
}

export default TemplatesSelector
