import consumer from 'channels/consumer';
import { useEffect, useState } from 'react';

const useActionCable = ({openChannel, channel, params}) => {
  const [connected, setConnected] = useState(false);
  const [disconnected, setDisconnected] = useState(false);
  const [received, setReceived] = useState(null);

  useEffect(() => {
    if (openChannel) {
      consumer.subscriptions.create(
        {channel, ...params},
        {
          connected: () => setDisconnected(false) && setConnected(true),
          disconnected: () => setConnected(false) && setDisconnected(true),
          received: (data) => setReceived(data),
        },
      );
    }
  }, [channel, params, openChannel]);

  return {connected, disconnected, received};
};

export default useActionCable;
