import {
  ChevronDownIcon,
  HamburgerIcon,
  SettingsIcon,
  SmallAddIcon
} from '@chakra-ui/icons'
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getRouteUrl } from '../../helpers'
import { getPermission } from '../../helpers/permissions'

const TemplateFrequencyMenu = ({ templateId, hasPermissionToEdit }) => {
  const user = useSelector(({ globalReducer }) => globalReducer.user)
  const permissions = useMemo(() => getPermission(user), [user])
  if (
    [permissions?.hasPermissionToViewScheduledForms, hasPermissionToEdit].every(
      (permission) => !permission
    )
  ) {
    return null
  }
  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<SettingsIcon />}
        rightIcon={<ChevronDownIcon />}
        colorScheme="blue"
        size="sm"
        variant="outline"
      >
        Form Schedule
      </MenuButton>
      <MenuList>
        {hasPermissionToEdit && (
          <Link to={getRouteUrl('createTemplateFrequency', { id: templateId })}>
            <MenuItem icon={<SmallAddIcon />}>New schedule</MenuItem>
          </Link>
        )}
        {permissions?.hasPermissionToViewScheduledForms && (
          <Link to={getRouteUrl('templateFrequencies', { id: templateId })}>
            <MenuItem icon={<HamburgerIcon />}>{`Schedules`}</MenuItem>
          </Link>
        )}
      </MenuList>
    </Menu>
  )
}

TemplateFrequencyMenu.propTypes = {
  templateId: PropTypes.string
}

export default TemplateFrequencyMenu
