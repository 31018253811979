import { AddIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  IconButton
} from '@chakra-ui/react'
import { cloneDeep, isArray, isNumber, noop, omit, uniq } from 'lodash'
import React, { useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import ListBuilder from '../../../../components/ListBuilder'
import ChecklistItem from './ChecklistItem'
import ChecklistModal from './ChecklistModal'

const getFrequenciesIndex = (frequencies = []) => {
  return frequencies.reduce((acc, { value }) => {
    acc[value] = []
    return acc
  }, {})
}

const emptyDefaultValues = {
  title: '',
  description: '',
  compulsory: false,
  frequency: { label: 'Daily', value: 'daily' },
  repeat_values: [],
  completion_limit: 0,
  hasTasksDone: false
}

const ChecklistItemsList = ({
  listKey,
  watch,
  frequencies,
  setValue,
  onRemoveChecklistItem,
  isDisabled,
  limitForCompletionLimit,
  id,
  readOnly,
  isEdit
}) => {
  const [index, setIndex] = useState([])
  const [defaultValues, setDefaultValues] = useState(emptyDefaultValues)
  const handleListChange = (changedList, key) => {
    setValue(`${listKey}.${key}`, changedList)
  }

  const list = watch(listKey, getFrequenciesIndex(frequencies))
  const modalRef = useRef({ open: noop, close: noop })

  const handleCreateItemClick = (e, key) => {
    e.stopPropagation()
    const frequency = frequencies.find(({ value }) => value === key)
    const valuesToSet = {
      ...emptyDefaultValues,
      frequency,
      key,
      _id: uuidv4(),
      context: 'Add',
      active: true,
      _destroy: false
    }

    if (frequency.value === 'monthly') {
      valuesToSet.repeat_values = { month_day: 1 }
    }
    setDefaultValues(valuesToSet)
    modalRef.current.open()
  }

  const handleEditItemClick = (key, index) => {
    const frequency = frequencies.find(({ value }) => value === key)
    const values = list[key][index]
    const valuesToSet = {
      ...values,
      frequency,
      key,
      order: index,
      _id: uuidv4(),
      context: 'Edit',
      index
    }
    setDefaultValues(valuesToSet)
    modalRef.current.open()
  }

  const onChangeItem = (form) => {
    const { key, index: itemIndex } = form
    const newItem = omit(form, ['key'])
    const currentList = isArray(list[key]) ? cloneDeep(list[key]) : []
    if (isNumber(itemIndex)) {
      currentList.splice(itemIndex, 1, newItem)
    } else {
      currentList.push(newItem)
    }
    setValue(`${listKey}.${key}`, currentList)
    const newIndex = frequencies.findIndex(({ value }) => value === key)
    if (index.includes(newIndex)) {
      return
    }
    setIndex((prev) => {
      return uniq([...prev, newIndex])
    })
  }

  const onInactivateChecklist = (index, props) => {
    const { listKey: key } = props
    const items = [...list[key]]
    items.splice(index, 1, { ...items[index], active: false })
    setValue(`${listKey}.${key}`, items)
  }

  return (
    <>
      <ChecklistModal
        ref={modalRef}
        defaultValues={defaultValues}
        frequencies={frequencies}
        onChange={onChangeItem}
        limitForCompletionLimit={limitForCompletionLimit}
        readOnly={readOnly}
      />
      <Flex align="center" gap={4}>
        <Button
          variant="outline"
          onClick={() =>
            setIndex(
              Array.from({ length: frequencies?.length }).map(
                (_, index) => index
              )
            )
          }
          size="sm"
        >
          Expand all
        </Button>
        {index?.length > 0 && (
          <Button variant="outline" onClick={() => setIndex([])} size="sm">
            Collapse all
          </Button>
        )}
      </Flex>
      <Accordion index={index} allowMultiple allowToggle onChange={setIndex}>
        {frequencies.map(({ label, value }, idx) => (
          <AccordionItem key={value}>
            <h2>
              <AccordionButton>
                <Flex
                  w="100%"
                  flex={1}
                  align="center"
                  justify={['flex-start', 'space-between']}
                  flexDir={['column', 'row']}
                >
                  <Box as="span" flex="1" textAlign="left">
                    {label} ({list[value]?.length ?? 0})
                  </Box>
                  <Flex gap={4} flexDirection={['column', 'row']}>
                    {!readOnly && (
                      <Button
                        size="sm"
                        onClick={(e) => handleCreateItemClick(e, value)}
                        colorScheme="blue"
                        leftIcon={<AddIcon />}
                        as="span"
                      >
                        Add task
                      </Button>
                    )}
                    <IconButton
                      size="sm"
                      icon={
                        index.includes(idx) ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        )
                      }
                      variant="outline"
                      colorScheme="blue"
                      as="span"
                    />
                  </Flex>
                </Flex>
              </AccordionButton>
            </h2>
            <AccordionPanel py={4} px={0}>
              <ListBuilder
                list={list[value] ?? []}
                resourceName="Checklist item"
                onChange={(updatedList) => handleListChange(updatedList, value)}
                ItemComponent={ChecklistItem}
                onAddItem={noop}
                onRemoveItem={onRemoveChecklistItem}
                idAttribute="_id"
                itemProps={{
                  listKey: value,
                  handleEditItemClick,
                  onEdit: handleEditItemClick,
                  frequencies,
                  id,
                  readOnly,
                  isEdit
                }}
                isDisabled={isDisabled || readOnly}
                displayAddItemButton={false}
                deleteButtonStyles={() => ({
                  position: 'absolute',
                  top: '10px',
                  right: '10px'
                })}
                deleteButtonAttributes={(index) => {
                  return list[value]?.[index]?.hasTasksDone
                    ? {
                        alertTitle: 'Action required',
                        alertBody:
                          'This checklist was already answered and cannot be deleted. Do you want to inactive it instead ?',
                        continueColor: 'green'
                      }
                    : {
                        alertTitle: 'Action required',
                        alertBody:
                          'Are your sure that you want to remove this this item ?',
                        continueColor: 'red'
                      }
                }}
                customRemoveItemAction={(index, props, fallback) => {
                  return list[value]?.[index]?.hasTasksDone
                    ? onInactivateChecklist(index, props)
                    : fallback(index)
                }}
              />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  )
}

export default ChecklistItemsList
