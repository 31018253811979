import { Tag, Tooltip } from '@chakra-ui/react'
import { isEmpty, pick, trim } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { getExtensionColor, getExtensionIcon } from '../../../helpers'
import {
  usePageProperties,
  useQuery,
  useTableColumns,
  useTableFilter
} from '../../../hooks'
import OlingaAPI from '../../../services/OlingaAPI'
import DocumentsActions from '../DocumentsActions'
import FoldersModal from '../FoldersModal'

const hiddenColumnsKey = 'form_answers_persisted_columns.documents'

const useLibrary = () => {
  const query = useQuery()

  const {
    shouldCreate: hasPermissionToCreate,
    shouldRender: hasPermissionToView,
    pageTitle
  } = usePageProperties({
    pageName: 'documents'
  })

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Extension',
        accessor: 'extension'
      },
      {
        Header: 'Active',
        accessor: 'active'
      },
      {
        Header: 'Folders',
        accessor: 'folders'
      }
    ],
    hiddenColumnsKey,
    hasPermissionToView,
    DocumentsActions,
    (row) => {
      const {
        values: { id },
        original: {
          attachment: { url },
          userPermissions
        }
      } = row
      return { id, url, userPermissions }
    }
  )

  const fieldsExtractor = useCallback((data) => {
    return data.map(
      ({
        attributes: {
          name,
          active,
          attachment,
          libraryId,
          userPermissions,
          id,
          formFolderIds
        }
      }) => {
        const fileExtension = attachment.extension

        return {
          name,
          attachment,
          userPermissions,
          libraryId,
          formFolderIds,
          active: (
            <Tag colorScheme={active ? 'green' : 'gray'}>
              {active ? 'Active' : 'Inactive'}
            </Tag>
          ),
          folders: (
            <FoldersModal
              id={id}
              count={formFolderIds.length}
              documentName={name}
            />
          ),
          extension: (
            <Tooltip label={fileExtension} hasArrow>
              <i
                className={getExtensionIcon(fileExtension)}
                style={{
                  color: getExtensionColor(fileExtension),
                  fontSize: '24px'
                }}
              />
            </Tooltip>
          ),
          id
        }
      }
    )
  }, [])

  const initialParams = useMemo(() => {
    return isEmpty(query)
      ? {
          page: 1,
          per_page: 15
        }
      : pick(query, ['page', 'per_page'])
  }, [query])

  const {
    refetch,
    isLoading,
    updateParams,
    fetchParams,
    tableData,
    pagination
  } = useTableFilter({
    id: query?.asset_template_id,
    fetchCallback: OlingaAPI.documentsIndex,
    fieldsExtractor,
    initialParams
  })

  const onTextChange = useCallback(
    (updatedText) => {
      if (!updatedText || !trim(updatedText)?.length) {
        return refetch(initialParams)
      }
      updateParams({ name: updatedText }, updatedText?.length >= 3)
    },
    [initialParams, refetch, updateParams]
  )

  return {
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data: tableData,
    isLoading,
    refetch,
    updateParams,
    fetchParams,
    pagination,
    onTextChange,
    hasPermissionToCreate,
    pageTitle
  }
}

export default useLibrary
