import { AddIcon } from '@chakra-ui/icons'
import { Button, Flex } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTemplateEditorContext } from '../../../../contexts'
import useFormActions from '../hooks/useFormActions'
import useFormActionsPositioning from '../hooks/useFormActionsPositioning'
import RemoveButton from './RemoveButton'
import RemoveFocusButton from './RemoveFocusButton'
import SettingsButton from './SettingsButton'
import { ActionsList, EndActions, Wrapper } from './styles'
import MaterialIcon from '../../../../components/MaterialIcon'

const buttonColorScheme = {
  question: 'orange',
  section: 'blue',
  logic: 'teal',
  instruction: 'gray',
  remove: 'red'
}

const MobileActions = ({ template }) => {
  const [isActive, setIsActive] = useState(false)
  const [selectedOption, setSelectedOption] = useState(0)

  const { isRemovable } = useFormActionsPositioning({ template })
  const {
    addQuestion,
    addInstruction,
    addLogic,
    addSection,
    removeElement,
    onOpenSettings,
    removeFocus
  } = useFormActions()

  const { focusedField } = useTemplateEditorContext()

  const actions = [
      {
        type: 'question',
        handler: addQuestion,
        icon: 'question_mark',
        color: '#ff871c'
      },
      {
        type: 'section',
        handler: addSection,
        icon: 'format_list_numbered',
        color: '#1890ff'
      },
      {
        type: 'logic',
        handler: addLogic,
        icon: 'playlist_add_check',
        color: '#36cfc9'
      },
      {
        type: 'instruction',
        handler: addInstruction,
        icon: 'info',
        color: '#4A5568'
      }
    ]

  const toggleActionAnimation = () => {
    setIsActive((prevState) => !prevState)
  }

  const handleSelection = (type) => {
    setSelectedOption(type)
  }

  const handleAddAction = () => {
    const { handler: performAdd } = actions[selectedOption]
    performAdd()
  }

  return (
    <>
      <Flex w="100%" mx="2" align="center" justify="space-between">
        <Wrapper
          onClick={toggleActionAnimation}
          color={actions[selectedOption].color}
          id="template-question-type-wrapper"
        >
          <MaterialIcon icon={actions[selectedOption].icon} />
          <ActionsList className={isActive ? 'active' : ''}>
            {actions.map((action, index) => {
              return (
                <Wrapper
                  onClick={() => handleSelection(index)}
                  key={index}
                  id={`template-add-${action.type}`}
                >
                  <MaterialIcon styles={{ color: action.color }} icon={action.icon} />
                </Wrapper>
              )
            })}
          </ActionsList>
        </Wrapper>
        {!isActive && (
          <Button
            onClick={handleAddAction}
            colorScheme={buttonColorScheme[actions[selectedOption].type]}
            leftIcon={<AddIcon />}
            variant="outline"
            aria-label="Mobile add template question"
          >
            Add {actions[selectedOption].type}
          </Button>
        )}
        <EndActions>
          <RemoveFocusButton
            focusedField={focusedField}
            isActive={isActive}
            removeFocus={removeFocus}
          />
          <RemoveButton
            focusedField={focusedField}
            isRemovable={isRemovable}
            removeElement={removeElement}
          />
          <SettingsButton onOpenSettings={onOpenSettings} />
        </EndActions>
      </Flex>
    </>
  )
}

export default MobileActions
