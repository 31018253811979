import AssetDefaultField from 'pages/admin/AssetDefaultFields'
import React from 'react'
import AssetDefaultFieldEditorPage from 'routes/ImplementedPages/Admin/AssetDefaultFieldEditorPage'
import SettingsEditorPage from 'routes/ImplementedPages/Admin/SettingsEditorPage'
import { useRoutes } from '../../hooks'
import Layout from '../../layout'
import AssetTypes from '../../pages/admin/AssetTypes'
import Businesses from '../../pages/admin/Businesses'
import BusinessesForm from '../../pages/admin/Businesses/BusinessForm'
import { GlobalStyle } from '../../utils/styles'
import AssetTypeEditorPage from '../ImplementedPages/Admin/AssetTypeEditorPage'

const Routes = () => {
  const Routes = useRoutes([
    {
      path: ['/sm/web/asset_types'],
      component: AssetTypes,
      isExact: true
    },
    {
      path: ['/sm/web/asset_types/:id'],
      render: ({
        match: {
          params: { id }
        }
      }) => <AssetTypeEditorPage id={id} />,
      isExact: true
    },
    {
      path: ['/sm/web/asset_default_fields/:id'],
      render: ({
        match: {
          params: { id }
        }
      }) => <AssetDefaultFieldEditorPage id={id} />,
      isExact: true
    },
    {
      path: ['/sm/web/asset_default_fields'],
      component: AssetDefaultField,
      isExact: true
    },
    {
      path: ['/sm/web/businesses'],
      component: Businesses,
      isExact: true
    },
    {
      path: ['/sm/web/business/:id'],
      render: ({
        match: {
          params: { id }
        }
      }) => <BusinessesForm id={id} />,
      isExact: true
    },
    {
      path: ['/sm/web/businesses/new'],
      component: BusinessesForm,
      isExact: true
    },
    {
      path: ['/sm/web/settings'],
      component: SettingsEditorPage,
      isExact: true
    }
  ])
  return (
    <>
      <GlobalStyle />
      <Layout>{Routes}</Layout>
    </>
  )
}

export default Routes
