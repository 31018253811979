import { Icon } from '@chakra-ui/react'
import React from 'react'

export const defaultOperations = [
  {
    keyboardKey: 'Backspace',
    type: 'remove',
    label: 'remove',
    symbol: true,
    color: 'red',
    value: (
      <Icon x="0px" y="0px" width="14px" height="14px" viewBox="0 0 612 612">
        <g>
          <g id="backspace">
            <path
              fill="currentColor"
              d="M561,76.5H178.5c-17.85,0-30.6,7.65-40.8,22.95L0,306l137.7,206.55c10.2,12.75,22.95,22.95,40.8,22.95H561
			c28.05,0,51-22.95,51-51v-357C612,99.45,589.05,76.5,561,76.5z M484.5,397.8l-35.7,35.7L357,341.7l-91.8,91.8l-35.7-35.7
			l91.8-91.8l-91.8-91.8l35.7-35.7l91.8,91.8l91.8-91.8l35.7,35.7L392.7,306L484.5,397.8z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </Icon>
    )
  }
]
