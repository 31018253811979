import { Accordion, HStack } from '@chakra-ui/react'
import React from 'react'
import SelectionComponent from '../SelectionComponent'

export const questionsTransformer = {
  question: {
    type: 'question',
    icon: 'question_mark',
    color: '#ff871c'
  },
  section: {
    type: 'section',
    icon: 'format_list_numbered',
    color: '#1890ff'
  },
  logic: {
    type: 'logic',
    icon: 'playlist_add_check',
    color: '#36cfc9'
  },
  condition: {
    type: 'condition',
    icon: 'playlist_add_check',
    color: '#358581'
  },
  instruction: {
    type: 'instruction',
    icon: 'info',
    color: '#4A5568'
  }
}

export const getDefaultStyles = (type) => ({
  p: 4,
  borderLeft: `4px solid ${questionsTransformer[type].color}`,
  borderRadius: 4,
  shadow: 'sm',
  transition: 'box-shadow 0.2s ease',
  _hover: { shadow: 'md' }
})

export const getMobileFlexStyles = (isMobile, isParent) => {
  return {
    justify: isMobile ? 'flex-start' : 'space-between',
    align: isMobile ? 'flex-start' : 'center',
    direction: isMobile ? 'column' : 'row',
    gap: isParent ? 4 : 0
  }
}

const BaseStructure = ({
  id,
  type,
  text,
  hasChildren,
  childrenIndexes,
  children,
  otherProps,
  hasSelectionComponent
}) => {
  if (['question', 'instruction'].includes(type) && !hasChildren) {
    return (
      <HStack spacing={3} {...getDefaultStyles(type)}>
        {hasSelectionComponent && (
          <SelectionComponent id={id} type={type} text={text} {...otherProps} />
        )}
        {children}
      </HStack>
    )
  }

  return (
    <Accordion defaultIndex={childrenIndexes} allowMultiple>
      {children}
    </Accordion>
  )
}

BaseStructure.defaultProps = {
  childrenIndexes: [],
  hasSelectionComponent: true
}

export default BaseStructure
