import { isEmpty, isString } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import OlingaAPI from 'services/OlingaAPI'

const formatOptions = (options = []) =>
  options
    .map((item) => {
      if (isString(item)) {
        return {
          label: item,
          value: item
        }
      }
      if (item?.name && isString(item?.name)) {
        const { name } = item
        return {
          label: name,
          value: name
        }
      }
      return null
    })
    .filter((v) => !!v)

const useAssetTemplateSelector = ({ association, onChange, selections }) => {
  const [{ assetTemplates, assetTemplateFields, options }, setResources] =
    useState({
      assetTemplates: [],
      assetTemplateFields: [],
      options: []
    })

  const [
    { isLoadingAssetTemplates, isLoadingAssetTemplateFields },
    setIsLoading
  ] = useState({
    isLoadingAssetTemplates: false,
    isLoadingAssetTemplateFields: false
  })

  const [
    {
      selectedAssetTemplate,
      selectedAssetTemplateField,
      selectedAssetTemplateFieldOption
    },
    setSelectedResource
  ] = useState(
    !isEmpty(selections)
      ? selections
      : {
          selectedAssetTemplate: null,
          selectedAssetTemplateField: null,
          selectedAssetTemplateFieldOption: null
        }
  )

  const fetchAssetTemplate = useCallback(
    async (id, shouldSetOptions = false) => {
      setIsLoading((prev) => ({
        ...prev,
        isLoadingAssetTemplateFields: true
      }))
      try {
        const response = await OlingaAPI.assetTemplate(id)
        const data = response?.data?.data?.attributes?.asset_template_fields
          ?.filter(({ field_type }) =>
            association === 'asset_template_field_option'
              ? field_type === 'list'
              : true
          )
          ?.map((field) => ({
            ...field,
            label: field.name,
            value: field.id
          }))

        setResources((prev) => ({
          ...prev,
          assetTemplateFields: data
        }))

        if (
          shouldSetOptions &&
          selections?.selectedAssetTemplate?.value ===
            response?.data?.data?.attributes?.id
        ) {
          const field =
            response?.data?.data?.attributes?.asset_template_fields?.find(
              ({ id }) => id === selectedAssetTemplateField?.value
            )
          setResources((prev) => ({
            ...prev,
            options: formatOptions(field?.settings?.list_options ?? [])
          }))
        }
      } catch (error) {
        //
      } finally {
        setIsLoading((prev) => ({
          ...prev,
          isLoadingAssetTemplateFields: false
        }))
      }
    },
    [
      association,
      selectedAssetTemplateField?.value,
      selections?.selectedAssetTemplate?.value
    ]
  )

  useEffect(() => {
    const fetchAssetTemplates = async () => {
      setIsLoading((prev) => ({
        ...prev,
        isLoadingAssetTemplates: true
      }))
      try {
        const { data } = await OlingaAPI.assetTemplatesAsEnumerator()
        const templates = data?.data?.asset_templates

        setResources((prev) => ({
          ...prev,
          assetTemplates: templates
        }))
      } catch (error) {
        //
      } finally {
        setIsLoading((prev) => ({
          ...prev,
          isLoadingAssetTemplates: false
        }))
      }
    }

    fetchAssetTemplates()
  }, [onChange, association, fetchAssetTemplate])

  const onChangeAssetTemplate = async (newAssetTemplate) => {
    let updatedContext
    setSelectedResource((prev) => {
      updatedContext = {
        ...prev,
        selectedAssetTemplate: newAssetTemplate,
        selectedAssetTemplateField: null,
        selectedAssetTemplateFieldOption: null
      }

      return updatedContext
    })

    if (
      ['asset_template_field', 'asset_template_field_option'].includes(
        association
      )
    ) {
      await fetchAssetTemplate(newAssetTemplate.value)
    }

    onChange([newAssetTemplate, updatedContext])
  }

  const onChangeAssetTemplateField = (newAssetTemplateField) => {
    let updatedContext
    setSelectedResource((prev) => {
      updatedContext = {
        ...prev,
        selectedAssetTemplateField: newAssetTemplateField,
        selectedAssetTemplateFieldOption: null
      }
      return updatedContext
    })

    if (association === 'asset_template_field_option') {
      setResources((prev) => ({
        ...prev,
        options: formatOptions(
          newAssetTemplateField?.settings?.list_options ?? []
        )
      }))
    }

    onChange([newAssetTemplateField, updatedContext])
  }

  const onChangeAssetTemplateFieldOption = (newAssetTemplateFieldOption) => {
    let updatedContext
    setSelectedResource((prev) => {
      updatedContext = {
        ...prev,
        selectedAssetTemplateFieldOption: newAssetTemplateFieldOption
      }
      return updatedContext
    })

    onChange([newAssetTemplateFieldOption, updatedContext])
  }

  useEffect(() => {
    if (
      selections?.selectedAssetTemplateField?.value &&
      selections?.selectedAssetTemplate?.value
    ) {
      fetchAssetTemplate(selections?.selectedAssetTemplate?.value, true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    assetTemplates,
    assetTemplateFields,
    options,
    isLoadingAssetTemplates,
    isLoadingAssetTemplateFields,
    selectedAssetTemplate,
    selectedAssetTemplateField,
    selectedAssetTemplateFieldOption,
    onChangeAssetTemplate,
    onChangeAssetTemplateField,
    onChangeAssetTemplateFieldOption
  }
}

export default useAssetTemplateSelector
