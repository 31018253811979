import { Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import FloatingLabelBox from '../../../components/FloatingLabelBox'
import AttributeSwitch from './AttributeSwitch'
import FolderTag from './FolderTag'
import GroupTag from './GroupTag'

const TemplateDetails = ({
  formFolderId,
  formFolderName,
  name,
  groups,
  active,
  notify,
  mapView,
  id,
  singularTitle,
  hasPermissionToModifyFolder,
  hasPermissionsToModifyGroup
}) => {
  return (
    <FloatingLabelBox label={`${singularTitle} details`}>
      <Stack w="100%" gap={4}>
        <SimpleGrid columns={2}>
          <Heading size="sm" isTruncated>
            ID
          </Heading>
          <Text fontSize="sm" isTruncated>
            {id}
          </Text>
        </SimpleGrid>
        <SimpleGrid columns={2}>
          <Heading size="sm" isTruncated>
            Name
          </Heading>
          <Text fontSize="sm" isTruncated>
            {name}
          </Text>
        </SimpleGrid>
        <SimpleGrid columns={2}>
          <Heading size="sm" isTruncated>
            Folder
          </Heading>
          <FolderTag
            formFolderId={formFolderId}
            formFolderName={formFolderName}
            hasPermissionToModifyFolder={hasPermissionToModifyFolder}
          />
        </SimpleGrid>
        <SimpleGrid columns={2}>
          <Heading size="sm" isTruncated>
            Group
          </Heading>
          <GroupTag
            groups={groups}
            hasPermissionsToModifyGroup={hasPermissionsToModifyGroup}
          />
        </SimpleGrid>
        <AttributeSwitch label="Active" answer={active} />
        <AttributeSwitch label="Notify" answer={notify} />
        <AttributeSwitch label="Map View" answer={mapView} />
      </Stack>
    </FloatingLabelBox>
  )
}

export default TemplateDetails
