import React from 'react'
import { Icon } from '@chakra-ui/icons'

export const OlingaLogo = (color) => (
  <Icon viewBox="0 0 100 113" color={color}>
    <path
      fill="currentColor"
      d="m48.3 9.2-6.1 6.3 1.1 5.1c1 5 .5 7.8-2.6 15.4-.4 1 1.6 3.9 6.1 8.5l6.7 7 .6-6.5.6-6.5.1 6.3c.1 3.5.6 6.2 1 6C57.9 50 76 29.9 76 28.5c0-3.1-3-7.1-12.2-16.3L54.5 3l-6.2 6.2zM32.5 41.1c-4.5 2.1-9.1 2.4-13.1.9-2.6-1-3.2-.7-9.1 5.2-3.5 3.4-6.2 6.6-6.1 7 .2.3 4.9 5.4 10.6 11.2 8.1 8.3 10.9 10.6 13 10.6 2.1 0 5-2.2 12.5-9.8 5.3-5.3 9.7-10 9.7-10.4 0-.4-1.2-.9-2.7-1.1l-2.8-.4 2.8-.2c1.5 0 2.7-.7 2.7-1.4 0-1.2-12-13.8-13-13.6-.3 0-2.3.9-4.5 2zM43.3 68C31 81 30.9 80.5 44.4 94.6l10.5 10.9 10.5-10.6c13.8-13.8 13.8-13.6 1.5-27-5.1-5.4-9.5-9.9-10-9.9-.4 0-1 1.5-1.2 3.2l-.4 3.3-.1-3.3c-.1-1.9-.7-3.2-1.4-3.1-.7.1-5.5 4.5-10.5 9.9z"
    />
  </Icon>
)

export const LuigiJobsLogo = (color) => (
  <Icon color={color} viewBox="0 0 128 128">
    <path
      fill="currentColor"
      d="M46.4 1.9C24 8.4 7.3 25.6 1.5 48-1.1 58-.2 76.1 3.3 86c6.5 18.1 21.9 33.2 39.9 39.1 6.2 2.1 9.3 2.4 20.8 2.4s14.6-.3 20.8-2.4c18.4-6 34.3-21.9 40.3-40.2 2.1-6.4 2.4-9.3 2.4-20.9 0-11.5-.3-14.6-2.4-20.8-5.9-18-21-33.4-39.1-39.9C79.6 1 76.7.6 65.5.3 55.3.1 51.2.4 46.4 1.9zm17.1 13.2c6.5 1.6 9.5 2.7 9.5 3.7 0 .8-4.7 17.2-10.5 36.5-5.8 19.2-10.3 35.2-10.1 35.5.3.2 9.9-1.9 21.4-4.7C85.3 83.3 95.3 81 96.1 81c.9 0 2.4 3.4 4.3 10 1.6 5.5 2.7 10.2 2.5 10.5-.3.2-13.5 3.6-29.3 7.5-24.3 5.9-29.2 6.8-30.5 5.7-.9-.7-5.3-4.6-9.9-8.7-7.3-6.4-8.3-7.6-7.7-9.9.3-1.4 6-21 12.7-43.4 10.8-36.3 12.3-40.8 14-40.4 1 .3 6.1 1.5 11.3 2.8z"
    />
  </Icon>
)
