import PropTypes from 'prop-types'

const baseEnumTypes = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string
})

export const operationsTypes = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    symbol: PropTypes.string,
    color: PropTypes.string,
    question_type: PropTypes.string,
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
)

export const calculatedFieldTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  template_question_id: PropTypes.number,
  expression: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    )
  }),
  do_action: PropTypes.string
})

export const calculatedFieldsTypes = PropTypes.arrayOf(calculatedFieldTypes)

export const templateAttributesTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      question_calculation_fields: PropTypes.array
    })
  )
})

export const templateTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  attributes: templateAttributesTypes
})

export const questionsTypes = calculatedFieldsTypes

export const calculatedFieldsEnumTypes = PropTypes.shape({
  doActions: PropTypes.arrayOf(baseEnumTypes),
  mathOperators: PropTypes.arrayOf(baseEnumTypes),
  states: PropTypes.arrayOf(baseEnumTypes)
})

export const templateStructureTypes = PropTypes.shape({
  complete: templateAttributesTypes,
  linear: PropTypes.shape({
    data: PropTypes.arrayOf(templateAttributesTypes),
    object: PropTypes.object
  })
})
