import { AddIcon } from '@chakra-ui/icons'
import { Button, Flex, Stack } from '@chakra-ui/react'
import SearchInput from 'components/SearchInput'
import React from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import { getRouteUrl } from '../../helpers'
import useGroups from './useGroups/index'

const GroupUsers = () => {
  const {
    hasPermissionsToModifyGroup,
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data,
    isLoading,
    refetch,
    fetchParams,
    pagination,
    onTextChange,
    pageTitle
  } = useGroups()

  return (
    <Stack as="section">
      <PageHeader
        Actions={
          <Flex flexDir={['column', 'row']} gap={4} align="center">
            <SearchInput
              value={fetchParams?.name}
              setValue={onTextChange}
              isLoading={isLoading}
              debounceTimeout={25}
              placeholder="Filter by name"
            />
            {hasPermissionsToModifyGroup && (
              <Link to={{ pathname: getRouteUrl('newGroup'), from: 'groups' }}>
                <Button colorScheme="blue" leftIcon={<AddIcon />}>
                  New
                </Button>
              </Link>
            )}
          </Flex>
        }
        title={pageTitle}
      />
      <Table
        shouldResetPage={fetchParams?.page === 1}
        isLoading={isLoading}
        data={data}
        columns={columns}
        pageCount={pagination?.last}
        totalItems={pagination?.items}
        fetchPage={refetch}
        initiallyHiddenColumns={hiddenColumns}
        initialPageIndex={Number(fetchParams?.page - 1)}
        persistedColumnsKey={hiddenColumnsKey}
      />
    </Stack>
  )
}

export default GroupUsers
