import { useGlobalStateContext } from '../../contexts/GlobalStateContext'
import { useEffect, useRef } from 'react'

const useGlobalEventListener = (onTrigger) => {
  const { global } = useGlobalStateContext()
  const subscriptionRef = useRef(null)

  useEffect(() => {
    subscriptionRef.current = global.subscribe((event) => {
      onTrigger(event)
    })
    return () => {
      subscriptionRef.current?.unsubscribe()
    }
  }, [global, onTrigger])

  return null
}

export default useGlobalEventListener
