import { ChevronDownIcon, ViewIcon } from '@chakra-ui/icons'
import { Heading, HStack, IconButton, Tag, Text } from '@chakra-ui/react'
import { capitalize, isArray } from 'lodash'
import React from 'react'
import List from '../../../components/List'
import Modal from '../../../components/Modal'
import { getPrefixes } from '../../../helpers'
import GroupKebab from '../../../pages/Group/GroupKebab'

const CountIcon = ({ count }) => {
  if (count === 0) {
    return <Tag>Empty</Tag>
  }
  return (
    <HStack flex={1} px={2} spacing={2} justify="center">
      <Text color="blue.500">{count}</Text>
      <ViewIcon />
    </HStack>
  )
}

const GroupsModal = ({ count, folderName, hasPermission, groups }) => {
  const getHeader = () => {
    if (isArray(groups)) {
      const { before, after } = getPrefixes(count, 'group')
      const getText = () => {
        if (!count || count === 0) {
          return 'There are no groups for this folder'
        }
        return `${capitalize(before)} ${
          groups.length === 1 ? 'only' : groups.length
        } ${after} from folder ${folderName}`
      }

      return <Heading size="md">{getText()}</Heading>
    }
    return null
  }

  const getItemBody = (item) => {
    return (
      <HStack align="center">
        <Text fontSize="md" color="gray.700">
          {item.name}
        </Text>
        {hasPermission && (
          <GroupKebab
            id={item.id}
            size="xs"
            zIndex={9999999}
            icon={<ChevronDownIcon />}
            hasBorder={false}
          />
        )}
      </HStack>
    )
  }

  return (
    <Modal
      header={getHeader()}
      body={<List getItemBody={getItemBody} items={groups ?? []} />}
    >
      <IconButton
        icon={<CountIcon count={count} />}
        colorScheme="blue"
        size="sm"
        variant="outline"
        minW="64px"
      />
    </Modal>
  )
}

GroupsModal.defaultProps = {
  customButtonLabel: ''
}

export default GroupsModal
