import { useDisclosure } from '@chakra-ui/react'
import { downloadDocument, setFormErrors } from 'helpers'
import { first, noop } from 'lodash'
import { useState } from 'react'
import Notification from 'services/Notification'
import OlingaAPI from 'services/OlingaAPI'

const useManageStaffImport = ({ refetch }) => {
  const {
    isOpen: isImportCsvModalOpen,
    onClose: onCloseImportCsvModal,
    onOpen
  } = useDisclosure()
  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onOpenImportCsvModal = async () => {
    onOpen()
  }

  const onDownloadStaff = async () => {
    try {
      const response = await OlingaAPI.exportStaffCsv()
      if (response.status === 200) {
        downloadDocument(response.data, `user_onboard_template.csv`)
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    }
  }

  const onBulkImportStaffs = async () => {
    setIsLoading(true)
    try {
      const data = {
        file: first(files)
      }
      const response = await OlingaAPI.importStaffCsv('', data)
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: response?.data?.message
        })
        onCloseImportCsvModal()
        refetch({
          page: 1,
          per_page: 15
        })
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeFiles = (newFiles) => {
    setFiles(newFiles)
  }

  return {
    isImportCsvModalOpen,
    onCloseImportCsvModal,
    onOpenImportCsvModal,
    onDownloadStaff,
    onChangeFiles,
    files,
    onBulkImportStaffs,
    isLoading
  }
}

export default useManageStaffImport
