import { capitalize, debounce, first, get, isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { usePageProperties } from '../../..//hooks'
import { useGlobalStateContext } from '../../../contexts/GlobalStateContext'
import { onChangeTemplateSettingsTab } from '../../../contexts/GlobalStateContext/events'
import { getRouteUrl } from '../../../helpers'
import tabs from '../TemplateSettingsTabs/tabs'

const useTemplateSettings = (template) => {
  const { global$ } = useGlobalStateContext()

  const history = useHistory()

  const templateDetails = useMemo(() => {
    if (template?.templateStructure?.attributes) {
      const attributes = get(template, 'templateStructure.attributes') ?? {}
      const selectedFolder =
        template?.formFolders.find(
          ({ value: id }) => id === get(attributes, 'form_folder_id')
        ) ?? {}
      return {
        ...attributes,
        formFolderName: attributes.formFolderName,
        userPermissions: attributes?.user_permissions ?? {},
        template_setting_attributes: {
          ...(attributes.template_setting_attributes ?? {}),
          label: capitalize(
            get(
              attributes,
              'template_setting_attributes.form_permission'
            )?.replace(/_/g, ' ')
          )
        },
        selectedFormFolder: selectedFolder,
        groups: attributes.template_groups_attributes.map(
          ({ id, group_id }) => ({
            groupId: group_id,
            id
          })
        )
      }
    }
    return null
  }, [template])

  const { pageTitle, singularTitle } = usePageProperties({
    pageName: 'templates'
  })

  const filteredTabs = useMemo(() => {
    const permissions = get(
      template,
      'templateStructure.attributes.user_permissions'
    )
    return tabs
      .filter(({ id }) => permissions[id])
      .map((tab) => ({
        ...tab,
        label: tab.label.replace(/template/g, singularTitle)
      }))
  }, [singularTitle, template])

  const onSelectUnauthorizedTab = () => {
    const tabs = filteredTabs
    if (isEmpty(tabs)) {
      return history.push(getRouteUrl('unauthorized'))
    } else {
      const tab = first(tabs)
      debounce(() => {
        global$.next(
          onChangeTemplateSettingsTab({
            id: tab.id
          })
        )
      }, 100)()
    }
  }

  return {
    templateDetails,
    tabs: filteredTabs,
    onSelectUnauthorizedTab,
    pageTitle,
    singularTitle
  }
}

export default useTemplateSettings
