import React from 'react'
import BasePage from '../../../pages/BasePage'
import DocumentEditor from '../../../pages/DocumentEditor'
import OlingaAPI from '../../../services/OlingaAPI'

const DocumentPage = ({ id = null }) => {
  const callbacks = id
    ? {
        document: {
          callback: OlingaAPI.libraryDocument,
          params: {}
        },
        folders: { callback: () => OlingaAPI.foldersEnumerator('documents') }
      }
    : {
        folders: { callback: () => OlingaAPI.foldersEnumerator('documents') }
      }
  return (
    <BasePage
      id={id}
      resourceName="data"
      fetchCallback={callbacks}
      fetchWithoutId
    >
      <DocumentEditor />
    </BasePage>
  )
}

export default DocumentPage
