export const actions = {
  SET_POSITION: 'SET_POSITION',
  SET_IS_REMOVABLE: 'SET_IS_REMOVABLE',
  SET_ELEMENT_TYPE: 'SET_ELEMENT_TYPE',
  SET_ATTRIBUTE_DISABLE: 'SET_ATTRIBUTE_DISABLE'
}

const {
  SET_ELEMENT_TYPE,
  SET_ATTRIBUTE_DISABLE,
  SET_IS_REMOVABLE,
  SET_POSITION
} = actions

export const formActionsReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_POSITION:
      return {
        ...state,
        position: payload
      }
    case SET_IS_REMOVABLE:
      return {
        ...state,
        isRemovable: payload
      }
    case SET_ELEMENT_TYPE:
      return {
        ...state,
        elementType: payload
      }
    case SET_ATTRIBUTE_DISABLE:
      return {
        ...state,
        attributeDisabled: payload
      }
    default:
      throw Error('Invalid action')
  }
}

export const initialState = {
  position: 96,
  isRemovable: false,
  elementType: '',
  attributeDisabled: null
}
