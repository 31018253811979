import { AddIcon } from '@chakra-ui/icons'
import { Button, Stack } from '@chakra-ui/react'
import DownloadAndUploadModal from 'components/DownloadAndUploadModal'
import MaterialIcon from 'components/MaterialIcon'
import React from 'react'
import { Link } from 'react-router-dom'
import SearchInput from '../../components/SearchInput'
import Table from '../../components/Table'
import { getRouteUrl } from '../../helpers'
import ManageStaffPageHeader from './PageHeader'
import useManageStaff from './hooks/useManageStaff'
import useManageStaffImport from './hooks/useManageStaffImport'

const ManageStaff = () => {
  const {
    columns,
    hiddenColumns,
    isLoading,
    fetchParams,
    tableData,
    pagination,
    onTextChange,
    onOpenCreateStaffModal,
    onChangePage,
    tableKey,
    initialPageIndex,
    initialRowsPerPage,
    location,
    title,
    subject,
    refetch
  } = useManageStaff()

  const {
    isImportCsvModalOpen,
    onCloseImportCsvModal,
    onOpenImportCsvModal,
    onChangeFiles,
    onBulkImportStaffs,
    files,
    onDownloadStaff,
    isLoading: isImporting
  } = useManageStaffImport({ refetch })

  return (
    <>
      <DownloadAndUploadModal
        isOpen={isImportCsvModalOpen}
        onClose={onCloseImportCsvModal}
        onBulkImportItem={onBulkImportStaffs}
        onChangeFiles={onChangeFiles}
        files={files}
        shouldSelectItem={false}
        isLoading={isImporting}
        onDownloadItem={onDownloadStaff}
        itemsDescriptions={['', '']}
        loadingText="Importing"
        downloadLabel="Download .csv template"
      ></DownloadAndUploadModal>
      <Stack w="100%">
        <ManageStaffPageHeader
          title={title}
          Actions={
            <>
              <Button
                leftIcon={<MaterialIcon icon="drive_file_move" />}
                colorScheme="green"
                as={Button}
                onClick={onOpenImportCsvModal}
              >
                Bulk Import
              </Button>
              <Link
                to={{
                  pathname: getRouteUrl('newStaff'),
                  state: { background: location }
                }}
              >
                <Button
                  colorScheme="blue"
                  leftIcon={<AddIcon />}
                  onClick={onOpenCreateStaffModal}
                >
                  New
                </Button>
              </Link>
            </>
          }
        />
        <Table
          shouldResetPage={fetchParams?.page === 1}
          initialPageIndex={initialPageIndex}
          initialRowsPerPage={initialRowsPerPage}
          columns={columns}
          data={tableData}
          isLoading={isLoading}
          pageCount={pagination?.last}
          totalItems={pagination?.items}
          fetchPage={onChangePage}
          initiallyHiddenColumns={hiddenColumns}
          persistedColumnsKey={tableKey}
        >
          <SearchInput
            value={fetchParams?.full_name}
            setValue={onTextChange}
            isLoading={isLoading}
            debounceTimeout={25}
            placeholder={`Filter by ${subject} name`}
          />
        </Table>
      </Stack>
    </>
  )
}

export default ManageStaff
