import { Box, Flex, Heading, Stack, Tag, theme } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import EmptyState from '../../../components/EmptyState'
import { getRouteUrl } from '../../../helpers'
import ChecklistKebab from '../ChecklistKebab'
import { Wrapper } from '../styles'

const ChecklistListItem = ({ checklists, pageTitle }) => {
  if (isEmpty(checklists)) {
    return <EmptyState text={`No ${pageTitle} found`} />
  }
  return (
    Array.isArray(checklists) &&
    checklists.map((checklist) => {
      const key = `${checklist.id}-${uuidv4()}`
      const {
        update: hasPermissionToEditTask,
        user_tasks: hasPermissionToVisualizeTasks
      } = checklist.attributes?.userPermissions
      return (
        <Wrapper data-id={`checklists-${checklist.id}`} key={key}>
          <Box shadow="sm" p="4">
            <Stack>
              <Flex align="center" justify="flex-start">
                <Flex
                  align={['flex-start', 'center']}
                  direction={['column', 'row']}
                  w="100%"
                >
                  {hasPermissionToEditTask || hasPermissionToVisualizeTasks ? (
                    <Link
                      to={getRouteUrl('showChecklist', {
                        id: checklist.id
                      })}
                    >
                      <Heading
                        _hover={{ color: theme.colors.orange[700] }}
                        color="orange.500"
                        size="sm"
                        w="inherit"
                        isTruncated
                      >
                        {checklist.attributes.name}
                      </Heading>
                    </Link>
                  ) : (
                    <Heading
                      _hover={{ color: theme.colors.orange[700] }}
                      color="gray.500"
                      size="sm"
                      isTruncated
                      w="max-content"
                      cursor="not-allowed"
                    >
                      {checklist.attributes.name}
                    </Heading>
                  )}
                  <Flex align="center">
                    <Tag
                      ml={2}
                      mr={4}
                      h="20px"
                      colorScheme={
                        checklist.attributes?.active ? 'green' : 'gray'
                      }
                      size="sm"
                      minWidth="auto"
                    >
                      {checklist.attributes?.active ? 'Active' : 'Inactive'}
                    </Tag>
                    {hasPermissionToEditTask && (
                      <ChecklistKebab
                        id={checklist.attributes?.id}
                        size="sm"
                        hasPermissionToModifyChecklist={hasPermissionToEditTask}
                        hasPermissionToShowChecklist={
                          hasPermissionToEditTask ||
                          hasPermissionToVisualizeTasks
                        }
                        pageTitle={pageTitle}
                      />
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Stack>
          </Box>
        </Wrapper>
      )
    })
  )
}

ChecklistListItem.defaultProps = {
  checklists: [],
  pageTitle: 'Checklists'
}

export default ChecklistListItem
