import styled from '@emotion/styled'
import backgroundImage from '../../../assets/images/cxmp/central-station.jpg'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #eff3f6;
  background: linear-gradient(rgba(10, 10, 10, 0.4), rgba(140, 140, 140, 0.4)),
    url(${backgroundImage}) center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  & input,
  select,
  .chakra-checkbox__control:not([data-checked]) {
    background-color: #fff;
  }
`

export const LoginBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  max-width: 420px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  padding: 16px;
`

export const Logo = styled.img`
  width: 80px;
  height: 80px;
  position: relative;
  margin: 0 auto;
  margin-top: -50px;
`

export const Header = styled.div`
  width: 100%;
  font-size: 18px;
  padding: 15px 0;
`
