import { Heading, Tag, theme } from '@chakra-ui/react'
import { getRouteUrl } from 'helpers'
import { get, isEmpty, omit, pick, trim } from 'lodash'
import { mapStatusColor } from 'pages/Form/FillForm/ActionsBar/constants'
import React, { useCallback, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  usePageProperties,
  useQuery,
  useTableColumns,
  useTableFilter
} from '../../../hooks'
import OlingaAPI from '../../../services/OlingaAPI'
import RowActions from '../RowActions'

const hiddenColumnsKey = 'form_answers_persisted_columns.actions'

const useActions = ({ enumerators }) => {
  const { pageTitle, shouldCreate: hasPermissionToCreateActions } =
    usePageProperties({ pageName: 'actions' })

  const query = useQuery()

  const history = useHistory()

  const fieldsExtractor = useCallback((data) => {
    return data.map((row) => {
      const attributes = row?.attributes ?? {}
      const title = get(attributes, 'title')
      const status = get(attributes, 'status')
      const statusText = get(attributes, 'statusText')
      const formId = get(attributes, 'formId')
      const priorityText = get(attributes, 'priorityText')
      const id = get(attributes, 'id')
      const userPermissions = get(attributes, 'userPermissions')
      const { creatorEmail, creatorName } = {
        creatorEmail: get(attributes, 'creator.data.attributes.email'),
        creatorName: get(attributes, 'creator.data.attributes.name')
      }
      const { templateId } = {
        templateId: get(
          attributes,
          'templateQuestion.data.attributes.templateId'
        )
      }
      const templateQuestion = get(attributes, 'templateQuestion')
      return {
        title,
        status: <Tag colorScheme={mapStatusColor[status]}>{statusText}</Tag>,
        priority: priorityText,
        formId:
          formId && templateId ? (
            <Link to={getRouteUrl('formAnswer', { id: templateId, formId })}>
              <Heading
                color="orange.500"
                fontWeight="normal"
                size="sm"
                _hover={{ color: theme.colors.orange[700] }}
              >
                {formId}
              </Heading>
            </Link>
          ) : (
            <Tag colorScheme="gray">Empty</Tag>
          ),
        userPermissions,
        isAssociatedToATemplate: formId && templateQuestion?.data?.id,
        creator:
          creatorName || creatorEmail ? (
            creatorName ?? creatorEmail
          ) : (
            <Tag colorScheme="gray">Empty</Tag>
          ),
        id
      }
    })
  }, [])

  const initialParams = useMemo(() => {
    const params = isEmpty(query)
      ? {
          page: 1,
          per_page: 15
        }
      : pick(query, ['page', 'per_page', 'title', 'status', 'priority'])
    if (params?.status) {
      const statusValue = params?.status
      const enumerator = enumerators?.status?.find(
        ({ value }) => value === statusValue
      )
      if (enumerator) {
        params.status = enumerator
      }
    }
    if (params?.priority) {
      const priorityValue = params?.priority
      const enumerator = enumerators?.priorities?.find(
        ({ value }) => value === priorityValue
      )
      if (enumerator) {
        params.priority = enumerator
      }
    }
    return params
  }, [query, enumerators])

  const {
    refetch,
    isLoading,
    updateParams,
    fetchParams,
    tableData,
    pagination
  } = useTableFilter({
    id: null,
    fetchCallback: OlingaAPI.actionsList,
    fieldsExtractor,
    initialParams,
    onRefetch: useCallback(
      (newParams) => {
        history.replace(getRouteUrl('actions', newParams))
      },
      [history]
    )
  })

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Form ID',
        accessor: 'formId'
      },
      {
        Header: 'Title',
        accessor: 'title'
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'priority',
        accessor: 'priority'
      },
      {
        Header: 'Creator',
        accessor: 'creator'
      }
    ],
    hiddenColumnsKey,
    true,
    RowActions,
    (row) => {
      const {
        values: { id },
        original: {
          userPermissions: {
            view: hasPermissionToView,
            edit: hasPermissionToEdit
          }
        }
      } = row
      return { id, hasPermissionToView, hasPermissionToEdit }
    }
  )

  const onTextChange = useCallback(
    (updatedText) => {
      if (!updatedText || !trim(updatedText)?.length) {
        return refetch(omit(fetchParams, 'title'))
      }
      updateParams({ title: updatedText }, updatedText?.length >= 3)
    },
    [fetchParams, refetch, updateParams]
  )

  const onStatusChange = useCallback(
    (updatedStatus) => {
      if (!updatedStatus) {
        return refetch(omit(fetchParams, 'status'))
      }
      updateParams({ status: updatedStatus })
    },
    [fetchParams, refetch, updateParams]
  )

  const onPriorityChange = useCallback(
    (updatedPriority) => {
      if (!updatedPriority) {
        return refetch(omit(fetchParams, 'priority'))
      }
      updateParams({ priority: updatedPriority })
    },
    [fetchParams, refetch, updateParams]
  )

  return {
    hasPermissionToCreateActions,
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data: tableData,
    isLoading,
    refetch,
    updateParams,
    fetchParams,
    pagination,
    onTextChange,
    onPriorityChange,
    onStatusChange,
    pageTitle
  }
}

export default useActions
