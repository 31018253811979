import React from 'react'
import Date from '../Date'
import List from '../List'
import Number from '../Number'
import Text from '../Text'

const DynamicFormComponent = ({ field, index, hasPermissionToEdit }) => {
  const { name, field_type } = field
  const props = {
    name,
    field,
    index,
    hasPermissionToEdit
  }
  switch (field_type) {
    case 'number':
      return <Number {...props} />
    case 'text':
      return <Text {...props} />
    case 'list':
      return <List {...props} />
    case 'date':
      return <Date {...props} />
    default:
      return null
  }
}

export default DynamicFormComponent
