import { AddIcon, DragHandleIcon, EditIcon, ViewIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Stack,
  Switch,
  Tag,
  Text,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react'
import { capitalize, cloneDeep, get, isEmpty, set } from 'lodash'
import React, { useRef, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useFormContext } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import DeleteButton from '../../../../components/DeleteButton'
import EmptyState from '../../../../components/EmptyState'
import EditInline from '../../../../components/Inputs/EditInline'
import MaterialIcon from '../../../../components/MaterialIcon'
import Dropzone from '../../../../pages/TemplateEditor/Dropzone'
import Notification from '../../../../services/Notification'
import { iconsTransformer } from '../hooks/useAssetTemplateEditor'
import NotificationsModal from './NotificationsModal'
import useAssetTemplateFields from './hooks/useAssetTemplateFields'

const Fields = ({
  fieldTypes = [],
  id,
  isDisabled,
  allowFieldTypeEdition = false
}) => {
  const {
    fields,
    onRemoveField,
    onOpenMobileDrawer,
    register,
    errors,
    onEditField,
    renderKeys,
    setRenderKeys,
    onOpenSidebarModal
  } = useAssetTemplateFields({ id })

  const { setValue, getValues } = useFormContext()

  const fieldsRef = useRef({})

  const [isMobile] = useMediaQuery('(max-width: 799px)')

  const [attributeKey, setAttributeKey] = useState('')

  const {
    isOpen: isNotificationsModalOpen,
    onOpen: onOpenNotificationsModal,
    onClose: onCloseNotificationsModal
  } = useDisclosure()

  const onOpenDateNotificationsModal = (index) => {
    const daysBeforeNotify =
      getValues(`asset_template_fields_attributes.${index}.notify_days`) ?? '30'
    setAttributeKey(`asset_template_fields_attributes.${index}`)
    setValue(
      `asset_template_fields_attributes.${index}.notify_days`,
      daysBeforeNotify
    )

    onOpenNotificationsModal()
  }

  return (
    <>
      <NotificationsModal
        isOpen={isNotificationsModalOpen}
        onClose={onCloseNotificationsModal}
        attributeKey={attributeKey}
        onEditField={onEditField}
      />
      <Stack gap={2}>
        <Flex align="center" gap={4}>
          <Heading size="md">Fields</Heading>
          {isMobile && (
            <Button
              onClick={onOpenMobileDrawer}
              variant="ghost"
              size="sm"
              leftIcon={<AddIcon />}
              colorScheme="green"
              isDisabled={isDisabled}
            >
              Add field
            </Button>
          )}
        </Flex>
        <Divider />
        <Dropzone
          id="asset-templates-dropzone"
          type="root"
          isDisabled={isDisabled}
        >
          {isEmpty(fields) ? (
            <EmptyState text="There are no fields yet" />
          ) : (
            fields.map((field, index) => {
              const { name, field_type, uuid, main, asset_default_field_id } =
                field
              return (
                <Draggable
                  key={uuid}
                  draggableId={uuid}
                  index={index}
                  type="asset-templates-dropzone"
                  isDragDisabled={isDisabled || !!main}
                >
                  {(provided) => (
                    <Flex
                      justify={['flex-start', 'space-between']}
                      align={['flex-start', 'center']}
                      flexDirection={['column', 'row']}
                      shadow="sm"
                      _hover={{ shadow: 'md', borderColor: 'orange.100' }}
                      borderRadius="md"
                      borderWidth={1}
                      borderColor="gray.200"
                      p={4}
                      key={index}
                      gap={4}
                      position="relative"
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                    >
                      {main && (
                        <Tag
                          size="sm"
                          position="absolute"
                          bottom="4px"
                          right="14px"
                          colorScheme="green"
                        >
                          Required
                        </Tag>
                      )}
                      <Box
                        {...provided.dragHandleProps}
                        color={isDisabled ? 'gray.200' : 'gray.700'}
                      >
                        {!main && <DragHandleIcon />}
                      </Box>
                      <FormControl>
                        <FormLabel fontSize="sm">Name</FormLabel>
                        <Box pl={1.5}>
                          <EditInline
                            defaultValue={name}
                            width="100%"
                            registerProps={{
                              ...register(
                                `asset_template_fields_attributes.${index}.name`,
                                { required: true }
                              ),
                              onChange: ({ target: { value } }) => {
                                if (!value) {
                                  set(fieldsRef.current, `${index}.name`, name)
                                } else {
                                  setValue(
                                    `asset_template_fields_attributes.${index}.name`,
                                    value
                                  )
                                }
                              }
                            }}
                            onSubmit={(value) => {
                              const currentName = get(
                                fieldsRef.current,
                                `${index}.name`
                              )
                              if (!value) {
                                setValue(
                                  `asset_template_fields_attributes.${index}.name`,
                                  currentName
                                )
                                setRenderKeys((prev) => {
                                  const clonedPrev = cloneDeep(prev)
                                  clonedPrev.splice(index, 1, uuidv4())

                                  return clonedPrev
                                })
                                Notification({
                                  icon: 'error',
                                  text: `Name can't be blank`
                                })
                              } else {
                                setValue(
                                  `asset_template_fields_attributes.${index}.name`,
                                  value
                                )
                                onEditField(index, { name: value })
                              }
                            }}
                            isInvalid={
                              !!errors?.asset_template_fields_attributes?.[
                                index
                              ]?.name
                            }
                            isDisabled={isDisabled || asset_default_field_id}
                            placeholder="Field name"
                            key={renderKeys?.[index]}
                          />
                        </Box>
                      </FormControl>
                      <FormControl>
                        <FormLabel fontSize="sm">Type</FormLabel>
                        {allowFieldTypeEdition ? (
                          <Select
                            {...register(
                              `asset_template_fields_attributes.${index}.field_type`,
                              { required: true }
                            )}
                            onChange={({ target: { value } }) => {
                              const currentFieldType = get(
                                fieldsRef.current,
                                `${index}.field_type`
                              )
                              if (!value) {
                                setValue(
                                  `asset_template_fields_attributes.${index}.field_type`,
                                  field_type || currentFieldType
                                )
                                setRenderKeys((prev) => {
                                  const clonedPrev = cloneDeep(prev)
                                  clonedPrev.splice(index, 1, uuidv4())

                                  return clonedPrev
                                })
                                Notification({
                                  icon: 'error',
                                  text: `Field type can't be blank`
                                })
                              } else {
                                setValue(
                                  `asset_template_fields_attributes.${index}.field_type`,
                                  value
                                )
                                onEditField(index, { field_type: value })
                              }
                            }}
                            isInvalid={
                              !!errors?.asset_template_fields_attributes?.[
                                index
                              ]?.field_type
                            }
                            isDisabled={isDisabled}
                            placeholder="Field type"
                          >
                            {fieldTypes.map(({ label, value }, idx) => (
                              <option value={value} key={idx}>
                                {label}
                              </option>
                            ))}
                          </Select>
                        ) : (
                          <Flex align="center" gap={2}>
                            <MaterialIcon icon={iconsTransformer[field_type]} />
                            <Text>{capitalize(field_type)}</Text>
                            {field_type === 'list' && (
                              <Button
                                variant="ghost"
                                colorScheme="blue"
                                leftIcon={
                                  !asset_default_field_id ? (
                                    <EditIcon />
                                  ) : (
                                    <ViewIcon />
                                  )
                                }
                                size="sm"
                                ml={2}
                                onClick={() => onOpenSidebarModal(field)}
                                isDisabled={isDisabled}
                              >
                                {!asset_default_field_id
                                  ? 'Edit Options'
                                  : 'View Options'}
                              </Button>
                            )}
                          </Flex>
                        )}
                      </FormControl>
                      <Flex
                        gap={4}
                        justify="flex-end"
                        align="center"
                        minW="200px"
                        flexWrap="wrap"
                      >
                        {field_type === 'date' && (
                          <>
                            <Button
                              variant="ghost"
                              leftIcon={<MaterialIcon icon='doorbell' />}
                              colorScheme="orange"
                              onClick={() =>
                                onOpenDateNotificationsModal(index)
                              }
                            >
                              Notifications
                            </Button>
                          </>
                        )}
                        <Switch
                          {...register(
                            `asset_template_fields_attributes.${index}.active`
                          )}
                          mb="0!important"
                          minW="100px"
                          colorScheme="green"
                          onChange={({ target: { checked } }) =>
                            onEditField(index, { active: checked })
                          }
                          isDisabled={isDisabled}
                          isReadOnly={!!asset_default_field_id}
                        >
                          Active
                        </Switch>
                        {!main && (
                          <DeleteButton
                            index={index}
                            continueColor="red"
                            size="sm"
                            onDelete={onRemoveField}
                            isDisabled={isDisabled || !!(main && id)}
                          />
                        )}
                      </Flex>
                    </Flex>
                  )}
                </Draggable>
              )
            })
          )}
        </Dropzone>
      </Stack>
    </>
  )
}

export default Fields
