import { Checkbox } from '@chakra-ui/checkbox'
import { Flex, SimpleGrid, Text } from '@chakra-ui/layout'
import { Select } from '@chakra-ui/select'
import React, { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import NotificationsHandler from '..'
import { useTemplateEditorContext } from '../../../../contexts'
import { getNodeById } from '../../helpers'

const QuestionNotificationsHandler = ({
  id,
  control,
  notifyKey,
  selfFormNotifyKey,
  notificationsKey,
  notificationsUsersKey,
  deletedNotifiedUsersKey,
  getOptions,
  isInitiallyNotifying,
  questionType
}) => {
  const { initialDataRef } = useTemplateEditorContext()
  const { register } = useFormContext()
  const [isNotifying, notifiedUsers, deletedNotifiedUsers] = useWatch({
    name: [notifyKey, notificationsUsersKey, deletedNotifiedUsersKey],
    control,
    defaultValue: [isInitiallyNotifying, getOptions().notifiedUsers, []]
  })

  const getQuestionsOptions = () => {
    if (questionType === 'boolean') {
      return ['Yes', 'No'].map((option) => ({ label: option, order: null }))
    }
    return getOptions().options
  }

  const initiallyNotifiedUsers = useMemo(() => {
    const level = getNodeById(id, initialDataRef.current.attributes, [
      'template_questions_attributes',
      'section_questions_attributes'
    ])
    if (level) {
      return (
        level?.alert_trigger_attributes?.alert_triggers_users_attributes || []
      )
    }
    return []
  }, [id, initialDataRef])

  return (
    <>
      <SimpleGrid columns={{ sm: 2, md: 2 }}>
        <Flex direction="row" align="center">
          <Checkbox
            minW="200px"
            mb="0"
            {...register(notifyKey)}
            isChecked={isNotifying}
          >
            {isNotifying ? 'Disable' : 'Enable'} Notifications
          </Checkbox>
          <Flex align="center" width={'100%'}>
            <Text color="gray.500" size="md" mr="2">
              if:
            </Text>
            <Select
              {...register(`${notificationsKey}.value`)}
              placeholder="Select value"
            >
              {getQuestionsOptions().map((option, index) => {
                return <option key={index}>{option.label}</option>
              })}
            </Select>
          </Flex>
        </Flex>
      </SimpleGrid>
      <NotificationsHandler
        selfFormNotifyKey={selfFormNotifyKey}
        notificationsUsersKey={notificationsUsersKey}
        deletedNotifiedUsersKey={deletedNotifiedUsersKey}
        isNotifying={isNotifying}
        notifiedUsers={notifiedUsers}
        deletedNotifiedUsers={deletedNotifiedUsers}
        initiallyNotifiedUsers={initiallyNotifiedUsers}
      />
    </>
  )
}

export default QuestionNotificationsHandler
