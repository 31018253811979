import { EditIcon } from '@chakra-ui/icons'
import {
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  Stack
} from '@chakra-ui/react'
import { noop } from 'lodash'
import React from 'react'

const EditInline = ({
  placeholder,
  defaultValue,
  startWithEditView,
  fontSize,
  width,
  fontWeight,
  autoFocus,
  editButtonSize,
  registerProps,
  justify,
  isInvalid,
  onSubmit,
  isDisabled
}) => {
  return (
    <Editable
      placeholder={placeholder}
      defaultValue={defaultValue}
      startWithEditView={startWithEditView}
      fontSize={fontSize}
      w="100%"
      onSubmit={onSubmit}
      isDisabled={isDisabled}
      data-testid="editinline"
    >
      {(props) => (
        <Stack w={width}>
          <Flex
            justify={justify ? 'space-around' : 'flex-start'}
            align="center"
            gap={4}
          >
            <EditablePreview
              width={width}
              fontWeight={fontWeight}
              color={isInvalid ? 'red.300' : 'black'}
            />

            <EditableInput
              data-testid="editinline-input"
              autoFocus={autoFocus}
              width={width}
              {...registerProps}
            />
            {!isDisabled && (
              <IconButton
                data-testid="editinline-edit-button"
                px="3"
                size={editButtonSize}
                icon={<EditIcon />}
                onClick={props.onEdit}
              />
            )}
          </Flex>
        </Stack>
      )}
    </Editable>
  )
}

EditInline.defaultProps = {
  placeholder: '',
  defaultValue: [],
  width: '90%',
  fontWeight: 'bold',
  autoFocus: true,
  editButtonSize: 'sm',
  registerProps: {},
  justify: true,
  isInvalid: false,
  onSubmit: noop,
  isDisabled: false
}

export default EditInline
