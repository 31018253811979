import {Tag} from '@chakra-ui/react'
import {get} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTableColumns} from '../../../../../hooks'
import AdminOlingaAPI from '../../../../../services/admin/AdminOlingaAPI'
import EditAssetsButton from '../../EditAssetsButton'

export const hiddenColumnsKey = 'persisted_columns.asset_types'

const columnsMapper = ({ original: { id } }) => ({ id })

const useAssetTypes = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({ pageCount: 0, totalItems: 0 })

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Total Businesses',
        accessor: 'totalBusinesses'
      },
      {
        Header: 'Active',
        accessor: 'active'
      }
    ],
    hiddenColumnsKey,
    true,
    EditAssetsButton,
    columnsMapper,
    ['id']
  )

  const fetchAssets = useCallback(
    async (params = { page: 1, per_page: 15 }) => {
      setIsLoading(true)
      try {
        const response = await AdminOlingaAPI.assetTypes(params)
        const {
          data,
          meta: { last: pageCount, count: totalItems }
        } = response
        setData(data)
        setPagination({ pageCount, totalItems })
      } catch (error) {
        setData([])
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  const tableData = useMemo(() => {
    return data.map((row) => {
      const attributes = row?.attributes ?? {}
      const id = row.id
      const name = get(attributes, 'name')
      const active = get(attributes, 'active')
      const totalBusinesses = get(attributes, 'total_businesses')
      return {
        name,
        active: (
          <Tag colorScheme={active ? 'green' : 'gray'}>
            {active ? 'Active' : 'Inactive'}
          </Tag>
        ),
        totalBusinesses: !totalBusinesses ? (
          <Tag colorScheme="gray">Empty</Tag>
        ) : (
          <Tag colorScheme="blue">{`${totalBusinesses} ${
            totalBusinesses === 1 ? 'business' : 'businesses'
          }`}</Tag>
        ),
        id
      }
    })
  }, [data])

  useEffect(() => {
    fetchAssets()
  }, [fetchAssets])

  return {
    fetchAssets,
    columns,
    hiddenColumns,
    pagination,
    data: tableData,
    isLoading
  }
}

export default useAssetTypes
