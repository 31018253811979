import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react'
import RelatedQuestionsStep from './RelatedQuestionsStep'
import TemplateStep from './TemplateStep'

const ReportEditorForm = ({
  headerQuestions,
  bodyQuestions,
  reportType,
  contexts,
  counterType,
  tabIndex,
  setTabIndex,
  formFieldKey,
  structure
}) => {
  return (
    <Tabs index={tabIndex} onChange={setTabIndex}>
      <TabList>
        <Tab>Template settings</Tab>
        <Tab isDisabled={tabIndex !== 1}>Related Questions</Tab>
      </TabList>
      <TabPanels px={4} py={2}>
        <TabPanel>
          <TemplateStep
            contexts={contexts}
            reportType={reportType}
            headerQuestions={headerQuestions}
            bodyQuestions={bodyQuestions}
            formFieldKey={formFieldKey}
            structure={structure}
          />
        </TabPanel>
        <TabPanel>
          <RelatedQuestionsStep
            headerQuestions={headerQuestions}
            bodyQuestions={bodyQuestions}
            formFieldKey={formFieldKey}
            counterType={counterType}
            structure={structure}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

ReportEditorForm.displayName = 'ReportEditorForm'

ReportEditorForm.defaulProps = {
  shouldDisableFurtherTabs: true
}

export default ReportEditorForm
