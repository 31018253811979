import { ChevronDownIcon } from '@chakra-ui/icons'
import { Flex, HStack, Tag, Text } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import React from 'react'
import GroupKebab from '../../../../pages/Group/GroupKebab'

const GroupTag = ({ groups, hasPermissionsToModifyGroup }) => {
  if (isEmpty(groups.data)) {
    return (
      <Tag w="max-content" colorScheme="gray">
        No group
      </Tag>
    )
  }
  return (
    <Flex align="center" gap={4}>
      {groups.data.map(({ attributes: { name, id } }) => {
        return (
          <Tag colorScheme="orange" key={id} maxW="max-content">
            <HStack align="center" spacing={2}>
              <Text>{name}</Text>
              {hasPermissionsToModifyGroup && (
                <GroupKebab
                  size="xs"
                  icon={<ChevronDownIcon />}
                  hasBorder={false}
                  id={id}
                />
              )}
            </HStack>
          </Tag>
        )
      })}
    </Flex>
  )
}

export default GroupTag
