import { cloneDeep, defer, isArray, isEmpty } from 'lodash'
import { useQuestionActions } from 'pages/Form/FillForm/ActionsContext'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { CREATE_FORM_VALUES, RENDER_FORM_TYPES } from '../../constants'

const { NOTES, ACTION, FILES } = RENDER_FORM_TYPES

const useActionsBar = ({
  initialActions,
  template,
  fieldType,
  field,
  currentKey
}) => {
  const [shouldRenderActionForm, setShouldRenderActionForm] = useState(false)
  const [shouldRenderNotesForm, setShouldRenderNotesForm] = useState(false)
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const { setValue, watch } = useFormContext()
  const { setOpenedAction, onOpen, setChildIndex, setCurrentQuestion } =
    useQuestionActions()
  const [actionFormValues, setActionFormValues] = useState({})

  const actions = watch(`${currentKey}.actions`, initialActions)
  const notes = watch(`${currentKey}.notes`, null)
  const files = watch(`${currentKey}.files`, [])

  const shouldRenderFilesForm = watch(
    `${currentKey}.shouldRenderFilesForm`,
    false
  )

  useEffect(() => {
    return () => {
      setShouldRenderActionForm(false)
      setShouldRenderNotesForm(false)
      setIsEditingNotes(false)
    }
  }, [])

  const handleSelectAction = (action, index) => {
    setChildIndex(index)
    setCurrentQuestion(field)
    defer(() => {
      setOpenedAction(action)
      onOpen()
    })
  }

  const handleCreateAction = (data) => {
    const currentActions = isArray(actions) ? cloneDeep(actions) : []
    const newAction = {
      ...data,
      status: 'to_do',
      template,
      fieldType,
      priority: 'medium',
      due_date: '',
      user_ids: [],
      currentKey
    }
    currentActions.push(newAction)
    setValue(`${currentKey}.actions`, currentActions)

    setShouldRenderActionForm(false)

    handleSelectAction(newAction, currentActions.length - 1)
  }

  const handleChangeNotes = (data) => {
    setValue(`${currentKey}.notes`, { currentKey, title: data.title })
    setShouldRenderActionForm(false)
    setShouldRenderNotesForm(false)
    setIsEditingNotes(false)
  }

  const handleFormRender = (type) => {
    switch (type) {
      case ACTION:
        setActionFormValues({
          ...CREATE_FORM_VALUES[type],
          onSuccess: handleCreateAction,
          onCancel: () => setShouldRenderActionForm(false)
        })
        defer(() => {
          setShouldRenderActionForm(true)
        })
        break
      case NOTES:
        setActionFormValues({
          ...CREATE_FORM_VALUES[type],
          onSuccess: handleChangeNotes,
          onCancel: () => setShouldRenderNotesForm(false)
        })
        defer(() => {
          setShouldRenderNotesForm(true)
        })
        break
      case FILES:
        defer(() => {
          setValue(
            `${currentKey}.shouldRenderFilesForm`,
            !shouldRenderFilesForm
          )
        })
        break
      default:
        break
    }
  }

  const handleNotesControl = () => {
    if (isEmpty(notes)) {
      handleFormRender(NOTES)
    } else {
      setIsEditingNotes(true)
    }
  }

  const handleFilesChange = (currentFiles) => {
    setValue(`${currentKey}.files`, currentFiles)
  }

  return {
    handleFilesChange,
    handleNotesControl,
    handleFormRender,
    handleCreateAction,
    handleChangeNotes,
    handleSelectAction,
    shouldRenderActionForm,
    shouldRenderFilesForm,
    shouldRenderNotesForm,
    files,
    notes,
    actions,
    actionFormValues,
    isEditingNotes,
    setIsEditingNotes
  }
}

export default useActionsBar
