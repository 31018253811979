/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputRightAddon,
  Popover,
  PopoverTrigger,
  Stack,
  Text
} from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { chakraComponentsSizes } from '../../helpers'
import AutocompleteResults from './Results'
import { Wrapper } from './styles'
import useAutocomplete from './useAutocomplete'

const Autocomplete = ({
  searchInterval,
  asyncCallback,
  size,
  minValueLength,
  placeholder,
  attributeName,
  onSelect,
  resultEndAdornment,
  groupByAttribute,
  getNestedAttribute,
  shouldAutofocus,
  inputId
}) => {
  const {
    handleSelect,
    changeInput,
    inputRef,
    containerRef,
    isGrouped,
    isOpen,
    onOpen,
    onClose,
    results,
    isLoading
  } = useAutocomplete({
    groupByAttribute,
    asyncCallback,
    getNestedAttribute,
    searchInterval,
    onSelect
  })

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      closeOnBlur={false}
      initialFocusRef={inputRef}
      containerRef={containerRef}
    >
      <PopoverTrigger>
        <Wrapper data-id="autocomplete" data-testid='autocomplete'>
          <Stack>
            <InputGroup>
              <Input
                onChange={changeInput}
                size={size}
                placeholder={placeholder}
                ref={inputRef}
                data-testid='autocomplete-input'
                autoFocus={shouldAutofocus}
                color="black"
                id={inputId}
              />
              {!isLoading && !isEmpty(results) && (
                <InputRightAddon cursor="pointer" onClick={onOpen}>
                  {isOpen ? (
                    <ChevronUpIcon color="gray" size="sm" />
                  ) : (
                    <ChevronDownIcon color="gray" size="sm" />
                  )}
                </InputRightAddon>
              )}
            </InputGroup>
            <Flex justify="flex-end">
              <Text fontSize="xs" color="gray">
                Type at least {minValueLength} characters
              </Text>
            </Flex>
            <Divider />
            {isLoading && (
              <Button
                colorScheme="blue"
                isDisabled
                isLoading
                loadingText="Loading"
              />
            )}
            {!isEmpty(results) && (
              <AutocompleteResults
                isGrouped={isGrouped}
                results={results}
                attributeName={attributeName}
                handleSelect={handleSelect}
                resultEndAdornment={resultEndAdornment}
                onClose={onClose}
              />
            )}
          </Stack>
        </Wrapper>
      </PopoverTrigger>
    </Popover>
  )
}

Autocomplete.defaultProps = {
  searchInterval: 500,
  size: 'md',
  minValueLength: 3,
  placeholder: '',
  attributeName: 'name',
  onSelect: () => ({}),
  resultEndAdornment: () => null,
  minWidth: '300px',
  groupByAttribute: '',
  getNestedAttribute: '',
  shouldAutofocus: false,
  inputId: 'autocomplete-input'
}

Autocomplete.propTypes = {
  searchInterval: PropTypes.number,
  asyncCallback: PropTypes.func,
  size: PropTypes.oneOf(chakraComponentsSizes),
  minValueLength: PropTypes.number,
  placeholder: PropTypes.string,
  attributeName: PropTypes.string,
  onSelect: PropTypes.func,
  resultEndAdornment: PropTypes.func,
  minWidth: PropTypes.string,
  groupByAttribute: PropTypes.string,
  getNestedAttribut: PropTypes.string,
  dataIdPrefix: PropTypes.string,
  inputId: PropTypes.string,
  shouldAutofocus: PropTypes.bool
}

export default Autocomplete
