import {
  Container,
  Divider,
  Fade,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  SimpleGrid,
  Stack,
  Switch,
  Tag,
  Text,
  theme
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import ImageInput from 'components/Inputs/ImageInput'
import MaterialIcon from 'components/MaterialIcon'
import { get, isEmpty } from 'lodash'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import Dialog from '../../../components/AlertDialog'
import EmptyState from '../../../components/EmptyState'
import FormSubmitActions from '../../../components/FormSubmitActions'
import PageHeader from '../../../components/PageHeader'
import AssetEditorBreadcrumb from './AssetEditorBreadcrumb'
import AssetResponses from './AssetResponses'
import DynamicFormComponent from './components/DynamicFormComponent'
import useAssetEditor from './hooks/useAssetEditor'

const AssetEditor = ({ data, id, shouldDisplayActiveToggle = false }) => {
  const {
    assetTypes,
    formMethods,
    onSelectAssetType,
    shouldDisplayAssetTypeForm,
    onSubmitForm,
    isLoading,
    isPerformingDialogConfirmAction,
    isFetchingAssetTypes,
    changeAssetTypeDialogRef,
    onConfirmAssetTypeChange,
    onCancelDialog,
    hasPermissionToEdit
  } = useAssetEditor({ data, id })

  const {
    register,
    setValue,
    watch,
    formState: { errors }
  } = formMethods

  const [active, assetType, fields, image] = watch([
    'active',
    'asset_template_id',
    'asset_fields_attributes',
    'attachment_attributes.media'
  ])

  return (
    <>
      <Dialog
        ref={changeAssetTypeDialogRef}
        header="Confirm this action"
        body="By proceeding, all fields that were already created will be inactivated."
        continueColor="red"
        continueLabel="Confirm"
        onContinue={onConfirmAssetTypeChange}
        handleClose={onCancelDialog}
        isLoading={isPerformingDialogConfirmAction}
      />
      <Container maxW="1200px" pb="200px">
        <PageHeader
          Breadcrumb={
            <AssetEditorBreadcrumb
              id={id}
              assetType={assetType}
              templateId={get(data, 'asset.data.attributes.asset_template_id')}
              hasPermissionToEdit={hasPermissionToEdit}
            />
          }
          title={
            id && hasPermissionToEdit
              ? 'Edit Asset'
              : id && !hasPermissionToEdit
              ? 'View Asset'
              : 'New Asset'
          }
        />
        <Stack w="100%" gap={4}>
          <Stack w="100%">
            <Flex align="center" gap={4} w="100%" mb="8">
              <ImageInput
                onChange={(imageFile) =>
                  setValue('attachment_attributes.media', imageFile)
                }
                value={image}
                isInvalid={!!errors?.attachment_attributes?.media}
                shouldDisplayPlaceholder={id && !image}
              />
              <FormControl>
                <FormLabel>Asset type *</FormLabel>
                <Select
                  options={assetTypes}
                  placeholder="Asset type"
                  {...register('asset_template_id', { required: true })}
                  value={assetType}
                  onChange={onSelectAssetType}
                  isInvalid={!!errors?.asset_template_id?.message}
                  key={assetType?.value}
                  isDisabled={
                    isFetchingAssetTypes || id || !hasPermissionToEdit
                  }
                />
              </FormControl>

              <Switch
                colorScheme="green"
                mb="0!important"
                {...register('active')}
                isChecked={active}
                minW="100px"
                mt={[0, 8]}
                isReadOnly={!hasPermissionToEdit}
              >
                Active
              </Switch>
            </Flex>
          </Stack>
          <FormProvider {...formMethods}>
            <SimpleGrid columns={[1, id ? 2 : 1]} spacing={4}>
              {shouldDisplayAssetTypeForm ? (
                <Fade in={shouldDisplayAssetTypeForm} h="100%">
                  <Stack
                    p={4}
                    borderWidth={1}
                    borderColor="gray.200"
                    borderRadius={2}
                    gap={2}
                    flex={1}
                    h="100%"
                  >
                    <Flex gap={2} align="center">
                      <Heading size="sm">Fields</Heading>
                      <Tag
                        size="sm"
                        px={4}
                        colorScheme={fields?.length ? 'blue' : 'gray'}
                      >
                        {fields?.length ? fields?.length : 'Empty'}
                      </Tag>
                    </Flex>
                    <Divider />
                    {isEmpty(fields) ? (
                      <EmptyState text="There are no fields for this asset type" />
                    ) : (
                      fields.map((field, index) => {
                        return (
                          <FormControl key={index}>
                            <Flex
                              justify="space-between"
                              align="center"
                              flexWrap="wrap"
                            >
                              <FormLabel>
                                <Flex gap={2}>
                                  {field?.name}{' '}
                                  {field?.main && <Text color="red">*</Text>}
                                </Flex>
                              </FormLabel>
                              {shouldDisplayActiveToggle && (
                                <Switch
                                  size="sm"
                                  colorScheme="green"
                                  {...register(
                                    `asset_fields_attributes.${index}.active`
                                  )}
                                  isReadOnly={!hasPermissionToEdit}
                                >
                                  Active
                                </Switch>
                              )}
                            </Flex>
                            <DynamicFormComponent
                              field={field}
                              index={index}
                              hasPermissionToEdit={hasPermissionToEdit}
                            />
                          </FormControl>
                        )
                      })
                    )}
                  </Stack>
                </Fade>
              ) : (
                <Stack
                  align="center"
                  justify="center"
                  h="100%"
                  minH="300px"
                  borderRadius="md"
                  borderWidth={1}
                  borderColor="gray.200"
                >
                  <Stack align="center">
                    <Flex
                      color="purple"
                      position="relative"
                      bg="purple.100"
                      borderRadius="100%"
                      justifyContent='center'
                      alignItems= 'center'
                      height='36px'
                      width='36px'
                    >
                      <MaterialIcon icon='ads_click' />
                    </Flex>
                    <Heading size="sm" textAlign="center" maxW="70%">
                      Please, select an asset type to display its fields
                    </Heading>
                  </Stack>
                </Stack>
              )}
              {id && <AssetResponses id={id} />}
            </SimpleGrid>
          </FormProvider>
          {hasPermissionToEdit && (
            <Flex
              justify="flex-end"
              w="100vw"
              position="fixed"
              bottom={0}
              left={0}
              px={8}
              py={4}
              bg="white"
              shadow="md"
              borderTopWidth={1}
              borderTopColor="gray.200"
              zIndex={theme.zIndices.sticky}
            >
              <FormSubmitActions
                cancelRoute="assets"
                onSubmit={onSubmitForm}
                isLoading={isLoading}
              />
            </Flex>
          )}
        </Stack>
      </Container>
    </>
  )
}

export default AssetEditor
