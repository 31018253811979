import { Heading, Text } from '@chakra-ui/react'
import { capitalize, isArray } from 'lodash'
import React from 'react'
import AsyncContentModal from '../../../components/AsyncContentModal'
import List from '../../../components/List'
import { getPrefixes } from '../../../helpers'
import OlingaAPI from '../../../services/OlingaAPI'

const FoldersModal = ({
  id,
  count,
  documentName,
}) => {
  const getHeader = (document) => {
    const foldersData = document?.attributes?.folders?.data;
    if (isArray(foldersData)) {
      const { before, after } = getPrefixes(count, 'folder')
      const getText = () => {
        if (!count || count === 0) {
          return 'There are no folder for this documnent'
        }
        return `${capitalize(before)} ${
          foldersData.length === 1 ? 'only' : foldersData.length
        } ${after} of document ${documentName}`
      }

      return <Heading size="md">{getText()}</Heading>
    }
    return null
  }



  const getItemBody = (item) => {
    return (
      <Text fontSize="md" color="gray.700">
        {item.name ?? `Folder with id ${item.id}`}
      </Text>
    )
  }

  const getBody = (document) => {
    const foldersData = document?.attributes?.folders?.data;
    if (isArray(foldersData)) {
      const names = foldersData.map(({ attributes: { name, id }}) => ({
        name,
        id
      }))
      return <List getItemBody={getItemBody} items={names} />
    }
    return null
  }

  return (
    <AsyncContentModal
      getHeader={getHeader}
      getBody={getBody}
      id={id}
      count={count}
      fetchCallback={OlingaAPI.documentFolders}
    />
  )
}

FoldersModal.defaultProps = {
  customButtonLabel: ''
}

export default FoldersModal
