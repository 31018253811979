import { useMediaQuery } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { setFormErrors } from '../../../helpers'
import Notification from '../../../services/Notification'
import OlingaAPI from '../../../services/OlingaAPI'

const defaultValues = {
  current_password: '',
  password: '',
  password_confirmation: ''
}

const useChangePassword = () => {
  const {
    handleSubmit,
    reset,
    control,
    register,
    setError,
    formState: { errors: formErrors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(yup.object({}))
  })
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onResetForm = () => {
    reset(defaultValues)
  }

  const onSubmit = async (submitData) => {
    setErrors([])
    if (submitData.password !== submitData.password_confirmation) {
      return Notification({
        icon: 'error',
        text: 'Password and password confirmation do not match'
      })
    }

    setIsLoading(true)
    try {
      await OlingaAPI.editUserPassword(submitData)
      Notification({
        icon: 'success',
        text: 'Password updated successfully'
      })

      onResetForm()
      setIsLoading(false)
    } catch (error) {
      setFormErrors(error, setError, Notification)
    } finally {
      setIsLoading(false)
    }
  }

  const onError = (error) => {
    setErrors(error)
  }

  const [isMobile] = useMediaQuery('(max-width: 730px)')

  return {
    isMobile,
    onError,
    onSubmit,
    handleSubmit,
    control,
    register,
    errors,
    isLoading,
    onResetForm,
    formErrors
  }
}

export default useChangePassword
