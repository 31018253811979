import { get } from 'lodash'

const isOwner = (user) => user?.roles?.includes('owner')

export const getPermissions = (user) => ({
  hasPermissionsToModifyGroup:
    user?.roles?.includes('group_modify') || isOwner(user),
  hasPermissionsToModifyTemplate:
    user?.roles?.includes('template_modify') ||
    user?.roles?.includes('template_modify_all') ||
    isOwner(user),
  hasPermissionToModifyFolder:
    user?.roles?.includes('folder_modify') || isOwner(user),
  hasPermissionToModifyDocument:
    user?.roles?.includes('document_modify') || isOwner(user),
  hasPermissionToModifyAction:
    user?.roles?.includes('action_modify') || isOwner(user),
  hasPermissionToModifyTemplateReport:
    user?.roles?.includes('report_modify') || isOwner(user),
  hasPermissionToCreateChecklist:
    isOwner(user) || user?.roles?.includes('checklist_modify_all'),
  hasPermissionToViewScheduledForms:
    user?.roles?.includes('scheduled_frequency_setting_view') || isOwner(user),
  hasPermissionToModifyScheduledForms:
    user?.roles?.includes('scheduled_frequency_setting_modify') || isOwner(user)
})

export const getPermission = (user, key) => get(user?.permissions, key)
