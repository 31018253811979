import { Box } from '@chakra-ui/react'
import { getRouteUrl } from 'helpers'
import { isEmpty } from 'lodash'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Notification from 'services/Notification'
import TemplateEditorProvider from '../../../contexts/TemplateEditor'
import Form from '../Form'
import TemplateSkeleton from '../Form/TemplateSkeleton'
import FormActions from '../FormActions'

const TemplateForm = ({
  data,
  pageTitle,
  singularTitle,
  hasHeader,
  top = '250px'
}) => {
  const history = useHistory()

  if (isEmpty(data)) {
    return <TemplateSkeleton />
  }

  if (isEmpty(data?.folders)) {
    history.push(getRouteUrl('newFormFolder'))
    return Notification({
      icon: 'error',
      text: 'This business has no folders. Please add a folder to create a new form'
    })
  }

  const { template } = data

  return (
    <TemplateEditorProvider
      data={data}
      pageTitle={pageTitle}
      singularTitle={singularTitle}
    >
      <Box as="section" w="100%" position="relative">
        <Box w={{ sm: '100%', md: 'calc(100% - 60px)' }} overflowY="auto" m={0}>
          <Form hasHeader={hasHeader} />
        </Box>
        <Box w="100%" h="100%" position="relative">
          <FormActions top={top} template={template} />
        </Box>
      </Box>
    </TemplateEditorProvider>
  )
}

TemplateForm.defaultProps = {
  hasHeader: true
}

export default TemplateForm
