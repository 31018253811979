/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import logo from '../../../../assets/images/cxmp/logo_orange.png';
import HomeHeader from '../../Home/HomeHeader';
import { Header, LoginBox, Logo } from '../../Home/styles';
import SignIn from './SignIn';
import { Container } from './styles';

const HomePage = ({ props }) => {
  return (
    <Container>
      <LoginBox>
        <Logo src={logo}/>
        <Header>
          <HomeHeader />
        </Header>
        <SignIn {...props} />
      </LoginBox>
    </Container>
  )
}

export default HomePage
