import {
  Box,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Popover,
  PopoverBody,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Switch,
  Tag,
  Text,
} from '@chakra-ui/react';
import { differenceBy, isEmpty, orderBy, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import IconBadge from 'components/IconBadgeButton';
import MaterialIcon from 'components/MaterialIcon';
import { toast } from 'react-toastify';
import OlingaAPI from 'services/OlingaAPI';
import colors from 'utils/colors';
import useActionCable from '../../../hooks/useActionCable';
import NotificationCard from './NotificationCard';
import { getActionFunc } from './NotificationCard/helpers';
import { Body, Content, Footer } from './styles';

const Notifications = () => {
  const [hasFilter, setHasFilter] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getNotifications = async () => {
    setIsLoading(true)
    const response = await OlingaAPI.getNotifications()
    if (response.data) {
      setNotifications(response.data);
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getNotifications();
  }, []);

  const getBadgeColor = () => {
    if (notifications?.length >= 1) {
      return '#3ba87e';
    }

    return 'transparent';
  };

  const link = '';

  const { received } = useActionCable({
    openChannel: true,
    channel: 'NotificationChannel',
  });

  useEffect(() => {
    if (received?.data) {
      const currentNotifications = notifications;
      const { data } = received;
      currentNotifications.unshift({ id: String(data.id), attributes: data });
      setNotifications([...currentNotifications]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [received]);

  const sortNotifications = (items) =>
    orderBy(items, (item) => new Date(item?.attributes?.createdAt || item?.createdAt), ['desc']);

  const onComplete = (dataItems) => {
    const normalizedItems = dataItems?.map((n) => ({
      id: n.id,
      ...n.attributes,
    }));
    const updatedNotifications = differenceBy(notifications, normalizedItems, 'id');
    dataItems.map((item) => updatedNotifications.push(item));
    const sortedNotifications = sortNotifications(updatedNotifications);
    setNotifications(sortedNotifications);
  };

  const updateReadOn = async (ids) => {
    try {
      const response = await OlingaAPI.notificationMarkAsRead(ids)
      if (response.status === 200) {
        const { data } = response
        onComplete(data?.data || []);
      }
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const onMarkAllAsRead = () => {
    if (!groupedNotifications?.unread?.length) {
      return;
    }
    const notificationsIds = groupedNotifications?.unread?.map((n) => n.id);
    updateReadOn(notificationsIds);
  };

  const onCardClick = ({ data, id }) => {
    updateReadOn([id]);
    const action = getActionFunc();
    action({
      url: data.fileUrl,
      id: data?.id,
    }).then?.();
  };

  const groupedNotifications = useMemo(() => {
    const result = { read: [], unread: [] };
    const sortedNotifications = uniqBy(sortNotifications(notifications), 'id');
    
    // eslint-disable-next-line array-callback-return
    sortedNotifications?.map((n) => {
      const attributes = {
        id: n.id,
        ...n.attributes,
      };

      const key = attributes.readOn ? 'read' : 'unread';
      result[key].push(attributes);
    });

    return result;
  }, [notifications]);

  const getContent = (key) => (
    <>
      <Flex style={{ textTransform: 'capitalize' }} align="center" justifyContent="space-between">
        {key}
        <Divider w="80%" />
      </Flex>
      {!isEmpty(groupedNotifications[key]) ? (
        <>
          {groupedNotifications[key]?.map((notification) => (
            <div key={notification.id}>
              <NotificationCard notification={notification} onCardClick={onCardClick} />
            </div>
          ))}
        </>
      ) : (
        <Center h="90px">
          <Text color="black">No {key} notifications</Text>
        </Center>
      )}
    </>
  );

  return (
    <Popover placement="bottom-start" closeOnBlur>
      <PopoverTrigger>
        <div>
          <IconBadge
            variant='ghost'
            value={groupedNotifications?.unread?.length || ''}
            Icon={<MaterialIcon icon='notifications' />}
            badgeColor={getBadgeColor()}
          />
        </div>
      </PopoverTrigger>
      <Content>
        <PopoverHeader>
          <Flex justifyContent="space-between">
            <Box style={{ fontWeight: 'bold' }}>
              <a href={link}>Notifications</a>
            </Box>
            <FormControl w='auto' display='flex' alignItems='center' gap={1}>
              <Switch
                onChange={() => setHasFilter((prevValue) => !prevValue)}
                size="md"
                isChecked={hasFilter}
              />
              <FormLabel m='0px'>
                Only show unread
              </FormLabel>
            </FormControl>
          </Flex>
        </PopoverHeader>
        <PopoverBody>
          <Body gap={2} flexDir="column">
            {isLoading ? (
              <Center mt={10}>
                <Spinner size="lg" color="blue" />
              </Center>
            ) : (
              <>
                {getContent('unread')}
                {!hasFilter && getContent('read')}
              </>
            )}
          </Body>
        </PopoverBody>
        <Footer maincolor={colors.primary}>
          <Flex justifyContent="space-between">
            <Tag size="lg" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={onMarkAllAsRead}>
              Mark all as read
            </Tag>
          </Flex>
        </Footer>
      </Content>
    </Popover>
  );
};

export default Notifications;
