import React from 'react'
import BasePage from '../../../pages/BasePage'
import TemplateStructure from '../../../pages/TemplateStructure'
import OlingaAPI from '../../../services/OlingaAPI'

const TemplateStructurePage = ({ id }) => {
  return (
    <BasePage
      id={id}
      resourceName="template"
      fetchCallback={OlingaAPI.templateStructure}
    >
      <TemplateStructure />
    </BasePage>
  )
}

export default TemplateStructurePage
