import { ChevronDownIcon } from '@chakra-ui/icons'
import { HStack, Tag, Text } from '@chakra-ui/react'
import React from 'react'
import FormFolderKebab from '../../../../pages/FormFolder/FormFolderKebab'

const FolderTag = ({
  formFolderId,
  hasPermissionToModifyFolder,
  formFolderName
}) => {
  if (formFolderId) {
    if (hasPermissionToModifyFolder) {
      return (
        <Tag colorScheme="blue" maxW="max-content">
          <HStack align="center" spacing={2}>
            <Text>{formFolderName}</Text>
            <FormFolderKebab
              size="xs"
              icon={<ChevronDownIcon />}
              hasBorder={false}
              id={formFolderId}
            />
          </HStack>
        </Tag>
      )
    }
    return <Text>{formFolderName}</Text>
  }
  return (
    <Tag w="max-content" colorScheme="gray">
      No folder
    </Tag>
  )
}

export default FolderTag
