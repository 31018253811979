import { getRouteUrl, groupNormalizedList, setFormErrors } from 'helpers'
import { usePageProperties } from 'hooks'
import { differenceBy, isString, uniqBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Notification from 'services/Notification'
import OlingaAPI from 'services/OlingaAPI'
import { formatSummaryReportInitialData } from '../../helpers'

const formDefaultValues = {
  template_report: {
    template_report_templates_attributes: [],
    template_report_templates_attributes_deleted: [],
    fields: {
      title: '',
      frequency: null,
      emails: []
    }
  }
}

const useSummaryReport = ({ data, id }) => {
  const history = useHistory()
  const templates = data?.templates ?? []

  const defaultValues = useMemo(() => {
    return formatSummaryReportInitialData(data?.report, {
      ...data?.enumerators,
      templates: data.templates
    })
  }, [data])

  const {
    shouldRender: shouldRenderSummaryReports,
    shouldCreate: shouldModifySummaryReports
  } = usePageProperties({
    pageName: 'summary_reports'
  })

  const isReadOnly = shouldRenderSummaryReports && !shouldModifySummaryReports

  const [isLoading, setIsLoading] = useState(false)

  const { templatesList } = useMemo(() => {
    return {
      templatesList: groupNormalizedList(
        (data?.templates ?? []).map((item) => ({
          ...item,
          group: item.folderName
        }))
      ).sort((a, b) => {
        if (a.label > b.label) return 1
        if (a.label < b.label) return -1
        return 0
      })
    }
  }, [data?.templates])

  const methods = useForm({
    defaultValues: formDefaultValues
  })

  const { handleSubmit, setError, reset, setValue, getValues } = methods

  useEffect(() => {
    reset(defaultValues)

    return () => {
      setIsLoading(false)
    }
  }, [defaultValues, reset])

  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true)
      const endpoint = id ? 'updateReport' : 'createReport'
      const { frequency } = data.template_report.fields
      const formattedData = {
        template_report: {
          template_report_templates_attributes: uniqBy(
            [
              ...(
                data?.template_report?.template_report_templates_attributes ??
                []
              ).map((item) => ({
                template_id: item.value,
                id: item.id
              })),
              ...(
                data?.template_report
                  ?.template_report_templates_attributes_deleted ?? []
              ).map((item) => ({
                template_id: item.template_id,
                id: item.id
              }))
            ],
            'template_id'
          ).map((item) => ({
            ...item,
            _destroy: !!getValues(
              'template_report.template_report_templates_attributes_deleted'
            ).find(({ template_id }) => item.template_id === template_id)
          })),
          report_type: 'summary',
          fields: {
            ...data.template_report.fields,
            emails: data.template_report.fields?.emails.map((email) =>
              isString(email) ? email : email?.email || email?.label
            ),
            frequency: frequency?.value
          }
        }
      }
      const response = await OlingaAPI[endpoint]('summary', formattedData, id)
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: response?.data?.message || 'Report updated successfully'
        })

        if (endpoint === 'createReport') {
          history.push(
            getRouteUrl('templateReports', { reportType: 'summary' })
          )
        } else {
          reset(
            formatSummaryReportInitialData(response?.data?.data, {
              ...data?.enumerators,
              templates
            })
          )
        }
      }
    } catch (error) {
      setFormErrors(error, setError, Notification)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = () => handleSubmit(onSubmitForm)()

  const onChangeTemplates = (key, values) => {
    const savedTemplates =
      getValues('template_report.template_report_templates_attributes') ?? []
    const persistedTemplates = savedTemplates?.map(({ value, id }) => ({
      id,
      template_id: value
    }))
    const newTemplates = values?.map(({ value, id }) => ({
      template_id: value,
      id
    }))
    const difference = differenceBy(
      persistedTemplates,
      newTemplates,
      'template_id'
    )
    setValue(
      'template_report.template_report_templates_attributes_deleted',
      uniqBy(
        [
          ...(getValues(
            'template_report.template_report_templates_attributes_deleted'
          ) ?? []),
          ...difference
        ],
        'template_id'
      )
    )

    setValue(key, values)
  }

  return {
    ...methods,
    onSubmit,
    templatesList,
    isLoading,
    defaultValues,
    onChangeTemplates,
    isReadOnly
  }
}

export default useSummaryReport
