import { Button } from '@chakra-ui/button'
import { Box, Divider, Flex, Heading, Stack, Text } from '@chakra-ui/layout'
import { useMediaQuery } from '@chakra-ui/media-query'
import MaterialIcon from '../../../components/MaterialIcon'
import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import Modal from '../../../components/Modal'
import { handleExportForms } from '../../../helpers'
import FormDetails from '../../../pages/Form/FormDetails'
import OlingaAPI from '../../../services/OlingaAPI'
import { InfoWrapper } from './styles'

const AlertShowModal = ({ alertInfo }) => {
  const { formId, templateId, templateName } = alertInfo
  const viewFormPermission = useMemo(
    () => alertInfo?.userPermissions?.view_form,
    [alertInfo]
  )

  useEffect(() => {
    OlingaAPI.alertMarkAsSeen(alertInfo.id)
  }, [alertInfo])

  const attributes = useMemo(() => {
    const attrs = _.pick(alertInfo, [
      'templateName',
      'authorName',
      'formId',
      'sendAt',
      'question',
      'answer'
    ])

    const labels = [
      'Template name',
      'Author',
      'Form ID',
      'Sent at',
      'Question',
      'Answer'
    ]

    return Object.keys(attrs).map((key, index) => ({
      data: attrs[key],
      label: labels[index]
    }))
  }, [alertInfo])

  const getInfoComponent = (label, value, idx) => {
    const isLink = label === 'Form ID' && alertInfo?.userPermissions?.view_form
    const getInputElement = (value) => (
      <Text
        fontSize="md"
        dangerouslySetInnerHTML={{ __html: value }}
        style={{ cursor: isLink ? 'pointer' : 'normal' }}
        aria-label={isLink ? 'Open form responses' : null}
      />
    )

    return (
      <Box pb={4} key={idx}>
        <Text fontSize="sm" fontWeight="bold" pb={2}>
          {label}
        </Text>
        <InfoWrapper isLink={isLink}>
          {isLink ? (
            <Modal
              scrollType="outside"
              size="xl"
              header={
                <Stack>
                  <Heading isTruncated size="sm">
                    #{templateId} - {templateName} details
                  </Heading>
                </Stack>
              }
              body={
                <FormDetails
                  templateId={templateId}
                  formId={formId}
                  templateName={templateName}
                />
              }
            >
              {getInputElement(value)}
            </Modal>
          ) : (
            getInputElement(value)
          )}
          <Divider p={1} />
        </InfoWrapper>
      </Box>
    )
  }

  const [isMobile] = useMediaQuery('(max-width: 799px)')

  return (
    <>
      <Flex justify={isMobile ? 'space-between' : 'flex-end'}>
        {viewFormPermission && (
          <Button
            leftIcon={<MaterialIcon icon='picture_as_pdf' />}
            colorScheme="red"
            size="sm"
            onClick={() =>
              handleExportForms(templateId, templateName, 'pdf', {
                form_id: formId
              })
            }
          >
            Export as PDF
          </Button>
        )}
      </Flex>
      {attributes.map((attr, idx) =>
        getInfoComponent(attr.label, attr.data, idx)
      )}
    </>
  )
}

export default AlertShowModal
