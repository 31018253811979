import { noop } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { shouldDisplayQuickSubmitQRCodeOption } from '../../../../helpers'
import { usePageProperties } from '../../../../hooks'
import { quickSubmitQrCodeValidQuestions } from '../ActionsBar/constants'

const useFillForm = ({ data }) => {
  const { template, details } = data
  const [templateDetails, setTemplateDetails] = useState({})
  const [templateStructure, setTemplateStructure] = useState({})
  const { user } = useSelector(({ globalReducer }) => globalReducer)
  const [geolocation, setGeolocation] = useState({
    latitude: null,
    longitude: null
  })
  const [shouldRenderQRCodeButton, setShouldRenderQrCodeButton] =
    useState(false)

  const handleGeolocation = () => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    }
    function success(position) {
      const { latitude, longitude } = position.coords
      setGeolocation({ latitude, longitude })
    }
    navigator.geolocation.getCurrentPosition(success, noop, options)
  }

  const { pageTitle, singularTitle } = usePageProperties({
    pageName: 'templates'
  })

  useEffect(() => {
    if (template.id && details?.id) {
      const { id, name, formFolderName, children, action } = details.attributes

      const shouldRenderQuickSubmitQrCode =
        shouldDisplayQuickSubmitQRCodeOption(
          details.attributes,
          true,
          (field) => {
            return (
              field?.type === 'question' &&
              quickSubmitQrCodeValidQuestions.includes(field?.question_type)
            )
          }
        )
      setShouldRenderQrCodeButton(
        user?.personable_type === 'Business' && shouldRenderQuickSubmitQrCode
      )

      setTemplateDetails({
        id,
        name,
        formFolderName,
        children,
        action,
        userPermissions: template?.attributes?.user_permissions
      })

      setTemplateStructure(details.attributes)
      handleGeolocation()
    }
  }, [details.attributes, details?.id, template, user])

  return {
    templateDetails,
    templateStructure,
    geolocation,
    shouldRenderQRCodeButton,
    singularTitle,
    pageTitle,
    template
  }
}

export default useFillForm
