import { AddIcon } from '@chakra-ui/icons'
import { Button, Flex, Stack } from '@chakra-ui/react'
import ToggleValueModalBody from 'components/ToggleValueModal/ToggleValueModalBody'
import { getRouteUrl } from 'helpers'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../../components/PageHeader'
import Table from '../../../components/Table'
import TemplateSelector from '../TemplateSelector'
import TemplatesModal from './TemplatesModal'
import useReportsList from './useReportsList'

const ReportsList = () => {
  const {
    refetch,
    columns,
    data,
    fetchParams,
    hiddenColumns,
    hiddenColumnsKey,
    isLoading,
    pagination,
    hasPermissionToModifyTemplateReport,
    pageTitle,
    reportType,
    onChangeReportType,
    shouldRenderScheduledReports,
    shouldRenderSummaryReports,
    reportTemplates,
    onCloseReportTemplatesModal,
    isReportTemplatesModalOpen
  } = useReportsList()

  const options = useMemo(() => {
    const options = []
    if (shouldRenderScheduledReports) {
      options.push({
        label: 'Scheduled',
        value: 'scheduled'
      })
    }
    if (shouldRenderSummaryReports) {
      options.push({
        label: 'Summary',
        value: 'summary'
      })
    }
    return options
  }, [shouldRenderScheduledReports, shouldRenderSummaryReports])

  return (
    <>
      <TemplatesModal
        isOpen={isReportTemplatesModalOpen}
        onClose={onCloseReportTemplatesModal}
        templates={reportTemplates}
      />
      <Stack as="section">
        <PageHeader
          Actions={
            hasPermissionToModifyTemplateReport ? (
              reportType === 'scheduled' ? (
                <TemplateSelector reportType={reportType} />
              ) : reportType === 'summary' ? (
                <Link
                  to={getRouteUrl('newTemplateReport', { type: reportType })}
                >
                  <Button leftIcon={<AddIcon />} colorScheme="blue">
                    New
                  </Button>
                </Link>
              ) : null
            ) : null
          }
          title={
            <Flex gap={4} align="center" title="">
              {pageTitle}
              <ToggleValueModalBody
                attributeKey="value"
                columns={2}
                value={reportType}
                colorScheme="blue"
                onChange={onChangeReportType}
                options={options}
              />
            </Flex>
          }
        />
        <Table
          shouldResetPage={fetchParams?.page === 1}
          isLoading={isLoading}
          data={data}
          columns={columns}
          pageCount={pagination?.last}
          totalItems={pagination?.items}
          fetchPage={refetch}
          initiallyHiddenColumns={hiddenColumns}
          persistedColumnsKey={hiddenColumnsKey}
        />
      </Stack>
    </>
  )
}

ReportsList.defaultProps = {
  persistedHiddenColumns: [],
  hiddenColumnsKey: ''
}

export default ReportsList
