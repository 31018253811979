import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel
} from '@chakra-ui/accordion'
import { Button } from '@chakra-ui/button'
import { SmallAddIcon } from '@chakra-ui/icons'
import { Input } from '@chakra-ui/input'
import { Box, Flex } from '@chakra-ui/layout'
import { Tooltip } from '@chakra-ui/tooltip'
import { map } from 'lodash'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import ActionsBar from '../ActionsBar'
import Content from '../Form/Content'
import useFillFormSection from './hooks/useFillFormSection'
import {
  ButtonLabel,
  RemoveIcon,
  SectionActionsWrapper,
  SectionWrapper
} from './styles'
import MaterialIcon from 'components/MaterialIcon'

const Section = ({
  field,
  sectionRepeatAttributes,
  localIndex,
  template,
  index: idx
}) => {
  const { register } = useFormContext()

  const { indexes, sections, removedSections, onRemove, onAdd } =
    useFillFormSection({ field, localIndex })

  return (
    <>
      {sections.map((section, sectionIndex) => (
        <Accordion
          defaultIndex={map(sections, (s) => s.sequence - 1)}
          allowMultiple
          key={sectionIndex}
          pb={3}
          paddingTop="15px"
          hidden={removedSections[sectionIndex]}
        >
          <AccordionItem bg="gray.200" borderBottom={0}>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Flex w={'100%'}>
                    <Box dangerouslySetInnerHTML={{ __html: field.name }} />
                    <Box paddingLeft="8px">{section.sequence}</Box>
                  </Flex>
                </Box>
                <AccordionIcon />
                {field.repeat && sectionIndex !== 0 && (
                  <Tooltip label="Remove repeated section">
                    <RemoveIcon
                      onClick={(evt) => onRemove(evt, sectionIndex)}
                      
                    >
                      <MaterialIcon icon='delete' />
                    </RemoveIcon>
                  </Tooltip>
                )}
              </AccordionButton>
            </h2>
            <AccordionPanel p={0} bg="white">
              <SectionWrapper>
                {field.children &&
                  field.children.map((children, index) => {
                    const sectionRepeat = [...sectionRepeatAttributes]
                    sectionRepeat.push(sections[sectionIndex])
                    const currentKey = `questions.${section.idx}.questions.${index}`
                    const key = indexes.getUniqKey(currentKey)

                    return (
                      <span key={index}>
                        <span data-key={currentKey} />
                        <Input
                          hidden
                          {...register(
                            `${currentKey}.section_repeats_attributes`,
                            { value: JSON.stringify(sectionRepeat) }
                          )}
                        />
                        <Content
                          index={idx}
                          currentKey={currentKey}
                          key={key}
                          sectionRepeatAttributes={sectionRepeat}
                          template={template}
                          field={{
                            ...children,
                            compulsory: removedSections[sectionIndex]
                              ? false
                              : children.compulsory
                          }}
                          localIndex={localIndex}
                        />
                      </span>
                    )
                  })}
              </SectionWrapper>
              {false && (
                <SectionActionsWrapper>
                  <ActionsBar
                    template={template}
                    fieldType="section"
                    currentValue={field.name}
                    index={idx}
                    currentKey={`questions.${section.idx}`}
                    hasBorder={false}
                  />
                </SectionActionsWrapper>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ))}
      {field.repeat && (
        <Tooltip label="Click to repeat this section">
          <Button
            onClick={onAdd}
            leftIcon={<SmallAddIcon />}
            w="100%"
            h="100%"
            p={2}
            color="blue.500"
            variant="solid"
          >
            <ButtonLabel dangerouslySetInnerHTML={{ __html: field.name }} />
          </Button>
        </Tooltip>
      )}
    </>
  )
}

Section.defaultProps = {
  sectionRepeatAttributes: []
}

export default Section
