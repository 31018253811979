import { HStack, Tag, Text, VStack } from '@chakra-ui/react'
import { isFunction, isNull, isUndefined } from 'lodash'
import AssetAnswer from 'pages/Form/FormDetails/AssetAnswer'
import React, { forwardRef } from 'react'
import TextClamper from '../../../components/TextClamper'
import { mapQuestionTypes } from '../../../pages/Form/FillForm/ActionsBar/constants'
import AttributeSwitch from '../../../pages/TemplateStructure/TemplateDetails/AttributeSwitch'
import BaseStructure from '../BaseStructure'
import QuestionLayout from '../QuestionLayout'

const Question = forwardRef((props) => {
  const {
    id,
    type,
    children,
    child,
    text,
    question_type: questionType,
    question_assets: assets,
    main,
    active,
    compulsory,
    onSelect,
    initialSelectedNodes,
    hasSelectionComponent,
    answer,
    answerLabel
  } = props

  return (
    <BaseStructure
      id={id}
      type={type}
      hasChildren={children.length}
      onSelect={onSelect}
      initialSelectedNodes={initialSelectedNodes}
      text={text}
      otherProps={{ ...props }}
      hasSelectionComponent={!!hasSelectionComponent}
    >
      <QuestionLayout
        leftSlot={
          <>
            <HStack spacing={2} flexDir={['column', 'row']}>
              <Text fontWeight="bold">Question: </Text>
              <TextClamper text={text} lines={2} isHtml />
            </HStack>
            <HStack spacing={2} flexDir={['column', 'row']}>
              <Text fontWeight="bold">Type: </Text>
              <Text>{mapQuestionTypes[questionType]}</Text>
            </HStack>
            {!isNull(answer?.value) && !isUndefined(answer?.value) && (
              <HStack spacing={2} color="orange.500">
                <Text fontWeight="bold">{answerLabel}: </Text>
                <Text>
                  {answer?.value ? (
                    answer?.value
                  ) : (
                    <Tag size="sm" colorScheme="gray">
                      Empty
                    </Tag>
                  )}
                </Text>
              </HStack>
            )}
          </>
        }
        rightSlot={
          <>
            {main && <AttributeSwitch label="Main" answer={main} />}
            {compulsory && (
              <AttributeSwitch label="Compulsory" answer={compulsory} />
            )}
            {compulsory && <AttributeSwitch label="Active" answer={active} />}
          </>
        }
      >
        {isFunction(child) ? child(props) : null}
        <VStack spacing={2} w="100%">
          {questionType === 'asset' &&
            assets.map((asset, index) => (
              <AssetAnswer
                key={index}
                name={asset?.template_name}
                label={asset?.label}
                fields={asset?.fields}
              />
            ))}
        </VStack>
      </QuestionLayout>
    </BaseStructure>
  )
})

Question.displayName = 'Question'

Question.defaultProps = {
  hasSelectionComponent: true
}

export default Question
