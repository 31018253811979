import { Flex, Text, theme } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import React from 'react'

const LinkWrapper = ({ url, children, size }) => {
  return (
    <Link to={url}>
      <Flex gap={2}>
        <Text
          fontSize={size}
          _hover={{ textDecoration: 'underline' }}
          color={theme.colors.orange[500]}
        >
          {children}
        </Text>
      </Flex>
    </Link>
  )
}

LinkWrapper.defaultProps = {
  size: 'md'
}

export default LinkWrapper
