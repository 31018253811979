import { useMediaQuery } from '@chakra-ui/react'
import TextClamper from 'components/TextClamper'
import { getValidDate } from 'helpers'
import { useTableColumns, useTableFilter } from 'hooks'
import { isEmpty, trim } from 'lodash'
import { default as React, useCallback, useMemo, useRef } from 'react'
import OlingaAPI from 'services/OlingaAPI'
import FormAnswerAction from '../FormAnswerAction'

const useFormAnswers = ({ id, data }) => {
  const {
    data: initialTableData,
    meta,
    template,
    templateDetails,
    places,
    formIds
  } = useMemo(() => {
    const { forms } = data
    if (forms && forms?.data && forms?.meta) {
      return {
        data: forms.data,
        meta: forms.meta,
        template: forms.template,
        templateDetails: {
          formFolderName: forms.template?.folderName,
          name: forms.template?.templateName,
          userPermissions: forms.template?.userPermissions
        },
        formIds: forms.data.map(({ attributes: { formId } }) => formId),
        places: forms.data.map(({ attributes }) => attributes)
      }
    }
    return {
      data: [],
      meta: {
        page: 1,
        per_page: 15
      },
      template: {},
      templateDetails: {},
      formIds: [],
      places: []
    }
  }, [data])

  const formAnswersActionsRef = useRef()

  const [isMobile] = useMediaQuery('(max-width: 799px)')

  const tableKey = `form_answers_persisted_columns.${id}`
  const { columns, hiddenColumns } = useTableColumns(
    [
      ...(template?.mainQuestions ?? []).map(({ text, id }) => ({
        Header: <div dangerouslySetInnerHTML={{ __html: text }} key={id} />,
        accessor: `${id}`,
        RawHeader: text
      })),
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Sent by',
        accessor: 'staff'
      },
      {
        Header: 'Date',
        accessor: 'date',
        defaultCanSort: false
      }
    ],
    tableKey,
    true,
    FormAnswerAction,
    (row) => {
      const {
        values: { id, date }
      } = row
      const validDate = getValidDate(date)

      return {
        id,
        date: validDate,
        template
      }
    },
    ['id']
  )

  const fieldsExtractor = useCallback(
    (data) => {
      const array = isEmpty(data?.data) ? data : data?.data
      return array.map(
        ({
          attributes: {
            value: title,
            employee: staff,
            dateTime: date,
            id,
            mainValues
          }
        }) => {
          const mainQuestionsAnswers = mainValues.reduce(
            (acc, { answer, question: { id: key } }) => {
              acc = {
                ...acc,
                [`${key}`]: (
                  <TextClamper lines={isMobile ? 2 : 1} isHtml text={answer} />
                )
              }
              return acc
            },
            {}
          )
          return {
            title,
            ...mainQuestionsAnswers,
            staff,
            date,
            id
          }
        }
      )
    },
    [isMobile]
  )

  const {
    refetch,
    isLoading,
    updateParams,
    fetchParams,
    tableData,
    pagination
  } = useTableFilter({
    id,
    fetchCallback: OlingaAPI.formsList,
    fieldsExtractor,
    initialParams: {
      page: 1,
      per_page: 15
    },
    responseTransformer: (data) => data.data,
    initialData: { data: initialTableData, meta },
    shouldFetch: false
  })

  const onSearchRangeChange = (dates = []) => {
    const [start, end] = dates
    if (!start && !end) {
      return refetch(['start_date', 'end_date'])
    }
    if (start && end) {
      const formattedStart = start || null
      const formattedEnd = end || null
      const params = {
        start_date: formattedStart,
        end_date: formattedEnd
      }

      updateParams(params)
    }
  }

  const onTextChange = useCallback(
    (updatedText) => {
      if (!updatedText || !trim(updatedText)?.length) {
        return refetch(['text'])
      }
      updateParams({ text: updatedText }, updatedText?.length >= 3)
    },
    [refetch, updateParams]
  )

  const onChangePage = (params) => {
    updateParams(params)
  }

  return {
    onChangePage,
    onTextChange,
    onSearchRangeChange,
    isMobile,
    id,
    isLoading,
    fetchParams,
    tableData,
    pagination,
    columns,
    hiddenColumns,
    tableKey,
    template,
    templateDetails,
    places,
    formIds,
    formAnswersActionsRef
  }
}

export default useFormAnswers
