/* eslint-disable @typescript-eslint/no-empty-function */
import { AddIcon } from '@chakra-ui/icons'
import { Stack } from '@chakra-ui/layout'
import { Button, Flex } from '@chakra-ui/react'
import SearchInput from 'components/SearchInput'
import React from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import { getRouteUrl } from '../../helpers'
import useManageUsers from './useManageUsers'

const ManageUsers = () => {
  const {
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data,
    isLoading,
    refetch,
    fetchParams,
    pagination,
    onTextChange,
    pageTitle,
    hasPermissionToCreateUsers
  } = useManageUsers()

  return (
    <Stack>
      <PageHeader title={pageTitle} />
      <PageHeader
        Actions={
          <Flex flexDir={['column', 'row']} gap={4} align="center">
            <SearchInput
              value={fetchParams?.email}
              setValue={onTextChange}
              isLoading={isLoading}
              debounceTimeout={25}
              placeholder="Filter by email"
            />
            {hasPermissionToCreateUsers ? (
              <Link to={getRouteUrl('newUser')}>
                <Button colorScheme="blue" leftIcon={<AddIcon />}>
                  New
                </Button>
              </Link>
            ) : null}
          </Flex>
        }
      />
      <Table
        shouldResetPage={fetchParams?.page === 1}
        isLoading={isLoading}
        data={data}
        columns={columns}
        pageCount={pagination?.last}
        totalItems={pagination?.items}
        fetchPage={(params) => refetch({ ...params, profile: true })}
        initiallyHiddenColumns={hiddenColumns}
        initialPageIndex={Number(fetchParams?.page - 1)}
        persistedColumnsKey={hiddenColumnsKey}
      />
    </Stack>
  )
}

export default ManageUsers
