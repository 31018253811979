import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure
} from '@chakra-ui/react'
import React from 'react'
import { HexColorPicker } from 'react-colorful'

const ColorPicker = ({ value, onChange }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Box
          p="5px"
          borderWidth={1}
          borderRadius="md"
          aria-label="Pick a color"
          tabIndex="0"
          onClick={onOpen}
          cursor="pointer"
        >
          {value ? (
            <Box bg={value} h="28px" w="100%" />
          ) : (
            <Button w="100%" size="xs" variant="ghost" colorScheme="blue">
              Click to pick a color
            </Button>
          )}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Pick a color</PopoverHeader>
          <PopoverBody>
            <HexColorPicker
              style={{ width: '100%' }}
              color={value}
              onChange={onChange}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default ColorPicker
