import { Button, useMediaQuery } from '@chakra-ui/react'
import { noop } from 'lodash'
import React from 'react'
import MaterialIcon from '../../MaterialIcon'

const AllFoldersButton = ({
  isLoading,
  onClick,
  colorScheme,
  variant,
  icon,
  shouldRenderStickyActions,
  loadedLabel,
  label,
  isOutlinedIcon,
  ml,
  mt
}) => {
  const [isMobile] = useMediaQuery('(max-width: 799px)')
  return (
    <Button
      isLoading={isLoading}
      onClick={onClick}
      colorScheme={colorScheme}
      isDisabled={isLoading}
      variant={variant}
      ml={ml}
      mt={mt}
      leftIcon={<MaterialIcon type={isOutlinedIcon ? 'material-icons-outlined' : 'material-icons'} icon={icon} styles={{ fontSize: "20px" }} />}
      data-testid="folders-tree-all-folders-button"
    >
      {isMobile && shouldRenderStickyActions ? loadedLabel : label}
    </Button>
  )
}

AllFoldersButton.defaultProps = {
  isLoading: false,
  onClick: noop,
  colorScheme: 'teal',
  variant: 'outline',
  shouldRenderStickyActions: false,
  icon: 'folder_copy',
  loadedLabel: '',
  label: '',
  mt: 0,
  ml: 0
}

export default AllFoldersButton
