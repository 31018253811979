import { SimpleGrid, Stack } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import FormField from 'components/FormField'
import FormSubmitActions from 'components/FormSubmitActions'
import EditInline from 'components/Inputs/EditInline'
import PageHeader from 'components/PageHeader'
import { usePageProperties } from 'hooks'
import UsersSelector from 'pages/Form/FillForm/ActionsBar/ActionSidebar/UsersSelector'
import TemplateSelector from 'pages/ManageUsers/ManageUserForm/TemplateSelector'
import React from 'react'
import OlingaAPI from 'services/OlingaAPI'
import { frequencies } from '../List/constants'
import { formattedValues } from '../ReportsEditor/helpers'
import ReportEditorBreadcrumb from '../ReportsEditor/ReportEditorBreadcrumb'
import useSummaryReport from './hooks/useSummaryReport'

const SummaryReportEditor = ({ data, id }) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
    onSubmit,
    templatesList,
    isLoading,
    defaultValues,
    onChangeTemplates,
    isReadOnly
  } = useSummaryReport({
    data,
    id
  })

  const { pageTitle, singularTitle } = usePageProperties({
    pageName: 'summary_reports'
  })

  const { templatesPageTitle } = usePageProperties({
    pageName: 'templates'
  })

  const [templateIds, frequency, title, emails] = watch([
    'template_report.template_report_templates_attributes',
    'template_report.fields.frequency',
    'template_report.fields.title',
    'template_report.fields.emails'
  ])

  return (
    <Stack as="section">
      <PageHeader
        title={`${id ? `Edit ${singularTitle}` : `New ${singularTitle}`}`}
        Breadcrumb={
          <ReportEditorBreadcrumb
            fields={{ title: defaultValues?.template_report?.fields?.title }}
            singularTitle={singularTitle}
            pageTitle={pageTitle}
            reportType="summary"
          />
        }
        Actions={null}
      />
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={4}>
        <FormField label="Title" isRequired>
          <EditInline
            placeholder={`${singularTitle} Title`}
            defaultValue={defaultValues?.template_report?.fields?.title}
            startWithEditView={!title}
            autoFocus={!title}
            fontSize="md"
            registerProps={{
              ...register('template_report.fields.title', { required: true })
            }}
            width={'100%'}
            isInvalid={!!errors?.template_report?.fields?.title}
            isDisabled={isReadOnly}
          />
        </FormField>
        <FormField
          label="Frequency"
          isRequired
          infoLabel={frequency?.value ? frequencies[frequency?.value] : ''}
        >
          <Select
            options={data?.enumerators?.frequency ?? []}
            {...register('template_report.fields.frequency', {
              required: true
            })}
            onChange={(option) => {
              setValue('template_report.fields.frequency', option)
            }}
            value={frequency}
            isInvalid={!!errors?.template_report?.fields?.frequency}
            isDisabled={isReadOnly}
          />
        </FormField>
      </SimpleGrid>
      <Stack gap={4}>
        <FormField label="Emails" isRequired>
          <UsersSelector
            defaultUsers={formattedValues(data?.report?.attributes?.fields?.emails) || emails}
            onSelect={(newEmails) =>
              setValue('template_report.fields.emails', newEmails)
            }
            requestParams={[true, { show_email: true }]}
            fetchCallback={OlingaAPI.businessSubUsersList}
            isInvalid={!!errors?.template_report?.fields?.emails}
            isDisabled={isReadOnly}
          />
        </FormField>
        <FormField isRequired label="Templates">
          <TemplateSelector
            attributeKey="template_report.template_report_templates_attributes"
            roleTypeLabel=""
            defaultValue={templateIds ?? []}
            onChangeTemplatesSelector={onChangeTemplates}
            register={register}
            templatesList={templatesList}
            pageTitle={templatesPageTitle}
            isDisabled={isReadOnly}
          />
        </FormField>
        <FormSubmitActions
          isLoading={isLoading}
          onSubmit={onSubmit}
          cancelRoute="templateReports"
          routeParams={{ reportType: 'summary' }}
          isDisabled={isReadOnly}
        />
      </Stack>
    </Stack>
  )
}

export default SummaryReportEditor
