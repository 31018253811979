import { Heading, Text } from '@chakra-ui/react'
import { capitalize, isArray } from 'lodash'
import React from 'react'
import AsyncContentModal from '../../../components/AsyncContentModal'
import List from '../../../components/List'
import { getPrefixes } from '../../../helpers'
import OlingaAPI from '../../../services/OlingaAPI'

const GroupPeopleModal = ({ id, count, groupName, customButtonLabel }) => {
  const getHeader = (people) => {
    if (isArray(people)) {
      const { before, after } = getPrefixes(count, 'person')
      const getText = () => {
        if (!count || count === 0) {
          return 'There are no people for this group'
        }
        return `${capitalize(before)} ${
          people.length === 1 ? 'only' : people.length
        } ${after} of group ${groupName}`
      }

      return <Heading size="md">{getText()}</Heading>
    }
    return null
  }

  const getItemBody = (item) => {
    return (
      <Text fontSize="md" color="gray.700">
        {item.name ?? `User with id ${item.id}`}
      </Text>
    )
  }

  const getBody = (people) => {
    if (isArray(people)) {
      const names = people.map(({ attributes: { name, id } }) => ({
        name,
        id
      }))
      return <List getItemBody={getItemBody} items={names} />
    }
    return null
  }

  return (
    <AsyncContentModal
      getHeader={getHeader}
      getBody={getBody}
      id={id}
      count={count}
      fetchCallback={OlingaAPI.groupPeople}
      customButtonLabel={customButtonLabel}
    />
  )
}

GroupPeopleModal.defaultProps = {
  customButtonLabel: ''
}

export default GroupPeopleModal
