import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Subject } from 'rxjs'
import { formatGlobalObject } from './helpers'

const useGlobalContext = (globalProps) => {
  const dispatch = useDispatch()

  const { global$, global } = useMemo(() => {
    const global$ = new Subject()
    const global = global$.asObservable().pipe()
    return {
      global,
      global$
    }
  }, [])

  useEffect(() => {
    dispatch(formatGlobalObject(globalProps))
  }, [globalProps, dispatch])

  return {
    global,
    global$
  }
}

export default useGlobalContext
