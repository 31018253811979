import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text
} from '@chakra-ui/react'
import MaterialIcon from '../../../components/MaterialIcon'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { LuigiJobsLogo, OlingaLogo } from '../../../components/Icons'
import Notification from '../../../services/Notification'
import OlingaAPI from '../../../services/OlingaAPI'

const ToggleUserActive = ({
  isActive,
  onSuccess,
  shouldToggleBoth,
  id,
  type: typeProp
}) => {
  const [isSaving, setIsSaving] = useState(false)

  const handleToggleAssessorActive = useCallback(
    async (type, id, active) => {
      let params = {}
      switch (type) {
        case 'olinga':
          params = {
            [typeProp]: {
              active_olinga: !active
            }
          }
          break
        case 'luigi':
          params = {
            [typeProp]: {
              active_olinga: !active,
              active_luigijobs: !active
            }
          }
          break
        default:
          break
      }
      setIsSaving(true)
      try {
        const endpoint =
          typeProp === 'assessor'
            ? OlingaAPI.assessorsToggleActive
            : OlingaAPI.usersToggleActive
        const response = await endpoint(id, params)
        if (response.status === 200) {
          Notification({
            icon: 'success',
            text: response.data.message ?? 'Assessor updated!'
          })
          onSuccess(!active)
        }
      } catch (error) {
        //
      } finally {
        setIsSaving(false)
      }
    },
    [onSuccess, typeProp]
  )

  return (
    <Menu closeOnSelect isLazy>
      <MenuButton
        as={IconButton}
        aria-label="To Active assessor"
        icon={<MaterialIcon type='material-icons-outlined' icon='toggle_on' />}
        variant="outline"
        colorScheme="orange"
        size="sm"
        isLoading={isSaving}
      />
      <MenuList>
        <MenuItem
          onClick={() =>
            handleToggleAssessorActive(
              shouldToggleBoth ? 'luigi' : 'olinga',
              id,
              isActive
            )
          }
          color="orange.500"
          icon={<OlingaLogo />}
        >
          <Stack>
            <Flex py={1} gap={2} align="center">
              <Text fontWeight="bold" color="orange.500">
                Olinga
              </Text>
            </Flex>
            <Text sx={{ margin: '0!important' }} color="gray.600" fontSize="xs">
              It will only be {isActive ? 'inactivated' : 'activated'} on Olinga
            </Text>
          </Stack>
        </MenuItem>
        {!shouldToggleBoth && (
          <MenuItem
            onClick={() => handleToggleAssessorActive('luigi', id, isActive)}
            color="blue.600"
            icon={<LuigiJobsLogo />}
          >
            <Stack>
              <Flex py={1} gap={2} align="center">
                <Text fontWeight="bold" color="blue.600">
                  Luigi Jobs
                </Text>
              </Flex>
              <Text
                sx={{ margin: '0!important' }}
                color="gray.600"
                fontSize="xs"
              >
                It will be {isActive ? 'inactivated' : 'activated'} on Luigi
                Jobs and Olinga
              </Text>
            </Stack>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}

ToggleUserActive.defaultProps = {
  shouldToggleBoth: true,
  endpoint: OlingaAPI.assessorsToggleActive,
  type: 'assessor'
}

ToggleUserActive.propTypes = {
  shouldToggleBoth: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.oneOf(['assessor', 'user'])
}

export default ToggleUserActive
