import { ViewIcon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/react'
import Modal from 'components/Modal'
import React, { forwardRef } from 'react'
import ChartPreview from '../ChartPreview'

const ChangePreviewModal = forwardRef(
  ({ chartId, id, preview, isLoading, getDashboardPreview }, ref) => {
    if (!chartId || !id) {
      return null
    }
    return (
      <Modal
        ref={ref}
        body={<ChartPreview isLoading={isLoading} preview={preview} />}
        header="Chart Preview"
        onModalOpen={() => getDashboardPreview(id, chartId)}
      >
        <Button
          leftIcon={<ViewIcon />}
          variant="ghost"
          size="sm"
          colorScheme="orange"
          isLoading={isLoading}
        >
          Chart Preview
        </Button>
      </Modal>
    )
  }
)

ChangePreviewModal.displayName = 'ChangePreviewModal'

export default ChangePreviewModal
