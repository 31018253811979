import { AddIcon } from '@chakra-ui/icons'
import { Button, Flex, Stack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import SearchInput from '../../components/SearchInput'
import Table from '../../components/Table'
import { getRouteUrl } from '../../helpers'
import useAssetTemplates from './hooks/useAssetTemplates'

const AssetTemplates = () => {
  const {
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data,
    refetch,
    isLoading,
    pagination,
    fetchParams,
    onTextChange,
    shouldAllowAssetTypesCreation
  } = useAssetTemplates()

  return (
    <Stack>
      <PageHeader
        title="Asset Types"
        Actions={
          <Flex flexDir={['column', 'row']} gap={4} align="center">
            <SearchInput
              value={fetchParams?.name}
              setValue={onTextChange}
              isLoading={isLoading}
              debounceTimeout={25}
              placeholder="Filter by name"
            />
            {shouldAllowAssetTypesCreation && (
              <Link to={getRouteUrl('newAssetTemplate')}>
                <Button leftIcon={<AddIcon />} colorScheme="blue">
                  New
                </Button>
              </Link>
            )}
          </Flex>
        }
      />
      <Table
        shouldResetPage={fetchParams?.page === 1}
        isLoading={isLoading}
        data={data}
        columns={columns}
        pageCount={pagination?.last}
        totalItems={pagination?.items}
        fetchPage={refetch}
        initiallyHiddenColumns={hiddenColumns}
        persistedColumnsKey={hiddenColumnsKey}
      />
    </Stack>
  )
}

export default AssetTemplates
