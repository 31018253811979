import styled from '@emotion/styled'

const getWrapperProps = (type) => {
  if (type === 'primary') {
    return `
      background: #FF871C;
      height: 60px;
    `
  }
  if (type === 'mobile') {
    return `
      background: #FF871C;
      height: 60px;
    `
  }
  return `
      background: #54433A;
      height: 20px;
    `
}

export const NavbarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
`

export const Wrapper = styled.nav`
  ${({ type }) => `
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    ${getWrapperProps(type)};
  `}
`

export const LogoWrapper = styled.div`
  width: 120px;
  height: auto;
  text-decoration: none;
`

export const Logo = styled.img`
  width: 100%;
  object-fit: contain;
`

export const MenuOptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:not(:last-of-type) {
    margin-right: 16px;
  }
`

export const UserEmailDropdownAnchor = styled.div`
  color: #ffe9d0;
  font-size: 12px;
  margin: 0px 16px;
  &:hover {
    cursor: pointer;
  }
  & i {
    margin-left: 5px;
  }
`
export const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`
export const OverlayClose = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
  color: white;
`

export const OverlayContentItem = styled.div`
  &:before {
    content: '';
    position: absolute;
    bottom: -10px;
    width: 0px;
    height: 2px;
    background-color: #ff871c;
    transition: width 0.2s ease;
  }
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`

export const OverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MenuIcon = styled.div`
  cursor: pointer;
  font-size: 24px;
  color: white;
`

export const MobileUserSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: white;
  margin: 12px 0;
`
export const Row = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid #dedede;
  margin: 0px;
`
export const UserIcon = styled.div`
  font-size: 20px;
  color: white;
  cursor: pointer;
`
export const SearchIcon = styled.div`
  font-size: 20px;
  color: white;
  margin: 8px 16px 18px 8px;
  cursor: pointer;
`

export const SearchWrapper = styled.div`
  padding: 8px;
  border-radius: 4px;
  background-color: white;
`
export const MenuWrapper = styled.div`
  ${({ top, left }) => `
    position: fixed;
    top: ${top}px;
    left: ${left}px;
    background: white;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 0px;
    min-width: 250px;
    z-index: 9999;
    padding: 8px;
    &:after {
      content: '';
      position: absolute;
      top: -7px;
      right: 30px;
      width: 30px;
      height: 15px;
      border-bottom: solid 15px white;
      border-left: solid 15px transparent;
      border-right: solid 15px transparent;
    }
  `}
`

export const MenuItem = styled.div`
  width: 100%;
  padding: 16px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dedede;
  border-left: 0px;
  border-right: 0px;

  &:hover {
    background-color: #dedede;
  }
`
