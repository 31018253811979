import styled from '@emotion/styled'

const borderTransformer = {
  section: (isDraggingOver, isNested) => `
    ${
      !isDraggingOver &&
      isNested &&
      `
      border-left: 2px solid #1890ff;
      border-top: 0;
      border-bottom: 0;
    `
    }
  `,
  logic: (isDraggingOver, isNested) => `
      ${
        !isDraggingOver &&
        isNested &&
        `
      border-left: 2px solid #36cfc9;
    `
      }
  `
}

export const DropzoneContainer = styled.div`
  ${({ isDraggingOver, isNested, fromType }) => `
    border: 4px dashed transparent;
    border-color: ${isDraggingOver ? 'lightblue' : 'transparent'};
    width: 100%;
    ${fromType && borderTransformer[fromType](isDraggingOver, isNested)};

    @media screen and (max-width: 730px) {
      padding: 0px;
      border: none;
    }
  `}
`
