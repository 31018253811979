import { useTemplateEditorContext } from 'contexts'
import { setQuillValue } from 'helpers'
import { defer, isFunction } from 'lodash'
import { findParentNodeByCondition } from 'pages/TemplateEditor/helpers'
import { useRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const useInstruction = ({ currentKey, isNested }) => {
  const { focusedField, handleFocusedField } = useTemplateEditorContext()

  const ref = useRef(null)

  const isFocused = focusedField === currentKey

  const handleDragClick = (event) => {
    const { target } = event
    const compare = (node) => {
      if (node) {
        if (isFunction(node?.getAttribute)) {
          const dataId = node.getAttribute('data-id')
          const dataIgnore = node.getAttribute('data-ignore') === 'true'
          if (dataIgnore) {
            return true
          }
          if (dataId && dataId === 'draggable-container') {
            return true
          }
        }
      }
      return false
    }
    const node = findParentNodeByCondition(target, compare)
    if (node) {
      const splittedKey = currentKey.split('.')
      const shouldIgnore = node.getAttribute('data-ignore') === 'true'
      const isParentExpanded = node.getAttribute('data-expanded') === 'true'
      if (isParentExpanded) {
        return
      }
      if (shouldIgnore) {
        return
      }
      let parent = ''
      if (isNested && splittedKey.length > 2) {
        parent = currentKey.split('.').slice(0, -2).join('.')
      }
      if (parent && isNested) {
        handleFocusedField(parent)
      } else {
        handleFocusedField(null)
      }
    } else {
      defer(() => {
        handleFocusedField(currentKey)
      })
    }
  }

  const { control, setValue } = useFormContext()

  const [id, text] = useWatch({
    name: [`${currentKey}._id`, `${currentKey}.text`],
    control
  })

  const onChangeTextValue = (value) => {
    setQuillValue(value, setValue, `${currentKey}.text`)
  }

  return {
    onChangeTextValue,
    handleDragClick,
    ref,
    isFocused,
    id,
    text
  }
}

export default useInstruction
