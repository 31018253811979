import { Button } from '@chakra-ui/button'
import { Box, Flex, Heading } from '@chakra-ui/layout'
import React from 'react'
import FormErrorMessage from '../../components/FormErrorMessage'
import PasswordInput from '../../components/Inputs/Password'
import useChangePassword from './useChangePassword'

const ChangePassword = () => {
  const {
    control,
    errors,
    handleSubmit,
    isLoading,
    isMobile,
    onError,
    onResetForm,
    onSubmit,
    register,
    formErrors,
  } = useChangePassword()

  return (
    <Flex justifyContent="center">
      <Box w={`${isMobile ? '100%' : '50%'}`}>
        <Heading as="h2" size="md">
          Change password
        </Heading>
        <Box pt={8}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Box>
              <PasswordInput
                name="current_password"
                label="Current password"
                register={register}
                control={control}
                isRequired
                isInvalid={!!errors.current_password}
                placeholder=""
              />
              <FormErrorMessage message={formErrors?.current_password?.message} />
            </Box>
            <Box>
              <PasswordInput
                name="password"
                label="New password"
                register={register}
                control={control}
                isInvalid={!!errors.password}
                isRequired
                placeholder=""
              />
              <FormErrorMessage message={formErrors?.password?.message} />
            </Box>
            <Box>
              <PasswordInput
                name="password_confirmation"
                label="Repeat new password"
                register={register}
                control={control}
                isInvalid={!!errors.password_confirmation}
                isRequired
                placeholder=""
              />
              <FormErrorMessage message={formErrors?.password_confirmation?.message} />
            </Box>

            <Flex justify="flex-start" w="100%" pt={5}>
              <Button onClick={onResetForm} mr={2}>
                Reset
              </Button>
              <Button
                isLoading={isLoading}
                loadingText="Submit"
                type="submit"
                colorScheme="blue"
              >
                Submit
              </Button>
            </Flex>
          </form>
        </Box>
      </Box>
    </Flex>
  )
}

export default ChangePassword
