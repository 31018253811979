import React from 'react'
import Arrow from '../index'

const LeftArrow = ({ onClick, hasPreviousItem, ariaLabel }) => {
  return (
    <Arrow
      onClick={onClick}
      isDisabled={!hasPreviousItem}
      ariaLabel={ariaLabel}
      direction="left"
    />
  )
}

export default LeftArrow
