import { Skeleton, Stack } from '@chakra-ui/react'
import React from 'react'

const CalculatedFieldsSkeleton = () => {
  return (
    <Stack py={4} gap={2} minH="400px">
      {Array.from({ length: 5 }).map((_, index) => (
        <Skeleton w="100%" key={index} h="268px" />
      ))}
    </Stack>
  )
}

export default CalculatedFieldsSkeleton
