import React from 'react'
import { useFormContext } from 'react-hook-form'
import FillingFormView from './FillingFormView'
import SummaryView from './SummaryView'

const ReportItem = ({
  fieldName,
  formFieldKey,
  index,
  bodyQuestions,
  headerQuestions,
  setFormFieldKey,
  sidebarRef,
  isDisabled
}) => {
  const { watch } = useFormContext()
  const fields = watch(`${fieldName}.${index}`)
  if (formFieldKey.index === index) {
    return <FillingFormView />
  }
  return (
    <SummaryView
      report={fields}
      headerQuestions={headerQuestions}
      bodyQuestions={bodyQuestions}
      index={index}
      fieldKey={`${fieldName}.${index}`}
      setFormFieldKey={setFormFieldKey}
      ref={sidebarRef}
      isDisabled={isDisabled}
    />
  )
}

ReportItem.defaultProps = {
  bodyQuestions: [],
  headerQuestions: [],
  isDisabled: false
}

export default ReportItem
