import { useTableColumns } from 'hooks'
import { get } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import OlingaAPI from 'services/OlingaAPI'
import FormAnswerView from '../../FormAnswerView'

export const hiddenColumnsKey = 'persisted_columns.asset_responses'

const columnsMapper = ({ original: { id, templateId, formId } }) => ({
  id,
  templateId,
  formId
})

const useAssetResponses = ({ id }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({ pageCount: 0, totalItems: 0 })

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Employee',
        accessor: 'employee'
      },
      {
        Header: 'Date',
        accessor: 'dateTime'
      }
    ],
    hiddenColumnsKey,
    true,
    FormAnswerView,
    columnsMapper,
    ['id']
  )

  const fetchAssetsResponses = useCallback(
    async (params = { page: 1, per_page: 15 }) => {
      setIsLoading(true)
      try {
        const response = await OlingaAPI.assetFormResponses(id, params)
        const {
          data,
          meta: { last: pageCount, count: totalItems }
        } = response
        setData(data)
        setPagination({ pageCount, totalItems })
      } catch (error) {
        setData([])
      } finally {
        setIsLoading(false)
      }
    },
    [id]
  )

  const tableData = useMemo(() => {
    return data.map((row) => {
      const attributes = row?.attributes ?? {}
      const id = row.id
      const employee = get(attributes, 'employee')
      const dateTime = get(attributes, 'dateTime')
      const templateId = get(attributes, 'templateId')
      const formId = get(attributes, 'formId')
      return {
        id,
        dateTime,
        employee,
        formId,
        templateId
      }
    })
  }, [data])

  useEffect(() => {
    fetchAssetsResponses()
  }, [fetchAssetsResponses])

  return {
    fetchAssetsResponses,
    columns,
    hiddenColumns,
    pagination,
    data: tableData,
    isLoading
  }
}

export default useAssetResponses
