import { EditIcon, ViewIcon } from '@chakra-ui/icons'
import { Box, Flex, IconButton, Tooltip } from '@chakra-ui/react'
import { noop, omit } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import DeleteButton from '../../../../components/DeleteButton'
import { getRouteUrl, setFormErrors } from '../../../../helpers'
import Notification from '../../../../services/Notification'
import OlingaAPI from '../../../../services/OlingaAPI'
import ReportDownloadButton from '../../ReportDownloadButton'
import ReportResendButton from '../../ReportResendButton'

const EditReportActions = ({
  id,
  title,
  templateId,
  emails,
  userPermissions,
  afterDelete,
  reportType
}) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const onDeleteReport = async (reportId) => {
    setIsDeleting(true)
    try {
      const response = await OlingaAPI.reportDelete(reportId, reportType)
      if (response) {
        Notification({
          icon: 'success',
          text: response?.message
        })

        afterDelete()
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    } finally {
      setIsDeleting(false)
    }
  }

  useEffect(() => {
    return () => {
      setIsDeleting(false)
    }
  }, [])

  const isReadOnly = userPermissions?.view && !userPermissions?.edit

  return (
    <Flex gap={2}>
      {userPermissions?.view && (
        <Link
          to={getRouteUrl(
            'editTemplateReport',
            omit(
              {
                id,
                type: reportType,
                templateId
              },
              reportType === 'summary' ? ['templateId'] : []
            )
          )}
        >
          <Tooltip hasArrow label={isReadOnly ? 'View report' : 'Edit report'}>
            <IconButton
              icon={isReadOnly ? <ViewIcon /> : <EditIcon />}
              colorScheme="blue"
              size="sm"
              variant="outline"
              aria-label={`Edit template report ${id}`}
            />
          </Tooltip>
        </Link>
      )}
      {userPermissions?.view && reportType === 'scheduled' && (
        <ReportDownloadButton
          id={id}
          variant="icon-only"
          title={title}
          isDisabled={!title}
          reportType={reportType}
        />
      )}
      {userPermissions?.edit && (
        <ReportResendButton
          id={id}
          emails={emails ?? []}
          reportType={reportType}
          variant="icon-only"
        />
      )}
      {userPermissions?.edit && (
        <Tooltip hasArrow label="Delete report">
          <Box>
            <DeleteButton
              index={id}
              onDelete={onDeleteReport}
              isDeleting={isDeleting}
              variant="outline"
              size="sm"
              borderRadius={4}
            />
          </Box>
        </Tooltip>
      )}
    </Flex>
  )
}

export default EditReportActions
