import { Stack } from '@chakra-ui/react'
import React from 'react'
import MobileNavItem from './MobileNavItem'

const MobileNav = ({ navbar, onClose }) => {
  return (
    <Stack bg="white" p={4} display={{ lg: 'none' }}>
      {navbar.menus?.map((navItem, index) => (
        <MobileNavItem
          key={index}
          {...navItem}
          bg={navbar.highlight}
          onClose={onClose}
        />
      ))}
    </Stack>
  )
}

export default MobileNav
