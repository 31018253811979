import { useState } from 'react'
import { useForm } from 'react-hook-form'
import Notification from '../../../../services/Notification'
import OlingaAPI from '../../../../services/OlingaAPI'

const useNewPassword = ({ onSuccess }) => {
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const { register, handleSubmit } = useForm({})

  const onSubmit = async (values) => {
    setIsLoading(true)
    const { email, personable_type } = values
    const params = {
      user: {
        email,
        personable_type
      },
      commit: 'Send',
      authenticity_token: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
    }
    setIsLoading(true)
    try {
      await OlingaAPI.newPassword(params)
      onSuccess()
    } catch (error) {
      Notification({
        icon: 'error',
        text:
          error?.response?.data?.message ||
          'Try again. Email or Profile is incorrect.'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const onError = (error) => {
    setErrors(error)
  }

  return {
    handleSubmit,
    onError,
    onSubmit,
    register,
    errors,
    isLoading
  }
}

export default useNewPassword
