import { ChevronDownIcon } from '@chakra-ui/icons'
import React, { useMemo } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import { getRouteUrl } from '../../../helpers'
import ActionKebab from '../ActionKebab'

const ActionBreadcrumb = ({ action }) => {
  const options = useMemo(() => {
    const breadcrumbOptions = [
      {
        title: 'Actions',
        url: getRouteUrl('actions')
      }
    ]
    if (action?.title) {
      breadcrumbOptions.push({
        title: `${action?.title}`,
        options: (
          <ActionKebab
            id={action?.id}
            size="xs"
            hasBorder={false}
            icon={<ChevronDownIcon />}
          />
        )
      })
      breadcrumbOptions.push({
        title: `Edit action ${action?.id}`
      })
    }
    return breadcrumbOptions
  }, [action?.id, action?.title])
  return <Breadcrumb routeTree={options} />
}

ActionBreadcrumb.defaultProps = {
  action: { id: null, title: '' }
}

export default ActionBreadcrumb
