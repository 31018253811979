/* eslint-disable no-async-promise-executor */
import {
  cloneDeep,
  isArray,
  isEmpty,
  isObject,
  isString,
  noop,
  omit,
  pick,
  trim
} from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { useGlobalStateContext } from '../../../../../../contexts/GlobalStateContext'
import {
  globalEvents,
  onOpenAssetFieldAttributesModal,
  onOpenAssetTemplateFieldsMobileDrawer,
  onOpenSetDefaultMainFieldSelector,
  onSetAssetTemplateActionLoadingState
} from '../../../../../../contexts/GlobalStateContext/events'
import { setFormErrors } from '../../../../../../helpers'
import { useGlobalEventListener } from '../../../../../../hooks'
import Notification from '../../../../../../services/Notification'
import OlingaAPI from '../../../../../../services/OlingaAPI'

export const transformOptions = (field) => {
  if (!isEmpty(field?.settings?.list_options)) {
    return {
      ...field,
      settings: {
        list_options: (field?.settings?.list_options ?? []).map((option) =>
          isObject(option) ? option?.name : isString(option) ? option : ''
        )
      }
    }
  }
  return field
}

const useAssetTemplateFields = ({ id }) => {
  const { global$ } = useGlobalStateContext()

  const {
    watch,
    setValue,
    getValues,
    register,
    formState: { errors }
  } = useFormContext()

  const [renderKeys, setRenderKeys] = useState([])
  const fields = watch('asset_template_fields_attributes')

  useEffect(() => {
    setRenderKeys(fields?.map(() => uuidv4()))
  }, [fields])

  const onRemoveField = async (index) => {
    const originalFields = cloneDeep(fields)
    const clonedFields = cloneDeep(fields)
    const [removedField] = clonedFields.splice(index, 1)
    if (removedField?.id) {
      try {
        await onEditField(index, {
          ...removedField,
          _destroy: true
        })
        if (!clonedFields?.find(({ main }) => main)) {
          global$.next(onOpenSetDefaultMainFieldSelector())
        }
        setValue('asset_template_fields_attributes', [...clonedFields])
      } catch (error) {
        setValue('asset_template_fields_attributes', [...originalFields])
      }
    } else {
      if (!clonedFields?.find(({ main }) => main)) {
        global$.next(onOpenSetDefaultMainFieldSelector())
      }
      setValue('asset_template_fields_attributes', [...clonedFields])
    }
  }

  const onEditField = useCallback(
    async (index, values = {}, isAdd = false) => {
      return new Promise(async (resolve, reject) => {
        const allFields = getValues('asset_template_fields_attributes')
        const clonedFields = cloneDeep(allFields)
        if (clonedFields?.[index]) {
          clonedFields[index] = {
            ...(clonedFields?.[index] ?? {}),
            ...values
          }
        } else {
          clonedFields.push({
            ...values,
            order: index
          })
        }
        if (id) {
          global$.next(onSetAssetTemplateActionLoadingState(true))
          try {
            if (
              isArray(values?.settings?.list_options) &&
              !isEmpty(values?.settings?.list_options)
            ) {
              const options = values?.settings?.list_options?.reduce(
                (acc, option) => {
                  if (isString(option)) {
                    acc.push(trim(option))
                  } else if (option?.name) {
                    const name = trim(option?.name)
                    if (name) {
                      acc.push(name)
                    }
                  }
                  return acc
                },
                []
              )
              clonedFields[index] = {
                ...clonedFields[index],
                settings: {
                  list_options: options
                }
              }
            }
            const response = await OlingaAPI.updateAssetTemplate(id, {
              id,
              asset_template_fields_attributes: isAdd
                ? clonedFields.map((field) =>
                    omit(transformOptions(field), ['uuid'])
                  )
                : [
                    transformOptions(
                      pick(clonedFields[index], ['id', ...Object.keys(values)])
                    )
                  ]
            })
            if (response.status === 200) {
              const newFields = (
                response?.data?.data?.attributes?.asset_template_fields ?? []
              ).map((field) => ({
                ...field,
                notify_days: `${field?.notify_days ?? '30'}`,
                uuid: uuidv4(),
                notify_field_users_attributes: (field?.notify_field_users).map(
                  (f) => ({
                    ...f,
                    user_id: f.user.id,
                    label: f.user.name,
                    value: f.user.id
                  })
                ),
                settings: {
                  list_options: (field?.settings?.list_options ?? []).map(
                    (option) => ({
                      name: isObject(option)
                        ? option?.name
                        : isString(option)
                        ? option
                        : '',
                      _id: uuidv4()
                    })
                  )
                }
              }))
              setValue('asset_template_fields_attributes', newFields)
              Notification({
                icon: 'success',
                text: 'Asset Type Saved Successfully'
              })

              resolve()
            }
          } catch (error) {
            setFormErrors(error, noop, Notification)

            setValue('asset_template_fields_attributes', allFields)

            reject(error)
          } finally {
            global$.next(onSetAssetTemplateActionLoadingState(false))
          }
        } else {
          setValue(`asset_template_fields_attributes.${index}`, {
            ...clonedFields[index],
            ...values
          })

          resolve()
        }
      })
    },
    [getValues, global$, id, setValue]
  )

  const onEditFieldWrapper = useCallback(
    async (index, values, isAdd) => {
      try {
        await onEditField(index, values, isAdd)
      } catch (error) {
        //
      }
    },
    [onEditField]
  )

  useGlobalEventListener(
    useCallback(
      ({ type, payload: newField }) => {
        if (type === globalEvents.ON_ADD_ASSET_TEMPLATE_FIELD) {
          const currentFields = getValues('asset_template_fields_attributes')
          if (id) {
            let index = currentFields.length
            if (newField?.id) {
              index = currentFields.findIndex(({ id }) => id === newField.id)
            }
            onEditFieldWrapper(index, newField, true)
          }

          setValue('asset_template_fields_attributes', [
            ...currentFields,
            { ...newField, order: currentFields.length + 1 }
          ])
        }
        if (type === globalEvents.ON_EDIT_ASSET_TEMPLATE_FIELD) {
          const currentFields = getValues('asset_template_fields_attributes')
          const index = currentFields?.findIndex(
            ({ uuid }) => uuid === newField?.uuid
          )
          if (index >= 0) {
            currentFields.splice(index, 1, newField)
            if (id) {
              onEditFieldWrapper(index, newField, true)
            } else {
              setValue('asset_template_fields_attributes', [...currentFields])
            }
          }
        }
      },
      [getValues, id, onEditFieldWrapper, setValue]
    )
  )

  const onOpenMobileDrawer = () => {
    global$.next(onOpenAssetTemplateFieldsMobileDrawer())
  }

  const onOpenSidebarModal = (field) => {
    global$.next(onOpenAssetFieldAttributesModal(field))
  }

  return {
    fields,
    register,
    errors,
    onRemoveField,
    onOpenMobileDrawer,
    onEditField: onEditFieldWrapper,
    renderKeys,
    setRenderKeys,
    onOpenSidebarModal
  }
}

export default useAssetTemplateFields
