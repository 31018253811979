import React, { useMemo } from 'react'
import { usePageProperties } from '../../hooks'
import TemplateEditorBreadcrumb from './Breadcrumb'
import TemplateForm from './TemplateForm'

const TemplateEditor = ({ data }) => {
  const { template } = data

  const templateDetails = useMemo(() => {
    if (template?.attributes) {
      return {
        name: template.attributes.name,
        userPermissions: template.attributes.user_permissions
      }
    }
    if (template?.id) {
      return {
        name: template.name
      }
    }
    return null
  }, [template])

  const { pageTitle, singularTitle } = usePageProperties({
    pageName: 'templates'
  })

  return (
    <>
      <TemplateEditorBreadcrumb
        pageTitle={pageTitle}
        singularTitle={singularTitle}
        id={template?.id}
        templateDetails={templateDetails}
      />
      <TemplateForm
        pageTitle={pageTitle}
        singularTitle={singularTitle}
        data={data}
      />
    </>
  )
}

export default TemplateEditor
