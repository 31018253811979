/* eslint-disable no-param-reassign */
import actions from '../../actions'

const { SET_EXPANDED_FOLDERS, SET_SHOULD_RELOAD_FOLDERS } = actions.templates

const initialState = {
  folders: [],
  shouldReloadFolders: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXPANDED_FOLDERS: {
      const keys = action.payload
      state = { ...state, folders: keys }
      return state
    }
    case SET_SHOULD_RELOAD_FOLDERS: {
      state = { ...state, shouldReloadFolders: action.payload }
      return state
    }
    default:
      return state
  }
}

export default reducer
