import { DeleteIcon } from '@chakra-ui/icons'
import {
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VisuallyHidden
} from '@chakra-ui/react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Dialog from '../../../../../components/AlertDialog'
import Sidebar from '../../../../../components/Sidebar'
import { useQuestionActions } from '../../ActionsContext'
import ActionStatusTag from '../ActionStatusTag'
import { getCurrentValue } from '../helpers'
import RemoveActionHandler from '../RemoveActionHandler'
import ActionEditor from './ActionEditor'
import ActionSummaryCard from './ActionSummaryCard/index.jsx'
import { TAB_DETAILS } from './constants'
import MaterialIcon from 'components/MaterialIcon'

const ActionSidebar = ({ isOpen, onClose, onOpen, children, action }) => {
  const ref = useRef()
  const [tabIndex, setTabIndex] = useState(0)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const { currentQuestion, childIndex } = useQuestionActions()
  const dialogTriggerRef = useRef(null)
  const saveButtonRef = useRef(null)

  const user = useSelector(({ globalReducer }) => globalReducer.user)

  const { setValue, getValues, watch } = useFormContext()

  const handleTabChange = (index) => {
    setTabIndex(index)
  }

  const currentValue = watch(action.currentKey)

  const notes = watch(`${action.currentKey}.notes`)
  const files = watch(`${action.currentKey}.files`)

  const handleActionEdit = (newData) => {
    const actionKey = `${action.currentKey}.actions`
    const actions = getValues(actionKey)
    const clonedActions = _.cloneDeep(actions)
    let actionToUpdate = clonedActions[childIndex]
    actionToUpdate = { ...actionToUpdate, ...newData }

    clonedActions.splice(childIndex, 1, actionToUpdate)

    setValue(actionKey, clonedActions)

    onClose()
  }

  const handleDialogClose = (closeDialog) => {
    closeDialog()
    onClose()
    setHasUnsavedChanges(false)
  }

  const handleExternalSave = (closeDialog) => {
    if (saveButtonRef?.current?.click) {
      saveButtonRef.current.click()
    }
    closeDialog()
  }

  const handleClose = () => {
    if (hasUnsavedChanges) {
      dialogTriggerRef.current.click()
    } else {
      onClose()
    }
  }

  const handleAfterRemove = () => {
    onClose()
  }

  useEffect(() => {
    return () => {
      setTabIndex(0)
      setHasUnsavedChanges(false)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      setTabIndex(TAB_DETAILS)
    }
  }, [isOpen])

  return (
    <>
      <Dialog
        ref={ref}
        header="You have unsaved changes!"
        body="Do you want to save those changes ?"
        handleClose={handleDialogClose}
        footer={
          <Button colorScheme="red" onClick={handleExternalSave}>
            Continue
          </Button>
        }
        cancelLabel="Discard"
        continueColor="green"
        continueLabel="Yes"
        onContinue={handleExternalSave}
      >
        <VisuallyHidden>
          <Button ref={dialogTriggerRef}>Open dialog</Button>
        </VisuallyHidden>
      </Dialog>
      {children}
      <Sidebar
        isOpen={isOpen}
        onClose={handleClose}
        onOpen={onOpen}
        size="md"
        header={
          action && (
            <Heading isTruncated size="md">
              {action.title}
            </Heading>
          )
        }
        body={
          action && (
            <Stack w="100%">
              <Stack py={2} px={6}>
                <Flex align="center" justify="space-between">
                  {user?.email && (
                    <Heading size="xs">Created by: {user?.email}</Heading>
                  )}
                  <ActionStatusTag status={action.status} />
                </Flex>
              </Stack>
              <Divider />
              <Tabs index={tabIndex} onChange={handleTabChange}>
                <TabList>
                  <Tab>Details</Tab>
                  <Tab>Summary</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel aria-label="History tab">
                    <ActionEditor
                      setHasUnsavedChanges={setHasUnsavedChanges}
                      onEdit={handleActionEdit}
                      action={action}
                      ref={saveButtonRef}
                      hasFullWidthTitle
                      isCreator
                    />
                  </TabPanel>
                  <TabPanel aria-label="History tab">
                    <Stack>
                      <ActionSummaryCard
                        files={files}
                        notes={notes}
                        action={{
                          ...action,
                          currentValue: getCurrentValue(
                            action.fieldType,
                            currentValue
                          )
                        }}
                        questionTitle={currentQuestion?.text}
                      />
                      <Button
                        variant="outline"
                        colorScheme="blue"
                        size="xs"
                        leftIcon={<MaterialIcon icon='edit' />}
                        onClick={() => handleTabChange(TAB_DETAILS)}
                      >
                        View action details
                      </Button>
                    </Stack>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Stack>
          )
        }
        footer={
          <RemoveActionHandler
            currentKey={action.currentKey}
            index={childIndex}
            onRemove={handleAfterRemove}
          >
            <Button leftIcon={<DeleteIcon />} colorScheme="red">
              Delete action
            </Button>
          </RemoveActionHandler>
        }
      ></Sidebar>
    </>
  )
}

ActionSidebar.defaultProps = {
  isOpen: false,
  onOpen: () => ({}),
  onClose: () => ({}),
  action: {},
  onActionEdit: () => ({})
}

ActionSidebar.propTypes = {
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  action: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  index: PropTypes.number,
  onActionEdit: PropTypes.func
}

export default ActionSidebar
