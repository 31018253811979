import { Box, Flex, FormControl, FormLabel, Select, Stack, Switch } from "@chakra-ui/react"
import PageHeader from "components/PageHeader"
import { FormProvider } from "react-hook-form"
import AssetDefaultFieldEditorBreadcrumb from "./AssetDefaultFieldEditorBreadcrumb"
import useAssetDefaultFieldEditor from "./hooks/useAssetDefaultFieldEditor"

import FormField from "components/FormField"
import FormSubmitActions from "components/FormSubmitActions"
import EditInline from "components/Inputs/EditInline"
import ListBuilder from "components/ListBuilder"
import React from 'react'
import ItemOption from "./ItemOption"

const AssetDefaultFieldEditor = ({ data, id }) => {
  const {
    fieldTypes,
    formProps,
    onSubmitForm,
    breadcrumbName,
    isLoading,
    focusedFieldIndex,
    onChangeFieldOptionsList,
    onAddFieldOption,
    shouldRenderForm,
  } = useAssetDefaultFieldEditor({
    data,
    id
  })

  const {
    register,
    watch,
    setValue,
    setFocus,
    formState: { errors }
  } = formProps

  const [name, fieldType, options] = watch(['name', 'field_type', 'settings.list_options'])

  if (!shouldRenderForm) {
    return null
  }

  return (
    <Box minH="100vh">
      <PageHeader
        Breadcrumb={
          <AssetDefaultFieldEditorBreadcrumb
            id={id}
            name={breadcrumbName}
          />
        }
      />
      <FormProvider {...formProps}>
        <Stack
          as="section"
          w={['100%', '100%', 'calc(100% - 448px)', 'calc(100% - 416px)']}
          h="100%"
          pb={16}
        >
          <Stack w="100%" h="100%" flex="1 1 auto">
            <Stack gap={4} mb={4}>
              <Flex
                flexDir={['column', 'row']}
                gap={4}
                align={['flex-start', 'center']}
              >
                <EditInline
                  width="100%"
                  justify={false}
                  placeholder="Name"
                  isInvalid={!!errors?.name}
                  defaultValue={name}
                  registerProps={{
                    ...register('name', { required: true })
                  }}
                  isDisabled={isLoading}
                />
                <Switch
                  minW="100px"
                  mb="0!important"
                  {...register('main')}
                  colorScheme="green"
                  isDisabled={isLoading}
                >
                  Main
                </Switch>
                <Switch
                  minW="100px"
                  mb="0!important"
                  {...register('active')}
                  colorScheme="green"
                  isDisabled={isLoading}
                >
                  Active
                </Switch>
              </Flex>
            </Stack>
            <Stack>
            <FormField label="Field type" isRequired>
              <Select {...register('field_type')} flex={1}>
                {fieldTypes.map(({ label, value }, index) => {
                  return (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  )
                })}
              </Select>
              </FormField>
              {fieldType === 'list' && (
                <FormControl>
                  <FormLabel pt='20px'>Options</FormLabel>
                  <ListBuilder
                    list={options ?? []}
                    allowDrag={() => true}
                    ItemComponent={ItemOption}
                    onRemoveItem={(values) => {
                      setValue('options', values)
                    }}
                    onChange={onChangeFieldOptionsList}
                    onAddItem={onAddFieldOption}
                    idAttribute="_id"
                    itemProps={{
                      fieldKey: 'settings.list_options',
                      register,
                      setFocus,
                      focusedFieldIndex
                    }}
                    customRemoveItemAction={(index, _, fallback) =>
                      fallback(index)
                    }
                  />
                </FormControl>
              )}
              <Flex pt='20px' w='100%' justify='flex-end'>
                <FormSubmitActions
                  cancelRoute="assetDefaultFields"
                  isAdmin
                  onSubmit={onSubmitForm}
                />
              </Flex>
            </Stack>
          </Stack>
        </Stack>
      </FormProvider>
    </Box>
  )
}

export default AssetDefaultFieldEditor
