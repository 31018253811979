import { EditIcon, ViewIcon } from '@chakra-ui/icons'
import MaterialIcon from '../../../components/MaterialIcon'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import Kebab from '../../../components/Kebab'

const ChecklistKebab = ({
  id,
  size,
  icon,
  zIndex,
  hasBorder,
  blackList,
  pageTitle,
  hasPermissionToModifyChecklist,
  hasPermissionToShowChecklist,
  renderPortal = false,
}) => {
  const location = useLocation()

  const currentRoute = useMemo(() => {
    const path = location.pathname
    if (path.match('new')) {
      return 'createChecklist'
    } else if (path.match('edit')) {
      return 'editChecklist'
    }
    return undefined
  }, [location])

  const routes = useMemo(() => {
    let items = [
      {
        name: `Edit ${pageTitle}`,
        icon: <EditIcon />,
        route: 'editChecklist',
        isVisible: hasPermissionToModifyChecklist
      },
      {
        name: `Show ${pageTitle}`,
        icon: <ViewIcon />,
        route: 'showChecklist',
        isVisible: hasPermissionToShowChecklist
      },
      {
        name: 'Task Tracker',
        icon: <MaterialIcon icon='list_alt' styles={{ fontSize: '16px' }} />,
        route: 'checklistTaskTraker',
        isVisible: hasPermissionToShowChecklist
      }
    ]
    if (!id) {
      items = items.filter(({ route }) => ['createChecklist'].includes(route))
    }
    if (blackList?.length) {
      return items.filter(({ route }) =>
        blackList.find(({ route: bRoute }) => bRoute !== route)
      )
    }
    return items.filter(({ isVisible }) => isVisible)
  }, [
    pageTitle,
    hasPermissionToModifyChecklist,
    hasPermissionToShowChecklist,
    id,
    blackList
  ])

  if (!hasPermissionToModifyChecklist) {
    return null
  }

  return (
    <Kebab
      id={id}
      icon={icon}
      size={size}
      zIndex={zIndex}
      hasBorder={hasBorder}
      items={routes}
      blackList={[currentRoute]}
      blackListAttribute="route"
      hasVisibilityRestriction
      renderPortal={renderPortal}
    />
  )
}

ChecklistKebab.defaultProps = {
  size: 'sm',
  zIndex: 10,
  hasBorder: true,
  icon: <MaterialIcon icon='more_vert' />,
  blackList: [],
  hasPermissionToModifyChecklist: true
}

ChecklistKebab.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  blackList: PropTypes.array,
  hasPermissionToModifyChecklist: PropTypes.bool
}

export default ChecklistKebab
