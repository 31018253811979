import { Tag } from '@chakra-ui/react'
import { setFormErrors } from 'helpers'
import { useTableColumns, useTableFilter } from 'hooks'
import { noop } from 'lodash'
import React, { useCallback } from 'react'
import Notification from 'services/Notification'
import AdminOlingaAPI from '../../../../../services/admin/AdminOlingaAPI'
import EditAssetDefaultFieldButton from '../../EditAssetDefaultFieldButton'

const useAssetsDefaultFields = () => {
  const fieldsExtractor = useCallback((data) => {
    return data.map(
      ({ attributes: { id, name, active, field_type, main } }) => {
        return {
          id,
          name,
          active: (
            <Tag colorScheme={active ? 'green' : 'gray'}>
              {active ? 'Active' : 'Inactive'}
            </Tag>
          ),
          main: (
            <Tag colorScheme={main ? 'green' : 'gray'}>
              {main ? 'Yes' : 'No'}
            </Tag>
          ),
          field_type
        }
      }
    )
  }, [])

  const {
    refetch,
    isLoading,
    updateParams,
    fetchParams,
    tableData,
    pagination
  } = useTableFilter({
    id: null,
    fetchCallback: AdminOlingaAPI.assetsDefaultFields,
    fieldsExtractor,
    initialParams: {
      page: 1,
      per_page: 15
    }
  })

  const onDelete = async (id) => {
    try {
      const response = await AdminOlingaAPI.deleteAssetDefaultField(id)
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: response?.data?.message
        })
        refetch()
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    }
  }

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Field Type',
        accessor: 'field_type'
      },
      {
        Header: 'Active',
        accessor: 'active'
      },
      {
        Header: 'Main',
        accessor: 'main'
      }
    ],
    '',
    true,
    EditAssetDefaultFieldButton,
    ({ original: { id } }) => ({
      id,
      onDelete
    }),
    []
  )

  return {
    columns,
    hiddenColumns,
    data: tableData,
    isLoading,
    refetch,
    updateParams,
    fetchParams,
    pagination
  }
}

export default useAssetsDefaultFields
