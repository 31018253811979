import { useMemo } from 'react'
import { usePageProperties } from '../../../../hooks'
import { transformUsers } from '../../../../pages/Form/FillForm/ActionsBar/helpers'

const useActionDetails = ({ data }) => {
  const action = useMemo(() => {
    if (data?.attributes) {
      return {
        ...data?.attributes,
        users: transformUsers(data?.attributes.users.data),
        templateId:
          data?.attributes?.templateQuestion?.data?.attributes?.templateId
      }
    }
    return {}
  }, [data])

  const { pageTitle, singularTitle } = usePageProperties({
    pageName: 'actions'
  })

  return {
    action,
    pageTitle,
    singularTitle
  }
}

export default useActionDetails
