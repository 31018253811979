import { AddIcon } from '@chakra-ui/icons'
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure
} from '@chakra-ui/react'
import { get, isEmpty, isFunction } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import { getRouteUrl } from '../../../helpers'
import OlingaAPI from '../../../services/OlingaAPI'
import InnerSelection from './InnerSelection'
import { ACTIONS, initialState, templateSelectorReducer } from './reducer'

const {
  SET_FOLDERS_LIST,
  SET_IS_LOADING_FOLDERS_LIST,
  SET_FOLDER,
  SET_IS_LOADING_TEMPLATES_LIST,
  SET_TEMPLATES_LIST,
  SET_TEMPLATE
} = ACTIONS

const TemplateSelector = ({
  mode,
  onChange,
  value,
  flexDirection,
  hasClear,
  reportType
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure({
    defaultIsOpen: mode === 'selection'
  })
  const [
    {
      foldersList,
      folder,
      isLoadingFoldersList,
      templatesList,
      isLoadingTemplatesList
    },
    dispatch
  ] = useReducer(templateSelectorReducer, initialState)

  const history = useHistory()

  const fetchFoldersTemplate = useCallback(async (folderId) => {
    const setTemplatesLoading = (payload) => {
      dispatch({
        type: SET_IS_LOADING_TEMPLATES_LIST,
        payload
      })
    }
    setTemplatesLoading(true)
    try {
      const res = await OlingaAPI.formFolderTemplatesList(folderId)
      if (res?.data) {
        dispatch({
          type: SET_TEMPLATES_LIST,
          payload: res.data.map(
            ({ attributes: { id: value, name: label } }) => ({ label, value })
          )
        })
      }
    } catch (err) {
      //
    } finally {
      setTemplatesLoading(false)
    }
  }, [])

  useEffect(() => {
    let mounted = true
    const fetchFoldersList = async () => {
      dispatch({
        type: SET_IS_LOADING_FOLDERS_LIST,
        payload: true
      })
      try {
        const res = await OlingaAPI.foldersEnumerator('templates')
        if (!isEmpty(res) && mounted) {
          dispatch({
            type: SET_FOLDERS_LIST,
            payload: res
          })
        }
      } catch (err) {
        //
      } finally {
        if (mounted) {
          dispatch({
            type: SET_IS_LOADING_FOLDERS_LIST,
            payload: false
          })
        }
      }
    }
    if (isOpen && !foldersList?.length) {
      fetchFoldersList()
    }

    return () => {
      if (!isOpen) {
        mounted = false
      }
    }
  }, [foldersList?.length, isOpen])

  const onFolderSelect = ({ value }) => {
    dispatch({ type: SET_FOLDER, payload: value })
    fetchFoldersTemplate(value)
  }

  const onTemplateSelect = (selectedTemplate) => {
    const id = get(selectedTemplate, 'value')
    dispatch({ type: SET_TEMPLATE, payload: id })
    if (isFunction(onChange)) {
      onChange(selectedTemplate)
      return
    }
    history.push(
      getRouteUrl('newTemplateReport', {
        type: reportType,
        searchParams: `?template_id=${id}`
      })
    )
  }

  if (mode === 'navigation') {
    return (
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement="bottom"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Button colorScheme="blue" leftIcon={<AddIcon />}>
            New
          </Button>
        </PopoverTrigger>
        <PopoverContent p={5}>
          <PopoverArrow />
          <PopoverCloseButton />
          <InnerSelection
            foldersList={foldersList}
            onFolderSelect={onFolderSelect}
            isLoadingFoldersList={isLoadingFoldersList}
            templatesList={templatesList}
            onTemplateSelect={onTemplateSelect}
            folder={folder}
            isLoadingTemplatesList={isLoadingTemplatesList}
            flexDirection={flexDirection}
          />
        </PopoverContent>
      </Popover>
    )
  }

  return (
    <InnerSelection
      foldersList={foldersList}
      onFolderSelect={onFolderSelect}
      isLoadingFoldersList={isLoadingFoldersList}
      templatesList={templatesList}
      onTemplateSelect={onTemplateSelect}
      folder={folder}
      isLoadingTemplatesList={isLoadingTemplatesList}
      flexDirection={flexDirection}
      selectedTemplate={value}
      hasClear={hasClear}
    />
  )
}

TemplateSelector.defaultProps = {
  mode: 'navigation',
  onChange: null,
  value: null,
  flexDirection: 'column',
  hasClear: false
}

export const flexDirectionOptions = [
  'column',
  'row',
  'row-reverse',
  'column-reverse'
]

TemplateSelector.propTypes = {
  mode: PropTypes.oneOf(['navigation', 'selection']),
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  flexDirection: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(flexDirectionOptions)),
    PropTypes.oneOf(flexDirectionOptions)
  ]),
  hasClear: PropTypes.bool
}

export default TemplateSelector
