import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Collapse,
  Flex,
  Icon,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import MaterialIcon from 'components/MaterialIcon'
import { isEmpty } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

const MobileNavItem = ({ name, url, icon, dropdown, onClose, bg }) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Stack spacing={4} onClick={!isEmpty(dropdown) ? onToggle : onClose}>
      <Link to={url ?? '#'}>
        <Flex
          gap={2}
          alignItems="center"
          _hover={{
            textDecoration: 'none'
          }}
          py={2}
          color={bg}
        >
          <MaterialIcon icon={icon} styles={{ fontSize: '16px' }} />
          <Text fontWeight={600}>{name}</Text>
          {!isEmpty(dropdown) && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Flex>
      </Link>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor="gray.200"
          align={'start'}
          gap={2}
        >
          {!isEmpty(dropdown) &&
            dropdown.map(({ name, url, icon }, index) => (
              <Link key={index} to={url}>
                <Flex
                  as="button"
                  color={bg}
                  align="center"
                  gap={2}
                  onClick={onClose}
                >
                  <MaterialIcon icon={icon} styles={{ fontSize: '16px' }} />
                  <Text color={bg}>{name}</Text>
                </Flex>
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}

export default MobileNavItem
