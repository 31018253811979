import React from 'react'
import { Button, SimpleGrid } from '@chakra-ui/react'
import { get } from 'lodash'
import PropTypes from 'prop-types'

const ToggleValueModalBody = ({
  options,
  value,
  attributeKey,
  onChange,
  colorScheme,
  columns
}) => {
  return (
    <SimpleGrid columns={columns} spacing={4}>
      {options.map((option) => (
        <Button
          isDisabled={option?.disabled}
          colorScheme={colorScheme}
          onClick={() => onChange(get(option, attributeKey))}
          variant={get(option, attributeKey) === value ? 'solid' : 'outline'}
          leftIcon={option?.icon || null}
          key={option?.value}
        >
          {option?.label}
        </Button>
      ))}
    </SimpleGrid>
  )
}

const ToggleMultiValuesModalBody = ({
  options,
  value,
  attributeKey,
  onChange,
  colorScheme,
  columns
}) => {
  return (
    <SimpleGrid columns={columns} spacing={4}>
      {options.map((option) => (
        <Button
          isDisabled={option?.disabled}
          colorScheme={colorScheme}
          onClick={() => onChange(get(option, attributeKey))}
          variant={
            value.includes(get(option, attributeKey)) ? 'solid' : 'outline'
          }
          leftIcon={option?.icon || null}
          key={option?.value}
        >
          {option?.label}
        </Button>
      ))}
    </SimpleGrid>
  )
}

ToggleValueModalBody.defaultValues = {
  columns: [2, 2, 4]
}

ToggleValueModalBody.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
      disabled: PropTypes.bool
    })
  ),
  attributeKey: PropTypes.string,
  colorScheme: PropTypes.string
}

ToggleValueModalBody.Multiple = ToggleMultiValuesModalBody

export default ToggleValueModalBody
