import React from 'react'
import BasePage from '../../../pages/BasePage'
import FormFolderEditor from '../../../pages/FormFolder/FormFolderEditor'
import OlingaAPI from '../../../services/OlingaAPI'

const FormFoldersPage = ({ id }) => {
  const callbacks = id
    ? {
        formFolder: { callback: OlingaAPI.getFormFolder, params: { index: true } },
        groups: { callback: OlingaAPI.groupsEnumerators }
      }
    : {
        groups: { callback: OlingaAPI.groupsEnumerators }
      }
  return (
    <BasePage
      fetchWithoutId
      id={id}
      resourceName="data"
      fetchCallback={callbacks}
    >
      <FormFolderEditor />
    </BasePage>
  )
}

export default FormFoldersPage
