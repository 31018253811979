import * as Sentry from '@sentry/react'
import ReactOnRails from 'react-on-rails'
import AdminApp from '../AdminApp'
import App from '../App'
import HomePage from '../pages/Home/HomeProvider'
import AdminLogin from '../pages/admin/Home/HomeProvider'

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://6bd796a8dc80424d8a22cccfe0e301c4@o236558.ingest.sentry.io/5904974',
    integrations: [Sentry.replayIntegration()],
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0
  })
}

ReactOnRails.register({
  AdminApp,
  AdminLogin,
  App,
  HomePage
})
