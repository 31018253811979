import { Text } from '@chakra-ui/react'
import _ from 'lodash'
import React from 'react'

const FormErrorMessage = ({ message = null }) => {
  if (!message) {
      return null
  }

  return (
    <Text color="red.500" ml={1}>
      {_.isArray(message) ? message.join(', ') : message}
    </Text>
  )
}

export default FormErrorMessage
