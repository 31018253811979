import { v4 as uuid } from 'uuid'

export class IndexMap {
  map = {}
  inverseMap = {}

  setItem(index, key) {
    this.map[index] = key
    this.inverseMap[key] = index
  }

  getKey(index) {
    return this.map[index]
  }

  getIndex(key) {
    return this.inverseMap[key]
  }

  clearKeys() {
    this.map = {}
    this.inverseMap = {}
  }

  getUniqKey(nodeKey) {
    const nodeIndex = this.getIndex(nodeKey)
    if (nodeIndex) {
      return nodeIndex
    }
    const key = uuid()
    if (this.getKey(key)) {
      return this.getUniqKey(nodeKey)
    }
    this.setItem(key, nodeKey)
    return key
  }
}
