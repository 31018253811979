import { Button, Flex, Stack } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import React from 'react'
import { defaultSelectStyles } from '../styles'

const InnerSelection = ({
  foldersList,
  onFolderSelect,
  isLoadingFoldersList,
  templatesList,
  onTemplateSelect,
  folder,
  isLoadingTemplatesList,
  selectedTemplate,
  flexDirection,
  hasClear
}) => {
  return (
    <Stack mt={4} gap={4} flexDirection={flexDirection} w="100%">
      <Flex {...defaultSelectStyles}>
        <Select
          options={foldersList}
          onChange={onFolderSelect}
          placeholder="Select a folder"
          isLoading={isLoadingFoldersList}
        />
      </Flex>
      <Flex {...defaultSelectStyles} m="0!important">
        <Select
          options={templatesList}
          onChange={onTemplateSelect}
          placeholder="Select a form"
          isDisabled={!folder}
          isLoading={isLoadingTemplatesList}
          value={selectedTemplate}
        />
      </Flex>
      {selectedTemplate?.value && hasClear && (
        <Button
          minW="200px"
          mt="0!important"
          onClick={() => onTemplateSelect(null)}
        >
          Clear
        </Button>
      )}
    </Stack>
  )
}

export default InnerSelection
