import { Box, Stack, Text } from '@chakra-ui/react'
import { capitalize, isEmpty, keys } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import ResultLine from '../ResultLine'

const ResultGrouped = (props) => {
  const { results } = props
  if (isEmpty(results)) {
    return null
  }
  return keys(results)
    .sort()
    .map((key, index) => {
      return (
        <Stack key={index}>
          <Box p="2" borderBottom="1px" borderBottomColor="gray.300">
            <Text data-testid='autocomplete-result-group' fontSize="xs" color="blue.400">
              {capitalize(key)}
            </Text>
          </Box>
          <ResultLine {...props} results={results[key]} />
        </Stack>
      )
    })
}

ResultGrouped.propTypes = {
  isGrouped: PropTypes.bool,
  results: PropTypes.any,
  attributeName: PropTypes.string,
  handleSelect: PropTypes.func,
  resultEndAdornment: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.number
  ]),
  onClose: PropTypes.func
}

export default ResultGrouped
