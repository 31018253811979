import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  SimpleGrid,
  Stack,
  Switch,
  Tag
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import React from 'react'
import { Link } from 'react-router-dom'
import FormErrorMessage from '../../../components/FormErrorMessage'
import EditInline from '../../../components/Inputs/EditInline'
import LazyComponent from '../../../components/LazyComponent'
import { getRouteUrl } from '../../../helpers'
import ChecklistPageHeader from '../PageHeader'
import ChecklistItemsList from './ChecklistItemsList'
import FakeInput from './ChecklistItemsList/ChecklistItem/FakeInput'
import useChecklistEditor from './useChecklistEditor'

const ChecklistEditor = (data) => {
  const {
    register,
    watch,
    setValue,
    formFolders,
    formFolder,
    onFormFolderSelect,
    groups,
    isLoadingGroups,
    checklist,
    frequencies,
    handleSubmit,
    onSubmit,
    isLoading,
    onChangeGroups,
    onRemoveChecklistItem,
    errors,
    limitForCompletionLimit,
    id,
    readOnly,
    isEdit,
    singularTitle,
    pageTitle
  } = useChecklistEditor(data)

  const [name, formFolderId, selectedGroups] = watch([
    'name',
    'form_folder_id',
    'checklist_groups_attributes'
  ])
  return (
    <Container maxW="1200px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack as="section">
          <ChecklistPageHeader
            readOnly={readOnly}
            id={checklist?.id}
            name={name}
            singularTitle={singularTitle}
            pageTitle={pageTitle}
          />
          <Stack border="2px solid #CBD5E0" p={4} borderRadius={4}>
            <Flex
              flexDir={['column', 'column', 'row']}
              py={4}
              justify={['flex-start', 'space-between']}
              align="center"
              gap={4}
            >
              <Flex w="100%">
                <Box w='100%'>
                  <FormControl>
                    <EditInline
                      placeholder="Type a name"
                      startWithEditView={!name}
                      autoFocus={!name}
                      defaultValue={name}
                      registerProps={{ ...register('name') }}
                      justify={false}
                      isInvalid={!!errors?.name?.message}
                      isDisabled={readOnly}
                    />
                  </FormControl>
                  <FormErrorMessage message={errors?.name?.message} />
                </Box>
              </Flex>
              <Switch
                colorScheme="green"
                {...register('active')}
                isInvalid={!!errors?.active?.message}
                id="active"
                cursor="pointer"
                minW="100px"
                mb="0"
                isDisabled={readOnly}
              >
                Active
              </Switch>
            </Flex>
            <Divider />
            {!readOnly ? (
              <SimpleGrid py={4} columns={[1, 2]} spacing={4}>
                <LazyComponent condition={!!formFolders?.length}>
                  <Box>
                    <FormControl>
                      <FormLabel htmlFor="form_folder_id">Folder</FormLabel>
                      <Select
                        options={formFolders}
                        {...register(`form_folder_id`, { required: true })}
                        onChange={onFormFolderSelect}
                        defaultValue={formFolderId}
                        hideSelectedOptions
                        placeholder="Select a form folder"
                        isInvalid={!!errors?.form_folder_id?.message}
                      />
                    </FormControl>
                    <FormErrorMessage message={errors?.form_folder?.message || errors?.form_folder_id?.message} />
                  </Box>
                  
                </LazyComponent>
                {!isLoadingGroups && !!groups?.length ? (
                  <FormControl>
                    <FormLabel htmlFor="groups">Groups</FormLabel>
                    <Select
                      isMulti
                      options={groups}
                      {...register(`checklist_groups_attributes`)}
                      onChange={onChangeGroups}
                      defaultValue={selectedGroups}
                      hideSelectedOptions
                      placeholder="Select groups"
                      key={selectedGroups}
                    />
                  </FormControl>
                ) : (
                  <FormControl disabled>
                    <FormLabel htmlFor="groups">Groups</FormLabel>
                    <Select
                      isDisabled
                      options={[]}
                      placeholder="Select a folder first"
                    />
                  </FormControl>
                )}
              </SimpleGrid>
            ) : (
              <SimpleGrid py={4} columns={[1, 2]} spacing={4}>
                <LazyComponent condition={formFolder}>
                  <FormControl>
                    <FormLabel>Folder</FormLabel>
                    <FakeInput minHeight="42px">{formFolder?.label}</FakeInput>
                  </FormControl>
                </LazyComponent>
                {selectedGroups && (
                  <LazyComponent condition={selectedGroups}>
                    <FormControl>
                      <FormLabel>Groups</FormLabel>
                      <FakeInput>
                        {selectedGroups.map(({ label }, index) => (
                          <Tag key={index}>{label}</Tag>
                        ))}
                      </FakeInput>
                    </FormControl>
                  </LazyComponent>
                )}
              </SimpleGrid>
            )}
          </Stack>
          <Stack py={4} borderRadius={4}>
            {frequencies?.length > 0 && (
              <ChecklistItemsList
                listKey={'checklist_items_attributes'}
                watch={watch}
                setValue={setValue}
                register={register}
                frequencies={frequencies}
                onRemoveChecklistItem={onRemoveChecklistItem}
                limitForCompletionLimit={limitForCompletionLimit}
                id={id}
                readOnly={readOnly}
                isEdit={isEdit}
              />
            )}
          </Stack>
          <Flex gap={4}>
            <Link to={getRouteUrl('checklists')}>
              <Button colorScheme="gray">Cancel</Button>
            </Link>
            {!readOnly && (
              <Button isLoading={isLoading} colorScheme="blue" type="submit">
                Save
              </Button>
            )}
          </Flex>
        </Stack>
      </form>
    </Container>
  )
}

export default ChecklistEditor
