import FormAnswers from 'pages/FormAnswers'
import React from 'react'
import OlingaAPI from 'services/OlingaAPI'
import BasePage from '../../../pages/BasePage'

const FormAnswersPage = ({ templateId }) => {
  return (
    <BasePage
      id={templateId}
      resourceName="data"
      fetchCallback={{
        forms: {
          callback: () =>
            OlingaAPI.formsList(templateId, {
              page: 1,
              per_page: 15
            })
        }
      }}
      otherParams={{
        id: templateId
      }}
    >
      <FormAnswers />
    </BasePage>
  )
}

export default FormAnswersPage
