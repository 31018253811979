import colors from '../../../utils/colors'
import styled from '@emotion/styled'

export const ButtonWrapper = styled.span`
  & button {
    color: ${colors.primary};
    border: hidden;

    &:hover {
      background-color: transparent;
    }
  }
`
