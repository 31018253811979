import React from 'react'
import FloatingLabelBox from '../../../../components/FloatingLabelBox'
import { Box, Stack, Text } from '@chakra-ui/react'
import Field from '../Field'

const BusinessCard = ({ id, tradingName, businessName }) => {
  if (!id) {
    return null
  }
  return (
    <Box p={4} shadow="md">
      <FloatingLabelBox label="Business">
        <Stack gap={2} w="100%">
          <Field label="ID">
            {
              <Text fontSize="sm" isTruncated>
                {id}
              </Text>
            }
          </Field>
          <Field label="Trading name">
            {
              <Text fontSize="sm" isTruncated>
                {tradingName}
              </Text>
            }
          </Field>
          <Field label="Business name">
            {
              <Text fontSize="sm" isTruncated>
                {businessName}
              </Text>
            }
          </Field>
        </Stack>
      </FloatingLabelBox>
    </Box>
  )
}

export default BusinessCard
