import { Heading, SimpleGrid } from '@chakra-ui/react'
import React from 'react'

const Field = ({ label, children }) => {
  return (
    <SimpleGrid columns={{ sm: 1, md: 2 }}>
      <Heading size="xs" isTruncated>
        {label}:
      </Heading>
      {children}
    </SimpleGrid>
  )
}

export default Field
