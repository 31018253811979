import React, { useMemo } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import { getRouteUrl } from '../../../../helpers'

const ManageDashboardEditorBreadcrumb = ({ id, hasPermissionToEdit }) => {
  const options = useMemo(() => {
    const items = [
      {
        title: 'Manage Dashboards',
        url: getRouteUrl('manageDashboards')
      }
    ]
    if (id) {
      items.push({
        title: hasPermissionToEdit ? 'Edit Dashboard' : 'View Dashboard'
      })
    } else {
      items.push({
        title: `New Dashboard`
      })
    }
    return items
  }, [id, hasPermissionToEdit])
  return <Breadcrumb routeTree={options} />
}

export default ManageDashboardEditorBreadcrumb
