import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Checkbox,
  Divider,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Stack,
  Text
} from '@chakra-ui/react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
  cloneElement,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'
import { FixedSizeList as List } from 'react-window'
import { v4 as uuidv4 } from 'uuid'

const TransferList = ({
  leftList,
  leftListItemAdornment,
  rightList,
  rightListItemAdornment,
  onChange,
  itemAttribute,
  listHeight,
  isLoading
}) => {
  const [lList, setLList] = useState(
    leftList.map((item) => ({ ...item, isChecked: false, uuid: uuidv4() }))
  )
  const [rList, setRList] = useState(
    rightList.map((item) => ({ ...item, isChecked: false, uuid: uuidv4() }))
  )

  const [leftListSearchText, setLeftListSearchText] = useState('')
  const [rightListSearchText, setRightListSearchText] = useState('')

  const shouldDisableTransferRightButton = useMemo(() => {
    return (
      isLoading ||
      lList.length === 0 ||
      !lList.some(({ isChecked }) => isChecked) ||
      rightListSearchText.length > 0
    )
  }, [isLoading, lList, rightListSearchText.length])
  const shouldDisableTransferLeftButton = useMemo(() => {
    return (
      isLoading ||
      rList.length === 0 ||
      !rList.some(({ isChecked }) => isChecked) ||
      leftListSearchText.length > 0
    )
  }, [isLoading, leftListSearchText.length, rList])
  const isAllLeftItemsSelected = useMemo(() => {
    return lList.length > 0 && lList.every(({ isChecked }) => isChecked)
  }, [lList])
  const isAllRightItemsSelected = useMemo(() => {
    return rList.length > 0 && rList.every(({ isChecked }) => isChecked)
  }, [rList])

  const leftListRef = useRef(null)
  const rightListRef = useRef(null)

  const beforeSearchLists = useRef({ left: lList, right: rList })

  const handleTransfer = (direction = 'right') => {
    const selectedList =
      direction === 'right'
        ? _.cloneDeep(
            leftListSearchText ? beforeSearchLists.current.left : lList
          )
        : _.cloneDeep(
            rightListSearchText ? beforeSearchLists.current.right : rList
          )
    const selectedItems = selectedList.filter(({ isChecked }) => isChecked)
    if (!_.isEmpty(selectedItems)) {
      let clonedLeftList = _.cloneDeep(
        leftListSearchText ? beforeSearchLists.current.left : lList
      )
      let clonedRightList = _.cloneDeep(
        rightListSearchText ? beforeSearchLists.current.right : rList
      )
      const difference = _.differenceBy(
        direction === 'right' ? clonedLeftList : clonedRightList,
        selectedItems,
        'uuid'
      )
      if (direction === 'right') {
        clonedLeftList = difference
        clonedRightList = clonedRightList.concat(selectedItems)
      } else {
        clonedRightList = difference
        clonedLeftList = clonedLeftList.concat(selectedItems)
      }

      setLList(clonedLeftList)
      setRList(clonedRightList)
      beforeSearchLists.current = {
        left: clonedLeftList,
        right: clonedRightList
      }
      _.defer(() => {
        if (direction === 'right') {
          rightListRef.current.scrollToItem(clonedRightList.length, 'end')
        } else {
          leftListRef.current.scrollToItem(clonedLeftList.length, 'end')
        }
      })

      onChange(clonedLeftList, clonedRightList)
    }
  }

  const handleItemSelection = (item) => {
    const { list: listSide, selected, uuid } = item
    let selectedList =
      listSide === 'left' ? _.cloneDeep(lList) : _.cloneDeep(rList)
    const idItem = selectedList.find(({ uuid: itemUUID }) => uuid === itemUUID)
    if (idItem) {
      idItem.isChecked = selected
    }
    if (listSide === 'left') {
      setLList(selectedList)
    } else {
      setRList(selectedList)
    }

    const backupList = beforeSearchLists.current[listSide]
    selectedList.forEach((item) => {
      const currentItem = backupList.find((bkpItem) => bkpItem.id === item.id)
      if (currentItem) {
        currentItem.isChecked = item.isChecked
      }
    })

    beforeSearchLists.current = {
      ...beforeSearchLists.current,
      [listSide]: backupList
    }
  }

  const handleBatchSelection = (listSide) => {
    const handleSelectAll = (list) => {
      let l = [...list]
      l.forEach((item) => {
        item.isChecked = true
      })

      return l
    }

    const handleUnselectAll = (list) => {
      let l = [...list]
      l.forEach((item) => {
        item.isChecked = false
      })

      return l
    }

    const handleBackupList = (listSide, action) => {
      const currentList = beforeSearchLists.current[listSide]
      if (action === 'select') {
        beforeSearchLists.current = {
          ...beforeSearchLists.current,
          [listSide]: currentList.map((item) => ({ ...item, isChecked: true }))
        }
      } else {
        beforeSearchLists.current = {
          ...beforeSearchLists.current,
          [listSide]: currentList.map((item) => ({ ...item, isChecked: false }))
        }
      }
    }

    if (listSide === 'left') {
      let list = lList
      if (isAllLeftItemsSelected) {
        list = handleUnselectAll(list)
        handleBackupList(listSide, 'unselect')
      } else {
        list = handleSelectAll(list)
        handleBackupList(listSide, 'select')
      }
      setLList(list)
    } else {
      let list = rList
      if (isAllRightItemsSelected) {
        list = handleUnselectAll(list)
        handleBackupList(listSide, 'unselect')
      } else {
        list = handleSelectAll(list)
        handleBackupList(listSide, 'select')
      }
      setRList(list)
    }
  }

  const handleSearch = useCallback(
    (listSide, term) => {
      if (!term) {
        if (listSide === 'left') {
          setLList(beforeSearchLists.current.left)
        } else {
          setRList(beforeSearchLists.current.right)
        }
        return
      }
      const searchList = beforeSearchLists.current[listSide] || []
      let list = []
      if (listSide === 'left') {
        list = [...lList]
      } else {
        list = [...rList]
      }
      _.throttle(() => {
        list = searchList.filter((item) =>
          item[itemAttribute].toLowerCase().match(term.toLowerCase())
        )
        if (listSide === 'left') {
          setLList(list)
        } else {
          setRList(list)
        }
      }, 500)()
    },
    [itemAttribute, lList, rList]
  )

  const getActions = () => {
    return (
      <Stack mx={4}>
        <IconButton
          colorScheme="teal"
          variant="outline"
          size="sm"
          icon={<ChevronRightIcon />}
          mb={4}
          data-id="transfer-right"
          data-testid="transfer-right"
          isDisabled={shouldDisableTransferRightButton}
          onClick={() => handleTransfer('right')}
        />
        <IconButton
          colorScheme="teal"
          variant="outline"
          size="sm"
          data-id="transfer-left"
          icon={<ChevronLeftIcon />}
          isDisabled={shouldDisableTransferLeftButton}
          onClick={() => handleTransfer('left')}
        />
      </Stack>
    )
  }

  const getBoxContainer = (list, listSide) => {
    const isAllItemsSelected =
      listSide === 'left' ? isAllLeftItemsSelected : isAllRightItemsSelected
    const shouldDisplaySelectAll =
      listSide === 'left' ? !leftListSearchText : !rightListSearchText
    const getDataId = (suffix) => `${listSide}-list-element-${suffix}`

    const ItemRow = ({ index, style }) => (
      <div style={style} key={index}>
        <Box
          ml={1}
          data-testid={getDataId('testid')}
          data-id={getDataId(index)}
        >
          <Checkbox
            mb={0}
            isChecked={list[index].isChecked}
            onChange={({ target: { checked } }) =>
              handleItemSelection({
                id: list[index].id,
                list: listSide,
                selected: checked,
                uuid: list[index].uuid
              })
            }
          >
            <Flex gap={2} align="center">
              <Text
                fontSize="xs"
                dangerouslySetInnerHTML={{
                  __html: _.get(list[index], itemAttribute)
                }}
              />
              {listSide === 'left' && leftListItemAdornment
                ? cloneElement(leftListItemAdornment, {
                    ...(leftListItemAdornment?.props ?? {}),
                    data: list[index]
                  })
                : null}
              {listSide === 'right' && rightListItemAdornment
                ? cloneElement(rightListItemAdornment, {
                    ...(rightListItemAdornment?.props ?? {}),
                    data: list[index]
                  })
                : null}
            </Flex>
          </Checkbox>
        </Box>
      </div>
    )
    const getVirtualizedList = () => {
      if (list.length === 0) {
        return (
          <Box h={`${listHeight}px`}>
            <Center h="100%">No data</Center>
          </Box>
        )
      }
      return (
        <List
          ref={listSide === 'left' ? leftListRef : rightListRef}
          height={listHeight}
          itemCount={list.length}
          width="100%"
          itemSize={30}
        >
          {ItemRow}
        </List>
      )
    }
    return (
      <Box w="calc(50% - 22px)" border="1px solid #dedede" borderRadius="2px">
        <Stack>
          <Flex minH="21px" py={2} px={3} align="center">
            {shouldDisplaySelectAll ? (
              <Checkbox
                mb={0}
                isChecked={isAllItemsSelected}
                data-testid={`transfer-list-select-all-${listSide}`}
                onChange={() => handleBatchSelection(listSide)}
                isIndeterminate={!isAllItemsSelected}
              >
                <Heading ml={2} size="xs">
                  {list.length} {list.length === 1 ? 'item' : 'items'}
                </Heading>
              </Checkbox>
            ) : (
              <b>
                <Text size="sm">
                  {list.length} {list.length === 1 ? 'item' : 'items'}
                </Text>
              </b>
            )}
          </Flex>
          <InputGroup>
            <InputLeftElement pointerEvents="none" style={{ zIndex: 0 }}>
              <SearchIcon mb={4} color="blue.300" />
            </InputLeftElement>
            <Input
              w="100%"
              placeholder="Search a item"
              size="xs"
              value={
                listSide === 'left' ? leftListSearchText : rightListSearchText
              }
              data-testid={`transfer-list-${listSide}-search`}
              onChange={({ target: { value } }) => {
                if (listSide === 'left') {
                  setLeftListSearchText(value)
                  handleSearch('left', value)
                } else {
                  setRightListSearchText(value)
                  handleSearch('right', value)
                }
              }}
            />
          </InputGroup>
        </Stack>
        <Divider />
        <Box p={2}>{getVirtualizedList()}</Box>
      </Box>
    )
  }
  return (
    <Flex w="100%" align="center">
      {getBoxContainer(lList, 'left')}
      {getActions()}
      {getBoxContainer(rList, 'right')}
    </Flex>
  )
}

TransferList.defaultProps = {
  leftList: [],
  rightList: [],
  leftListItemAdornment: null,
  rightListItemAdornment: null,
  onChange: () => ({}),
  itemAttribute: 'name',
  listHeight: 320,
  isLoading: false
}

TransferList.propTypes = {
  leftList: PropTypes.array,
  leftListItemAdornment: PropTypes.element,
  rightList: PropTypes.array,
  rightListItemAdornment: PropTypes.element,
  onChange: PropTypes.func,
  itemAttribute: PropTypes.string,
  listHeight: PropTypes.number
}

export default TransferList

export const getTransferListSkeleton = (listHeight = 320) => {
  const getBox = () => {
    return (
      <Box w="calc(50% - 22px)" border="1px solid #dedede" borderRadius="2px">
        <Stack>
          <Flex minH="21px" py={2} px={3} align="center">
            <Skeleton w="40%" h="16px" />
          </Flex>
          <Box p={2} h={`${listHeight}px`}>
            {Array.from(new Array(Math.floor(listHeight / 30) - 1)).map(
              (_, index) => {
                return <Skeleton mb={1} h="30px" w="100%" key={index} />
              }
            )}
          </Box>
        </Stack>
      </Box>
    )
  }
  return (
    <Flex align="center">
      {getBox()}
      <Stack h="100%" mx={4} />
      {getBox()}
    </Flex>
  )
}
