/* eslint-disable react/jsx-key */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  IconButton,
  Tag,
  Tooltip
} from '@chakra-ui/react'
import EmptyState from 'components/EmptyState'
import { capitalize, first, isArray, isEmpty } from 'lodash'
import React, { useState } from 'react'
import MaterialIcon from '../../../../../../components/MaterialIcon'
import Field from './Field'
import { getTitle } from './helpers'

const FormBuilder = ({ formKey, values, rolesData, isNested }) => {
  const [accordionIndexes, setAccordionIndexes] = useState([])
  if (isEmpty(values)) {
    return <EmptyState text="There are no features available" />
  }
  return (
    <>
      {!isEmpty(accordionIndexes) && (
        <Tooltip label="Collapse all" hasArrow>
          <IconButton
            icon={<MaterialIcon icon='close_fullscreen' />}
            isRound
            position="fixed"
            bottom="10px"
            right="10px"
            variant="outline"
            colorScheme="blue"
            aria-label="Collapse all accordions"
            onClick={() => setAccordionIndexes([])}
          />
        </Tooltip>
      )}
      <Accordion
        allowMultiple
        defaultIndex={[]}
        onChange={setAccordionIndexes}
        index={accordionIndexes}
        reduceMotion
      >
        {values?.map((value, valueIndex) => (
          <AccordionItem key={valueIndex} border="none">
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton
                    borderColor={isExpanded ? 'blue.100' : 'gray.100'}
                    bg={isExpanded ? 'gray.100' : 'transparent'}
                    borderWidth={1}
                    borderRadius="md"
                    display="flex"
                    justifyContent="space-between"
                    my={2}
                    mt={valueIndex === 0 ? 0 : 2}
                    mb={isExpanded ? 0 : 2}
                    borderBottomWidth={isExpanded ? 0 : 1}
                    borderBottomLeftRadius={isExpanded ? 0 : 'md'}
                    borderBottomRightRadius={isExpanded ? 0 : 'md'}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      {capitalize(getTitle(value))}
                    </Box>
                    <Flex gap={4} align="center">
                      {Object.values(value).map((data, innerIndex) => {
                        const hasAttributes =
                          isArray(data) &&
                          ['active', 'roles'].every((key) =>
                            data.find(({ attribute }) => attribute === key)
                          )
                        if (hasAttributes) {
                          const active = data?.find(
                            ({ attribute }) => attribute === 'active'
                          )?.value
                          const roles =
                            data?.find(({ attribute }) => attribute === 'roles')
                              ?.value ?? []
                          return (
                            <Flex key={innerIndex} gap={2} mr={2}>
                              <Tag colorScheme={active ? 'green' : 'red'}>
                                {active ? 'Active' : 'Inactive'}
                              </Tag>
                              <Tag
                                colorScheme={isEmpty(roles) ? 'gray' : 'blue'}
                              >
                                {roles?.length ?? '0'}{' '}
                                {roles?.length === 1 ? 'role' : 'roles'}
                              </Tag>
                            </Flex>
                          )
                        }
                        return null
                      })}
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                {isExpanded && (
                  <AccordionPanel
                    px="3"
                    borderRadius="md"
                    borderWidth={1}
                    mt="0!important"
                    borderTopWidth={isExpanded ? 0 : 1}
                    borderColor={isExpanded ? 'blue.100' : 'gray.100'}
                    borderTopLeftRadius={isExpanded ? 0 : 'md'}
                    borderTopRightRadius={isExpanded ? 0 : 'md'}
                  >
                    {React.Children.toArray(
                      Object.values(value)?.map((field, idx) => {
                        return (
                          <Flex flexDir="column" gap="10px" key={idx}>
                            <Field
                              field={field}
                              rolesData={rolesData}
                              formKey={
                                isNested ? formKey : `${formKey}.${valueIndex}`
                              }
                              attribute={first(Object.keys(value))}
                            />
                          </Flex>
                        )
                      })
                    )}
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </>
  )
}

export default FormBuilder
