import { ChevronDownIcon } from '@chakra-ui/icons'
import { Box } from '@chakra-ui/layout'
import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/popover'
import { Portal } from '@chakra-ui/portal'
import { Button } from '@chakra-ui/react'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { getRouteUrl } from '../../../../helpers'
import QRCodeGenerator from '../index'
import { DropdownOverlay, QRCodeButton } from '../styles'
import MaterialIcon from 'components/MaterialIcon'

const DropdownSelection = ({ templateId, templateName, isEdit }) => {
  const shouldDisplayQuickSubmit = !isEdit && templateId

  const [closePopoverOnBlur, setClosePopoverOnBlur] = useState(true)
  const onDisplayQrCodeParams = (value) => {
    setClosePopoverOnBlur(!value)
  }

  const getQRCodeButtonOverlay = () => (
    <DropdownOverlay noPadding>
      <QRCodeGenerator
        popoverPlacement="right"
        type="quick-access"
        templateId={templateId}
        templateName={templateName}
        onDisplayQrCodeParams={onDisplayQrCodeParams}
      />
      {shouldDisplayQuickSubmit && (
        <QRCodeGenerator
          popoverPlacement="right"
          type="quick-submit"
          templateId={templateId}
          templateName={templateName}
          onDisplayQrCodeParams={onDisplayQrCodeParams}
        />
      )}
      {isEdit && (
        <Box
          sx={{
            '& a': { textDecoration: 'none' }
          }}
        >
          <Link to={getRouteUrl('fillForm', { id: templateId })}>
            <QRCodeButton fontWeight="normal" withoutbg="true" onClick={noop}>
              Quick Submit QR Code
            </QRCodeButton>
          </Link>
        </Box>
      )}
    </DropdownOverlay>
  )

  if (!templateId) {
    return null
  }

  return (
    <Popover
      shadow="md"
      placement="bottom-start"
      closeOnBlur={closePopoverOnBlur}
    >
      <PopoverTrigger>
        <Button
          leftIcon={<MaterialIcon icon='qr_code' />}
          rightIcon={<ChevronDownIcon />}
          colorScheme="orange"
          color="#FF871C"
          variant="ghost"
          size="sm"
          maxW="135px"
        >
          QR Codes
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent width={220}>{getQRCodeButtonOverlay()}</PopoverContent>
      </Portal>
    </Popover>
  )
}

DropdownSelection.defaultProps = {
  isEdit: false
}

DropdownSelection.propTypes = {
  templateId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  templateName: PropTypes.string.isRequired,
  isEdit: PropTypes.bool
}

export default DropdownSelection
