import { Box, Input, Tag } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import DatePckr from '../../../../../components/DatePicker'

const Date = ({ index, hasPermissionToEdit, field: { _active, main } }) => {
  const { setValue, control } = useFormContext()
  if (!_active || !hasPermissionToEdit) {
    return (
      <Controller
        name={`asset_fields_attributes.${index}.value`}
        control={control}
        rules={{
          required: main
        }}
        render={({ field }) => {
          const formattedValue = field?.value ? (
            moment(field.value).format('DD/MM/YYYY')
          ) : (
            <Tag colorScheme="gray">Empty</Tag>
          )
          return field?.value ? (
            <Input value={formattedValue} isReadOnly />
          ) : (
            formattedValue
          )
        }}
      />
    )
  }
  return (
    <Box
      sx={{
        '& > span': {
          w: '100%'
        }
      }}
    >
      <Controller
        name={`asset_fields_attributes.${index}.value`}
        control={control}
        render={({ field, formState: { errors } }) => (
          <DatePckr
            placeholder="Date field"
            initialStartDate={field.value}
            isRangePicker={false}
            showTimeSelect={false}
            onChange={(newDate) =>
              setValue(`asset_fields_attributes.${index}.value`, newDate)
            }
            key={field.value}
            isInvalid={!!errors?.asset_fields_attributes?.index?.value?.message}
          />
        )}
      />
    </Box>
  )
}

export default Date
