import { Box, Flex, Heading, Input, Switch } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import ListBuilder from '../../../../../../../../components/ListBuilder'
import AdditionalFieldsList from '../../AdditionalFieldsList'
import { formattedRolesOptions, getRolesOptions } from '../../helpers'
import DomainItem from './DomainItem'

const Component = ({ fieldKey, fieldProps, rolesData }) => {
  const { register, setValue, watch } = useFormContext()

  const value = watch(fieldKey)

  const isAdditionalFields = fieldProps.attribute === 'additional_fields'

  const isDomainComponent = fieldProps.attribute === 'domains'

  switch (fieldProps.type) {
    case 'Boolean':
      return (
        <Box w="200px">
          <Switch
            colorScheme="green"
            {...register(fieldKey)}
            defaultChecked={value}
            id="active"
            cursor="pointer"
            minW="100px"
            mb="0"
          >
            {fieldProps.label}
          </Switch>
        </Box>
      )
    case 'Array':
      if (isAdditionalFields) {
        return (
          <AdditionalFieldsList
            value={value}
            register={register}
            fieldKey={fieldKey}
            setValue={setValue}
            watch={watch}
          />
        )
      }

      if (isDomainComponent) {
        return (
          <Box>
            <Heading as="h5" pb="10px" size="sm" textTransform="capitalize">
              Domains
            </Heading>
            <Box style={{ display: 'none' }}>
              <Input
                placeholder={fieldProps.label}
                {...register(fieldKey)}
                value={value}
              />
            </Box>
            <ListBuilder
              list={fieldProps.value}
              allowDrag={() => false}
              ItemComponent={DomainItem}
              onRemoveItem={(values) => {
                setValue(fieldKey, values)
              }}
              idAttribute="_id"
              itemProps={{
                fieldProps,
                fieldKey
              }}
              customRemoveItemAction={(index, _, fallback) => fallback(index)}
            />
          </Box>
        )
      }

      return (
        <Flex maxW="500px" flexDir="column" gap="10px">
          <Box>{fieldProps.label}</Box>
          <Select
            isMulti
            options={getRolesOptions({ rolesData })}
            {...register(fieldKey, { required: fieldProps.required })}
            onChange={(values) => {
              setValue(
                fieldKey,
                values?.map(({ name, group }) => ({
                  name,
                  group
                }))
              )
            }}
            defaultValue={formattedRolesOptions(value)}
            placeholder={fieldProps.label}
            hideSelectedOptions
          />
        </Flex>
      )
    case 'String':
      return (
        <Flex maxW="500px" flexDir="column" gap="10px">
          <Box>{fieldProps.label}</Box>
          <Input
            placeholder={fieldProps.label}
            {...register(fieldKey, { required: fieldProps.required })}
            value={value}
          />
        </Flex>
      )
    default:
      return null
  }
}

export default Component
