import { Checkbox, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import RadioGroup from '../../../../../components/RadioGroup'

const DialogBody = ({
  type,
  shouldRemoveAllChildren,
  setShouldRemoveAllChildren,
  logicRemoveDecision,
  setLogicRemoveDecision
}) => {
  if (['question', 'instruction'].includes(type)) {
    return (
      <Text fontSize="sm">
        {`This action cannot be undone. By proceeding, this ${type} will be deleted`}
      </Text>
    )
  } else if (type === 'logic') {
    return (
      <Stack>
        <Text fontSize="sm">
          {`This action cannot be undone. Please select an option that better describes how to proceed in relation with this logic's children`}
        </Text>
        <RadioGroup
          options={[
            {
              label: 'Remove it all',
              value: 'remove'
            },
            {
              label: `Move it to it's parent`,
              value: 'move'
            }
          ]}
          value={logicRemoveDecision}
          onChange={(value) => setLogicRemoveDecision(value)}
        />
      </Stack>
    )
  }
  return (
    <Stack>
      <Text fontSize="sm">
        {`This action cannot be undone. Please, select below if this section's children should also be removed`}
      </Text>
      <Checkbox
        isChecked={shouldRemoveAllChildren}
        onChange={(e) => setShouldRemoveAllChildren(e.target.checked)}
      >
        Remove all children
      </Checkbox>
    </Stack>
  )
}

export default DialogBody
