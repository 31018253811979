import * as yup from 'yup'

export const alertInfoMessages = {
  daily: 'It will be presented to the user daily for completion',
  weekly:
    'Specify the days of the week when the task will be available for the user to complete',
  monthly:
    'Choose a specific day of the month when the task will be presented to the user',
  quarterly:
    'Select a date, and the task will become available to the user every 3 months on that date',
  fortnightly:
    'The task will be available to the user on selected days of the current week and then every two weeks thereafter'
}

export const schema = yup
  .object({
    title: yup.string().required('The task title is required')
  })
  .required()
