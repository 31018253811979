import ManageDashboards from 'pages/ManageDashboards'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import TermsAndConditions from 'pages/TermsAndConditions'
import React from 'react'
import { useRoutes } from '../hooks'
import Layout from '../layout'
import EditAction from '../pages/Actions/EditAction'
import AlertPage from '../pages/Alert'
import Assessors from '../pages/Assessors'
import AssetTemplates from '../pages/AssetTemplates'
import ChangePassword from '../pages/ChangePassword'
import ChecklistsPage from '../pages/Checklists'
import Documents from '../pages/Documents'
import FormFolderPage from '../pages/FormFolder'
import GroupPage from '../pages/Group'
import DocumentsLibrary from '../pages/Library'
import ManageStaffPage from '../pages/ManageStaff'
import ManageStaffEditorModal from '../pages/ManageStaff/ManageStaffEditorModal'
import ManageUsers from '../pages/ManageUsers'
import Profiles from '../pages/Profiles'
import TemplateReports from '../pages/Reports/List'
import TemplatePage from '../pages/Templates'
import UnautorizedPage from '../pages/Unauthozied'
import { GlobalStyle } from '../utils/styles'
import ActionPage from './ImplementedPages/Action'
import ActionsPage from './ImplementedPages/Actions'
import AssessorFormPage from './ImplementedPages/AssessorForm'
import AssetsEditor from './ImplementedPages/AssetsEditor'
import AssetsPage from './ImplementedPages/AssetsPage'
import AssetTemplateEditor from './ImplementedPages/AssetTemplatesEditor'
import ChecklistEditorPage from './ImplementedPages/ChecklistEditor'
import ChecklistsTasks from './ImplementedPages/ChecklistsTasks'
import ChecklistUsersTasksPage from './ImplementedPages/ChecklistUsersTasksPage'
import DashboardPage from './ImplementedPages/Dashboard'
import DocumentPage from './ImplementedPages/Document'
import FillFormPage from './ImplementedPages/FillForm'
import FormAnswerPage from './ImplementedPages/FormAnswer'
import FormAnswersPage from './ImplementedPages/FormAnswers'
import FormFoldersPage from './ImplementedPages/FormFolders'
import GroupUsersPage from './ImplementedPages/GroupUsers'
import LibraryEditorPage from './ImplementedPages/LibraryEditor'
import ManageDashboardEditorPage from './ImplementedPages/ManageDashboardEditor'
import ManageUsersPage from './ImplementedPages/ManageUsers'
import ProfileForm from './ImplementedPages/Profile'
import ReportsEditorPage from './ImplementedPages/ReportsEditor'
import TemplateEditorPage from './ImplementedPages/TemplateEditor'
import TemplateFrequenciesPage from './ImplementedPages/TemplateFrequencies'
import TemplateFrequenciesEditorPage from './ImplementedPages/TemplateFrequencyEditor'
import TemplateSettings from './ImplementedPages/TemplateSettings'
import TemplateStructurePage from './ImplementedPages/TemplateStructure'

const Routes = () => {
  const Routes = useRoutes([
    {
      path: ['/web/:userType/dashboard', '/web/dashboard'],
      component: DashboardPage,
      isExact: true
    },
    {
      path: '/web/form_folders',
      component: FormFolderPage,
      isExact: true
    },
    {
      path: '/web/form_folders/:id/edit',
      render: ({
        match: {
          params: { id }
        }
      }) => <FormFoldersPage id={id} />,
      isExact: true
    },
    {
      path: '/web/form_folders/new',
      render: () => <FormFoldersPage id={null} />,
      isExact: true
    },
    {
      path: '/web/groups',
      component: GroupPage,
      isExact: true
    },
    {
      path: '/web/groups/:id/edit',
      render: ({
        match: {
          params: { id }
        }
      }) => <GroupUsersPage id={id} />,
      isExact: true
    },
    {
      path: '/web/groups/new',
      render: () => <GroupUsersPage id={null} />,
      isExact: true
    },
    {
      path: '/web/template_reports',
      render: () => <TemplateReports />,
      isExact: true
    },
    {
      path: '/web/template_reports/:type/new',
      render: () => <ReportsEditorPage id={null} />,
      isExact: true
    },
    {
      path: '/web/template_reports/:type/:id/edit',
      render: ({
        match: {
          params: { id }
        }
      }) => <ReportsEditorPage id={id} />,
      isExact: true
    },
    {
      path: '/web/templates',
      component: TemplatePage,
      isExact: true
    },
    {
      path: '/web/templates/new',
      render: () => <TemplateEditorPage id={null} />,
      isExact: true
    },
    {
      path: '/web/templates/:id',
      render: ({
        match: {
          params: { id }
        }
      }) => <TemplateStructurePage id={id} />,
      isExact: true
    },
    {
      path: '/web/templates/:id/settings',
      render: ({
        match: {
          params: { id }
        }
      }) => <TemplateSettings id={id} />,
      isExact: true
    },
    {
      path: '/web/templates/:id/forms',
      render: ({
        match: {
          params: { id }
        }
      }) => <FormAnswersPage templateId={id} />,
      isExact: true
    },
    {
      path: '/web/templates/:id/forms/:formId',
      render: ({
        match: {
          params: { id, formId }
        }
      }) => <FormAnswerPage templateId={id} formId={formId} />,
      isExact: true
    },
    {
      path: '/web/templates/:id/fill_form',
      render: ({
        match: {
          params: { id }
        }
      }) => <FillFormPage id={id} />,
      isExact: true
    },
    {
      path: '/web/form_folders/documents',
      component: Documents,
      isExact: true
    },
    {
      path: '/web/templates/:id/template_frequency_settings',
      render: ({
        match: {
          params: { id }
        }
      }) => <TemplateFrequenciesPage id={id} />,
      isExact: true
    },
    {
      path: '/web/templates/:template_id/template_frequency_settings/new',
      render: ({
        match: {
          params: { template_id: templateId }
        }
      }) => <TemplateFrequenciesEditorPage templateId={templateId} />,
      isExact: true
    },
    {
      path: '/web/templates/:template_id/template_frequency_settings/edit',
      render: ({
        match: {
          params: { template_id: templateId }
        }
      }) => <TemplateFrequenciesEditorPage templateId={templateId} />,
      isExact: true
    },
    {
      path: '/web/libraries/new',
      render: () => <LibraryEditorPage id={null} />,
      isExact: true
    },
    {
      path: '/web/libraries/:id/edit',
      render: ({
        match: {
          params: { id }
        }
      }) => <LibraryEditorPage id={id} />,
      isExact: true
    },
    {
      path: '/web/documents/new',
      render: () => <DocumentPage id={null} />,
      isExact: true
    },
    {
      path: '/web/documents/:id/edit',
      render: ({
        match: {
          params: { id }
        }
      }) => {
        return <DocumentPage id={id} />
      },
      isExact: true
    },
    {
      path: '/web/alerts',
      component: AlertPage,
      isExact: true
    },
    {
      path: '/users/edit',
      component: ChangePassword,
      isExact: true
    },
    {
      path: '/web/profiles',
      component: Profiles,
      isExact: true
    },
    {
      path: '/web/profiles/new',
      render: () => <ProfileForm profileId={null} />,
      isExact: true
    },
    {
      path: '/web/profiles/:id/edit',
      render: ({
        match: {
          params: { id }
        }
      }) => <ProfileForm id={id} />,
      isExact: true
    },
    {
      path: '/web/profiles/manage_users',
      component: ManageUsers,
      isExact: true
    },
    {
      path: '/web/profiles/manage_user/:id',
      render: ({
        match: {
          params: { id }
        }
      }) => <ManageUsersPage id={id} />,
      isExact: true
    },
    {
      path: '/web/profiles/manage_users/new',
      component: ManageUsersPage,
      isExact: true
    },
    {
      path: '/web/unauthorized',
      component: UnautorizedPage,
      isExact: true
    },
    {
      path: '/web/assessors',
      component: Assessors,
      isExact: true
    },
    {
      path: '/web/assessors/new',
      render: () => <AssessorFormPage id={null} />,
      isExact: true
    },
    {
      path: '/web/assessors/:id/edit',
      render: ({
        match: {
          params: { id }
        }
      }) => <AssessorFormPage id={id} />,
      isExact: true
    },
    {
      path: '/web/documents',
      component: DocumentsLibrary,
      isExact: true
    },
    {
      path: '/web/actions',
      component: ActionsPage,
      isExact: true
    },
    {
      path: '/web/actions/new',
      render: () => <EditAction id={null} />,
      isExact: true
    },
    {
      path: '/web/actions/:id/edit',
      render: ({
        match: {
          params: { id }
        }
      }) => <EditAction id={id} />,
      isExact: true
    },
    {
      path: '/web/actions/:id',
      render: ({
        match: {
          params: { id }
        }
      }) => <ActionPage id={id} />,
      isExact: true
    },
    {
      path: '/web/checklists',
      component: ChecklistsPage,
      isExact: true
    },
    {
      path: '/web/checklists/new',
      component: ChecklistEditorPage,
      isExact: true
    },
    {
      path: '/web/checklists/:id',
      render: ({
        match: {
          params: { id }
        }
      }) => <ChecklistEditorPage id={id} show />,
      isExact: true
    },
    {
      path: '/web/checklists/:id/tasks',
      render: ({
        match: {
          params: { id }
        }
      }) => <ChecklistsTasks id={id} />,
      isExact: true
    },
    {
      path: '/web/checklists/:id/edit',
      render: ({
        match: {
          params: { id }
        }
      }) => <ChecklistEditorPage id={id} />,
      isExact: true
    },
    {
      path: `/web/checklists/:checklist_id/tasks/:checklist_item_id`,
      render: ({
        match: {
          params: { checklist_id: id, checklist_item_id: itemId }
        }
      }) => <ChecklistUsersTasksPage id={id} itemId={itemId} />,
      isExact: true
    },
    {
      path: '/web/staff',
      component: ManageStaffPage,
      isExact: false
    },
    {
      path: '/web/staff/:id',
      component: ManageStaffEditorModal,
      isExact: true,
      isBackground: true
    },
    {
      path: '/web/asset_types',
      component: AssetTemplates,
      isExact: true
    },
    {
      path: '/web/asset_types/new',
      component: AssetTemplateEditor,
      isExact: true
    },
    {
      path: '/web/assets',
      component: AssetsPage,
      isExact: true
    },
    {
      path: '/web/asset_types/:id',
      render: ({
        match: {
          params: { id }
        }
      }) => <AssetTemplateEditor id={id} />,
      isExact: true
    },
    {
      path: '/web/assets/new',
      component: AssetsEditor,
      isExact: true
    },
    {
      path: '/web/assets/:id',
      render: ({
        match: {
          params: { id }
        }
      }) => <AssetsEditor id={id} />,
      isExact: true
    },
    {
      path: '/web/manage_dashboards',
      component: ManageDashboards,
      isExact: true
    },
    {
      path: '/web/manage_dashboards/new',
      render: ({
        match: {
          params: { id }
        }
      }) => <ManageDashboardEditorPage id={id} />,
      isExact: true
    },
    {
      path: '/web/manage_dashboards/:id',
      render: ({
        match: {
          params: { id }
        }
      }) => <ManageDashboardEditorPage id={id} />
    },
    {
      path: '/terms_and_conditions',
      component: TermsAndConditions,
      isExact: true
    },
    {
      path: '/privacy_policy',
      component: PrivacyPolicy,
      isExact: true
    }
  ])
  return (
    <>
      <GlobalStyle />
      <Layout>{Routes}</Layout>
    </>
  )
}

export default Routes
