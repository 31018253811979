import { omit } from 'lodash'

export const formattedData = (data) => {
  const {
    tradingName,
    businessName,
    industry,
    abnAcn,
    features,
    screens,
    validations
  } = data
  let screensData = {}
  let featuresData = {}
  let validationsData = {}
  const omitAttributes = []
  if (!screens) {
    omitAttributes.push('screens')
  } else {
    screensData = screens
      .filter((v) => !!v)
      .reduce((acc, feature) => {
        const [entries] = Object.entries(feature)
        const [featureKey, featureValue] = entries
        acc[featureKey] = featureValue
        if (['dashboard', 'templates_menus'].includes(featureKey)) {
          const items = featureValue.reduce((innerAcc, item) => {
            const [innerEntries] = Object.entries(item)
            const [key, value] = innerEntries
            innerAcc[key] = value
            return innerAcc
          }, {})
          acc[featureKey] = items
        }
        return acc
      }, {})
  }
  if (!features) {
    omitAttributes.push('features')
  } else {
    const filteredFeatures = features.filter((v) => !!v)
    featuresData = filteredFeatures.reduce((acc, feature) => {
      const [entries] = Object.entries(feature)
      const [featureKey, featureValue] = entries
      acc[featureKey] = {
        ...featureValue,
        additional_fields: featureValue?.additional_fields
          ? featureValue?.additional_fields.map((field) => omit(field, ['_id']))
          : []
      }
      return acc
    }, {})
  }

  if (!validations) {
    omitAttributes.push('validations')
  } else {
    validationsData = validations
      .filter((v) => !!v)
      .reduce((acc, feature) => {
        const [entries] = Object.entries(feature)
        const [featureKey, featureValue] = entries
        acc[featureKey] = featureValue
        return acc
      }, {})
  }

  const settingsFieldsAttributes = ['features', 'screens', 'validations']

  return omit(
    {
      trading_name: tradingName,
      business_name: businessName,
      abn_acn: abnAcn,
      industry,
      settings_fields: omit(
        {
          features: featuresData,
          screens: screensData,
          validations: validationsData
        },
        omitAttributes
      )
    },
    settingsFieldsAttributes.every((attribute) =>
      omitAttributes.includes(attribute)
    )
      ? ['settings_fields']
      : []
  )
}
