import { globalEvents } from 'contexts/GlobalStateContext/events'
import { useGlobalEventListener } from 'hooks'
import { isEmpty } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Notification from 'services/Notification'
import OlingaAPI from 'services/OlingaAPI'
import actions from 'store/actions'

const useDynamicDependencies = () => {
  const { assetsTemplates, isLoading } = useSelector(
    ({ editTemplateReducer }) => editTemplateReducer
  )
  const dispatch = useDispatch()

  const fetchAssetsTemplates = useCallback(async () => {
    if (!assetsTemplates || isEmpty(assetsTemplates)) {
      dispatch({
        type: actions.edit_template.SET_IS_LOADING,
        payload: true
      })
      try {
        const response = await OlingaAPI.assetTemplatesAsEnumerator()
        if (response.status === 200) {
          const data = response?.data?.data?.asset_templates
          dispatch({
            type: actions.edit_template.SET_ASSETS_TEMPLATES,
            payload: data.map((assetTemplate) => ({
              ...assetTemplate,
              id: assetTemplate.value
            }))
          })
        }
      } catch (error) {
        Notification({
          icon: 'error',
          text: 'Unable to fetch assets types. Try again later'
        })
      } finally {
        dispatch({
          type: actions.edit_template.SET_IS_LOADING,
          payload: false
        })
      }
    }
  }, [assetsTemplates, dispatch])

  useGlobalEventListener(
    useCallback(
      (event) => {
        if (event.type === globalEvents.ON_FETCH_ASSETS_TEMPLATES) {
          fetchAssetsTemplates()
        }
      },
      [fetchAssetsTemplates]
    )
  )

  return {
    isLoading,
    assetsTemplates,
    fetchAssetsTemplates
  }
}

export default useDynamicDependencies
