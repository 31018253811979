import styled from '@emotion/styled'
import { Container as HomeContainer } from '../../Home/styles'

import backgroundImage from '../../../../assets/images/admin/sign_up.jpg'

export const Container = styled(HomeContainer)`
  background-image: url(${backgroundImage});
  background-size: cover;
  justify-content: center;
`
