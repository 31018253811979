import { Box, Flex, IconButton, theme, Tooltip } from '@chakra-ui/react'
import React from 'react'
import MaterialIcon from 'components/MaterialIcon'

const Controls = ({
  onLoopClick,
  onPlayClick,
  onThumbsClick,
  isPlaying,
  isLooping,
  isDisplayingThumbs,
  currentImage
}) => {
  return (
    <Box
      borderRadius={4}
      p={2}
      gap={4}
      bg="transparent"
      zIndex={theme.zIndices.modal + 10}
      variant="outline"
    >
      <Flex align="center" gap={4}>
        <a href={currentImage?.url} rel="noreferrer noopener" target="_blank">
          <IconButton
            aria-label="Download image"
            shadow="sm"
            icon={<MaterialIcon icon='download' />}
            colorScheme="green"
          />
        </a>
        <Tooltip
          placement="bottom"
          label={isPlaying ? 'Pause slides' : 'Play slides'}
          hasArrow
        >
          <IconButton
            aria-label="Play/Pause slides"
            onClick={onPlayClick}
            shadow="sm"
            icon={
              <MaterialIcon icon={`${isPlaying ? 'pause' : 'play_arrow'}`} />
            }
            colorScheme="blue"
          />
        </Tooltip>
        <Tooltip
          placement="bottom"
          label={isLooping ? 'Disable looping' : 'Enable looping'}
          hasArrow
        >
          <IconButton
            aria-label="Loop/unloop slides"
            onClick={onLoopClick}
            shadow="sm"
            icon={
              <MaterialIcon icon={`${
                isLooping ? 'mobiledata_off' : 'repeat'
              }`} />
            }
            colorScheme="blue"
          />
        </Tooltip>
        <Tooltip
          placement="bottom"
          label={
            isDisplayingThumbs
              ? 'Hide images thumbnails'
              : 'Display images thumbnails'
          }
          hasArrow
        >
          <IconButton
            aria-label="Show/Not show thumbs"
            onClick={onThumbsClick}
            shadow="sm"
            icon={
              <MaterialIcon icon={`${
                isDisplayingThumbs ? 'hide_image' : 'image'
              }`} />
            }
            colorScheme="blue"
          />
        </Tooltip>
      </Flex>
    </Box>
  )
}

Controls.defaultProps = {
  currentImage: {}
}

export default Controls
