/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup'
import { get, isEmpty } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { useGlobalStateContext } from '../../../../contexts/GlobalStateContext'
import { onManageStaffSuccess } from '../../../../contexts/GlobalStateContext/events'
import { getRouteUrl, setFormErrors } from '../../../../helpers'
import { appMasks } from '../../../../helpers/masks'
import Notification from '../../../../services/Notification'
import OlingaAPI from '../../../../services/OlingaAPI'

const schema = yup
  .object({
    full_name: yup.string().required('The name is required'),
    telephone: yup.string(),
    user_attributes: yup.object().shape({
      email: yup
        .string()
        .email('The email must be valid')
        .required('The email is required'),
      active: yup.bool()
    })
  })
  .required()

const useOnboardStaff = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      full_name: '',
      telephone: '',
      user_attributes: {
        email: '',
        active: true
      }
    },
    resolver: yupResolver(schema)
  })
  const { id } = useParams()
  const history = useHistory()
  const { global$ } = useGlobalStateContext()
  const [isLoading, setIsLoading] = useState(false)
  const [isFetchingStaff, setIsFetchingStaff] = useState(false)
  const [additionalFields, setAdditionalFields] = useState([])
  const screens = useSelector(({ globalReducer }) => globalReducer.screens)

  const groupDialogRef = useRef()

  const formValues = watch()

  const onClose = useCallback(() => {
    history.push(getRouteUrl('manageStaff'))
  }, [history])

  const onGroupDialogConfirm = (onCloseDialog) => {
    onCloseDialog()
    history.push(getRouteUrl('groups'))
  }

  const onCloseGroupDialog = (onCloseDialog) => {
    global$.next(onManageStaffSuccess())
    onCloseDialog()
    onClose()
  }

  const checkAdditionalCompulsoryFields = (formData) => {
    if (!isEmpty(additionalFields)) {
      const compulsoryFields = additionalFields.filter(
        ({ compulsory }) => compulsory
      )
      const notAnsweredFields = compulsoryFields.reduce(
        (acc, { label, attribute_name }) => {
          if (!formData?.[attribute_name]) {
            acc.labels.push(label)
            acc.fields.push(attribute_name)
          }
          return acc
        },
        { labels: [], fields: [] }
      )
      if (!isEmpty(notAnsweredFields.labels)) {
        const { labels, fields } = notAnsweredFields

        return {
          fields: fields.map((field, index) => ({
            field,
            message: `${labels[index]} is required!`
          }))
        }
      }
    }
    return null
  }

  const onSubmit = async (data) => {
    const compulsoryFieldsErrors = checkAdditionalCompulsoryFields(data)
    if (compulsoryFieldsErrors && !isEmpty(compulsoryFieldsErrors)) {
      const { fields } = compulsoryFieldsErrors
      return fields.forEach(({ field, message }) => {
        setError(field, { type: 'custom', message })
      })
    } else {
      additionalFields.forEach(({ attribute_name }) => {
        data[attribute_name] = data?.[attribute_name]?.value
      })
    }
    setIsLoading(true)
    try {
      const endpoint = id === 'new' ? 'createStaff' : 'editStaff'
      const response = await OlingaAPI[endpoint]({ person: data }, id)
      if (response?.message) {
        Notification({
          icon: 'success',
          text: response?.message,
          position: toast.POSITION.TOP_RIGHT
        })

        if (endpoint === 'createStaff') {
          groupDialogRef.current.open()
        } else {
          global$.next(onManageStaffSuccess())
          onClose()
        }
      }
    } catch (error) {
      const receivedErrors = setFormErrors(error, setError, Notification, {
        position: toast.POSITION.TOP_RIGHT
      })
      if (isEmpty(receivedErrors)) {
        Notification({
          icon: 'error',
          text: `Unable to ${
            id === 'new' ? 'create' : 'edit'
          } staff. Please check the error logs`,
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const fetchPerson = useCallback(async () => {
    setIsFetchingStaff(true)
    try {
      const promises = [OlingaAPI.custom.staff.additionalFields()]
      if (id !== 'new') {
        promises.push(OlingaAPI.getStaff(id))
      }
      const [fields, user] = await Promise.all(promises)

      setAdditionalFields(fields)

      if (user?.attributes) {
        const data = user.attributes
        let formAttributes = {
          full_name: data.name,
          telephone: data.telephone ? appMasks.phoneNumber(data.telephone) : '',
          user_attributes: {
            active: !!data?.employment?.active,
            email: data?.email
          }
        }
        if (!isEmpty(fields)) {
          const additionalFields = fields.reduce(
            (acc, { attribute_name: attributeName, collection }) => {
              const answeredField = data?.additionalFields?.find(
                ({ attribute_name }) => attribute_name === attributeName
              )
              acc[attributeName] = collection?.find(
                ({ value }) => value === answeredField?.value
              )
              return acc
            },
            {}
          )
          formAttributes = {
            ...formAttributes,
            ...additionalFields
          }
        }
        reset(formAttributes)
      }
    } catch (error) {
      //
    } finally {
      setIsFetchingStaff(false)
    }
  }, [reset, id])

  const onCustomFieldChange = (attributeName, value) => {
    if (value) {
      setError(attributeName, null)
    }
    setValue(attributeName, value)
  }

  const onTelephoneChange = (value) => {
    setValue('telephone', appMasks.phoneNumber(value))
  }

  useEffect(() => {
    fetchPerson()
  }, [])

  const { title, subject } = {
    title:
      get(screens, 'manage_staff.business.custom_name') ||
      get(screens, 'manage_staff.name'),
    subject:
      get(screens, 'manage_staff.business.custom_subject') ||
      get(screens, 'manage_staff.subject')
  }

  return {
    handleSubmit,
    register,
    onSubmit,
    errors,
    isLoading,
    id,
    onClose,
    shouldRenderFormFields: id !== 'new' ? !isFetchingStaff : true,
    groupDialogRef,
    onCloseGroupDialog,
    onGroupDialogConfirm,
    title,
    subject,
    additionalFields,
    formValues,
    onCustomFieldChange,
    onTelephoneChange
  }
}

export default useOnboardStaff
