// Implements the LocalStorage class and injects the user email on it to make the methods exportation easier

import { get, isNull } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LocalStorage } from '../../pages/TemplateEditor/helpers'

const storage = new LocalStorage()

export const getStorageKey = (email) => {
  return `olinga_${email}_template_editor_settings`
}

const useLocalStorage = (resource) => {
  const user = useSelector(({ globalReducer }) => globalReducer.user)
  const [data, setData] = useState(null)
  const [storageKey, setStorageKey] = useState(getStorageKey(user?.email))

  useEffect(() => {
    setStorageKey(user?.email ? getStorageKey(user?.email) : null)
  }, [user?.email])

  useEffect(() => {
    if (storageKey && !storageKey?.includes('undefined')) {
      if (resource?.includes('.')) {
        const [key, id] = resource.split('.')
        setData(storage.getData(storageKey)?.[key]?.[id])
      } else {
        setData(get(storage.getData(storageKey), resource))
      }
    }
  }, [resource, storageKey])

  const setDataMemoized = useCallback(
    (value, key) => {
      if (key || storageKey) {
        storage.setData(key ?? storageKey, value)
      }
    },
    [storageKey]
  )

  const getDataMemoized = useCallback(
    (key) => {
      return storage.getData(key ?? storageKey)?.[resource] ?? undefined
    },
    [storageKey, resource]
  )

  const destroyStoredResources = () => {
    const key = getStorageKey(user?.email)
    if (!key?.includes('undefined')) {
      storage.deleteData(getStorageKey(user?.email))
    }
  }

  return {
    data,
    setData: setDataMemoized,
    deleteData(key) {
      storage.deleteData(key)
    },
    getData: getDataMemoized,
    getStorageKey() {
      return storageKey
    },
    destroyStoredResources,
    isDataRetrieved: !isNull(data)
  }
}

export default useLocalStorage
