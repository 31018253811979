import { EditIcon } from '@chakra-ui/icons'
import { IconButton } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { getRouteUrl } from '../../../helpers'

const EditFolderButton = ({ id }) => (
  <Link
    to={{ pathname: getRouteUrl('editFormFolder', { id }), from: 'folders' }}
  >
    <IconButton
      icon={<EditIcon />}
      colorScheme="blue"
      size="sm"
      variant="outline"
      aria-label={`Edit folder ${id}`}
    />
  </Link>
)

export default EditFolderButton
