import { Stack } from '@chakra-ui/react'
import React from 'react'
import { getPrefixes } from '../../../../helpers'
import GroupPeopleModal from '../../GroupPeopleModal'

const GroupUsersEditorActions = ({ group, singularTitle }) => {
  const { userIds, name, id } = group.attributes
  const totalUsers = userIds.length
  const { before, after } = getPrefixes(totalUsers, 'person')
  return (
    <Stack direction={['column', 'row']}>
      {totalUsers > 0 && (
        <GroupPeopleModal
          count={totalUsers}
          groupName={name}
          id={id}
          customButtonLabel={`See ${before} ${
            totalUsers === 1 ? 'only' : totalUsers
          } ${after} of this ${singularTitle}`}
        />
      )}
    </Stack>
  )
}

export default GroupUsersEditorActions
