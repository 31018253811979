import { isEmpty } from 'lodash'

const routeWithParams = (params = {}, url) => {
  const urlParams = new URLSearchParams({
    ...(params ?? {})
  })

  return `${url}?${urlParams.toString()}`
}

const routes = {
  dashboard: ({ userType }) => `/web/${userType}/dashboard`,
  client_dashboard: () => '/web/dashboard',
  folders: (params) => routeWithParams(params, `/web/form_folders`),
  editFormFolder: ({ id }) => `/web/form_folders/${id}/edit`,
  newFormFolder: () => '/web/form_folders/new',
  groups: (params) => routeWithParams(params, `/web/groups`),
  editGroup: ({ id }) => `/web/groups/${id}/edit`,
  newGroup: () => '/web/groups/new',
  templates: () => '/web/templates',
  showTemplate: ({ id }) => `/web/templates/${id}`,
  createTemplate: () => '/web/templates/new',
  templateForm: ({ id }) => `/web/templates/${id}/forms`,
  formAnswer: ({ id, formId, context, contextId }) => {
    const urlParams = new URLSearchParams({
      context,
      contextId
    })
    return `/web/templates/${id}/forms/${formId}?${
      context && contextId ? urlParams : ''
    }`
  },
  editTemplate: ({ id, isCloned }) =>
    `/web/templates/${id}/edit${isCloned ? '?cloned=true' : ''}`,
  cloneTemplate: ({ id }) => `/web/clone/${id}`,
  documents: () => '/web/form_folders/documents',
  documentsLibrary: () => '/web/documents',
  createDocument: () => `/web/documents/new`,
  editDocument: ({ id }) => `/web/documents/${id}/edit`,
  libraries: () => `/web/libraries`,
  createLibrary: () => `/web/libraries/new`,
  editLibrary: ({ libraryId }) => `/web/libraries/${libraryId}/edit`,
  fillForm: ({ id }) => `/web/templates/${id}/fill_form`,
  alerts: () => '/web/alerts',
  editUser: () => '/users/edit',
  signIn: () => '/users/sign_in',
  profiles: (params) => routeWithParams(params, `/web/profiles`),
  createProfile: () => '/web/profiles/new',
  editProfile: ({ id }) => `/web/profiles/${id}/edit`,
  manageUsers: (params) =>
    routeWithParams(params, '/web/profiles/manage_users'),
  manageUser: ({ id }) => `/web/profiles/manage_user/${id}`,
  newUser: () => '/web/profiles/manage_users/new',
  unauthorized: () => '/web/unauthorized',
  assessors: (params) => routeWithParams(params, '/web/assessors'),
  newAssessor: () => '/web/assessors/new',
  editAssessor: ({ id }) => `/web/assessors/${id}/edit`,
  actions: (params) => routeWithParams(params, '/web/actions'),
  newAction: () => `/web/actions/new`,
  editAction: ({ id }) => `/web/actions/${id}/edit`,
  viewAction: ({ id }) => `/web/actions/${id}`,
  templateReports: (params) =>
    `/web/template_reports?context=${params?.reportType ?? 'scheduled'}`,
  newTemplateReport: ({ searchParams, type }) =>
    `/web/template_reports/${type || 'scheduled'}/new${searchParams ?? ''}`,
  newSummaryTemplateReport: () => `/web/template_reports/summary/new`,
  editTemplateReport: ({ id, templateId, type }) =>
    `/web/template_reports/${type}/${id}/edit${
      templateId ? `?template_id=${templateId}` : ''
    }`,
  templateFrequencies: ({ id }) =>
    `/web/templates/${id}/template_frequency_settings`,
  editTemplateFrequency: ({ templateId, frequencyId }) =>
    `/web/templates/${templateId}/template_frequency_settings/edit?id=${frequencyId}`,
  createTemplateFrequency: ({ id }) =>
    `/web/templates/${id}/template_frequency_settings/new`,
  templateSettings: ({ id, params = { tab: 'edit_form' } }) => {
    const urlParams = new URLSearchParams({
      ...params
    })
    return `/web/templates/${id}/settings?${urlParams.toString()}`
  },
  checklists: () => '/web/checklists',
  createChecklist: () => '/web/checklists/new',
  editChecklist: ({ id }) => `/web/checklists/${id}/edit`,
  showChecklist: ({ id }) => `/web/checklists/${id}`,
  checklistTaskTraker: ({ id }) => `/web/checklists/${id}/tasks`,
  showChecklistUsersTasks: ({ id, itemId }) =>
    `/web/checklists/${id}/tasks/${itemId}`,
  manageStaff: (params) => {
    const queryParams = new URLSearchParams(params ?? {})
    return `/web/staff${
      !isEmpty(queryParams) ? `?${queryParams.toString()}` : ''
    }`
  },
  editStaff: ({ id }) => `/web/staff/${id}`,
  newStaff: () => `/web/staff/new`,
  assets: (params) => {
    const urlParams = new URLSearchParams({
      ...(params ?? {})
    })
    return `/web/assets?${urlParams.toString()}`
  },
  asset: ({ id }) => `/web/assets/${id}`,
  editAsset: ({ id }) => `/web/assets/${id}`,
  newAsset: (params) => {
    const urlParams = new URLSearchParams({
      ...(params ?? {})
    })
    return `/web/assets/new?${urlParams.toString()}`
  },
  assetTemplates: () => `/web/asset_types`,
  editAssetTemplate: ({ id }) => `/web/asset_types/${id}`,
  newAssetTemplate: () => '/web/asset_types/new',
  manageDashboards: (params) =>
    routeWithParams(params, `/web/manage_dashboards`),
  newDashboard: () => `/web/manage_dashboards/new`,
  editDashboard: ({ id }) => `/web/manage_dashboards/${id}`,
  privacyPolicy: () => '/privacy_policy',
  termsAndConditions: () => '/terms_and_conditions'
}

export default routes
