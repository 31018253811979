import { getRouteUrl } from 'helpers'
import React, { useMemo } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'

const ReportEditorBreadcrumb = ({
  fields,
  singularTitle,
  pageTitle,
  reportType
}) => {
  const options = useMemo(() => {
    const items = [
      {
        title: pageTitle,
        url: getRouteUrl('templateReports', { reportType })
      }
    ]
    if (fields?.title) {
      items.push(
        {
          title: fields?.title
        },
        {
          title: `Edit ${singularTitle}`,
          route: null
        }
      )
    } else {
      items.push({
        title: `New ${singularTitle}`,
        url: null
      })
    }
    return items
  }, [fields?.title, pageTitle, reportType, singularTitle])
  return <Breadcrumb routeTree={options} />
}

ReportEditorBreadcrumb.defaultProps = {
  reportType: 'scheduled'
}

export default ReportEditorBreadcrumb
