import { IconButton } from '@chakra-ui/react'
import MaterialIcon from 'components/MaterialIcon'
import React from 'react'

const RemoveButton = ({ isRemovable, focusedField, removeElement }) => {
  if (focusedField && isRemovable) {
    return (
      <IconButton
        aria-label="Remove element"
        colorScheme="red"
        onClick={removeElement}
        icon={<MaterialIcon icon='delete_forever' />}
      />
    )
  }
  return null
}

export default RemoveButton
