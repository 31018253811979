import React from 'react'
import NewPassword from '../NewPassword'
import ResetPassword from '../ResetPassword'
import SignIn from '../SignIn'

const PageContent = ({ currentPage, ...props }) => {
  if (currentPage === 'newPassword') {
    return <NewPassword {...props} />
  }
  if (currentPage === 'resetPassword') {
    return <ResetPassword {...props} />
  }
  return <SignIn {...props} />
}

export default PageContent
