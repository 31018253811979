import { Heading, Stack } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import React from 'react'
import ImageAnswer from '../ImageAnswer'
import CurrentAnswer from './CurrentAnswer'
import Notes from './Notes'

const Answer = ({ answer }) => {
  const attachments = answer?.answer?.attachments
  return (
    <Stack gap={4} py={2}>
      <CurrentAnswer answer={answer} />
      <Notes answer={answer} />
      {attachments &&
        !isEmpty(attachments) &&
        answer?.question_type !== 'image' && (
          <Stack>
            <Heading size="sm">Action Images</Heading>
            <ImageAnswer images={attachments} />
          </Stack>
        )}
    </Stack>
  )
}

export default Answer
