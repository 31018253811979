import React from 'react'
import BasePage from '../../../pages/BasePage'
import GroupEditor from '../../../pages/Group/GroupUsersEditor'
import OlingaAPI from '../../../services/OlingaAPI'

const GroupUsersPage = ({ id }) => {
  const callbacks = id
    ? {
        group: { callback: OlingaAPI.getGroup, params: {} },
        employees: { callback: OlingaAPI.employeesList, params: {} }
      }
    : {
        employees: { callback: OlingaAPI.employeesList, params: {} }
      }
  return (
    <BasePage
      fetchWithoutId
      id={id}
      resourceName="data"
      fetchCallback={callbacks}
    >
      <GroupEditor />
    </BasePage>
  )
}

export default GroupUsersPage
