import { ChevronDownIcon } from '@chakra-ui/icons'
import { isArray } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import { chakraComponentsSizes, getRouteUrl } from '../../../helpers'
import TemplateKebab from '../../Templates/TemplateKebab'

const TemplatePageBreadcrumb = ({
  templateId,
  templateDetails,
  kebabSize,
  routeName,
  ariaLabel,
  pageTitle,
  singularTitle
}) => {
  const breadcrumbRoutes = useMemo(() => {
    let items = [
      {
        title: pageTitle,
        url: getRouteUrl('templates')
      },
      {
        title: templateDetails?.formFolderName
      },
      {
        title: templateDetails?.name,
        options: (
          <TemplateKebab
            icon={<ChevronDownIcon />}
            hasBorder={false}
            id={templateId}
            size={kebabSize}
            permissions={templateDetails?.userPermissions}
            ariaLabel={ariaLabel}
            pageTitle={singularTitle}
          />
        )
      }
    ]
    if (isArray(routeName)) {
      items = items.concat(routeName)
    } else {
      items.push({
        title: routeName
      })
    }
    if (!templateDetails?.formFolderName) {
      items.splice(1, 1)
    }
    return items
  }, [
    templateDetails?.formFolderName,
    templateDetails?.name,
    templateDetails?.userPermissions,
    templateId,
    kebabSize,
    ariaLabel,
    routeName,
    pageTitle,
    singularTitle
  ])

  return <Breadcrumb routeTree={breadcrumbRoutes} />
}

TemplatePageBreadcrumb.defaultProps = {
  kebabSize: 'xs'
}

TemplatePageBreadcrumb.propTypes = {
  templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  templateDetails: PropTypes.shape({
    formFolderId: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  kebabSize: PropTypes.oneOf(chakraComponentsSizes),
  routeName: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
    .isRequired,
  ariaLabel: PropTypes.string
}

export default TemplatePageBreadcrumb
