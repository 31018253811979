import { Skeleton } from '@chakra-ui/react'
import React from 'react'

// it'll only render if the passed condition value is truthy
const LazyComponent = ({
  children,
  condition,
  skeletonWidth,
  skeletonHeight
}) => {
  return condition ? (
    children
  ) : (
    <Skeleton width={skeletonWidth} height={skeletonHeight} />
  )
}

LazyComponent.defaultProps = {
  skeletonWidth: '100%',
  skeletonHeight: '28px'
}

export default LazyComponent
