import React from 'react'
import AssessorEditor from '../../../pages/Assessors/AssessorEditor'
import BasePage from '../../../pages/BasePage'
import OlingaAPI from '../../../services/OlingaAPI'

const AssessorFormPage = ({ id }) => {
  const callbacks = id
    ? {
        assessor: { callback: OlingaAPI.getAssessor, params: {} },
        profiles: { callback: OlingaAPI.profilesEnumerators, params: {} },
        templates: { callback: OlingaAPI.templatesEnumerators, params: {} },
        checklists: { callback: OlingaAPI.checklistsEnumerators, params: {} },
        assetTemplates: {
          callback: () =>
            OlingaAPI.assetTemplatesAsEnumerator({ with_assets: false })
        },
        dashboards: {
          callback: () => OlingaAPI.manageDashboards(null, { active: true }),
          params: {}
        },
        documents: {
          callback: () => OlingaAPI.foldersEnumerator('documents'),
          params: {}
        },
        roles: {
          callback: () => OlingaAPI.profileRolesList({ isGrouped: true }),
          params: {}
        }
      }
    : {
        profiles: { callback: OlingaAPI.profilesEnumerators, params: {} },
        templates: { callback: OlingaAPI.templatesEnumerators, params: {} },
        checklists: { callback: OlingaAPI.checklistsEnumerators, params: {} },
        dashboards: {
          callback: () => OlingaAPI.manageDashboards(null, { active: true }),
          params: {}
        },
        assetTemplates: {
          callback: () =>
            OlingaAPI.assetTemplatesAsEnumerator({ with_assets: false })
        },
        documents: {
          callback: () => OlingaAPI.foldersEnumerator('documents'),
          params: {}
        },
        roles: {
          callback: () => OlingaAPI.profileRolesList({ isGrouped: true }),
          params: {}
        }
      }
  return (
    <BasePage
      fetchWithoutId
      id={id}
      resourceName="data"
      fetchCallback={callbacks}
    >
      <AssessorEditor />
    </BasePage>
  )
}

export default AssessorFormPage
