import { ChevronDownIcon } from '@chakra-ui/icons'
import React, { useMemo } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import { getRouteUrl } from '../../../../helpers'
import GroupKebab from '../../AssessorsKebab'

const AssessorEditorBreadcrumb = ({ assessor }) => {
  const options = useMemo(() => {
    const opts = [
      {
        title: 'Assessors',
        url: getRouteUrl('assessors')
      }
    ]
    if (assessor?.attributes) {
      opts.push(
        {
          title: assessor?.attributes?.name,
          options: (
            <GroupKebab
              id={assessor.attributes.id}
              size="xs"
              hasBorder={false}
              icon={<ChevronDownIcon />}
            />
          )
        },
        {
          title: 'Edit Assessor',
          route: null
        }
      )
    } else {
      opts.push({
        title: 'New Assessor',
        url: null
      })
    }
    return opts
  }, [assessor.attributes])

  return <Breadcrumb routeTree={options} />
}

export default AssessorEditorBreadcrumb
