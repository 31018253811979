/* eslint-disable react-hooks/exhaustive-deps */
import { Tag } from '@chakra-ui/react'
import { get, isEmpty, noop, trim } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { globalEvents } from '../../../../contexts/GlobalStateContext/events'
import { getRouteUrl, setFormErrors } from '../../../../helpers'
import { appMasks } from '../../../../helpers/masks'
import {
  useGlobalEventListener,
  usePageProperties,
  useTableColumns,
  useTableFilter
} from '../../../../hooks'
import Notification from '../../../../services/Notification'
import OlingaAPI from '../../../../services/OlingaAPI'
import ManageStaffActions from '../../ManageStaffActions'

const useManageStaff = () => {
  const tableKey = 'manage_staff_persisted_columns'
  const history = useHistory()
  const location = useLocation()

  const { screens } = useSelector(({ globalReducer }) => globalReducer)
  const [tableColumns, setTableColumns] = useState([])

  const [isToggling, setIsToggling] = useState(false)

  const onToggle = useCallback(async (id, onCloseDialog) => {
    setIsToggling(true)
    try {
      const response = await OlingaAPI.toggleStaffActiveState(id)
      if (response?.message) {
        Notification({
          icon: 'success',
          text: response?.message
        })
        onCloseDialog()

        refetch()
      }
    } catch (error) {
      const receivedErrors = setFormErrors(error, noop, Notification, {
        position: toast.POSITION.TOP_RIGHT
      })
      if (isEmpty(receivedErrors)) {
        Notification({
          icon: 'error',
          text: `Unable to toggle user active status. Check error logs`,
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } finally {
      setIsToggling(false)
    }
  }, [])

  const { columns, hiddenColumns } = useTableColumns(
    tableColumns,
    tableKey,
    true,
    ManageStaffActions,
    (row) => {
      const {
        values: { id }
      } = row
      return { id, isToggling, onToggle }
    },
    ['id']
  )

  const { pageTitle } = usePageProperties({ pageName: 'manage_staff' })

  const fieldsExtractor = useCallback((data) => {
    return data.map(({ attributes }) => {
      const {
        id,
        telephone,
        name,
        email,
        employment: { active },
        additionalFields
      } = attributes
      return {
        id,
        name: name || <Tag colorScheme="gray">Empty</Tag>,
        ...(additionalFields ?? []).reduce((acc, { value, attribute_name }) => {
          acc[attribute_name] = value
          if (!value) {
            acc[attribute_name] = <Tag colorScheme="gray">Empty</Tag>
          }
          return acc
        }, {}),
        email: email || <Tag colorScheme="gray">Empty</Tag>,
        telephone: telephone ? (
          appMasks.phoneNumber(telephone)
        ) : (
          <Tag colorScheme="gray">Not informed</Tag>
        ),
        active: (
          <Tag colorScheme={active ? 'green' : 'gray'}>
            {active ? 'Active' : 'Inactive'}
          </Tag>
        )
      }
    })
  }, [])

  const onChangePage = (params) => {
    updateParams(params)
  }

  useEffect(() => {
    const fetchAdditionalFields = async () => {
      try {
        const additionalFields = await OlingaAPI.custom.staff.additionalFields()
        const tableColumns = [
          {
            Header: 'ID',
            accessor: 'id'
          },
          {
            Header: 'Full name',
            accessor: 'name'
          },
          ...(additionalFields ?? []).map(({ attribute_name, label }) => ({
            Header: label,
            accessor: attribute_name
          })),
          {
            Header: 'Email',
            accessor: 'email'
          },
          {
            Header: 'Telephone',
            accessor: 'telephone'
          },
          {
            Header: 'Active',
            accessor: 'active'
          }
        ]
        setTableColumns(tableColumns)
      } catch (err) {
        //
      }
    }

    fetchAdditionalFields()
  }, [])

  const {
    refetch,
    isLoading,
    updateParams,
    fetchParams,
    tableData,
    pagination
  } = useTableFilter({
    fetchCallback: OlingaAPI.manageStaff,
    fieldsExtractor
  })

  const onTextChange = useCallback(
    (updatedText) => {
      if (!updatedText || !trim(updatedText)?.length) {
        return refetch(['full_name'])
      }
      updateParams({ full_name: updatedText }, updatedText?.length >= 3)
    },
    [refetch, updateParams]
  )

  useEffect(() => {
    const pathname = history.location.pathname
    if (
      pathname.match(/\/web\/staff\/[0-9]+/g) ||
      pathname.match(/\/web\/staff\/new*/g)
    ) {
      const staffId = pathname.replace(/\D/g, '')
      history.replace(getRouteUrl('editStaff', { id: staffId || 'new' }), {
        background: location
      })
    }
  }, [history, location])

  useGlobalEventListener(
    useCallback(
      (event) => {
        if (event.type === globalEvents.ON_MANAGE_STAFF_SUCCESS) {
          refetch()
        }
      },
      [refetch]
    )
  )

  const { subject } = {
    subject:
      get(screens, 'manage_staff.business.custom_subject') ||
      get(screens, 'manage_staff.subject')
  }

  return {
    columns,
    hiddenColumns,
    refetch,
    isLoading,
    updateParams,
    fetchParams,
    tableData,
    pagination,
    onTextChange,
    onChangePage,
    tableKey,
    location,
    title: pageTitle,
    subject,
  }
}

export default useManageStaff
