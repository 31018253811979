import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpDownIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  DragHandleIcon
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Table as ChakraTable,
  Flex,
  IconButton,
  Select,
  SimpleGrid,
  Skeleton,
  Stack,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery
} from '@chakra-ui/react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

import {isFunction, isNumber, map, noop} from 'lodash';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {usePagination, useSortBy, useTable} from 'react-table';
import colors from '../../utils/colors';
import EmptyState from '../EmptyState';
import TextClamper from '../TextClamper';
import ColumnsVisibilityController from './ColumnsVisibilityController';

/* Pass the hasManualPagination=false attribute to use pagination without external fetch */

const Table = ({
  columns: tableColumns,
  data: tableData,
  isLoading,
  initialPageIndex,
  initialRowsPerPage,
  paginationPosition,
  paginationButtonSize,
  paginationButtonColorScheme,
  paginationButtonVariant,
  fetchPage,
  pageCount,
  totalCount,
  totalItems,
  hasManualPagination,
  shouldResetPage,
  emptyStateMessage,
  pagesInterval,
  isUsingElastic,
  persistedColumnsKey,
  initiallyHiddenColumns,
  hiddenColumnsBlacklist,
  shouldDisplayColumnsController,
  hiddenColumnsLimit,
  customColumnStyles,
  children,
  shouldItemsMessageOnFooter,
  isDraggeable,
  onDragEnd,
  shouldShowPagination,
}) => {
  const [clickedPage, setClickedPage] = useState(null)
  const [data, setData] = useState(tableData);
  const [columns, setColumns] = useState(tableColumns)


  const DragIcon = (props) => (
    <span
      {...props.dragHandleProps}
      className={props.className}
      aria-label="move"
    >
      <Flex gap='5px'>
        <DragHandleIcon color={colors.primary} /> {props?.row?.original?.order}
      </Flex>
    </span>
  );

  useEffect(() => {
    setData(tableData);
    
  }, [tableData, ])

  useEffect(() => {
    setColumns((previousColumns) => {
      const columnsIds = map(previousColumns, (column) => column.id);
      if (isDraggeable && previousColumns && !columnsIds.includes('order')) {
        return [
          {
            Header: 'Order',
            id: 'order',
            size: 30,
            Cell: (props) => <DragIcon {...props} />
          },
          ...previousColumns
        ]
      }

      return previousColumns
    })
  }, [tableColumns, isDraggeable]);
  
  const reorderData = (startIndex, endIndex) => {
    const newData = [...data];
    const [movedRow] = newData.splice(startIndex, 1);
    newData.splice(endIndex, 0, movedRow);
    setData(newData);
    onDragEnd(newData);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    allColumns,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: initialPageIndex,
        pageSize: initialRowsPerPage,
        hiddenColumns: initiallyHiddenColumns
      },
      manualPagination: hasManualPagination,
      autoResetSortBy: false,
      autoResetPage: false,
      pageCount
    },
    useSortBy,
    usePagination,
  )

  const handleDragEnd = result => {
    const { source, destination } = result;
    if (!destination) return;
    reorderData(source.index, destination.index);
  };
  
  useEffect(() => {
    if (pageOptions?.length && pageIndex + 1 > pageOptions.length) {
      gotoPage(0)
    }
  }, [gotoPage, pageIndex, pageOptions])

  useEffect(() => {
    if (shouldResetPage) {
      gotoPage(0)
    }
  }, [gotoPage, shouldResetPage])

  const paginationColumnsLength = useMemo(() => {
    return pagesInterval * 2 + 1
  }, [pagesInterval])

  const getColumnLabel = useCallback((column) => {
    if (column?.RawHeader) {
      return column.RawHeader
    } else if (isFunction(column.Header)) {
      return column.Header()
    }
    return column.Header
  }, [])

  const isMobile = useMediaQuery('(max-width: 799px)')

  const itemsCountLabel = useMemo(() => {
    if (!hasManualPagination) {
      return ''
    }
    return `${
      pageSize * (pageIndex + 1) > totalItems
        ? totalItems
        : pageSize * (pageIndex + 1)
    } / ${totalItems} ${pageSize === 1 ? 'item' : 'items'}`
  }, [hasManualPagination, pageIndex, pageSize, totalItems])

  const getSortAdornment = (column) => {
    if (isLoading) {
      return null
    }
    const { canSort, isSorted, isSortedDesc } = column
    if (canSort) {
      if (isSorted) {
        return isSortedDesc ? (
          <ChevronDownIcon color="blue.400" ml={1} w={4} h={4} />
        ) : (
          <ChevronUpIcon color="blue.400" ml={1} w={4} h={4} />
        )
      } else {
        return <ArrowUpDownIcon ml={1} w={3} h={3} />
      }
    } else {
      return null
    }
  }

  const [isChangingPage, setIsChangingPage] = useState(false)

  useEffect(() => {
    setIsChangingPage(isLoading)
  }, [isLoading])

  const getPaginationButtons = useCallback(() => {
    const initialCount = isUsingElastic ? 2 : 4
    let columnsCount = initialCount
    const handlePageClick = (pageNumber) => {
      setClickedPage(pageNumber)
      setIsChangingPage(true)
      if (hasManualPagination) {
        fetchPage({ page: pageNumber + 1, per_page: pageSize }, gotoPage)
        gotoPage(pageNumber)
      } else {
        gotoPage(pageNumber)
      }
    }
    const getRangeButtons = () => {
      const getSequentialPages = (sliceRange = null, sliceEndRange = null) => {
        let array = Array.from(new Array(pageCount))
        if (isNumber(sliceRange) && !isNumber(sliceEndRange)) {
          array = array
            .slice(sliceRange)
            .map((_, index) => pageCount - index - 1)
            .reverse()
        } else if (isNumber(sliceRange) && isNumber(sliceEndRange)) {
          const originalIndexes = array.map((_, index) => index)
          array = originalIndexes.slice(sliceRange, sliceEndRange)
        }
        return array.map((page, index) => {
          const value = isNumber(page) ? page : index
          return (
            <Button
              size={paginationButtonSize}
              colorScheme="orange"
              variant={pageIndex === value ? 'solid' : 'outline'}
              key={index}
              onClick={() => handlePageClick(value)}
              isLoading={isLoading && clickedPage === value}
              isDisabled={isLoading}
            >
              {value + 1}
            </Button>
          )
        })
      }
      if (isUsingElastic) {
        if (pageIndex < pagesInterval) {
          return getSequentialPages(0, pagesInterval)
        }
        const index = pageIndex
        const half = Math.ceil(pagesInterval / 2)
        const bottomLimit = index - half <= 0 ? index : index - half - 1
        const upperLimit =
          index + half > pageCount ? pageCount : index + half - 1
        const absoluteBottomLimit =
          Math.abs(bottomLimit - upperLimit) <= pagesInterval - 1
            ? bottomLimit - 1
            : bottomLimit
        const absoluteUpperLimit =
          Math.abs(upperLimit - bottomLimit) >= pagesInterval + 1
            ? upperLimit - 1
            : upperLimit
        return getSequentialPages(absoluteBottomLimit, absoluteUpperLimit)
      } else {
        if (pageCount <= pagesInterval) {
          return getSequentialPages()
        } else {
          if (pageIndex >= pageCount - paginationColumnsLength) {
            return getSequentialPages(-paginationColumnsLength)
          }
          const firstRange = Array.from(new Array(pagesInterval)).map(
            (_, index) => pageIndex + index
          )
          const lastRange = Array.from(new Array(pagesInterval)).map(
            (_, index) => pageOptions.length - (pagesInterval - index)
          )
          const CutRangeButton = (
            <Button
              size={paginationButtonSize}
              colorScheme="orange"
              variant="solid"
              isDisabled
              border="none"
            >
              ...
            </Button>
          )
          return (
            <>
              {firstRange.map((range) => (
                <Button
                  size={paginationButtonSize}
                  colorScheme="orange"
                  key={range}
                  variant={pageIndex === range ? 'solid' : 'outline'}
                  onClick={() => handlePageClick(range)}
                  isLoading={isLoading && clickedPage === range}
                  isDisabled={isLoading}
                >
                  {range + 1}
                </Button>
              ))}
              {CutRangeButton}
              {lastRange.map((range) => (
                <Button
                  size={paginationButtonSize}
                  colorScheme="orange"
                  key={range}
                  variant={pageIndex === range ? 'solid' : 'outline'}
                  onClick={() => handlePageClick(range)}
                  isLoading={isLoading && clickedPage === range}
                  isDisabled={isLoading}
                >
                  {range + 1}
                </Button>
              ))}
            </>
          )
        }
      }
    }
    if (isUsingElastic) {
      columnsCount = initialCount + pagesInterval
    } else {
      columnsCount =
        initialCount +
        (pageCount <= paginationColumnsLength
          ? pageCount
          : paginationColumnsLength)
    }
    return (
      <SimpleGrid
        gap={isMobile ? '4px' : '16px'}
        templateColumns={`repeat(${columnsCount}, 1fr)`}
      >
        {!isUsingElastic && (
          <IconButton
            icon={<ArrowLeftIcon fontSize="10px" />}
            size={paginationButtonSize}
            colorScheme={paginationButtonColorScheme}
            variant={paginationButtonVariant}
            isDisabled={pageIndex === 0}
            onClick={() => handlePageClick(0)}
          />
        )}
        <IconButton
          icon={<ChevronLeftIcon fontSize="18px" />}
          size={paginationButtonSize}
          colorScheme={paginationButtonColorScheme}
          variant={paginationButtonVariant}
          isDisabled={!canPreviousPage}
          onClick={() => handlePageClick(pageIndex - 1)}
        />
        {getRangeButtons()}
        <IconButton
          icon={<ChevronRightIcon fontSize="18px" />}
          size={paginationButtonSize}
          colorScheme={paginationButtonColorScheme}
          variant={paginationButtonVariant}
          isDisabled={!canNextPage}
          onClick={() => handlePageClick(pageIndex + 1)}
        />
        {!isUsingElastic && (
          <IconButton
            icon={<ArrowRightIcon fontSize="10px" />}
            size={paginationButtonSize}
            colorScheme={paginationButtonColorScheme}
            variant={paginationButtonVariant}
            isDisabled={pageIndex === pageCount - 1}
            onClick={() => handlePageClick(pageCount - 1)}
          />
        )}
      </SimpleGrid>
    )
  }, [
    canNextPage,
    canPreviousPage,
    clickedPage,
    fetchPage,
    gotoPage,
    hasManualPagination,
    isLoading,
    isMobile,
    isUsingElastic,
    pageCount,
    pageIndex,
    pageOptions.length,
    pageSize,
    pagesInterval,
    paginationButtonColorScheme,
    paginationButtonSize,
    paginationButtonVariant,
    paginationColumnsLength
  ])

  const handleSelectLimit = useCallback(
    (event) => {
      const {
        target: { value }
      } = event
      setPageSize(value)
      fetchPage({ page: 1, per_page: value }, gotoPage)
    },
    [fetchPage, gotoPage, setPageSize]
  )

  const getElasticPagination = useCallback(() => {
    if (!isLoading && !data?.length) {
      return null
    }
    if (!isUsingElastic) {
      return null
    }

    return (
      <Flex w="100%" py={4} align="center" justify={paginationPosition}>
        <Stack minW="354px">
          {pageCount > 1 && getPaginationButtons()}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            mt="4"
          >
            <Select
              maxW="150px"
              size="sm"
              mr={2}
              value={pageSize}
              onChange={handleSelectLimit}
            >
              {Array.from(new Array(5)).map((_, index) => (
                <option value={(index + 2) * 5} key={index}>
                  {(index + 2) * 5}
                </option>
              ))}
            </Select>
            {!isUsingElastic && (
              <Text fontSize="16px" colorScheme="gray">
                <b>{pageIndex + 1}</b> of <b>{pageOptions.length}</b>{' '}
                {pageCount === 1 ? 'page' : 'pages'}
              </Text>
            )}
            <Text ml="2" fontSize="16px" colorScheme="gray">
              <b>{itemsCountLabel}</b>
            </Text>
          </Box>
        </Stack>
      </Flex>
    )
  }, [
    data?.length,
    getPaginationButtons,
    handleSelectLimit,
    isLoading,
    isUsingElastic,
    itemsCountLabel,
    pageCount,
    pageIndex,
    pageOptions.length,
    pageSize,
    paginationPosition
  ])
  

  const getActiveRecordPagination = useCallback(() => {
    if (!isLoading && !data?.length) {
      return null
    }
    if (isUsingElastic) {
      return null
    }

    if (!shouldShowPagination) {
      return null
    }
    
    return (
      <Flex w="100%" py={4} align="center" justify={paginationPosition}>
        <Stack minW="354px">
          {pageCount > 1 && getPaginationButtons()}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            mt="4"
          >
            <Select
              maxW="150px"
              size="sm"
              mr={2}
              value={pageSize}
              onChange={handleSelectLimit}
            >
              {Array.from(new Array(5)).map((_, index) => (
                <option value={(index + 2) * 5} key={index}>
                  {(index + 2) * 5}
                </option>
              ))}
            </Select>
            {!shouldItemsMessageOnFooter && (
              <>
                <Text fontSize="16px" colorScheme="gray">
                  <b>{pageIndex + 1}</b> of <b>{pageOptions.length}</b>{' '}
                  {pageCount === 1 ? 'page' : 'pages'}
                </Text>
                <Text ml="2" fontSize="16px" colorScheme="gray">
                  <b>{itemsCountLabel}</b>
                </Text>
              </>
            )}
            {shouldItemsMessageOnFooter && (
              <Box w="410px">
                <Text fontSize="16px" colorScheme="gray">
                  Showing{' '}
                  <b>
                    {isChangingPage ? '...' : pageIndex * pageSize + totalItems}
                  </b>{' '}
                  of <b>{totalCount}</b> forms
                </Text>
                <Text>
                  (<b>{pageSize}</b> forms per page)
                </Text>
              </Box>
            )}
          </Box>
        </Stack>
      </Flex>
    )
  }, [
    data?.length,
    shouldItemsMessageOnFooter,
    getPaginationButtons,
    totalCount,
    totalItems,
    handleSelectLimit,
    isLoading,
    isUsingElastic,
    pageOptions,
    itemsCountLabel,
    pageCount,
    pageIndex,
    isChangingPage,
    pageSize,
    paginationPosition
  ])

  const getTableBody = () => {
    if (isLoading) {
      return (
        <Tbody data-testid="table-loading">
          {Array.from(new Array(Number(pageSize))).map((_, index) => {
            return (
              <Tr key={index}>
                {Array.from(new Array(columns.length)).map((_, index) => {
                  return (
                    <Td key={index}>
                      <Box borderBottom="2px solid white">
                        <Skeleton w="50%" h="25px" />
                      </Box>
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      )
    }

    if (isDraggeable) {
      return (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="table-body">
            {(provided) => (
              <Tbody {...getTableBodyProps()} ref={provided.innerRef} {...provided.droppableProps}>
                {rows.map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <Draggable
                      draggableId={row.id}
                      key={row.id}
                      index={rowIndex}
                    >
                      {(provided, snapshot) => {
                        return (
                          <Tr
                              {...row.getRowProps()}
                              key={row.index}
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                              isDragging={snapshot.isDragging}
                            >
                              {row.cells.map((cell, index) => {
                                return (
                                  <Td
                                    data-testid="table-content"
                                    key={index}
                                    {...cell.getCellProps()}
                                    {...customColumnStyles(cell)}
                                  >
                                    {cell.render("Cell", {
                                      index: rowIndex,
                                      dragHandleProps: provided.dragHandleProps,
                                      isSomethingDragging: snapshot.isDraggingOver
                                    })}
                                  </Td>
                                )
                              })}
                            </Tr>
                        )
                      }}
                    </Draggable>
                  )})}
              </Tbody>
            )}
          </Droppable>
        </DragDropContext>
      )
    }

    return (
      <Tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row)
          return (
            <Tr {...row.getRowProps()} key={index}>
              {row.cells.map((cell, index) => {
                return (
                  <Td
                    data-testid="table-content"
                    key={index}
                    {...cell.getCellProps()}
                    {...customColumnStyles(cell)}
                  >
                    {cell.render('Cell')}
                  </Td>
                )
              })}
            </Tr>
          )
        })}
      </Tbody>
    )
  }

  return (
    <Stack w="100%" overflowX="auto" flex={1}>
      {shouldDisplayColumnsController && (
        <Flex align="center" my={2} gap={4} w="100%" justify="flex-end">
          {children}
          <ColumnsVisibilityController
            columns={allColumns}
            persistedColumnsKey={persistedColumnsKey}
            blackList={hiddenColumnsBlacklist}
            columnsLimit={hiddenColumnsLimit}
            isLoading={isLoading}
          />
        </Flex>
      )}
      {!isLoading && !data?.length ? (
        <EmptyState text={emptyStateMessage} />
      ) : (
        <ChakraTable
          {...getTableProps()}
          minH={isLoading ? `${65.05 * pageSize + 40.4}px` : 'auto'}
        >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    data-testid="table-header"
                    userSelect="none"
                    key={index}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    _disabled={isLoading}
                  >
                    <Flex align="center">
                      {isLoading && <Skeleton h="16px" w="30%" />}
                      {!isLoading && (
                        <TextClamper
                          text={getColumnLabel(column)}
                          isHtml={!!column.RawHeader}
                        />
                      )}
                      {getSortAdornment(column)}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          {getTableBody()}
        </ChakraTable>
      )}
      {getElasticPagination()}
      {getActiveRecordPagination()}
    </Stack>
  )
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  initialPageIndex: PropTypes.number,
  initialRowsPerPage: PropTypes.number,
  paginationPosition: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  paginationButtonSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  paginationButtonVariant: PropTypes.oneOf(['solid', 'outline']),
  paginationButtonColorScheme: PropTypes.string,
  fetchPage: PropTypes.func,
  hasManualPagination: PropTypes.bool,
  shouldResetPage: PropTypes.bool,
  emptyStateMessage: PropTypes.string,
  pagesInterval: PropTypes.number,
  isUsingElastic: PropTypes.bool,
  customColumnStyles: PropTypes.func,
  shouldItemsMessageOnFooter: PropTypes.bool,
  onDragEnd: PropTypes.func,
  isDraggeable: PropTypes.bool,
  shouldShowPagination: PropTypes.bool,
}

Table.defaultProps = {
  isLoading: false,
  initialPageIndex: 0,
  initialRowsPerPage: 15,
  paginationPosition: 'flex-end',
  paginationButtonSize: 'sm',
  paginationButtonVariant: 'solid',
  paginationButtonColorScheme: 'blue',
  fetchPage: null,
  hasManualPagination: true,
  shouldResetPage: false,
  emptyStateMessage: 'No results found',
  pagesInterval: 2,
  isUsingElastic: false,
  initiallyHiddenColumns: [],
  persistedColumnsKey: '',
  hiddenColumnsBlacklist: ['expander'],
  shouldDisplayColumnsController: true,
  hiddenColumnsLimit: -1,
  customColumnStyles: () => ({}),
  shouldItemsMessageOnFooter: false,
  onDragEnd: noop,
  isDraggeable: false,
  shouldShowPagination: true,
}

export default Table
