import { theme } from '@chakra-ui/react'
import styled from '@emotion/styled'

const getOffsetStyles = (isDragging, dragOffset) => {
  if (isDragging && typeof dragOffset === 'string') {
    return {
      left: `${dragOffset}!important`
    }
  }
  return {}
}

export const getListItemStyles = (isDragging, dragOffset) => ({
  borderRadius: 4,
  border: `2px solid ${theme.colors.gray[300]}`,
  borderColor: isDragging ? theme.colors.orange[400] : theme.colors.gray[300],
  p: 4,
  w: '100%',
  bg: theme.colors.white,
  ...getOffsetStyles(isDragging, dragOffset)
})

export const getListStyles = (isDraggingOver) => ({
  borderRadius: 4,
  py: 4,
  minH: 'auto',
  bg: isDraggingOver ? theme.colors.gray[100] : theme.colors.white
})

export const Wrapper = styled.div`
  & li {
    list-style: none;
  }
`
