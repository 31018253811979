import { Button } from '@chakra-ui/react';
import React from 'react';
import OlingaAPI from '../../../../services/OlingaAPI';

const MasqueradeButton = ({ user: { email, id } }) => {
  const onClick = async () => {
    const response = await OlingaAPI.masqueradeUser({ id })
    
    if (response?.success) {
      window.location.href = '/web/businesses/dashboard'
    }
  }
  
  return (
    <Button
      bgColor='transparent'
      textDecoration='underline'
      type='link'
      onClick={onClick}
    >
      {email}
    </Button>
  );
} 

export default MasqueradeButton;
