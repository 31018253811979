import React from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import { getRouteUrl } from '../../../../helpers'

const ManageStaffBreadcrumb = ({ title }) => {
  return (
    <Breadcrumb
      routeTree={[
        {
          title,
          url: getRouteUrl('manageStaff')
        }
      ]}
    />
  )
}

export default ManageStaffBreadcrumb
