/* eslint-disable react/display-name */
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
  useMediaQuery
} from '@chakra-ui/react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import {
  cloneDeep,
  intersection,
  isArray,
  isFunction,
  isNumber,
  keys,
  uniq
} from 'lodash'
import PropTypes from 'prop-types'
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef
} from 'react'
import { v4 as uuid } from 'uuid'
import PageHeader from '../../components/PageHeader'
import { useLocalStorage } from '../../hooks'
import MaterialIcon from '../MaterialIcon'
import AllFoldersButton from './AllFoldersButton'
import FolderContent from './FolderContent'
import FolderIcon from './FolderIcon'
import LoadingTreeSkeleton from './LoadingTreeSkeleton'
import RightAdornment from './RightAdornment'
import ShowAllCheckbox from './ShowAllCheckbox'
import StickyActions from './StickyActions'
import foldersTreeReducer, {
  ACTIONS,
  foldersTreeInitialState,
  onScroll,
  scrollAndShine
} from './helpers'

const {
  SET_LOADING_FOLDERS,
  SET_LOADING_ALL_RESOURCES,
  SET_ALL_RESOURCES_WERE_LOADED,
  SET_FOLDERS,
  SET_FOLDERS_STATES,
  SET_SHOULD_DISPLAY_ALL_RESOURCES,
  SET_SHOULD_RENDER_STICKY_ACTIONS
} = ACTIONS

const FoldersTree = forwardRef(
  (
    {
      resourceFetchCallback,
      folderListCallback,
      getFolderListCallbackParams,
      resourceName,
      getResources,
      Autocomplete,
      ActionsAdornment,
      title,
      getFolderAdornment,
      expandedResourceKey,
      pageName,
      adornmentOtherAttributes,
      displayHelperSubtitles,
      displayLoadAllOpenedFoldersButton,
      displayAllFoldersButton,
      isTest,
      itemsPerPage,
      shouldActivateInactiveFiltering
    },
    ref
  ) => {
    const [
      {
        isLoadingFolders,
        isLoadingAllResources,
        allResourcesWereLoaded,
        shinyResourceId,
        folders,
        foldersStates,
        shouldDisplayAllResources,
        shouldRenderStickyActions
      },
      dispatch
    ] = useReducer(foldersTreeReducer, foldersTreeInitialState)

    const [isMobile] = useMediaQuery('(max-width: 799px)')
    const [shouldWrapButtons] = useMediaQuery('(max-width: 576px)')

    const fetchFoldersRef = useRef(false)
    const storedResourcesRef = useRef({})

    const {
      setData: setStoredExpandedFolders,
      getData: getCurrentStoredExpandedeFolders,
      isDataRetrieved
    } = useLocalStorage(expandedResourceKey)

    useScrollPosition(
      ({ currPos }) => {
        if (currPos.y <= -200) {
          dispatch({
            type: SET_SHOULD_RENDER_STICKY_ACTIONS,
            payload: true
          })
        } else {
          dispatch({
            type: SET_SHOULD_RENDER_STICKY_ACTIONS,
            payload: false
          })
        }
      },
      [],
      null,
      false,
      500
    )

    const highlightResource = (resourceObject, onClose) => {
      const { formFolderId, id: resourceId } = resourceObject
      const currentState = Object.assign({}, foldersStates)
      const alreadyLoaded = currentState[formFolderId][resourceName].length > 0
      currentState[formFolderId].isOpened = true
      dispatch({ type: SET_FOLDERS_STATES, payload: { ...currentState } })
      if (alreadyLoaded) {
        scrollAndShine(dispatch, resourceName, resourceId)
      } else {
        fetchFoldersResources(formFolderId, scrollAndShine)
      }

      onClose()
    }

    const shouldDisplayAllLoadedFoldersButton = useMemo(() => {
      return (
        displayLoadAllOpenedFoldersButton &&
        keys(foldersStates).some(
          (key) => foldersStates[key][resourceName].length > 0
        )
      )
    }, [foldersStates, resourceName, displayLoadAllOpenedFoldersButton])

    useImperativeHandle(ref, () => ({
      highlightResource
    }))

    const setExpandedFolders = (id, action = 'add') => {
      const currentItems = getCurrentStoredExpandedeFolders() ?? []
      if (action === 'replace') {
        return setStoredExpandedFolders({ [expandedResourceKey]: id })
      }
      if (action === 'add') {
        const ids = isArray(id) ? id : [id]
        setStoredExpandedFolders({
          [expandedResourceKey]: uniq([...currentItems, ...ids])
        })
      } else if (action === 'remove') {
        setStoredExpandedFolders({
          [expandedResourceKey]: currentItems.filter((item) => item !== id)
        })
      }
    }

    const handleFolderOpen = (id) => {
      const currentState = Object.assign({}, foldersStates)
      const isOpened = currentState[id].isOpened
      const alreadyLoaded = currentState[id][resourceName].length > 0
      currentState[id].isOpened = !isOpened

      dispatch({
        type: SET_FOLDERS_STATES,
        payload: cloneDeep(currentState)
      })
      if (!isOpened && !alreadyLoaded) {
        fetchFoldersResources(id)
      }
      if (!isOpened) {
        setExpandedFolders(id)
        onScroll(`folder-${id}`)
      } else {
        setExpandedFolders(id, 'remove')
      }
    }

    const handleActiveFilter = async (value) => {
      dispatch({
        type: SET_SHOULD_DISPLAY_ALL_RESOURCES,
        payload: value
      })
      await fetchFoldersList(
        value,
        shouldActivateInactiveFiltering
          ? () => filterByActiveResource(value)
          : undefined
      )
    }

    const fetchFoldersResources = async (
      id,
      afterFetchCallback = () => ({})
    ) => {
      const currentState = Object.assign({}, foldersStates)
      currentState[id] = { ...currentState[id], isLoading: true }
      dispatch({
        type: SET_FOLDERS_STATES,
        payload: { ...currentState }
      })
      try {
        const { page, perPage } = getStatePagination(currentState[id])
        let fetchParams = {
          page,
          per_page: perPage
        }
        if (shouldDisplayAllResources) {
          fetchParams = {
            ...fetchParams,
            [`all_${resourceName}`]: shouldDisplayAllResources
          }
        }
        const response = await resourceFetchCallback(id, fetchParams)
        const data = response?.data
        const meta = response?.meta
        currentState[id] = {
          ...currentState[id],
          isLoading: false,
          [resourceName]: [
            ...(currentState[id]?.[resourceName] ?? []),
            ...data
          ],
          meta,
          hasLoadMore: isNumber(meta?.next)
        }
        dispatch({
          type: SET_FOLDERS_STATES,
          payload: { ...currentState }
        })
        storedResourcesRef.current = {
          ...storedResourcesRef.current,
          [id]: { ...currentState[id] }
        }

        afterFetchCallback(dispatch, resourceName, id)
        filterByActiveResource(shouldDisplayAllResources, currentState)
      } catch (error) {
        currentState[id] = { ...currentState[id], isLoading: false }
        dispatch({
          type: SET_FOLDERS_STATES,
          payload: { ...currentState }
        })
      }
    }

    const filterByActiveResource = useCallback(
      (value, state) => {
        const currentState = Object.assign({}, state ?? foldersStates)
        const allIds = keys(currentState)
        if (!value ?? !shouldDisplayAllResources) {
          allIds.forEach((id) => {
            currentState[id] = {
              ...currentState[id],
              [resourceName]: currentState[id][resourceName]?.filter(
                ({ attributes }) => !!attributes?.active
              )
            }
          })
          dispatch({
            type: SET_FOLDERS_STATES,
            payload: cloneDeep(currentState)
          })
        } else {
          const storedState = storedResourcesRef.current
          allIds.forEach((id) => {
            storedState[id] = {
              ...storedState[id],
              isOpened: currentState[id].isOpened
            }
          })
          dispatch({
            type: SET_FOLDERS_STATES,
            payload: cloneDeep(storedState)
          })
        }
      },
      [foldersStates, resourceName, shouldDisplayAllResources]
    )

    const getStatePagination = useCallback(
      (currentState) => {
        const currentPagination = currentState?.meta
        const page = currentPagination?.page || 0
        const perPage = currentPagination?.items || itemsPerPage

        return {
          page: page + 1,
          perPage
        }
      },
      [itemsPerPage]
    )

    const handleFetchAllResources = async () => {
      const currentState = Object.assign({}, foldersStates)
      const allIds = keys(foldersStates)
      if (!allResourcesWereLoaded) {
        dispatch({
          type: SET_LOADING_ALL_RESOURCES,
          payload: true
        })
        allIds.forEach((id) => {
          currentState[id] = {
            ...currentState[id],
            isLoading: currentState[id][resourceName].length === 0,
            isOpened: true
          }
        })
        dispatch({
          type: SET_FOLDERS_STATES,
          payload: { ...currentState }
        })
        const promises = folders.map(({ id }) => resourceFetchCallback(id))
        try {
          const allResoucesData = await Promise.all(promises)
          folders.forEach(({ id }, index) => {
            const { data } = allResoucesData[index]
            currentState[id].isOpened = true
            currentState[id].isLoading = false
            currentState[id][resourceName] = data
          })
          dispatch({
            type: SET_FOLDERS_STATES,
            payload: { ...currentState }
          })
          storedResourcesRef.current = currentState
          dispatch({
            type: SET_LOADING_ALL_RESOURCES,
            payload: false
          })
          dispatch({
            type: SET_ALL_RESOURCES_WERE_LOADED,
            payload: true
          })
          filterByActiveResource()
        } catch (error) {
          allIds.forEach((id) => {
            currentState[id] = {
              ...currentState[id],
              isLoading: false,
              isOpened: false
            }
          })
          dispatch({
            type: SET_FOLDERS_STATES,
            payload: { ...currentState }
          })
          dispatch({
            type: SET_LOADING_ALL_RESOURCES,
            payload: false
          })
          dispatch({
            type: SET_ALL_RESOURCES_WERE_LOADED,
            payload: false
          })
          storedResourcesRef.current = currentState
        }
      } else {
        folders.forEach(({ id }) => (currentState[id].isOpened = true))
        dispatch({
          type: SET_FOLDERS_STATES,
          payload: { ...currentState }
        })
      }

      setExpandedFolders(allIds, 'replace')
    }

    const handleDisplayLoadedFolders = () => {
      const currentState = Object.assign({}, foldersStates)
      const foldersWithResourcesLoaded = keys(currentState).reduce(
        (acc, folder) => {
          if (currentState[folder][resourceName].length > 0) {
            acc = acc.concat(folder)
          }
          return acc
        },
        []
      )
      foldersWithResourcesLoaded.forEach((folder) => {
        currentState[folder] = {
          ...currentState[folder],
          isOpened: true
        }
      })
      dispatch({
        type: SET_FOLDERS_STATES,
        payload: cloneDeep(currentState)
      })
      setExpandedFolders(
        foldersWithResourcesLoaded.map((folder) => Number(folder))
      )
    }

    const fetchExpandedFoldersResources = useCallback(
      async (foldersList, filterTemplates, params = {}) => {
        const currentExpandedFolders = getCurrentStoredExpandedeFolders() ?? []
        const validExpandedFolders = intersection(
          keys(foldersList).map((key) => Number(key)),
          currentExpandedFolders
        )
        if (validExpandedFolders.length > 0) {
          const promises = validExpandedFolders.map((id) => {
            const { page, perPage } = getStatePagination(
              currentState?.[id] ?? {}
            )
            return resourceFetchCallback(id, {
              page,
              per_page: perPage,
              ...params
            })
          })
          const currentState = Object.assign({}, foldersList)
          dispatch({
            type: SET_FOLDERS_STATES,
            payload: { ...currentState }
          })
          try {
            const allResources = await Promise.all(promises)
            validExpandedFolders.forEach((id, index) => {
              const response = allResources[index]
              const data = response?.data
              const meta = response?.meta
              currentState[id] = {
                ...currentState[id],
                isLoading: false,
                [resourceName]: data,
                meta,
                hasLoadMore: isNumber(meta?.next)
              }
            })
            keys(foldersList).forEach((id) => {
              currentState[id] = {
                ...currentState[id],
                isLoading: false
              }
            })
            const newState = cloneDeep(currentState)
            storedResourcesRef.current = newState
            dispatch({
              type: SET_FOLDERS_STATES,
              payload: newState
            })
            dispatch({
              type: SET_ALL_RESOURCES_WERE_LOADED,
              payload: validExpandedFolders.length === keys(foldersList).length
            })
            if (isFunction(filterTemplates)) {
              filterTemplates()
            } else if (shouldActivateInactiveFiltering) {
              filterByActiveResource(false, currentState)
            }
          } catch (error) {
            keys(foldersList).forEach((id) => {
              currentState[id] = {
                ...currentState[id],
                isLoading: false
              }
            })
            dispatch({
              type: SET_FOLDERS_STATES,
              payload: cloneDeep(currentState)
            })
          }
        } else {
          dispatch({
            type: SET_FOLDERS_STATES,
            payload: cloneDeep(foldersList)
          })
        }
      },
      [
        filterByActiveResource,
        getCurrentStoredExpandedeFolders,
        getStatePagination,
        resourceFetchCallback,
        resourceName,
        shouldActivateInactiveFiltering
      ]
    )

    const fetchFoldersList = useCallback(
      async (shouldShowAllFolders = false, filterTemplates = null) => {
        dispatch({
          type: SET_LOADING_FOLDERS,
          payload: true
        })
        try {
          const listFolders = await folderListCallback(
            getFolderListCallbackParams({ shouldShowAllFolders })
          )
          dispatch({
            type: SET_FOLDERS,
            payload: listFolders?.map(({ attributes: { name, id } }) => ({
              name,
              id
            }))
          })
          const currentExpandedFolders =
            getCurrentStoredExpandedeFolders() ?? []
          const foldersList = listFolders?.reduce(
            (acc, { attributes: { id } }) => {
              acc[id] = {
                isLoading: true,
                isOpened: currentExpandedFolders.includes(id),
                [resourceName]: []
              }

              return acc
            },
            {}
          )
          storedResourcesRef.current = foldersList
          fetchExpandedFoldersResources(
            foldersList,
            filterTemplates,
            shouldShowAllFolders
              ? {
                  [`all_${resourceName}`]: shouldShowAllFolders
                }
              : {}
          )
        } catch (error) {
          //
        } finally {
          dispatch({
            type: SET_LOADING_FOLDERS,
            payload: false
          })
        }
      },

      [
        folderListCallback,
        getFolderListCallbackParams,
        getCurrentStoredExpandedeFolders,
        fetchExpandedFoldersResources,
        resourceName
      ]
    )

    useEffect(() => {
      if (isTest && !fetchFoldersRef.current) {
        fetchFoldersRef.current = true
        fetchFoldersList()
      } else if (isDataRetrieved && !fetchFoldersRef.current) {
        fetchFoldersRef.current = true
        fetchFoldersList()
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDataRetrieved, isTest])

    return (
      <>
        {isLoadingFolders && <LoadingTreeSkeleton />}
        {!isLoadingFolders && foldersStates && (
          <Stack>
            <StickyActions
              shouldRenderStickyActions={shouldRenderStickyActions}
              isLoadingFolders={isLoadingFolders}
              isLoadingAllResources={isLoadingAllResources}
              shouldDisplayAllResources={shouldDisplayAllResources}
              handleActiveFilter={handleActiveFilter}
              ActionsAdornment={ActionsAdornment}
              handleFetchAllResources={handleFetchAllResources}
              shouldDisplayAllLoadedFoldersButton={
                shouldDisplayAllLoadedFoldersButton
              }
              handleDisplayLoadedFolders={handleDisplayLoadedFolders}
              pageName={pageName}
            />
            <PageHeader
              title={title}
              Actions={
                <Flex
                  my={isMobile ? '20px' : '0px'}
                  w="100%"
                  align={isMobile ? 'flex-start' : 'center'}
                  justify="flex-end"
                  flexWrap="wrap"
                >
                  {ActionsAdornment && ActionsAdornment}
                  {displayAllFoldersButton && (
                    <AllFoldersButton
                      isLoading={isLoadingAllResources}
                      loadedLabel="All folders"
                      label="Open all folders"
                      onClick={handleFetchAllResources}
                      icon="folder_copy"
                      isOutlinedIcon
                      ml={isMobile && shouldRenderStickyActions ? 0 : 4}
                    />
                  )}
                  {shouldDisplayAllLoadedFoldersButton && (
                    <AllFoldersButton
                      loadedLabel="All loaded folders"
                      label="Open all loaded folders"
                      onClick={handleDisplayLoadedFolders}
                      icon="drive_folder_upload"
                      ml={isMobile && shouldRenderStickyActions ? 0 : 4}
                      mt={
                        shouldWrapButtons && !shouldRenderStickyActions ? 4 : 0
                      }
                    />
                  )}
                </Flex>
              }
            />
            <Stack>
              <>
                <Flex
                  my={3}
                  w="100%"
                  justify={
                    isMobile
                      ? 'flex-start'
                      : !displayHelperSubtitles
                      ? 'flex-end'
                      : 'space-between'
                  }
                  flexDirection={isMobile ? 'column' : 'row'}
                  align="center"
                >
                  {displayHelperSubtitles && (
                    <Box
                      my={isMobile ? 4 : 0}
                      p={4}
                      shadow="md"
                      display="flex"
                      alignItems="center"
                      data-testid="folders-tree-subtitles"
                    >
                      <Flex align="center">
                        <Stack
                          p={2}
                          border="1px solid #dedede"
                          borderRadius="2px"
                        >
                          <MaterialIcon icon='folder_off' />
                          <MaterialIcon icon='folder_open' styles={{ marginTop: '8px' }}/>
                        </Stack>
                        <Stack ml={2}>
                          <Text size="xs">Folder was not loaded yet</Text>
                          <Text size="xs">Folder is empty</Text>
                        </Stack>
                      </Flex>
                      <Flex ml={4} align="center">
                        <Stack
                          p={2}
                          border="1px solid #dedede"
                          borderRadius="2px"
                        >
                          <MaterialIcon icon='folder_off' />
                          <MaterialIcon icon='folder_open' styles={{ marginTop: '8px' }}/>
                        </Stack>
                        <Text ml={2} size="xs">
                          Folder loaded
                        </Text>
                      </Flex>
                    </Box>
                  )}
                  <Flex>
                    <Flex
                      direction={isMobile ? 'column' : 'row'}
                      align="center"
                    >
                      <ShowAllCheckbox
                        isDisabled={isLoadingFolders || isLoadingAllResources}
                        isChecked={shouldDisplayAllResources}
                        onChange={({ target: { checked } }) =>
                          handleActiveFilter(checked)
                        }
                        alignSelf={isMobile ? 'center' : 'flex-start'}
                        mb={isMobile ? 4 : 0}
                        pageName={pageName}
                      />
                      <Box ml={Autocomplete ? 4 : 0}>
                        {Autocomplete && Autocomplete}
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
                <Divider my="4" />
              </>
              {folders.map(({ name, id }, index) => {
                const key = `${id}-${index}-${uuid()}`
                return (
                  <Box
                    data-id={`folder-${id}`}
                    data-testid={`folder-${id}`}
                    aria-hidden={!foldersStates[id].isOpened}
                    key={key}
                    shadow="md"
                    border="1px"
                    borderColor={
                      foldersStates[id].isOpened ? 'blue.400' : 'transparent'
                    }
                    borderRadius="2px"
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      onClick={() => handleFolderOpen(id)}
                      p="4"
                      cursor="pointer"
                      w="100%"
                    >
                      <Flex
                        align="center"
                        boxSizing="border-box"
                        mr={2}
                        maxW={
                          pageName.match('documents')
                            ? 'calc(100% - 132px)'
                            : 'calc(100% - 30px)'
                        }
                      >
                        <FolderIcon
                          foldersStates={foldersStates}
                          id={id}
                          resourceName={resourceName}
                        />
                        <Heading
                          ml={2}
                          size="sm"
                          data-testid="folders-tree-heading"
                        >
                          {name}
                        </Heading>
                      </Flex>
                      <RightAdornment
                        foldersStates={foldersStates}
                        id={id}
                        name={name}
                        getFolderAdornment={getFolderAdornment}
                      />
                    </Flex>
                    {foldersStates[id].isOpened && (
                      <Stack>
                        <Divider />
                        <FolderContent
                          foldersStates={foldersStates}
                          id={id}
                          resourceName={resourceName}
                          getResources={getResources}
                          otherAttributes={adornmentOtherAttributes}
                          shinyResourceId={shinyResourceId}
                        />
                        {foldersStates[id].hasLoadMore && (
                          <Box w="100%" p={4}>
                            <Button
                              isLoading={foldersStates[id].isLoading}
                              w="100%"
                              colorScheme="blue"
                              onClick={() => fetchFoldersResources(id)}
                            >
                              Load More
                            </Button>
                          </Box>
                        )}
                      </Stack>
                    )}
                  </Box>
                )
              })}
            </Stack>
          </Stack>
        )}
      </>
    )
  }
)

FoldersTree.displayName = 'FoldersTree'

FoldersTree.defaultProps = {
  Autocomplete: null,
  ActionsAdornment: null,
  title: 'Folders',
  getFolderAdornment: () => null,
  pageName: '',
  adornmentOtherAttributes: [],
  getFolderListCallbackParams: () => ({}),
  displayHelperSubtitles: false,
  displayAllFoldersButton: false,
  displayLoadAllOpenedFoldersButton: false,
  isTest: false,
  itemsPerPage: 15,
  shouldActivateInactiveFiltering: false
}

FoldersTree.propTypes = {
  resourceName: PropTypes.string.isRequired,
  displayHelperSubtitles: PropTypes.bool,
  displayAllFoldersButton: PropTypes.bool,
  displayLoadAllOpenedFoldersButtondisplayAllFoldersButton: PropTypes.bool,
  resourceFetchCallback: PropTypes.func.isRequired,
  folderListCallback: PropTypes.func.isRequired,
  Autocomplete: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.func,
    PropTypes.object
  ]),
  ActionsAdornment: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.func,
    PropTypes.object
  ]),
  title: PropTypes.string,
  getFolderAdornment: PropTypes.func,
  expandedResourceKey: PropTypes.oneOf([
    'expanded_documents_folders',
    'expanded_templates_folders',
    'expanded_libraries_folders',
    'expanded_checklists_folders'
  ]).isRequired,
  pageName: PropTypes.string,
  adornmentOtherAttributes: PropTypes.array,
  getFolderListCallbackParams: PropTypes.func,
  isTest: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  shouldActivateInactiveFiltering: PropTypes.bool
}

export default memo(FoldersTree)
