import React, { useMemo } from 'react'
import MaterialIcon from '../../MaterialIcon'

const FolderIcon = ({ foldersStates, id, resourceName }) => {
  const alreadyLoaded = useMemo(() => {
    return !!foldersStates?.[id]?.[resourceName]?.length
  }, [foldersStates, id, resourceName])
  if (foldersStates[id].isOpened) {
    return <MaterialIcon icon={alreadyLoaded ? 'folder_copy' : 'folder_open'} />
  }
  return (
    <MaterialIcon
      type={!alreadyLoaded ? 'material-icons-outlined' : 'material-icons'}
      icon="folder"
    />
  )
}

FolderIcon.defaultProps = {
  foldersStates: {},
  id: null,
  resourceName: ''
}

export default FolderIcon
