import { Checkbox, Radio } from '@chakra-ui/react'
import React from 'react'
import { useRecursiveStructureContext } from '../../../contexts/RecursiveStructureSelection'

const SelectionComponent = (props) => {
  const { id } = props
  const { onSelect, initialSelectedNodes, allowSelection, selectionMode } =
    useRecursiveStructureContext()
  if (!allowSelection(props)) {
    return null
  }

  return (
    <>
      {selectionMode === 'multiple' && (
        <Checkbox
          mb={0}
          mt="6px"
          alignSelf="flex-start"
          defaultChecked={initialSelectedNodes?.includes(id)}
          onChange={() => onSelect(props)}
        />
      )}
      {selectionMode === 'single' && (
        <Radio name="single-selection" value={`${id}`} />
      )}
    </>
  )
}

export default SelectionComponent
