import { Search2Icon } from '@chakra-ui/icons'
import { Button, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import Autocomplete from 'components/Autocomplete'
import React from 'react'
import { Link } from 'react-router-dom'
import FoldersTree from '../../components/FoldersTree'
import { getRouteUrl } from '../../helpers'
import OlingaAPI from '../../services/OlingaAPI'
import DocumentListItem from './DocumentListItem'
import useDocuments from './useDocuments'
import MaterialIcon from '../../components/MaterialIcon'

const Documents = () => {
  const {
    documentListRef,
    getFolderListCallbackParams,
    pageTitle,
    singularTitle,
    getAutocompleteCallback,
    onAutocompleteOpen,
    onClose,
    isOpen,
    handleAutocompleteSelect,
  } = useDocuments()

  return (
    <FoldersTree
      resourceFetchCallback={OlingaAPI.formFolderDocumentsList}
      folderListCallback={OlingaAPI.folderTreeList}
      getFolderListCallbackParams={getFolderListCallbackParams}
      resourceName="documents"
      pageName={pageTitle}
      getResources={(documents) => (
        <DocumentListItem documents={documents} pageTitle={singularTitle} />
      )}
      ref={documentListRef}
      title={pageTitle}
      expandedResourceKey="expanded_documents_folders"
      adornmentOtherAttributes={['attributes.libraryId']}
      Autocomplete={
        <Popover isOpen={isOpen} onOpen={onAutocompleteOpen} onClose={onClose}>
          <PopoverTrigger>
            <Button
              leftIcon={<Search2Icon />}
              variant="ghost"
              colorScheme="orange"
              size="sm"
              aria-label={`Search ${pageTitle}`}
            >
              Search {pageTitle}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody p={4}>
              <Autocomplete
                asyncCallback={getAutocompleteCallback}
                placeholder={`Search ${pageTitle}`}
                attributeName="name"
                onSelect={(result) => handleAutocompleteSelect(result)}
                minWidth="100%"
                getNestedAttribute="attributes"
                hasFixedResults
                shouldAutofocus
                inputId="templates-autocomplete-input"
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      }
      ActionsAdornment={
        <Link to={getRouteUrl('documentsLibrary')}>
          <Button
            colorScheme="orange"
            leftIcon={<MaterialIcon icon='local_library' />}
          >
            Library
          </Button>
        </Link>
      }
    />
  )
}

export default Documents
