import { Divider, Flex, Heading, Stack, Tag, Text } from '@chakra-ui/react'
import EmptyState from 'components/EmptyState'
import InfoTip from 'components/Infotip'
import { isEmpty } from 'lodash'
import React from 'react'

const AssetAnswer = ({ name, label, fields, children }) => {
  return (
    <>
      <Stack
        borderRadius="md"
        borderWidth={1}
        borderColor="gray.200"
        bg="white"
        p={4}
        w="100%"
      >
        <Flex w="100%" justify="space-between" align="center">
          <Flex gap={2}>
            <Heading size="sm">{name}</Heading>
            <InfoTip label={label} placement="bottom" />
          </Flex>
          {children && children}
        </Flex>
        <Divider />
        {isEmpty(fields) ? (
          <EmptyState text="This asset has no fields" />
        ) : (
          fields.map(({ label, value }, index) => (
            <Flex w="100%" key={index} justify="space-between" align="center">
              <Flex gap={4}>
                <Text fontWeight="bold" size="sm">
                  {label}:
                </Text>
                {value?.match(';') ? (
                  <Flex gap={2} align="center">
                    {value?.split(';')?.map((item, index) => (
                      <Tag colorScheme="blue" size="sm" key={index}>
                        {item}
                      </Tag>
                    ))}
                  </Flex>
                ) : value ? (
                  value
                ) : (
                  <Tag colorScheme="gray" size="sm">
                    Empty
                  </Tag>
                )}
              </Flex>
            </Flex>
          ))
        )}
      </Stack>
    </>
  )
}

export default AssetAnswer
