import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import {
  calculatedFieldsEnumTypes,
  templateStructureTypes
} from '../../pages/TemplateSettings/TemplateSettingsTabs/components/CalculatedFields/constants'
import ListBuilder from '../ListBuilder'
import EditableOperationCard from '../OperationsBoard/SetValueAdapter/EditableOperationCard'
import useOperationsList from './hooks'

const OperationsList = ({
  operations,
  structure,
  attributeKey,
  setValue,
  watch,
  id
}) => {
  const {
    items,
    onChange,
    alertMessage,
    onAddItem,
    onRemoveItem,
    setAddOperatorsButtonRef,
    setAddOperationsButtonRef,
    setEditOperationsButtonRef,
    setEditionAlertMessage
  } = useOperationsList({ setValue, attributeKey, watch, id })

  return (
    <ListBuilder
      list={items}
      resourceName="Operation"
      ItemComponent={EditableOperationCard}
      onAddItem={onAddItem}
      onChange={onChange}
      onRemoveItem={onRemoveItem}
      customRemoveItemAction={(index, _, fallback) => fallback(index)}
      setAddItemButtonRef={setAddOperationsButtonRef}
      idAttribute="_id"
      isDisabled={!isEmpty(items)}
      allowDelete={({ id }) => !id}
      allowDrag={() => false}
      itemProps={{
        structure,
        operations,
        setValue,
        attributeKey,
        watch,
        id,
        allowSetOperator: true,
        setEditionAlertMessage,
        alertMessage,
        setAddOperatorsButtonRef,
        setEditOperationsButtonRef
      }}
    />
  )
}

OperationsList.propTypes = {
  operations: calculatedFieldsEnumTypes,
  structure: templateStructureTypes,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentKey: PropTypes.string,
  setValue: PropTypes.func,
  watch: PropTypes.func
}

export default OperationsList
