import { EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Heading,
  HStack,
  IconButton,
  Tag,
  Text,
  Tooltip,
  useMediaQuery
} from '@chakra-ui/react'
import { capitalize } from 'lodash'
import React, { forwardRef, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import InfoTip from '../../../../../components/Infotip'
import { chartTypeContainerStyles, ChartTypeIcon } from './styles'
import MaterialIcon from '../../../../../components/MaterialIcon'

const Icons = {
  bar: <MaterialIcon icon='bar_chart' />,
  line: <MaterialIcon icon='show_chart' />,
  table: <MaterialIcon icon='table_chart' />
}

const SummaryView = forwardRef(
  (
    {
      report,
      headerQuestions,
      bodyQuestions,
      fieldKey,
      setFormFieldKey,
      index,
      isDisabled
    },
    ref
  ) => {
    const { watch } = useFormContext()
    const [relatedQuestions, context] = watch(
      [`${fieldKey}.related_questions`, `${fieldKey}.context`],
      []
    )
    const { mainQuestion, chartTypes } = useMemo(() => {
      report.main_question ||= { id: null, label: '' }
      report.has_table ||= false
      report.chart_type ||= []
      report.related_questions ||= []

      return {
        mainQuestion: [...headerQuestions, ...bodyQuestions].find(
          ({ data: { id } }) => id === report.main_question?.id
        ),
        chartTypes: [report.chart_type],
        _key: report._key
      }
    }, [report, bodyQuestions, headerQuestions])

    const [isMobile] = useMediaQuery('(max-width: 799px)')

    const onEdit = () => {
      const { open } = ref.current
      setFormFieldKey({ key: fieldKey, index })
      open()
    }

    if (!report) {
      return <Tag colorScheme="gray">Empty report</Tag>
    }
    return (
      <HStack px={4} spacing={2} align="center" justify="flex-start">
        <Flex
          justify={isMobile ? 'flex-start' : 'space-between'}
          w="100%"
          flexWrap="wrap"
          align={isMobile ? 'flex-start' : 'center'}
          flexDirection={isMobile ? 'column' : 'row'}
          gap={2}
        >
          <Flex align="center" flex={1} gap={2}>
            <Flex
              align={isMobile ? 'flex-start' : 'center'}
              flexDirection={isMobile ? 'column' : 'row'}
              flex={1}
              justifyContent="space-between"
            >
              {context?.label && (
                <Box>
                  <Text fontSize="xs">Context: </Text>
                  <Flex align="center" gap={2}>
                    <Heading size="sm">{context?.label}</Heading>
                    {mainQuestion?.label && (
                      <InfoTip label={mainQuestion?.label} />
                    )}
                  </Flex>
                </Box>
              )}
              {!!relatedQuestions?.length && (
                <Tag colorScheme="blue">
                  {relatedQuestions?.length} related{' '}
                  {relatedQuestions?.length === 1 ? 'question' : 'questions'}
                </Tag>
              )}
              {!relatedQuestions?.length && (
                <Tag colorScheme="gray">No related questions</Tag>
              )}
            </Flex>
          </Flex>
          <Flex align="center" gap={2} {...chartTypeContainerStyles} py={0}>
            <Text>Chart type: </Text>
            {chartTypes.map((type, index) => (
              <Tooltip key={index} hasArrow label={capitalize(type)}>
                <ChartTypeIcon type={type}>{Icons[type]}</ChartTypeIcon>
              </Tooltip>
            ))}
          </Flex>
        </Flex>
        {!isDisabled && (
          <IconButton
            icon={<EditIcon />}
            aria-label="Edit this report"
            size="xs"
            colorScheme="blue"
            borderRadius={2}
            onClick={onEdit}
          />
        )}
      </HStack>
    )
  }
)

SummaryView.defaultProps = {
  report: {}
}

SummaryView.displayName = 'SummaryView'

export default SummaryView
