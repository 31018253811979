import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import InfoTip from '../../components/Infotip'

const FormField = ({ label, children, isRequired, Action, infoLabel }) => {
  const getLabel = (label, isRequired) => {
    return (
      <Flex
        minH={label ? "32px" : "0px"}
        justify="space-between"
        align="center"
        data-testid="form-field"
      >
        <Flex>
          {label && <Text size="sm">{label}</Text>}
          {isRequired && (
            <Text color="red.500" ml={1} data-testid="form-field-required">
              *
            </Text>
          )}
          {infoLabel && <Box ml={2}><InfoTip label={infoLabel} /></Box>}
        </Flex>
        {Action && Action}
      </Flex>
    )
  }

  const getFormField = (Component, label, isRequired = true) => {
    return (
      <Stack w="100%">
        {getLabel(label, isRequired)}
        {children}
      </Stack>
    )
  }

  return getFormField(children, label, isRequired)
}

FormField.defaultProps = {
  isRequired: true,
  Action: null,
  infoLabel: ''
}

FormField.propTypes = {
  label: PropTypes.string,
  infoLabel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isRequired: PropTypes.bool,
  Action: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}

export default FormField
