import { Input } from '@chakra-ui/react'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const DomainItem = ({ index, fieldProps, fieldKey }) => {
  const { register, control } = useFormContext()

  const value = useWatch({
    name: `${fieldKey}.${index}`,
    control,
    defaultValue: fieldProps.value[index] || null
  })

  return (
    <Input
      placeholder={fieldProps.label}
      {...register(`${fieldKey}.${index}`, { required: fieldProps.required })}
      value={value}
    />
  )
}

export default DomainItem
