/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@chakra-ui/react'
import { Select as MultiSelect } from 'chakra-react-select'
import { map, sortBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { getHasRolesByCategory } from '../../../helpers'
import FormField from '../../FormField'

const RolesSelector = ({
  setValue,
  roles,
  rolesData,
  getValues,
  label,
  disabledRolesCategories,
  isEmptyLabel
}) => {
  useEffect(() => {
    setHasRolesByCategory(getHasRolesByCategory(roles))
  }, [roles, getHasRolesByCategory])

  const [hasRolesByCategory, setHasRolesByCategory] = useState({})

  const updateRolesValue = (values) => {
    const rolesValue = []

    values.forEach((v) => {
      rolesValue.push({ name: v.name, id: v.id, label: v.label, value: v.id, description: v.description })
    })

    setHasRolesByCategory(getHasRolesByCategory(rolesValue))
    setValue('roles', rolesValue)
  }

  const normalizedRolesValue = (rolesArray, disableValue) => {
    return map(rolesArray, (role) => ({
      ...role,
      value: role.id,
      isDisabled: disableValue
    }))
  }

  const groupedRoles = useMemo(() => {
    const {
      hasTemplateSelected,
      hasAssetTemplateSelected,
      hasAssetSelected,
      hasFolderSelected,
      hasFormSelected,
      hasGroupSelected,
      hasAlertSelected,
      hasDocumentSelected,
      hasDashboardSelected,
      hasActionSelected,
      hasSummaryReportSelected,
      hasScheduledReportSelected,
      hasChecklistSelected,
      hasScheduledFormsSelected,
      hasOnboardStaffSelected,
    } = hasRolesByCategory

    return sortBy(
      [
        {
          label: 'Templates',
          options: normalizedRolesValue(
            rolesData.template,
            hasTemplateSelected || disabledRolesCategories.hasTemplateSelected
          )
        },
        {
          label: 'Assets',
          options: normalizedRolesValue(
            (rolesData?.asset ?? []).filter((role) =>
              [
                'asset_modify',
                'asset_view',
                'asset_modify_all',
                'asset_view_all'
              ].includes(role?.name)
            ),
            hasAssetSelected || disabledRolesCategories.hasAssetSelected
          )
        },
        {
          label: 'Asset Templates',
          options: normalizedRolesValue(
            (rolesData?.asset ?? []).filter((role) =>
              role?.name?.includes('asset_template')
            ),
            hasAssetTemplateSelected ||
              disabledRolesCategories.hasAssetTemplateSelected
          )
        },
        {
          label: 'Forms',
          options: normalizedRolesValue(
            rolesData.form,
            hasFormSelected || disabledRolesCategories.hasFormSelected
          )
        },
        {
          label: 'Groups',
          options: normalizedRolesValue(
            rolesData.group,
            hasGroupSelected || disabledRolesCategories.hasGroupSelected
          )
        },
        {
          label: 'Folders',
          options: normalizedRolesValue(
            rolesData.folder,
            hasFolderSelected || disabledRolesCategories.hasFolderSelected
          )
        },
        {
          label: 'Alerts',
          options: normalizedRolesValue(
            rolesData.alert,
            hasAlertSelected || disabledRolesCategories.hasAlertSelected
          )
        },
        {
          label: 'Documents',
          options: normalizedRolesValue(
            rolesData.document,
            hasDocumentSelected || disabledRolesCategories.hasDocumentSelected
          )
        },
        {
          label: 'Dashboards',
          options: normalizedRolesValue(
            rolesData.dashboard,
            hasDashboardSelected || disabledRolesCategories.hasDashboardSelected
          )
        },
        {
          label: 'Actions',
          options: normalizedRolesValue(
            rolesData.action,
            hasActionSelected || disabledRolesCategories.hasActionSelected
          )
        },
        {
          label: 'Scheduled Reports',
          options: normalizedRolesValue(
            rolesData.scheduled_report,
            hasScheduledReportSelected ||
              disabledRolesCategories.hasScheduledReportSelected
          )
        },
        {
          label: 'Summary Reports',
          options: normalizedRolesValue(
            rolesData.summary_report,
            hasSummaryReportSelected ||
              disabledRolesCategories.hasSummaryReportSelected
          )
        },
        {
          label: 'Checklists',
          options: normalizedRolesValue(
            rolesData.checklist,
            hasChecklistSelected || disabledRolesCategories.hasChecklistSelected
          )
        },
        {
          label: 'Scheduled Forms',
          options: normalizedRolesValue(
            rolesData.scheduled,
            hasScheduledFormsSelected ||
              disabledRolesCategories.hasScheduledFormsSelected
          )
        },
        {
          label: 'Onboard Staff',
          options: normalizedRolesValue(
            rolesData.onboard,
            hasOnboardStaffSelected ||
              disabledRolesCategories.hasOnboardStaffSelected
          )
        },
      ],
      ['label']
    )
  }, [hasRolesByCategory, rolesData, roles, disabledRolesCategories])

  return (
    <FormField label={isEmptyLabel ? '' : label} isRequired={false}>
      <Box minW='300px'>
        <MultiSelect
          isMulti
          placeholder="Select roles"
          size="sm"
          options={groupedRoles}
          defaultValue={getValues('roles')}
          onChange={updateRolesValue}
          hideSelectedOptions={false}
          key={getValues('roles')}
        />
      </Box>
    </FormField>
  )
}

RolesSelector.defaultProps = {
  label: 'Roles',
  disabledRolesCategories: {}
}

export default RolesSelector
