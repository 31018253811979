import { capitalize } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getValidDate } from '../../../../helpers'
import OlingaAPI from '../../../../services/OlingaAPI'

const useFormDetails = ({ templateId, formId }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [formDetails, setFormDetails] = useState({})

  const { screens } = useSelector(({ globalReducer }) => globalReducer)

  const fetchFormDetails = useCallback(async () => {
    setIsLoading(true)
    try {
      const {
        data: { attributes }
      } = await OlingaAPI.formDetail(templateId, formId)

      setFormDetails({
        ...attributes,
        date: getValidDate(attributes.dateTime)
      })
    } catch (error) {
      //
    } finally {
      setIsLoading(false)
    }
  }, [templateId, formId])

  const { subject } = {
    subject: capitalize(
      screens?.forms?.business?.custom_subject || screens?.forms?.subject
    )
  }

  useEffect(() => {
    fetchFormDetails()
  }, [fetchFormDetails])

  return {
    isLoading,
    formDetails,
    fetchFormDetails,
    subject
  }
}

export default useFormDetails
