/* eslint-disable react/display-name */
import { Button, VisuallyHidden } from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import Dialog from '../../../../components/AlertDialog'
import DialogBody from './DialogBody'
import useDeleteFormElement from './useDeleteFormElement'

const DeleteElementBody = forwardRef(
  ({ type, handleClose, getValues, context }, ref) => {
    const {
      handleDelete,
      isLoading,
      logicRemoveDecision,
      shouldRemoveAllChildren,
      onClose,
      setLogicRemoveDecision,
      setShouldRemoveAllChildren
    } = useDeleteFormElement({
      type,
      handleClose,
      getValues,
      context
    })

    return (
      <Dialog
        body={
          <DialogBody
            type={type}
            setShouldRemoveAllChildren={setShouldRemoveAllChildren}
            shouldRemoveAllChildren={shouldRemoveAllChildren}
            logicRemoveDecision={logicRemoveDecision}
            setLogicRemoveDecision={setLogicRemoveDecision}
          />
        }
        header={`Delete the ${type}?`}
        handleClose={onClose}
        onContinue={handleDelete}
        continueLabel={'Yes, delete it!'}
        isLoading={isLoading}
        isSubmittedDisabled={type === 'logic' && !logicRemoveDecision}
      >
        <VisuallyHidden ref={ref}>
          <Button aria-label="Open delete question dialog">Open</Button>
        </VisuallyHidden>
      </Dialog>
    )
  }
)

export default DeleteElementBody
