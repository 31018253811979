import { EditIcon } from '@chakra-ui/icons'
import {
  Button,
  Divider,
  Flex,
  IconButton,
  Stack,
  Text
} from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import React from 'react'
import EmptyState from '../../../../components/EmptyState'
import BoardOperations from '../../../../components/OperationsBoard/BoardOperations'
import useShowOperations from './hooks'
import MaterialIcon from '../../../../components/MaterialIcon'

const ShowOperations = ({ id, currentKey, watch }) => {
  const { items, onCreateOperation, onEditOperation } = useShowOperations({
    id,
    currentKey,
    watch
  })

  if (!id) {
    return (
      <Text>
        <MaterialIcon icon='info' /> Calculations will only be
        available after you save this form
      </Text>
    )
  }

  if (isEmpty(items)) {
    return (
      <EmptyState
        text="There are no operations yet"
        Action={
          <Button
            colorScheme="blue"
            leftIcon={<MaterialIcon type='material-icons-outlined' icon='calculate' />}
            onClick={onCreateOperation}
          >
            Create Operations
          </Button>
        }
      />
    )
  }

  return (
    <Stack gap={4}>
      {items.map(({ expression, id }, index) => (
        <Stack key={index}>
          <Flex gap={4} align="center" flexDir={['column', 'row']}>
            <Flex
              key={index}
              align="center"
              gap={4}
              justify="flex-start"
              w="100%"
              flexDir={['column', 'row']}
              flex={1}
            >
              <BoardOperations operations={expression} />
            </Flex>
            <IconButton
              icon={<EditIcon />}
              aria-label="Edit operation"
              size="xs"
              borderRadius={2}
              colorScheme="blue"
              onClick={() => onEditOperation(id)}
            />
          </Flex>
          <Divider />
        </Stack>
      ))}
    </Stack>
  )
}

export default ShowOperations
