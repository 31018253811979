import { Tag, useMediaQuery } from '@chakra-ui/react'
import { capitalize, get, isEmpty, pick, trim } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import {
  usePageProperties,
  useTableColumns,
  useTableFilter
} from '../../../../hooks'
import OlingaAPI from '../../../../services/OlingaAPI'

const useChecklistsTasks = (data) => {
  const { id, name, frequencies } = useMemo(() => {
    if (!isEmpty(data.checklist) && !isEmpty(data.enumerators)) {
      const checklist = data.checklist
      const enumerators = data.enumerators
      return {
        id: get(checklist, 'attributes.id'),
        name: get(checklist, 'attributes.name'),
        frequencies: [
          { label: 'All', value: '' },
          ...get(enumerators, 'frequencies')
            .map((frequency) => pick(frequency, ['label', 'value']))
            .filter(({ value }) => value !== 'biannual')
        ]
      }
    }
    return {
      id: null,
      name: '',
      frequencies: [{ label: 'All', value: '' }]
    }
  }, [data])

  const { singularTitle, pageTitle } = usePageProperties({
    pageName: 'checklists'
  })

  const tableKey = `checklists_answers_persisted_columns.${id}`
  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Completed by',
        accessor: 'fullName'
      },
      {
        Header: 'Date',
        accessor: 'dateTime'
      },
      {
        Header: 'Task',
        accessor: 'task'
      },
      {
        Header: 'Latitude',
        accessor: 'latitude'
      },
      {
        Header: 'Longitude',
        accessor: 'longitude'
      },
      {
        Header: 'Frequency',
        accessor: 'frequency'
      },
      {
        Header: 'Status',
        accessor: 'status'
      }
    ],
    tableKey,
    true,
    null,
    (row) => {
      const {
        values: { id, task }
      } = row
      return { id, task }
    },
    ['latitude', 'longitude', 'id']
  )

  const fieldsExtractor = useCallback((data) => {
    return data.map(
      ({
        attributes: {
          id,
          user,
          checklistItemTitle,
          checklistItemId,
          dateTime,
          latitude,
          longitude,
          frequency,
          status
        }
      }) => {
        return {
          id,
          checklistItemId,
          task: checklistItemTitle,
          dateTime,
          latitude,
          longitude,
          frequency: <Tag>{capitalize(frequency)}</Tag>,
          status: status ? (
            <Tag colorScheme="blue">{capitalize(status)}</Tag>
          ) : (
            <Tag>{status}</Tag>
          ),
          email: user?.email ?? <Tag>Empty</Tag>,
          fullName: user?.fullName ?? <Tag>Empty</Tag>
        }
      }
    )
  }, [])

  const {
    refetch,
    isLoading,
    updateParams,
    fetchParams,
    tableData,
    pagination
  } = useTableFilter({
    id,
    fetchCallback: OlingaAPI.getChecklistsAnswers,
    fieldsExtractor,
    initialParams: {
      page: 1,
      per_page: 15,
      frequency: { label: 'All', value: '' }
    }
  })

  const [isMobile] = useMediaQuery('(max-width: 799px)')

  const onSearchRangeChange = (dates = []) => {
    const [start, end] = dates
    if (!start && !end) {
      return refetch(['start_date', 'end_date'])
    }
    if (start && end) {
      const formattedStart = start || null
      const formattedEnd = end || null
      const params = {
        start_date: formattedStart,
        end_date: formattedEnd
      }

      updateParams(params)
    }
  }

  const onTextChange = useCallback(
    (updatedText) => {
      if (!updatedText || !trim(updatedText)?.length) {
        return refetch(['name'])
      }
      updateParams({ name: updatedText }, updatedText?.length >= 3)
    },
    [refetch, updateParams]
  )

  const onFrequencyChange = useCallback(
    (updatedFrequency) => {
      if (!updatedFrequency) {
        return refetch(['frequency'])
      }
      updateParams({ frequency: updatedFrequency })
    },
    [refetch, updateParams]
  )

  const onChangePage = (params) => {
    updateParams(params)
  }

  return {
    onChangePage,
    onFrequencyChange,
    onTextChange,
    onSearchRangeChange,
    isMobile,
    id,
    name,
    frequencies,
    isLoading,
    fetchParams,
    tableData,
    pagination,
    columns,
    hiddenColumns,
    tableKey,
    pageTitle,
    singularTitle
  }
}

export default useChecklistsTasks
