import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import useCookie from 'react-use-cookie'
import { getDashboardUrlParams, getRouteUrl } from '../../../../helpers'
import { useLocalStorage } from '../../../../hooks'
import Notification from '../../../../services/Notification'
import OlingaAPI from '../../../../services/OlingaAPI'

const defaultPersistedUser = 'null,null,false'
const defaultCookiesSettings = {
  days: 365,
  SameSite: 'Strict',
  Secure: true
}

const useSignIn = ({ setCurrentPage, defaultPersonableType }) => {
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [persistedUser, setPersistedUser] = useCookie(
    '_olinga_session_user',
    defaultPersistedUser
  )
  const { setData } = useLocalStorage()

  const { email, profile, rememberMe } = useMemo(() => {
    if (persistedUser !== defaultPersistedUser) {
      const [email, profile, remember] = persistedUser.split(',')
      return { email, profile, rememberMe: remember === 'true' }
    }
    return { email: '', profile: '', rememberMe: false }
  }, [persistedUser])

  const { register, handleSubmit } = useForm({
    defaultValues: {
      remember_me: rememberMe,
      email,
      personable_type: profile
    }
  })

  const history = useHistory()

  const onError = (error) => {
    setErrors(error)
  }

  const onSubmit = async (values) => {
    setErrors([])
    setIsLoading(true)
    const params = {
      user: {
        email: values.email,
        password: values.password,
        personable_type: values.personable_type || defaultPersonableType,
        remember_me: values.remember_me ? 1 : 0
      },
      commit: 'Log in',
      authenticity_token: document
        ?.querySelector('meta[name="csrf-token"]')
        ?.getAttribute('content')
    }

    try {
      await OlingaAPI.signIn(params)
      if (values.remember_me) {
        setPersistedUser(
          `${values.email},${values.personable_type},${values.remember_me}`,
          defaultCookiesSettings
        )
      } else {
        setPersistedUser(defaultPersistedUser, defaultCookiesSettings)
      }
      setData(
        {
          personable_type: params.user.personable_type
        },
        'olinga_signed_user'
      )
      const { dashboardId, userType } = getDashboardUrlParams(values)
      history.push({
        pathname: getRouteUrl(dashboardId, { userType })
      })
      window.location.reload()
    } catch (error) {
      return Notification({
        icon: 'error',
        text:
          error?.response?.data?.message ||
          'Try again or click Forgot password to reset it.'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const onForgotPassword = () => {
    setCurrentPage('newPassword')
    window.history.pushState('', 'Olinga', '/users/password/new')
  }

  return {
    onForgotPassword,
    register,
    isLoading,
    errors,
    handleSubmit,
    onSubmit,
    onError,
    email
  }
}

export default useSignIn
