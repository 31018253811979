import { useMediaQuery } from '@chakra-ui/react'
import {
  concat,
  debounce,
  differenceBy,
  intersectionBy,
  isArray,
  isEmpty,
  keys,
  omit
} from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTemplateEditorContext } from '../../../../../contexts'

const useNotificationHandler = ({
  selfFormNotifyKey,
  notificationsUsersKey,
  deletedNotifiedUsersKey,
  deletedNotifiedUsers,
  initiallyNotifiedUsers,
  notifiedUsers,
  setValueMethod,
  watchValueMethod,
  subUsersList
}) => {
  const formContextAttributes = useFormContext()
  const templateEditorContextAttributes = useTemplateEditorContext()

  const contextList = !isEmpty(subUsersList)
    ? subUsersList
    : templateEditorContextAttributes?.subUsersList

  const [checkedUsers, setCheckedUsers] = useState({})
  const [userName, setUserName] = useState('')
  const [usersList, setUsersList] = useState(contextList)
  const [isMobile] = useMediaQuery('(max-width: 599px')

  const setValue = setValueMethod ?? formContextAttributes?.setValue
  const watch = watchValueMethod ?? formContextAttributes?.watch

  const isSelfNotifying = watch(selfFormNotifyKey)

  const onToggleSelfNotify = ({ target: { checked } }) => {
    setValue(selfFormNotifyKey, checked)
  }

  const customUsers = useMemo(() => {
    return (notifiedUsers ?? [])
      .filter(({ external_email }) => external_email)
      .map(({ external_email }) => ({
        label: external_email,
        value: external_email
      }))
  }, [notifiedUsers])

  const getSummaryList = useCallback(
    (list) => {
      return intersectionBy(
        (isArray(contextList) ? contextList : []).map((user) => ({
          ...user,
          user_id: Number(user.id)
        })),
        list,
        'user_id'
      )
    },
    [contextList]
  )

  const [summaryList, setSummaryList] = useState(getSummaryList(notifiedUsers))

  const getFormattedUserObject = (userObject) => {
    const notifiedUser = initiallyNotifiedUsers.find(
      (user) => user.user_id === Number(userObject.id)
    )
    if (notifiedUser) {
      return { ...notifiedUser, user_id: Number(userObject.id) }
    }
    return { user_id: Number(userObject.id) }
  }

  const getDelettedUserObject = (userObject) => {
    return omit({ ...userObject, _destroy: true }, 'user_id')
  }

  const handleCheckUser = (id, userObject) => {
    let clonedList = [...notifiedUsers]
    const removedItem = clonedList.find((user) => user.user_id === Number(id))
    let currentDeletedUsers = []
    if (deletedNotifiedUsers) {
      currentDeletedUsers = [...deletedNotifiedUsers]
    }
    if (removedItem) {
      clonedList = clonedList.filter((user) => user.user_id !== Number(id))
      if (keys(removedItem).includes('id')) {
        if (!currentDeletedUsers.find((user) => user.id === removedItem.id)) {
          currentDeletedUsers.push(getDelettedUserObject(removedItem))
        }
        setValue(deletedNotifiedUsersKey, currentDeletedUsers)
      }
    } else {
      clonedList.push(getFormattedUserObject(userObject))
      const persistedElement = getFormattedUserObject(userObject)
      if (persistedElement) {
        const filteredList = currentDeletedUsers.filter(
          (user) => user.id !== persistedElement.id
        )
        setValue(deletedNotifiedUsersKey, filteredList)
      }
    }
    setValue(notificationsUsersKey, clonedList)
  }

  const filterList = useCallback(
    (value) => {
      let filteredList = []
      if (value) {
        filteredList = contextList.filter(
          (user) => user.attributes.email.match(value) || user.id.match(value)
        )
        setUsersList(filteredList)
      } else {
        setUsersList(contextList)
      }
    },
    [contextList]
  )

  const resetList = () => {
    setUserName('')
  }

  const handleBatchSelection = () => {
    const selectedItems = [...notifiedUsers]
    const tempUsersList = usersList.map((user) => ({
      ...user,
      user_id: Number(user.id)
    }))
    if (usersList.length === selectedItems.length) {
      const notificatedUsersToDelete = intersectionBy(
        initiallyNotifiedUsers,
        tempUsersList,
        'user_id'
      )
      setValue(
        deletedNotifiedUsersKey,
        notificatedUsersToDelete.map((user) => ({ ...user, _destroy: true }))
      )
      setValue(notificationsUsersKey, [])
    } else {
      const unselectedItems = differenceBy(
        tempUsersList,
        selectedItems,
        'user_id'
      )
      const allUsers = concat(
        selectedItems,
        unselectedItems.map((item) => getFormattedUserObject(item))
      )
      setValue(notificationsUsersKey, allUsers)
      setValue(deletedNotifiedUsersKey, [])
    }
  }

  const onSelectCustomUsers = (users) => {
    const clonedList = [...(notifiedUsers ?? [])]
    const currentDestroyedUsers = [...(deletedNotifiedUsers ?? [])]
    const externalUsers = clonedList.filter(
      ({ external_email }) => external_email
    )
    const mappedUsers = users.map((u) => ({
      user_id: null,
      external_email: u.value
    }))
    const deletedUsers = differenceBy(
      externalUsers,
      mappedUsers,
      'external_email'
    ).map((user) => ({ ...user, _destroy: true }))
    const newUsers = differenceBy(mappedUsers, externalUsers, 'external_email')
    if (!isEmpty(deletedUsers)) {
      setValue(deletedNotifiedUsersKey, [
        ...currentDestroyedUsers,
        ...deletedUsers
      ])
      const filteredUsers = differenceBy(clonedList, deletedUsers)
      setValue(notificationsUsersKey, filteredUsers)
    }
    if (!isEmpty(newUsers)) {
      setValue(notificationsUsersKey, [...clonedList, ...newUsers])
    }
  }

  useEffect(() => {
    debounce(() => {
      filterList(userName)
    }, 200)()
  }, [filterList, userName])

  useEffect(() => {
    const usersChecked = notifiedUsers?.reduce((acc, user) => {
      acc[user.user_id] = true
      return acc
    }, {})
    setCheckedUsers(usersChecked)
    setSummaryList(getSummaryList(notifiedUsers))
  }, [getSummaryList, notifiedUsers])

  return {
    checkedUsers,
    onSelectCustomUsers,
    summaryList,
    handleBatchSelection,
    resetList,
    handleCheckUser,
    customUsers,
    isMobile,
    usersList,
    userName,
    setUserName,
    isSelfNotifying,
    onToggleSelfNotify
  }
}

export default useNotificationHandler
