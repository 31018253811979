import { Button } from '@chakra-ui/button'
import { Flex, Stack } from '@chakra-ui/layout'
import React from 'react'
import PasswordInput from '../../../components/Inputs/Password'
import useResetPassword from './useResetPassword'

const ResetPassword = ({ onSuccess, token }) => {
  const { handleSubmit, onSubmit, onError, isLoading, errors, register } =
    useResetPassword({ onSuccess, token })

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Stack gap={2}>
        <PasswordInput
          placeholder="Password"
          register={register}
          name="password"
          isRequired
          isInvalid={!!errors.password}
          isDisabled={isLoading}
          shouldShowLockIcon
          autoFocus
        />
        <PasswordInput
          placeholder="Password confirmation"
          register={register}
          name="password_confirmation"
          isRequired
          isInvalid={!!errors.password_confirmation}
          isDisabled={isLoading}
          shouldShowLockIcon
          autoFocus={false}
        />
      </Stack>
      <Flex justify="flex-end" w="100%" p={2}>
        <Button colorScheme="blue" type="submit" isLoading={isLoading}>
          Send
        </Button>
      </Flex>
    </form>
  )
}

export default ResetPassword
