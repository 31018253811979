import { useCallback, useRef, useState } from 'react'
import { useGlobalEventListener } from '../../../../../hooks'
import { actions as FormActions } from '../../../../../pages/TemplateEditor/FormActions/actions'

const { SET_DIALOG_CONTENT } = FormActions

const useFormAlerts = () => {
  const [dialogContent, setDialogContent] = useState()
  const dialogRef = useRef(null)
  const dialogTriggerRef = useRef(null)

  const onCloseDialog = (closeDialog) => {
    setDialogContent(null)
    closeDialog()
  }

  useGlobalEventListener(
    useCallback((event) => {
      const { type, payload } = event
      if (type === SET_DIALOG_CONTENT) {
        setDialogContent(payload)

        dialogTriggerRef.current.click()
      }
    }, [])
  )

  return {
    dialogContent,
    dialogTriggerRef,
    dialogRef,
    onCloseDialog
  }
}

export default useFormAlerts
