/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import Notification from '../../../../../services/Notification'
import AdminOlingaAPI from '../../../../../services/admin/AdminOlingaAPI'

const useSettingsFields = () => {
  const [rolesData, setRolesData] = useState([])
  const [isLoadingRoles, setIsLoadingRoles] = useState(false)

  const fetchRoles = useCallback(async () => {
    try {
      const { data } = await AdminOlingaAPI.rolesList({ isGrouped: true })
      setRolesData(data?.data)
    } catch (error) {
      Notification({
        icon: 'error',
        text: error?.message || ''
      })
    } finally {
      setIsLoadingRoles(false)
    }
  }, [])

  useEffect(() => {
    fetchRoles()
  }, [])

  return {
    rolesData,
    isLoadingRoles
  }
}

export default useSettingsFields
