export const mapStatusColor = {
  to_do: 'yellow',
  in_progress: 'purple',
  complete: 'green',
  cant_do: 'gray'
}

export const mapPriorityColor = {
  high: 'red',
  medium: 'yellow',
  low: 'blue'
}

export const questionTypes = [
  {
    id: 'boolean',
    name: 'Yes / No'
  },
  {
    id: 'counter',
    name: 'Number'
  },
  {
    id: 'free_field_one_line',
    name: 'Short Text'
  },
  {
    id: 'free_field_multiline',
    name: 'Paragraph'
  },
  {
    id: 'radio_btn',
    name: 'Single Select'
  },
  {
    id: 'checkbox',
    name: 'Multi Select'
  },
  {
    id: 'scale',
    name: 'Slider'
  },
  {
    id: 'select',
    name: 'List'
  },
  {
    id: 'image',
    name: 'Image'
  },
  {
    id: 'instruction',
    name: 'Instruction'
  },
  {
    id: 'date_time',
    name: 'Date & Time'
  },
  {
    id: 'calculated',
    name: 'Calculated'
  },
  {
    id: 'signature',
    name: 'Signature'
  }
]

export const quickSubmitQrCodeValidQuestions = [
  'boolean',
  'counter',
  'free_field_one_line',
  'radio_btn',
  'scale',
  'select',
  'asset'
]

export const mapQuestionTypes = questionTypes.reduce((acc, { id, name }) => {
  acc[id] = name
  return acc
}, {})

export const CREATE_FORM_VALUES = {
  ACTION: {
    label: 'What needs to be done?',
    renderDelete: false,
    fielName: 'title'
  },
  NOTES: {
    label: 'Type your notes...',
    renderDelete: false,
    fielName: 'title'
  }
}

export const RENDER_FORM_TYPES = {
  ACTION: 'ACTION',
  NOTES: 'NOTES',
  FILES: 'FILES'
}
