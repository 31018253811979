import {
  Box,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Switch
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import React from 'react'
import FormSubmitActions from '../../../../components/FormSubmitActions'
import EditInline from '../../../../components/Inputs/EditInline'
import LazyComponent from '../../../../components/LazyComponent'
import PageHeader from '../../../../components/PageHeader'
import AssetTypeEditorBreadcrumb from './AssetTypeEditorBreadcrumb'
import useAssetsEditor from './hooks/useAssetsEditor'

const AssetTypeEditor = ({ data, id }) => {
  const {
    businesses,
    errors,
    assetType,
    submitForm,
    register,
    onSetBusinesses,
    name,
    assetTypesBusinesses,
    renderNameInput
  } = useAssetsEditor({ data, id })

  return (
    <>
      <PageHeader
        Breadcrumb={
          <AssetTypeEditorBreadcrumb id={id} name={assetType?.name} />
        }
      />
      <Container maxW="1200px">
        <Box as="form" onSubmit={submitForm} w="100%">
          <Stack gap={4} w="100%">
            <Flex align="center" gap={4} flexDir={['column', 'row']}>
              <Flex flex={[1, 4]} w="100%" mr={[0, 4]}>
                <FormControl>
                  <FormLabel aria-labelledby="name">Name</FormLabel>
                  <LazyComponent condition={renderNameInput}>
                    <EditInline
                      placeholder="Asset type name"
                      registerProps={{
                        ...register('name', { required: true })
                      }}
                      defaultValue={name}
                      autoFocus={!name}
                      startWithEditView={!name}
                      width="100%"
                      isInvalid={!!errors?.name?.message}
                    />
                  </LazyComponent>
                </FormControl>
              </Flex>
              <FormControl flex={1}>
                <Switch
                  cursor="pointer"
                  mb="0"
                  mt={[0, 9]}
                  colorScheme="green"
                  {...register('active')}
                >
                  Active
                </Switch>
              </FormControl>
            </Flex>
            <FormControl>
              <FormLabel aria-labelledby="businesses">Business</FormLabel>
              <Select
                isMulti
                value={assetTypesBusinesses}
                id="businesses"
                placeholder="Businesses"
                onChange={onSetBusinesses}
                options={businesses}
                isInvalid={!!errors?.asset_type_businesses_attributes?.message}
                hideSelectedOptions
              />
            </FormControl>
            <FormSubmitActions isAdmin cancelRoute="assetTypes" />
          </Stack>
        </Box>
      </Container>
    </>
  )
}

export default AssetTypeEditor
