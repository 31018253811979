import { Flex, PopoverContent, PopoverFooter } from '@chakra-ui/react'
import styled from '@emotion/styled'
import colors from 'utils/colors'

export const Footer = styled(PopoverFooter)`
  span {
    ${({ maincolor }) => `
      background-color: ${maincolor};
    `}
    color: #fff;
    font-weight: 800;
  }
`

export const Content = styled(PopoverContent)`
  color: ${colors.primary};

  * {
    font-family: var(--lj-font-family-pt-sans-regular);
  }

  .chakra-form__label,
  .chakra-form__label {
    font-size: 14px;
    padding-right: 10px;
    color: #000;
  }

  a:hover,
  a:active {
    color: #000;
  }

  width: 450px;
  &:focus {
    box-shadow: none;
  }
`

export const Body = styled(Flex)`
  min-height: 242px;
  max-height: 242px;
  overflow: auto;
  cursor: default;
`
