import { Text } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import React from 'react'
import FloatingLabelBox from '../../../../../components/FloatingLabelBox'

const Notes = ({ answer }) => {
  const { answer: answerAttributes } = answer
  if (isEmpty(answerAttributes?.note)) {
    return null
  }
  const note = answerAttributes?.note.text
  return (
    <FloatingLabelBox label="Notes" borderColor="gray">
      <Text fontSize="md">{note}</Text>
    </FloatingLabelBox>
  )
}

export default Notes
