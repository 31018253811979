import { AddIcon, EditIcon } from '@chakra-ui/icons'
import { Alert, AlertIcon, Button, IconButton, Stack } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, { memo, useRef } from 'react'
import Modal from '../../../../../../components/Modal'
import SetValueAdapter from '../../../../../../components/OperationsBoard/SetValueAdapter'
import { calculatedFieldsEnumTypes, templateStructureTypes } from '../constants'

const CalculatedFieldsModal = ({
  watch,
  alertMessage,
  setValue,
  structure,
  operations,
  id,
  attributeKey,
  index,
  setEditionAlertMessage,
  isAddAction = false,
  setAddOperatorsButtonRef,
  setEditOperationsButtonRef
}) => {
  const ref = useRef()
  const itemId = watch(`${attributeKey}.${index}.id`)

  return (
    <Modal
      ref={ref}
      size="4xl"
      header={isAddAction ? 'Add operators' : 'Edit operators'}
      body={
        <Stack>
          {!!alertMessage && (
            <Alert size="sm" w="100%" status="warning">
              <AlertIcon />
              {alertMessage}
            </Alert>
          )}
          <SetValueAdapter
            watch={watch}
            setValue={setValue}
            structure={structure}
            id={id}
            operations={operations}
            attributeKey={attributeKey}
            index={index}
            setEditionAlertMessage={setEditionAlertMessage}
          />
        </Stack>
      }
    >
      {isAddAction ? (
        <Button
          ref={(buttonRef) => setAddOperatorsButtonRef(index, buttonRef)}
          colorScheme="blue"
          leftIcon={<AddIcon />}
          alignSelf="flex-start"
        >
          Add operators
        </Button>
      ) : (
        <IconButton
          size="xs"
          borderRadius={2}
          mt="0.5"
          icon={<EditIcon />}
          colorScheme="blue"
          ref={(ref) => setEditOperationsButtonRef(itemId, ref)}
        />
      )}
    </Modal>
  )
}

CalculatedFieldsModal.propTypes = {
  operations: calculatedFieldsEnumTypes,
  structure: templateStructureTypes,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentKey: PropTypes.string,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  allowSetOperator: PropTypes.bool,
  setEditionAlertMessage: PropTypes.func,
  alertMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setAddOperatorsButtonRef: PropTypes.any,
  setEditOperationsButtonRef: PropTypes.any,
  isAddAction: PropTypes.bool
}

export default memo(CalculatedFieldsModal)
