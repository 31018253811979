import React from 'react'
import BasePage from '../../../pages/BasePage'
import FillForm from '../../../pages/Form/FillForm'
import OlingaAPI from '../../../services/OlingaAPI'

const FillFormPage = ({ id }) => {
  return (
    <BasePage
      id={id}
      resourceName="data"
      fetchCallback={{
        template: { callback: () => OlingaAPI.templateShow(id), params: {} },
        details: {
          callback: () =>
            OlingaAPI.templateStructure(id, {
              actives: true
            })
        }
      }}
    >
      <FillForm />
    </BasePage>
  )
}

export default FillFormPage
