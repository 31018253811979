import { useEffect, useState } from 'react'

const useResize = () => {
  const [size, setSize] = useState([0, 0])
  const [navbarHeight, setNavbarHeight] = useState()
  useEffect(() => {
    const getSize = () => {
      const navbar = document.querySelector('#olinga-navbar')
      if (navbar) {
        setNavbarHeight(navbar.offsetHeight)
      }
      setSize([window.innerWidth, window.innerHeight])
    }
    getSize()
    window.addEventListener('resize', getSize)
    return () => window.removeEventListener('resize', getSize)
  }, [])
  return { size, navbarHeight }
}
export default useResize
