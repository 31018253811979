import { theme, Tooltip } from '@chakra-ui/react'
import MaterialIcon from '../MaterialIcon'
import PropTypes from 'prop-types'
import React from 'react'

const InfoTip = ({ label, icon, placement }) => {
  return (
    <Tooltip zIndex={99999} label={label} hasArrow placement={placement}>
      <span>
        <MaterialIcon
          icon={icon}
          styles={{
            color: theme.colors.gray[500],
            fontSize: '14px',
            cursor: 'pointer',
          }} />
      </span>
    </Tooltip>
  )
}

InfoTip.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  placement: PropTypes.string
}

InfoTip.defaultProps = {
  icon: 'info',
  placement: 'auto'
}

export default InfoTip
