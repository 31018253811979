/* eslint-disable no-param-reassign */
import actions from '../../actions'

const { SET_GLOBAL_SETTINGS } = actions.global

const initialState = {
  user: {
    id: null,
    email: '',
    personable_type: 'Business',
    roles: [],
    permissions: {
      templates: {
        shouldCreate: true,
        shouldRenderMenu: true
      },
      checklists: {
        shouldCreate: true
      },
      staff: {
        shouldModify: true
      }
    }
  },
  navbar: {
    logo_path: '/cxmp/olinga_white_logo.png',
    menus: [],
    settings_dropdown: [],
    templates_dropdown: []
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GLOBAL_SETTINGS: {
      state = { ...state, ...(action.payload ?? {}) }
      return state
    }
    default:
      return state
  }
}

export default reducer
