import React, { useRef } from 'react'
import Modal from '../Modal'
import ToggleValueModalBody from './ToggleValueModalBody'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { Button } from '@chakra-ui/react'

const ToggleValueModal = ({
  value,
  onChange,
  options,
  children,
  title,
  attributeKey,
  colorScheme,
  closeOnSelect
}) => {
  const ref = useRef()
  const onChangeClosing = (value) => {
    onChange(value)
    if (closeOnSelect) {
      ref.current.close()
    }
  }
  return (
    <Modal
      ref={ref}
      header={title}
      footer={
        <Button onClick={() => onChangeClosing('')} colorScheme="red">
          Clear
        </Button>
      }
      body={
        <ToggleValueModalBody
          options={options}
          value={value}
          onChange={onChangeClosing}
          attributeKey={attributeKey}
          colorScheme={colorScheme}
        />
      }
    >
      {children}
    </Modal>
  )
}

ToggleValueModal.displayName = 'ToggleValueModal'

ToggleValueModal.defaultProps = {
  value: '',
  onChange: noop,
  options: [],
  attributeKey: 'value',
  colorScheme: 'teal',
  closeOnSelect: true
}

ToggleValueModal.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
      disabled: PropTypes.bool
    })
  ),
  attributeKey: PropTypes.string,
  colorScheme: PropTypes.string,
  closeOnSelect: PropTypes.bool
}

export default ToggleValueModal
