import { EditIcon, ViewIcon } from '@chakra-ui/icons'
import { Flex, IconButton } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { getRouteUrl } from '../../../helpers'

const RowActions = ({ id, hasPermissionToView, hasPermissionToEdit }) => {
  return (
    <Flex gap={2}>
      {hasPermissionToView && (
        <Link
          to={{ pathname: getRouteUrl('viewAction', { id }), from: 'actions' }}
        >
          <IconButton
            icon={<ViewIcon />}
            colorScheme="teal"
            size="sm"
            variant="outline"
          />
        </Link>
      )}
      {hasPermissionToEdit && (
        <Link
          to={{ pathname: getRouteUrl('editAction', { id }), from: 'actions' }}
        >
          <IconButton
            icon={<EditIcon />}
            colorScheme="blue"
            size="sm"
            variant="outline"
          />
        </Link>
      )}
    </Flex>
  )
}

export default RowActions
