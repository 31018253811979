import { Button } from '@chakra-ui/button'
import { AddIcon } from '@chakra-ui/icons'
import { Flex, Stack } from '@chakra-ui/layout'
import SearchInput from 'components/SearchInput'
import React from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import { getRouteUrl } from '../../helpers'
import useProfiles from './useProfiles'

const Profiles = () => {
  const {
    columns,
    hiddenColumns,
    isLoading,
    pagination,
    tableData,
    fetchParams,
    refetch,
    hiddenColumnsKey,
    pageTitle,
    onTextChange,
    hasPermissionToCreateProfiles
  } = useProfiles()

  return (
    <Stack>
      <PageHeader
        title={pageTitle}
        Actions={
          <Flex flexDir={['column', 'row']} gap={4} align="center">
            <SearchInput
              value={fetchParams?.name}
              setValue={onTextChange}
              isLoading={isLoading}
              debounceTimeout={25}
              placeholder="Filter by profile name"
            />
            {hasPermissionToCreateProfiles ? (
              <Link to={getRouteUrl('createProfile')}>
                <Button leftIcon={<AddIcon />} colorScheme="blue">
                  New
                </Button>
              </Link>
            ) : null}
          </Flex>
        }
      />
      <Table
        shouldResetPage={fetchParams?.page === 1}
        isLoading={isLoading}
        data={tableData}
        columns={columns}
        pageCount={pagination?.last}
        totalItems={pagination?.items}
        fetchPage={refetch}
        initiallyHiddenColumns={hiddenColumns}
        initialPageIndex={Number(fetchParams?.page - 1)}
        persistedColumnsKey={hiddenColumnsKey}
      />
    </Stack>
  )
}

export default Profiles
