import { noop, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { createContext, memo, useContext, useRef } from 'react'
import SelectionHOC from './SelectionHOC'

const RecursiveStructureContext = createContext({})

const RecursiveStructureProvider = ({
  initialSelectedNodes,
  onSelectionChange,
  children,
  allowSelection,
  selectionMode,
  answers,
  answerLabel
}) => {
  const selectionRef = useRef(initialSelectedNodes)
  const onSelect = (node) => {
    const currentNodes = [...selectionRef.current]
    if (currentNodes.map(({ id }) => id).includes(node.id)) {
      selectionRef.current = selectionRef.current.filter((id) => id !== node.id)
    } else {
      selectionRef.current = uniqBy([...currentNodes, node], ({ id }) => id)
    }
    onSelectionChange(selectionRef.current)
  }

  return (
    <RecursiveStructureContext.Provider
      value={{
        initialSelectedNodes,
        onSelect,
        allowSelection,
        selectionMode,
        answers,
        answerLabel
      }}
    >
      <SelectionHOC
        selectionMode={selectionMode}
        defaultSingleSelectedNode={null}
        onSelectionChange={onSelectionChange}
        initialSelectedNodes={initialSelectedNodes}
      >
        {children}
      </SelectionHOC>
    </RecursiveStructureContext.Provider>
  )
}

RecursiveStructureProvider.propTypes = {
  selectionMode: PropTypes.oneOf(['single', 'multiple', 'disabled'])
}

RecursiveStructureProvider.defaultProps = {
  selectionMode: 'disabled',
  allowSelection: noop,
  answerLabel: 'Answer'
}

export default memo(RecursiveStructureProvider)

export const useRecursiveStructureContext = () => {
  const context = useContext(RecursiveStructureContext)

  return {
    ...context
  }
}
