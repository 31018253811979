import { Button, Flex, Link } from '@chakra-ui/react'
import { isFunction } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as DOMLink, useHistory } from 'react-router-dom'
import { chakraComponentsSizes, getRouteUrl } from '../../helpers'
import { getRouteUrl as getAdminRouteUrl } from '../../helpers/admin'

const CancelButton = ({
  cancelRoute,
  isAdmin,
  cancelAction,
  cancelLabel,
  size,
  routeParams = {}
}) => {
  const history = useHistory()
  if (history?.location?.from) {
    return (
      <Button onClick={() => history.goBack()} size={size}>
        {cancelLabel}
      </Button>
    )
  }
  if (cancelRoute) {
    return (
      <Link
        as={DOMLink}
        to={
          isAdmin
            ? getAdminRouteUrl(cancelRoute, routeParams)
            : getRouteUrl(cancelRoute, routeParams)
        }
      >
        <Button size={size}>{cancelLabel}</Button>
      </Link>
    )
  }
  return (
    <Button size={size} onClick={cancelAction}>
      {cancelLabel}
    </Button>
  )
}

const FormSubmitActions = ({
  isLoading,
  isAdmin,
  cancelRoute,
  cancelAction,
  cancelLabel,
  margin,
  size,
  onSubmit,
  saveButtonRef,
  routeParams,
  isDisabled
}) => {
  const handleSubmit = () => {
    if (isFunction(onSubmit)) {
      onSubmit()
    }
  }
  return (
    <Flex gap={4} my={margin} align="center">
      <CancelButton
        size={size}
        cancelAction={cancelAction}
        cancelRoute={cancelRoute}
        cancelLabel={cancelLabel}
        isAdmin={isAdmin}
        routeParams={routeParams}
      />
      <Button
        isLoading={isLoading}
        loadingText="Saving"
        type={onSubmit ? 'button' : 'submit'}
        colorScheme="blue"
        size={size}
        onClick={handleSubmit}
        ref={saveButtonRef}
        isDisabled={isDisabled}
      >
        Save
      </Button>
    </Flex>
  )
}

FormSubmitActions.defaultProps = {
  isLoading: false,
  isAdmin: false,
  isDisabled: false,
  cancelRoute: '',
  cancelLabel: 'Cancel',
  margin: 0,
  cancelAction: () => ({}),
  size: 'md',
  onSubmit: null,
  saveButtonRef: null
}

FormSubmitActions.propTypes = {
  isLoading: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isDisabled: PropTypes.bool,
  cancelRoute: PropTypes.string,
  cancelAction: PropTypes.func,
  cancelLabel: PropTypes.string,
  size: PropTypes.oneOf(chakraComponentsSizes),
  onSubmit: PropTypes.func,
  saveButtonRef: PropTypes.object
}

export default FormSubmitActions
