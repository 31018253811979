import {
  onDestroyTemplateTypeQuestionAssetTypesAssociation,
  onFetchAssetsTemplates
} from 'contexts/GlobalStateContext/events'
import useTemplateEditorSettings from 'contexts/TemplateEditor/TemplateEditorSettings/useTemplateEditorSettings'
import {
  cloneDeep,
  defer,
  first,
  isArray,
  isFunction,
  isString,
  keys,
  omit,
  startsWith
} from 'lodash'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTemplateEditorContext } from '../../../../../contexts'
import { useGlobalStateContext } from '../../../../../contexts/GlobalStateContext'
import { getOption } from '../../../../../pages/TemplateEditor/Form/helpers'
import { actions as FormActions } from '../../../../../pages/TemplateEditor/FormActions/actions'
import {
  booleanValues,
  findParentNodeByCondition,
  shouldKeepOpened
} from '../../../../../pages/TemplateEditor/helpers'

export const tabs = {
  logics: 0,
  settings: 1,
  notifications: 2,
  options: 3,
  operations: 0
}

const {
  REMOVE,
  APPEND_SECTION,
  ADD_QUESTION,
  APPEND_QUESTION,
  ADD_LOGIC,
  REMOVE_ALL_LOGICS
} = FormActions

const useQuestionOperations = ({ currentKey, isNested }) => {
  const { register, control, setValue, getValues } = useFormContext()
  const {
    focusedField,
    handleFocusedField,
    ghostElement,
    errors,
    logicOptions
  } = useTemplateEditorContext()

  const { getDefaultSectionField } = useTemplateEditorSettings()

  const { global$ } = useGlobalStateContext()

  const dispatchAction = (type, payload) => {
    global$.next({
      type,
      payload
    })
  }

  const {
    optionsKey,
    deletedOptionsKey,
    notificationsKey,
    deletedOperationsKey,
    operationsKey
  } = {
    optionsKey: `${currentKey}.template_question_options_attributes`,
    deletedOptionsKey: `${currentKey}.template_question_options_attributes_deleted`,
    notificationsKey: `${currentKey}.alert_trigger_attributes`,
    operationsKey: `${currentKey}.question_calculation_fields_attributes`,
    deletedOperationsKey: `${currentKey}.question_calculation_fields_attributes_deleted`
  }

  const ref = useRef(null)
  const alertTriggerRef = useRef(null)
  const scaleAlertTriggerRef = useRef(null)
  const assetAlertTriggerRef = useRef(null)
  const questionTypeRef = useRef({ current: '', goto: '' })

  const [
    id,
    questionType,
    movedQuestions,
    logics,
    operationsItems,
    deletedOperationsItems
  ] = useWatch({
    name: [
      `${currentKey}.id`,
      `${currentKey}.question_type`,
      'moved_questions_attributes',
      `${currentKey}.questions`,
      operationsKey,
      deletedOperationsKey
    ],
    control
  })

  const setSelectedTab = useCallback(
    (tab) => {
      setValue(`${currentKey}._selectedTab`, tab)
    },
    [currentKey, setValue]
  )

  useEffect(() => {
    const firstError = first(errors)
    if (isString(firstError)) {
      const logicAttributesError = ['value', 'question_logic_option_id']
      if (
        logicAttributesError.some((attribute) => firstError.match(attribute))
      ) {
        setSelectedTab(tabs['logics'])
      }
    }
  }, [errors, setSelectedTab])

  useEffect(() => {
    if (startsWith(focusedField, currentKey) && ghostElement) {
      setSelectedTab(tabs['logics'])
    }
  }, [focusedField, currentKey, ghostElement, setSelectedTab])

  const shouldKeepFocused = useMemo(() => {
    return logics?.length > 0 && isString(focusedField)
      ? shouldKeepOpened(currentKey, focusedField)
      : false
  }, [currentKey, focusedField, logics])

  const getPossibleMoveSections = () => {
    const oneLevelBehindKey = currentKey.split('.').slice(0, -2).join('.')
    let level = []
    if (!oneLevelBehindKey) {
      level = getValues('questions')
    } else {
      level = getValues(`${oneLevelBehindKey}.questions`)
    }
    const _question = { ...getValues(currentKey), _key: currentKey }
    const questionIndex = Number(currentKey.split('.').slice(-1))

    const getKeys = (index) => {
      const _key = `${oneLevelBehindKey}${
        !oneLevelBehindKey ? '' : '.'
      }questions.${index}`
      const splittedKey = _key.split('.')
      splittedKey[splittedKey.length - 1] =
        index > questionIndex ? index - 1 : index
      const _focusKey = splittedKey.join('.')

      return { _key, _focusKey }
    }

    const sameLevelSections = level
      .map((question, index) => {
        const { _key, _focusKey } = getKeys(index)
        return {
          ...question,
          ...getKeys(index),
          _key,
          _question: { ..._question, _focusKey }
        }
      })
      .filter((question) => question.origin === 'section_questions_attributes')
      .filter((section) => !!section.temp_id || section?._id)
    sameLevelSections.push({
      _key: 'new_section',
      temp_id: Math.floor(1000 + Math.random() * 9000),
      name: 'New Section',
      Icon: null,
      _question
    })

    return sameLevelSections
  }

  const getOptions = (values = null) => {
    const options = getOption(optionsKey, getValues(), values).map(
      (option) => ({ ...option, _id: option._id || option.id })
    )
    const deletedOptions = getOption(deletedOptionsKey, getValues(), values)
    const notifiedUsers = getOption(notificationsKey, getValues(), values)
    return {
      options: options,
      deletedOptions: deletedOptions,
      notifiedUsers: keys(notifiedUsers).includes(
        'alert_triggers_users_attributes'
      )
        ? notifiedUsers['alert_triggers_users_attributes']
        : []
    }
  }

  const options = useWatch({
    name: optionsKey,
    control,
    defaultValue: getOptions().options
  })

  const notifiedOptions = useWatch({
    name: `${notificationsKey}.alert_triggers_users_attributes`,
    control,
    defaultValue: getOptions().notifiedUsers
  })

  const handleDragClick = (event) => {
    const { target } = event
    const compare = (node) => {
      if (node) {
        if (isFunction(node?.getAttribute)) {
          const dataId = node.getAttribute('data-id')
          const dataIgnore = node.getAttribute('data-ignore') === 'true'
          if (dataIgnore) {
            return true
          }
          if (dataId && dataId === 'draggable-container') {
            return true
          }
        }
      }
      return false
    }
    const node = findParentNodeByCondition(target, compare)
    const questionType = getValues(currentKey)?.question_type
    if (questionType === 'asset') {
      global$.next(onFetchAssetsTemplates())
    }
    if (node) {
      const splittedKey = currentKey.split('.')
      const shouldIgnore = node.getAttribute('data-ignore') === 'true'
      const isParentExpanded = node.getAttribute('data-expanded') === 'true'
      if (isParentExpanded) {
        return
      }
      if (shouldIgnore) {
        return
      }
      let parent = ''
      if (isNested && splittedKey.length > 2) {
        parent = currentKey.split('.').slice(0, -2).join('.')
      }
      if (parent && (isNested || shouldKeepFocused)) {
        handleFocusedField(parent)
      } else {
        handleFocusedField(null)
      }
    } else {
      if (shouldKeepFocused) {
        return
      }
      defer(() => {
        handleFocusedField(currentKey)
      })
    }
  }

  const handleTabsChange = (index) => {
    setSelectedTab(index)
  }

  const handleQuestionType = ({ target: { value } }) => {
    setValue(`${currentKey}.question_type`, value)

    if (logics?.length > 0) {
      logics.forEach((logic, index) => {
        if (
          logic?.question_logic_option_id &&
          Object.keys(logicOptions ?? {}).length > 0
        ) {
          const selectedOptionLabel = Object.values(logicOptions)
            .flatMap((v) => v)
            ?.find(
              ({ id }) =>
                !isArray(logic?.question_logic_option_id) &&
                id.toString() === logic?.question_logic_option_id.toString()
            )?.attributes?.text

          const logicOptionsContainingSelectedOptionLabel = Object.entries(
            logicOptions
          )
            .filter(([, v]) =>
              v.find(({ text }) => {
                return text === selectedOptionLabel
              })
            )
            .reduce((acc, [k, v]) => {
              acc[k] = {
                value: v.find(({ text }) => text === selectedOptionLabel)
                  ?.attributes?.id,
                options: v
              }
              return acc
            }, {})
          const logicOptionWithSameLabelValue =
            logicOptionsContainingSelectedOptionLabel[value]
          const questionsWithOptions = [
            'boolean',
            'select',
            'radio_btn',
            'checkbox'
          ]

          const options =
            value === 'boolean'
              ? booleanValues
              : questionsWithOptions.includes(value)
              ? getValues(optionsKey)
              : []

          if (logicOptionWithSameLabelValue?.value) {
            setValue(
              `${currentKey}.questions.${index}.question_logic_option_id`,
              logicOptionWithSameLabelValue?.value
            )
            if (questionsWithOptions.includes(value)) {
              if (options.length) {
                const newValueIndex = options.findIndex(
                  ({ label }) => label === logic?.value
                )
                if (newValueIndex >= 0) {
                  setValue(
                    `${currentKey}.questions.${index}.value`,
                    options[newValueIndex].label
                  )
                } else if (['boolean'].includes(value)) {
                  setValue(
                    `${currentKey}.questions.${index}.value`,
                    first(booleanValues).label
                  )
                } else {
                  setValue(
                    `${currentKey}.questions.${index}.value`,
                    first(options)?.label
                  )
                }
              }
            } else if (!['counter', 'scale'].includes(value)) {
              setValue(`${currentKey}.questions.${index}.value`, '')
            }
          } else if (questionsWithOptions.includes(value)) {
            setValue(
              `${currentKey}.questions.${index}.question_logic_option_id`,
              first(options)?.label
            )
          } else {
            setValue(`${currentKey}.questions.${index}.value`, '')
          }
        }
      })
    }

    if (value === 'asset') {
      global$.next(onFetchAssetsTemplates())
    }

    if (id && questionType === 'asset' && value !== 'asset') {
      const assetTypes =
        getValues(`${currentKey}.template_question_assets_attributes`) ?? []
      if (assetTypes.some(({ _id }) => !!_id)) {
        questionTypeRef.current = { current: questionType, goto: value }
        assetAlertTriggerRef?.current?.open()
      }

      return
    }

    if (id && questionType === 'scale' && value !== 'scale') {
      questionTypeRef.current = { current: questionType, goto: value }
      scaleAlertTriggerRef?.current?.open()

      return
    }

    if (id && questionType === 'calculated' && value !== 'calculated') {
      questionTypeRef.current = { current: questionType, goto: value }
      alertTriggerRef?.current?.open()

      return
    }

    if (value === 'calculated') {
      setValue(`${currentKey}.compulsory`, false)
      setValue(`${currentKey}.main`, false)
    }
  }

  const handleAddQuestionToSection = (section) => {
    const { _question: question, _key: key } = section
    const updatedQuestion = getValues(question._key)
    const sectionTempId = Math.floor(1000 + Math.random() * 9000)
    let movedQuestionAttributes = {
      section_id: null,
      question_id: null
    }
    if (key === 'new_section') {
      const sectionStructure = {
        ...getDefaultSectionField(),
        temp_id: sectionTempId,
        _shouldFocusFirstChild: question._key + `.question.0`
      }
      sectionStructure.questions.push({
        ...question,
        ...updatedQuestion,
        _focusKey: question._key + `.question.0`
      })
      dispatchAction(REMOVE, { focusedField: question._key })
      movedQuestionAttributes = {
        section_temp_id: sectionTempId,
        section_id: null
      }
      defer(() => {
        dispatchAction(APPEND_SECTION, { element: sectionStructure })
      })
    } else {
      movedQuestionAttributes.section_id = section._id
      dispatchAction(ADD_QUESTION, {
        key,
        element: {
          ...question,
          ...omit(updatedQuestion, ['_focusKey', '_key'])
        },
        shouldRemoveQuestion: true
      })
    }
    if (question._id) {
      movedQuestionAttributes.question_id = {
        ...question,
        ...omit(updatedQuestion, ['_focusKey', '_key'])
      }._id
    } else {
      movedQuestionAttributes = null
    }
    const clonedMovedQuestions = movedQuestions ? [...movedQuestions] : []
    if (movedQuestionAttributes) {
      clonedMovedQuestions.push(movedQuestionAttributes)
    }
    setValue('moved_questions_attributes', clonedMovedQuestions)
  }

  const handleRemoveOptionFromSection = () => {
    const question = getValues(currentKey)
    dispatchAction(REMOVE, { focusedField: currentKey })
    let clonedMovedQuestions = cloneDeep(movedQuestions) || []
    clonedMovedQuestions = clonedMovedQuestions.filter(
      (option) => option.question_id !== question._id
    )
    setValue('moved_questions_attributes', clonedMovedQuestions)
    defer(() => {
      const newQuestion = omit(question, ['_key', '_focus_key'])
      dispatchAction(APPEND_QUESTION, { element: newQuestion })
    })
  }

  useEffect(() => {
    register(`${currentKey}.text`, { required: true })
  }, [register, currentKey])

  const onChangeTextValue = (value) => {
    const regex = new RegExp('<[^>]*>', 'g')
    const isEmptyValue = !(value.trim && value.trim().replace(regex, ''))
    const newValue = isEmptyValue ? '' : value

    setValue(`${currentKey}.text`, newValue)
  }

  const handleAddLogic = () => {
    dispatchAction(ADD_LOGIC, { focusedField: currentKey })
  }

  const handleRemoveLogics = () => {
    dispatchAction(REMOVE_ALL_LOGICS, { focusedField: currentKey })
  }

  const handleParentFocus = () => {
    handleFocusedField(currentKey)
  }

  const onDestroyAssociatedAssetTypes = ({ questionType, onClose }) => {
    const associatedAssetTypes = (
      getValues(`${currentKey}.template_question_assets_attributes`) ?? []
    ).filter(({ _id }) => _id)

    global$.next(
      onDestroyTemplateTypeQuestionAssetTypesAssociation({
        onSuccess: () => {
          setValue(`${currentKey}.template_question_assets_attributes`, [])
          onClose()

          handleFocusedField(currentKey)
        },
        data: {
          template_questions_attributes: [
            {
              id,
              question_type: questionType,
              template_question_assets_attributes: associatedAssetTypes.map(
                (item) => ({ ...item, _destroy: true })
              )
            }
          ]
        }
      })
    )
  }

  const onConfirmChangeType = (onClose) => {
    const { current, goto } = questionTypeRef.current
    if (current === 'calculated') {
      const operationsToDestroy = operationsItems
        .filter(({ id }) => !!id)
        .map((operation) => ({ ...operation, _destroy: true }))
      setValue(`${currentKey}.question_calculation_fields_attributes_deleted`, [
        ...(deletedOperationsItems ?? []),
        ...operationsToDestroy
      ])
      setValue(`${currentKey}.question_calculation_fields_attributes`, [])
    } else if (current === 'asset') {
      onDestroyAssociatedAssetTypes({ questionType: goto, onClose })
      questionTypeRef.current = { current, goto: '' }

      return
    }
    setValue(`${currentKey}.question_type`, goto)
    handleTabsChange(tabs['logics'])
    questionTypeRef.current = { current, goto: '' }

    onClose()
  }

  const onCancelChangeType = () => {
    const { current } = questionTypeRef.current
    setValue(`${currentKey}.question_type`, current)
    alertTriggerRef?.current?.close()
    scaleAlertTriggerRef?.current?.close()
    assetAlertTriggerRef?.current?.close()
  }

  return {
    handleParentFocus,
    handleRemoveLogics,
    handleAddLogic,
    handleRemoveOptionFromSection,
    handleAddQuestionToSection,
    handleQuestionType,
    handleTabsChange,
    handleDragClick,
    getOptions,
    getPossibleMoveSections,
    onChangeTextValue,
    onConfirmChangeType,
    onCancelChangeType,
    options,
    notifiedOptions,
    alertTriggerRef,
    scaleAlertTriggerRef,
    assetAlertTriggerRef,
    ref,
    shouldKeepFocused
  }
}

export default useQuestionOperations
