import { Input } from '@chakra-ui/input'
import { Flex } from '@chakra-ui/layout'
import {
  Slider as ChakraSlider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack
} from '@chakra-ui/slider'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

const labelStyles = {
  mt: '5',
  ml: '-5.5',
  fontSize: 'sm'
}

const Slider = ({ onChange, value, isInvalid, interval = {} }) => {
  const { min, max } = interval

  return (
    <Flex justify="space-between">
      <Input value={value} size="sm" readOnly w="50px" />
      <ChakraSlider
        aria-label="slider"
        defaultValue={0}
        onChange={onChange}
        min={Number(min)}
        max={Number(max)}
      >
        {min && (
          <SliderMark value={min} {...labelStyles}>
            {min}
          </SliderMark>
        )}
        <SliderTrack bg={isInvalid ? 'red.500' : 'gray.200'}>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
        {max && (
          <SliderMark value={max} {...labelStyles}>
            {max}
          </SliderMark>
        )}
      </ChakraSlider>
    </Flex>
  )
}

Slider.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool
}

Slider.defaultProps = {
  value: 0,
  onChange: _.noop,
  isInvalid: false
}

export default Slider
