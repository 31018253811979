import { Tag } from '@chakra-ui/react'
import { useGlobalStateContext } from 'contexts/GlobalStateContext'
import { onRefreshAssetsPage } from 'contexts/GlobalStateContext/events'
import { getRouteUrl } from 'helpers'
import {
  usePageProperties,
  useQuery,
  useTableColumns,
  useTableFilter
} from 'hooks'
import { isEmpty, noop, pick, trim } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Notification from 'services/Notification'
import OlingaAPI from 'services/OlingaAPI'
import { setFormErrors } from '../../../../helpers'
import EditAssetButton from '../../EditAssetButton'

export const hiddenColumnsKey = 'form_answers_persisted_columns.assets'

const useAssets = ({ data }) => {
  const history = useHistory()

  const assetTemplatesEnumerators = data?.assetTemplates?.data?.asset_templates

  const [selectedAssetTemplateFilter, setSelectedAssetTemplateFilter] =
    useState(null)

  const fieldsExtractor = useCallback((data) => {
    let allMainFields = data.flatMap(
      ({ attributes: { main_fields } }) => main_fields
    )
    const returnedData = data.map(
      ({
        attributes: { id, active, category, user_permissions, main_fields }
      }) => {
        return {
          id,
          active: (
            <Tag colorScheme={active ? 'green' : 'gray'}>
              {active ? 'Active' : 'Inactive'}
            </Tag>
          ),
          ...(allMainFields ?? [])?.reduce((acc, { name: n }) => {
            const fieldValue = main_fields.find(({ name }) => name === n)
            if (fieldValue) {
              acc[n] = fieldValue?.value
            } else {
              acc[n] = <Tag colorScheme="gray">Empty</Tag>
            }
            return acc
          }, {}),
          main_fields,
          asset_type: category,
          user_permissions
        }
      }
    )
    return returnedData
  }, [])

  const [template, setTemplate] = useState({ name: '', id: null })

  const { shouldRender: shouldAllowAssetTypesRender } = usePageProperties({
    pageName: 'asset_templates'
  })

  const {
    shouldCreate: shouldAllowAssetsCreation,
    shouldRender: shouldAllowAssetsRender
  } = usePageProperties({
    pageName: 'assets'
  })

  const { shouldBulkImport: shouldRenderBulkImportButton } = usePageProperties({
    pageName: 'asset_templates'
  })

  const query = useQuery()

  const { global$ } = useGlobalStateContext()
  const [shouldFetchAssetTemplate, setShouldFetchAssetTemplate] = useState(
    !!query?.asset_template_id
  )

  useEffect(() => {
    if (query?.asset_template_id && !isEmpty(assetTemplatesEnumerators)) {
      setSelectedAssetTemplateFilter(
        assetTemplatesEnumerators?.find(
          ({ value }) => value === Number(query?.asset_template_id)
        )
      )
    }
  }, [query?.asset_template_id, assetTemplatesEnumerators])

  const initialParams = useMemo(() => {
    return isEmpty(query)
      ? {
          page: 1,
          per_page: 15
        }
      : pick(query, [
          'page',
          'per_page',
          'asset_template_id',
          'asset_field_value'
        ])
  }, [query])

  const {
    refetch,
    isLoading,
    updateParams,
    fetchParams,
    tableData,
    pagination
  } = useTableFilter({
    id: query?.asset_template_id,
    fetchCallback: shouldFetchAssetTemplate
      ? OlingaAPI.assetTemplateAssets
      : OlingaAPI.assets,
    responseTransformer: (data) => {
      if (data?.asset_template) {
        setTemplate(data?.asset_template)
      }
      return data
    },
    fieldsExtractor,
    initialParams,
    onRefetch: useCallback(
      (newParams) => {
        history.replace(getRouteUrl('assets', newParams))
        setShouldFetchAssetTemplate(
          Object.keys(newParams).includes('asset_template_id')
        )
      },
      [history]
    )
  })

  const onTextChange = useCallback(
    (updatedText) => {
      if (!updatedText || !trim(updatedText)?.length) {
        updateParams(fetchParams, true, ['asset_field_value'])
        return
      }
      updateParams({ asset_field_value: updatedText }, updatedText?.length >= 3)
    },
    [updateParams, fetchParams]
  )

  const onDelete = async (id) => {
    try {
      const response = await OlingaAPI.deleteAsset(id)
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: response?.data?.message || 'Asset deleted successfully'
        })

        refetch(pick(query, ['asset_template_id', 'page', 'per_page']))
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    }
  }

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      ...tableData.reduce((acc, item) => {
        if (!isEmpty(item?.main_fields)) {
          acc = [
            ...item?.main_fields?.map(({ name }) => ({
              Header: name,
              accessor: name
            }))
          ]
        }
        return acc
      }, []),
      {
        Header: 'Asset Type',
        accessor: 'asset_type'
      },
      {
        Header: 'Active',
        accessor: 'active'
      }
    ],
    hiddenColumnsKey,
    true,
    EditAssetButton,
    ({ original: { id, user_permissions } }) => ({
      id,
      onDelete,
      permissions: user_permissions
    }),
    []
  )

  const onReplaceUrl = () => {
    const newParams = {
      per_page: 15,
      page: 1
    }
    history.replace(
      getRouteUrl('assets', {
        ...newParams
      })
    )

    global$.next(onRefreshAssetsPage())
  }

  const onSelectAssetTemplateFilter = (assetTemplate) => {
    if (assetTemplate?.value) {
      updateParams({ asset_template_id: assetTemplate?.value })
    } else {
      updateParams(fetchParams, true, ['asset_template_id'])
    }
    setSelectedAssetTemplateFilter(assetTemplate)
  }

  return {
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data: tableData,
    isLoading,
    refetch,
    updateParams,
    fetchParams,
    pagination,
    onTextChange,
    shouldAllowAssetTypesRender,
    shouldAllowAssetsCreation,
    shouldAllowAssetsRender,
    shouldRenderBulkImportButton,
    template,
    onReplaceUrl,
    assetTemplatesEnumerators,
    onSelectAssetTemplateFilter,
    selectedAssetTemplateFilter,
    shouldFetchAssetTemplate
  }
}

export default useAssets
