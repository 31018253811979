import theme from '@chakra-ui/theme'
import styled from '@emotion/styled'

export const RequiredInfo = styled.span`
  color: ${theme.colors.red[500]};
  font-weight: bold;
  margin-left: 4px;
`

export const FieldContainer = styled.div`
  padding-top: 15px;
  & p {
    margin-bottom: 0px;
  }
`
