import { ViewIcon } from '@chakra-ui/icons'
import { Button, Tag } from '@chakra-ui/react'
import { get, noop, trim } from 'lodash'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { getRouteUrl, setFormErrors } from '../../../../helpers'
import {
  usePageProperties,
  useTableColumns,
  useTableFilter
} from '../../../../hooks'
import Notification from '../../../../services/Notification'
import OlingaAPI from '../../../../services/OlingaAPI'
import EditAssetTemplateButton from '../../EditAssetTemplateButton'

export const hiddenColumnsKey = 'form_answers_persisted_columns.asset_templates'

const useAssetTemplates = () => {
  const fieldsExtractor = useCallback(
    (data) => {
      return data.map(
        ({
          attributes: {
            id,
            name,
            active,
            asset_type: assetType,
            total_assets: totalAssets,
            user_permissions
          }
        }) => {
          return {
            id,
            name,
            active: (
              <Tag colorScheme={active ? 'green' : 'gray'}>
                {active ? 'Active' : 'Inactive'}
              </Tag>
            ),
            total_assets: totalAssets,
            totalAssets: !totalAssets ? (
              <Tag colorScheme="gray">Empty</Tag>
            ) : (
              <Link
                to={getRouteUrl('assets', {
                  asset_template_id: id,
                  page: 1,
                  per_page: 15
                })}
              >
                <Button
                  variant="outline"
                  colorScheme="blue"
                  leftIcon={<ViewIcon />}
                  size="sm"
                  isDisabled={!shouldAllowAssetsRender}
                >
                  {totalAssets}
                </Button>
              </Link>
            ),
            assetType,
            user_permissions
          }
        }
      )
    },
    [shouldAllowAssetsRender]
  )

  const {
    refetch,
    isLoading,
    updateParams,
    fetchParams,
    tableData,
    pagination
  } = useTableFilter({
    id: null,
    fetchCallback: OlingaAPI.assetTemplates,
    fieldsExtractor,
    initialParams: {
      page: 1,
      per_page: 15
    }
  })

  const onTextChange = useCallback(
    (updatedText) => {
      if (!updatedText || !trim(updatedText)?.length) {
        return refetch(['name'])
      }
      updateParams({ name: updatedText }, updatedText?.length >= 3)
    },
    [refetch, updateParams]
  )

  const onDelete = async (id) => {
    try {
      const response = await OlingaAPI.deleteAssetTemplate(id)
      if (response.status === 200) {
        Notification({
          icon: 'success',
          text: response?.data?.message || 'Asset type deleted successfully'
        })

        refetch()
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    }
  }

  const { shouldCreate: shouldAllowAssetTypesCreation } = usePageProperties({
    pageName: 'asset_templates'
  })

  const { shouldRender: shouldAllowAssetsRender } = usePageProperties({
    pageName: 'assets'
  })

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Asset Category',
        accessor: 'assetType.name'
      },
      {
        Header: 'Assets',
        accessor: 'totalAssets',
        sortType: useCallback((rowA, rowB) => {
          const getAttribute = (object) => {
            return get(object, `original.total_assets`)
          }
          if (getAttribute(rowA) > getAttribute(rowB)) return 1
          if (getAttribute(rowA) < getAttribute(rowB)) return -1
          return 0
        }, [])
      },
      {
        Header: 'Active',
        accessor: 'active'
      }
    ],
    hiddenColumnsKey,
    true,
    EditAssetTemplateButton,
    ({ original: { id, user_permissions } }) => ({
      id,
      onDelete,
      permissions: user_permissions
    }),
    ['id']
  )

  return {
    columns,
    hiddenColumns,
    hiddenColumnsKey,
    data: tableData,
    isLoading,
    refetch,
    updateParams,
    fetchParams,
    pagination,
    onTextChange,
    shouldAllowAssetTypesCreation,
    shouldAllowAssetsRender
  }
}

export default useAssetTemplates
