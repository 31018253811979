/* eslint-disable react-hooks/exhaustive-deps */
import { Button, HStack } from '@chakra-ui/react'
import Modal from 'components/Modal'
import { startCase } from 'lodash'
import PrivacyPolicyContent from 'pages/PrivacyPolicy/content'
import TermsAndConditionsContent from 'pages/TermsAndConditions/content'
import React from 'react'
import logo from '../../../assets/images/cxmp/logo_orange.png'
import HomeHeader from './HomeHeader'
import PageContent from './PageContent'
import { Container, Header as PageHeader, LoginBox, Logo } from './styles'
import useUnsignedHome from './useUnsignedHome'

const contents = {
  termsAndConditions: <TermsAndConditionsContent />,
  privacyPolicy: <PrivacyPolicyContent />
}

const HomePage = ({ usersProfile, defaultPersonableType }) => {
  const { currentPage, displayLogo, props, onGoBack } = useUnsignedHome({
    usersProfile,
    defaultPersonableType
  })

  return (
    <Container>
      <LoginBox>
        <Logo src={logo} isNewPasswordPage={displayLogo} />
        <PageHeader>
          <HomeHeader onGoBack={onGoBack} currentPage={currentPage} />
        </PageHeader>
        <PageContent currentPage={currentPage} {...props} />
        <HStack as="nav" spacing={{ base: '1', md: '4' }} justify="center">
          {['privacyPolicy', 'termsAndConditions'].map((route) => {
            return (
              <Modal
                key={route}
                header={startCase(route)}
                body={contents[route]}
                size="4xl"
              >
                <Button
                  size="sm"
                  variant="ghost"
                  color="black"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {startCase(route)}
                </Button>
              </Modal>
            )
          })}
        </HStack>
      </LoginBox>
    </Container>
  )
}

export default HomePage
