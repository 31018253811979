import styled from '@emotion/styled'

export const QuestionActions = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 16px;

  & > * {
    margin-right: 16px;
  }
`

const typeTransformer = {
  question: (isFocused) => `
    border-left: 2px solid #ff871c;
    ${
      isFocused &&
      `
      border: 2px solid #ff871c;
    `
    }
  `,
  logic: (isFocused) => `
    border-left: 2px solid #36cfc9;
    ${
      isFocused &&
      `
      border: 2px solid #36cfc9;
    `
    }
  `,
  section: (isFocused) => `
    border-left: 2px solid #1890ff;
    ${
      isFocused &&
      `
      border: 2px solid #1890ff;
    `
    }
  `,
  instruction: (isFocused) => `
  border-left: 2px solid #4A5568;
  ${
    isFocused &&
    `
    border: 2px solid #4A5568;
  `
  }
`
}
const fromTypeTransformer = {
  question: () => `
    &:before {
      background-color: #ff871c;
    }
  `,
  logic: () => `
    &:before {
      background-color: #36cfc9;
    }
  `,
  section: () => `
    &:before {
      background-color: #1890ff;
    }
  `
}

export const Wrapper = styled.div`
  ${({
    elementType,
    isFocused,
    isNested,
    fromType,
    shouldHideLevelIndicators
  }) => `
    position: relative;
    border-radius: 4px;
    transition: border-color 0.2s ease;
    margin-bottom: 16px;
    margin-left: 8px;
    &:before {
      position: absolute;
      content: '';
      width: 20px;
      height: 1px;
      background-color: transparent;
      top: 50%;
      left: -20px;
      transform: translateY(-50%);
    }
    ${
      (!shouldHideLevelIndicators &&
        isNested &&
        fromTypeTransformer[fromType]()) ||
      ''
    };
    ${!shouldHideLevelIndicators && `border-left: 0;`}
    ${(elementType && typeTransformer[elementType](isFocused)) || ''};

    @media screen and (max-width: 730px) {
      &:before {
        content: none;
      }
    }
  `}
`
export const ChildrenWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  display: inline-flex;
  &:before {
    content: '\f061';
    font-family: 'Font Awesome 5 Free';
    position: relative;
    font-size: 18px;
    color: #1890ff;
    margin-right: 16px;
    margin-left: 12px;
  }
`
export const Container = styled.div`
  position: relative;
  width: 100%;
`

export const Sidebar = styled.div`
  background-color: #dedede;
  height: calc(100vh - 120px);
  position: relative;
`

export const Content = styled.div`
  position: relative;
  width: calc(100% - 60px);
  overflow-y: auto;
  margin: 0;

  @media screen and (max-width: 730px) {
    width: 100% !important;
  }
`

export const FloatingActions = styled.ul`
  position: fixed;
  background-color: #fff;
  margin: 0;
  width: 50px;
  height: auto;
  ${({ top }) => `
    top: ${top};
  `}
  right: 55px;
  padding: 12px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  min-height: 80px;
  & > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media screen and (max-width: 730px) {
    flex-direction: row;
    align-items: center;
    width: 100%;
    left: 0;
    top: unset;
    right: unset;
    bottom: 0;
    background: white;
    border-top: 1px solid orange;
    z-index: 9999;
    padding: 6px 0px;
    & > * {
      &:not(:last-child) {
        margin-bottom: 16px;
        margin-right: 8px;
        margin-bottom: unset;
      }
    }
    & .chakra-divider {
      display: none;
    }
  }
`
const iconTransformer = {
  question: `
    color: #ff871c;
  `,
  section: `
    color: #1890ff;
  `,
  logic: `
    color: #36cfc9;
  `,
  remove: `
    color: #C53030;
  `,
  instruction: `
    color: #4A5568;
  `,
  focus: `
    color: #00B5D8;
  `
}
export const ActionButton = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  border: none;
  outline: none;
  font-size: 10px;
  color: #889eaf;
  cursor: pointer;
  min-width: 55px;
  & .material-icons {
    font-size: 20px;
    ${({ type }) => type && iconTransformer[type]};
    font-style: normal !important;
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: #EBEBE4;
    cursor: not-allowed;
    & i {
      color: #EBEBE4;
    }
  `}
`

export const DraggableContainer = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;

  & i {
    font-size: 32px;
  }
`
export const BoxInnerContent = styled.div`
  display: flex;
  width: 100%;
  ${({ justify, justifyEnd }) => `
    justify-content: ${justify ? 'space-between' : 'flex-start'};
    justify-content: ${justifyEnd ? 'flex-end' : 'space-between'};
    ${!justify && !justifyEnd && 'justify-content: flex-start;'}
  `}
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 16px;
  }
`
export const ItemsNumberLabel = styled.div`
  font-size: 16px;
  color: #889eaf;
  font-weight: bold;
  min-width: 60px;
`
export const FormWrapper = styled.div`
  position: relative;
  padding-top: 10px;

  @media screen and (max-width: 730px) {
    padding-bottom: 120px;
  }
`

export const FixedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const RequiredInfo = styled.div`
  color: #e53e3e;
  font-weight: bold;
  margin-top: -3px;
  position: absolute;
  left: 40px;
`

export const SectionNameWrapper = styled.span`
  margin-left: 4px;
`
export const MobileWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 12px;
  }

  @media screen and (max-width: 599px) {
    flex-direction: column;
    align-items: flex-start;

    & > *:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`
export const FocusButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;

  @media screen and (max-width: 730px) {
    z-index: 9999;
    right: 10px;
    bottom: 90px;
  }
`
