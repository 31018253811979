import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack
} from '@chakra-ui/react'
import EmptyState from 'components/EmptyState'
import { useKeyPress } from 'hooks'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import DefaultFieldCard from './DefaultFieldCard'

const MainFieldSelector = ({
  isOpen,
  onClose,
  defaultFields,
  onChange,
  value,
  onConfirm
}) => {
  const isEnterPressed = useKeyPress('Enter', true, true)

  useEffect(() => {
    if (isEnterPressed && value?.id) {
      onConfirm()
    }
  }, [isEnterPressed, onConfirm, value?.id])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Please, select a required field</ModalHeader>
        <ModalBody>
          {isEmpty(defaultFields) ? (
            <EmptyState text="There are no default fields" />
          ) : (
            <Stack gap={4}>
              {defaultFields
                ?.filter(({ main }) => main)
                ?.map((defaultField, index) => (
                  <DefaultFieldCard
                    key={index}
                    value={value}
                    onChange={onChange}
                    defaultField={defaultField}
                  />
                ))}
            </Stack>
          )}
        </ModalBody>

        <ModalFooter display="flex" gap={4} alignItems="center">
          {value?.id && (
            <Button colorScheme="gray" onClick={() => onChange(null)}>
              Clear
            </Button>
          )}
          <Button
            isDisabled={!value?.id}
            colorScheme="green"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default MainFieldSelector
