import _ from 'lodash'

export const shouldKeepOpened = (currentKey, focusedField) => {
  if (_.startsWith(focusedField, currentKey)) {
    const currentKeyIndexes = currentKey
      .split('.')
      .filter((i) => i !== 'questions')
      .map((i) => _.toNumber(i))
    const focusedFieldIndexes = focusedField
      .split('.')
      .filter((i) => i !== 'questions')
      .map((i) => _.toNumber(i))
      .slice(0, currentKeyIndexes.length)
    return Array.from(focusedFieldIndexes).every((_, i) => {
      return currentKeyIndexes[i] === focusedFieldIndexes[i]
    })
  }
  return false
}
