import { Button, IconButton, Tooltip } from '@chakra-ui/react'
import MaterialIcon from 'components/MaterialIcon'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import OlingaAPI from '../../../services/OlingaAPI'

const ReportDownloadButton = ({
  variant,
  id,
  label,
  isDisabled,
  reportType
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const onClick = async () => {
    setIsLoading(true)
    try {
      const response = await OlingaAPI.downloadReport(id, reportType)
      if (response.status === 200) {
        const { message } = response?.data || {}
        toast.success(message)
      }
    } catch (err) {
      //
    } finally {
      setIsLoading(false)
    }
  }
  const icon = <MaterialIcon icon='cloud_download' />
  return (
    <>
      <Tooltip hasArrow label="Download report">
        {variant === 'icon-only' ? (
          <IconButton
            onClick={onClick}
            aria-label={label}
            icon={icon}
            colorScheme="green"
            isLoading={isLoading}
            size="sm"
            variant="outline"
            isDisabled={isDisabled}
          />
        ) : (
          <Button
            onClick={onClick}
            leftIcon={icon}
            colorScheme="green"
            isLoading={isLoading}
            isDisabled={isDisabled}
          >
            {label}
          </Button>
        )}
      </Tooltip>
    </>
  )
}

ReportDownloadButton.defaultProps = {
  isDisabled: false,
  label: 'Download'
}

ReportDownloadButton.propTypes = {
  variant: PropTypes.oneOf(['icon-only', 'default'])
}

export default ReportDownloadButton
