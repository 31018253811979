import styled from '@emotion/styled'

export const DraggableContainer = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  color: #889eaf;
  &:hover {
    color: #ff871c;
  }
`
