import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { itemsTransformer } from '../../contexts/GlobalStateContext/useGlobalContext/helpers/settings'
import { singularize } from '../../helpers'

const usePageProperties = ({ pageName }) => {
  const { features } = useSelector(({ globalReducer }) => globalReducer)
  const pageTitle =
    get(features, `${pageName}.businessName`) ||
    get(features, `${pageName}.name`)
  const icon = itemsTransformer[pageName]?.icon ?? null
  return {
    pageTitle,
    singularTitle: singularize(pageTitle),
    shouldRender: get(features, `${pageName}.shouldRender`),
    shouldCreate: get(features, `${pageName}.shouldCreate`),
    shouldBulkImport: get(features, `${pageName}.shouldBulkImport`),
    icon
  }
}

export default usePageProperties
