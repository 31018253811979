import { getRouteUrl } from 'helpers'
import { capitalize, isEmpty, map, pick, trim, upperCase } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Notification from '../../..//services/Notification'
import OlingaAPI from '../../..//services/OlingaAPI'
import {
  usePageProperties,
  useQuery,
  useTableColumns,
  useTableFilter
} from '../../../hooks'
import ProfilesActions from '../ProfilesActions'

const hiddenColumnsKey = 'form_answers_persisted_columns.profiles'

const useProfiles = () => {
  const {
    pageTitle,
    singularTitle,
    shouldCreate: hasPermissionToCreateProfiles
  } = usePageProperties({ pageName: 'profiles' })

  const history = useHistory()

  const query = useQuery()

  const fieldsExtractor = useCallback((data) => {
    if (!isEmpty(data)) {
      return data.map(({ attributes: { name, id, description, roles } }) => ({
        id,
        name,
        description,
        roles: map(roles, (role) => capitalize(upperCase(role))).join(', ')
      }))
    }
    return []
  }, [])

  const initialParams = useMemo(() => {
    return isEmpty(query)
      ? {
          page: 1,
          per_page: 15
        }
      : pick(query, ['page', 'per_page', 'name'])
  }, [query])

  const {
    refetch,
    isLoading,
    fetchParams,
    tableData,
    pagination,
    updateParams
  } = useTableFilter({
    id: null,
    fetchCallback: OlingaAPI.profilesList,
    fieldsExtractor,
    initialParams,
    onRefetch: useCallback(
      (newParams) => {
        history.replace(getRouteUrl('profiles', newParams))
      },
      [history]
    )
  })

  const onConfirmDelete = async (id) => {
    try {
      const response = await OlingaAPI.profileDelete(id)
      if (response?.status === 200) {
        Notification({
          icon: 'success',
          text: 'Profile has been deleted.'
        })

        refetch()
      }
    } catch (error) {
      Notification({
        icon: 'error',
        text: error?.message || ''
      })
    }
  }

  const { columns, hiddenColumns } = useTableColumns(
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
      {
        Header: 'Roles',
        accessor: 'roles'
      }
    ],
    hiddenColumnsKey,
    hasPermissionToCreateProfiles,
    ProfilesActions,
    (row) => {
      const {
        values: { id }
      } = row
      return { id, onConfirmDelete }
    }
  )

  const onTextChange = useCallback(
    (updatedText) => {
      if (!updatedText || !trim(updatedText)?.length) {
        return refetch({ per_page: 15, page: 1 })
      }
      updateParams({ name: updatedText }, updatedText?.length >= 3)
    },
    [refetch, updateParams]
  )

  return {
    isLoading,
    pagination,
    tableData,
    columns,
    hiddenColumns,
    refetch,
    fetchParams,
    hiddenColumnsKey,
    pageTitle,
    singularTitle,
    onTextChange,
    hasPermissionToCreateProfiles
  }
}

export default useProfiles
