import { useTemplateEditorContext } from 'contexts'
import { differenceBy, isEmpty, sortBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import OlingaAPI from 'services/OlingaAPI'

const useHeader = ({ isEdit }) => {
  const { control, getValues, setValue } = useFormContext()

  const { formFolderList, formPermissions } = useTemplateEditorContext()

  const [isLoadingGroups, setIsLoadingGroups] = useState(false)
  const [isLoadingFolders, setIsLoadingFolders] = useState(true)
  const [shouldShowNotifyHandler, setShouldShowNotifyHandler] = useState(false)
  const [groups, setGroups] = useState([])

  const selectablePermissions = useMemo(() => {
    if (!formPermissions) {
      return []
    }
    return sortBy(formPermissions, ({ label }) => label)
  }, [formPermissions])

  const groupsRef = useRef([])

  const [id, formFolderId, notify] = useWatch({
    name: ['_id', 'form_folder_id', 'notify'],
    control,
    defaultValue: [
      getValues('_id'),
      getValues('form_folder_id'),
      getValues('notify')
    ]
  })

  const loadGroups = useCallback(
    async (updatedFormFolderId) => {
      setIsLoadingGroups(true)
      const listGroups = await OlingaAPI.groupsSelectOptionsList(
        updatedFormFolderId ?? formFolderId
      )

      if (listGroups) {
        setGroups(listGroups.map((groupItem) => groupItem.attributes))
      }

      setIsLoadingGroups(false)
    },
    [formFolderId]
  )

  useEffect(() => {
    setShouldShowNotifyHandler(notify)
  }, [notify])

  useEffect(() => {
    if (formFolderId) {
      loadGroups()
    }
  }, [formFolderId, loadGroups])

  useEffect(() => {
    setIsLoadingFolders(isEmpty(formFolderList))
  }, [formFolderList])

  const groupsOptions = useMemo(() => {
    const templateGroupsPersistedIds = (
      getValues('template_groups_attributes') || []
    ).reduce((acc, group) => {
      acc[group.group_id] = group.id
      return acc
    }, {})
    return (
      groups.map((group) => {
        const groupToSave = {
          label: group.name,
          value: Number(group.id),
          id: Number(templateGroupsPersistedIds[group.id])
        }
        if (!group.id) {
          delete groupToSave.id
        }
        return groupToSave
      }) || []
    )
  }, [getValues, groups])

  const defaultGroupValue = useMemo(() => {
    return groupsOptions.filter((group) => {
      const groupsAttributes = getValues('template_groups_attributes') || []
      const groupIds = groupsAttributes.map(({ group_id: groupId }) => groupId)
      if (groupIds.includes(group.value) && !group?._destroy) {
        return group
      }
      return false
    })
  }, [getValues, groupsOptions])

  const defaultFormFolderValue = useMemo(() => {
    return formFolderList.find(({ value }) => value === formFolderId) ?? null
  }, [formFolderList, formFolderId])

  const shouldRenderFormFolderIdSelector = useMemo(() => {
    return isEdit ? !!defaultFormFolderValue : true
  }, [isEdit, defaultFormFolderValue])

  const shouldRenderSwitches = useMemo(() => {
    return isEdit ? !!id : true
  }, [isEdit, id])

  const onChangeGroupsValue = (value) => {
    const groupTransformer = (group, shouldDestroy = false) => {
      const groupToSave = { id: group.id, group_id: group.value }
      if (!group.id) {
        delete groupToSave.id
      }
      if (shouldDestroy) {
        groupToSave._destroy = true
      }
      return groupToSave
    }
    if (groupsRef.current.length) {
      const groupsToSave = [
        ...groupsRef.current,
        ...value.map((group) => groupTransformer(group))
      ]
      setValue('template_groups_attributes', groupsToSave)
    } else {
      const diff = differenceBy(groupsOptions, value, 'value')
      const groupsToSave = [
        ...value.map((group) => groupTransformer(group)),
        ...diff.map((group) => groupTransformer(group, true))
      ]
      setValue('template_groups_attributes', groupsToSave)
    }
  }

  const onChangeFormFolderId = ({ value }) => {
    if (value !== formFolderId) {
      const persistedGroups = groupsOptions
        .filter(({ id }) => !!id)
        .map((group) => ({
          _destroy: true,
          id: group.id,
          group_id: group.value
        }))
      if (persistedGroups.length) {
        groupsRef.current = [...groupsRef.current, ...persistedGroups]
        setValue('template_groups_attributes', persistedGroups)
      }
      setValue('form_folder_id', value)
      loadGroups(value)
    }
  }

  const onChangePermission = (permission) => {
    setValue('template_setting_attributes', permission)
  }

  return {
    onChangeFormFolderId,
    onChangeGroupsValue,
    onChangePermission,
    isLoadingFolders,
    isLoadingGroups,
    selectablePermissions,
    shouldRenderSwitches,
    shouldRenderFormFolderIdSelector,
    shouldShowNotifyHandler,
    defaultGroupValue,
    defaultFormFolderValue,
    groups,
    groupsOptions
  }
}

export default useHeader
