import { Box, Divider, Flex, Heading, Stack } from '@chakra-ui/react'
import { omit } from 'lodash'
import React from 'react'
import EmptyState from '../../components/EmptyState'
import RecursiveStructure from '../../components/TemplateRecursiveStructure'
import TemplatePageBreadcrumb from '../Template/TemplatePagesBreadcrumb'
import TemplateDetails from './TemplateDetails'
import useTemplateStructure from './useTemplateStructure'

const TemplateStructure = ({ template }) => {
  const {
    pageTitle,
    singularTitle,
    templateDetails,
    hasPermissionsToModifyFolder,
    hasPermissionsToModifyGroup
  } = useTemplateStructure({
    template
  })

  if (!template?.attributes) {
    return <EmptyState text="This template does not exists !" />
  }

  return (
    <Stack>
      <TemplatePageBreadcrumb
        templateDetails={templateDetails}
        templateId={template.id}
        routeName={`${singularTitle} Structure`}
        pageTitle={pageTitle}
        singularTitle={singularTitle}
      />
      <Box py={4}>
        <TemplateDetails
          {...omit(template.attributes, ['children'])}
          singularTitle={singularTitle}
          hasPermissionToModifyFolder={hasPermissionsToModifyFolder}
          hasPermissionsToModifyGroup={hasPermissionsToModifyGroup}
        />
      </Box>
      <Divider />
      <Flex
        direction={['column', 'row']}
        align={['flex-start', 'center']}
        justify={['flex-start', 'space-between']}
        w="100%"
      >
        <Heading size="sm">
          {singularTitle}: {templateDetails.name}
        </Heading>
      </Flex>
      <Stack w="100%">
        <RecursiveStructure
          displayLabels
          childrenStructure={template.attributes.children}
        />
      </Stack>
    </Stack>
  )
}

export default TemplateStructure
