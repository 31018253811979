import { Box, Button } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const Signature = ({ onChange }) => {
	const sigRef = useRef()
  const [signature, setSignature] = useState(null)

  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL())
  }

  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
  }

	useEffect(() => {
		onChange(signature);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signature])


	return (
		<>
			<Box borderColor='blackAlpha.500' border='1px' w='500px'>
				<SignatureCanvas 
					ref={sigRef}
					onEnd={handleSignatureEnd}
					canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
				/>
			</Box>
			<Button type='button' onClick={clearSignature}>Clear</Button>
		</>
	);
};

export default Signature;
