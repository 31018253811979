import React, { createContext, useContext } from 'react'
import useGlobalContext from './useGlobalContext'

const GlobalStateContext = createContext({})

const GlobalStateProvider = ({ children, ...globalProps }) => {
  const { global, global$ } = useGlobalContext(globalProps)

  return (
    <GlobalStateContext.Provider
      value={{
        global$,
        global
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  )
}

export default GlobalStateProvider

export const useGlobalStateContext = () => {
  const context = useContext(GlobalStateContext)

  return {
    ...context
  }
}
