export const actions = [
  {
    name: 'Zoom In',
    handler(chart) {
      chart.zoom({ x: 1.2 })
    },
    icon: 'zoom_in'
  },
  {
    name: 'Zoom Out',
    handler(chart) {
      chart.zoom({ x: 0.8 })
    },
    icon: 'zoom_out'
  },
  {
    name: 'Reset zoom',
    handler(chart) {
      chart.resetZoom()
    },
    icon: 'zoom_out_map'
  },
  {
    name: 'Pan Left',
    handler(chart) {
      chart.pan({ x: 200 }, undefined, 'default')
    },
    icon: 'arrow_left'
  },
  {
    name: 'Pan Right',
    handler(chart) {
      chart.pan({ x: -200 }, undefined, 'default')
    },
    icon: 'arrow_right'
  }
]
