import {
  Alert,
  AlertIcon,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Radio,
  RadioGroup,
  Stack
} from '@chakra-ui/react'
import TransferList from 'components/TransferList'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import useAssetsHandler from './hooks/useAssetsHandler'

const AssetsHandler = ({ currentKey }) => {
  const { leftList, rightList, onChange, isLoading } = useAssetsHandler({
    currentKey
  })

  const { setValue } = useFormContext()

  return (
    <Stack>
      <Stack>
        <FormControl>
          <FormLabel>Association type</FormLabel>
          <RadioGroup
            onChange={(value) =>
              setValue(`${currentKey}.association_type`, value)
            }
            defaultValue="specific"
            isDisabled
          >
            <Stack direction="row">
              <Radio mb="0" value="all">
                All
              </Radio>
              <Radio value="specific">Specific</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
      </Stack>
      <Divider />
      <Heading size="sm">Asset types</Heading>
      <Alert status="info" borderRadius="sm">
        <AlertIcon />
        Asset Types having registered Assets will be listed below
      </Alert>
      <TransferList
        leftList={leftList}
        rightList={rightList}
        isLoading={isLoading}
        onChange={onChange}
        itemAttribute="label"
      />
    </Stack>
  )
}

export default AssetsHandler
