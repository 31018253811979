import {
  filter,
  first,
  isArray,
  isEmpty,
  isNull,
  isObject,
  isString,
  isUndefined,
  map,
  reject
} from 'lodash'
import { getLogicOperator } from 'pages/Form/FillForm/helpers'
import { LOGIC_TYPE_IS_A_NUMBER } from 'pages/Template/ManageTemplate/FormQuestions/constants'
import { IndexMap } from 'pages/TemplateEditor/helpers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const useFillFormLogic = ({ field, logicKey }) => {
  const { control, unregister } = useFormContext()
  const [visibleChildrens, setVisibleChildrens] = useState([])

  const indexes = useMemo(() => {
    return new IndexMap()
  }, [])

  const fieldCurrentKey = useMemo(() => `${logicKey}.questions`, [logicKey])

  const fieldKey = useMemo(
    () => indexes.getUniqKey(fieldCurrentKey),
    [fieldCurrentKey, indexes]
  )

  const value = useWatch({
    name: `${fieldCurrentKey}.value`,
    control,
    defaultValue: null
  })

  const childrens = visibleChildrens

  const updateChildrensValue = useCallback(
    (logic, isValid) => {
      const isVisible = !isEmpty(
        visibleChildrens.filter(
          (v) =>
            v.question_logic_id === logic.id ||
            map(logic.children, 'id').includes(v.id)
        )
      )

      const logicChildrens = logic.children?.map((c, idx) => {
        const currentChildrenKey = `${logicKey}.questions.questions.${idx}`
        const key = indexes.getUniqKey(currentChildrenKey)

        return {
          ...c,
          currentChildrenKey,
          key
        }
      })

      if (isValid && !isVisible) {
        setVisibleChildrens([...childrens, ...logicChildrens])
      } else if (!isValid && isVisible) {
        const callback = (l) =>
          l.question_logic_id === logic.id ||
          map(logic.children, 'id').includes(l.id)

        const currentChildrenKey = first(
          filter(logicChildrens, callback)
        )?.currentChildrenKey

        setVisibleChildrens(reject(childrens, callback))
        unregister(currentChildrenKey)
      }
    },
    [childrens, indexes, logicKey, unregister, visibleChildrens]
  )

  const currentCondition = (logicCondition, value) =>
    `${logicCondition}: [${value}]`

  const verifyLogicCondition = useCallback(
    (expressionValue, value, logic, operator) => {
      const logicQuestionType = logic.question_logic_option.question_type
      let isValid = false

      if (logicQuestionType === 'checkbox') {
        const logicConditionValue = logic.condition.toLowerCase()
        const logicCondition = logic.question_logic_option.text.toLowerCase()
        const isNot = expressionValue.includes('not')

        if (Array.isArray(value)) {
          value.join(' # ')
        }

        value = isString(value) ? value.toLowerCase() : value

        if (!value) {
          isValid = false
        } else if (isNot) {
          isValid =
            currentCondition(logicCondition, value) !== logicConditionValue
        } else {
          isValid =
            currentCondition(logicCondition, value) === logicConditionValue
        }
      } else {
        const logicValue = logic.value

        if (!value) {
          isValid = false
        } else if (
          LOGIC_TYPE_IS_A_NUMBER.includes(
            first(field.children)?.question_logic_option?.question_type
          )
        ) {
          isValid = eval(`${parseInt(value)} ${operator} ${logicValue}`)
        } else {
          isValid = eval(
            `'${value.toLowerCase()}' ${operator} '${logicValue.toLowerCase()}'`
          )
        }
      }

      updateChildrensValue(logic, isValid)
    },
    [field.children, updateChildrensValue]
  )

  const verifyBetweenCondition = useCallback(
    (expressionValue, logic, value) => {
      let isValid = false
      const logicValue = logic.value

      if (
        logic?.question_logic_option?.question_type === 'scale'
          ? !value
          : isEmpty(value)
      ) {
        return updateChildrensValue(logic, false)
      }

      const isNot = expressionValue.includes('not')
      const commaIndex = logicValue.indexOf(',')
      const start = logicValue.slice(0, commaIndex).replace(/ /g, '')
      const end = logicValue.slice(commaIndex + 1).replace(/ /g, '')

      const inRange = inRange(value, start, end)
      isValid = isNot ? !inRange : inRange

      updateChildrensValue(logic, isValid)
    },
    [updateChildrensValue]
  )

  const verifyOneOfCondition = useCallback(
    (expressionValue, logic, value) => {
      let isValid = false
      const logicValue = logic.value

      if (isEmpty(value)) {
        return updateChildrensValue(logic, false)
      }

      const isNot = expressionValue.includes('not')
      let logicAttrValue = []
      if (logicValue.match(';')) {
        logicAttrValue = logicValue
          .split(';')
          .map((v) => v.trim())
          .filter((v) => !isEmpty(v))
      } else {
        logicAttrValue = [logicValue.trim()]
      }
      const filterValue = value.filter((v) => {
        return logicAttrValue.includes(v.toString())
      })

      isValid = isNot ? isEmpty(filterValue) : !isEmpty(filterValue)

      updateChildrensValue(logic, isValid)
    },
    [updateChildrensValue]
  )

  const shouldReturnWithEmptyValue = useCallback(() => {
    const shouldResetValue = ['boolean', 'radio_btn']

    return (
      isNull(value) ||
      isUndefined(value) ||
      ((value === '' || value === 0) &&
        !shouldResetValue.includes(field.question_type))
    )
  }, [field.question_type, value])

  const verifyLogicMatch = useCallback(
    (operator, value, logic, idx, expressionValue) => {
      if (operator) {
        verifyLogicCondition(expressionValue, value, logic, operator)
      } else if (
        expressionValue === 'between' ||
        expressionValue === 'not_between'
      ) {
        verifyBetweenCondition(expressionValue, logic, value)
      } else if (
        expressionValue === 'is_one_of' ||
        expressionValue === 'is_not_one_of'
      ) {
        verifyOneOfCondition(expressionValue, logic, value)
      }
    },
    [verifyBetweenCondition, verifyLogicCondition, verifyOneOfCondition]
  )

  useEffect(() => {
    if (shouldReturnWithEmptyValue()) {
      return
    }

    let logicValue = value
    if (isObject(value) && !isArray(value)) {
      logicValue = value?.value
    } else if (isArray(value) && isEmpty(value)) {
      logicValue = null
    }

    field.children.map((logic, idx) => {
      const expressionValue = logic?.question_logic_option?.expression_value
      if (!logic.children) {
        return
      }

      const operator = getLogicOperator(expressionValue)

      verifyLogicMatch(operator, logicValue, logic, idx, expressionValue)
    })
  }, [field.children, shouldReturnWithEmptyValue, value, verifyLogicMatch])

  return {
    verifyLogicMatch,
    shouldReturnWithEmptyValue,
    verifyOneOfCondition,
    verifyBetweenCondition,
    verifyLogicCondition,
    updateChildrensValue,
    fieldCurrentKey,
    fieldKey,
    visibleChildrens
  }
}

export default useFillFormLogic
