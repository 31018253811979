import { theme } from '@chakra-ui/react'
import { omit } from 'lodash'

export const getStyles = (isOperation, isNumber, color) => {
  const defaultStyles = {
    borderRadius: 4,
    display: 'block',
    align: 'center',
    w: '250px',
    maxW: '250px',
    p: 2,
    bg: 'gray.100',
    borderLeft: `4px solid ${theme.colors.blue[500]}`,
    color: 'blue.700',
    fontWeight: 'bold',
    boxSizing: 'border-box',
    fontSize: '14px',
    cursor: 'help',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
  if (isOperation) {
    return {
      ...omit(defaultStyles, ['display']),
      w: '40px',
      h: '40px',
      alignSelf: 'center',
      bg: color,
      border: 'none',
      color: 'white',
      align: 'center',
      justify: 'center',
      cursor: 'default',
      fontSize: '20px',
      lineHeight: '20px'
    }
  }
  if (isNumber) {
    return {
      ...omit(defaultStyles, ['display']),
      w: 'auto',
      h: '40px',
      minW: '40px',
      alignSelf: 'center',
      bg: 'teal.500',
      border: 'none',
      color: 'white',
      align: 'center',
      justify: 'center',
      cursor: 'default'
    }
  }
  return defaultStyles
}
