import { EditIcon } from '@chakra-ui/icons'
import { Flex, IconButton } from '@chakra-ui/react'
import DeleteButton from 'components/DeleteButton'
import React from 'react'
import { Link } from 'react-router-dom'
import { getRouteUrl } from '../../../helpers'

const EditDashboardActions = ({ id, onDelete, userPermissions }) => (
  <>
    <Flex align="center" gap={4}>
      <Flex align="center" gap={4}>
        {userPermissions?.edit && (
          <Link to={getRouteUrl('editDashboard', { id })}>
            <IconButton
              icon={<EditIcon />}
              colorScheme="blue"
              size="sm"
              variant="outline"
              aria-label={`Edit dashboard ${id}`}
            />
          </Link>
        )}
        {userPermissions?.destroy && (
          <DeleteButton
            index={id}
            onDelete={onDelete}
            alertTitle="Delete dashboard"
            alertBody="This action cannot be undone."
            continueLabel="Yes, remove it!"
            size="sm"
            variant="outline"
            borderRadius={6}
          />
        )}
      </Flex>
    </Flex>
  </>
)

export default EditDashboardActions
