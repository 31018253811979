export const ACTIONS = {
  SET_FOLDERS_LIST: 'SET_FOLDERS_LIST',
  SET_FOLDER: 'SET_FOLDER',
  SET_IS_LOADING_FOLDERS_LIST: 'SET_IS_LOADING_FOLDERS_LIST',
  SET_TEMPLATES_LIST: 'SET_TEMPLATES_LIST',
  SET_TEMPLATE: 'SET_TEMPLATE',
  SET_IS_LOADING_TEMPLATES_LIST: 'SET_IS_LOADING_TEMPLATES_LIST'
}

const {
  SET_FOLDERS_LIST,
  SET_FOLDER,
  SET_IS_LOADING_FOLDERS_LIST,
  SET_TEMPLATES_LIST,
  SET_IS_LOADING_TEMPLATES_LIST,
  SET_TEMPLATE
} = ACTIONS

export const initialState = {
  foldersList: [],
  folder: null,
  isLoadingFoldersList: false,
  templatesList: [],
  template: null,
  isLoadingTemplatesList: false
}

export const templateSelectorReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_FOLDERS_LIST:
      return {
        ...state,
        foldersList: payload
      }
    case SET_FOLDER:
      return {
        ...state,
        folder: payload
      }
    case SET_IS_LOADING_FOLDERS_LIST:
      return {
        ...state,
        isLoadingFoldersList: payload
      }
    case SET_TEMPLATES_LIST:
      return {
        ...state,
        templatesList: payload
      }
    case SET_TEMPLATE:
      return {
        ...state,
        template: payload
      }
    case SET_IS_LOADING_TEMPLATES_LIST:
      return {
        ...state,
        isLoadingTemplatesList: payload
      }
    default:
      throw Error('Invalid action')
  }
}
